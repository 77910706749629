import { Flex, IconWrap, Text } from "@coaching-culture/ui";
import { FaBan } from "react-icons/fa";

export const NoData = () => {
  return (
    <Flex
      p={6}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <IconWrap icon={FaBan} color="grey3" size="8em" />
      <Text fontSize={5} color="grey2" mt={3} fontWeight={600}>
        No Data
      </Text>
    </Flex>
  );
};
