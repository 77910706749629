import { Circle, CircleProps } from "@coaching-culture/ui";
import InitialDisplay from "./InitialDisplay";

export type UserProfileImageProps = {
  profileImage?: string;
  name: string;
} & CircleProps;

const UserProfileImage = ({
  profileImage,
  name,
  ...rest
}: UserProfileImageProps) => {
  return profileImage ? (
    <Circle
      title={name}
      image={`${process.env.REACT_APP_STATIC_ROOT}/${profileImage}`}
      {...rest}
    />
  ) : (
    <InitialDisplay title={name} text={name} {...rest} />
  );
};

export default UserProfileImage;
