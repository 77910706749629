import { CoachingTopicDto, DiscussionPointDto } from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  CheckBox,
  Circle,
  Flex,
  FormInput,
  FormTextArea,
  IconButton,
  Input,
  Loader,
  Panel,
  Rule,
  SavedToast,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { FormDateSelect } from "components/FormDateSelect";
import { PageHeader } from "components/PageHeader";
import {
  addBusinessDays,
  fromUnixTime,
  getUnixTime,
  isPast,
  isToday,
  startOfDay,
} from "date-fns";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import NotFound from "pages/NotFound";
import {
  useCancelConversation,
  useCancelConversationOccurrence,
  useChangeActionItem,
  useChangeAgenda,
  useConversation,
  useRescheduleConversation,
} from "queries/conversations";
import { useEffect, useState } from "react";
import {
  FaArrowCircleRight,
  FaCalendarTimes,
  FaCommentDots,
  FaGripVertical,
  FaStopwatch,
  FaThumbsDown,
  FaThumbsUp,
  FaTimes,
} from "react-icons/fa";
import { useParams } from "react-router";
import { isDateBetween } from "utils/dates";
import { CancelConversationModal } from "./CancelConversationModal";
import { CoachingTopicsModal } from "./CoachingTopicsModal";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import { arrMove } from "utils";

export function ConversationDetails() {
  const { id, index } = useParams<{ id: string; index: string }>();
  const [user] = useUser();
  const { data, isFetched } = useConversation(id, parseInt(index, 10));
  const rescheduleConvo = useRescheduleConversation(id, parseInt(index, 10));
  const changeAgenda = useChangeAgenda(id, parseInt(index, 10));
  const cancelConversation = useCancelConversation(id);
  const cancelConversationOccurence = useCancelConversationOccurrence(
    id,
    parseInt(index, 10)
  );
  const changeActionItemStatus = useChangeActionItem();
  const [date, setDate] = useState<Date>(new Date());
  const [discussionPoints, setDiscussionPoints] = useState<
    Partial<DiscussionPointDto>[]
  >([]);
  const [actionCompletion, setActionCompletion] = useState<
    Record<string, boolean>
  >({});
  const [showPrivateNotes, setShowPrivateNotes] = useState<boolean>(false);
  const pushToast = useToast();
  const [cancelling, setCancelling] = useState(false);
  const [addingCoachingTopics, setAddingCoachingTopics] = useState(false);

  const convo = data;

  useEffect(() => {
    if (convo != null) {
      setDate(fromUnixTime(convo.scheduledDate));
      setDiscussionPoints([...convo.agenda]);
      setActionCompletion(
        Object.fromEntries(
          (convo.actionItems ?? []).map((x) => [x.id, x.completedOn != null])
        )
      );
    }
  }, [convo]);

  if (!isFetched) {
    return <Loader />;
  }

  if (convo == null) {
    return <NotFound />;
  }

  const reschedule = async () => {
    if (getUnixTime(date) === convo.scheduledDate) {
      // No change
      return;
    }

    if (
      window.confirm("Are you sure you want to reschedule this conversation?")
    ) {
      await rescheduleConvo.mutateAsync(date);
      pushToast({ content: <SavedToast /> });
    } else {
      setDate(fromUnixTime(convo.scheduledDate));
    }
  };

  const canViewPrivateNotes = convo ? convo.manager.id === user.id : false;

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    setDiscussionPoints(
      arrMove(discussionPoints, result.source.index, result.destination.index)
    );
  };

  const canEditItem = (item: Partial<DiscussionPointDto>) => {
    if (item.id == null) {
      // New
      return true;
    }
    if (user.id === convo.manager.id) {
      return true;
    }
    return item.addedBy === user.id;
  };

  const saveAgenda = async () => {
    const items = discussionPoints.filter((x) => x.name.trim() !== "");
    setDiscussionPoints(items);

    try {
      await changeAgenda.mutateAsync(items);
      pushToast({ content: <SavedToast /> });
    } catch (err) {
      window.alert(err);
    }
  };

  const changeActionCompletion = async (aid: string, val: boolean) => {
    actionCompletion[aid] = val;
    setActionCompletion({ ...actionCompletion });
    await changeActionItemStatus.mutateAsync({
      actionItemId: aid,
      value: val,
      conversationId: id,
    });
  };

  const removeAgendaItem = (idx: number) => {
    setDiscussionPoints(discussionPoints.filter((_, i) => i !== idx));
  };

  const addAgendaItem = () => {
    setDiscussionPoints([...discussionPoints, { name: "", addedBy: user.id }]);
  };

  const changeAgendaName =
    (idx: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      setDiscussionPoints(
        discussionPoints.map((x, i) => ({
          ...x,
          name: i === idx ? ev.target.value : x.name,
        }))
      );
    };

  const cancelConvo = async () => {
    if (convo.recurrence === "none") {
      if (
        window.confirm("Are you sure you want to cancel this conversation?")
      ) {
        await cancelConversationOccurence.mutateAsync();
      }
    } else {
      setCancelling(true);
    }
  };

  const confirmCancellation = async (all: boolean) => {
    setCancelling(false);
    if (all) {
      await cancelConversation.mutateAsync();
    } else {
      await cancelConversationOccurence.mutateAsync();
    }
  };

  const saveTopics = (topics: Partial<CoachingTopicDto[]>) => {
    const temp = topics.map((x) => ({ name: x.body, addedBy: user.id }));
    setDiscussionPoints([...discussionPoints, ...temp]);
    setAddingCoachingTopics(false);
  };

  const notMe =
    convo.directReport.id === user.id ? convo.manager : convo.directReport;

  const isCancelled = convo.cancelledOn != null;
  const isComplete = convo.completedOn != null;
  const isManager = convo.manager.id === user.id;
  const canReschedule = !convo.isParentCancelled;
  const isStartable =
    !isCancelled &&
    !isComplete &&
    isDateBetween(
      new Date(),
      startOfDay(fromUnixTime(convo.scheduledDate)),
      addBusinessDays(fromUnixTime(convo.scheduledDate), 3)
    ) &&
    isManager;
  const hasPast =
    isPast(fromUnixTime(convo.scheduledDate)) &&
    !isToday(fromUnixTime(convo.scheduledDate));
  const canCancel = !isCancelled && !isComplete;

  const isSuggested = (item: Partial<DiscussionPointDto>) => {
    if (item.addedBy === convo.manager.id) {
      return false;
    }
    if (item.id == null && isManager) {
      return false;
    }

    return true;
  };

  return (
    <>
      <CancelConversationModal
        isOpen={cancelling}
        onCancel={() => setCancelling(false)}
        onConfirm={confirmCancellation}
      />

      <CoachingTopicsModal
        isOpen={addingCoachingTopics}
        onSave={(val) => saveTopics(val)}
        onCancel={() => setAddingCoachingTopics(false)}
      />
      <PageHeader
        text={`${convo.type}: ${notMe.name}`}
        backUrl="/solutions/performance/conversations"
        subtitle="Modify a scheduled conversation"
      />
      <Panel p={[2, 3]}>
        {isCancelled ? (
          <Flex
            border={1}
            borderColor="danger"
            borderRadius={6}
            p={2}
            mb={2}
            alignItems="center"
          >
            <Circle color="danger" icon={FaCalendarTimes} size="large" mr={2} />
            <div>
              <Text fontSize={4} fontWeight={500} color="danger">
                Conversation Cancelled
              </Text>
              <Text fontWeight={500}>
                {data.isParentCancelled
                  ? "This conversation cannot be rescheduled."
                  : "You can still reschedule it for another time."}
              </Text>
            </div>
          </Flex>
        ) : isComplete ? (
          <Flex
            border={1}
            borderColor="positive"
            borderRadius={6}
            p={2}
            mb={2}
            alignItems="center"
          >
            <Circle color="positive" icon={FaThumbsUp} size="large" mr={2} />
            <div>
              <Text fontSize={4} fontWeight={500} color="positive">
                Conversation Complete
              </Text>
              <Text fontWeight={500}>
                This conversation has been completed. You can see any action
                items below.
              </Text>
            </div>
          </Flex>
        ) : isStartable ? (
          <Flex
            border={1}
            borderColor="primary"
            borderRadius={6}
            p={2}
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center">
              <Circle color="primary" icon={FaThumbsUp} size="large" mr={2} />
              <Text fontSize={4} fontWeight={500} color="primary">
                Ready to Start
              </Text>
            </Flex>
            <Button
              icon={FaArrowCircleRight}
              to={`/solutions/performance/conversations/${id}/${index}/play`}
              color="primary"
            >
              Start Conversation
            </Button>
          </Flex>
        ) : hasPast ? (
          <Flex
            border={1}
            borderColor="danger"
            borderRadius={6}
            p={2}
            mb={2}
            alignItems="center"
          >
            <Circle color="danger" icon={FaThumbsDown} size="large" mr={2} />
            <div>
              <Text fontSize={4} fontWeight={500} color="danger">
                Conversation Missed
              </Text>
              <Text fontWeight={500}>
                You can still update the discussion points and reschedule it for
                another time.
              </Text>
            </div>
          </Flex>
        ) : (
          <Flex
            border={1}
            borderColor="primary"
            borderRadius={6}
            p={2}
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center">
              <Circle color="primary" icon={FaStopwatch} size="large" mr={2} />
              <div>
                <Text fontSize={4} fontWeight={500} color="primary">
                  Upcoming Conversation
                </Text>
                <Text fontWeight={500}>
                  This conversation is scheduled for the future. You can edit
                  the discussion points below.
                </Text>
              </div>
            </Flex>
          </Flex>
        )}
        {canCancel && (
          <Flex border={1} borderRadius={6} mb={2} p={2} alignItems="center">
            <Circle color="danger" size="large" icon={FaCalendarTimes} mr={2} />
            <div style={{ flex: 1 }}>
              <Text color="danger" fontWeight={500}>
                Cancel Conversation
              </Text>
              <Text fontWeight={500}>
                Cancelling the conversation will notify the other party.
              </Text>
            </div>
            <Button color="danger" onClick={cancelConvo}>
              Cancel Conversation
            </Button>
          </Flex>
        )}
        <FormDateSelect
          buttonText="Reschedule"
          label="Scheduled For"
          minDate={new Date()}
          value={date}
          onCalendarClose={reschedule}
          onChange={setDate}
          disabled={isComplete || !isManager || !canReschedule}
        />
        <FormInput
          value={convo.purpose}
          label="Conversation Purpose"
          readOnly
        />
        <FormInput
          value={convo.outcome}
          label="Conversation Outcome"
          readOnly
        />
        <FormTextArea
          value={convo.preparation}
          label="Suggested Preparation"
          readOnly
          mb={3}
        />
        <Box mb={3}>
          <Text fontSize={4} fontWeight={500} mb={3}>
            Discussion Points
          </Text>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {discussionPoints.map((x, i) => (
                    <Draggable
                      key={x.id}
                      draggableId={x.id != null ? x.id : i.toString()}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <Flex
                          borderBottom={1}
                          alignItems="center"
                          p={2}
                          key={x.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getDndItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Flex
                            {...provided.dragHandleProps}
                            alignItems="center"
                            mr={1}
                          >
                            <FaGripVertical color="#999" />
                          </Flex>
                          <Circle
                            color={isSuggested(x) ? "orange" : "primary"}
                            icon={FaCommentDots}
                            mr={2}
                          />
                          <Input
                            value={x.name}
                            onChange={changeAgendaName(i)}
                            style={{ flex: 1 }}
                            mr={2}
                            readOnly={
                              isCancelled || isComplete || !canEditItem(x)
                            }
                          />
                          <IconButton
                            disabled={
                              isCancelled || isComplete || !canEditItem(x)
                            }
                            icon={FaTimes}
                            color="danger"
                            onClick={() => removeAgendaItem(i)}
                          />
                        </Flex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!isComplete && !isCancelled && (
            <>
              <Flex>
                <AddButton onClick={addAgendaItem} mt={2}>
                  {isManager ? "Add Agenda Item" : "Suggest Agenda Item"}
                </AddButton>
                {isManager && (
                  <AddButton
                    ml={2}
                    onClick={() => setAddingCoachingTopics(true)}
                    mt={2}
                  >
                    Add Question From Library
                  </AddButton>
                )}
              </Flex>

              <Rule />
              <div>
                <Button onClick={saveAgenda} color="primary" mr={2}>
                  Save Discussion Points
                </Button>
              </div>
            </>
          )}
        </Box>
        {isComplete &&
          (convo.publicNotes ||
            (convo.privateNotes && canViewPrivateNotes)) && (
            <Box>
              <Text fontSize={4} fontWeight={500} mb={3}>
                Notes
              </Text>
              {convo.publicNotes && (
                <FormTextArea
                  readOnly
                  value={convo.publicNotes}
                  label="Public Notes"
                />
              )}
              {canViewPrivateNotes && convo.privateNotes && (
                <Button
                  mb={showPrivateNotes ? 2 : 3}
                  type="button"
                  onClick={() => setShowPrivateNotes(!showPrivateNotes)}
                >
                  {showPrivateNotes
                    ? "Hide private notes"
                    : "Show private notes"}
                </Button>
              )}
              {showPrivateNotes && (
                <FormTextArea
                  readOnly
                  value={convo.privateNotes}
                  label="Private Notes"
                />
              )}
            </Box>
          )}
        {isComplete && convo.actionItems.length > 0 && (
          <Box>
            <Text fontSize={4} fontWeight={500} mb={3}>
              Action Items
            </Text>
            {convo.actionItems.map((x) => (
              <Flex borderBottom={1} mb={1} alignItems="center" pb={1}>
                <CheckBox
                  value={actionCompletion[x.id]}
                  mb={0}
                  onChange={(val) => changeActionCompletion(x.id, val)}
                />
                <Text
                  fontWeight={500}
                  ml={2}
                  style={{
                    textDecoration: actionCompletion[x.id]
                      ? "line-through"
                      : "none",
                  }}
                >
                  {x.name}
                </Text>
              </Flex>
            ))}
          </Box>
        )}
      </Panel>
    </>
  );
}
