import { v4 as uuid } from "uuid";
import {
  FormItem,
  FormItemSpec,
  FormModel,
  FormModelSpec,
} from "@coaching-culture/types";
import { range } from "lodash";
import React, { useEffect, useState } from "react";
import { FaBinoculars, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import Button from "../Button";
import Flex from "../Flex";
import Text from "../Text";
import { FormItemEditor } from "./FormItemEditor";
import { FormItemList } from "./FormItemList";
import { ItemSelectModal } from "./ItemSelectModal";

const FormItemCreationButton = styled.button`
  height: 68px;
  border: 1px dashed ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.grey6};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey4};
  }

  & svg {
    margin-right: 12px;
  }
`;

function getNewItem(type: FormItemSpec["type"]): FormItemSpec {
  if (type === "content") {
    return {
      type: "content",
      title: "Content Item",
      content: "",
    };
  } else if (type === "free_text") {
    return {
      type: "free_text",
      content: "New Free Text Question",
      fieldType: "multiline",
    };
  } else if (type === "multiple_choice") {
    return {
      type: "multiple_choice",
      content: "New Multiple Choice Question",
      answers: [],
      group: "",
    };
  } else if (type === "rating") {
    return {
      type: "rating",
      content: "New Rating",
      ratingType: "stars",
      requiresEvidence: false,
      highRangeLabel: "",
      allowNa: false,
      reverseScale: false,
      lowRangeLabel: "",
      noEvidenceLabel: "No Opportunity to Observe",
    };
  } else if (type === "heading") {
    return {
      type: "heading",
      content: "New Section",
    };
  } else {
    throw new Error("Invalid type");
  }
}

export type FormEditorProps = {
  form: FormModel | FormModelSpec;
  onChange: (val: FormModelSpec) => void;
  onSave: (val: FormModelSpec) => void;
  onPreview?: (val: FormModelSpec) => void;
  adminMode?: boolean;
  allowEvidence?: boolean;
};

export function FormEditor({
  form,
  onSave,
  onPreview,
  onChange,
  adminMode = false,
  allowEvidence = true,
}: FormEditorProps) {
  const [itemOrder, setItemOrder] = useState<number[]>([]);
  const [modal, setModal] = useState<string>("");
  const [editingItem, setEditingItem] = useState<number | null>(null);

  const getForm = (): FormModelSpec | null => {
    const spec = {
      ...form,
      items: itemOrder.map((x) => form.items[x]),
    };

    if (spec.items.length === 0) {
      window.alert("A form cannot have 0 items");
      return null;
    } else if (spec.items[spec.items.length - 1].type === "heading") {
      window.alert("The last item of a form cannot be a section heading");
      return null;
    }

    return spec;
  };

  const submit = () => {
    const spec = getForm();

    if (spec != null) {
      onSave(spec);
    }
  };

  useEffect(() => {
    if (itemOrder.length === 0 && form.items.length > 0) {
      setItemOrder(range(form.items.length));
    }
  }, [form, itemOrder]);

  const onOrderChange = (newOrder: number[]) => {
    setItemOrder(newOrder);
  };

  const onItemSelect = (itemIndex: number) => {
    setEditingItem(itemIndex);
  };

  const onItemDelete = (itemIndex: number) => {
    setItemOrder((old) =>
      old
        .map((x, i) => (x === itemIndex ? -1 : x > itemIndex ? x - 1 : x))
        .filter((x) => x > -1),
    );
    onChange({
      ...form,
      items: form.items.filter((x, i) => i !== itemIndex),
    });
  };

  const onItemClone = (itemIndex: number) => {
    const i = itemOrder.indexOf(itemIndex);
    const newOrder = [
      ...itemOrder.slice(0, i),
      itemOrder.length,
      ...itemOrder.slice(i),
    ];
    setItemOrder(newOrder);
    onChange({
      ...form,
      items: [
        ...form.items,
        {
          ...form.items[itemIndex],
          id: undefined,
        },
      ],
    });
  };

  const appendItem = (type: FormItem["type"] | null) => {
    setModal("");
    if (type == null) {
      return;
    }
    setItemOrder((old) => [...old, old.length]);

    onChange({
      ...form,
      items: [...form.items, getNewItem(type)],
    });
    setEditingItem(form.items.length);
  };

  const updateEditedItem = (item: FormItemSpec) => {
    onChange({
      ...form,
      items: form.items.map((x, i) => (i === editingItem ? item : x)),
    });
  };

  // We do this so that everything has an id for the preview answers to link up correctly
  const fakeIds = (form: FormModelSpec): FormModel => {
    return {
      ...form,
      id: uuid(),
      items: form.items.map((x) => {
        if (x.type === "multiple_choice") {
          return {
            ...x,
            id: uuid(),
            answers: x.answers.map((x) => ({
              ...x,
              id: uuid(),
            })),
          };
        } else {
          return {
            ...x,
            id: uuid(),
          };
        }
      }),
    };
  };

  const preview = () => {
    if (onPreview != null) {
      const spec = getForm();
      if (spec != null) {
        onPreview(fakeIds(spec));
      }
    }
  };

  if (itemOrder.length !== form.items.length) {
    return null;
  }

  return (
    <>
      {modal === "newitem" && <ItemSelectModal onSelect={appendItem} />}
      {editingItem != null && (
        <FormItemEditor
          onClose={() => setEditingItem(null)}
          value={form.items[editingItem]}
          onChange={updateEditedItem}
          adminMode={adminMode}
          allowEvidence={allowEvidence}
        />
      )}
      <Flex flexDirection="column" mb={5}>
        <Text fontSize={4} mb={2}>
          Form items
        </Text>
        <FormItemList
          items={form.items}
          indexOrder={itemOrder}
          onOrderChange={onOrderChange}
          onItemSelect={onItemSelect}
          onItemClone={onItemClone}
          onItemDelete={onItemDelete}
        />
        <FormItemCreationButton onClick={() => setModal("newitem")}>
          <FaPlus />
          Add Item
        </FormItemCreationButton>
      </Flex>
      <Flex alignItems="center">
        <Button color="primary" type="button" onClick={submit}>
          Save Form
        </Button>
        {onPreview && (
          <Button color="primary" onClick={preview} ml={1} icon={FaBinoculars}>
            Preview Form
          </Button>
        )}
      </Flex>
    </>
  );
}
