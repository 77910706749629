import React, { ComponentPropsWithoutRef } from "react";
import { IconType } from "react-icons";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { IconButton } from "..";

const getColor = (props: any) => {
  return props.theme.colors[props.color] ?? props.color ?? "#f3f3f3";
};

const getTextColor = (props: LabelProps) => {
  if (props.textColor) {
    return props.textColor;
  }

  if (props.color) {
    return "white";
  }

  return "#aaa";
};

export type LabelProps = SpaceProps & {
  size?: "small" | "normal" | "tiny";
  color?: string;
  textColor?: string;
  icon?: IconType;
  onRemove?: () => void;
  children?: React.ReactNode;
} & ComponentPropsWithoutRef<"span">;

const LabelElem = styled.span<LabelProps>`
  background-color: ${(props) => getColor(props)};
  color: ${(props) => getTextColor(props)};
  font-size: ${(props) => (props.size === "tiny" ? 10 : 12)}px;
  font-weight: 600;
  padding: ${(props) =>
    props.size === "small"
      ? "3px 6px"
      : props.size === "tiny"
        ? "2px 6px"
        : "6px 12px"};
  border-radius: 4px;
  word-break: keep-all;
  display: inline-flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
  }

  & + & {
    margin-left: 3px;
  }

  ${space};
`;

LabelElem.defaultProps = {
  size: "normal",
};

const Label = ({
  color,
  icon: Icon,
  onRemove,
  children,
  ...rest
}: LabelProps) => (
  <LabelElem {...rest} color={color}>
    {Icon && <Icon />}
    {children}
    {onRemove && (
      <IconButton
        ml={1}
        icon={FaTimes}
        onClick={onRemove}
        color={color ? "white" : "body"}
      />
    )}
  </LabelElem>
);

export default Label;
