import { UserFilter } from "@coaching-culture/types";
import { Box, Button, Loader, Text } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { FilterModal } from "components/FilterPanel";
import { PageHeader } from "components/PageHeader";
import { sortBy } from "lodash";
import { useAvailableContent } from "queries/mindset";
import { useMemo, useState } from "react";
import { ContentReport } from "./ContentReport";
import { ContentSummaryCharts } from "./ContentSummaryCharts";
import { MatrixReport } from "./MatrixReport";
import { SummaryReport } from "./SummaryReport";

export const MindsetReport = () => {
  const [selectedLesson, setSelectedLesson] = useState<string>("matrix");
  const [showFilters, setShowFilters] = useState(false);
  const content = useAvailableContent();

  const lessons = useMemo(
    () =>
      sortBy(
        (content.data ?? []).filter((x) => x.type === "module"),
        (x) => x.orderIndex,
      ),
    [content.data],
  );
  const noData = content.isFetched && lessons.length === 0;
  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });

  const isSummary = selectedLesson === "summary";
  const isMatrix = selectedLesson === "matrix";
  const loading = content.isLoading;

  const lessonOptions = [
    {
      value: "summary",
      label: "Summary By Module",
    },
    {
      value: "matrix",
      label: "Summary By User",
    },
    ...lessons.map((x) => ({
      value: x.id.toString(),
      label: x.name,
    })),
  ];

  if (noData) {
    return (
      <Box>
        <Text textAlign="center" fontSize={4}>
          No Modules Available
        </Text>
      </Box>
    );
  }

  const commonProps = {
    options: lessonOptions,
    selected: selectedLesson,
    filter,
    type: "module",
    onChange: setSelectedLesson,
    onFilterChange: setFilter,
  } as const;

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        filter={filter}
        onClose={(result) => {
          if (result != null) {
            setFilter(result);
          }
          setShowFilters(false);
        }}
      />
      <CenterColumn>
        <PageHeader
          text="Insights"
          subtitle="An overview of the data collected by the platform"
        >
          <Button
            ml={[0, 2]}
            color="primary"
            onClick={() => setShowFilters(true)}
          >
            Filters ({filter.groups.length + filter.userFields.length})
          </Button>
        </PageHeader>
        <Box mb={3}>
          <Text mb={2} fontSize={5}>
            Summary
          </Text>
          <ContentSummaryCharts type="module" filter={filter} />
        </Box>
        <Box>
          <Text mb={2} fontSize={5}>
            Modules
          </Text>
          {loading && <Loader overlay />}
          {isSummary ? (
            <SummaryReport {...commonProps} />
          ) : isMatrix ? (
            <MatrixReport {...commonProps} content={lessons} type="module" />
          ) : (
            <ContentReport {...commonProps} contentId={selectedLesson} />
          )}
        </Box>
      </CenterColumn>
    </>
  );
};
