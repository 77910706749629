import React, { AriaAttributes } from "react";
import { IconType } from "react-icons/lib";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { lighten } from "polished";

const getColor = (props: any, defaultColor?: string) =>
  props.theme.colors[props.color] ||
  props.color ||
  defaultColor ||
  props.theme.colors.grey3;

const ButtonElem = styled.button<SpaceProps & { color?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 1px;
  background: none;
  border: 0;
  cursor: pointer;

  color: ${(props) => lighten(0.2, getColor(props))};

  &:hover {
    color: ${(props) => getColor(props, props.theme.colors.body)};
  }

  &:disabled {
    color: ${(props) => lighten(0.5, getColor(props))};
    cursor: default;
  }

  ${space};
`;

ButtonElem.defaultProps = {
  type: "button",
};

const FilledButtonElem = styled(ButtonElem)`
  background-color: ${(props) => getColor(props)};
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: ${(props) => lighten(0.1, getColor(props))};
    color: white;
  }
`;

export type IconButtonProps = React.ComponentPropsWithoutRef<"button"> &
  SpaceProps & {
    color?: string;
    icon: IconType;
    buttonStyle?: "filled" | "normal";
  };

export const IconButton = ({
  icon: Icon,
  buttonStyle = "normal",
  children,
  ...rest
}: IconButtonProps) => {
  const Elem = buttonStyle === "filled" ? FilledButtonElem : ButtonElem;

  return (
    <Elem {...rest}>
      <Icon />
    </Elem>
  );
};
