import {
  CampaignForward,
  FeedbackInsights,
  FormResponses,
  ManagerCampaign,
  RatingFormItemResponses,
  RatingType,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  Label,
  Panel,
  PanelHeader,
  Rule,
  ShareLink,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import {
  FeedbackInsightsDisplay,
  groupBySection,
} from "components/FeedbackInsights";
import { TabPane, Tabs } from "components/Tabs";
import { format } from "date-fns";
import { meanBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { useTheme } from "styled-components";
import { defaultChartOptions } from "utils/chartJsUtil";
import { CampaignInsightsWrap } from "./CampaignInsightsWrap";
import { FeedbackQuestionList } from "./FeedbackQuestionsList";
import { ShareForm, ShareFormData } from "./ShareForm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const sectionOptions = defaultChartOptions(
  { label: "SCORE", max: 10, min: 0, stepSize: 1 },
  { label: "SECTION" },
);

const questionOptions = defaultChartOptions(
  { label: "SCORE", max: 10, min: 0, stepSize: 1 },
  { label: "QUESTION" },
);

export type UserCampaignDisplayProps = {
  item: ManagerCampaign;
  onNudge: (id: string) => void;
  onShare: (spec: ShareFormData) => void;
};

const respondentSearch = (item: ManagerCampaign["forwards"][number]) =>
  [item.name, item.email].join(" ");

export const normalizeRating = (
  rating: number,
  ratingType: RatingType,
): number => {
  return ratingType === "stars" || ratingType === "smiles"
    ? rating * 2
    : ratingType === "dots"
      ? +((rating * 10) / 7).toFixed(2)
      : rating;
};

export const UserCampaignDisplay = ({
  item,
  onNudge,
  onShare,
}: UserCampaignDisplayProps) => {
  const [user] = useUser();
  const [insights, setInsights] = useState<
    FeedbackInsights | false | "not-available-yet" | null
  >(null);
  const [modal, setModal] = useState<string>("");
  const theme = useTheme();

  const items =
    insights != null && insights !== false && insights !== "not-available-yet"
      ? insights.items
      : insights;

  const groupedSections = useMemo(() => {
    if (items != null && items !== "not-available-yet" && insights) {
      return groupBySection(items as FormResponses[]);
    }
  }, [items, insights]);

  const userResponded = item.forwards.some(
    (x) => x.email === item.user.email && x.completed != null,
  );

  useEffect(() => {
    setInsights(null);
    axios
      .get(`/api/feedback/user-campaigns/${item.userCampaignId}/insights`)
      .then(({ data }) => {
        setInsights(data);
      })
      .catch(() => {
        setInsights(false);
      });
  }, [item.userCampaignId]);

  const rels = item.relationships;

  const cols = useMemo<SortableTableColumn<CampaignForward>[]>(() => {
    return (
      [
        {
          name: "name",
          label: "Name",
          format: (x) => x.name ?? "<Unknown>",
        },
        {
          name: "relationship",
          label: "Relationship",
          format: (x) =>
            x.relationship == null ? "<Not Completed>" : x.relationship.name,
          sortFormat: (x) =>
            x.relationship == null ? "" : x.relationship.name,
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "sentOn",
          label: "Sent On",
          format: (x) => format(new Date(x.sentOn), "yyyy-MM-dd"),
        },
        {
          name: "completed",
          label: "Completed",
          format: (x) =>
            x.completed ? (
              format(new Date(x.completed), "yyyy-MM-dd")
            ) : (
              <Flex alignItems="center" justifyContent="space-between">
                Not yet
                {item.user.id === user.id && (
                  <Button
                    ml={2}
                    tiny
                    onClick={() => onNudge(x.id)}
                    disabled={x.lastNudged != null}
                  >
                    {x.lastNudged == null ? "Nudge" : "Nudged"}
                  </Button>
                )}
              </Flex>
            ),
        },
      ] as SortableTableColumn<CampaignForward>[]
    ).filter((x) => item?.collectRelationship || x.name !== "relationship");
  }, [item, user, onNudge]);

  const canInvite = user.id === item.user.id && !item.archived;

  const share = (data: ShareFormData) => {
    setModal("");
    onShare(data);
  };

  const getUserResponses = (section: any) => {
    let responses: number[] = [];
    if (groupedSections != null && userResponded) {
      (section.items as FormResponses[])
        .filter((x) => x.type === "rating")
        .forEach((y) => {
          const question = y as RatingFormItemResponses;
          const userResponse = question.responses.find(
            (x) => x.email === item.user.email,
          );
          if (userResponse != null) {
            const value = question.responses.find(
              (x) => x.email === item.user.email,
            ).rating;
            responses.push(normalizeRating(value, question.ratingType));
          } else {
            responses.push(0);
          }
        });
    }
    return responses;
  };

  const getUserSectionAverages = () => {
    if (groupedSections != null && userResponded) {
      const responses = groupedSections.map((section) => {
        let total = 0;
        const ratingQuestions = section.items.filter(
          (x) => x.type === "rating",
        );

        ratingQuestions.forEach((question) => {
          const temp = question as RatingFormItemResponses;
          total += normalizeRating(
            temp.responses.find((x) => x.email === item.user.email).rating,
            temp.ratingType,
          );
        });
        return +(total / ratingQuestions.length).toFixed(2);
      });
      return responses;
    }
    return [];
  };

  return (
    <>
      {modal === "recipient" && (
        <ShareForm
          onCancel={() => setModal("")}
          onShare={share}
          defaultSubject={item.respondentEmailSubject}
          defaultText={item.respondentEmailTemplate.replace(
            /\*\|SENDERS_NAME\|\*/g,
            user.name,
          )}
        />
      )}
      <Tabs>
        <TabPane defaultValue={"Campaign Overview"}>
          <Panel mb={3}>
            <PanelHeader>
              <Text fontWeight={600} fontSize={3}>
                Campaign Overview
              </Text>
            </PanelHeader>

            <Box style={{ overflowX: "auto" }}>
              <Box p={3}>
                <Flex mb={3} alignItems={"center"} style={{ gap: 3 }}>
                  <Text mr={3} fontWeight={500}>
                    Report Visible To:
                  </Text>
                  {item.resultsVisibility.includes("admin") && (
                    <Label color="primary">Admins</Label>
                  )}
                  {item.resultsVisibility.includes("manager") && (
                    <Label color="primary">User's Manager</Label>
                  )}
                  {item.resultsVisibility.includes("self") && (
                    <Label color="primary">The User</Label>
                  )}
                  {item.viewers.map((x) => (
                    <Label icon={FaUser}>{x.name}</Label>
                  ))}
                </Flex>
                {!item.archived && (
                  <Flex alignItems={"center"} width={"100%"} mb={1}>
                    <Text mr={3} fontWeight={500}>
                      Share Link:
                    </Text>
                    <ShareLink
                      url={`${process.env.REACT_APP_PLATFORM_ROOT}/feedback?survey=${item.masterCode}`}
                    />
                  </Flex>
                )}
                <Rule />
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Flex justifyContent={"flex-start"}>
                    <Text fontSize={4} fontWeight={500}>
                      Respondents
                    </Text>
                  </Flex>

                  {canInvite && (
                    <Flex justifyContent={"flex-end"}>
                      <Button
                        icon={FaUserPlus}
                        color="primary"
                        onClick={() => setModal("recipient")}
                      >
                        Invite Respondents
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Box>
              <SortableTable
                columns={cols}
                data={item.forwards}
                emptyMessage="Not sent to any recipients yet."
                paged
                rowSearch={respondentSearch}
              />
            </Box>
          </Panel>
        </TabPane>
        <TabPane defaultValue={"Graph View"}>
          <CampaignInsightsWrap
            insights={insights}
            render={() => (
              <>
                {groupedSections.length > 1 && (
                  <Panel mb={3}>
                    <PanelHeader>
                      <Text fontWeight={600} fontSize={3}>
                        Average Scores - All Sections
                      </Text>
                    </PanelHeader>
                    <Box p={3}>
                      <Bar
                        options={{
                          ...(sectionOptions as any),
                          ...{
                            indexAxis: "y" as const,
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  label: (yDatapoint) => {
                                    return yDatapoint.raw;
                                  },
                                },
                              },
                            },
                          },
                        }}
                        height={150 * groupedSections.length + 150}
                        data={{
                          labels: groupedSections
                            .filter((section) =>
                              section.items.some((x) => x.type === "rating"),
                            )
                            .map((item) =>
                              item.heading != null
                                ? item.heading?.content
                                : "No Section",
                            ),
                          datasets: [
                            {
                              xAxisID: "x1",
                              yAxisID: "y1",
                              label: "Respondent Average Score",
                              backgroundColor: theme.colors.primary,
                              barThickness: 40,
                              data: groupedSections
                                .filter((section) =>
                                  section.items.some(
                                    (x) => x.type === "rating",
                                  ),
                                )
                                .map((section) => {
                                  let total = 0;
                                  const ratingQuestions = section.items.filter(
                                    (x) => x.type === "rating",
                                  );

                                  ratingQuestions.forEach((question) => {
                                    const questionAverage = meanBy(
                                      (
                                        question as RatingFormItemResponses
                                      ).responses.filter(
                                        (x) =>
                                          x.rating != null &&
                                          x.email !== item.user.email,
                                      ),
                                      (res) => {
                                        return normalizeRating(
                                          (res as any).rating,
                                          (question as any).ratingType,
                                        );
                                      },
                                    );
                                    total += questionAverage;
                                  });

                                  return +(
                                    total / ratingQuestions.length
                                  ).toFixed(2);
                                }),
                            },
                            {
                              xAxisID: "x1",
                              yAxisID: "y1",
                              label: "My Score (" + item.user.email + ")",
                              backgroundColor: theme.colors.body,
                              barThickness: 40,
                              data: getUserSectionAverages(),
                            },
                          ],
                        }}
                      />
                    </Box>
                  </Panel>
                )}

                {groupedSections
                  .filter((section) =>
                    section.items.some((x) => x.type === "rating"),
                  )
                  .map((section, i) => (
                    <Panel mb={3}>
                      <PanelHeader>
                        <Text fontWeight={600} fontSize={3}>
                          Average Scores -{" "}
                          {section.heading != null
                            ? section.heading.content
                            : "No Section"}
                        </Text>
                      </PanelHeader>
                      <Box p={3}>
                        <Bar
                          options={{
                            ...(questionOptions as any),
                            ...{
                              indexAxis: "y" as const,
                              plugins: {
                                tooltip: {
                                  callbacks: {
                                    label: (yDatapoint) => {
                                      return yDatapoint.raw;
                                    },
                                  },
                                },
                              },
                            },
                          }}
                          height={
                            150 *
                              section.items.filter((x) => x.type === "rating")
                                .length +
                            150
                          }
                          data={{
                            labels: section.items
                              .filter((x) => x.type === "rating")
                              .map((item) => item.content),
                            datasets: [
                              {
                                xAxisID: "x1",
                                yAxisID: "y1",
                                label: "Respondent Average Score",
                                backgroundColor: theme.colors.primary,
                                barThickness: 40,
                                data: section.items
                                  .filter((x) => x.type === "rating")
                                  .map(
                                    (response) =>
                                      +meanBy(
                                        (response as any).responses.filter(
                                          (x) =>
                                            x.rating != null &&
                                            x.email !== item.user.email,
                                        ),
                                        (y) =>
                                          normalizeRating(
                                            (y as any).rating,
                                            (response as any).ratingType,
                                          ),
                                      ).toFixed(2),
                                  ),
                              },
                              {
                                xAxisID: "x1",
                                yAxisID: "y1",
                                label: "My Score (" + item.user.email + ")",
                                backgroundColor: theme.colors.body,
                                barThickness: 40,
                                data: getUserResponses(section),
                              },
                            ],
                          }}
                        />
                      </Box>
                    </Panel>
                  ))}
              </>
            )}
          />
        </TabPane>
        <TabPane defaultValue={"List View"}>
          <CampaignInsightsWrap
            insights={insights}
            render={() => (
              <FeedbackQuestionList
                sections={groupedSections}
                campaignUserEmail={item.user.email}
              />
            )}
          />
        </TabPane>
        <TabPane defaultValue={"Details"}>
          <CampaignInsightsWrap
            insights={insights}
            render={() => (
              <FeedbackInsightsDisplay
                insights={items as any}
                relationships={rels}
                highlightedUser={item.user}
                showResponses
                campaign={item}
                title={item.name}
              />
            )}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
