import styled from "styled-components";
import {
  grid,
  space,
  layout,
  GridProps,
  SpaceProps,
  LayoutProps
} from "styled-system";

type MyGridProps = GridProps & SpaceProps & LayoutProps;

export const Grid = styled.div<MyGridProps>`
  ${grid};
  ${space};
  ${layout};
  display: grid;
`;

export default Grid;
