import {
  ContentSummaryReportItem,
  ContentType,
  UserFilter,
} from "@coaching-culture/types";
import {
  Box,
  Circle,
  Flex,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import StarRating from "components/StarRating";
import Papa from "papaparse";
import qsStringify from "qs-stringify";
import { useQuery } from "react-query";
import { downloadContent } from "utils";
import { ReportProps, ReportSelector } from "./ReportSelector";
import { sortBy } from "lodash";

type SummaryReportProps = ReportProps & {
  filter: UserFilter;
  type: ContentType;
};

const summaryCols: SortableTableColumn<ContentSummaryReportItem>[] = [
  {
    name: "name",
    label: "Name",
    format: (x) => (
      <Flex alignItems="center">
        <Circle
          mr={2}
          image={process.env.REACT_APP_STATIC_ROOT + "/" + x.icon}
        />
        <Flex flexDirection={"column"}>
          <Text color="grey1" fontSize={2}>
            {x.category}
          </Text>
          <Text fontWeight={600}>{x.name}</Text>
        </Flex>
      </Flex>
    ),
    sortFormat: (x) => [x.category, x.name].join(" "),
  },
  {
    name: "averageRating",
    label: "Avg. Rating",
    format: (x) => <StarRating value={x.averageRating} />,
  },
  {
    name: "usersAvailable",
    label: "Not Started",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
    format: (x) => x.usersAvailable - (x.usersStarted + x.usersCompleted),
  },
  {
    name: "usersStarted",
    label: "Started",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
  },
  {
    name: "usersCompleted",
    label: "Completed",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
  },
  {
    name: "%completed",
    label: "% Completed",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
    format: (x) =>
      Math.floor((x.usersCompleted / x.usersAvailable) * 100) + "%",
    sortFormat: (x) => Math.floor((x.usersCompleted / x.usersAvailable) * 100),
  },
];

export const useSummaryReport = ({
  filter,
  type,
}: {
  filter: UserFilter;
  type: ContentType;
}) => {
  return useQuery(["summaryReport", type, filter], () => {
    const filterString = qsStringify({ filter } as any);
    const t = type === "module" ? "mindset" : type;
    const url = `/api/insights/${t}/summary?${filterString}`;
    return axios.get<ContentSummaryReportItem[]>(url).then(({ data }) => data);
  });
};

export const SummaryReport = ({
  filter,
  type,
  ...rest
}: SummaryReportProps) => {
  const { data = [], isFetched } = useSummaryReport({ filter, type });

  const handleExport = () => {
    const csvData = sortBy(data, (x) => x.category).map((x) => ({
      category: x.category,
      module: x.name,
      notStarted: x.usersAvailable - (x.usersStarted + x.usersCompleted),
      started: x.usersStarted,
      completed: x.usersCompleted,
      averageRating: x.averageRating,
      "%completed":
        Math.floor((x.usersCompleted / x.usersAvailable) * 100) + "%",
    }));

    const csv = Papa.unparse(csvData, { delimiter: ",", header: true });
    downloadContent(csv, "report.csv");
  };

  return (
    <>
      <ReportSelector {...rest} filter={filter} onExport={handleExport} />
      <Panel>
        <Box style={{ overflowX: "auto", position: "relative" }}>
          {!isFetched && <Loader overlay />}
          <SortableTable<ContentSummaryReportItem>
            key="SummaryTable"
            data={data}
            columns={summaryCols}
            emptyMessage="No Data Available"
            paged
          />
        </Box>
      </Panel>
    </>
  );
};
