import {
  MeasureDto,
  MeasuresConfigurationDto,
  MeasureSpec,
  UpdateUserMeasureDto,
  UserFilter,
  UserMeasuresDto,
  UserMeasuresTrendsDto,
} from "@coaching-culture/types";
import axios from "axios";
import { getUnixTime } from "date-fns";
import queryStringify from "qs-stringify";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getMeasures = () =>
  axios.get<MeasureDto[]>("/api/v2/measures").then(({ data }) => data);

const getMeasuresConfig = () =>
  axios
    .get<MeasuresConfigurationDto>("/api/v2/measures-config")
    .then(({ data }) => data);

const updateMeasuresConfig = (spec: MeasuresConfigurationDto) =>
  axios.put("/api/v2/measures-config", spec);

const getUserMeasures = (userId: string) =>
  axios
    .get<UserMeasuresDto[]>(`/api/v2/users/${userId}/measures`)
    .then(({ data }) => data);

const getAllUserMeasures = (filter?: UserFilter) =>
  axios
    .get<UserMeasuresDto[]>(
      `/api/v2/user-measures?${queryStringify({ filter } as any)}`
    )
    .then(({ data }) => data);

const getMeasuresTrends = (relationshipId: string) =>
  axios
    .get<UserMeasuresTrendsDto>(
      `/api/v2/relationships/${relationshipId}/measures`
    )
    .then(({ data }) => data);

const updateMeasure = (spec: MeasureSpec) =>
  axios.put(`/api/v2/measures/${spec.id}`, spec);

const createMeasure = (spec: MeasureSpec) =>
  axios.post(`/api/v2/measures`, spec);

const deleteMeasure = (id: string) => axios.delete(`/api/v2/measures/${id}`);

const updateUserMeasures = (spec: UpdateUserMeasureDto) =>
  axios.post(`/api/v2/relationships/${spec.relationshipId}/measures`, spec);

export const useMeasures = () => useQuery(["measures"], getMeasures);

export const useRelationshipMeasures = (relationshipId: string) =>
  useQuery(["relationships", relationshipId, "measures"], () =>
    getMeasuresTrends(relationshipId)
  );

export const useMeasuresConfig = () =>
  useQuery(["measures", "config"], getMeasuresConfig);

export const useUpdateMeasuresConfig = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: MeasuresConfigurationDto) => updateMeasuresConfig(spec),
    {
      onError: () => {
        queryClient.invalidateQueries(["measures"]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["measures"]);
      },
    }
  );

  return mutation;
};

export const useUserMeasures = (userId: string) =>
  useQuery(["measures", userId], () => getUserMeasures(userId));

export const useAllUserMeasures = (filter?: UserFilter) =>
  useQuery(["measures", "all", filter], () => getAllUserMeasures(filter));

export const useUpdateUserMeasures = (userId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: UpdateUserMeasureDto) => updateUserMeasures(spec),
    {
      onMutate: (spec: UpdateUserMeasureDto) => {
        queryClient.setQueryData(
          ["measures", userId],
          (data: UserMeasuresDto[]) => {
            return data.map((x) => {
              if (x.relationshipId !== spec.relationshipId) {
                return x;
              }

              return {
                ...x,
                measures: [
                  ...x.measures.filter((m) => m.id !== spec.measureId),
                  {
                    id: spec.measureId,
                    lastSet: getUnixTime(new Date()),
                    value: spec.optionId,
                  },
                ],
              };
            });
          }
        );
      },
      onError: () => {
        queryClient.invalidateQueries(["measures", userId]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["measures", userId]);
        queryClient.invalidateQueries(["actions"]);
      },
    }
  );

  return mutation;
};

export const useUpdateMeasure = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((spec: MeasureSpec) => updateMeasure(spec), {
    onError: () => {
      queryClient.invalidateQueries(["measures"]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["measures"]);
    },
  });

  return mutation;
};

export const useCreateMeasure = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((spec: MeasureSpec) => createMeasure(spec), {
    onError: () => {
      queryClient.invalidateQueries(["measures"]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["measures"]);
    },
  });

  return mutation;
};

export const useDeleteMeasure = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => deleteMeasure(id), {
    onError: () => {
      queryClient.invalidateQueries(["measures"]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["measures"]);
    },
  });

  return mutation;
};
