import { AssessmentCompletionSummary } from "@coaching-culture/types";
import { Button, Flex, Img, Loader, Panel, Text } from "@coaching-culture/ui";
import axios from "axios";
import { PageHeader } from "components/PageHeader";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { formatDate } from "utils/dates";
import blankSrc from "../../../img/blank.svg";

function Completion({
  completion,
}: {
  completion: AssessmentCompletionSummary;
}) {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderBottom={1}
    >
      <Flex alignItems="center">
        <Img
          mr={2}
          width="50px"
          src={
            process.env.REACT_APP_STATIC_ROOT + "/" + completion.assessment.icon
          }
        />
        <Flex flexDirection="column">
          <Text fontWeight={600}>{completion.assessment.name}</Text>
          <Text fontWeight={500} color="grey1">
            Completed on {formatDate(completion.completedOn, "dd/MM/yyyy")}
          </Text>
        </Flex>
      </Flex>
      <Button
        color="primary"
        to={`/solutions/assessments/modules/${completion.assessment.slug}/results/${completion.id}`}
      >
        See Results
      </Button>
    </Flex>
  );
}

export function PreviousResults() {
  const [items, setItems] = useState<AssessmentCompletionSummary[] | null>(
    null
  );

  useEffect(() => {
    axios.get(`/api/users/me/assessment-completions`).then(({ data }) => {
      setItems(data);
    });
  }, []);

  return (
    <>
      <PageHeader
        text="Previous Results"
        subtitle="Results from your previous assessments"
      />
      <Panel>
        {items == null ? (
          <Loader />
        ) : items.length === 0 ? (
          <Flex alignItems="center" flexDirection="column" p={7}>
            <Img src={blankSrc} width="60%" mb={6} />
            <Text fontSize={5} fontWeight={500} mb={3}>
              No Results Yet
            </Text>
            <Button to="/solutions/assessments" color="primary">
              Take an Assessment
            </Button>
          </Flex>
        ) : (
          sortBy(items, "completedOn")
            .reverse()
            .map((x) => <Completion completion={x} />)
        )}
      </Panel>
    </>
  );
}
