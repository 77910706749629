import { MeasureDto, UserMeasuresTrendsDto } from "@coaching-culture/types";
import { NoData } from "components/NoData";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import { groupBy, maxBy, min, range, sortBy } from "lodash";
import styled from "styled-components";

const SECONDS_IN_A_DAY = 60 * 60 * 24;

export type MeasureTrendChartProps = {
  measure: MeasureDto;
  data: UserMeasuresTrendsDto;
};

const Axis = styled.line`
  stroke: ${(props) => props.theme.colors.grey3};
`;

const AxisLabel = styled.text`
  font-size: 9px;
  fill: ${(props) => props.theme.colors.primary};
  font-weight: 600;
`;

const XAxisLabel = styled.text`
  font-size: 9px;
  fill: ${(props) => props.theme.colors.body};
  font-weight: 600;
`;

const TrendLine = styled.path`
  stroke: ${(props) => props.theme.colors.primary};
  fill: none;
  stroke-width: 2;
`;

const Chart = styled.svg`
  padding-top: 30px;
  padding-bottom: 30px;
`;
const UnderTrend = styled.path`
  stroke: ${(props) => props.theme.colors.primary};
  stroke-width: 10;
  fill: transparent;
  filter: url(#strong-inner);
`;

const CirclePoint = styled.circle`
  stroke: ${(props) => props.theme.colors.primary};
  stroke-width: 3;
  fill: white;
`;

type Point = [number, number];

export const MeasureTrendChart = ({
  measure,
  data,
}: MeasureTrendChartProps) => {
  const graphHeight = 300;

  const measureData = data.measures.find((x) => x.id === measure.id);

  if (measureData == null || measureData.values.length === 0) {
    return <NoData />;
  }

  const minDate = min(measureData.values.map((x) => x.dateSet)) ?? 0;

  const options = measure.options;

  const optionYs = options.map(
    (_, i, self) => ((graphHeight - 50) / (self.length - 1)) * i,
  );

  const aggregatedPoints = Object.values(
    groupBy(measureData.values, (x) =>
      Math.floor(x.dateSet / SECONDS_IN_A_DAY),
    ),
  ).map((x) => maxBy(x, (x) => x.dateSet));

  const points: Point[] = sortBy(aggregatedPoints, (x) => x.dateSet).map(
    (item) => {
      const r = getUnixTime(new Date()) - minDate;
      const t = (item.dateSet - minDate) / r;
      const x = 100 + 500 * t;
      const index = options.findIndex((x) => x.id === item.value);
      const y = optionYs[index];
      return [x, y];
    },
  );

  const pathPoints = [...points, [600, points[points.length - 1][1]]];

  const underPathPoints = [[100, 250], ...pathPoints, [600, 250]];
  const gradPoints = [
    [0, 1000],
    [0, pathPoints[0][1]],
    ...pathPoints,
    [1000, pathPoints[pathPoints.length - 1][1]],
    [1000, 1000],
  ];

  const path = `M ${pathPoints.flat().join(" ")}`;
  const underPath = `M ${underPathPoints.flat().join(" ")}`;
  const gradPath = `M ${gradPoints.flat().join(" ")}`;

  const xAxis = range(0, 6)
    .map((x) => minDate + (getUnixTime(new Date()) - minDate) * (x / 5))
    .map((x) => format(fromUnixTime(x), "dd MMM yy"));

  return (
    <Chart viewBox={`0 -10 625 ${graphHeight}`}>
      <linearGradient id="undertrend" x1="0" x2="0" y1="0" y2="1">
        <stop className="stop1" offset="0%" />
        <stop className="stop2" offset="25%" />
      </linearGradient>
      <defs>
        <filter id="strong-inner">
          <feGaussianBlur stdDeviation="20" />
          <feMorphology operator="dilate" radius="-4" />
        </filter>
        <mask id="mask">
          <path d={underPath} fill="white" />
        </mask>
      </defs>
      <g>
        {options.map((x, i) => (
          <g>
            <AxisLabel
              x={80}
              y={optionYs[i]}
              alignmentBaseline="middle"
              textAnchor="end"
            >
              {x.name}
            </AxisLabel>
            <Axis x1={100} y1={optionYs[i]} x2={600} y2={optionYs[i]} />
          </g>
        ))}
        <TrendLine d={path} />
        <UnderTrend d={gradPath} mask="url(#mask)" />
      </g>
      <g>
        {points.map((x) => (
          <CirclePoint cx={x[0]} cy={x[1]} r={6} />
        ))}
      </g>
      <g>
        {xAxis.map((x, i) => (
          <g>
            <Axis
              x1={100 + (500 / 5) * i}
              x2={100 + (500 / 5) * i}
              y1={optionYs[optionYs.length - 1]}
              y2={optionYs[optionYs.length - 1] + 4}
            />
            <XAxisLabel
              y={graphHeight - 25}
              x={100 + (500 / 5) * i}
              textAnchor="middle"
            >
              {x}
            </XAxisLabel>
          </g>
        ))}
      </g>
    </Chart>
  );
};
