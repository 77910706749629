import {
  CampaignSpec,
  FeedbackFormSummary,
  FeedbackResultsVisibility,
  UserSummary,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  CheckBox,
  ControlLabel,
  Flex,
  FormInput,
  FormInputWrap,
  FormTextArea,
  FormToggle,
  IconButton,
  IconProgress,
  ImageUploader,
  Input,
  Label,
  MultiToggle,
  Panel,
  RichTextEditor,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import {
  FaClipboardCheck,
  FaCogs,
  FaEnvelope,
  FaInfoCircle,
  FaMailBulk,
  FaTrash,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FeedbackFormSelector } from "./FeedbackFormSelector";
import { UserSelectionTable } from "./UserSelectionTable";
import { UserSelectModal } from "./UserSelectModal";

const defaultEmail = `
<h2>It's time to get some feedback</h2>
<p>
  As part of your continued professional development, we’d like you to carry out a 360 degree feedback survey.
</p>

<h3>What is it?</h3>
<p>
  A 360 feedback survey is a survey which you can send to people all around you to get feedback. People such as your line manager, direct reports, peers and anyone else who you interact at work.
</p>

<h3>Why?</h3>
<p>
  Feedback is one of the most effective ways to raising your self-awareness and finding out your strengths and weaknesses. By asking for feedback you will be able to make improvements to your performance.
</p>

<h3>How?</h3>
<p>
  To get started, simply click on the button below and follow the instructions.
</p>
`;

const defaultRespondentEmail = `
<h2>Please give me some feedback!</h2>
<p>
  I'd like you to fill out a survey on my performance. Please click the link below to start.
</p>

<p>
Many thanks
<br>
*|SENDERS_NAME|*
</p>
`;

export type Recipient = {
  id: string;
  name: string;
  email: string;
};

const emailMergeFields = [
  {
    description: "User's First Name",
    value: "*|FNAME|*",
  },
  {
    description: "User's Last Name",
    value: "*|LNAME|*",
  },
  {
    description: "User's Email",
    value: "*|EMAIL|*",
  },
  {
    description: "Platform URL",
    value: "https://platform.coachingculture.com",
  },
];

const respondentMergeFields = [
  {
    description: "Senders Name",
    value: "*|SENDERS_NAME|*",
  },
];

type CampaignState = {
  name: string;
  description: string;
  thumbnail: string | null;
  feedbackForm: FeedbackFormSummary;
  anonType: "anonymous" | "transparent";
  minimumForwards: number | null;
  resultsVisibility: FeedbackResultsVisibility[];
  recipients: Recipient[];
  email: string;
  emailSubject: string;
  respondentEmail: string;
  respondentEmailSubject: string;
  relationships: string[] | null;
  viewers: UserSummary[];
  sendEmail: boolean;
};

const defaultRelationships = ["Direct Report", "Line Manager", "Coach"];

export default function CreateCampaign() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [spec, setSpec] = useState<Partial<CampaignState>>({
    name: "",
    description: "",
    thumbnail: null,
    anonType: "transparent",
    minimumForwards: 1,
    resultsVisibility: [],
    recipients: [],
    email: defaultEmail,
    emailSubject: "It's time to get some feedback",
    respondentEmail: defaultRespondentEmail,
    respondentEmailSubject: "Please give me some feedback",
    relationships: null,
    sendEmail: true,
    viewers: [],
  });
  const [step, setStep] = useState<number>(0);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [allowSpecificUsers, setAllowSpecificUsers] = useState<boolean>(false);
  const [addingViewers, setAddingViewers] = useState(false);

  const submit = (v: Partial<CampaignState>, isDraft: boolean) => {
    const values = v as CampaignState;

    const spec: CampaignSpec = {
      description: values.description,
      image: values.thumbnail,
      anonType: values.anonType,
      feedbackFormId: values.feedbackForm.id,
      minimumForwards: values.minimumForwards,
      name: values.name,
      users: values.recipients.map((x) => x.id),
      resultsVisibility: v.resultsVisibility,
      email: v.email,
      emailSubject: v.emailSubject,
      respondentEmail: v.respondentEmail,
      respondentEmailSubject: v.respondentEmailSubject,
      relationships:
        v.relationships != null
          ? v.relationships.map((x) => ({ name: x, id: null }))
          : [],
      viewers: v.viewers,
      sendEmail: v.sendEmail,
      isDraft: isDraft,
    };

    setSubmitting(true);

    axios
      .post("/api/v2/feedback-campaigns", spec)
      .then(({ data }) => {
        history.push(`/success/feedback/campaigns/${data.id}`);
      })
      .catch((err) => {
        setSubmitting(false);
        window.alert("There was an error");
      });
  };

  const setValue = (key: keyof CampaignState) => (val: any) => {
    if (key === "anonType" && val === "anonymous") {
      setSpec((old) => ({
        ...old,
        minimumForwards: Math.max(5, old.minimumForwards),
      }));
    }
    setSpec((old) => ({
      ...old,
      [key]: val,
    }));
    setErrors((old) => ({
      ...old,
      [key]: null,
    }));
  };

  const calcErrors = () => {
    if (step === 0) {
      return {
        feedbackForm: spec.feedbackForm == null ? "A form is required" : null,
      };
    } else if (step === 1) {
      return {
        name: spec.name ? null : "Name is required",
        minimumForwards:
          spec.anonType === "anonymous" && spec.minimumForwards < 5
            ? "At least 5 respondents are needed for anonymous campaigns"
            : null,
        resultsVisibility:
          spec.resultsVisibility.length === 0 && spec.viewers.length === 0
            ? "At least one is required"
            : null,
      };
    }
    return {};
  };

  const advance = (isDraft: boolean) => {
    const errors = calcErrors();

    if (Object.values(errors).some((x) => x != null)) {
      setErrors(errors);
    } else if (step === 2) {
      if (spec.recipients.length === 0) {
        window.alert("At least one user is required");
      } else {
        setStep((old) => old + 1);
      }
    } else if (step < 4) {
      setErrors({});
      setStep((old) => old + 1);
    } else {
      submit(spec, isDraft);
    }
  };

  const setVisibility = (name: FeedbackResultsVisibility) => (val: boolean) => {
    setSpec((old) => ({
      ...old,
      resultsVisibility: val
        ? old.resultsVisibility.concat([name])
        : old.resultsVisibility.filter((x) => x !== name),
    }));
    setErrors((old) => ({
      ...old,
      resultsVisibility: null,
    }));
  };

  const setRelationship =
    (idx: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      const v = ev.target.value;
      setSpec((old) => ({
        ...old,
        relationships: old.relationships.map((x, i) => (i === idx ? v : x)),
      }));
    };

  const removeRelationship = (idx: number) => {
    setSpec((old) => ({
      ...old,
      relationships:
        old.relationships.length === 1
          ? null
          : old.relationships.filter((x, i) => i !== idx),
    }));
  };

  const addRelationship = () => {
    setSpec((old) => ({
      ...old,
      relationships: [...old.relationships, ""],
    }));
  };

  const setSpecificUsers = (val: boolean) => {
    setAllowSpecificUsers(val);
  };

  const addViewers = (users: UserSummary[]) => {
    if (users) {
      setValue("viewers")(uniqBy(users.concat(spec.viewers), "id"));
    }
    setAddingViewers(false);
  };

  const removeViewer = (id: string) => {
    setValue("viewers")(spec.viewers.filter((x) => x.id !== id));
  };

  return (
    <CenterColumn>
      {addingViewers && <UserSelectModal onSelect={addViewers} />}
      <Box p={[2, 5]} pt={5}>
        <Text textAlign="center" mb={5} fontSize={[5, 6]}>
          <Link to="/success/feedback">Feedback</Link> / Create Feedback
          Campaign
        </Text>
        <Panel p={3}>
          <Flex mb={5} mt={2} width="80%" maxWidth="500px" ml="auto" mr="auto">
            <IconProgress
              icons={[
                FaClipboardCheck,
                FaCogs,
                FaUsers,
                FaEnvelope,
                FaMailBulk,
              ]}
              activeIndex={step}
            />
          </Flex>
          <Box mb={3}>
            {step === 0 ? (
              <>
                <Text fontSize={4}>Select Form</Text>
                <Text color="grey1" mb={3}>
                  Select a form to use in your Feedback Campaign
                </Text>
                <FeedbackFormSelector
                  value={spec.feedbackForm}
                  onChange={(v) => {
                    setValue("feedbackForm")(v);
                    setValue("name")(v.name + " Campaign");
                  }}
                />
                {errors.feedbackForm ? (
                  <Text color="danger" mt={1}>
                    {errors.feedbackForm}
                  </Text>
                ) : null}
              </>
            ) : step === 1 ? (
              <>
                <Text fontSize={4}>Customise Campaign</Text>
                <Text color="grey1" mb={3}>
                  Change how your campaign behaves
                </Text>
                <FormInput
                  required
                  name="name"
                  label="Campaign Name"
                  width="500px"
                  error={errors.name}
                  value={spec.name}
                  onChange={(ev) => setValue("name")(ev.target.value)}
                />
                <FormTextArea
                  required
                  name="description"
                  height="150px"
                  label="Description"
                  value={spec.description}
                  onChange={(ev) => setValue("description")(ev.target.value)}
                />
                <FormInputWrap label="Image (Optional)">
                  <ImageUploader
                    value={spec.thumbnail}
                    onChange={setValue("thumbnail")}
                    width={320}
                    height={180}
                  />
                </FormInputWrap>

                <FormInputWrap label="Survey Anonymity">
                  <MultiToggle
                    items={["Anonymous", "Transparent"]}
                    active={spec.anonType === "anonymous" ? 0 : 1}
                    mb={1}
                    onSelect={(v) =>
                      setValue("anonType")(
                        v === 0 ? "anonymous" : "transparent"
                      )
                    }
                  />
                  <Flex alignItems="center">
                    <FaInfoCircle style={{ flex: "0 0 auto" }} />
                    <Text fontSize={3} color="grey1" ml={1}>
                      {spec.anonType === "anonymous"
                        ? "An anonymous campaign hides the names of the respondents. Results will not be visible until at least 5 responses have been received."
                        : "A transparent campaign allows the user to see how individual respondents answered"}
                    </Text>
                  </Flex>
                </FormInputWrap>
                <FormInput
                  required
                  name="minimumForwards"
                  label="Minimum Respondents"
                  width="300px"
                  value={spec.minimumForwards}
                  error={errors.minimumForwards}
                  onChange={(ev) =>
                    setValue("minimumForwards")(
                      ev.target.value.replace(/[^0-9]/g, "")
                    )
                  }
                />
                <Flex alignItems="center" mb={2}>
                  <FaInfoCircle style={{ flex: "0 0 auto" }} />
                  <Text fontSize={3} color="grey1" ml={1}>
                    This is the minimum number of respondents a manager must
                    request responses from.
                  </Text>
                </Flex>
                <CheckBox
                  label="Collect Respondent Relationship"
                  mb={1}
                  value={spec.relationships != null}
                  onChange={(val) =>
                    setValue("relationships")(
                      val ? [...defaultRelationships] : null
                    )
                  }
                />
                <Flex alignItems="center" mb={2}>
                  <FaInfoCircle />
                  <Text fontSize={3} color="grey1" ml={1}>
                    Collect how a respondent is related to the user.
                  </Text>
                </Flex>
                {spec.relationships != null && (
                  <Box mb={3}>
                    <ControlLabel>Available Relationships</ControlLabel>
                    <Flex flexDirection="column">
                      {spec.relationships.map((x, i) => (
                        <Flex alignItems="center" mb={1}>
                          <Input
                            value={x}
                            onChange={setRelationship(i)}
                            width={500}
                          />
                          <IconButton
                            color="danger"
                            icon={FaTrash}
                            onClick={() => removeRelationship(i)}
                            ml={1}
                          />
                        </Flex>
                      ))}
                      <AddButton width={500} p={1} onClick={addRelationship}>
                        Add Relationship
                      </AddButton>
                    </Flex>
                  </Box>
                )}
                <ControlLabel mb={2}>Who can see the results?</ControlLabel>
                <CheckBox
                  label="The User"
                  mb={1}
                  value={spec.resultsVisibility.includes("self")}
                  onChange={setVisibility("self")}
                />
                <CheckBox
                  label="Their Line Manager"
                  mb={1}
                  value={spec.resultsVisibility.includes("manager")}
                  onChange={setVisibility("manager")}
                />
                <CheckBox
                  label="Organisation Admins"
                  mb={1}
                  value={spec.resultsVisibility.includes("admin")}
                  onChange={setVisibility("admin")}
                />
                <CheckBox
                  label="Specific Users"
                  mb={1}
                  value={allowSpecificUsers}
                  onChange={setSpecificUsers}
                />
                {allowSpecificUsers && (
                  <>
                    <Flex flexWrap="wrap" style={{ gap: 3 }} mb={2} mt={2}>
                      {spec.viewers.map((x) => (
                        <Label
                          icon={FaUser}
                          onRemove={() => removeViewer(x.id)}
                        >
                          {x.name}
                        </Label>
                      ))}
                      {spec.viewers.length === 0 && (
                        <Text color="grey2">No Users</Text>
                      )}
                    </Flex>

                    <Button onClick={() => setAddingViewers(true)}>
                      Add Users
                    </Button>
                  </>
                )}
                {errors.resultsVisibility && (
                  <Text mt={1} color="danger">
                    {errors.resultsVisibility}
                  </Text>
                )}
              </>
            ) : step === 2 ? (
              <>
                <Text fontSize={4}>Select Users</Text>
                <Text color="grey1" mb={3}>
                  Select who to distribute the Campaign to
                </Text>
                <UserSelectionTable
                  value={spec.recipients}
                  onChange={setValue("recipients")}
                />
              </>
            ) : step === 3 ? (
              <>
                <FormToggle
                  mb={3}
                  value={spec.sendEmail}
                  label="Send system emails"
                  helpText="This cannot be changed after creation"
                  onChange={setValue("sendEmail")}
                ></FormToggle>
                {spec.sendEmail && (
                  <>
                    <Text fontSize={4}>Email Template</Text>
                    <Text color="grey1" mb={3}>
                      This is the email that will be sent to your users.
                    </Text>
                    <FormInput
                      name="subject"
                      label="Email Subject"
                      value={spec.emailSubject}
                      onChange={(ev) =>
                        setValue("emailSubject")(ev.target.value)
                      }
                    />
                    <RichTextEditor
                      value={spec.email}
                      onChange={setValue("email")}
                      mergeFields={emailMergeFields}
                    />
                  </>
                )}
              </>
            ) : step === 4 ? (
              <>
                <Text fontSize={4}>Respondent Email Template</Text>
                <Text color="grey1" mb={3}>
                  This is the template for the email sent by your users to the
                  respondents. They will be able to edit it.
                </Text>
                <FormInput
                  name="subject"
                  label="Email Subject"
                  value={spec.respondentEmailSubject}
                  onChange={(ev) =>
                    setValue("respondentEmailSubject")(ev.target.value)
                  }
                />
                <RichTextEditor
                  value={spec.respondentEmail}
                  onChange={setValue("respondentEmail")}
                  mergeFields={respondentMergeFields}
                />
              </>
            ) : null}
          </Box>
          <Flex justifyContent="center">
            <Button
              disabled={step === 0}
              onClick={() => setStep((old) => old - 1)}
            >
              Back
            </Button>
            <Button
              color="primary"
              ml={2}
              onClick={() => advance(false)}
              disabled={submitting}
            >
              {step === 4 ? "Submit & Send" : "Next"}
            </Button>
            {step === 4 && (
              <Button
                color="primary"
                ml={2}
                onClick={() => advance(true)}
                disabled={submitting}
              >
                Save as Draft
              </Button>
            )}
          </Flex>
        </Panel>
      </Box>
    </CenterColumn>
  );
}
