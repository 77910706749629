import { Flex, Text } from "@coaching-culture/ui";
import { useRef } from "react";
import styled from "styled-components";

type Option = {
  value: string;
  label: string;
};

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
`;

const RadioDisplay = styled.span<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  position: relative;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    position: relative;
    background-color: ${(props) =>
      props.checked ? props.theme.colors.primary : "transparent"};
  }
`;

const RadioButton = ({
  checked,
  label,
  onChange,
}: {
  checked: boolean;
  label: string;
  onChange: (val: boolean) => void;
}) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.checked);
  };

  return (
    <RadioContainer>
      <Text mr={2} textAlign={"right"} fontWeight={600}>
        {label}
      </Text>
      <RadioDisplay checked={checked}>
        <RadioInput type="checkbox" checked={checked} onChange={handleChange} />
      </RadioDisplay>
    </RadioContainer>
  );
};

export type DualOptionSelectProps = {
  options: Option[];
  value: [string, string];
  onChange: (val: [string, string]) => void;
};

export const DualOptionSelect = ({
  options,
  value,
  onChange,
}: DualOptionSelectProps) => {
  const last = useRef(0);

  const set = (id: string, val: boolean) => {
    if (!val) {
      // We can't unset a radio button
      return;
    }

    if (last.current === 0) {
      onChange([value[0], id]);
      last.current = 1;
    } else {
      onChange([id, value[1]]);
      last.current = 0;
    }
  };

  return (
    <Flex flexDirection={"column"} alignItems={"flex-end"}>
      {options.map((x) => (
        <RadioButton
          key={x.value}
          checked={value.includes(x.value)}
          label={x.label}
          onChange={(val) => set(x.value, val)}
        />
      ))}
    </Flex>
  );
};
