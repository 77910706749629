import { HelpItemIdent } from "@coaching-culture/types";
import { HelpItemDisplayModal } from "@coaching-culture/ui";
import { useHelpItem, useMarkHelpItemAsSeen } from "queries/mindset";
import { useEffect, useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: none;
  position: relative;
  transition: color 0.2s ease;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export type HelpItemButtonProps = {
  ident: HelpItemIdent;
  style?: React.CSSProperties;
};

export function HelpItemButton({ ident, style }: HelpItemButtonProps) {
  const item = useHelpItem(ident);
  const mark = useMarkHelpItemAsSeen();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (item?.forceShow && !item?.seen) {
      setShow(true);
    }
  }, [item]);

  if (item == null) {
    return null;
  }

  const close = () => {
    setShow(false);
    if (item?.id) {
      mark.mutateAsync(item.id);
    }
  };

  return (
    <>
      {show && <HelpItemDisplayModal item={item} onClose={close} />}
      <Button onClick={() => setShow(true)} style={style}>
        <FaQuestionCircle />
      </Button>
    </>
  );
}
