import { GoalDto, ObjectiveDto } from "@coaching-culture/types";
import {
  Button,
  Circle,
  DotMenu,
  Flex,
  IconButton,
  LinkButton,
  Loader,
  Panel,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import { PieChart } from "react-minimal-pie-chart";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import { fromUnixTime } from "date-fns";
import { sortBy } from "lodash";
import {
  useDeleteGoal,
  useGoalFields,
  useGoalsForUser,
  useUpdateGoal,
} from "queries/performance";
import { useMyRelationships } from "queries/team";
import { useState } from "react";
import {
  FaBullseye,
  FaChevronDown,
  FaChevronRight,
  FaMedal,
  FaPen,
  FaSearchPlus,
  FaTrash,
  FaLeaf,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatDate } from "utils/dates";
import { EditObjectiveModal } from "./GoalEditor";
import { GoalProgressDisplay } from "./GoalProgressDisplay";
import { TimePeriod } from "./TimePeriod";

const Row = styled(Flex)`
  width: 100%;
  &:nth-child(odd) {
    background-color: #fafafa;
  }
`;

const Col = styled(Flex)`
  position: relative;
`;

const Line = styled.span`
  position: absolute;
  left: 7px;
  width: 2px;
  display: block;
  background-color: ${(props) => props.theme.colors.grey4};
`;

const ObjLine = styled(Line)`
  bottom: -18px;
  top: -18px;

  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 30px;
    left: 0px;
    background-color: ${(props) => props.theme.colors.grey4};
    position: absolute;
    top: 50%;
  }
`;

const ActionLine = styled(ObjLine)`
  bottom: 50%;
  top: -18px;

  &:after {
    top: 100%;
  }
`;

const DownLine = styled(Line)`
  bottom: -18px;
  top: calc(50% + 12px);
`;

Col.defaultProps = {
  alignItems: "center",
};

export type GoalDisplayProps = {
  goal: GoalDto;
  onChange: (val: GoalDto) => void;
  onDelete: () => void;
  onReview: () => void;
  userId: string;
};

export function GoalDisplay({
  goal,
  onChange,
  onDelete,
  onReview,
  userId,
}: GoalDisplayProps) {
  const [open, setOpen] = useState<boolean>(false);
  const terms = useGoalTerminology();
  const [editingObj, setEditingObj] = useState<ObjectiveDto | "new" | null>(
    null,
  );
  const [user] = useUser();
  const { data: goalFields } = useGoalFields();
  const { data: relationships } = useMyRelationships();

  const onItemClick = (idx: number, cmd: string) => {
    if (cmd === "delete") {
      onDelete();
    } else if (cmd === "review") {
      onReview();
    }
  };

  const getFriendlyName = (outcome: string) => {
    switch (outcome) {
      case "completedachieved":
        return (
          <Text display={"inline"} fontWeight={600} color={"positive"}>
            Completed - Achieved
          </Text>
        );
      case "partially":
        return (
          <Text display={"inline"} fontWeight={600} color={"warning"}>
            Closed - Partially Met
          </Text>
        );
      case "exceeded":
        return (
          <Text display={"inline"} fontWeight={600} color={"positive"}>
            Completed - Exceeded
          </Text>
        );
      case "missed":
        return (
          <Text display={"inline"} fontWeight={600} color={"danger"}>
            Closed - Missed
          </Text>
        );
      case "nla":
        return (
          <Text display={"inline"} fontWeight={600} color={"grey"}>
            Closed - No Longer Applicable
          </Text>
        );
      case "open":
        return (
          <Text display={"inline"} fontWeight={600} color={"warning"}>
            Reopened
          </Text>
        );
    }
  };

  const onObjectiveItemClick = (id: string) => (idx: number) => {
    if (idx === 1) {
      if (window.confirm("Are you sure?")) {
        onChange({
          ...goal,
          objectives: goal.objectives.filter((x) => x.id !== id),
        });
      }
    } else if (idx === 0) {
      const obj = goal.objectives.find((x) => x.id === id);
      if (obj != null) {
        setEditingObj(obj);
      }
    }
  };

  const setDeadline = (val: number) => {
    onChange({
      ...goal,
      deadline: val,
    });
  };

  const setObjectiveDeadline = (id: string, val: number) => {
    onChange({
      ...goal,
      objectives: goal.objectives.map((x) => ({
        ...x,
        deadline: x.id === id ? val : x.deadline,
      })),
    });
  };

  const saveObj = (values: ObjectiveDto) => {
    if (editingObj === "new") {
      // new objective
      onChange({
        ...goal,
        objectives: [...goal.objectives, values],
      });
    } else {
      onChange({
        ...goal,
        objectives: goal.objectives.map((x) =>
          x.id === values.id ? values : x,
        ),
      });
    }
    setEditingObj(null);
  };

  const objFields = goalFields?.filter((x) => x.usage !== "goal") ?? [];

  const sortedObjs = goal.objectives.sort((a, b) => {
    if (
      fromUnixTime(a.createdOn).setHours(0, 0, 0, 0) <
      fromUnixTime(b.createdOn).setHours(0, 0, 0, 0)
    ) {
      return -1;
    } else if (
      fromUnixTime(a.createdOn).setHours(0, 0, 0, 0) <
      fromUnixTime(b.createdOn).setHours(0, 0, 0, 0)
    ) {
      return 1;
    } else if (
      fromUnixTime(a.createdOn).setHours(0, 0, 0, 0) ===
      fromUnixTime(b.createdOn).setHours(0, 0, 0, 0)
    ) {
      if (fromUnixTime(a.deadline) < fromUnixTime(b.deadline)) {
        return -1;
      } else if (fromUnixTime(a.deadline) < fromUnixTime(b.deadline)) {
        return 1;
      }
    }
    return 0;
  });

  const canEdit =
    relationships?.some((x) => x.to.id === userId && x.type === "manager") ||
    goal.userId === user.id;

  return (
    <>
      {editingObj != null && (
        <EditObjectiveModal
          value={editingObj === "new" ? null : editingObj}
          onClose={() => setEditingObj(null)}
          onSave={saveObj}
          fields={objFields}
          goalDeadline={goal.deadline}
        />
      )}
      <Row p={[2, 3]} borderTop={1}>
        <Col width={1 / 24}>
          {(canEdit || goal.objectives.length > 0) && (
            <IconButton
              icon={open ? FaChevronDown : FaChevronRight}
              color="body"
              onClick={() => setOpen((old) => !old)}
            />
          )}
          {open && <DownLine />}
        </Col>
        <Col width={[23 / 24, 8 / 24]}>
          <Circle
            color="primary"
            icon={goal.type === "performance" ? FaMedal : FaLeaf}
            size="small"
            ml={2}
            mr={2}
            title={
              goal.type === "performance"
                ? `Performance ${terms.goal.asTitle()}`
                : `Development ${terms.goal.asTitle()}`
            }
          />
          <LinkButton onClick={() => onReview()} fontWeight={500} mr={2}>
            {goal.purpose}
          </LinkButton>
        </Col>
        <Col width={3 / 24} justifyContent="center" display={["none", "flex"]}>
          <Text fontWeight={500}>
            {goal.updatedOn != null
              ? formatDate(goal.updatedOn, "dd/MM/yyyy : HH:mm")
              : null}
          </Text>
        </Col>
        <Col width={6 / 24} justifyContent="center" display={["none", "flex"]}>
          {goal.completedOn == null ? (
            <Link
              title={`Review ${terms.goal.asTitle()}`}
              to={`/solutions/performance/goals/${goal.id}/review`}
              onClick={() => onReview()}
            >
              <GoalProgressDisplay
                value={goal.completedOn != null ? "achieved" : goal.status}
                disabled={goal.completedOn != null}
              />
            </Link>
          ) : (
            getFriendlyName(goal.outcome)
          )}
        </Col>
        <Col width={2 / 24} justifyContent="center" display={["none", "flex"]}>
          <Flex ml={3} mr={3} alignItems={"center"} height={"30px"}>
            <PieChart
              startAngle={270}
              data={[
                {
                  value:
                    goal.progress > goal.target
                      ? 100
                      : Math.round((goal.progress / goal.target) * 100),
                  color: "#48B2D2",
                },
                {
                  value:
                    goal.progress > goal.target
                      ? 0
                      : 100 - Math.round((goal.progress / goal.target) * 100),
                  color: "lightgrey",
                },
              ]}
            />
            <Text
              width="80px"
              fontSize={2}
              ml={1}
              fontWeight={600}
              color="primary"
            >
              {Math.round((goal.progress / goal.target) * 100)} %
            </Text>
          </Flex>
        </Col>
        <Col width={2 / 12} justifyContent="center" display={["none", "flex"]}>
          <TimePeriod
            start={goal.createdOn}
            disabled={goal.completedOn != null}
            value={goal.deadline}
            onChange={setDeadline}
          />
        </Col>
        <Col width={1 / 24} justifyContent="flex-end">
          {canEdit && (
            <DotMenu
              items={[
                {
                  label: `${terms.goal.asTitle()} Details`,
                  icon: FaSearchPlus,
                  cmd: "review",
                },
                {
                  label: "Delete",
                  icon: FaTrash,
                  color: "danger",
                  cmd: "delete",
                },
              ]}
              onItemClick={onItemClick}
            />
          )}
        </Col>
      </Row>
      {open && (
        <>
          {sortBy(sortedObjs, (x) => x.completedOn !== null).map((x, i) => (
            <Row p={[2, 3]} key={x.id ?? i}>
              <Col width={1 / 24}>
                {goal.completedOn != null &&
                i === goal.objectives.length - 1 ? (
                  <ActionLine />
                ) : (
                  <ObjLine />
                )}
              </Col>
              <Col width={goal.completedOn === null ? 8 / 24 : 7 / 24}>
                <Circle
                  ml={2}
                  color="orange"
                  icon={FaBullseye}
                  size="small"
                  mr={2}
                  title={terms.objective.asTitle()}
                />
                <Text
                  fontWeight={500}
                  style={{
                    textDecoration:
                      x.completedOn == null ? "none" : "line-through",
                  }}
                >
                  {x.purpose}
                </Text>
              </Col>
              <Col
                width={goal.completedOn === null ? 3 / 24 : 4 / 24}
                justifyContent="center"
                display={["none", "flex"]}
              >
                <Text fontWeight={500}>
                  {x.updatedOn != null
                    ? formatDate(x.updatedOn, "dd/MM/yyyy : HH:mm")
                    : null}
                </Text>
              </Col>
              {goal.completedOn !== null && (
                <Col
                  width={1 / 24}
                  justifyContent="center"
                  display={["none", "flex"]}
                ></Col>
              )}

              <Col
                width={goal.completedOn === null ? 6 / 24 : 4 / 24}
                justifyContent="center"
                display={["none", "flex"]}
              >
                {goal.completedOn == null &&
                goal.objectives.find((obj) => obj.id === x.id).completedOn ==
                  null ? (
                  <Link
                    title={`Review ${terms.goal.asTitle()}`}
                    to={null}
                    onClick={() => onReview()}
                  >
                    <GoalProgressDisplay
                      value={
                        goal.objectives.find((obj) => obj.id === x.id).status
                      }
                      disabled={false}
                    />
                  </Link>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                width={2 / 24}
                justifyContent="center"
                display={["none", "flex"]}
              >
                <Flex alignItems={"center"} width={"70px"}>
                  <PieChart
                    startAngle={270}
                    data={[
                      {
                        value:
                          x.progress > x.target
                            ? 100
                            : Math.round((x.progress / x.target) * 100),
                        color: "#48B2D2",
                      },
                      {
                        value:
                          x.progress > x.target
                            ? 0
                            : 100 - Math.round((x.progress / x.target) * 100),
                        color: "lightgrey",
                      },
                    ]}
                  />
                  <Text
                    fontSize={2}
                    width={"80px"}
                    ml={1}
                    fontWeight={600}
                    color="primary"
                  >
                    {Math.round((x.progress / x.target) * 100)} %
                  </Text>
                </Flex>
              </Col>

              <Col
                width={4 / 24}
                justifyContent="center"
                display={["none", "flex"]}
              >
                <TimePeriod
                  value={x.deadline}
                  start={x.createdOn}
                  onChange={(newVal) => setObjectiveDeadline(x.id, newVal)}
                  disabled={x.completedOn != null || !canEdit}
                  maxValue={fromUnixTime(goal.deadline)}
                />
              </Col>
              <Col width={1 / 24} justifyContent="flex-end">
                {goal.completedOn == null && canEdit && (
                  <DotMenu
                    items={[
                      {
                        label: "Edit",
                        icon: FaPen,
                        disabled: x.completedOn != null,
                      },
                      {
                        label: "Delete",
                        icon: FaTrash,
                        color: "danger",
                      },
                    ]}
                    onItemClick={onObjectiveItemClick(x.id)}
                  />
                )}
              </Col>
            </Row>
          ))}
          {goal.completedOn == null && canEdit && (
            <Row p={[2, 3]}>
              <Col width={1 / 24}>
                <ActionLine />
              </Col>
              <Col width={23 / 24}>
                <Button
                  color="primary"
                  icon={FaBullseye}
                  tiny
                  onClick={() => setEditingObj("new")}
                >
                  Add {terms.objective.asTitle()}
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}

export const GoalList = ({
  userId,
  reviewGoal,
}: {
  userId: string;
  reviewGoal: (goalId: string) => void;
}) => {
  const { data: goals } = useGoalsForUser(userId);
  const updateGoal = useUpdateGoal(userId);
  const deleteGoalMut = useDeleteGoal(userId);
  const terms = useGoalTerminology();

  const changeGoal = (goal: GoalDto) => {
    updateGoal.mutateAsync(goal);
  };

  const deleteGoal = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      deleteGoalMut.mutateAsync(id);
    }
  };

  const sortedGoals = sortBy(goals, (x) =>
    x.completedOn == null ? x.deadline : Number.MAX_VALUE,
  );

  return (
    <>
      <Panel>
        <PanelHeader>
          <Circle color="primary" icon={FaMedal} mr={2} size="small" />
          <Text fontWeight={500} fontSize={4}>
            {terms.goal.asPluralTitle()}
          </Text>
        </PanelHeader>
        <Flex width={1} p={2} borderBottom={1}>
          <Col width={11 / 24}>
            <Text fontWeight={600}>{terms.goal.asTitle()}</Text>
          </Col>
          <Col
            width={3 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Last Status Update
            </Text>
          </Col>
          <Col
            width={8 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Status
            </Text>
          </Col>
          <Col
            width={2 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Progress
            </Text>
          </Col>
          <Col
            width={6 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Time Period
            </Text>
          </Col>
          <Col width={1 / 24} />
        </Flex>
        {goals == null ? (
          <Loader />
        ) : (
          <Flex flexDirection={"column"}>
            {sortedGoals
              .filter((goal) => goal.completedOn === null)
              .map((x, i) => (
                <GoalDisplay
                  goal={x}
                  key={i}
                  onChange={changeGoal}
                  onDelete={() => deleteGoal(x.id)}
                  onReview={() => reviewGoal(x.id)}
                  userId={userId}
                />
              ))}
          </Flex>
        )}
      </Panel>
      <Panel mt={3}>
        <PanelHeader>
          <Circle color="primary" icon={FaMedal} mr={2} size="small" />
          <Text fontWeight={500} fontSize={4}>
            Completed/Closed {terms.goal.asPluralTitle()}
          </Text>
        </PanelHeader>
        <Flex width={1} p={2} borderBottom={1}>
          <Col width={10 / 24}>
            <Text fontWeight={600}>{terms.goal.asTitle()}</Text>
          </Col>
          <Col
            width={4 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Last Status Update
            </Text>
          </Col>
          <Col
            width={6 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Outcome
            </Text>
          </Col>
          <Col
            width={2 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Progress
            </Text>
          </Col>
          <Col
            width={6 / 24}
            justifyContent="center"
            display={["none", "flex"]}
          >
            <Text fontWeight={600} textAlign="center">
              Time Period
            </Text>
          </Col>
          <Col width={1 / 24} />
        </Flex>
        {goals == null ? (
          <Loader />
        ) : (
          <Flex flexDirection={"column"}>
            {sortedGoals
              .filter((goal) => goal.completedOn !== null)
              .map((x, i) => (
                <GoalDisplay
                  goal={x}
                  key={i}
                  onChange={changeGoal}
                  onDelete={() => deleteGoal(x.id)}
                  onReview={() => reviewGoal(x.id)}
                  userId={userId}
                />
              ))}
          </Flex>
        )}
      </Panel>
    </>
  );
};
