import { Box, RawContent } from "@coaching-culture/ui";
import React from "react";

const TermsOfUse = () => {
  return (
    <Box p={3} pt={5} maxWidth="800px" ml="auto" mr="auto">
      <RawContent>
        <h1 id="terms-of-use">Terms of Use</h1>
        <p>
          These Terms and Conditions apply to the use of our Solutions Platform
          provided by us, Coaching Culture Limited a company registered in
          England and Wales under company number 10855629 whose registered
          office address is at Old Chapel, Old Cherry Lane, Lymm, Cheshire. WA13
          0TA (“the Company/we/us/our”).
        </p>
        <h2 id="definitions-and-interpretation">
          1. Definitions and Interpretation
        </h2>
        <p>
          In these Terms and Conditions, the following expressions have the
          following meanings: “Client/you/your” means the individual, firm or
          corporate body purchasing the Solutions Platform. Where an individual
          is entering into this Contract on behalf of a business, the individual
          confirms they have the authority to do so and to contractually bind
          that business and the business shall be the Client in the context of
          this Contract; “Client Data” means the data inputted to the Solutions
          Platform by you, your authorised agents, authorised users, or us on
          your behalf, for the purpose of using the Solutions Platform or
          facilitating your use of it; “Contract” means the contract formed as
          detailed in clause 2, which includes the acceptance of these Terms and
          Conditions; “Launch Date” means the date your users will have access
          to the Solutions Platform, this date can be no later than 90 days from
          the Contract being agreed; “Start Date” means the date you will have
          access to the Solutions Platform; “Solutions Platform” means the
          online Platform provided by us on a subscription; “User/Users” means
          those who have access to the Solutions Platform; “Website” means the
          Coaching Culture website{" "}
          <a href="http://www.coachingculture.com">www.coachingculture.com</a>{" "}
          1.1 Each reference in these Terms and Conditions to: 1.1.1 “writing”
          and “written” includes emails; 1.1.2 a statute is a reference to that
          statute as amended or reenacted at the relevant time; 1.1.3 “these
          Terms and Conditions” is a reference to these Terms and Conditions as
          amended or supplemented at the relevant time; 1.1.4 a clause refers to
          a clause of these Terms and Conditions; 1.1.5 a “Party” or the
          “Parties” refer to the parties to these Terms and Conditions. 1.2 The
          headings used in these Terms and Conditions are for convenience only
          and will have no effect upon their interpretation. 1.3 Words imparting
          the singular number include the plural and vice versa. References to
          any gender include the other gender. References to persons include
          corporations.
        </p>
        <h2 id="the-contract">2. The Contract</h2>
        <p>
          2.1 We will send you a written Proposal detailing the Fees and the
          minimum term for our Solutions Platform. A legally binding Contract
          between you and us will be formed when you accept our Proposal, by
          sending us written confirmation or by sending us a purchase order. The
          Contract will incorporate, and be subject to, these Terms and
          Conditions. 2.2 No terms or conditions stipulated or referred to by
          you in any form whatsoever will in any way vary or add to these Terms
          and Conditions unless we agree otherwise in writing. 2.3 You are
          responsible for the accuracy of any information submitted to us and
          for ensuring that our Proposal and the Services to be provided reflect
          your requirements. Our Proposal is based on the information provided
          to us at the time we prepare it. If any errors or discrepancies become
          evident, we reserve the right to make adjustments to it. 2.4 We will
          use all reasonable endeavours to complete our obligations under the
          Contract in a timely manner, but time will not be of the essence in
          the performance of our Services. 2.5 Once the Contract is agreed, on
          the Launch Date we will grant you a limited, non-exclusive,
          non-transferrable licence to use our Solutions Platform solely for
          your own personal purposes or internal business operations, within the
          bounds of these Terms and Conditions. 2.6 The Contract will continue
          be for a fixed 12-month term from the Launch Date, or as agreed
          between you and us in writing. Once the 12-month term has completed it
          shall renew on a 12-month rolling basis, on the same terms and
          conditions as set out in this Contract (with the exception of the
          price) until notice to terminate is given by either party in
          accordance with clause 6 below. 2.7 You are responsible for appointing
          primary contact/s to act as the Client’s representative to liaise with
          us in connection with the Services. Any User problems must be logged
          with this contact and not direct to us unless the appointed primary
          contact/s are unable to rectify the users problem.
        </p>
        <h2 id="solutions-platform">3. Solutions Platform</h2>
        <p>
          3.1 We shall provide support in setting up the Platform and shall use
          all reasonable endeavours to ensure they are provided in a timely
          manner; 3.2 You will be provided with an account and login details; it
          is your responsibility to add and delete Users of the Solutions
          Platform and you will be provided with an admin area to ensure this is
          done; 3.3 It is your responsibility to ensure that your employees,
          agents and other parties under your control who will use the Platform
          do so in accordance with these Terms and Conditions and are
          accordingly notified of the same. 3.4 You must remove access
          completely to the Platform of any User that leaves your employment.
          You will use all reasonable endeavours to prevent any unauthorised
          access to, or use of, the Platform and, in the event of any such
          unauthorised access or use, you will promptly notify us. We will be
          entitled to carry out regular audits to ensure that only authorised
          Users have access to the Solutions Platform. 3.5 All proprietary
          rights in the Solutions Platform remain with us. You will not: 3.5.1
          attempt to copy, modify, duplicate, create derivative works from,
          reverse compile, disassemble, reverse engineer, frame, mirror,
          republish, transmit, or distribute all or any portion of the Solutions
          Platform in any form or media or by any means; 3.5.2 access all or any
          part of the Solutions Platform in order to build a product or service
          which competes with the Solutions Platform; 3.5.3 vary, delete or
          obscure any notices of proprietary rights, means of identification or
          restrictions on or in the Solutions Platform; 3.5.4 sub-license, sell,
          rent, lease, transfer, assign, distribute or otherwise commercially
          exploit or make the Solutions Platform available to any third party
          except the Authorised Users; or 3.5.5 attempt to obtain, or assist
          third parties in obtaining, access to the Solutions Platform other
          than as provided under this clause 3. 3.6 We do not warrant that your
          use of the Solutions Platform will be uninterrupted or error-free; nor
          that the Solutions Platform, and/or the information obtained by you
          through the Solutions Platform will meet your requirements. 3.7 We are
          not responsible for any delays, delivery failures, or any other loss
          or damage resulting from the transfer of data over communications
          networks and facilities, including the internet, and you acknowledge
          that the Solutions Platform may be subject to limitations, delays and
          other problems inherent in the use of such communications facilities.
          3.8 We reserve the right to carry out maintenance at such times as may
          be necessary at our discretion but will endeavour to give you advance
          notice where possible. 3.9 You agree that you will not allow any
          log-in to be used by more than one authorised User and you confirm
          that you will use reasonable endeavours to ensure that each User keeps
          their password confidential. You will also ensure that each password
          is reasonably strong and secure and is changed regularly. 3.10 You
          will not access, store, distribute or transmit any viruses, or any
          material during the course of your use of the Solutions Platform that:
          3.10.1 is unlawful, harmful, threatening, defamatory, obscene,
          harassing or offensive; 3.10.2 facilitates illegal activity; 3.10.3
          depicts sexually explicit images; 3.10.4 promotes unlawful violence;
          3.10.5 is knowingly discriminatory; or 3.10.6 causes or may cause
          damage or injury to any person or property and we reserve the right,
          without liability to you, to remove any material that breaches the
          provisions of this clause. 3.11 The rights provided under this clause
          3 are granted to the Client only, and will not be considered granted
          to any subsidiary or holding company of the Client without our written
          permission. 3.12 You own all rights, title and interest in and to all
          of the Client Data and will have sole responsibility for its legality,
          reliability, integrity, accuracy and quality. You are responsible for
          backing up any Client Data. 3.13 You are solely responsible for
          procuring and maintaining adequate network connections and
          telecommunications links enabling you to access the Solutions
          Platform. We cannot be held responsible for problems, delays and all
          other loss or damage arising from or relating to this. 3.14 You assume
          sole responsibility for the results obtained from your use of the
          Solutions Platform and for conclusions drawn from such use.
        </p>
        <h2 id="payment">4. Payment</h2>
        <p>
          4.1 All fees and charges payable under this Contract are exclusive of
          VAT (where applicable) and must be paid within 30 days of invoice. 4.2
          The time for payment is of the essence of the Contract. If you fail to
          make any payment to us by the due date then, without prejudice to any
          right which we may have pursuant to any statutory provision in force
          from time to time, we will have the right to immediately suspend your
          access to the Solutions Platform and charge you interest on a daily
          basis at the rate of 8% per annum above The Bank of England base rate
          from time to time, in accordance with the Late Payment of Commercial
          Debts (Interest) Act 1998. Such interest will accrue after as well as
          before any judgment. 4.3 If any payment becomes overdue for a period
          of 60 days or more, we reserve the right to terminate your account and
          irretrievably delete any Client Data contained in the Solutions
          Platform.
        </p>
        <h2 id="intellectual-property-rights-claims-and-disputes">
          5. Intellectual Property Rights, Claims and Disputes
        </h2>
        <p>
          5.1 The Solutions Platform, together with any and all intellectual
          property rights of whatever nature which now or in the future subsist
          in the Solutions Platform are and will remain our property. This
          Contract does not constitute a sale of the original Solutions Platform
          or any copies of it. 5.2 You must notify us immediately if you become
          aware of any unauthorised use of the whole or any part of the
          Solutions Platform by any person. 5.3 We will defend, at our own
          expense, any claim brought against you alleging that the use of the
          Solutions Platform infringes the intellectual property rights of a
          third party and we will pay all costs and damages awarded or agreed to
          in settlement of such a claim, provided you: 5.3.1 provide us with
          prompt written notice of the alleged claim; 5.3.2 provide us with
          reasonable assistance in respect of the claim; 5.3.3 and give us the
          sole authority to defend or settle the claim. 5.4 We will have no
          liability for any such claim resulting from any modification of any
          part of the Solutions Platform by any party other than us or an
          authorised agent of ours.
        </p>
        <h2 id="cancellation">6. Cancellation</h2>
        <p>
          6.1 Either Party has the right to cancel the subscription immediately
          if the other Party: 6.1.1 has committed a material breach of this
          Contract, unless such breach is capable of remedy, in which case the
          right to terminate immediately will be exercisable if the other Party
          has failed to remedy the breach within 14 days after a written notice
          to do so; or 6.1.2 goes into bankruptcy or liquidation either
          voluntary or compulsory (save for the purposes of bona fide corporate
          reconstruction or amalgamation) or if a receiver is appointed in
          respect of the whole or any part of its assets. 6.2 If the Client
          wishes to cancel the subscription, they may do so at any time by
          giving written notice to the Company, provided that: 6.2.1 The Company
          shall be entitled to payment or to withhold payment for 100% of the
          total fees if cancellation takes place within 4 weeks or less before
          the Start date of the Services. 6.3 If, due to circumstances beyond
          our control, we have to make any change in the arrangements relating
          to the Platform, we shall notify you as soon as possible. We shall
          endeavour to keep such changes to a minimum and shall seek to offer
          you arrangements as close to the original, or better, as reasonably
          possible in these circumstances. If we have to make a significant
          change for any reason, we shall offer you the alternative as an option
          or an option to cancel with all sums refunded and no termination
          costs. 6.4 Any and all obligations of the Parties which either
          expressly or by their nature continue beyond the termination,
          cancellation or expiration of this Contract will survive termination
          under this clause 9 on a pro-rata basis.
        </p>
        <h2 id="liability">7. Liability</h2>
        <p>
          7.1 Nothing in these Terms and Conditions excludes or seeks to exclude
          our liability for death or personal injury caused by our negligence,
          or for fraud or fraudulent misrepresentation. 7.2 Except as provided
          in clause 7.1 above, we will not by reason of any representation,
          implied warranty, condition or other term, or any duty at common law
          or under the express terms contained here, be liable for any loss of
          profit or any indirect, special or consequential loss, damage, costs,
          expenses or other claims (whether caused by our employees, agents or
          otherwise) in connection with the performance of our obligations under
          the Contract. All warranties or conditions whether express or implied
          by law are expressly excluded to the maximum extent permitted by law.
          7.3 In the event of a breach by us of our express obligations under
          these Terms and Conditions, your remedies will be limited to damages,
          which in any event, will not exceed the fees paid by you for the use
          of the Solutions Platform in the 6 months preceding the date on which
          the alleged claim arose. 7.4 You are wholly responsible for the Client
          Data you input to the Solutions Platform and for the results obtained
          from this. We accept no liability for your use of the information
          obtained by using the Solutions Platform.
        </p>
        <h2 id="confidentiality">8. Confidentiality</h2>
        <p>
          Each Party undertakes that throughout the duration of the Contract,
          the Parties may disclose certain confidential information to each
          other. Both parties agree that they will not use the confidential
          information provided by the other, other than to perform their
          obligations under the Contract. Each Party will maintain the
          confidential information’s confidentiality and will not disseminate it
          to any third party, unless so authorised by the other Party in
          writing.
        </p>
        <h2 id="data-protection">9. Data Protection</h2>
        <p>
          9.1 Both parties agree to comply with all applicable data protection
          legislation including, but not limited to, the General Data Protection
          Regulation 2016 (“GDPR”) and any subsequent amendments to it. 9.2 The
          parties shall act as joint Data Controllers for the purposes of the
          provision of the service. You will be responsible for the upload and
          management of user data in the Solution and for the fulfilment of any
          individual rights as specified within the GDPR where such data resides
          within the Solution. 9.3 In the event of a Data Breach regarding Your
          data within the Solution We: a) will immediately (and in any case
          within 24 hours) inform You in writing of any unauthorized or unlawful
          processing of Personal Data and/or material incident of Personal Data
          loss, corruption, destruction, alteration, disclosure, access or
          damage (“Data Breach”) or any action that causes or could reasonably
          be deemed to cause a Data Breach and shall liaise with You in managing
          such Data Breach (including by providing sufficient information,
          cooperation, analysis and support) b) shall liaise with You in any
          notification to Your users of the Solution and/or to the Information
          Commissioner’s Office c) shall not make any public announcement
          regarding such incident without prior consultation with You and
          subject to Your written consent. 9.4 You warrant that you have a
          lawful reason under the GDPR to hold and upload the Client Data. We
          will only store and process that data within the Solutions Platform,
          in order to perform our obligations under the Contract. We will not
          use it in any other manner without consent. For further information,
          please refer to the Privacy Policy on our Website.
        </p>
        <h2 id="force-majeure">10. Force Majeure</h2>
        <p>
          Neither Party to this Contract will be liable for any failure or delay
          in performing their obligations where such failure or delay results
          from any cause that is beyond the reasonable control of that Party.
          Such causes include, but are not limited to: power failure, internet
          service provider failure, industrial action, civil unrest, fire,
          flood, storms, earthquakes, acts of terrorism, acts of war,
          governmental action or any other event that is beyond the control of
          the Party in question.
        </p>
        <h2 id="notices-and-communications">11. Notices and Communications</h2>
        <p>
          11.1 We may revise these Terms and Conditions from time to time. 11.2
          Notices will be deemed to have been duly received and properly served
          24 hours after an email is sent or three working days after the date
          of posting of any letter. In proving the service of any notice, it
          will be sufficient to prove, in the case of a letter, that it was
          properly addressed to the address provided, stamped and placed in the
          post and in the case of an email, that it was sent to the specified
          email address of the addressee.
        </p>
        <h2 id="other-important-terms">12. Other Important Terms</h2>
        <p>
          12.1 We may transfer (assign) our obligations and rights under these
          Terms and Conditions (and under the Contract, as applicable) to a
          third party (if for example, if we sell our business). If this occurs,
          we will inform you in writing. Your rights under these Terms and
          Conditions will not be affected and our obligations under these Terms
          and Conditions will be transferred to the third party who will remain
          bound by them. 12.2 You may not transfer (assign) your obligations and
          rights under these Terms and Conditions (and under the Contract, as
          applicable) without our express written permission. 12.3 Each Party
          acknowledges that, in entering into this Contract, it does not rely on
          any representation, warranty or other provision except as expressly
          provided in this Contract. This Contract constitutes the entire
          Contract between you and us with respect to its subject matter and
          supersedes all proposals, representations, understandings and prior
          Contracts, whether oral or written, and all other communications
          between us relating to that subject matter. 12.4 The Contract is
          between you and us. It is not intended to benefit any other person or
          third party in any way and no such person or party will be entitled to
          enforce any provision of these Terms and Conditions. 12.5 Any part of
          these Terms and Conditions found to be unlawful, invalid or otherwise
          unenforceable would be severed from our Contract. The validity and
          enforceability of the remaining parts of the Contract would not be
          affected. 12.6 If any rights under these Terms and Conditions are not
          exercised or enforced following a breach of contract by either party,
          this does not mean that either of us has waived our right to do so at
          a later date.
        </p>
        <h2 id="law-and-jurisdiction">13. Law and Jurisdiction</h2>
        <p>
          13.1 These Terms and Conditions and the relationship between you and
          us (whether contractual or otherwise) will be governed by, and
          construed in accordance with, the laws of England and Wales. 13.2 Any
          dispute, controversy, proceedings or claim between you and us relating
          to the Contract or these Terms and Conditions (whether contractual or
          otherwise) will be subject to the jurisdiction of the courts of
          England and Wales.
        </p>
      </RawContent>
    </Box>
  );
};

export default TermsOfUse;
