import styled from "styled-components";
import { Box, Flex, Text } from "@coaching-culture/ui";

export type ExplainImageProps = {
  src: string;
  title: string;
  body: string;
  action?: React.ReactNode;
};

const PanelImage = styled.img`
  flex: 1;
  max-width: 80%;
`;

export const ExplainImage = ({
  action,
  src,
  title,
  body,
}: ExplainImageProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      pb={[3, "60px"]}
      pt={[3, "60px"]}
      pl={[3, "90px"]}
      pr={[3, "90px"]}
      borderBottom={1}
      flexDirection={["column", "row"]}
    >
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        flex="1"
        order={[1, 0]}
        mt={[3, 0]}
        mb={[4, 0]}
      >
        <Text
          fontSize={5}
          fontWeight={600}
          maxWidth="400px"
          mb={2}
          as="h2"
          lineHeight={[1.25, 1]}
        >
          {title}
        </Text>
        <Box mb={2}>
          {body.split("\n").map((x, i) => (
            <Text fontSize={4} mb={1} key={i}>
              {x}
            </Text>
          ))}
        </Box>
        {action}
      </Flex>
      <Flex
        style={{ position: "relative" }}
        flex="1"
        justifyContent="center"
        mb={[3, 0]}
      >
        <PanelImage src={src} />
      </Flex>
    </Flex>
  );
};
