import { lighten } from "polished";
import React from "react";
import { IconType } from "react-icons/lib/cjs";
import { Link } from "react-router-dom";
import styled, { css, ThemedStyledProps } from "styled-components";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";

type CommonProps = FlexboxProps &
  SpaceProps &
  LayoutProps & {
    block?: boolean;
    large?: boolean;
    small?: boolean;
    tiny?: boolean;
    disabled?: boolean;
    filled?: boolean;
    color?: string;
    active?: boolean;
    name?: string;
    value?: string;
    style?: React.CSSProperties;
  };

const colorFromProps = (props: ThemedStyledProps<CommonProps, any>) =>
  props.theme.colors[props.color || props.theme.button.defaultColor] ||
  props.color;

const common = css<CommonProps>`
  display: ${(props) => (props.block ? "flex" : "inline-flex")};
  padding: ${(props) =>
    props.tiny
      ? "3px 12px"
      : props.small
        ? "3px 12px"
        : props.large
          ? "12px 24px"
          : "6px 12px"};
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid ${colorFromProps};
  border-radius: ${(props) => props.theme.button.borderRadius}px;
  background-color: ${(props) =>
    props.active || props.filled ? colorFromProps(props) : "white"};
  color: ${(props) =>
    props.active || props.filled ? "white" : colorFromProps(props)};
  align-items: center;
  font-weight: 600;
  font-size: ${(props) => (props.large ? 1.15 : props.tiny ? 0.8 : 1)}em;
  justify-content: center;
  min-width: ${(props) => (props.tiny ? 80 : 120)}px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  ${flexbox};
  ${space};
  ${layout};

  &[disabled] {
    opacity: 0.5;
  }

  &:enabled:hover {
    background-color: ${(props) =>
      props.active || props.filled
        ? lighten(0.1, colorFromProps(props))
        : colorFromProps(props)};
    color: white;
    text-decoration: none;
    box-shadow: none !important;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: 0;
    box-shadow: inset 0px 0px 5px ${(props) => props.theme.colors.primary};
  }
`;

export const ButtonElem = styled.button<CommonProps>`
  ${common};
`;

const LinkElem = styled(Link)`
  ${common};
  &:hover {
    background-color: ${colorFromProps};
    color: white;
    text-decoration: none;
  }
  text-decoration: none;
`;

const AnchorElem = styled.a`
  ${common};
  &:hover {
    background-color: ${colorFromProps};
    color: white;
    text-decoration: none;
  }
  text-decoration: none;
`;

type ButtonProps = CommonProps & {
  to?: string;
  href?: string;
  target?: string;
  icon?: IconType;
  children?: React.ReactNode | React.ReactNode[];
  type?: "button" | "submit";
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
};

const Button = ({
  to,
  href,
  icon: Icon,
  children,
  type,
  target,
  disabled,
  name,
  value,
  ...rest
}: ButtonProps) => {
  if (to != null && to.startsWith("http")) {
    href = to;
  }

  if (disabled) {
    to = undefined;
    href = undefined;
  }

  if (href != null) {
    return (
      <AnchorElem href={href} {...rest} target={target}>
        {children} {Icon && <Icon style={{ marginLeft: children ? 12 : 0 }} />}
      </AnchorElem>
    );
  }

  if (to != null) {
    return (
      <LinkElem to={to} {...rest}>
        <>
          {children}{" "}
          {Icon && <Icon style={{ marginLeft: children ? 12 : 0 }} />}
        </>
      </LinkElem>
    );
  }

  return (
    <ButtonElem
      type={type}
      {...rest}
      disabled={disabled}
      name={name}
      value={value}
    >
      {children} {Icon && <Icon style={{ marginLeft: children ? 12 : 0 }} />}
    </ButtonElem>
  );
};

Button.defaultProps = {};

export default Button;
