import CenterColumn from "components/CenterColumn";
import { Redirect, Route, Switch } from "react-router-dom";
import { Campaigns } from "./Campaigns";
import { Forms } from "./Forms";

const Feedback = () => {
  return (
    <CenterColumn>
      <Switch>
        <Route path="/success/feedback/forms" component={Forms} />
        <Route path="/success/feedback/campaigns" component={Campaigns} />
        <Redirect to="/success/feedback/campaigns" />
      </Switch>
    </CenterColumn>
  );
};

export default Feedback;
