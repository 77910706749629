import { Flex, Text } from "@coaching-culture/ui";
import { animated, useSprings } from "react-spring";
import styled from "styled-components";

const BarChartTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  td:first-child {
    width: 120px;
    white-space: nowrap;
    border-right: 1px solid ${(props) => props.theme.colors.grey4};
    text-align: right;
    font-weight: 600;
    color: ${(props) => props.theme.colors.grey1};
  }

  td {
    vertical-align: middle;
    padding: 3px 6px;

    & > * {
      vertical-align: middle;
    }
  }
`;

const BarChartBar = styled(animated.div)`
  background-color: ${(props) => props.theme.colors.primary};
  opacity: 0.8;
  height: 100%;
`;

export const FreqChart = ({
  data,
}: {
  data: { x: number; y: React.ReactNode }[];
}) => {
  const max = Math.max(...data.map((x) => x.x));

  const springs = useSprings(
    data.length,
    data.map((x, i) => ({
      from: {
        width: "0%",
      },
      delay: i * 100,
      width: Math.max(5, (x.x / max) * 90) + "%",
    }))
  );

  return (
    <BarChartTable>
      {data.map((x, i) => (
        <tr>
          <td>{x.y}</td>
          <td>
            <Flex alignItems="center" height="25px">
              <BarChartBar style={springs[i]} />
              <Text fontWeight={600} ml={2}>
                {x.x}
              </Text>
            </Flex>
          </td>
        </tr>
      ))}
    </BarChartTable>
  );
};
