import { Flex, Text } from "@coaching-culture/ui";
import { FaInfoCircle } from "react-icons/fa";

export const NoDataWidget = ({ text }: { text: string }) => (
  <Flex alignItems="center" justifyContent="center" p={6} height={"100%"}>
    <Text color="grey3" mb={0} mr={4} mt={1}>
      <FaInfoCircle fontSize="6em" />
    </Text>
    <Text mb={0} fontWeight={600} fontSize={5} color="grey2">
      {text}
    </Text>
  </Flex>
);
