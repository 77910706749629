import { FormLibraryCategory, FormLibraryItem } from "@coaching-culture/types";
import {
  Modal,
  Text,
  Box,
  Flex,
  Img,
  Loader,
  Grid,
  Panel,
  Button,
  PanelInset,
} from "@coaching-culture/ui";
import axios from "axios";
import { ContentImage } from "components/ContentCard";
import { useEffect, useState } from "react";
import logoSrc from "../../../img/logo.png";
import helpSrc from "../../../img/help.svg";
import styled from "styled-components";

const CategoryLink = styled.button<{ active: boolean }>`
  border: 0;
  background-color: ${(props) =>
    props.active ? props.theme.colors.grey4 : "transparent"};
  font-weight: 500;
  cursor: pointer;
  padding: 3px;
`;

export type SurveyLibraryModalProps = {
  onClose: () => void;
  onSelect: (item: FormLibraryItem) => void;
  type: "survey" | "feedback";
};

export type CardProps = {
  title: string;
  description?: string;
  image: string;
  onSelect: () => void;
};

const Card = ({ title, image, description, onSelect }: CardProps) => {
  return (
    <Panel style={{ overflow: "hidden" }} color="none">
      <ContentImage
        src={process.env.REACT_APP_STATIC_ROOT + "/" + image}
        width={"100%"}
      />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        flex="1"
        p={3}
      >
        <div>
          <Text fontWeight={600} fontSize={4} lineHeight={1.25} mr={1} mb={1}>
            {title}
          </Text>
          <Box mb={4}>
            {(description ?? "")
              .split("\n")
              .filter((x) => x.trim())
              .map((x) => (
                <Text fontSize={3} mb={1}>
                  {x}
                </Text>
              ))}
          </Box>
        </div>
        <Flex alignItems="center">
          <Button onClick={onSelect} mr={1} color="primary">
            Import
          </Button>
        </Flex>
      </Flex>
    </Panel>
  );
};

export function SurveyLibraryModal({
  onClose,
  onSelect,
  type,
}: SurveyLibraryModalProps) {
  const [forms, setForms] = useState<FormLibraryItem[] | null>(null);
  const [cats, setCats] = useState<FormLibraryCategory[]>([]);
  const [cat, setCat] = useState<string>("");

  useEffect(() => {
    axios.get("/api/form-library").then(({ data }) => setForms(data));
    axios.get("/api/form-library-categories").then(({ data }) => setCats(data));
  }, []);

  const items = (forms ?? []).filter((x) => x.type === type);

  const catOpts = [
    {
      id: "",
      name: "All",
    },
    ...cats.filter((x) => items.some((f) => f.categoryId === x.id)),
  ];

  return (
    <Modal
      showCloseButton
      onClose={onClose}
      width={1000}
      height={["auto", "80%"]}
      p={3}
    >
      <Flex alignItems="center" mb={3}>
        <Img src={logoSrc} height={50} mr={2} />
        <Text fontSize={4} fontWeight={500}>
          {type === "survey" ? "Survey Library" : "Feedback Library"}
        </Text>
      </Flex>
      <PanelInset p={2} mb={3}>
        <Flex alignItems="center" flexDirection={["column", "row"]}>
          <Img src={helpSrc} height={90} mr={3} mb={[2, 0]} />
          <div>
            <Text fontSize={4} fontWeight={600}>
              What's this?
            </Text>
            <Text>
              Take advantage of our years of experience by importing these
              pre-made surveys to get started instantly.
            </Text>
          </div>
        </Flex>
      </PanelInset>
      {forms == null ? (
        <Box p={5}>
          <Loader />
        </Box>
      ) : (
        <Flex flexDirection={["column", "row"]} minHeight={0} flex="1">
          <Flex flex={["1", "0 0 200px"]} flexDirection="column" mb={3}>
            {catOpts.map((x) => (
              <CategoryLink onClick={() => setCat(x.id)} active={x.id === cat}>
                {x.name}
              </CategoryLink>
            ))}
          </Flex>
          <Box
            overflowY={["visible", "auto"]}
            style={{ flex: 1 }}
            p={[0, 3]}
            pt={[0, 0]}
          >
            <Grid gridTemplateColumns={["100%", "100%", "1fr 1fr"]} gridGap={3}>
              {items
                .filter((x) => cat === "" || cat === x.categoryId)
                .map((x) => (
                  <Card
                    image={x.icon}
                    title={x.name}
                    description={x.description}
                    onSelect={() => onSelect(x)}
                  />
                ))}
            </Grid>
          </Box>
        </Flex>
      )}
    </Modal>
  );
}
