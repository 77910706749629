import {
  ConversationDto,
  ConversationOccurrenceDto,
} from "@coaching-culture/types";

import {
  Box,
  Button,
  Flex,
  Loader,
  Modal,
  Panel,
  Text,
} from "@coaching-culture/ui";

import { useUser } from "auth";

import {
  addMonths,
  endOfDay,
  format,
  fromUnixTime,
  getUnixTime,
  getWeek,
  isThisWeek,
  isToday,
  isYesterday,
  startOfDay,
} from "date-fns";

import { groupBy, sortBy } from "lodash";

import {
  useCancelConversation,
  useCancelConversationOccurrence,
  useConversations,
  useRescheduleConversation,
} from "queries/conversations";

import { useState } from "react";

import ReactDatePicker from "react-datepicker";

import {
  FaArrowCircleRight,
  FaCommentDots,
  FaInfoCircle,
  FaPlusCircle,
} from "react-icons/fa";

import styled from "styled-components";
import DotMenuText from "components/DotMenuText";
import { CancelConversationModal } from "pages/Solutions/Performance/CancelConversationModal";
import { ScrollableContainer } from "components/ScrollableContainer";
import WidgetHeader from "components/WidgetHeader";
import { CustomCalendarComponent } from "components/CalendarAltStyle";
import { useMyRelationships } from "queries/team";

const DateContainer = styled.div<{ completed: boolean; cancelled: boolean }>`
  padding-left: 18px;
  margin-left: 18px;
  padding-bottom: 12px;
  position: relative;

  &:before {
    content: "";
    display: block;
    background-color: ${(props) =>
      props.completed
        ? props.theme.colors.positive
        : props.cancelled
        ? props.theme.colors.danger
        : props.theme.colors.primary};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
    left: -9px;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    background-color: ${(props) => props.theme.colors.grey3};
    height: calc(100% - 10px);
    position: absolute;
    left: 0;
    top: 6px;
  }
`;

type ConvoAndInstance = ConversationDto & ConversationOccurrenceDto;

const Convo = ({ conversation }: { conversation: ConvoAndInstance }) => {
  const [user] = useUser();
  const [cancelling, setCancelling] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const rescheduleConvo = useRescheduleConversation(
    conversation.id,
    conversation.index
  );
  const cancelConversation = useCancelConversation(conversation.id);
  const cancelConversationOccurence = useCancelConversationOccurrence(
    conversation.id,
    conversation.index
  );
  const [date, setDate] = useState<Date>(new Date());

  const isCancelled = conversation.cancelledOn !== null;
  const isComplete = conversation.completedOn !== null;

  const reschedule = async () => {
    if (getUnixTime(date) === conversation.scheduledDate) {
      setRescheduling(false);
      return;
    }
    await rescheduleConvo.mutateAsync(date);
    setRescheduling(false);
  };

  const confirmCancellation = async (all: boolean) => {
    setCancelling(false);
    if (all) {
      await cancelConversation.mutateAsync();
    } else {
      await cancelConversationOccurence.mutateAsync();
    }
  };

  const onItemClick = (cmd: string) => {
    switch (cmd) {
      case "cancel":
        setCancelling(true);
        break;
      case "reschedule":
        setDate(fromUnixTime(conversation.scheduledDate));
        setRescheduling(true);
        break;
    }
  };

  return (
    <DateContainer cancelled={isCancelled} completed={isComplete}>
      <CancelConversationModal
        isOpen={cancelling}
        onCancel={() => setCancelling(false)}
        onConfirm={confirmCancellation}
      />
      {rescheduling && (
        <Modal>
          <Box p={4}>
            <Text mb={3} fontSize={18} fontWeight={600}>
              Reschedule Conversation
            </Text>
            <div style={{ width: "100%" }}>
              <ReactDatePicker
                selected={date}
                onChange={setDate}
                inline
                showTimeSelect
                timeIntervals={15}
              />
            </div>
            <Flex flexDirection={"row"} justifyContent={"center"} mt={3}>
              <Button color="positive" mr={2} onClick={reschedule}>
                Save
              </Button>
              <Button onClick={() => setRescheduling(false)}>Cancel</Button>
            </Flex>
          </Box>
        </Modal>
      )}
      <Flex mb={2} flexDirection={"row"}>
        <Flex flexGrow={8}>
          <Flex flexDirection={"column"}>
            <Flex alignItems="center" mb={1}>
              <Text fontWeight={600}>
                {format(
                  fromUnixTime(conversation.scheduledDate),

                  "do MMMM yyyy @ p"
                )}

                {conversation.recurrence !== "none" && (
                  <Text as="span" color="grey2" fontSize={2} ml={1}>
                    (Recurring)
                  </Text>
                )}
              </Text>
            </Flex>

            <Flex alignItems="center" mb={1}>
              <Text fontSize={12} fontWeight={500}>
                {conversation.type}{" "}
                {isComplete ? "(Complete)" : isCancelled ? "(Cancelled)" : ""}
              </Text>
            </Flex>

            <Flex alignItems="center" mb={2}>
              <Text fontStyle={"italic"} fontWeight={500} fontSize={12}>
                With{" "}
                {conversation.manager.id === user.id
                  ? conversation.directReport.name
                  : conversation.manager.name}
                {user.id === conversation.directReport.id
                  ? "(Manager)"
                  : "(Direct Report)"}
              </Text>
            </Flex>
            <Flex>
              <Button
                icon={FaArrowCircleRight}
                color="primary"
                to={`/solutions/performance/conversations/${conversation.id}/${conversation.index}/play`}
                mr={1}
                small
              >
                Go To Conversation
              </Button>
              {!isCancelled && !isComplete === null && (
                <DotMenuText
                  color="primary"
                  text={"Options"}
                  items={[
                    {
                      label: "Cancel",
                      cmd: "cancel",
                    },
                    {
                      label: "Reschedule",
                      cmd: "reschedule",
                    },
                  ]}
                  onItemClick={(val, cmd) => onItemClick(cmd)}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </DateContainer>
  );
};

export function ConversationsWidget() {
  const { data: convos, isFetched } = useConversations();
  const { data: relationships } = useMyRelationships();

  const [startDate, setStartDate] = useState<Date>(
    sessionStorage.getItem("startDateHistoryWidget") !== null
      ? new Date(sessionStorage.getItem("startDateHistoryWidget"))
      : startOfDay(new Date())
  );

  const [endDate, setEndDate] = useState<Date>(
    sessionStorage.getItem("endDateHistoryWidget") !== null
      ? new Date(sessionStorage.getItem("endDateHistoryWidget"))
      : addMonths(new Date(), 1)
  );

  const mappedConvos = (convos ?? []).flatMap((x) =>
    x.occurences
      .map((o) => ({
        ...x,
        ...o,
        cancelledOn: x.cancelledOn ?? o.cancelledOn,
      }))
      .filter(
        (x) =>
          fromUnixTime(x.scheduledDate) >= startOfDay(startDate) &&
          fromUnixTime(x.scheduledDate) <= endOfDay(endDate)
      )
  );

  const humanizeDate = (value: Date) => {
    if (isYesterday(value)) {
      return "Yesterday";
    } else if (isToday(value)) {
      return "Today";
    } else if (isThisWeek(value)) {
      return "This Week";
    } else if (getWeek(new Date()) - getWeek(value) === 1) {
      return "Last Week";
    } else if (getWeek(new Date()) - getWeek(value) === -1) {
      return "Next Week";
    } else {
      return format(value, "MMMM yyyy");
    }
  };

  const groupConvos = (data: ConvoAndInstance[]) => {
    const entries = Object.entries(
      groupBy(data, (x) => humanizeDate(fromUnixTime(x.scheduledDate)))
    );

    return sortBy(entries, (x) => x[1][0].scheduledDate).map((x) => ({
      label: x[0],

      items: x[1],
    }));
  };

  const NoConvos = () => (
    <Flex alignItems="center" justifyContent="center" flex="1">
      <Text color="grey3" fontSize="3em" mr={2} mb={0} lineHeight={0}>
        <FaInfoCircle />
      </Text>
      <Text fontSize={4} fontWeight={600} color="grey2">
        No Conversations
      </Text>
    </Flex>
  );

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    sessionStorage.setItem("startDateHistoryWidget", start);
    setEndDate(end);
    sessionStorage.setItem("endDateHistoryWidget", end);
  };

  if (!isFetched) {
    return <Loader />;
  }

  return (
    <>
      <Panel width={"100%"}>
        <WidgetHeader
          title={"Upcoming Conversations"}
          icon={FaCommentDots}
          button={
            relationships?.some((relation) => relation.type === "manager") && {
              text: "Schedule Conversation",
              icon: FaPlusCircle,
              to: "/solutions/performance/conversations/create",
            }
          }
        />
        <Box p={4}>
          <Flex flexDirection={["column-reverse", "row"]}>
            <Flex mr={2} flex="3">
              {mappedConvos.length > 0 ? (
                <ScrollableContainer maxHeight={310}>
                  <div>
                    {groupConvos(mappedConvos).map((x) => (
                      <>
                        <Text mt={2} fontSize={4} fontWeight={500} mb={2}>
                          {x.label}
                        </Text>

                        {sortBy(x.items.reverse(), (x) => x.scheduledDate).map(
                          (x, i, self) => (
                            <Box mb={i === self.length - 1 ? 0 : 2}>
                              <Convo conversation={x} />
                            </Box>
                          )
                        )}
                      </>
                    ))}
                  </div>
                </ScrollableContainer>
              ) : (
                <NoConvos />
              )}
            </Flex>

            <Flex
              flexBasis={"1"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CustomCalendarComponent>
                <ReactDatePicker
                  calendarStartDay={1}
                  selected={startDate}
                  onChange={onChange}
                  inline
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  minDate={new Date()}
                />
              </CustomCalendarComponent>
            </Flex>
          </Flex>
        </Box>
      </Panel>
    </>
  );
}
