import styled from "styled-components";
import React from "react";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

type ProgressBarProps = {
  value: number;
  color?: string;
} & LayoutProps &
  SpaceProps;

const Container = styled.div`
  ${layout};
  height: 6px;
  background-color: ${(props) => props.theme.colors.grey4};
  border-radius: 2px;
  overflow: hidden;
  ${space};
`;

const Bar = styled.div<{ value: number; color: string }>`
  width: ${(props) => props.value * 100}%;
  height: 100%;
  background-color: ${(props) => props.theme.colors[props.color]};
  transition: width 0.3s ease;
`;

export const ProgressBar = ({ value, color, ...rest }: ProgressBarProps) => (
  <Container {...rest}>
    <Bar value={value} color={color ?? "primary"} />
  </Container>
);
