import { ContentType, UserContentDto } from "@coaching-culture/types";
import {
  Box,
  ExpandableContainer,
  Flex,
  Img,
  LinkButton,
  Panel,
  PanelHeader,
  Text,
  TimelineItem,
} from "@coaching-culture/ui";
import { format, fromUnixTime } from "date-fns";
import { groupBy, sortBy } from "lodash";
import { useState } from "react";
import { FaBatteryEmpty, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { hasCompleted, hasStarted, isAssigned } from "utils/content";

const NoContent = () => (
  <Flex
    alignItems="center"
    justifyContent={"center"}
    p={5}
    flexDirection={"column"}
  >
    <FaBatteryEmpty color="#ddd" size="6em" />
    <Text color="grey3" fontWeight={600} fontSize={5}>
      No Content
    </Text>
  </Flex>
);

const ContentTimeline = ({ item }: { item: UserContentDto }) => {
  const assignments = item.assignments.map((x) => ({
    type: "assigned",
    date: x.assignedOn,
  }));

  const started = item.attempts.map((x) => ({
    type: "started",
    date: x.startedOn,
  }));

  const completed = item.attempts
    .filter((x) => x.completedOn != null)
    .map((x) => ({
      type: "completed",
      date: x.completedOn,
    }));

  const items = [...assignments, ...started, ...completed]
    .sort((a, b) => a.date - b.date)
    .reverse();

  return (
    <Box px={"24px"} pt={2}>
      {items.map((x) => (
        <TimelineItem>
          <Flex alignItems={"center"}>
            <Flex flex={1} mr={3}>
              <Text fontWeight={600}>
                {format(fromUnixTime(x.date), "do MMM yyyy p")}
              </Text>
            </Flex>
            <Flex flex={2}>
              {x.type === "assigned" ? (
                <Text color="grey2">Assigned</Text>
              ) : x.type === "started" ? (
                <Text color="warning">Started</Text>
              ) : (
                <Text fontWeight={600} color="positive">
                  Completed
                </Text>
              )}
            </Flex>
          </Flex>
        </TimelineItem>
      ))}
    </Box>
  );
};

export type ContentDetailsTableProps = {
  items: UserContentDto[];
  contentType: ContentType;
};

export function LessonDetailsTable({ items }: { items: UserContentDto[] }) {
  const [exp, setExp] = useState<string>("");

  const categories = Object.entries(groupBy(items, (x) => x.category.name));
  return (
    <>
      {categories.map((category, i) => (
        <Panel mb={i !== categories.length - 1 ? 3 : 0} p={2}>
          <PanelHeader mb={2}>
            <Text as={"h2"} fontSize={4} fontWeight={600}>
              {category[0]}
            </Text>
          </PanelHeader>
          <Flex width={"100%"} pb={2} borderBottom={1} px={2}>
            <Flex width={1 / 2}>
              <Text fontWeight={600}>Topics</Text>
            </Flex>
            <Flex width={1 / 4}>
              <Text fontWeight={600}>Status</Text>
            </Flex>
            <Flex width={1 / 4} />
          </Flex>
          {sortBy(category[1], "orderIndex").map((x) => (
            <Flex flexDirection="column" borderBottom={1}>
              <Flex p={2}>
                <Flex width={1 / 2}>
                  <Flex alignItems="center">
                    <Img
                      mr={2}
                      src={process.env.REACT_APP_STATIC_ROOT + "/" + x.icon}
                      width="40px"
                    />
                    <Text fontWeight={600}>{x.name}</Text>
                  </Flex>
                </Flex>
                <Flex width={1 / 4} alignItems={"center"}>
                  {hasCompleted(x) ? (
                    <Text color="positive" fontWeight={600}>
                      Completed
                    </Text>
                  ) : hasStarted(x) ? (
                    <Text color="warning" fontWeight={600}>
                      Started
                    </Text>
                  ) : isAssigned(x) ? (
                    <Text color="primary" fontWeight={600}>
                      Assigned
                    </Text>
                  ) : (
                    <Text fontWeight={600}>Available</Text>
                  )}
                </Flex>
                <Flex width={1 / 4} justifyContent={"flex-end"}>
                  {x.assignments.length + x.attempts.length > 0 && (
                    <LinkButton
                      onClick={() =>
                        setExp((old) => (old === x.id ? "" : x.id))
                      }
                    >
                      <Text as="span" mr={1} fontWeight={600}>
                        Expand
                      </Text>
                      {exp === x.id ? (
                        <FaChevronUp style={{ position: "relative", top: 2 }} />
                      ) : (
                        <FaChevronDown
                          style={{ position: "relative", top: 2 }}
                        />
                      )}
                    </LinkButton>
                  )}
                </Flex>
              </Flex>
              <ExpandableContainer expanded={exp === x.id}>
                <ContentTimeline item={x} />
              </ExpandableContainer>
            </Flex>
          ))}
        </Panel>
      ))}
    </>
  );
}

export function ContentDetailsTable({
  items,
  contentType,
}: ContentDetailsTableProps) {
  const [exp, setExp] = useState<string>("");

  const contentTypeName = contentType === "module" ? "Module" : "Topic";
  return (
    <Flex flexDirection={"column"} p={2}>
      <Flex width={"100%"} pb={2} borderBottom={1} px={2}>
        <Flex width={1 / 2}>
          <Text fontWeight={600}>{contentTypeName}</Text>
        </Flex>
        <Flex width={1 / 4}>
          <Text fontWeight={600}>Status</Text>
        </Flex>
        <Flex width={1 / 4} />
      </Flex>
      {items.length === 0 && <NoContent />}
      {items.map((x) => (
        <Flex flexDirection="column" borderBottom={1}>
          <Flex p={2}>
            <Flex width={1 / 2}>
              <Flex alignItems="center">
                <Img
                  mr={2}
                  src={process.env.REACT_APP_STATIC_ROOT + "/" + x.icon}
                  width="40px"
                />
                <Text fontWeight={600}>{x.name}</Text>
              </Flex>
            </Flex>
            <Flex width={1 / 4} alignItems={"center"}>
              {hasCompleted(x) ? (
                <Text color="positive" fontWeight={600}>
                  Completed
                </Text>
              ) : hasStarted(x) ? (
                <Text color="warning" fontWeight={600}>
                  Started
                </Text>
              ) : isAssigned(x) ? (
                <Text color="primary" fontWeight={600}>
                  Assigned
                </Text>
              ) : (
                <Text fontWeight={600}>Available</Text>
              )}
            </Flex>
            <Flex width={1 / 4} justifyContent={"flex-end"}>
              {x.assignments.length + x.attempts.length > 0 && (
                <LinkButton
                  onClick={() => setExp((old) => (old === x.id ? "" : x.id))}
                >
                  <Text as="span" mr={1} fontWeight={600}>
                    Expand
                  </Text>
                  {exp === x.id ? (
                    <FaChevronUp style={{ position: "relative", top: 2 }} />
                  ) : (
                    <FaChevronDown style={{ position: "relative", top: 2 }} />
                  )}
                </LinkButton>
              )}
            </Flex>
          </Flex>
          <ExpandableContainer expanded={exp === x.id}>
            <ContentTimeline item={x} />
          </ExpandableContainer>
        </Flex>
      ))}
    </Flex>
  );
}
