import { Button, Flex, Panel } from "@coaching-culture/ui";
import { FaPlusCircle } from "react-icons/fa";
import { ExplainImage } from "components/ExplainImage";
import imgSrc from "../../../img/goals.svg";
import { GoalList } from "./GoalDisplay";
import { PageHeader } from "components/PageHeader";
import { useGoalTerminology } from "auth/OrgProvider";
import { useUser } from "auth";
import { useHistory } from "react-router";

export const Goals = () => {
  const goalTerms = useGoalTerminology();
  const [user] = useUser();
  const history = useHistory();

  const reviewGoal = (goalId: string) => {
    history.push(`/solutions/performance/goals/${goalId}/review`);
  };

  return (
    <>
      <PageHeader
        text={goalTerms.goal.asPluralTitle()}
        subtitle={`${goalTerms.goal.asPluralTitle()} you've set for yourself`}
      />
      <Panel p={[2, 3]}>
        <ExplainImage
          src={imgSrc}
          title={`${goalTerms.goal.asPluralTitle()} and ${goalTerms.objective.asPluralTitle()}`}
          body={`Setting ${goalTerms.goal.asPluralBody()} is a great way to remain focused and stretch us to achieve our potential.\nUse ${goalTerms.objective.asPluralBody()} to create steps towards your goals and link your ${goalTerms.goal.asPluralBody()} to the overall aims of the organisation to make sure your ${goalTerms.goal.asPluralBody()}  are relevant.`}
          action={
            <Button
              large
              to="/solutions/performance/goals/create"
              icon={FaPlusCircle}
              color="primary"
            >
              Create a new {goalTerms.goal.asBody()}
            </Button>
          }
        />
        <Flex mb={0} width="100%" flexDirection="column" pt={3}>
          <GoalList
            reviewGoal={(goalId) => reviewGoal(goalId)}
            userId={user.id}
          />
        </Flex>
      </Panel>
    </>
  );
};
