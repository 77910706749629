import { FormItem, FormItemSpec } from "@coaching-culture/types";
import { Text } from "@coaching-culture/ui";
import React from "react";
import { Answer } from ".";
import { Content } from "./Content";
import { FreeText } from "./FreeText";
import { MultipleChoice } from "./MultipleChoice";
import { Rating } from "./Rating";

export type FormItemRendererProps = {
  onChange: (ans: Answer | null, item: FormItem | FormItemSpec) => void;
  value: Answer | null;
  item: FormItem | FormItemSpec;
  index: number;
  count: number;
  reverse: boolean;
  onConfirm: (item: FormItem | FormItemSpec) => void;
  sectionName: string | null;
};

export const FormItemRenderer = ({
  onChange,
  value,
  item,
  index,
  count,
  reverse,
  onConfirm,
  sectionName,
}: FormItemRendererProps) => {
  if (item.type === "heading") {
    return (
      <Text fontSize={4} mb={3}>
        {item.content}
      </Text>
    );
  } else if (item.type === "free_text") {
    return (
      <FreeText
        content={item.content}
        fieldType={item.fieldType}
        value={value?.answer?.toString()}
        onChange={(answer: string) => onChange({ answer }, item)}
        index={index}
        count={count}
        sectionName={sectionName}
        reverse={reverse}
        onConfirm={() => onConfirm(item)}
      />
    );
  } else if (item.type === "content") {
    return (
      <Content
        key={item.id}
        index={index}
        count={count}
        sectionName={sectionName}
        content={item.content}
        title={item.title}
        onConfirm={() => onConfirm(item)}
        reverse={reverse}
      />
    );
  } else if (item.type === "multiple_choice") {
    return (
      <MultipleChoice
        key={item.id}
        index={index}
        count={count}
        content={item.content}
        options={item.answers}
        value={value?.answer as string}
        sectionName={sectionName}
        onChange={(answer: string) => {
          onChange({ answer }, item);
          setTimeout(() => {
            onConfirm(item);
          }, 300);
        }}
        reverse={reverse}
      />
    );
  } else if (item.type === "rating") {
    return (
      <Rating
        noEvidenceLabel={item.noEvidenceLabel}
        content={item.content}
        allowNa={item.allowNa}
        type={item.ratingType}
        requiresEvidence={item.requiresEvidence}
        value={value}
        sectionName={sectionName}
        onChange={(a) => onChange(a, item)}
        index={index}
        count={count}
        reverse={reverse}
        reverseScale={item.reverseScale}
        onConfirm={() => onConfirm(item)}
        lowEndLabel={item.lowRangeLabel}
        highEndLabel={item.highRangeLabel}
      />
    );
  }
};
