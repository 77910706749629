import { format, fromUnixTime } from "date-fns";
import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { CoachingCultureColorName } from "../theme";
import Label from "./Label";
import Text from "./Text";

export type DatePillProps = {
  value: Date | number | null | undefined;
  color?: CoachingCultureColorName;
  dangerIfEmpty?: boolean;
  dateOnly?: boolean;
};

export const DatePill = ({
  value,
  color = "grey2",
  dangerIfEmpty = false,
  dateOnly = false,
}: DatePillProps) => {
  const asDate = typeof value === "number" ? fromUnixTime(value) : value;

  const col = dangerIfEmpty && value == null ? "danger" : color;

  const formatStr = dateOnly ? "do MMM yyyy" : "dd MMM 'at' h:mm a";

  return (
    <Label
      icon={FaCalendarAlt}
      color={col}
      size="small"
      style={{ width: dateOnly ? 120 : undefined }}
    >
      <Text as="span" fontWeight={500} color="inherit">
        {asDate == null ? "Never" : format(asDate, formatStr)}
      </Text>
    </Label>
  );
};
