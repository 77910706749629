import { ProductVisibility } from "@coaching-culture/types";
import { useUser } from "auth";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { Redirect } from "react-router";

export function SafeRedirect() {
  const [user] = useUser();
  const productVis = useAllProductVisibility();

  if (user == null) {
    return <Redirect to="/login" />;
  }

  if (
    Object.entries(productVis)
      .filter((x) => x[0] !== "diagnostics")
      .map((x) => x[1])
      .every((x) => x !== ProductVisibility.Enabled)
  ) {
    return <Redirect to="/community" />;
  }

  return <Redirect to="/dashboard" />;
}
