import { InsightsData, UserFilter } from "@coaching-culture/types";
import { Button, Flex, Loader } from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { FilterModal } from "components/FilterPanel";
import { PageHeader } from "components/PageHeader";
import Insights from "pages/Solutions/Insights";
import qsStringify from "qs-stringify";
import React, { useEffect, useState } from "react";

const OrgInsights = () => {
  const [data, setData] = useState<InsightsData | null>(null);
  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });
  const [showFilters, setShowFilters] = useState(false);

  const filterString = qsStringify({ filter } as any);

  useEffect(() => {
    axios
      .get(`/api/insights?${filterString}`)
      .then(({ data }) => setData(data));
  }, [filterString]);

  const onCancel = (result) => {
    if (result) {
      setFilter(result);
    }

    setShowFilters(false);
  };

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        filter={filter}
        onClose={(result) => {
          onCancel(result);
        }}
      />
      <CenterColumn>
        <PageHeader
          text="Insights"
          subtitle="An overview of the data collected by the platform"
          helpIdent="success-insights-main"
        />
        {data == null ? (
          <Loader />
        ) : (
          <>
            <Flex mb={1} justifyContent={"flex-end"}>
              <Button color="primary" onClick={() => setShowFilters(true)}>
                Filters ({filter.groups.length + filter.userFields.length})
              </Button>
            </Flex>
            <Insights data={data} />
          </>
        )}
      </CenterColumn>
    </>
  );
};

export default OrgInsights;
