import {
  ContentItem,
  HelpItem,
  HelpItemIdent,
  UserContentDto,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const getContent = () =>
  axios.get<UserContentDto[]>("/api/v2/content").then(({ data }) => data);

export const useAvailableContent = () => useQuery(["content"], getContent);
export async function getContentForUser(
  userId: string,
): Promise<UserContentDto[]> {
  return await axios
    .get<UserContentDto[]>(`/api/v2/users/${userId}/content`)
    .then(({ data }) => data);
}

export const getAllHelpItems = () =>
  axios.get<HelpItem[]>("/api/help").then(({ data }) => data);

export const markHelpItemAsSeen = (id: string) =>
  axios.put(`/api/help/${id}/seen`);

export const useMarkHelpItemAsSeen = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(markHelpItemAsSeen, {
    onSuccess: () => {
      queryClient.invalidateQueries("helpitems");
    },
  });

  return mutation;
};

export const useHelpItems = () => useQuery("helpitems", getAllHelpItems);

export const useHelpItem = (ident: HelpItemIdent): HelpItem | undefined => {
  const { data } = useHelpItems();

  if (data == null) {
    return undefined;
  } else {
    return data.find((x) => x.ident === ident);
  }
};

export const useUserContent = (userId: string, disabled?: boolean) =>
  useQuery(["content", userId], () => getContentForUser(userId), {
    enabled: !disabled,
  });

export type ShareTarget = {
  type: "group" | "user";
  id: string;
};

const shareContent = (spec: ShareContentMutation) => {
  return axios.post("/api/shares", spec);
};

export type ShareContentMutation = {
  content: Pick<ContentItem, "id" | "type">[];
  targets: ShareTarget[];
};

export const useShareContent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(shareContent, {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"]);
      queryClient.invalidateQueries(["content"]);
    },
  });

  return mutation;
};
