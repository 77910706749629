import styled from "styled-components";
import {
  flexbox,
  space,
  color,
  layout,
  border,
  FlexboxProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
  BorderProps
} from "styled-system";

type FlexProps = FlexboxProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  BorderProps;

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${flexbox};
  ${space};
  ${color};
  ${layout};
  ${border};
`;

export default Flex;
