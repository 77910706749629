import { UserSummary } from "@coaching-culture/types";
import {
  IconToast,
  Input,
  Panel,
  useDebounce,
  useToast,
} from "@coaching-culture/ui";
import UserProfileImage from "components/UserProfileImage";
import { sortBy, escapeRegExp } from "lodash";
import { useMemo, useState } from "react";
import { FaThumbsUp } from "react-icons/fa";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;

const OptionPanel = styled(Panel)`
  position: absolute;
  left: 3px;
  top: calc(100% + 6px);
  max-width: 100%;
  width: 400px;
  height: 200px;
  z-index: 1000;
  background: white;
  overflow-y: auto;
`;

const OptionButton = styled.button`
  background: white;
  border: 0;
  padding: 6px;
  padding-left: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
`;

const Mark = styled.mark`
  border-radius: 3px;
`;

const Highlighted = ({
  text = "",
  highlight = "",
}: {
  text: string;
  highlight: string;
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <Mark key={i}>{part}</Mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  );
};

export type UserSearchInputProps = {
  options: UserSummary[];
  onSelect: (item: UserSummary) => void;
};

export function UserSearchInput({ options, onSelect }: UserSearchInputProps) {
  const [search, setSearch] = useState<string>("");
  const [focus, setFocus] = useState(false);
  const pushToast = useToast();

  const debouncedSearch = useDebounce(search, 250);

  const displayedOptions = useMemo(
    () =>
      debouncedSearch.length < 3
        ? []
        : sortBy(
            options.filter((x) =>
              `${x.name} ${x.email}`
                .toLowerCase()
                .includes(debouncedSearch.toLowerCase())
            ),
            (x) => x.name
          ),
    [debouncedSearch, options]
  );

  const selectUser = (user: UserSummary) => {
    setSearch("");
    onSelect(user);
  };

  const onPaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = ev.clipboardData;
    const pastedData = clipboardData.getData("Text");

    console.log(pastedData);

    let count = 0;

    const fixName = (s: string) =>
      s.replace(/^(\s*)|(\s*)$/g, "").replace(/\s+/g, " ");

    pastedData.split("\n").forEach((x) => {
      const item = options.find(
        (user) =>
          user.email === x.trim().toLowerCase() ||
          fixName(user.name).trim().toLowerCase() === x.trim().toLowerCase()
      );
      if (item != null) {
        ev.preventDefault();
        count++;
        onSelect(item);
      }
    });

    if (count > 0) {
      pushToast({
        content: (
          <IconToast
            icon={FaThumbsUp}
            iconColor="primary"
            text={`${count} user(s) added.`}
          />
        ),
      });
    }
  };

  const onKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" && displayedOptions.length > 0) {
      selectUser(displayedOptions[0]);
    }
  };

  return (
    <Container>
      <Input
        autoFocus
        value={search}
        onPaste={onPaste}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(ev) => setSearch(ev.target.value)}
        onKeyPress={onKeyPress}
        placeholder="Search..."
      />
      {displayedOptions.length > 0 && search.length > 0 && focus && (
        <OptionPanel boxShadow={2} color="none">
          {displayedOptions.map((x) => (
            <OptionButton onMouseDown={() => selectUser(x)}>
              <UserProfileImage
                size="small"
                name={x.name}
                profileImage={x.profileImage}
                mr={2}
              />
              <Highlighted text={x.name} highlight={debouncedSearch} />
            </OptionButton>
          ))}
        </OptionPanel>
      )}
    </Container>
  );
}
