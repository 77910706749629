import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { Flex, Text, Loader, Panel } from "@coaching-culture/ui";
import { FormCompletionItem, SurveyPlayer } from "components/SurveyPlayer";
import { Survey } from "@coaching-culture/types";
import { useSpring, animated } from "react-spring";
import { useUser } from "auth";
import chooseSrc from "../../../img/choose.svg";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  flex: 1;
`;

const Img = styled.img`
  width: 50%;
`;

const AniPanel = animated(Panel);

function Done({ text }: { text: string }) {
  const spring = useSpring({
    from: {
      y: 50,
    },
    y: 0,
  });

  return (
    <Flex alignItems="center" justifyContent="center" flex="1">
      <AniPanel p={3} width={600} style={spring} m={2}>
        <Flex
          justifyContent="center"
          alignItems="center"
          p={3}
          flexDirection="column"
        >
          <Img src={chooseSrc} />
          <Text as="h1" fontSize={5} textAlign="center" mt={5} mb={2}>
            {text}
          </Text>
          <Link to="/dashboard">Return to Dashboard</Link>
        </Flex>
      </AniPanel>
    </Flex>
  );
}

export function SurveyRoute() {
  const [user] = useUser();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [status, setStatus] = useState<
    "survey" | "done" | "noaccess" | "alreadycompleted"
  >("survey");

  useEffect(() => {
    axios
      .get<Survey>(`/api/surveys/${id}`)
      .then(({ data }) => {
        if (
          user.id !== "FAKER" &&
          !data.recipients.some((x) => x.id === user.id)
        ) {
          setStatus("noaccess");
        } else if (
          data.recipients.some((x) => x.id === user.id && x.completedOn != null)
        ) {
          setStatus("alreadycompleted");
        } else {
          setStatus("survey");
          setSurvey(data);
        }
      })
      .catch(() => {
        history.push("/solutions/surveys");
      });
  }, [id, user.id, history]);

  const onCompletion = (answers: FormCompletionItem[]) => {
    axios
      .post(`/api/surveys/${survey.id}/complete`, answers)
      .then(() => {
        setStatus("done");
      })
      .catch((e) => {
        window.alert("There was an error submitting your response.");
      });
  };

  const loading = survey == null;

  return (
    <Container>
      {status === "noaccess" ? (
        <Done text={"You don't have access to this survey"} />
      ) : status === "done" || status === "alreadycompleted" ? (
        <Done
          text={
            status === "done"
              ? "Thank you for completing the survey."
              : "You've already completed this survey"
          }
        />
      ) : loading ? (
        <Loader />
      ) : (
        <SurveyPlayer
          email={user.email}
          form={survey.form.form}
          onCompletion={onCompletion}
        />
      )}
    </Container>
  );
}
