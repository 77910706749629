import { ContentItem, UserContentDto } from "@coaching-culture/types";
import { sortBy } from "lodash";
import { Flex, Grid, IconButton, Panel, Text } from "@coaching-culture/ui";
import { ContentCard } from "components/ContentCard";
import ShareModal, { ShareConfig } from "components/ShareModal";
import React, { useState } from "react";
import { FaList, FaTh } from "react-icons/fa";
import { useHistory } from "react-router";
import { fromUnixTime } from "date-fns";

const getLastAttempt = (content: UserContentDto) =>
  content.attempts
    .map((x) => fromUnixTime(x.startedOn))
    .sort()
    .reverse()[0];

const getActiveAttempt = (content: UserContentDto) =>
  content.attempts.find((x) => x.completedOn == null);

type UserContentDisplayProps = {
  content: UserContentDto;
  onShare: () => void;
  onSelect: () => void;
  displayMode: "list" | "grid";
};

const UserContentDisplay = ({
  content,
  onSelect,
  onShare,
  displayMode,
}: UserContentDisplayProps) => {
  const { name, description, icon, attempts } = content;

  const lastAttempt = getLastAttempt(content);
  const activeAttempt = getActiveAttempt(content);
  const completed = attempts
    .filter((x) => x.completedOn != null)
    .map((x) => x.completedOn)
    .sort()[0];

  return (
    <ContentCard
      accessReasons={content.assignmentReasons}
      image={icon}
      onSelect={onSelect}
      onShare={onShare}
      title={name}
      activeAttempt={activeAttempt != null}
      canShare={content.canShare}
      completed={completed != null ? fromUnixTime(completed) : null}
      description={description}
      lastAttempt={lastAttempt}
      displayMode={displayMode}
    />
  );
};

const getContentUrl = (content: ContentItem) => {
  const type =
    content.type === "module"
      ? "mindset"
      : content.type === "lesson"
        ? "lessons"
        : content.type === "learncast"
          ? "learncast"
          : "assessments";

  return `/solutions/${type}/modules/${content.slug}`;
};

export default function ContentList({ items }: { items: UserContentDto[] }) {
  const [displayMode, setDisplayMode] = useState<"list" | "grid">("grid");
  const [sharingModule, setSharingModule] = useState<ShareConfig | null>(null);
  const history = useHistory();

  const gotoContent = (content: UserContentDto) => {
    history.push(getContentUrl(content));
  };

  const shareContent = (content: UserContentDto) => {
    setSharingModule({
      type: content.type,
      itemId: content.id,
      targetId: null,
      targetType: null,
    });
  };

  const modules =
    (items ?? []).length === 0 ? (
      <Text textAlign="center" fontSize={3} fontWeight={600} m={3}>
        No Modules Available
      </Text>
    ) : (
      sortBy(items, "orderIndex").map((x) => (
        <UserContentDisplay
          content={x}
          key={x.id}
          onSelect={() => gotoContent(x)}
          onShare={() => shareContent(x)}
          displayMode={displayMode}
        />
      ))
    );

  return (
    <>
      {sharingModule && (
        <ShareModal
          config={sharingModule}
          onClose={() => setSharingModule(null)}
        />
      )}
      <Flex justifyContent="flex-end" mb={3}>
        <IconButton
          icon={FaList}
          color={displayMode === "list" ? "body" : "grey2"}
          onClick={() => setDisplayMode("list")}
          style={{ fontSize: "1.3em" }}
        />
        <IconButton
          icon={FaTh}
          color={displayMode === "grid" ? "body" : "grey2"}
          onClick={() => setDisplayMode("grid")}
          style={{ fontSize: "1.3em" }}
          ml={1}
        />
      </Flex>
      {displayMode === "list" ? (
        <Panel color="primary">{modules}</Panel>
      ) : (
        <Grid
          gridGap={3}
          gridTemplateColumns={["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"]}
        >
          {modules}
        </Grid>
      )}
    </>
  );
}
