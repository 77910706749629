import { useState, useEffect } from "react";
import axios from "axios";
import { InsightsData } from "@coaching-culture/types";
import { Box, Loader } from "@coaching-culture/ui";
import Insights from "./Insights";

const TeamInsights = () => {
  const [data, setData] = useState<InsightsData | null>(null);

  useEffect(() => {
    axios.get("/api/insights/team").then(({ data }) => setData(data));
  }, []);

  return <Box>{data == null ? <Loader /> : <Insights data={data} />}</Box>;
};

export default TeamInsights;
