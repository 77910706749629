import { MeasureSpec } from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Circle,
  Flex,
  FormInput,
  IconButton,
  Loader,
  Text,
} from "@coaching-culture/ui";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { FaCircle, FaGripVertical, FaPen, FaTrash } from "react-icons/fa";
import { arrMove } from "utils";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import { MeasureOptionModal } from "./MeasureOptionModal";

export type MeasureFormProps = {
  value: MeasureSpec;
  onSave: (value: MeasureSpec) => void;
  disabled: boolean;
};

export const MeasureForm = ({ value, onSave, disabled }: MeasureFormProps) => {
  const [opts, setOpts] = useState<MeasureSpec["options"]>(value.options);
  const [editing, setEditing] = useState<MeasureSpec["options"][number] | null>(
    null
  );
  const { register, handleSubmit, errors } = useForm<MeasureSpec>({
    defaultValues: value,
  });

  const onSubmit = (values: MeasureSpec) => {
    if (opts.length < 2) {
      return window.alert("At least 2 options are required");
    }

    onSave({
      ...values,
      options: opts,
    });
  };

  const newPoint = () => {
    setEditing({
      id: "",
      name: "",
    });
  };

  const remove = (idx: number) => {
    setOpts(opts.filter((_, i) => i !== idx));
  };

  const onDragEnd = async (result: DropResult) => {
    setOpts(arrMove(opts, result.source.index, result.destination.index));
  };

  const saveOption = (value: MeasureSpec["options"][number]) => {
    if (value.id == null || value.id === "") {
      setOpts([...opts, value]);
    } else {
      setOpts(opts.map((x) => (x.id === value.id ? value : x)));
    }
    setEditing(null);
  };

  return (
    <Box>
      {disabled && <Loader overlay />}
      {editing && (
        <MeasureOptionModal
          value={editing}
          onSave={saveOption}
          onCancel={() => setEditing(null)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="id" ref={register} />
        <FormInput
          ref={register({ required: true })}
          label="Name"
          error={errors.name}
          name="name"
        />

        <Text fontWeight={500} fontSize={4}>
          Ratings
        </Text>
        <Text fontWeight={600} fontSize={2} color="grey2" mb={2}>
          (Higher in the list is considered better)
        </Text>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {opts.map((x, i) => (
                  <Draggable key={i} draggableId={i.toString()} index={i}>
                    {(provided, snapshot) => (
                      <Flex
                        border={1}
                        borderRadius={6}
                        p={2}
                        mb={2}
                        alignItems="center"
                        key={i}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getDndItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Flex {...provided.dragHandleProps} alignItems="center">
                          <FaGripVertical color="#999" />
                        </Flex>
                        <Circle icon={FaCircle} color="primary" mr={2} ml={2} />
                        <Text style={{ flex: 1 }} fontWeight={500}>
                          {x.name}
                        </Text>
                        <IconButton
                          icon={FaPen}
                          color="grey"
                          mr={1}
                          onClick={() => setEditing(x)}
                        />
                        <IconButton
                          icon={FaTrash}
                          color="danger"
                          onClick={() => remove(i)}
                        />
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Flex mb={3}>
          <AddButton mr={2} onClick={newPoint}>
            Add New Option
          </AddButton>
        </Flex>
        <Flex style={{ gap: 6 }}>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button to="/success/settings/measures">Cancel</Button>
        </Flex>
      </form>
    </Box>
  );
};
