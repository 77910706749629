import { useUser } from "auth";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Impersonate = () => {
  const [, setToken] = useUser();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  useEffect(() => {
    setToken(token);
    history.push("/");
  }, [token, history, setToken]);

  return null;
};

export default Impersonate;
