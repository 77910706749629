import axios, { AxiosRequestConfig } from "axios";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import Flex from "./Flex";

const Container = styled.div<any>`
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
  margin-right: 6px;
`;

type UploaderProps = {
  onChange: any;
  value: string | null;
};

const FileUploader = ({ onChange, value }: UploaderProps) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const uploadImage = async () => {
    const file = (fileRef?.current?.files || [])[0];

    if (file == null) {
      return;
    }

    const filename = file.name;

    const res = await axios.post<{ url: string; key: string }>(
      "/api/uploads/signed",
      { filename }
    );

    const config: AxiosRequestConfig = {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );
        console.log("onUploadProgress", totalLength);
        if (totalLength !== null) {
          setUploadProgress(
            Math.round((progressEvent.loaded * 100) / totalLength)
          );
        }
      },
    };
    setUploadProgress(0);

    axios
      .put(res.data.url, new File([file], res.data.key), config)
      .then(() => {
        onChange(res.data.key);
      })
      .catch((err) => {
        alert(err.message);
        console.log(err.toJSON());
      })
      .finally(() => {
        setUploadProgress(null);
      });
  };

  const startUpload = () => {
    fileRef.current?.click();
  };

  return (
    <Flex>
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={uploadImage}
      />
      <Container>
        {uploadProgress != null
          ? `Uploading ${uploadProgress}%`
          : value || "No File Uploaded"}
      </Container>
      <Button onClick={startUpload} type="button">
        Change
      </Button>
    </Flex>
  );
};

export default FileUploader;
