import { DirectReportDto, GoalDto } from "@coaching-culture/types";
import { Flex, Text } from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { GoalEditor } from "pages/Solutions/Performance/GoalEditor";
import { useCreateGoal } from "queries/performance";
import { FaBullseye } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { animated, useSpring } from "react-spring";

import styled from "styled-components";

const SectionIcon = styled.div`
  color: white;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 3px;
`;

const Drawer = animated(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  overflow-y: auto;
`);

export type SetGoalDrawerProps = {
  onClose: () => void;
  user: DirectReportDto;
};

export const SetGoalDrawer = ({ onClose, user }: SetGoalDrawerProps) => {
  const styleProps = useSpring({
    y: "0%",
    from: {
      y: "100%",
    },
  });
  const terms = useGoalTerminology();

  const createGoal = useCreateGoal(user.id);
  const qc = useQueryClient();

  const onSave = async (goal: GoalDto) => {
    goal.userId = user.id;
    await createGoal.mutateAsync(goal);
    qc.invalidateQueries("team");

    onClose();
  };

  return (
    <Drawer style={styleProps}>
      <Flex p={"100px"} py={5} flexDirection="column">
        <Flex justifyContent={"center"}>
          <Flex alignItems={"center"} mb={5}>
            <SectionIcon>
              <FaBullseye fontSize={"1.5em"} />
            </SectionIcon>
            <Text fontWeight={600} ml={2}>
              {terms.goal.asPluralTitle()}
            </Text>
          </Flex>
        </Flex>
        <Text fontSize={4} fontWeight={600}>
          Set a new {terms.goal.asTitle()} for {user.name.split(" ")[0]}.
        </Text>
        <Text mb={5} fontWeight={500} color="grey1">
          Regular feedback is important
        </Text>
        <GoalEditor
          goal={null}
          onSave={onSave}
          loading={false}
          onCancel={onClose}
        />
      </Flex>
    </Drawer>
  );
};
