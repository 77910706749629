import { PostUserSpec } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormInput,
  FormSelect,
  FormTextArea,
  Loader,
  Panel,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import UserFieldInput from "components/UserFieldInput";
import { useUserFields } from "queries/users";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";
import { ProfilePictureInput } from "./ProfilePictureInput";
import tzData from "./tz.json";

type ProfileForm = {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  bio: string;
  profileImage: string | null;
  timezone: string;
  userFields: Record<number, string>;
};

export const ProfileSettings = () => {
  const [user, , setUser] = useUser();
  const { data: userFields, isFetched } = useUserFields();
  const loading = !isFetched;
  const pushToast = useToast();

  const tzOptions = useMemo(
    () =>
      Object.entries(tzData).map(([name, offset]) => ({
        label: `${name} - ${offset}`,
        value: name,
      })),
    []
  );

  const { register, handleSubmit, errors, reset, control, watch, setError } =
    useForm<ProfileForm>();

  useEffect(() => {
    const spec: ProfileForm = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      profileImage: user.profileImage,
      bio: user.bio,
      jobTitle: user.jobTitle,
      timezone: user.tz,
      userFields: user.userFields.reduce((acc, val) => {
        acc[val.id] = val.value;
        return acc;
      }, {}),
    };
    reset(spec);
  }, [user, reset]);

  const onSubmit = (values: ProfileForm) => {
    const spec: PostUserSpec = {
      ...values,
      userFields: Object.entries(values.userFields || [])
        .filter((x) => x[0] != null)
        .map((x) => ({
          id: x[0],
          value: x[1],
        })),
    };

    axios
      .put("/api/users/me", spec)
      .then(({ data }) => {
        setUser(data);
        pushToast({
          content: (
            <Flex alignItems="center" p={3} justifyContent="center">
              <FaCheckCircle color="green" size={"1.5em"} />
              <Text ml={2} fontWeight={600} fontSize={3}>
                Profile Saved!
              </Text>
            </Flex>
          ),
        });
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          setError("email", {
            type: "manual",
            message: "Email Taken",
          });
        } else {
          window.alert(err.response?.data);
        }
      });
  };

  return (
    <Panel p={[3, 5]} pt={5}>
      {loading && <Loader overlay />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection={["column", "row"]}
          alignItems={["center", "flex-start"]}
        >
          <Controller
            control={control}
            name="profileImage"
            defaultValue={null}
            render={({ value, onChange }) => (
              <ProfilePictureInput
                value={value}
                onChange={onChange}
                name={watch("name")}
                mb={[5, 0]}
              />
            )}
          />
          <Box pl={[0, 5]} style={{ flex: 1 }} width={["100%", "auto"]}>
            <Text
              fontSize={4}
              mb={3}
              style={{ borderBottom: "1px solid #ececec" }}
            >
              Personal Information
            </Text>
            <Flex
              alignItems={["stretch", "flex-start"]}
              flexDirection={["column", "row"]}
            >
              <FormInput
                name="firstName"
                label="First Name"
                error={errors.firstName}
                ref={register({ required: true })}
                style={{ flex: 1 }}
                mr={[0, 2]}
              />
              <FormInput
                name="lastName"
                label="Last Name"
                error={errors.lastName}
                ref={register({ required: true })}
                style={{ flex: 1 }}
                mr={[0, 2]}
              />
              <FormInput
                name="email"
                label="Email"
                type="email"
                error={errors.email}
                disabled={user.authMethod === "sso"}
                ref={register({ required: true })}
                style={{ flex: 1 }}
              />
            </Flex>
            <FormInput
              name="jobTitle"
              label="Job Title"
              type="text"
              error={errors.jobTitle}
              ref={register}
            />
            <FormTextArea
              name="bio"
              label="Bio"
              ref={register}
              height="160px"
            />
            <FormSelect
              name="timezone"
              label="Preferred Timezone"
              ref={register}
              options={tzOptions}
            />
            <Box mb={3}>
              {(userFields ?? []).map((x) => (
                <Controller
                  control={control}
                  name={`userFields[${x.id}]`}
                  defaultValue={""}
                  key={x.id}
                  render={({ value, onChange }) => (
                    <UserFieldInput
                      isFilter={false}
                      field={x}
                      value={value}
                      onChange={onChange}
                      error={null}
                    />
                  )}
                />
              ))}
            </Box>
            <Button color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Flex>
      </form>
    </Panel>
  );
};
