import { UserDisplay } from "@coaching-culture/types";
import {
  Box,
  Circle,
  DatePill,
  Flex,
  Label,
  Loader,
  Panel,
  Table,
  Text,
  UserFlag,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { ScrollableContainer } from "components/ScrollableContainer";
import { Toggle } from "components/Toggle";
import { differenceInDays, fromUnixTime } from "date-fns";
import { keyBy, sortBy } from "lodash";
import { useApplicableCollectiveGoals, useTeam } from "queries/performance";
import { useState } from "react";
import { FaBuilding, FaMedal, FaStar } from "react-icons/fa";
import { NoDataWidget } from "./NoDataWidget";

const isNewGoal = (date: number) =>
  differenceInDays(new Date(), fromUnixTime(date)) < 30;

export const TeamGoalWidget = () => {
  const data = useApplicableCollectiveGoals();
  const team = useTeam();
  const [user] = useUser();

  const [showAll, setShowAll] = useState(false);

  const toShow = showAll
    ? [...data.teamGoals, ...data.orgGoals]
    : data.teamGoals;

  const lms: Record<string, UserDisplay> = keyBy(
    team.data?.lineManagers ?? [],
    (x) => x.id,
  );

  lms[user.id] = user;

  return (
    <Panel gridColumn={["span 1", "span 1", "span 1", "span 2"]} width={"100%"}>
      <Flex borderBottom={1} height={83} justifyContent={"space-between"} p={4}>
        <Flex alignItems="center">
          <Circle icon={FaMedal} size={"medium"} color="primary" mr={2} />
          <Text
            fontSize={4}
            fontWeight={600}
            lineHeight={1}
            mr={1}
            color="black"
            as="h1"
          >
            Collective Goals
          </Text>
        </Flex>
        <Toggle
          leftLabel="Team Goals"
          rightLabel="All"
          value={showAll}
          onChange={setShowAll}
        />
      </Flex>
      {!data.isFetched ? (
        <Loader />
      ) : toShow.length === 0 ? (
        <NoDataWidget text="No Collective Goals" />
      ) : (
        <ScrollableContainer maxHeight={310}>
          <Table>
            <thead>
              <tr>
                <th style={{ width: 200 }}>Owner</th>
                <th>Goal</th>
                <th style={{ width: 240 }}>Date Set</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(toShow, (x) => x.createdOn)
                .reverse()
                .map((x) => (
                  <tr>
                    <td>
                      {"lineManagerId" in x ? (
                        <UserFlag
                          user={lms[x.lineManagerId]}
                          isYou={x.lineManagerId === user.id}
                        />
                      ) : (
                        <Flex alignItems="center">
                          <Circle color="green" icon={FaBuilding} />
                          <Box ml={2}>
                            <Text fontWeight={500}>Organisation</Text>
                            <Text fontSize="80%" color="grey2">
                              Org Level Goal
                            </Text>
                          </Box>
                        </Flex>
                      )}
                    </td>
                    <td>{x.name}</td>
                    <td>
                      <DatePill dateOnly value={x.createdOn} />
                      {isNewGoal(x.createdOn) && (
                        <Label size="small" color="primary" icon={FaStar}>
                          New
                        </Label>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ScrollableContainer>
      )}
    </Panel>
  );
};
