import { GroupMember, UserSummary } from "@coaching-culture/types";
import {
  Text,
  Box,
  Button,
  Flex,
  IconButton,
  SortableTable,
  SortableTableColumn
} from "@coaching-culture/ui";
import { uniqBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FaTimes, FaUser, FaUsers } from "react-icons/fa";
import { Recipient } from "./CreateCampaign";
import { GroupSelectModal } from "./GroupSelectModal";
import { UserSelectModal } from "./UserSelectModal";

export type UserSelectionTableProps = {
  value: Recipient[];
  onChange: (val: Recipient[]) => void;
};

export function UserSelectionTable({
  value,
  onChange
}: UserSelectionTableProps) {
  const [modal, setModal] = useState<"user" | "group" | "">("");

  const removeUser = useCallback(
    (u: Recipient) => {
      onChange(value.filter((x) => x.id !== u.id));
    },
    [value, onChange]
  );

  const cols = useMemo(
    (): SortableTableColumn<UserSummary>[] => [
      {
        name: "name",
        label: "Name"
      },
      {
        name: "email",
        label: "Email",
        props: {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
          }
        }
      },
      {
        name: "controls",
        label: "",
        props: {
          style: {
            textAlign: "right",
            width: "1px"
          }
        },
        format: (x) => (
          <IconButton
            color="danger"
            icon={FaTimes}
            onClick={() => removeUser(x)}
          />
        )
      }
    ],
    [removeUser]
  );

  const addUsers = (users: UserSummary[] | GroupMember[]) => {
    const u = uniqBy(value.concat(users), "id").map((x) => ({
      id: x.id,
      name: x.name,
      email: x.email
    }));
    onChange(u);
    setModal("");
  };

  return (
    <>
      {modal === "user" && <UserSelectModal onSelect={addUsers} />}
      {modal === "group" && <GroupSelectModal onSelect={addUsers} />}
      <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
        <Text>
          <strong>Users:</strong> {value.length}
        </Text>
        <Flex>
          <Button
            icon={FaUser}
            mr={1}
            color="primary"
            onClick={() => setModal("user")}
          >
            Add Users
          </Button>
          <Button
            icon={FaUsers}
            color="primary"
            onClick={() => setModal("group")}
          >
            Add Groups
          </Button>
        </Flex>
      </Flex>
      <Box
        style={{ border: "1px solid #ddd", overflowY: "auto" }}
        maxHeight={500}
      >
        <SortableTable<Recipient>
          columns={cols}
          data={value}
          emptyMessage="No Users Have Been Added"
        />
      </Box>
    </>
  );
}
