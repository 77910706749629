import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  FaSignOutAlt,
  FaChevronDown,
  FaNewspaper,
  FaUserCircle,
} from "react-icons/fa";
import { useUser } from "../auth";
import { Text, UserProfileImage } from "@coaching-culture/ui";
import { useOrg } from "../auth/OrgProvider";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { space, SpaceProps } from "styled-system";

const Container = styled.div<SpaceProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 500;
  ${space};
`;

const MenuContainer = styled(animated.div)`
  background: white;
  box-shadow: ${(props) => props.theme.shadows[2]};
  border-radius: 6px;
  padding: ${(props) => props.theme.space[4]}px;
  position: absolute;
  right: 0;
  top: 80px;
  width: 350px;
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MenuDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey};
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.body};

  svg {
    margin-right: 12px;
  }
`;

const ButtonContainer = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
  padding: 8px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.active ? props.theme.colors.grey5 : ""};
`;

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 26,
};

const AccountMenu = () => {
  const [open, setOpen] = useState(false);
  const [user] = useUser();
  const [org] = useOrg();
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const transitions = useTransition(open, {
    config: springConfig,
    from: {
      opacity: 0,
      transform: "translate3d(0,-100px,0) scale(1, 0.8)",
    },
    enter: { opacity: 1, transform: "translate3d(0,0px,0) scale(1, 1)" },
    leave: {
      opacity: 0,
      transform: "translate3d(0,0px,0) scale(0.8, 0.8)",
    },
  });

  if (user == null) {
    return null;
  }

  return (
    <Container ref={ref} ml={[2, 3]}>
      <ButtonContainer active={open} onClick={() => setOpen((old) => !old)}>
        <UserProfileImage
          profileImage={user.profileImage}
          name={`${user.firstName} ${user.lastName}`}
          mr={[1, 3]}
          size="medium"
        />
        <Text
          className="user-name"
          mr={3}
          fontWeight={600}
          display={["none", "block"]}
        >
          {user.firstName + " " + user.lastName}
        </Text>
        <FaChevronDown />
      </ButtonContainer>
      {transitions(
        (props, item) =>
          item && (
            <MenuContainer style={props} key="1">
              <MenuItem>
                <Text fontWeight={600}>{org.name}</Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem>
                <MenuLink to="/account-settings" onClick={() => setOpen(false)}>
                  <FaUserCircle size="1.3em" /> My Profile
                </MenuLink>
              </MenuItem>
              {user.permissions.includes("community") && (
                <MenuItem>
                  <MenuLink
                    to="/account-settings/delivery"
                    onClick={() => setOpen(false)}
                  >
                    <FaNewspaper size="1.3em" /> Magazine Delivery
                  </MenuLink>
                </MenuItem>
              )}
              <MenuDivider />
              <MenuItem>
                <MenuLink to="/logout">
                  <FaSignOutAlt size="1.3em" />
                  Logout
                </MenuLink>
              </MenuItem>
            </MenuContainer>
          ),
      )}
    </Container>
  );
};

export default AccountMenu;
