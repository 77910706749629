import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  GaugeChart,
  Loader,
  Panel,
  Select,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { Link } from "react-router-dom";
import { UserFilter, UserPerformanceInsightDto } from "@coaching-culture/types";

import { saveExportAsCsv } from "utils";
import UserProfileImage from "components/UserProfileImage";
import { isOverdue } from "utils/content";
import {
  addDays,
  format,
  fromUnixTime,
  isFuture,
  isPast,
  isToday,
} from "date-fns";
import { maxBy, minBy } from "lodash";
import { FilterModal } from "components/FilterPanel";
import qsStringify from "qs-stringify";
import axios from "axios";
import { FaExclamation } from "react-icons/fa";
import { useGoalTerminology } from "auth/OrgProvider";

export const PerformUserInsights = () => {
  const goalTerms = useGoalTerminology();
  const [data, setData] = useState<UserPerformanceInsightDto[] | null>(null);
  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });

  const [showFilters, setShowFilters] = useState(false);
  const [timeframe, setTimeframe] = useState(30);

  const filterString = qsStringify({ filter } as any);
  useEffect(() => {
    axios
      .get(`/api/v2/insights/perform/users?${filterString}`)
      .then(({ data }) => setData(data));
  }, [filterString]);

  const cols = useMemo(
    (): SortableTableColumn<UserPerformanceInsightDto>[] => [
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Flex alignItems={"center"} flexDirection={"row"}>
            <Flex mr={2} flexDirection={"column"}>
              <UserProfileImage name={x.name} image={x.profileImage} />
            </Flex>
            <Flex flexDirection={"column"}>
              <Link to={`/success/people/users/${x.id}`}>
                <strong>{x.name}</strong>
              </Link>
              <p>{x.email}</p>
            </Flex>
          </Flex>
        ),
        sortFormat: (x) => x.name.toLowerCase(),
        exportFormat: (x) => x.name,
      },
      {
        name: "manager",
        label: "Manager",
        format: (x) => {
          const managers = x.managers.map((manager) => (
            <Flex alignItems={"center"} flexDirection={"row"} key={x.id}>
              <Flex mr={2} flexDirection={"column"}>
                <UserProfileImage
                  name={manager.name}
                  image={manager.profileImage}
                />
              </Flex>
              <Flex flexDirection={"column"}>
                <Link to={`/success/people/users/${manager.id}`}>
                  <strong>{manager.name}</strong>
                </Link>
                <p>{manager.email}</p>
              </Flex>
            </Flex>
          ));
          return managers;
        },
        sortFormat: (x) => {
          if (x.managers.length > 0) {
            let sortedManagers = x.managers.sort();
            return sortedManagers[0].name;
          } else return "";
        },
        exportFormat: (x) => {
          let managers = "";
          x.managers.map((manager) => {
            return (managers += manager.name + " (" + manager.email + ")");
          });
          return managers;
        },
      },
      /*
      {
        name: "mindset",
        label: "Mindset",
        format: (x) => {
          const modules = x.content.filter(
            (content) => content.type === "module"
          );
          return (
            <Link to={`/people/${x.id}`}>
              {
                modules.filter((y) =>
                  y.attempts.some((z) => z.completedOn !== null)
                ).length
              }{" "}
              / {modules.length}
            </Link>
          );
        },
        sortFormat: (x) => {
          const modules = x.content.filter(
            (content) => content.type === "module"
          );
          return modules.filter((y) =>
            y.attempts.some((z) => z.completedOn !== null)
          ).length;
        },
        exportFormat: (x) => {
          const modules = x.content.filter(
            (content) => content.type === "module"
          );
          return (
            modules.filter((y) =>
              y.attempts.some((z) => z.completedOn !== null)
            ).length +
            "(Completed)/" +
            modules.length
          );
        },
      },
      {
        name: "lessons",
        label: "Lessons",
        format: (x) => {
          const lessons = x.content.filter(
            (content) => content.type === "lesson"
          );
          return (
            <Link to={`/people/${x.id}`}>
              {
                lessons.filter((y) =>
                  y.attempts.some((z) => z.completedOn !== null)
                ).length
              }{" "}
              / {lessons.length}
            </Link>
          );
        },
        sortFormat: (x) => {
          const lessons = x.content.filter(
            (content) => content.type === "lesson"
          );
          return lessons.filter((y) =>
            y.attempts.some((z) => z.completedOn !== null)
          ).length;
        },
        exportFormat: (x) => {
          const lessons = x.content.filter(
            (content) => content.type === "lesson"
          );
          return (
            lessons.filter((y) =>
              y.attempts.some((z) => z.completedOn !== null)
            ).length +
            "(Completed)/" +
            lessons.length
          );
        },
      },
      */
      {
        name: "createdgoals",
        label: `Total Created ${goalTerms.goal.asPluralTitle()}`,
        format: (x) => x.goals.length,
        sortFormat: (x) => x.goals.length,
        exportFormat: (x) => x.goals.length.toString(),
      },
      {
        name: "goals",
        label: `Active ${goalTerms.goal.asPluralTitle()}`,
        format: (x) => {
          const overdue = x.goals.filter((y) => isOverdue(y));
          if (overdue.length > 0) {
            return (
              <Flex alignContent={"center"}>
                <Link to={`/solutions/people/goals/${x.id}`}>
                  {x.goals.filter((goal) => goal.completedOn === null).length}
                </Link>
                <Text
                  title={`${
                    overdue.length
                  } Overdue ${goalTerms.goal.asPluralTitle()}`}
                  color={"danger"}
                >
                  <FaExclamation />
                </Text>
              </Flex>
            );
          } else {
            return (
              <Link to={`/solutions/people/goals/${x.id}`}>
                {x.goals.length}
              </Link>
            );
          }
        },
        sortFormat: (x) =>
          x.goals.filter((goal) => goal.completedOn === null).length,
        exportFormat: (x) =>
          x.goals.filter((goal) => goal.completedOn === null).length.toString(),
      },
      {
        name: "lastConversation",
        label: "Last Conversation",
        format: (x) => {
          const lastConvo = maxBy(
            x.conversations
              .filter((y) => y.cancelledOn == null)
              .filter((z) => {
                const d = fromUnixTime(z.scheduledDate);
                return isPast(d) || isToday(d);
              }),
            (convo) => convo.scheduledDate,
          );
          if (lastConvo) {
            return (
              <Text>
                {format(fromUnixTime(lastConvo.scheduledDate), "do MMM yy")}
              </Text>
            );
          }
          return <Text>-</Text>;
        },
        exportFormat: (x) => {
          const lastConvo = maxBy(
            x.conversations
              .filter((y) => y.cancelledOn == null)
              .filter((z) => {
                const d = fromUnixTime(z.scheduledDate);
                return isPast(d) || isToday(d);
              }),
            (convo) => convo.scheduledDate,
          );
          if (lastConvo) {
            return format(fromUnixTime(lastConvo.scheduledDate), "dd/MM/yyyy");
          } else {
            return "";
          }
        },
        sortFormat: (x) => {
          const lastConvo = maxBy(
            x.conversations
              .filter((y) => y.cancelledOn == null)
              .filter((z) => {
                const d = fromUnixTime(z.scheduledDate);
                return isPast(d) || isToday(d);
              }),
            (convo) => convo.scheduledDate,
          );
          if (lastConvo) {
            return lastConvo.scheduledDate;
          }
          return 0;
        },
      },
      {
        name: "nextConversation",
        label: "Next Conversation",
        format: (x) => {
          const nextConvo = minBy(
            x.conversations
              .filter((y) => y.completedOn == null && y.cancelledOn == null)
              .filter((z) => {
                const d = fromUnixTime(z.scheduledDate);
                return isFuture(d) || isToday(d);
              }),
            (convo) => convo.scheduledDate,
          );
          if (nextConvo) {
            return (
              <Text>
                {format(fromUnixTime(nextConvo.scheduledDate), "do MMM yy")}
              </Text>
            );
          }
          return (
            <Flex alignContent={"center"}>
              <Text>-</Text>
              <Text color={"danger"}>
                <FaExclamation />
              </Text>
            </Flex>
          );
        },
        sortFormat: (x) => {
          const nextConvo = minBy(
            x.conversations
              .filter((y) => y.cancelledOn == null)
              .filter((z) => {
                const d = fromUnixTime(z.scheduledDate);
                return isFuture(d) || isToday(d);
              }),
            (convo) => convo.scheduledDate,
          );
          if (nextConvo) {
            return nextConvo.scheduledDate;
          }
          return 0;
        },
        exportFormat: (x) => {
          const nextConvo = minBy(
            x.conversations
              .filter((y) => y.cancelledOn == null)
              .filter((z) => {
                const d = fromUnixTime(z.scheduledDate);
                return isFuture(d) || isToday(d);
              }),
            (convo) => convo.scheduledDate,
          );
          if (nextConvo) {
            return format(fromUnixTime(nextConvo.scheduledDate), "do MMM yy");
          } else {
            return "";
          }
        },
      },
      {
        name: "totalConversations",
        label: "Completed Conversations",
        format: (x) =>
          x.conversations.filter((x) => x.completedOn != null).length,
        exportFormat: (x) =>
          x.conversations
            .filter((x) => x.completedOn != null)
            .length.toString(),
        sortFormat: (x) =>
          x.conversations.filter((x) => x.completedOn != null).length,
      },
      {
        name: "lastSentFeedback",
        label: "Feedback Last Sent",
        format: (x) => {
          if (x.lastSentFeedback) {
            return (
              <Text>
                {format(fromUnixTime(x.lastSentFeedback), "do MMM yy")}
              </Text>
            );
          }
          return <Text>-</Text>;
        },
        exportFormat: (x) => {
          if (x.lastSentFeedback) {
            return format(fromUnixTime(x.lastSentFeedback), "do MMM yy");
          }
          return "";
        },
        sortFormat: (x) => x.lastSentFeedback,
      },
      {
        name: "lastSentFeedback",
        label: "Feedback Last Received",
        format: (x) => {
          if (x.lastReceivedFeedback) {
            return (
              <Text>
                {format(fromUnixTime(x.lastReceivedFeedback), "do MMM yy")}
              </Text>
            );
          }
          return <Text>-</Text>;
        },
        exportFormat: (x) => {
          if (x.lastReceivedFeedback) {
            return format(fromUnixTime(x.lastReceivedFeedback), "do MMM yy");
          }
          return "";
        },
        sortFormat: (x) => x.lastReceivedFeedback,
      },
    ],
    [goalTerms],
  );

  if (data === null) {
    return <Loader />;
  }

  const activeGoals = data.filter((x) =>
    x.goals.some((x) => x.completedOn == null),
  );
  const overdueGoals = data.filter((x) => !x.goals.some(isOverdue));

  const upcomingConvos = data.filter((x) =>
    x.conversations.some(
      (y) =>
        y.completedOn == null &&
        y.cancelledOn == null &&
        (isFuture(fromUnixTime(y.scheduledDate)) ||
          isToday(fromUnixTime(y.scheduledDate))),
    ),
  );
  const recentConvos = data.filter((x) =>
    x.conversations.some(
      (y) =>
        fromUnixTime(y.scheduledDate) > addDays(new Date(), -timeframe) &&
        fromUnixTime(y.scheduledDate) < new Date(),
    ),
  );

  const onCancel = (result) => {
    if (result) {
      setFilter(result);
    }

    setShowFilters(false);
  };

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        filter={filter}
        filteredUsers={data.map((x) => x.id)}
        onClose={(result) => {
          onCancel(result);
        }}
      />
      <Flex mb={2} justifyContent={"flex-end"}>
        <Button color="primary" onClick={() => setShowFilters(true)}>
          Filters ({filter.groups.length + filter.userFields.length})
        </Button>

        <Select
          ml={2}
          width={200}
          value={timeframe}
          onChange={(val) => setTimeframe(parseInt(val.target.value))}
          options={[
            { label: "Last 14 days", value: "14" },
            { label: "Last 30 days", value: "30" },
            { label: "Last 90 days", value: "90" },
            { label: "Last 180 days", value: "180" },
            { label: "Last year", value: "365" },
          ]}
        />
      </Flex>

      <Flex justifyContent={"center"} mb={3} flexDirection={["column", "row"]}>
        <Flex
          mr={[0, 2, 2, 5]}
          mb={[3, 0]}
          flexDirection={"column"}
          width={["100%", "25%"]}
        >
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Users with:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Active {goalTerms.goal.asTitle()}
              </Text>
            </div>
            <GaugeChart
              value={activeGoals.length}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex
          mr={[0, 2, 2, 5]}
          mb={[3, 0]}
          flexDirection={"column"}
          width={["100%", "25%"]}
        >
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Users without:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Overdue {goalTerms.goal.asPluralTitle()}
              </Text>
            </div>
            <GaugeChart
              value={overdueGoals.length}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex
          mr={[0, 2, 2, 5]}
          mb={[3, 0]}
          flexDirection={"column"}
          width={["100%", "25%"]}
        >
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Users with:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Conversations in the last {timeframe} days
              </Text>
            </div>
            <GaugeChart
              value={recentConvos.length}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex mb={[3, 0]} flexDirection={"column"} width={["100%", "25%"]}>
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Users with:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Upcoming Conversations
              </Text>
            </div>
            <GaugeChart
              value={upcomingConvos.length}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
      </Flex>
      <Panel style={{ overflow: "hidden" }}>
        <SortableTable<UserPerformanceInsightDto>
          columns={cols}
          data={data}
          defaultSort={1}
          rowKey="id"
          paged
          onExport={saveExportAsCsv("CCUserExport.csv")}
        />
      </Panel>
    </>
  );
};
