import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Panel, { PanelProps } from "./Panel";
import { useSpring, animated } from "react-spring";
import { FaTimes } from "react-icons/fa";
import ReactDOM from "react-dom";
import { useScrollbarWidth } from "react-use";
import Input from "./Input";

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const ModalContainer = styled(animated.div)`
  padding: 6px;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2000;
`;

const KillPlane = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ModalPanel = styled(Panel)`
  position: relative;
  z-index: 2000;
  margin: auto;
`;

type ModalProps = React.ComponentPropsWithoutRef<"div"> &
  PanelProps & {
    showCloseButton?: boolean;
    onClose?: () => any;
  };

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grey2};
  background: none;
  transition: all 0.3s ease;
  border: 0;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.grey1};
  }
`;

function ModalPortal({ children }: { children: React.ReactNode }) {
  const modalEl = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    const root = document.getElementById("root");
    const el = modalEl.current;
    root?.parentNode?.appendChild(el);

    return () => {
      root?.parentNode?.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(children, modalEl.current);
}

const Modal = ({ onClose, children, showCloseButton, ...rest }: ModalProps) => {
  const sbw = useScrollbarWidth();

  const overlayProps = useSpring({
    opacity: 1,
    from: {
      opacity: 0.0,
    },
  });

  const containerProps = useSpring({
    transform: "translate3d(0px, 0px, 0px)",
    opacity: 1,
    from: {
      transform: "translate3d(0px, 60px, 0px)",
      opacity: 0,
    },
  });

  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    document.body.style.paddingRight = `${sbw}px`;

    return () => {
      document.documentElement.style.overflowY = "scroll";
      document.body.style.paddingRight = "";
    };
  }, [sbw]);

  return (
    <ModalPortal>
      <Container style={overlayProps}>
        <Overlay />
        <ModalContainer style={containerProps}>
          <KillPlane onClick={onClose} />
          <ModalPanel {...rest} boxShadow={2}>
            <Input type="hidden" autoFocus />
            {showCloseButton && (
              <CloseButton onClick={onClose}>
                <FaTimes />
              </CloseButton>
            )}
            {children}
          </ModalPanel>
        </ModalContainer>
      </Container>
    </ModalPortal>
  );
};

export default Modal;
