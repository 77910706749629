import { ContentItem, DownloadResource } from "@coaching-culture/types";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Text,
  Loader,
  Flex,
  Panel,
  Img,
  Box,
  Button,
  IconButton,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import NavActions from "components/NavActions";
import { groupBy } from "lodash";
import { FaBan, FaDownload } from "react-icons/fa";
import { FileExplorerPanel } from "./FileExplorerPanel";
import { PageHeader } from "components/PageHeader";

function ScormItem({ item }: { item: ContentItem }) {
  const download = () => {
    const type = item.type === "lesson" ? "lessons" : "mindset";
    axios.get(`/api/${type}/${item.id}/download`).then(({ data }) => {
      if (data?.url == null) {
        window.alert("Missing download. Contact support");
      } else {
        window.location = data.url;
      }
    });
  };

  return (
    <Flex
      p={1}
      pl={3}
      pr={3}
      borderBottom={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Img
          width="60px"
          height="60px"
          mr={2}
          src={process.env.REACT_APP_STATIC_ROOT + "/" + item.icon}
        />
        <Text fontSize={3} fontWeight={600}>
          {item.name}
        </Text>
      </Flex>
      <Box as="span" display={["block", "none"]}>
        <IconButton icon={FaDownload} onClick={download} color="body" />
      </Box>
      <Box as="span" display={["none", "block"]}>
        <Button color="primary" onClick={download}>
          Download Scorm 1.2
        </Button>
      </Box>
    </Flex>
  );
}

export function DownloadsList() {
  const [items, setItems] = useState<DownloadResource[] | null>(null);
  const [content, setContent] = useState<ContentItem[] | null>(null);
  const [showing, setShowing] = useState<number>(0);

  useEffect(() => {
    axios.get("/api/downloads").then(({ data }) => {
      setItems(data);
    });
    axios.get("/api/downloads/content").then(({ data }) => {
      setContent(data);
    });
  }, []);

  if (items == null || content == null) {
    return <Loader />;
  }

  const ms = content.filter((x) => x.type === "module");
  const lessons = content.filter((x) => x.type === "lesson");

  let vis = showing === 0 && ms.length > 0 ? ms : lessons;
  const showBoth = ms.length > 0 && lessons.length > 0;
  const lmsAvail = vis.length > 0;
  const anyAvail = lmsAvail || items.length > 0;

  return (
    <CenterColumn>
      <PageHeader
        text="Downloads"
        subtitle="Download content for your LMS"
        helpIdent="downloads-main"
      />
      {!anyAvail && (
        <Flex
          p={5}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Text fontSize="100px" color="grey3">
            <FaBan />
          </Text>
          <Text fontSize={4} fontWeight={600} color="grey3">
            No Content Available
          </Text>
        </Flex>
      )}
      {lmsAvail && (
        <>
          <Text mb={2} fontWeight={500} fontSize={4} as="h3">
            For your LMS
          </Text>
          <Panel pt={3} mb={5}>
            {showBoth && (
              <NavActions
                items={["Mindset", "Lessons"]}
                active={showing}
                onSelect={setShowing}
              />
            )}
            {Object.entries(groupBy(vis, (x) => x.category.name)).map(
              ([cat, items], i, self) => (
                <Box mb={i === self.length - 1 ? 0 : 5} key={cat}>
                  <Text ml={3} fontWeight={500} fontSize={4} mb={3}>
                    {cat}
                  </Text>
                  {items.map((x) => (
                    <ScormItem item={x} key={x.id} />
                  ))}
                </Box>
              )
            )}
          </Panel>
        </>
      )}
      {items.length > 0 && (
        <>
          <Text mb={2} fontWeight={500} fontSize={4} as="h3">
            Other Resources
          </Text>
          <FileExplorerPanel items={items} />
        </>
      )}
    </CenterColumn>
  );
}
