import styled from "styled-components";
import { space, SpaceProps, layout, LayoutProps } from "styled-system";

const CenterColumn = styled.div<SpaceProps & LayoutProps>`
  max-width: 100%;
  ${space};
  ${layout};
`;

CenterColumn.defaultProps = {
  width: ["100%", "1000px", "1200px", "1400px"],
  p: [2, 5],
  pt: [5, 5],
};

export default CenterColumn;
