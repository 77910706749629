import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";

export const LinkButton = styled.button<SpaceProps & TypographyProps>`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  cursor: pointer;
  display: inline;
  text-align: left;
  color: ${(props) => props.theme.colors.primary};
  font-weight: inherit;

  &:hover {
    text-decoration: underline;
  }
  ${space}
  ${typography}
`;

export type LinkButtonProps = React.ComponentProps<typeof LinkButton>;
