import React from "react";
import FormInputWrap, { FormInputWrapProps } from "./FormInputWrap";
import TextArea from "./TextArea";

type FormInputProps = FormInputWrapProps &
  React.ComponentPropsWithoutRef<"textarea">;

const FormTextArea = React.forwardRef<HTMLTextAreaElement, FormInputProps>(
  (
    {
      name,
      label,
      placeholder,
      error,
      defaultValue,
      required,
      height,
      value,
      disabled,
      readOnly,
      ...rest
    }: FormInputProps,
    ref
  ) => (
    <FormInputWrap
      name={name}
      label={label}
      error={error}
      required={required}
      {...rest}
    >
      <TextArea
        disabled={disabled}
        readOnly={readOnly}
        height={height}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete="no"
        ref={ref}
        defaultValue={defaultValue}
      />
    </FormInputWrap>
  )
);

export default FormTextArea;
