import { MeasureSpec } from "@coaching-culture/types";
import { IconToast, Loader, Panel, useToast } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import NotFound from "pages/NotFound";
import { useMeasures, useUpdateMeasure } from "queries/measures";
import { FaSave } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { MeasureForm } from "./MeasureForm";

export function EditMeasure() {
  const { data, isFetched } = useMeasures();
  const { id } = useParams<{ id: string }>();
  const item = data?.find((x) => x.id === id);
  const updateMeasure = useUpdateMeasure();
  const history = useHistory();
  const pushToast = useToast();

  const onSubmit = async (values: MeasureSpec) => {
    await updateMeasure.mutateAsync(values);
    pushToast({
      content: <IconToast icon={FaSave} text="Saved" iconColor="primary" />,
    });

    history.push("/success/settings/measures");
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Edit Measure"
        subtitle="Edit an existing Measure for your organisation"
        backUrl="/success/settings/measures"
      />
      {!isFetched ? (
        <Loader />
      ) : item == null ? (
        <NotFound />
      ) : (
        <Panel p={[2, 3]}>
          <MeasureForm
            value={item}
            onSave={onSubmit}
            disabled={updateMeasure.isLoading}
          />
        </Panel>
      )}
    </CenterColumn>
  );
}
