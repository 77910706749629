import { Flex, Text } from "@coaching-culture/ui";
import React from "react";
import styled from "styled-components";
import { SpaceProps } from "styled-system";

export type ToggleProps = {
  value: boolean;
  onChange: (val: boolean) => void;
  leftLabel: string;
  rightLabel: string;
  ariaLabel?: string;
} & SpaceProps;

const ToggleButton = styled.span<{ on: boolean }>`
  height: 25px;
  width: 47px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.on ? props.theme.colors.primary : props.theme.colors.grey5};
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 0px inset,
    rgba(255, 255, 255, 0.3) -1px -1px 4px 1px inset;
  transition: all 0.3s ease;
  flex: 0 0 auto;

  &:after {
    content: "";
    display: block;
    width: 17px;
    position: relative;
    top: 4px;
    left: 4px;
    height: 17px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(${(props) => (props.on ? 21 : 0)}px, 0, 0);
    transition: all 0.3s ease;
  }
`;

export function Toggle({
  value,
  onChange,
  rightLabel,
  leftLabel,
  ariaLabel,
  ...rest
}: ToggleProps) {
  return (
    <Flex alignItems={"center"} {...rest}>
      <Text mr={2} color={value ? "grey" : "body"} fontWeight={600}>
        {leftLabel}
      </Text>

      <ToggleButton
        onKeyDown={(key) => {
          if (key.code === "Space" || key.code === "Enter") {
            onChange(!value);
          }
        }}
        role="checkbox"
        aria-checked={value}
        aria-label={ariaLabel}
        tabIndex={0}
        on={value}
        onClick={() => onChange(!value)}
      />
      <Text ml={2} color={!value ? "grey" : "body"} fontWeight={600}>
        {rightLabel}
      </Text>
    </Flex>
  );
}
