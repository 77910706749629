import {
  InsightsGraphType,
  InsightsType,
  ManagerVisibility,
  ProductVisibility,
} from "./Enums";
import { Relationship, UserDisplay, UserSummary } from "./User";
import { HelpItemIdent } from "./utils";

export * from "./Planhat";
export * from "./Community";
export * from "./Enums";
export * from "./Org";
export * from "./User";
export * from "./utils";

export type PartialPick<T, F extends keyof T> = Omit<T, F> &
  Partial<Pick<T, F>>;

export type Spec<T extends { id: string }> = PartialPick<T, "id">;

export type ContentHubAccessType = "none" | "free" | "premium";

export type TitlePublishState = "hidden" | "comingsoon" | "published";

export type AudienceType = "me" | "managers" | "everyone";

export type SignupSpec = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  code?: string;
};

export type SignupCompletionSpec = {
  userId: string;
  phoneNumber: string;
  companyName: string;
  jobTitle: string;
  decisionMaker: "decision-maker" | "influencer" | "not-involved";
};

export const ProductIdents = [
  "mindset",
  "lessons",
  "360",
  "performance",
  "surveys",
  "assessments",
  "diagnostics",
  "measures",
  "cont-feedback",
  "learncast",
  "teamview",
  "branding",
] as const;

export type ProductIdent = (typeof ProductIdents)[number];

export type ProductVisibilityStr =
  | "hidden"
  | "locked"
  | "comingsoon"
  | "enabled";

export type OrgStatus = "disabled" | "trial" | "active" | "free";

export type AuthMethod = "password" | "sso" | "impersonated";

export type ResourceFormat =
  | "workshop"
  | "worksheet"
  | "video"
  | "scorm"
  | "infographic"
  | "article"
  | "image"
  | "subtitles"
  | "audio";

export type ContentHubBundleDto = {
  id: string;
  name: string;
  code: string;
  slogan: string | null;
  image: string | null;
  titles: {
    id: string;
    name: string;
  }[];
  userCount: number;
};

export type ContentHubBundleSpec = {
  id?: string;
  name: string;
  code: string;
  slogan: string | null;
  image: string | null;
  titles: string[];
};

export type ContentHubTagDto = {
  id: string;
  name: string;
  icon: string | null;
  color: string | null;
};

export type ContentHubCollectionDto = {
  id: string;
  name: string;
  icon: string;
  strap: string;
  image: string | null;
  color: string;
  imageAlign: "bottom" | "center" | "top";
};

export type ContentHubCollectionSpec = Spec<ContentHubCollectionDto>;

export type ContentHubTagSpec = Spec<ContentHubTagDto>;

export type ContentHubResourceSpec = Spec<ContentHubResourceDto>;

export type ContentHubResourceDto = {
  id: string;
  name: string;
  format: ResourceFormat;
  filename: string;
  isPublished: boolean;
  publishDate: number | null;
  previewUrl: string | null;
  type: "lms" | "resource";
  isMain: boolean;
};

export type ContentHubTitleSummaryDto = {
  id: string;
  name: string;
  subcategory: string;
  shortDescription: string;
  image: string | null;
  publishState: TitlePublishState;
  isGettingStarted: boolean;
  audience: AudienceType | null;
  isPremium: boolean;
  isNew: boolean;
  publishDate: number | null;
  collection: ContentHubCollectionDto;
  tags: ContentHubTagDto[];
  featuredStatus: "main" | "frontpage" | "no";
  bundles: string[];
  orderIndex: number | null;
};

export type ContentHubTitleDto = ContentHubTitleSummaryDto & {
  description: string;
  who: string;
  how: string;
  whatsNext: string;
  resources: ContentHubResourceDto[];
};

export type ContentHubTitleSpec = {
  id?: string;
  name: string;
  shortDescription: string;
  subcategory: string;
  description: string;
  image: string | null;
  isPremium: boolean;
  orderIndex: number | null;
  isGettingStarted: boolean;
  isNew: boolean;
  publishState: TitlePublishState;
  audience: AudienceType;
  publishDate: number | null;
  collection: string;
  featuredStatus: "main" | "frontpage" | "no";
  who: string;
  how: string;
  whatsNext: string;
  tags: string[];
  resources: ContentHubResourceSpec[];
};

export type CompleteConversationDto = {
  actions: string[];
  privateNotes: string;
  publicNotes: string;
};

export type MeasuresConfigurationDto = {
  reminderSchedule: number;
  measuresOrder: string[];
};

export type UserMeasuresDto = {
  teamOrder: number;
  manager: UserDisplay;
  user: UserDisplay;
  relationshipId: string;
  reminderLastSent: number | null;
  measures: {
    id: string;
    value: string;
    lastSet: number;
  }[];
};

export type UserMeasuresTrendsDto = {
  user: UserDisplay;
  relationshipId: string;
  measures: {
    id: string;
    values: {
      value: string;
      dateSet: number;
    }[];
  }[];
};

export type MeasureDto = {
  id: string;
  name: string;
  options: {
    id: string;
    name: string;
  }[];
};

export type MeasureSpec = {
  id?: string;
  name: string;
  options: {
    id?: string;
    name: string;
  }[];
};

export type UpdateUserMeasureDto = {
  relationshipId: string;
  measureId: string;
  optionId: string;
};

export interface DeleteGoalFieldDto {
  goalFieldId: string;
}

export type DiscussionPointDto = {
  id: string;
  name: string;
  addedBy: string;
  addedOn: number;
};

export type SentEmailSummaryDto = {
  recipient: string;
  dateSent: number;
  subject: string;
  opened: boolean;
  clicked: boolean;
  status: string;
};

export type ActionItemDto = {
  id: string;
  name: string;
  completedOn: number | null;
};

export type ActionItemSpec = {
  text: string;
  assignedTo: string;
  dueDate: number | null;
  conversationId: string;
  occurenceIndex: number;
};

export type ConversationActionDto = {
  id: string;
  conversation: {
    id: string;
    purpose: string;
    date: number;
    manager: UserDisplay;
    directReport: UserDisplay;
    index: number;
  };
  assignedTo: UserDisplay;
  type: "conversation";
  text: string;
  dueDate: number | null;
  completedOn: number | null;
};

export type SurveyActionDto = {
  type: "survey";
  survey: {
    id: string;
    name: string;
    sender: UserDisplay | null;
  };
  date: number;
  dueDate: number | null;
  completedOn: number | null;
};

export type SendFeedbackActionDto = {
  type: "requestfeedback";
  campaign: {
    id: string;
    name: string;
    sender: UserDisplay;
  };
  date: number;
  completedOn: number | null;
};

export type ProvideFeedbackActionDto = {
  type: "providefeedback";
  code: string;
  campaign: {
    id: string;
    name: string;
    user: UserDisplay;
  };
  date: number;
  dueDate: number | null;
  completedOn: number | null;
};

export type AssessmentActionDto = {
  type: "assessment";
  content: ContentItem;
  completedOn: number;
  date: number;
  assigner: UserDisplay;
};

export type ContinuousFeedbackActionDto = {
  type: "contfeedback";
  sentOn: number;
  requester: UserDisplay;
  reason: string;
  requestId: string;
  completedOn: number | null;
};

export type MeasuresUpdateActionDto = {
  type: "measures";
  manager: UserDisplay;
  completedOn: number | null;
};

export type ActionDto =
  | ConversationActionDto
  | SurveyActionDto
  | SendFeedbackActionDto
  | ProvideFeedbackActionDto
  | AssessmentActionDto
  | MeasuresUpdateActionDto
  | ContinuousFeedbackActionDto;

export type ActionType = ActionDto["type"];

export type ConversationOccurrenceDto = {
  index: number;
  completedOn: number | null;
  cancelledOn: number | null;
  scheduledDate: number;
  agenda: DiscussionPointDto[];
  actionItems: ActionItemDto[] | null;
  publicNotes: string | null;
  privateNotes: string | null;
  comments: CommentDto[];
};

export const recurrenceOptions = [
  "none",
  "weekly",
  "2weekly",
  "3weekly",
  "4weekly",
  "monthly",
  "2monthly",
  "3monthly",
  "6monthly",
  "yearly",
] as const;

/**
 * Represents a type of recurrence.
 * none: No recurrence
 * weekly: Every week on the same weekday
 * monthly: Every month on the same occurence of a particular weekday e.g. Every 3rd Monday.
 */
export type RecurrenceType = (typeof recurrenceOptions)[number];

export type ConversationDto = {
  id: string;
  type: string;
  purpose: string;
  outcome: string;
  cancelledOn: number | null;
  createdBy: string;
  preparation: string;
  recurrence: RecurrenceType;
  manager: UserDisplay;
  directReport: UserDisplay;
  agenda: DiscussionPointDto[];
  duration: number;
  location: string;
  occurences: ConversationOccurrenceDto[];
};

export type ConversationOccurenceViewDto = {
  id: string;
  index: number;
  type: string;
  recurrence: RecurrenceType;
  purpose: string;
  outcome: string;
  isParentCancelled: boolean;
  completedOn: number | null;
  cancelledOn: number | null;
  createdBy: string;
  scheduledDate: number;
  preparation: string;
  manager: UserDisplay;
  directReport: UserDisplay;
  agenda: DiscussionPointDto[];
  actionItems: ActionItemDto[] | null;
  publicNotes: string | null;
  privateNotes: string | null;
  duration: number;
  location: string;
  comments: ConversationCommentDto[];
};

export type RescheduleConversationSpec = {
  scheduledDate: number;
  location: string;
  duration: number;
};

export type ConversationCommentDto = {
  id: string;
  createdBy: UserDisplay;
  body: string;
  createdOn: number;
  isPrivate: boolean;
};

export type CreateConversationCommand = {
  conversationTypeId: string;
  purpose: string;
  outcome: string;
  managerId: string;
  directReportId: string;
  preparation: string;
  discussionPoints: string[];
  scheduledDate: number;
  endDate: number | null;
  recurrence: RecurrenceType;
  userId: string;
  location: string;
  duration: number;
  isOnlineMeeting: boolean;
};

export type UpdateConversationDetailsCommand = {
  purpose: string;
  outcome: string;
};

export type DiscussionPointTemplateDto = {
  id: string;
  name: string;
};

export type ConversationTypeDto = {
  id: string;
  name: string;
  description: string;
  orgId: string | null;
  active: boolean;
  createdOn: number;
  discussionPoints: DiscussionPointTemplateDto[];
};

export type TeamGoalDto = {
  id: string;
  name: string;
  description: string;
  createdOn: number;
  lineManagerId: string;
};

export type RelationshipDto = {
  id: string;
  type: ParentRelationships;
  created: Date;
  removed: Date | null;
  fromAccepted: Date | null;
  toAccepted: Date | null;
};

export type LineManagerDto = UserDisplay & {
  relationship: RelationshipDto;
  teamGoals: TeamGoalDto[];
};

export type GoalSummaryDto = {
  id: string;
  purpose: string;
  deadline: number;
  status: GoalReviewStatusOptions | GoalOutcomeOptions | null;
  completedOn: number | null;
  updatedOn: number | null;
};

export type ConversationSummaryDto = {
  id: string;
  index: number;
  type: string;
  purpose: string;
  outcome: string;
  preparation: string;
  scheduledDate: number;
  completedOn: number | null;
  cancelledOn: number | null;
};

export type CreateConversationCommentCommand = {
  body: string;
  createdBy?: string;
  isPrivate: boolean;
  conversationId: string;
  index: number;
};

export type CoachingCategoryDto = {
  id?: string;
  name: string;
  public: boolean;
  coachingTopics: CoachingTopicDto[];
  order: number;
};

export type CoachingTopicDto = {
  id?: string;
  body: string;
  addedCount?: number;
  favouriteCount?: number;
  public: boolean;
  favourite?: boolean;
  categoryId: string;
};

export type DirectReportDto = {
  id: string;
  relationship: RelationshipDto;
  name: string;
  email: string;
  jobTitle: string | null;
  profileImage: string | null;
  goals: GoalSummaryDto[];
  conversations: ConversationSummaryDto[];
  content?: UserContentDto[];
  directReports?: DirectReportDto[];
  receivedFeedback: ContinuousFeedbackDto[];
  sentFeedback: ContinuousFeedbackDto[];
  measures: {
    id: string;
    name: string;
    value: string | null;
    options: {
      id: string;
      name: string;
      orderIndex: number;
    }[];
    lastSet: number | null;
  }[];
};

export type UserPerformanceInsightDto = {
  id: string;
  managers: UserDisplay[];
  name: string;
  email: string;
  profileImage: string | null;
  goals: GoalSummaryDto[];
  conversations: ConversationSummaryDto[];
  content?: UserContentDto[];
  lastSentFeedback: number | null;
  lastReceivedFeedback: number | null;
};

export type ManagerPerformanceInsightDto = {
  id: string;
  directReports?: {
    goals: GoalSummaryDto[];
    user: UserDisplay;
    conversations: ConversationSummaryDto[];
    sentFeedback: ContinuousFeedbackDto[];
    receivedFeedback: ContinuousFeedbackDto[];
  }[];
  name: string;
  email: string;
  profileImage: string | null;
  conversations: ConversationSummaryDto[];
};

export type TeamDto = {
  lineManagers: LineManagerDto[];
  directReports: DirectReportDto[];
};

export interface GoalFieldDto {
  id?: string;
  name: string;
  required: boolean;
  prompt: string;
  usage: "goal" | "objective" | "both";
}

export interface ReorderGoalFieldsDto {
  orgId?: string;
  ids: string[];
}

export interface CreateGoalFieldDto {
  orgId?: string;
  name: string;
  required: boolean;
  prompt: string;
  usage: "goal" | "objective" | "both";
}

export type CreateGoalDto = GoalDto & {
  userId: string;
  orgId: string;
};

export type ObjectiveDto = {
  id?: string;
  purpose: string;
  deadline: number;
  createdOn?: number;
  completedOn?: number | null;
  fields: {
    id: string;
    value: string;
  }[];
  status: GoalReviewStatusOptions;
  updatedOn?: number | null;
  statusHistory: GoalStatusHistoryDto[];
  measureType: GoalMeasureTypeOptions;
  canExceed: number | null;
  target: number | null;
  progress: number | null;
  type: GoalTypeOptions;
};

export type GoalAssociationDto = {
  collectiveGoalId: string;
  name: string;
  description?: string;
  type: "org" | "team";
  hasConditions: boolean;
  lineManagerId?: string;
};

export type GoalReviewStatusOptions =
  | "blocked"
  | "inprogress"
  | "notstarted"
  | "achieved"
  | "onhold";

export type GoalTypeOptions = "performance" | "development";

export type GoalMeasureTypeOptions = "number" | "percentage" | "subgoals";

export type GoalOutcomeOptions =
  | "exceeded"
  | "completedachieved"
  | "partially"
  | "missed"
  | "nla"
  | "open";

export type GoalDto = {
  id?: string;
  purpose: string;
  deadline: number;
  fields: {
    id: string;
    value: string;
  }[];
  objectives: ObjectiveDto[];
  associations: GoalAssociationDto[];
  comments: CommentDto[];
  userId?: string;
  createdOn?: number;
  completedOn?: number | null;
  status: GoalReviewStatusOptions;
  updatedOn?: number | null;
  statusHistory: GoalStatusHistoryDto[];
  type: GoalTypeOptions;
  measureType: GoalMeasureTypeOptions;
  canExceed: number | null;
  progress: number | null;
  target: number | null;
  outcome?: GoalOutcomeOptions | null;
};

export type GoalExportDto = {
  id?: string;
  purpose: string;
  deadline: number;
  fields: {
    id: string;
    value: string;
  }[];
  associations: GoalAssociationDto[];
  userName: string;
  userEmail: string;
  createdOn: number;
  completedOn: number | null;
  status: GoalReviewStatusOptions;
  updatedOn: number | null;
  type: GoalTypeOptions;
  measureType: GoalMeasureTypeOptions;
  canExceed: number | null;
  progress: number | null;
  target: number | null;
  outcome: GoalOutcomeOptions | null;
  isObjective: boolean;
  parentId: string | null;
  userFields: {
    id: string;
    value: string | number;
  }[];
};

export type CollectiveGoalDto = {
  id?: string;
  name: string;
  description?: string;
  createdOn?: number;
  aggregationMode: "any" | "all";
  conditions: {
    userFieldId: string;
    expectedValue: string;
  }[];
};

export type CollectiveGoalInsightsDto = {
  activeGoals: number;
  completedGoals: number;
};

export type CreateGoalCommentCommand = {
  body: string;
  goalId: string;
  createdBy?: string;
};

export type CommentDto = {
  id: string;
  createdBy: UserDisplay;
  body: string;
  createdOn: number;
  updatedOn?: number | null;
  isPrivate?: boolean;
};

export type GoalStatusHistoryDto = {
  id: string;
  user: UserDisplay;
  newStatus?: GoalReviewStatusOptions | GoalOutcomeOptions | null;
  createdOn: number;
  previousStatus?: GoalReviewStatusOptions;
  goalPurpose: string;
  progress?: number | null;
  previousProgress?: number | null;
};

export interface UpdateUserDto {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  sendWelcomeEmail: "none" | "platform" | "ch";
  permissions: string[];
  hubspotId?: number;
  contentHubAccess?: ContentHubAccessType;
  userFields?: {
    id: string;
    value: string | null;
  }[];
  groups?: {
    id: string;
    membershipLevel: string;
  }[];
  managers?: string[];
  directReports?: string[];
  orgId?: string;
  bundles?: string[];
}

export interface CreateUserDto {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  sendWelcomeEmail: "none" | "platform" | "ch";
  permissions: string[];
  hubspotId?: number;
  orgId?: string;
  userFields?: {
    id: string;
    value: string | null;
  }[];
  groups?: {
    id: string;
    membershipLevel: string;
  }[];
  managers?: string[];
  directReports?: string[];
  bundles?: string[];
}

export type HelpItem = {
  id?: string;
  ident: HelpItemIdent;
  title: string;
  videoUrl: string | null;
  content: string | null;
  version: number;
  isPublished: boolean;
  forceShow: boolean;
  lastEdited: number;
  lastEditedBy: string | null;
  seen?: boolean;
};

export type Announcement = {
  id?: string;
  title: string;
  content: string;
  isPublished: boolean;
  date: Date;
  image: string;
  url: string;
  cta: string;
};

export type RelationshipSpec = {
  type: RelationshipType;
  from: string;
  to: string;
};

export type NewTestOrgSpec = {
  domainPrefix: string;
  name: string;
};

export type TodoListSource =
  | {
      type: "assessment";
    }
  | {
      type: "share";
      sharer: UserDisplay;
    };

export type TodoListItem = {
  date: number;
  source: TodoListSource;
  content: ContentDisplay;
  completed: number | null;
};

export type AssessmentSuggestionTodoSpec = {
  source: "assessment";
  assessmentCompletionId: string;
  contentType: ContentType;
  contentId: string;
};

export type ContentAssignmentTodoSpec = {
  source: "content";
  contentType: ContentType;
  contentId: string;
};

export type NewTodoSpec =
  | AssessmentSuggestionTodoSpec
  | ContentAssignmentTodoSpec;

export type MigrationExercise = {
  name: string;
  type: "video" | "form" | "challenge";
  completed: string | null;
  answers: {
    key: string;
    question: string;
    answer: string;
  }[];
};

export type MigrationAttempt = {
  attempt: "current";
  slug: string;
  completed: string | null;
  "assessment-completed": string | null;
  "assessment-answers": {
    question?: string;
    answer: string;
    answer_value: string;
  }[];
  exercises: MigrationExercise[];
  challenges: MigrationExercise[];
  na: MigrationExercise[];
};

export type MigrationUser = {
  FirstName: string;
  LastName: string;
  Email: string;
  Company: string;
  groups: string[] | null;
  "custom-fields": {
    label: string;
    key: string;
    value: string;
  }[];
  modules: Record<string, MigrationAttempt[]>;
};

export type AssessmentCompletionSummary = {
  id: string;
  assessment: ContentDisplay;
  completedOn: number;
};

export type AssessmentCompletion = {
  id: string;
  userId: string;
  completedOn: number;
  result: AssessmentOutcome[];
};

export type ModuleFeedbackReportItem = {
  contentName: string;
  timestamp: number;
  rating: number | null;
  feedback1: string | null;
  feedback2: string | null;
  feedback3: string | null;
};

export type DownloadSettings = {
  downloadId: string;
  allowed: boolean;
};

export type DownloadResourceSpec = {
  name: string;
  icon: string | null;
  description: string | null;
  parentId: string | null;
  filename: string | null;
};

export type DownloadResource = {
  id?: string;
  icon: string | null;
  description: string | null;
  name: string;
} & (
  | {
      type: "folder";
      children: DownloadResource[];
    }
  | { type: "file" }
);

export type ParentRelationships = "manager" | "coach" | "mentor";
export type ChildRelationships = "direct-report" | "coachee" | "mentee";

export type RelationshipType = ParentRelationships | ChildRelationships;
export type SamlLevels = "off" | "allow" | "require";

export type OrgSecuritySettings = {
  createUser: boolean;
  useSaml: SamlLevels;
  signOnUrl: string | null;
  issuer: string | null;
  publicCert: string | null;
};

export type UserProfile = {
  id: string;
  name: string;
  email: string;
  profileImage: string | null;
  userCreated: number;
  viewerRelationship: RelationshipType | null;
  lessons: UserContentDto[];
  conversations: Conversation[];
  mindset: UserContentDto[];
  groups: UserGroup[];
};

export type AgendaItemSource = "org" | "custom" | "user-goal";

export type AgendaItem = {
  id: string;
  source: AgendaItemSource;
  goal: Goal;
  name: string;
  body: string;
  notes?: string | null;
  privateNotes?: string | null;
};

export type ConversationCompletion = {
  agendaNotes: {
    id: string;
    publicNotes: string;
    privateNotes: string;
  }[];
};

export type Conversation = {
  id: string;
  creator: UserDisplay;
  target: UserDisplay;
  scheduledTime: number;
  duration: number;
  agenda: AgendaItem[];
  completedOn: number | null;
  cancelled: {
    userId: string;
    date: number;
  } | null;
};

export type AgendaItemSpec = {
  source: AgendaItemSource;
  goalId?: string | null;
  name: string;
  body: string;
};

export type ConversationSpec = {
  targetId: string;
  scheduledTime: number;
  duration: number;
  agenda: AgendaItemSpec[];
};

export type GoalCommon = {
  id?: string;
  userId: string;
};

export type Objective = {
  id?: string;
  goal: string;
  reality: string;
  options: string;
  will: string;
  completedOn: number | null;
  createdOn: number;
  progress: number;
  progressLastSet: number | null;
  status: GoalReviewStatusOptions;
  updatedOn: number | null;
};

export type SmartGoal = {
  s: string;
  m: string;
  a: string;
  r: string;
  t: number;
  resourcesNeeded: string;
  motivationLevel: number;
  goalSystem: "smart";
  objectives: Objective[];
  progress: number;
  progressLastSet: number | null;
  createdOn: number;
  completedOn: number | null;
  conversationNotes: {
    privateNotes?: string;
    publicNotes: string;
    date: number;
    user: UserDisplay;
  }[];
};

export type Goal = GoalCommon & SmartGoal;

export type AddressBookItem = {
  id: string;
  name: string;
  email?: string;
  type: "user" | "group" | "userfield";
};

export type EmailComm = {
  to: "org" | AddressBookItem[];
  subject: string;
  body: string;
};

export type DiscussionPointTemplate = {
  id?: string;
  orgId?: string;
  name: string;
  body: string;
};

export type LessonAttemptImport = {
  email: string;
  lessonId: string;
  completionDate: number;
};

export type SurveySummary = {
  id: string;
  name: string;
  description: string | null;
  thumbnail: string | null;
  createdOn: number;
  creatorId: string | null;
  responseCount?: number;
  recipientCount?: number;
  archived: boolean;
  isDraft: boolean;
};

export type UserSurvey = SurveySummary & {
  completedOn: number | null;
};

export type SurveyCampaignDto = {
  id: string;
  name: string;
  orgId: string;
  description: string | null;
  thumbnail: string | null;
  createdOn: number;
  creatorId: string | null;
  emailSubject: string | null;
  emailBody: string | null;
  form: {
    id: string;
    name: string;
    form: FormModel;
  };
  recipients: (UserDisplay & {
    completedOn: number | null;
    lastNudged: number | null;
  })[];
  sendEmail: boolean;
  archived: boolean;
  isDraft: boolean;
};

export type Survey = {
  id: string;
  name: string;
  orgId: string;
  description: string | null;
  thumbnail: string | null;
  createdOn: number;
  creatorId: string | null;
  emailSubject: string | null;
  emailBody: string | null;
  form: SurveyForm;
  recipients: {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    profileImage: string | null;
    email: string;
    completedOn: number | null;
  }[];
  sendEmail: boolean;
};

export type SurveySpec = {
  name: string;
  description: string;
  thumbnail: string | null;
  emailSubject: string;
  emailBody: string;
  surveyFormId: string;
  recipients: string[];
  sendEmail: boolean;
  isDraft: boolean;
  deadline: number | null;
};

export type SurveyFormSummary = {
  id: string;
  name: string;
  formId: string;
  orgId: string;
  createdOn: number;
  creatorId: string | null;
  importedId: string | null;
};

export type SurveyForm = {
  id: string;
  name: string;
  orgId: string;
  createdOn: number;
  form: Required<FormModel>;
  deleted: boolean;
  importedId: string | null;
};

export type SurveyFormDto = {
  id: string;
  name: string;
  org: OrgDisplay;
  createdOn: number;
  form: FormModel;
  deleted: boolean;
  importedId: string | null;
};

export type FeedbackFormDto = {
  id: string;
  name: string;
  org: OrgDisplay;
  createdOn: number;
  form: FormModel;
  deleted: boolean;
  importedId: string | null;
};

export type IncomingRelationshipNotification = {
  id: string;
  created: Date;
  type: "incoming_relationship";
  sender: {
    id: string;
    name: string;
    profileImage: string;
  };
  relationship: {
    id: string;
    type: string;
    from: {
      id: string;
      name: string;
    };
    to: {
      id: string;
      name: string;
    };
  };
};

export type ContentType = "module" | "lesson" | "assessment" | "learncast";

export type ShareContent = {
  id: string;
  type: ContentType;
  name: string;
  slug: string;
  icon: string;
};

export type FeedbackNotification = {
  id: string;
  type: "new_feedback_campaign";
  created: Date;
  name: string;
  userCampaignId: string;
};

export type ConversationCommentNotification = {
  id: string;
  type: "conversation_comment";
  created: Date;
  sender: UserDisplay;
  conversationId: string;
  conversationOccurrence: number;
};

export type ConversationAgendaNotification = {
  id: string;
  type: "conversation_agenda";
  created: Date;
  sender: UserDisplay;
  conversationId: string;
  conversationOccurrence: number;
};

export type ContinuousFeedbackNotification = {
  id: string;
  type: "continuous_feedback_sent";
  created: Date;
  sender: string;
  summary: string;
  feedbackId: string;
};

export type SurveyNotification = {
  id: string;
  type: "new_survey";
  created: Date;
  name: string;
  surveyId: string;
};

export type ShareNotification = {
  id: string;
  created: Date;
  type: "share";
  sender: {
    id: string;
    name: string;
  };
  content: ShareContent;
};

export type GroupInviteNotification = {
  id: string;
  created: Date;
  type: "group";
  sender: {
    id: string;
    name: string;
  };
  group: {
    id: string;
    name: string;
  };
};

export type ConfirmAddressNotification = {
  id: string;
  type: "confirm_address";
  created: Date;
};

export type FeedbackResponseNotification = {
  id: string;
  created: Date;
  name: string;
  type: "feedback_response";
  userCampaignId: string;
};

export type ConversationNotification = {
  id: string;
  created: Date;
  type: "conversation";
  action: "new" | "cancel";
  sender: {
    id: string;
    name: string;
  };
  conversation: {
    id: string;
    time: number;
  };
};

export type Notification =
  | IncomingRelationshipNotification
  | ShareNotification
  | GroupInviteNotification
  | ConfirmAddressNotification
  | FeedbackNotification
  | FeedbackResponseNotification
  | SurveyNotification
  | ConversationNotification
  | ContinuousFeedbackNotification
  | ConversationCommentNotification
  | ConversationAgendaNotification;

export type OrgDisplay = {
  id: string;
  name: string;
};

export type OrgSummary = {
  id: string;
  name: string;
  createdAt: Date;
  status: OrgStatus;
  statusLastChanged: number;
  partner: {
    id: string;
    name: string;
  } | null;
  owner: {
    id: string;
    name: string;
    email: string;
  } | null;
  products: {
    ident: string;
    adminVisibility: ProductVisibility;
    userVisibility: ProductVisibility;
  }[];
};

export type UpdateOrgBrandingSpec = {
  logo: string | null;
  primaryColor: string | null;
};

export type UpdateOrgTerminologySpec = {
  goalName: string;
  objectiveName: string;
};

export type UpdateContentAccessSpec = {
  allowSuggestedContent?: boolean;
  content: {
    id: string;
    type: ContentType;
    public: boolean;
    conditions: (
      | {
          type: "permission";
          permission: UserPermission;
          canView: boolean;
          canShare: boolean;
        }
      | {
          type: "userfield";
          userFieldOptionId: string;
          canView: boolean;
          canShare: boolean;
        }
    )[];
  }[];
};

export type OrgDto = {
  id: string;
  name: string;
  createdAt: number;
  status: OrgStatus;
  statusLastChanged: number;
  hubspotId: number | null;
  isRestricted: boolean;
  emailsDisabled: boolean;
  allowSuggestedContent: boolean;
  goalName: string;
  objectiveName: string;
  collectRatings: boolean;
  allowImpersonation: boolean;
  theme: {
    primaryColor: string | null;
    logo: string | null;
  };
  partner: {
    id: string;
    name: string;
    isSuperPartner: boolean;
  };
  creator: {
    id: string;
    email: string;
    name: string;
  } | null;
  products: {
    ident: ProductIdent;
    isOrgWide: boolean;
    name: string;
    adminVisibility: ProductVisibilityStr;
    userVisibility: ProductVisibilityStr;
  }[];
  integrationsEnabled: boolean;
};

export type UpdateOrgByAdminDto = {
  status: string;
  name: string;
  hubspotId: number | null;
  emailsDisabled: boolean;
  collectRatings: boolean;
  integrationsEnabled: boolean;
  partner: {
    id: string;
  };
  products: {
    ident: ProductIdent;
    adminVisibility: ProductVisibilityStr;
    userVisibility: ProductVisibilityStr;
  }[];
};

export type LessonAttemptStatus = {
  completion: "not-started" | "started" | "completed";
  completionDate: number | null;
};

export type LessonSummaryReportItem = {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string | null;
  email: string;
  groups: {
    id: string;
    name: string;
  }[];
  userFields: {
    id: string;
    value: string | number;
  }[];
  lessons: Record<string, LessonAttemptStatus>;
};

export type UserFilter = {
  groups: string[];
  userFields: {
    id: string;
    value: string[];
  }[];
};

export type ModuleInsight = {
  id: string;
  name: string;
  icon: string;
  mainColor: string;
  graphType: string;
  started: number;
  completed: number;
  averageRating: number | null;
  challengeCompleted: number | null;
  challengeStarted: number | null;
  formCompleted: number | null;
  data: {
    name: string;
    count: number;
  }[];
  reportHelp: string;
  xAxis: string;
  yAxis: string;
};

export type InsightsData = {
  totalUsers: number;
  moduleAttempts: number;
  activeUsersWeek: number;
  activeUsersMonth: number;
  modules: ModuleInsight[];
  activity: number[][];
};

export type DashboardDataSet = {
  total: number;
  thisMonth: number;
  lastMonth: number;
  thisWeek: number;
  lastWeek: number;
  thisYear: number;
  lastYear: number;
};

export type ContentHubDownloadReportItem = {
  email: string;
  downloadCount: number;
  lastDownload: number;
  lastActive: number;
};

export type SuperDashboard = {
  community: DashboardDataSet;
  userCount: number;
  activeMonthly: number;
  activeWeekly: number;
  activeYearly: number;
  activeMonthlyRatio: number;
  usersByMonth: [string, number][];
  usersByMonthCum: [string, number][];
  lessonData: LessonModuleDataSet;
  moduleData: LessonModuleDataSet;
  goalData: GoalDataSet;
  downloadData: ContentHubDownloadReportItem[];
};

export type LessonModuleDataSet = {
  weeklyStarted: number;
  monthlyStarted: number;
  yearlyStarted: number;
  weeklyCompleted: number;
  monthlyCompleted: number;
  yearlyCompleted: number;
};

export type GoalDataSet = {
  goalCount: number;
  completedWeekly: number;
  completedMonthly: number;
  completedYearly: number;
  createdWeekly: number;
  createdMonthly: number;
  createdYearly: number;
  reviewsWeekly: number;
  reviewsMonthly: number;
  reviewsYearly: number;
};

export type Permission = {
  key: string;
  label: string;
  requiredPermission: string;
  description: string;
};

export enum UserFieldType {
  FreeText,
  Select,
  Bool,
}

export type UserFieldOption = {
  id?: string;
  name: string;
};

export type UserFieldEditMode = "admin" | "user";

export type UserField = {
  id?: string;
  name: string;
  orgId?: string;
  type: UserFieldType;
  editable: UserFieldEditMode;
  options?: UserFieldOption[];
};

export type BoolUserFieldDto = {
  id?: string;
  name: string;
  type: UserFieldType.Bool;
  editable: UserFieldEditMode;
};

export type FreeTextUserFieldDto = {
  id?: string;
  name: string;
  type: UserFieldType.FreeText;
  editable: UserFieldEditMode;
};

export type UserFieldOptionDto = {
  id?: string;
  name: string;
};

export type SelectUserFieldDto = {
  id?: string;
  name: string;
  type: UserFieldType.Select;
  options: UserFieldOptionDto[];
  editable: UserFieldEditMode;
};

export type UserFieldDto =
  | BoolUserFieldDto
  | FreeTextUserFieldDto
  | SelectUserFieldDto;

export type ContentItem = {
  id: string;
  name: string;
  slug: string;
  description: string;
  icon: string;
  type: ContentType;
  category: {
    id: string;
    name: string;
    orderIndex: number;
  };
  orderIndex: number;
};

export type UserPermission =
  | "manager"
  | "user-admin"
  | "org-admin"
  | "community";

export type PermissionContentAccessConditionDto = {
  type: "permission";
  permission: UserPermission;
  canView: boolean;
  canShare: boolean;
};

export type UserFieldContentAccessConditionDto = {
  type: "userfield";
  userFieldOptionId: string;
  canView: boolean;
  canShare: boolean;
};

export type ContentAccessConditionDto =
  | PermissionContentAccessConditionDto
  | UserFieldContentAccessConditionDto;

export type ContentAccessDto = {
  content: ContentItem;
  public: boolean;
  conditions: ContentAccessConditionDto[];
};

export type ContentSettings = {
  content: ContentItem;
  enabled: boolean;
  isPublic: boolean;
  canDownload: boolean;
  managerVisibility: number;
};

export type LessonSpec = {
  id?: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  path: string;
  scormFile: string | null;
  category: string;
  orderIndex: number;
};

export type Lesson = {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  path: string;
  scormFile: string | null;
  orderIndex: number;
  category: {
    id: string;
    name: string;
    orderIndex: number;
  };
};

export type RateContentSpec = {
  id: string;
  type: ContentType;
  rating: number | null;
  feedback1: string;
  feedback2: string;
  feedback3: string;
};

export type LearnCastSpec = {
  id?: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  path: string;
  scormFile: string | null;
  orderIndex: number;
};

export type LearnCastDto = {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  path: string;
  scormFile: string | null;
  orderIndex: number;
};

export type LearnCastAttemptDto = {
  id: string;
  completedOn: Date | null;
  startedOn: Date | null;
  programmeCompletedOn: Date | null;
  lmsData: object | null;
};

export type LessonAttempt = {
  id: string;
  completedOn: Date | null;
  startedOn: Date | null;
  programmeCompletedOn: Date | null;
  lmsData: object | null;
};

export type LessonSharingConfig = {
  public: boolean;
  managerVisibility: ManagerVisibility;
};

export type UserLesson = Lesson & UserContent;

export enum GroupMembershipLevel {
  Invited,
  Member,
  Admin,
  Owner,
}

export type UserGroup = {
  id: string;
  name: string;
  description: string;
  createdOn: Date;
  membershipLevel: GroupMembershipLevel | null;
};

export type GroupMember = {
  id: string;
  name: string;
  email: string;
  membershipLevel: GroupMembershipLevel;
};

export type GroupContent = {
  id: string;
  type: ContentType;
  name: string;
  slug: string;
  icon: string;
};

export type FullGroup = {
  id: string;
  name: string;
  description: string;
  createdOn: Date;
  orgId: string;
  members: GroupMember[];
  content: GroupContent[];
};

export type UpdateGroupDto = {
  name: string;
};

export type GroupDto = {
  id: string;
  name: string;
  description: string;
  createdOn: number;
  orgId: string;
  members: (UserDisplay & { membershipLevel: GroupMembershipLevel })[];
  content: ContentItem[];
};

export type GroupUpdateSpec = {
  name: string;
  description: string;
};

export type LessonReportItem = {
  lesson: {
    id: string;
    name: string;
    icon: string;
  };
  user: UserSummary;
  rating: number | null;
  status: "completed" | "started" | "not-started";
  dateCompleted: Date | null;
};

export type MindsetReportItem = {
  user: UserSummary;
  rating: number | null;
  status: "completed" | "started" | "not-started";
  dateCompleted: Date | null;
};

export type RatingType = "dots" | "smiles" | "stars" | "number";

export type HeadingFormItem = {
  id: string;
  content: string;
  type: "heading";
};

export type MultipleChoiceAnswer = {
  id: string;
  content: string;
  value: number | null;
};

export type MultipleChoiceAnswerSpec = {
  id?: string;
  content: string;
  value: number | null;
};

export type MultipleChoiceQuestionFormItem = {
  id: string;
  questionId?: string;
  content: string;
  group: string | null;
  type: "multiple_choice";
  answers: MultipleChoiceAnswer[];
};

export type MultipleChoiceQuestionFormItemSpec = {
  id?: string;
  questionId?: string;
  content: string;
  group: string | null;
  type: "multiple_choice";
  answers: MultipleChoiceAnswerSpec[];
};

export type MultipleChoiceQuestionFormItemResponses =
  MultipleChoiceQuestionFormItem & {
    responses: (ResponseBase & {
      answer: string;
    })[];
  };

export type CompletedMultipleChoiceQuestionFormItem =
  MultipleChoiceQuestionFormItem & {
    answer: string;
  };

export type FreeTextQuestionFormItem = {
  id: string;
  questionId?: string;
  content: string;
  type: "free_text";
  fieldType: "multiline" | "singleline";
};

export type FreeTextQuestionFormItemResponses = FreeTextQuestionFormItem & {
  responses: (ResponseBase & {
    content: string;
  })[];
};

export type CompletedFreeTextQuestionFormItem = FreeTextQuestionFormItem & {
  answer: string;
};

export type RatingFormItem = {
  id: string;
  questionId?: string;
  content: string;
  ratingType: RatingType;
  requiresEvidence: boolean;
  allowNa: boolean;
  lowRangeLabel: string | null;
  highRangeLabel: string | null;
  noEvidenceLabel: string | null;
  reverseScale: boolean;
  type: "rating";
};

export type Section = {
  heading?: HeadingFormItem | null;
  items: FormResponses[];
};

export type RatingFormItemResponses = RatingFormItem & {
  responses: (ResponseBase & {
    rating: number | null;
    evidence: string | null;
  })[];
};

export type ContentFormItem = {
  id: string;
  type: "content";
  title: string;
  content: string;
};

export type CompletedRatingFormItem = RatingFormItem & {
  rating: number | null;
  evidence: string | null;
};

export type FormItem =
  | HeadingFormItem
  | MultipleChoiceQuestionFormItem
  | FreeTextQuestionFormItem
  | RatingFormItem
  | ContentFormItem;

export type FormItemDto = FormItem;

export type FormItemSpec =
  | Spec<HeadingFormItem>
  | MultipleChoiceQuestionFormItemSpec
  | Spec<FreeTextQuestionFormItem>
  | Spec<RatingFormItem>
  | Spec<ContentFormItem>;

export type FormModel = {
  id: string;
  name: string;
  items: FormItem[];
};

export type FormDto = {
  id: string;
  name: string;
  items: FormItemDto[];
};

export type FormModelSpec = {
  id?: string | null;
  name: string;
  items: FormItemSpec[];
};

export type ResponseBase = {
  name?: string | null;
  email?: string | null;
  completionId: string;
  feedbackRelationshipId?: string | null;
};

export type FormResponses =
  | MultipleChoiceQuestionFormItemResponses
  | RatingFormItemResponses
  | FreeTextQuestionFormItemResponses
  | HeadingFormItem
  | ContentFormItem;

export type CompletedFormItem =
  | HeadingFormItem
  | CompletedMultipleChoiceQuestionFormItem
  | CompletedFreeTextQuestionFormItem
  | CompletedRatingFormItem
  | ContentFormItem;

export type CompletedFormModel = {
  id: string;
  name: string;
  items: CompletedFormItem[];
};

export type UserContentDto = ContentItem & UserContent;

export type UserContentAssignmentDto = {
  assignedOn: number;
  assigner: UserDisplay;
  group?: {
    id: string;
    name: string;
  };
};

export type UserContent = {
  attempts: {
    id: string;
    startedOn: number;
    completedOn: number | null;
  }[];
  assignments: UserContentAssignmentDto[];
  canShare: boolean;
  assignmentReasons: ContentAccessReason[];
};

export type ContentDisplay = {
  id: string;
  name: string;
  slug: string;
  icon: string | null;
  description: string;
  type: ContentType;
};

export type AssessmentSummary = {
  id: string;
  name: string;
  slug: string;
  icon: string;
  description: string;
  formId: string | null;
  isDiagnostic: boolean;
  isPublished: boolean;
};

export type UserAssessmentSummary = AssessmentSummary & UserContent;

export type PageBreakOptions = "none" | "before" | "after";

export type AssessmentOutcome = {
  id: string;
  name: string;
  content: string;
  query: any;
  pageBreak: PageBreakOptions;
  suggestedContent: null | ContentDisplay;
};

export type Assessment = AssessmentSummary & {
  outcomes: AssessmentOutcome[];
};

export type AssessmentData = Assessment & {
  form: FormModel;
};

export type FormLibraryCategory = {
  id: string;
  name: string;
};

export type FormLibraryItem = {
  id: string;
  name: string;
  description: string;
  icon: string | null;
  type: "survey" | "feedback";
  isPublished: boolean;
  formId: string;
  categoryId: string | null;
};

export type FormSummary = {
  id: string;
  name: string;
  type: "mindset" | "survey" | "feedback" | "assessment" | "library" | "none";
  org: {
    id: string;
    name: string;
  } | null;
};

export type QueryRule = {
  id?: string;
  field: string;
  value: string | number[] | number | string[];
  operator:
    | "greater_or_equal"
    | "less_or_equal"
    | "between"
    | "greater"
    | "lesser"
    | "equal";
};

export type Query = {
  condition: "AND" | "OR";
  rules: (Query | QueryRule)[];
};

export type ModulePostAssessment = {
  id: string;
  videoUrl: string;
  transcript: string;
};

export type ModuleProfile = {
  id: string;
  content: string;
  icon: string | null;
  name: string;
  showAssessmentResults: "none" | "form-groups";
};

export type ModuleProgramme = {
  id: string;
  name: string;
  url: string;
  challengeFormId: string | null;
  challengeDaysVarName: string | null;
  challengeText: string | null;
  associatedGroup: string;
  questions: {
    id: string;
    varName: string;
    content: string;
  }[];
};

export type ProgrammeAnswer = {
  id: string;
  question: string;
  answer: string;
};

export type ModuleAttempt = {
  id: string;
  module: ModuleSummary;
  userId: string;
  started: Date;
  completed: Date | null;
  formCompletionId: string | null;
  challengeStarted: Date | null;
  challengeDays: number | null;
  challengeCompletionId: string | null;
  profiles: null | ModuleProfile[];
  postAssessment: null | ModulePostAssessment;
  assessmentScores: ScoreSet | null;
  recommendedProgramme: string | null;
  availableProgrammes: ModuleProgramme[];
  programme: null | ModuleProgramme;
  lmsData: any;
  assessment?: CompletedFormModel;
  challenge?: CompletedFormModel;
  programmeAnswers?: ProgrammeAnswer[] | null;
  isLegacyAttempt: boolean;
};

export type ScoreSet = {
  totalScore: number;
  groups: {
    name: string;
    score: number;
  }[];
};

export type ModuleAttemptSummary = {
  id: string;
  started: Date;
  completed: Date | null;
  challengeStarted: Date | null;
  challengeDays: number | null;
  isLegacyAttempt: boolean;
  module: {
    id: string;
    name: string;
    slug: string;
    icon: string;
  };
};

export type CampaignSummary = {
  id: string;
  name: string;
  createdOn: Date;
  userCount: number;
  responseCount: number;
  archived: boolean;
  isDraft: boolean;
};

export type UserCampaignSummary = {
  id: string;
  campaignUserId: string;
  name: string;
  image: string | null;
  description: string | null;
  respondentCount: number;
  earliestDeadline: number | null;
  createdOn: Date;
  user: {
    id: string;
    name: string;
    email: string;
  };
  archived: boolean;
};

export type FeedbackAnonymousType = "anonymous" | "transparent";
export type FeedbackResultsVisibility = "self" | "manager" | "coach" | "admin";

export type FeedbackSummaryInsights = {
  users: (UserSummary & {
    feedbackCampaignUserId: string;
    responseCount: number;
    respondentCount: number;
    averageScore: {
      value: number;
      type: RatingType;
    } | null;
    completionIds: string[];
    lastNudged: number | null;
  })[];
  items: FormResponses[];
};

export type FeedbackInsights = {
  responseCount: number;
  items: FormResponses[];
};

export type CampaignSpec = {
  name: string;
  description: string;
  image: string | null;
  feedbackFormId: string;
  minimumForwards: number | null;
  resultsVisibility: FeedbackResultsVisibility[];
  anonType: FeedbackAnonymousType;
  users: string[];
  email: string;
  emailSubject: string;
  respondentEmail: string;
  respondentEmailSubject: string;
  relationships: { name: string; id: string | null }[] | null;
  viewers: UserDisplay[];
  sendEmail: boolean;
  isDraft: boolean;
};

export type CampaignForward = {
  id: string;
  userId?: string | null;
  name?: string | null;
  email: string;
  sentOn: Date;
  lastNudged: Date | null;
  completed: Date | null;
  relationship: {
    id: string;
    name: string;
  } | null;
};

export type FeedbackCampaignCommon = {
  id: string;
  name: string;
  description: string;
  orgId: string;
  creator: {
    id: string;
    name: string;
    email: string;
  } | null;
  formId: string;
  anonType: FeedbackAnonymousType;
  resultsVisibility: FeedbackResultsVisibility[];
  viewers: UserDisplay[];
  minimumForwards: number;
  createdOn: Date;
  userEmailSubject: string;
  userEmailTemplate: string;
  respondentEmailSubject: string;
  respondentEmailTemplate: string;
  collectRelationship: boolean;
  relationships: {
    id: string;
    name: string;
  }[];
  sendEmail: boolean;
  archived: boolean;
  isDraft: boolean;
  thumbnail: string;
};

export type FeedbackCampaign = FeedbackCampaignCommon & {
  users: {
    id: string;
    campaignUserId: string;
    name: string;
    email: string;
    code: string;
  }[];
};

export type ManagerCampaign = FeedbackCampaignCommon & {
  userCampaignId: string;
  masterCode: string;
  forwards: CampaignForward[];
  user: UserDisplay;
};

export type FeedbackForm = {
  id: string;
  orgId: string;
  name: string;
  deleted: boolean;
  published: boolean;
  form: FormModel;
};

export type CampaignReport = {
  id: string;
  users: {
    id: string;
    userCampaignId: string;
    name: string;
    email: string;
    respondents: {
      email: string;
      sentOn: Date | null;
      formCompletionId: string;
    }[];
  }[];
};

export type FeedbackFormSummary = {
  id: string;
  name: string;
  createdOn: Date;
  published: boolean;
  formId: string;
  campaignCount: number;
  creator: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
};

export type MindsetCompletionReport = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string | null;
  groups: {
    id: string;
    name: string;
  }[];
  userFields: {
    id: string;
    value: number | string;
  }[];
  modules: {
    id: string;
    completion: "started" | "not-started" | "completed";
    completionDate: number | null;
  }[];
};

export type UserReportItem = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  profileImage: string | null;
  groups: {
    id: string;
    name: string;
  }[];
  userFields: {
    id: string;
    value: number | string;
  }[];
};

export type ContentReportItem = UserReportItem & {
  completion: "not-available" | "started" | "not-started" | "completed";
  dateCompleted: number | null;
  attemptCount: number;
  rating?: number;
};

export type ContentCompletionReport = UserReportItem & {
  content: {
    id: string;
    completion: "not-available" | "started" | "not-started" | "completed";
    completionDate: number | null;
  }[];
};

export type FeedbackFormSpec = {
  name: string;
  published: boolean;
  form: FormModelSpec;
};

export type FeedbackSurvey = {
  id: string;
  relationships:
    | {
        id: string;
        name: string;
      }[]
    | null;
  sender: {
    id: string;
    email: string;
    name: string;
  };
  form: FormModel;
};

export type Webinar = {
  id?: string;
  title: string;
  subtitle: string | null;
  description: string | null;
  image: string | null;
  date: Date;
  isPublished: boolean;
  vimeoUrl: string | null;
  signupUrl: string | null;
};

export type CampaignUser = {
  id: string;
  campaignId: string;
  formId: string;
};

export type ModuleRatingSpec = {
  rating: number;
  feedback1: string;
  feedback2: string;
  feedback3: string;
};

export enum ModuleAccess {
  Unavailable = 0,
  CanView = 1,
  CanShare = 2,
}

export type ModuleSettings = {
  module: {
    id: string;
    name: string;
    icon: string;
  };
  enabled: boolean;
  managerVisibility: ModuleAccess;
  isPublic: boolean;
};

export type ModuleSummary = {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  formId: string;
  mainColor: string;
  published: boolean;
};

export type FullModuleProfile = {
  id?: string;
  name: string;
  content: string;
  icon: string;
  query: Query;
  insightsName: string;
  insightsInclude: boolean;
  showAssessmentResults: "none" | "form-groups";
  tagName: string | null;
};

export type ProgrammeQuestion = {
  id: string;
  varName: string;
  content: string;
  ident: string;
};

export type FullModuleProgramme = {
  id?: string;
  name: string;
  query: Query;
  url: string;
  associatedGroup: string;
  challengeDaysVarName: string | null;
  challengeFormId: string | null;
  challengeText: string;
  questions: ProgrammeQuestion[];
};

export type FullModulePostAssessment = {
  id?: string;
  videoUrl: string;
  transcript: string;
  query: Query;
};

export type FullModule = {
  id?: string;
  slug: string;
  name: string;
  scormFile: string | null;
  suggestedProgramme: "highest" | "lowest" | "none";
  published: boolean;
  description: string;
  icon: string;
  color: string;
  formId: string | null;
  insightsType: InsightsType;
  insightsGraphType: InsightsGraphType;
  profiles: FullModuleProfile[];
  programmes: FullModuleProgramme[];
  postAssessments: FullModulePostAssessment[];
  reportHelp: string;
  xAxis: string;
  yAxis: string;
};

export type ContentAccessReason =
  | "public"
  | "admin"
  | "group"
  | "user"
  | "manager"
  | "userfield";

export type UserModuleSummary = ModuleSummary & {
  attempts: {
    started: Date;
    completed: Date | null;
  }[];
  canShare: boolean;
  assignmentReasons: ContentAccessReason[];
};

export type MindsetSummaryReportItem = {
  id: string;
  name: string;
  icon: string;
  attemptCount: number;
  averageRating: number | null;
};

export type ContentSummaryReportItem = {
  id: string;
  name: string;
  icon: string;
  category: string;
  usersAvailable: number;
  usersStarted: number;
  usersCompleted: number;
  averageRating: number | null;
};

export type ContinuousFeedbackType = "praise" | "feedback";

export type ContinuousFeedbackDto = {
  id: string;
  summary: string;
  detail: string | null;
  type: ContinuousFeedbackType;
  sentOn: number;
  sentBy: UserDisplay;
  recievedBy: UserDisplay;
};

export type ContinuousFeedbackRequestDto = {
  id: string;
  reason: string;
  sentOn: number;
  requester: UserDisplay;
};

export type SendFeedbackRequest = {
  senderId: string;
  recipientId: string;
  type: ContinuousFeedbackType;
  summary: string;
  detail: string;
  requestId?: string;
};

export type RequestFeedbackRequest = {
  requesterId: string;
  recipientId: string;
  reason: string;
};
