export enum ResourceType {
  Resource = 0,
  Magazine = 1,
}

export type Resource = {
  id: string;
  title: string;
  publishDate: Date;
  isPublished: boolean;
  isFeatured: boolean;
  description: string;
  filename: string;
  image: string;
  type: ResourceType;
  downloadFilename: string;
};
