import React from "react";
import { Button, Flex, Panel, Text } from "@coaching-culture/ui";
import styled from "styled-components";
import { useTrail, animated } from "react-spring";
import { grid, GridProps } from "styled-system";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { useUser } from "auth";

const ItemGrid = styled.div<GridProps>`
  display: grid;
  max-width: 100%;
  width: 1000px;
  ${grid}
`;

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 26,
};

type GridItem = {
  name: string;
  content: string;
  cta: string;
  url: string;
  comingSoon?: boolean;
  icon?: string;
  permissions?: string[];
  product?: string;
  locked?: boolean;
};

type HomeGridProps = {
  items: GridItem[];
  color?: "primary" | "blue";
};

const Icon = styled.img`
  width: 50%;
  margin-bottom: 18px;
`;

const HomeGrid = ({ items, color = "primary" }: HomeGridProps) => {
  const products = useAllProductVisibility();
  const [user] = useUser();

  const doesOrgHaveAccess = (x: GridItem): GridItem | null => {
    if (x.product == null) {
      return {
        ...x,
      };
    }

    const vis = products[x.product];

    return vis === 0
      ? null
      : {
          ...x,
          comingSoon: vis === 2,
          locked: vis === 1,
        };
  };

  const filteredItems = items
    .map(doesOrgHaveAccess)
    .filter((x) => x != null)
    .filter(
      (x) =>
        x.permissions == null ||
        user.permissions.some((p) => x.permissions.includes(p))
    );

  const itemStyles = useTrail(filteredItems.length, {
    transform: "scale(1)",
    opacity: 1,
    config: springConfig,
    delay: 200,
    from: {
      transform: "scale(0.7)",
      opacity: 0,
    },
  });

  return (
    <ItemGrid
      gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
      gridGap={4}
    >
      {filteredItems.map((x, i) => (
        <animated.div style={itemStyles[i]} key={i}>
          <Panel p={4} color="none" height="100%">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
            >
              <Flex flexDirection="column" alignItems="center" width="100%">
                <Text fontWeight={600} mb={0} fontSize={5} textAlign="center">
                  {x.name}
                </Text>
                {x.icon && <Icon src={x.icon} />}
                <Text textAlign="center" mb={3}>
                  {x.content}
                </Text>
              </Flex>
              <Button
                to={x.url}
                color={color}
                disabled={x.comingSoon || x.locked}
                width="180px"
              >
                {x.comingSoon ? "Coming Soon" : x.locked ? "Locked" : x.cta}
              </Button>
            </Flex>
          </Panel>
        </animated.div>
      ))}
    </ItemGrid>
  );
};

export default HomeGrid;
