import { format, fromUnixTime, isAfter, isBefore } from "date-fns";
import { enGB } from "date-fns/locale";

export const getDayName = (day: number) =>
  [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][day];

export function formatDate(date: Date | number, formatStr: string): string {
  const d = typeof date === "number" ? fromUnixTime(date) : date;
  return format(d, formatStr, { locale: enGB });
}

export const isDateBetween = (date: Date, before: Date, after: Date) =>
  isAfter(date, before) && isBefore(date, after);
