import React from "react";
import FormInputWrap from "./FormInputWrap";
import Select from "./Select";
import { FormInputWrapProps } from "./FormInputWrap";

type FormSelectProps = FormInputWrapProps & {
  name?: string;
  label?: string;
  error?: any;
  defaultValue?: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
  options: {
    value: string;
    label: string;
  }[];
};

const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  (props, ref) => {
    const {
      name,
      label,
      error,
      defaultValue,
      required,
      value,
      options,
      disabled,
      onChange,
      ...rest
    } = props;

    return (
      <FormInputWrap
        name={name}
        label={label}
        error={error}
        required={required}
        {...rest}
      >
        <Select
          name={name}
          onChange={onChange}
          value={value}
          ref={ref}
          disabled={disabled}
          defaultValue={defaultValue}
          options={options}
        />
      </FormInputWrap>
    );
  }
);

export default FormSelect;
