import { UserGroup } from "@coaching-culture/types";
import {
  Button,
  CheckBox,
  Flex,
  FormInput,
  FormInputWrap,
  FormSelect,
  IconButton,
  Input,
  Loader,
  Panel,
  PanelInset,
  Select,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import React, { useEffect, useState } from "react";
import { FaArrowDown, FaArrowUp, FaTimes } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.grey3};
  margin-left: 12px;
  margin-right: 12px;
`;

type EditUserFieldParams = { id: string };

enum UserFieldType {
  FreeText,
  Select,
  Bool,
}

type UserFieldOption = {
  id?: number;
  name: string;
};

type UserField = {
  id?: number;
  name: number;
  orgId: number;
  type: UserFieldType;
  options?: UserFieldOption[];
};

type SelfRegistrationField = {
  id?: number;
  userField: UserField;
  forcedValue: string | number | null;
};

type SelfRegistration = {
  id?: number;
  name: string;
  group: string;
  url: string;
  createdOn?: Date;
  fields: SelfRegistrationField[];
};

const defaultItem = (): SelfRegistration => ({
  name: "",
  group: "",
  url: "",
  fields: [],
});

const UserFieldInput = ({
  value,
  userField,
  onChange,
}: {
  value: string | number;
  userField: UserField;
  onChange: (v: any) => void;
}) => {
  return userField.type === UserFieldType.FreeText ? (
    <Input value={value} onChange={(ev) => onChange(ev.target.value)} />
  ) : userField.type === UserFieldType.Select ? (
    <Select
      value={value.toString()}
      onChange={(ev) => onChange(ev.target.value)}
      options={userField.options.map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }))}
    />
  ) : userField.type === UserFieldType.Bool ? (
    <Select
      value={value.toString()}
      onChange={(ev) => onChange(ev.target.value)}
      options={[
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ]}
    />
  ) : null;
};

const EditSelfReg = () => {
  const { id } = useParams<EditUserFieldParams>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState<UserField[]>([]);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [item, setItem] = useState<SelfRegistration | null>(null);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    const p = [
      axios.get("/api/groups").then(({ data }) => setGroups(data)),
      axios.get("/api/user-fields").then(({ data }) => setFields(data)),
    ];

    if (id !== "create") {
      p.push(
        axios
          .get(`/api/self-registration-forms/${id}`)
          .then(({ data }) => setItem(data))
      );
    } else {
      setItem(defaultItem());
    }

    Promise.all(p).then(() => setLoading(false));
  }, [id]);

  const setItemField =
    (field: string) =>
    (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      setItem((old) => ({
        ...old,
        [field]: ev.target.value,
      }));

  const getUnusedFields = () =>
    fields.filter((f) => !item.fields.some((x) => x.userField.id === f.id));

  const includeField = (field) => {
    setItem((old) => ({
      ...old,
      fields: [
        ...old.fields,
        {
          forcedValue: null,
          userField: field,
        },
      ],
    }));
  };

  const removeField = (index: number) => {
    setItem((old) => ({
      ...old,
      fields: old.fields.filter((x, i) => i !== index),
    }));
  };

  const setFieldAsForced = (idx: number, forced: boolean) => {
    const field = item.fields[idx];

    const forcedValue = forced
      ? field.userField.type === UserFieldType.Select
        ? field.userField.options[0].id
        : field.userField.type === UserFieldType.Bool
        ? "0"
        : ""
      : null;

    field.forcedValue = forcedValue;

    setItem({
      ...item,
    });
  };

  const validate = () => {
    const e: any = {};

    if (item.name.trim() === "") {
      e.name = { message: "Value is required" };
    }

    e.fields = item.fields.map((x) => {
      if (x.forcedValue != null && x.forcedValue === "") {
        return {
          message: "Value is required if forced",
        };
      } else {
        return null;
      }
    });

    return e;
  };

  const onSubmit = () => {
    const e = validate();

    if (e.name == null && e.fields.every((x) => x == null)) {
      setErrors({});

      const spec = {
        name: item.name,
        group: item.group,
        fields: item.fields.map((x) => ({
          id: x.userField.id,
          forcedValue: x.forcedValue,
        })),
      };

      const p =
        id === "create"
          ? axios.post("/api/self-registration-forms", spec)
          : axios.put(`/api/self-registration-forms/${id}`, spec);

      p.then(() => {
        history.push("/success/people/self-registration");
      });
    } else {
      setErrors(e);
    }
  };

  const setForcedValue = (idx: number, value: string) => {
    setItem((old) => ({
      ...old,
      fields: old.fields.map((x, i) => ({
        ...x,
        forcedValue: i === idx ? value : x.forcedValue,
      })),
    }));
  };

  const moveField = (idx: number, delta: number) => {
    const arr = item.fields;
    var element = arr[idx];
    arr.splice(idx, 1);
    arr.splice(idx + delta, 0, element);
    setItem((old) => ({
      ...old,
      fields: arr,
    }));
  };

  const groupOptions = [
    { label: "<None>", value: "" },
    ...groups.map((x) => ({ label: x.name, value: x.id.toString() })),
  ];

  return (
    <CenterColumn>
      <PageHeader
        text={id === "create" ? "Create Form" : "Edit Form"}
        subtitle="Configure a self registration form"
        backUrl="/success/people/self-registration"
      />
      <Panel p={3}>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <FormInput
              name="name"
              label="Form Name"
              value={item.name}
              onChange={setItemField("name")}
              error={errors.name}
            />
            <FormSelect
              name="group"
              label="Assign to Group"
              value={item.group}
              options={groupOptions}
              onChange={setItemField("group")}
              error={errors.group}
            />
            {id !== "create" && (
              <FormInput
                error={null}
                name="url"
                value={`https://platform.coachingculture.com/register/${item.url}`}
                readOnly
                disabled
                label="Url"
              />
            )}
            <Text fontWeight={600} mb={1}>
              Included Fields
            </Text>
            <PanelInset mb={2}>
              {item.fields.length === 0 ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  pl={3}
                  pr={3}
                  pt={2}
                  pb={2}
                >
                  <Text textAlign="center" color="grey3">
                    No Fields Included
                  </Text>
                </Flex>
              ) : (
                item.fields.map((x, i, self) => (
                  <Flex
                    key={x.userField.id}
                    flexDirection="column"
                    style={
                      i === self.length - 1
                        ? null
                        : { borderBottom: "1px solid #efefef" }
                    }
                    pt={2}
                    pl={3}
                    pr={3}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      flexDirection={["column", "row"]}
                      mb={2}
                    >
                      <Text fontWeight={600} color="primary" mb={[2, 0]}>
                        {x.userField.name}
                      </Text>
                      <Flex alignItems="center">
                        <CheckBox
                          mb={0}
                          label="Force Value"
                          value={x.forcedValue != null}
                          onChange={(v) => setFieldAsForced(i, v)}
                        />
                        <Separator />
                        <IconButton
                          color={i > 0 ? "primary" : "body"}
                          disabled={i === 0}
                          icon={FaArrowUp}
                          onClick={() => moveField(i, -1)}
                        />
                        <IconButton
                          color={i < self.length - 1 ? "primary" : "body"}
                          disabled={i === self.length - 1}
                          icon={FaArrowDown}
                          onClick={() => moveField(i, 1)}
                        />
                        <IconButton
                          icon={FaTimes}
                          color="danger"
                          onClick={() => removeField(i)}
                        />
                      </Flex>
                    </Flex>
                    {x.forcedValue != null && (
                      <>
                        <FormInputWrap
                          label="Forced Value"
                          error={errors.fields && errors.fields[i]}
                        >
                          <UserFieldInput
                            value={x.forcedValue}
                            userField={x.userField}
                            onChange={(v) => setForcedValue(i, v)}
                          />
                        </FormInputWrap>
                      </>
                    )}
                  </Flex>
                ))
              )}
            </PanelInset>
            <Text fontWeight={600} mb={1}>
              Unused Fields
            </Text>
            <PanelInset mb={3}>
              {getUnusedFields().length === 0 ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  pl={3}
                  pr={3}
                  pt={2}
                  pb={2}
                >
                  <Text textAlign="center" color="grey3">
                    No Unused Fields To Include
                  </Text>
                </Flex>
              ) : (
                getUnusedFields().map((x, i, self) => (
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    style={
                      i === self.length - 1
                        ? null
                        : { borderBottom: "1px solid #efefef" }
                    }
                    pl={3}
                    pr={3}
                    pt={2}
                    pb={2}
                  >
                    <Text fontWeight={600}>{x.name}</Text>
                    <Button onClick={() => includeField(x)}>
                      Include Field
                    </Button>
                  </Flex>
                ))
              )}
            </PanelInset>
            <Button type="button" onClick={onSubmit} color="primary">
              Save
            </Button>
          </div>
        )}
      </Panel>
    </CenterColumn>
  );
};

export default EditSelfReg;
