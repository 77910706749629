import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { AuthMethod, FullUser } from "@coaching-culture/types";

export const userKey = "cc-token";

type UserType = FullUser & {
  authMethod: AuthMethod;
  impersonator: { id: string } | null;
};

export const UserContext =
  createContext<
    [
      UserType | null,
      React.Dispatch<React.SetStateAction<string | null>>,
      (newUser: FullUser) => void
    ]
  >(null);

const setInterceptor = (onFail: () => void) => {
  return axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response?.status === 401) {
        onFail();
      }
      return Promise.reject(error);
    }
  );
};

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, _setUser] = useState<UserType | null>(null);
  const [token, setToken] = useState<string | null>(
    window.localStorage.getItem(userKey)
  );

  useEffect(() => {
    const interceptor = setInterceptor(() => {
      setToken(null);
      _setUser(null);
    });
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [setToken]);

  useEffect(() => {
    if (token) {
      window.localStorage.setItem(userKey, token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      setLoading(true);
      axios.get("/api/users/me").then(({ data }) => {
        _setUser(data);
        setLoading(false);
      });
    } else {
      window.localStorage.removeItem(userKey);
      axios.defaults.headers.common["Authorization"] = null;
      _setUser(null);
      setLoading(false);
    }
  }, [token]);

  const setUser = useCallback(
    (newUser: FullUser) => {
      _setUser({
        ...newUser,
        email: user.authMethod === "sso" ? user.email : newUser.email,
        authMethod: user.authMethod,
        impersonator: null,
      });
    },
    [user?.authMethod, user?.email]
  );

  return (
    <UserContext.Provider value={[user, setToken, setUser]}>
      {loading ? null : children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
