import styled from "styled-components";
import {
  space,
  layout,
  flexbox,
  grid,
  SpaceProps,
  LayoutProps,
  GridProps,
  FlexboxProps,
  shadow,
  ShadowProps,
  BorderProps,
  border,
  BackgroundProps,
  background,
} from "styled-system";
import { lighten } from "polished";

export type PanelProps = BackgroundProps &
  SpaceProps &
  LayoutProps &
  GridProps &
  ShadowProps &
  BorderProps &
  FlexboxProps & {
    color?: "primary" | "blue" | "green" | "none" | string;
  };

type PanelHeaderProps = SpaceProps & FlexboxProps & LayoutProps;

type PanelFooterProps = SpaceProps & FlexboxProps & LayoutProps;

type PanelInsetProps = SpaceProps & {
  color?: string;
};

const Panel = styled.div<PanelProps>`
  background: ${(props) => props.theme.panel.backgroundColor};
  border-top: ${(props) =>
      (props.color ?? props.theme.panel.defaultColor) !== "none" ? 4 : 0}px
    solid
    ${(props) =>
      props.theme.colors[
        props.color ?? props.theme.panel.defaultColor ?? "primary"
      ] || props.color};
  display: flex;
  flex-direction: column;
  border-radius: ${(props) =>
    props.theme.borderRadius[props.theme.panel.borderRadius]}px;
  ${shadow}
  ${space};
  ${layout};
  ${flexbox};
  ${border};
  ${grid};
  ${background};
`;

Panel.defaultProps = {
  boxShadow: 1,
};

export const PanelCircleContainer = styled.div`
  position: absolute;
  top: -30px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
`;

const getColor = (props: any) => props.theme.colors[props.color] || props.color;

export const PanelInset = styled.div<PanelInsetProps>`
  border-radius: 5px;
  border: 1px solid ${getColor};
  background-color: ${(props) => lighten(0.3, getColor(props))};
  page-break-inside: avoid;
  ${space};
`;

PanelInset.defaultProps = {
  color: "grey3",
};

export const PanelHeader = styled.div<PanelHeaderProps>`
  display: flex;
  height: 52px;
  align-items: center;
  ${space};
  ${flexbox};
  ${layout};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
`;

export const PanelFooter = styled.div<PanelFooterProps>`
  display: flex;
  height: 65px;
  align-items: center;
  ${space};
  ${flexbox};
  ${layout};
  border-top: 1px solid ${(props) => props.theme.colors.grey3};
`;

PanelHeader.defaultProps = {
  p: 3,
};

export default Panel;
