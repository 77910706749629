import { DirectReportDto } from "@coaching-culture/types";
import {
  differenceInCalendarDays,
  fromUnixTime,
  getUnixTime,
  isFuture,
  isPast,
  isToday,
} from "date-fns";
import { maxBy, mean, minBy } from "lodash";
import { isOverdue } from "utils/content";

type MetricStatus = "positive" | "warning" | "danger";

export const measuresLastReviewed = (user: DirectReportDto) =>
  user.measures
    .map((x) => x.lastSet)
    .filter((x) => x != null)
    .sort()
    .reverse()[0] ?? null;

export const goalsSet = (user: DirectReportDto) =>
  user.goals.filter((x) => x.completedOn == null).length;

export const goalsBlocked = (user: DirectReportDto) =>
  user.goals.filter((x) => x.completedOn == null && x.status === "blocked")
    .length;

export const goalsStatus = (user: DirectReportDto): MetricStatus => {
  if (user.goals.length > 0 && user.goals.every((x) => isOverdue(x))) {
    return "danger";
  }

  if (goalsSet(user) > 0) {
    return "positive";
  }

  if (user.goals.length === 0) {
    return "danger";
  }

  const lastClosed = user.goals
    .map((x) => x.completedOn)
    .sort()
    .reverse()[0];

  const daysSinceActiveGoal = differenceInCalendarDays(
    new Date(),
    fromUnixTime(lastClosed),
  );

  if (daysSinceActiveGoal > 14) {
    return "danger";
  }

  return "warning";
};

export const conversationsStatus = (user: DirectReportDto): MetricStatus => {
  const lc = daysSinceLastConvo(user);
  const nc = daysToNextConvo(user);

  if ((lc == null || lc > 14) && (nc == null || nc > 14)) {
    return "danger";
  }

  if (lc < 14 && nc < 14) {
    return "positive";
  }

  return "warning";
};

export const feedbackStatus = (user: DirectReportDto): MetricStatus => {
  const lf = differenceInCalendarDays(
    new Date(),
    fromUnixTime(lastFeedbackFromAnyone(user)),
  );

  if (lf <= 14) {
    return "positive";
  } else if (lf <= 30) {
    return "warning";
  }

  return "danger";
};

export const measuresStatus = (user: DirectReportDto): MetricStatus => {
  const rev = measuresLastReviewed(user);

  if (rev == null) {
    return "danger";
  }

  const daysSince = differenceInCalendarDays(new Date(), fromUnixTime(rev));

  if (daysSince <= 14) {
    return "positive";
  } else if (daysSince <= 30) {
    return "warning";
  } else {
    return "danger";
  }
};

export const overdueGoals = (user: DirectReportDto) =>
  user.goals.filter((x) => x.deadline < getUnixTime(new Date())).length;

export const nextConvo = (user: DirectReportDto) =>
  minBy(
    user.conversations
      .filter((x) => x.completedOn == null && x.cancelledOn == null)
      .filter((x) => {
        const d = fromUnixTime(x.scheduledDate);
        return isFuture(d) || isToday(d);
      }),
    (x) => x.scheduledDate,
  );

export const lastConvo = (user: DirectReportDto) =>
  maxBy(
    user.conversations
      .filter((x) => x.cancelledOn == null)
      .filter((x) => {
        const d = fromUnixTime(x.scheduledDate);
        return isPast(d) || isToday(d);
      }),
    (x) => x.scheduledDate,
  );

export const daysSinceLastConvo = (user: DirectReportDto) => {
  const lc = lastConvo(user);

  return lc == null
    ? null
    : differenceInCalendarDays(new Date(), fromUnixTime(lc.scheduledDate));
};

export const daysToNextConvo = (user: DirectReportDto) => {
  const nc = nextConvo(user);

  return nc == null
    ? null
    : differenceInCalendarDays(fromUnixTime(nc.scheduledDate), new Date());
};

export const lastFeedbackFromMe = (user: DirectReportDto, myId: string) =>
  user.receivedFeedback
    .filter((x) => x.sentBy.id === myId)
    .map((x) => x.sentOn)
    .sort()
    .reverse()[0];

export const daysSinceFeedbackFromMe = (
  user: DirectReportDto,
  myId: string,
) => {
  const fromMe = lastFeedbackFromMe(user, myId);

  return fromMe == null
    ? null
    : differenceInCalendarDays(new Date(), fromUnixTime(fromMe));
};

export const lastFeedbackFromAnyone = (user: DirectReportDto) =>
  user.receivedFeedback
    .map((x) => x.sentOn)
    .sort()
    .reverse()[0];

export const daysSinceFeedbackFromAnyone = (user: DirectReportDto) => {
  const lf = lastFeedbackFromAnyone(user);

  return lf == null
    ? null
    : differenceInCalendarDays(new Date(), fromUnixTime(lf));
};

export const averageMeasureIndex = (user: DirectReportDto) =>
  mean(
    user.measures
      .filter((x) => x.value != null)
      .map(
        (x) =>
          x.options.findIndex((o) => o.id === x.value) /
          Math.max(1, x.options.length - 1),
      ),
  );
