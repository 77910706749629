import { Relationship } from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export async function getAllRelationships(): Promise<Relationship[]> {
  return axios
    .get<Relationship[]>("/api/relationships")
    .then(({ data }) => data);
}

export async function reorderTeam(ids: string[]) {
  return await axios.put("/api/v2/relationships/reorder", ids);
}

export const useMyRelationships = () =>
  useQuery("myrelationships", getAllRelationships);

export const acceptRelationship = (id: string) => {
  return axios.put(`/api/relationships/${id}/accept`);
};

export const deleteRelationship = (id: string) => {
  return axios.delete(`/api/relationships/${id}`);
};

export const useReorderTeam = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(reorderTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries("team");
      queryClient.invalidateQueries("myteam");
      queryClient.invalidateQueries("myrelationships");
    },
  });

  return mutation;
};

export const useAcceptRelationship = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(acceptRelationship, {
    onSuccess: () => {
      queryClient.invalidateQueries("myteam");
      queryClient.invalidateQueries("myrelationships");
    },
  });

  return mutation;
};

export const useDeleteRelationship = () => {
  const queryClient = useQueryClient();

  // When this mutation succeeds, invalidate any queries with the `todos` or `reminders` query key
  const mutation = useMutation(deleteRelationship, {
    onSuccess: () => {
      queryClient.invalidateQueries("team");
      queryClient.invalidateQueries("myteam");
      queryClient.invalidateQueries("myrelationships");
    },
  });

  return mutation;
};
