import { getContent } from "@coaching-culture/api-client";
import {
  ContentItem,
  ContentType,
  UserDisplay,
  UserGroup,
} from "@coaching-culture/types";
import {
  Button,
  Circle,
  Flex,
  Loader,
  Modal,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import { useShareContent } from "queries/mindset";
import { useEffect, useState } from "react";
import { FaShareAlt, FaUsers } from "react-icons/fa";
import { useUser } from "../../auth";
import UserProfileImage from "../../components/UserProfileImage";
import MenuItem from "./MenuItem";
import ModuleDirectoryDrawer from "./ModuleDirectory";
import UserDirectoryDrawer from "./TeamDirectory";

export type ShareConfig = {
  type: ContentType;
  targetType: "user" | "group" | null;
  targetId: string | null;
  itemId: string | null;
};

export type ShareModalProps = {
  onClose: () => void;
  config: ShareConfig;
};

export type ShareTarget =
  | ({ type: "user" } & UserDisplay)
  | ({ type: "group" } & UserGroup);

const ShareModal = ({ onClose, config }: ShareModalProps) => {
  const [me] = useUser();
  const [loading, setLoading] = useState(true);
  const shareContent = useShareContent();

  const [targets, setTargets] = useState<ShareTarget[] | null>(null);
  const [items, setItems] = useState<ContentItem[] | null>(null);
  const [drawerShown, setDrawerShown] = useState(null);

  const { type, targetId, itemId, targetType } = config;

  useEffect(() => {
    const ps = [];
    setLoading(true);
    if (targetId != null) {
      const url =
        targetType === "user"
          ? `/api/users/${targetId}`
          : `/api/groups/${targetId}`;
      const p = axios.get(url).then(({ data }) => {
        setTargets([
          {
            ...data,
            type: targetType,
          },
        ]);
      });
      ps.push(p);
    }

    if (itemId != null) {
      const p = getContent().then((x) => {
        const i = x.find((x) => x.id === itemId);

        if (i != null) {
          setItems([i]);
        }
      });

      ps.push(p);
    }

    Promise.all(ps).then(() => setLoading(false));
  }, [type, targetId, itemId, targetType]);

  const ready = targets != null && items != null;
  const targetDisabled = config.targetId != null;
  const itemDisabled = config.itemId != null;

  const doShare = async () => {
    let spec = {
      content: items.map((x) => ({
        type: x.type,
        id: x.id,
      })),
      targets: targets.map((x) => ({
        type: x.type,
        id: x.id,
      })),
    };

    try {
      await shareContent.mutateAsync(spec);
      onClose();
    } catch (err) {
      window.alert(err);
      setLoading(false);
    }
  };

  const onTargetSelect = (targets: ShareTarget[]) => {
    if (targets != null && targets.length > 0) {
      setTargets(targets);
    }
    setDrawerShown(null);
  };

  const onItemSelect = (items: ContentItem[]) => {
    if (items != null) {
      setItems(items);
    }
    setDrawerShown(null);
  };

  const title = {
    module: "Assign Content",
    lesson: "Assign Content",
    assessment: "Assign Content",
  }[config.type];

  const shareButtonText = {
    module: "Assign Module",
    lesson: "Assign Lesson",
    assessment: "Assign Assessment",
    learncast: "Assign Learn Cast",
  }[config.type];

  let subtitleText = "Select the user or group to share with";

  if (targets != null && targets.length === 1) {
    subtitleText = targets[0].type === "user" ? targets[0].email : "Group";
  } else if (targets != null && targets.length > 1) {
    const userCount = targets.filter((x) => x.type === "user").length;
    const groupCount = targets.filter((x) => x.type === "group").length;

    subtitleText = [
      userCount && `${userCount} user${userCount === 1 ? "" : "s"}`,
      groupCount && `${groupCount} group${groupCount === 1 ? "" : "s"}`,
    ]
      .filter(Boolean)
      .join(" and ");
  }

  return (
    <Modal
      color="primary"
      width={600}
      height={500}
      p={[2, 5]}
      pt={5}
      pb={5}
      onClose={() => onClose()}
      showCloseButton={!loading}
      style={{ overflow: "hidden" }}
    >
      {loading && <Loader overlay color="primary" />}
      {drawerShown === "user" && (
        <UserDirectoryDrawer onSelect={onTargetSelect} me={me} />
      )}
      {drawerShown === "item" && (
        <ModuleDirectoryDrawer
          userId={
            targetType === "user" && targets.length > 0 ? targets[0].id : null
          }
          onSelect={onItemSelect}
        />
      )}
      <Flex alignItems="center" flexDirection="column" mb={5}>
        <Circle color="primary" icon={FaShareAlt} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          {title}
        </Text>
      </Flex>
      <Flex mb={5} flexDirection="column">
        <MenuItem
          title={
            items == null
              ? "Select Items To Assign"
              : items.length === 1
                ? items[0].name
                : `Multiple modules`
          }
          image={
            items != null ? (
              <Circle
                mr={2}
                image={process.env.REACT_APP_STATIC_ROOT + "/" + items[0].icon}
              />
            ) : (
              <Circle color="grey3" mr={2} />
            )
          }
          subtitle={items != null ? `${items.length} items` : "Select Item"}
          disabled={itemDisabled}
          onClick={() => setDrawerShown("item")}
        />
        <MenuItem
          image={
            targets != null ? (
              targets[0].type === "user" && targets.length === 1 ? (
                <UserProfileImage
                  profileImage={targets[0].profileImage}
                  name={targets[0].name}
                  mr={2}
                />
              ) : (
                <Circle icon={FaUsers} color="primary" mr={2} />
              )
            ) : (
              <Circle color="grey3" mr={2} />
            )
          }
          disabled={targetDisabled}
          title={
            targets
              ? targets.length === 1
                ? targets[0].name
                : "Multiple items"
              : "Who would you like to assign to?"
          }
          subtitle={subtitleText}
          onClick={() => setDrawerShown("user")}
        />
      </Flex>
      <Flex justifyContent="center">
        <Button onClick={doShare} disabled={!ready} color="primary">
          {shareButtonText}
        </Button>
      </Flex>
    </Modal>
  );
};

export default ShareModal;
