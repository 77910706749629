import { ContentType } from "@coaching-culture/types";
import {
  Box,
  Circle,
  Flex,
  Img,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import {
  ContentDonutGraph,
  ContentDonutGraphProps,
} from "components/ContentDonutGraph";
import WidgetHeader from "components/WidgetHeader";
import { sortBy } from "lodash";
import { useUserContent } from "queries/mindset";
import { FaBroadcastTower, FaEye, FaThumbsUp } from "react-icons/fa";
import { useHistory } from "react-router";
import styled from "styled-components";
import { clientUrlForContent } from "utils/content";
import assessmentIcon from "../../../img/assessments.svg";
import lessonsIcon from "../../../img/LessonsBlue.svg";
import mindsetIcon from "../../../img/MindsetBlue.svg";
import { NoDataWidget } from "./NoDataWidget";

const ModuleContainer = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 30px;

  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);

  overflow-y: auto;
  max-height: 150px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};

    border-radius: 10px;
    margin-bottom: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
    margin-bottom: 30px;
  }
`;

const ContentImage = styled(Img)`
  width: 100%;
  max-width: unset;
`;

const ContentImageContainer = styled(Circle)<{ next: boolean }>`
  cursor: pointer;
  border: 3px solid ${(props) => props.theme.colors.grey3};
  transition: all 0.3s ease;

  border-color: ${(props) =>
    props.next
      ? (props) => props.theme.colors.primary
      : (props) => props.theme.colors.grey3};

  :hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export type LessonModuleWidgetProps = {
  userId?: string;
  contentType: ContentType;
};

const AllComplete = () => (
  <Flex flexDirection={"column"} alignItems="center">
    <Circle icon={FaThumbsUp} mb={2} size="xlarge" color="grey3" />
    <Text color="grey3" fontSize={5} fontWeight={600}>
      All Complete
    </Text>
  </Flex>
);

export function LessonModuleWidget({
  userId,
  contentType,
}: LessonModuleWidgetProps) {
  const [user] = useUser();
  const { data: content, isFetched } = useUserContent(userId ?? user.id);
  const history = useHistory();

  if (!isFetched) return <Loader />;

  let modules =
    contentType === "lesson"
      ? content.filter((x) => x.type === "lesson")
      : contentType === "learncast"
        ? content.filter((x) => x.type === "learncast")
        : contentType === "assessment"
          ? content.filter((x) => x.type === "assessment")
          : content.filter((x) => x.type === "module");

  if (contentType === "lesson") {
    modules = sortBy(modules, "orderIndex");
  }
  const started = modules.filter((x) =>
    x.attempts.some((x) => x.completedOn == null),
  );
  const toDo = modules.filter((x) => x.attempts.length === 0);
  const completed = modules.filter((x) =>
    x.attempts.some((x) => x.completedOn != null),
  );

  const noModules = modules.length === 0;

  const vals: ContentDonutGraphProps = {
    values: [
      {
        label: "To Do",
        value: toDo.length,
        colour: "black",
      },
      {
        label: "Completed",
        value: completed.length,
        colour: "positive",
      },

      {
        label: "Started",
        value: started.length,
        colour: "primary",
      },
    ],
  };

  return (
    <Panel width={"100%"}>
      <WidgetHeader
        title={
          contentType === "lesson"
            ? "Lessons"
            : contentType === "learncast"
              ? "Learn Cast"
              : contentType === "assessment"
                ? "Assessments"
                : "Mindset"
        }
        iconImage={
          contentType === "lesson"
            ? lessonsIcon
            : contentType === "module"
              ? mindsetIcon
              : contentType === "assessment"
                ? assessmentIcon
                : contentType === "learncast"
                  ? FaBroadcastTower
                  : null
        }
        iconImageHeight={contentType === "lesson" ? "30px" : null}
        button={{
          text: "View Library",
          icon: FaEye,
          to:
            contentType === "lesson"
              ? "/solutions/lessons"
              : contentType === "learncast"
                ? "/solutions/learncast"
                : contentType === "assessment"
                  ? "/solutions/assessments"
                  : "/solutions/mindset",
        }}
      />
      {noModules ? (
        <NoDataWidget text="No Content Assigned" />
      ) : (
        <Box p={4} py={6} height="100%" pl={0}>
          <Flex mt={2} alignItems={"center"} height={150} flexDirection={"row"}>
            <Flex width={"30%"} mr={2}>
              <ContentDonutGraph values={vals.values} />
            </Flex>
            <Flex
              width={"70%"}
              justifyContent={"space-evenly"}
              flexDirection={"row"}
            >
              {started.length === 0 && toDo.length === 0 && <AllComplete />}
              {started.length !== 0 && (
                <Flex minWidth={"50%"} mr={3} flexDirection={"column"}>
                  <Text
                    color={"primary"}
                    fontWeight={600}
                    fontSize={"20px"}
                    mb={2}
                  >
                    In Progress
                  </Text>
                  <Flex flexDirection={"row"}>
                    <ModuleContainer maxWidth={"100%"}>
                      {started.map((x) => (
                        <Flex
                          mr={2}
                          alignItems="center"
                          flexDirection={"column"}
                          justifyContent={"space-between"}
                        >
                          <ContentImageContainer next={false} mb={2} size={43}>
                            <ContentImage
                              title={x.name}
                              tabIndex={0}
                              src={
                                process.env.REACT_APP_STATIC_ROOT + "/" + x.icon
                              }
                              onKeyDown={(key) => {
                                if (key.code === "Enter") {
                                  history.push(clientUrlForContent(x));
                                }
                              }}
                              onClick={() =>
                                history.push(clientUrlForContent(x))
                              }
                              alt={x.name}
                            />
                          </ContentImageContainer>
                        </Flex>
                      ))}
                    </ModuleContainer>
                  </Flex>
                </Flex>
              )}
              {toDo.length > 0 && (
                <Flex
                  minWidth={
                    started.length === 0 && contentType !== "lesson"
                      ? "100%"
                      : "50%"
                  }
                  flexDirection={"column"}
                >
                  <Text fontWeight={600} fontSize={"20px"} mb={2}>
                    To do
                  </Text>
                  <ModuleContainer maxWidth="100%">
                    {toDo.map((x, i) => (
                      <Flex
                        justifyContent={"space-between"}
                        mr={2}
                        alignItems="center"
                        flexDirection={"column"}
                      >
                        <ContentImageContainer
                          next={i === 0 && contentType === "lesson"}
                          mb={i !== 0 ? 2 : 0}
                          size={43}
                        >
                          <ContentImage
                            tabIndex={0}
                            onKeyDown={(key) => {
                              if (key.code === "Enter") {
                                history.push(clientUrlForContent(x));
                              }
                            }}
                            title={x.name}
                            src={
                              process.env.REACT_APP_STATIC_ROOT + "/" + x.icon
                            }
                            onClick={() => history.push(clientUrlForContent(x))}
                            alt={x.name}
                          />
                        </ContentImageContainer>
                        {i === 0 && contentType === "lesson" && (
                          <Text fontWeight={600} fontSize={1} color={"primary"}>
                            NEXT
                          </Text>
                        )}
                      </Flex>
                    ))}
                  </ModuleContainer>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>
      )}
    </Panel>
  );
}
