import {
  CoachingTopicDto,
  DiscussionPointDto,
  GoalDto,
  ManagerCampaign,
  ProductVisibility,
  RecurrenceType,
  UserCampaignSummary,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Circle,
  ConfirmationModal,
  ControlLabel,
  Flex,
  IconButton,
  IconToast,
  Img,
  Input,
  Label,
  Loader,
  Panel,
  PanelHeader,
  ReactModal,
  RichTextEditor,
  Rule,
  SortableTable,
  SortableTableColumn,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import axios from "axios";
import { Comment } from "components/Comment";
import { ScrollableContainer } from "components/ScrollableContainer";
import { TabPane, Tabs } from "components/Tabs";
import { Toggle } from "components/Toggle";
import UserProfileImage from "components/UserProfileImage";
import {
  addBusinessDays,
  format,
  fromUnixTime,
  getDate,
  getDay,
  startOfDay,
} from "date-fns";
import { useProductVisibility } from "hooks/useProductVisibility";
import { sortBy } from "lodash";
import NotFound from "pages/NotFound";
import {
  useChangeAgenda,
  useCompleteConversation,
  useConversationInterval,
  useCreateConversationComment,
  useUpdateConversationDetails,
} from "queries/conversations";
import { useGoalFields } from "queries/performance";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  FaBolt,
  FaBullseye,
  FaCheck,
  FaChevronLeft,
  FaCommentDots,
  FaEnvelopeOpen,
  FaEye,
  FaGripVertical,
  FaMedal,
  FaPaperPlane,
  FaPen,
  FaPlusCircle,
  FaSave,
  FaStickyNote,
  FaTimes,
  FaUser,
} from "react-icons/fa";
import { Prompt, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { arrMove, ordinal } from "utils";
import { formatDate, getDayName } from "utils/dates";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import getUuid from "uuid-by-string";
import imgSrc from "../../../img/feedback.svg";
import { ContinuousFeedbackTable } from "../ContinuousFeedback/MyContinuousFeedback";
import { ShareFormData } from "../Feedback/ShareForm";
import { UserCampaignDisplay } from "../Feedback/UserCampaignDisplay";
import { ActionPane } from "./ActionPane";
import { CoachingTopicsModal } from "./CoachingTopicsModal";
import { ContentPane } from "./ContentPane";
import { ConversationHistory } from "./ConversationHistory";
import { CreateGoalModal } from "./CreateGoalModal";
import { GoalList } from "./GoalDisplay";
import { GoalReview } from "./GoalReview";

const CommentContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

const DpContainer = styled.div`
  max-height: 220px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

const GoalDetailModal = ({
  goal,
  onClose,
  isOpen,
}: {
  goal: GoalDto;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const terms = useGoalTerminology();
  const { data: fields } = useGoalFields();

  const fieldName = (fieldId: string) =>
    (fields ?? []).find((x) => x.id === fieldId)?.name ?? "";

  return (
    <ReactModal isOpen={isOpen} width={600} onClose={onClose}>
      <>
        <PanelHeader>
          <Text fontSize={4} fontWeight={500}>
            {terms.goal.asTitle()} Details
          </Text>
        </PanelHeader>
        <Box p={3}>
          <Box mb={2}>
            <ControlLabel>What is the {terms.goal.asBody()}?</ControlLabel>
            <Text>{goal?.purpose}</Text>
          </Box>
          <Box mb={2}>
            <ControlLabel>When would you like to achieve this by?</ControlLabel>
            <Text>{formatDate(goal?.deadline, "dd/MM/yyyy")}</Text>
          </Box>
          {goal.fields.map((x) => (
            <Box mb={2}>
              <ControlLabel>{fieldName(x.id)}</ControlLabel>
              <Text>{x.value || "<Not Given>"}</Text>
            </Box>
          ))}
          {goal.associations.length > 0 && (
            <Box mb={3}>
              <Text fontSize={4} fontWeight={500} mb={2}>
                Collective {terms.goal.asTitle()} Associations
              </Text>
              {goal.associations.map((x) => (
                <Flex
                  alignItems="center"
                  border={1}
                  borderRadius={3}
                  p={2}
                  mb={1}
                >
                  <Circle icon={FaMedal} color="primary" mr={2} />
                  <Text fontWeight={500} style={{ flex: 1 }}>
                    {x.name}
                  </Text>
                </Flex>
              ))}
            </Box>
          )}
          {goal?.objectives.length > 0 && (
            <Box mb={3}>
              <Text fontSize={4} fontWeight={500} mb={2}>
                {terms.objective.asPluralTitle()}
              </Text>
              {goal.objectives.map((obj) => (
                <Flex
                  p={2}
                  justifyContent="space-between"
                  border={1}
                  borderRadius={1}
                  mb={2}
                >
                  <Flex alignItems="center">
                    <Circle icon={FaBullseye} color="orange" mr={2} />
                    <div>
                      <Text fontWeight={500}>{obj.purpose}</Text>
                      <Text fontSize={2} color="grey2">
                        Due by: {formatDate(obj.deadline, "dd/MM/yyyy")}
                      </Text>
                    </div>
                  </Flex>
                </Flex>
              ))}
            </Box>
          )}
          <Button onClick={onClose}>Close</Button>
        </Box>
      </>
    </ReactModal>
  );
};

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-top: 12px;

  & svg {
    margin-right: 6px;
  }
`;

const FeedbackContainer = styled.div`
  padding: 12px;
`;

function FeedbackCampaignModal({
  id,
  onCancel,
  isOpen,
}: {
  id: string;
  onCancel: () => void;
  isOpen: boolean;
}) {
  if (id.length < 10) {
    const s = "feedback_campaign_user-" + id;
    id = getUuid(s);
  }

  const [item, setItem] = useState<ManagerCampaign | null>(null);
  const history = useHistory();

  const loading = item == null;

  useEffect(() => {
    setItem(null);
    axios
      .get<ManagerCampaign>(`/api/feedback/user-campaigns/${id}`)
      .then(({ data }) => {
        setItem(data);
      })
      .catch((err) => {
        alert(err);
      });
  }, [id, history]);

  const nudge = useCallback(
    (forwardId: string) => {
      const forward = item.forwards.find((x) => x.id === forwardId);
      forward.lastNudged = new Date();

      setItem({
        ...item,
      });

      axios
        .put(`/api/feedback/user-campaigns/${id}/forwards/${forwardId}/nudge`)
        .then(() => {});
    },
    [id, item],
  );

  const share = (data: ShareFormData) => {
    const spec = {
      emails: data.recipients.split("\n").filter((x) => x !== ""),
      subject: data.subject,
      text: data.text,
      deadline: data.deadline,
    };
    axios
      .post(`/api/feedback/user-campaigns/${id}/forwards`, spec)
      .then(({ data }) => {
        setItem(data);
      })
      .catch((err) => {
        window.alert("Error");
      });
  };

  return (
    <ReactModal width={900} isOpen={isOpen} onClose={onCancel}>
      {loading ? (
        <Loader />
      ) : (
        <Box p={3}>
          <Flex alignItems="center" flexDirection="column" mb={3}>
            <UserProfileImage
              profileImage={item.user.profileImage}
              name={item.user.name}
              size="xlarge"
              mb={2}
            />
            <Text textAlign="center" fontSize={[3, 4]} fontWeight={600}>
              {item.user.name}
            </Text>
            <Text color="grey2" mb={2}>
              {item.user.email}
            </Text>
            <Text mb={1} color="grey2" fontWeight={500}>
              Report Visible To:
            </Text>
            <Flex flexWrap="wrap" width="600" style={{ gap: 3 }}>
              {item.resultsVisibility.includes("admin") && (
                <Label color="primary">Admins</Label>
              )}
              {item.resultsVisibility.includes("manager") && (
                <Label color="primary">User's Manager</Label>
              )}
              {item.resultsVisibility.includes("self") && (
                <Label color="primary">The User</Label>
              )}
              {item.viewers.map((x) => (
                <Label icon={FaUser}>{x.name}</Label>
              ))}
            </Flex>
          </Flex>
          <Text textAlign="center" fontSize={[5, 6]} mb={5}>
            {item.name}
          </Text>
          <ScrollableContainer maxHeight={700}>
            <FeedbackContainer>
              <UserCampaignDisplay
                item={item}
                onNudge={nudge}
                onShare={share}
              />
            </FeedbackContainer>
          </ScrollableContainer>
        </Box>
      )}
    </ReactModal>
  );
}

export function ConversationPlayer() {
  const { id, index } = useParams<{ id: string; index: string }>();
  const [items, setItems] = useState<UserCampaignSummary[] | null>(null);
  const { data: convo, isFetched } = useConversationInterval(
    id,
    parseInt(index, 10),
  );
  const completeConversation = useCompleteConversation(id, parseInt(index, 10));
  const createComment = useCreateConversationComment();
  const changeAgenda = useChangeAgenda(id, parseInt(index, 10));
  const updateDetailsMutation = useUpdateConversationDetails(
    id,
    parseInt(index, 10),
  );

  const [user] = useUser();
  const pushToast = useToast();
  const hasLessons =
    useProductVisibility("lessons") === ProductVisibility.Enabled;
  const hasContFeedback =
    useProductVisibility("cont-feedback") === ProductVisibility.Enabled;
  const hasMindset =
    useProductVisibility("mindset") === ProductVisibility.Enabled;
  const hasFeedback = useProductVisibility("360") === ProductVisibility.Enabled;

  const [showingGoal, setShowingGoal] = useState<GoalDto | null>(null);
  const [displayPrivate, setDisplayPrivate] = useState(false);
  const [commentText, setCommentText] = useState<string>("");
  const terms = useGoalTerminology();
  const [selectedGoal, setSelectedGoal] = useState<string>(null);
  const [creatingGoal, setCreatingGoal] = useState(false);
  const [editingPurpose, setEditingPurpose] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [editingOutcome, setEditingOutcome] = useState(false);
  const [showFeedbackCampaign, setShowFeedbackCampaign] = useState(null);
  const [outcome, setOutcome] = useState("");
  const [discussionPoints, setDiscussionPoints] = useState<
    Partial<DiscussionPointDto[]>
  >([]);
  const [addingDiscussionPoint, setAddingDiscussionPoint] = useState(false);
  const [completingConversation, setCompletingConversation] = useState(false);
  const [discussionPoint, setDiscussionPoint] = useState("");

  const [addingCoachingTopics, setAddingCoachingTopics] = useState(false);
  const history = useHistory();

  const purposeElement = useRef(null);

  const cols = useMemo(
    (): SortableTableColumn<UserCampaignSummary>[] => [
      {
        name: "name",
        label: "Name",
        headingProps: {
          style: {
            width: "50%",
          },
        },
        format: (x) => <Text fontWeight={600}>{x.name}</Text>,
      },
      {
        name: "username",
        label: "User",
        format: (x) => x.user.name,
        sortFormat: (x) => x.user.name,
        headingProps: {
          style: {
            width: "25%",
          },
        },
      },
      {
        name: "createdOn",
        label: "Created",
        format: (x) => format(new Date(x.createdOn), "dd-MM-yyyy"),
      },
      {
        name: "earliestDeadline",
        label: "Deadline",
        format: (x) =>
          x.earliestDeadline == null
            ? "None"
            : format(fromUnixTime(x.earliestDeadline), "dd-MM-yyyy"),
      },
      {
        name: "actions",
        label: "",
        headingProps: {
          style: {
            width: "20px",
          },
        },
        format: (x) => (
          <IconButton
            color="body"
            icon={FaEye}
            onClick={() => setShowFeedbackCampaign(x.campaignUserId)}
          />
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (isFetched) {
      setDiscussionPoints(convo.agenda);
      setOutcome(convo.outcome);
      setPurpose(convo.purpose);
      axios
        .get(`/api/v2/feedback/user-campaigns?userId=${convo.directReport.id}`)
        .then(({ data }) => {
          setItems(sortBy(data, "createdOn"));
        });
    }
  }, [isFetched, convo]);

  if (!isFetched) {
    return <Loader />;
  }

  if (convo == null) {
    return <NotFound />;
  }

  const isManager = user.id === convo.manager.id;
  const isOwner = user.id === convo.createdBy;

  const isComplete = convo.completedOn != null;
  const isCancelled = convo.cancelledOn != null;

  const canComplete =
    !isComplete &&
    !isCancelled &&
    (isManager || isOwner) &&
    (startOfDay(fromUnixTime(convo.scheduledDate)) <
      addBusinessDays(new Date(), 3) ||
      new Date() > fromUnixTime(convo.scheduledDate));

  const saveAgenda = async (
    discussionPoints: Partial<DiscussionPointDto>[],
  ) => {
    try {
      await changeAgenda.mutateAsync(discussionPoints);
      pushToast({
        content: (
          <IconToast icon={FaSave} text="Agenda saved" iconColor="primary" />
        ),
      });
    } catch (err) {
      window.alert(err);
    }
  };

  const saveTopics = (topics: Partial<CoachingTopicDto[]>) => {
    const temp = topics
      .map(
        (x) =>
          ({ name: x.body, addedBy: user.id }) as Partial<DiscussionPointDto>,
      )
      .concat(discussionPoints);

    saveAgenda(temp);
    setAddingCoachingTopics(false);
  };

  const deleteDiscussionPoint = (id: string) => {
    const temp = [...discussionPoints.filter((x) => x.id !== id)];
    saveAgenda(temp);
  };

  const saveDiscussionPoint = () => {
    if (discussionPoint.trim().length > 0) {
      const temp = [
        ...discussionPoints,
        {
          name: discussionPoint,
          addedBy: user.id,
        } as Partial<DiscussionPointDto>,
      ];
      saveAgenda(temp);
      setAddingDiscussionPoint(false);
      setDiscussionPoint("");
    }
  };

  const handleInputChangePurpose = (e) => {
    setPurpose(e.target.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateDetails();
    }
    if (event.key === "Escape") {
      setEditingPurpose(false);
    }
  };

  const handleInputChangeOutcome = (e) => {
    setOutcome(e.target.value);
  };

  const updateDetails = () => {
    purposeElement.current.focus();
    updateDetailsMutation.mutateAsync({ outcome, purpose });
    setEditingPurpose(false);
    setEditingOutcome(false);
    pushToast({
      content: <IconToast icon={FaSave} text="Saved" iconColor="primary" />,
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    saveAgenda(
      arrMove(discussionPoints, result.source.index, result.destination.index),
    );
  };

  const createCommentClick = () => {
    if (commentText.length > 5000) {
      return window.alert(
        "Conversation comments are capped at 5000 characters",
      );
    }

    const comment = {
      body: commentText,
      isPrivate: displayPrivate,
      convoId: convo.id,
      index: convo.index,
    };

    createComment.mutateAsync(comment).then(() => {
      setCommentText("");
      pushToast({
        content: (
          <IconToast
            icon={FaPlusCircle}
            text="Comment added"
            iconColor="primary"
          />
        ),
      });
    });
  };

  const getDayOcc = (date: Date) => {
    const num = Math.ceil(getDate(date) / 7);

    return num === 5 ? "last" : ordinal(Math.ceil(getDate(date) / 7));
  };

  const formatRecurrence = (
    recurrence: RecurrenceType,
    scheduledDate: number,
  ): string => {
    if (recurrence === "weekly") {
      return `Repeat every ${getDayName(getDay(fromUnixTime(scheduledDate)))}`;
    } else if (recurrence.includes("weekly")) {
      const number = recurrence.charAt(0);
      return `Repeat every ${getDayName(
        getDay(fromUnixTime(scheduledDate)),
      )} every ${number} weeks`;
    } else if (recurrence === "monthly") {
      return `Repeat every ${getDayOcc(
        fromUnixTime(scheduledDate),
      )} ${getDayName(getDay(fromUnixTime(scheduledDate)))} every month`;
    } else if (recurrence.includes("monthly")) {
      const number = recurrence.charAt(0);
      return `Repeat every ${getDayOcc(
        fromUnixTime(scheduledDate),
      )} ${getDayName(
        getDay(fromUnixTime(scheduledDate)),
      )} every ${number} months`;
    } else {
      return `Repeat every year`;
    }
  };

  const directReportCampaigns =
    items?.filter((x) => x.user.id === convo?.directReport.id) ?? [];

  return (
    <>
      {showingGoal != null && (
        <GoalDetailModal
          isOpen={showingGoal != null}
          goal={showingGoal}
          onClose={() => setShowingGoal(null)}
        />
      )}

      <CreateGoalModal
        isOpen={creatingGoal}
        onCancel={() => setCreatingGoal(false)}
        directReportId={convo.directReport.id}
      ></CreateGoalModal>

      <CoachingTopicsModal
        onSave={(val) => saveTopics(val)}
        onCancel={() => setAddingCoachingTopics(false)}
        isOpen={addingCoachingTopics}
      />

      {showFeedbackCampaign != null && (
        <FeedbackCampaignModal
          isOpen={showFeedbackCampaign}
          id={showFeedbackCampaign}
          onCancel={() => setShowFeedbackCampaign(null)}
        />
      )}

      <ConfirmationModal
        modalText="Are you sure you want to complete this conversation?"
        onCancel={() => {
          setCompletingConversation(false);
        }}
        isOpen={completingConversation && commentText.length === 0}
        title={"Complete Conversartion"}
        buttons={[
          {
            text: "Complete",
            color: "positive",
            onClick: () => {
              completeConversation.mutateAsync().then(() => {
                setCompletingConversation(false);
                pushToast({
                  content: (
                    <IconToast
                      icon={FaCheck}
                      text="Conversation completed"
                      iconColor="positive"
                    />
                  ),
                });
              });
            },
          },
        ]}
      />

      <ConfirmationModal
        isOpen={completingConversation && commentText.length !== 0}
        cancelText="Cancel"
        title={"Complete Conversartion"}
        modalText="There are unsaved comments, how do you want to complete the conversation?"
        onCancel={() => {
          setCompletingConversation(false);
        }}
        buttons={[
          {
            text: "Save & Complete",
            color: "positive",
            onClick: () => {
              createComment
                .mutateAsync({
                  body: commentText,
                  isPrivate: displayPrivate,
                  convoId: convo.id,
                  index: convo.index,
                })
                .then(() => {
                  completeConversation.mutateAsync().then(() => {
                    setCompletingConversation(false);
                    setCommentText("");
                    pushToast({
                      content: (
                        <IconToast
                          icon={FaCheck}
                          text="Conversation completed"
                          iconColor="positive"
                        />
                      ),
                    });
                  });
                });
            },
          },
          {
            text: "Discard & Complete",
            color: "warning",
            onClick: () => {
              completeConversation.mutateAsync().then(() => {
                setCommentText("");
                setCompletingConversation(false);
                pushToast({
                  content: (
                    <IconToast
                      icon={FaCheck}
                      text="Conversation completed"
                      iconColor="positive"
                    />
                  ),
                });
              });
            },
          },
        ]}
      />

      {canComplete && (
        <Prompt message="Are you sure you want to leave the conversation without completing?" />
      )}
      <Flex mb={2} alignItems={["flex-start", "normal"]} flexDirection={"row"}>
        <Flex flexGrow={6}>
          <Flex flexDirection={"column"}>
            <Flex>
              <Text
                fontSize={[5, 6]}
                fontWeight={600}
                lineHeight={1.2}
                mr={1}
                color="black"
                as="h1"
              >
                Conversation with{" "}
                {isManager ? convo.directReport.name : convo.manager.name}
              </Text>
            </Flex>
            <BackLink
              ref={purposeElement}
              to={null}
              onClick={() => history.goBack()}
            >
              <FaChevronLeft />
              Back
            </BackLink>
          </Flex>
        </Flex>

        <Flex
          alignItems="end"
          justifyContent="center"
          flexDirection="column"
          flexGrow={1}
        >
          {(isOwner || isManager) && !isComplete ? (
            <>
              <Button
                color="primary"
                onClick={() => setCompletingConversation(true)}
                disabled={completeConversation.isLoading || !canComplete}
                mb={2}
              >
                Complete Conversation
              </Button>
              {!canComplete && (
                <Text fontWeight={600} color="grey1">
                  Scheduled for{" "}
                  {format(
                    fromUnixTime(convo.scheduledDate),
                    "do MMMM yyyy @ p",
                  )}
                </Text>
              )}
            </>
          ) : (
            convo.completedOn !== null && (
              <Text>
                Completed on{" "}
                {format(fromUnixTime(convo.completedOn), "do MMMM yyyy @ p")}
              </Text>
            )
          )}
        </Flex>
      </Flex>
      <Rule />
      <Tabs>
        <TabPane defaultValue="Conversation">
          <Flex
            flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
            flexDirection={"row"}
            style={{ gap: 18 }}
          >
            <Flex flexBasis={["100%", "100%", "100%", "60%"]}>
              <Panel width="100%">
                <PanelHeader>
                  <Circle
                    color="primary"
                    icon={FaStickyNote}
                    mr={2}
                    size="small"
                  />
                  <Text fontWeight={500} fontSize={4}>
                    Notes
                  </Text>
                </PanelHeader>
                <Box p={2}>
                  {user.id === convo.manager.id && (
                    <Flex
                      justifyContent={"space-between"}
                      mb={3}
                      alignItems={"center"}
                    >
                      <Toggle
                        value={displayPrivate}
                        onChange={setDisplayPrivate}
                        rightLabel={"Private"}
                        leftLabel={"Public"}
                        mb={0}
                        ariaLabel={"Display private notes"}
                      />
                      {isManager && (
                        <Text
                          fontSize={2}
                          fontWeight={600}
                          color="warning"
                          textAlign={"right"}
                        >
                          {displayPrivate
                            ? "This comment will only be visible to you"
                            : "This comment will be visible to all participants"}
                        </Text>
                      )}
                    </Flex>
                  )}

                  <RichTextEditor
                    value={commentText}
                    onChange={setCommentText}
                    height={300}
                  />

                  <Flex mb={2} justifyContent={"end"}>
                    <Button
                      onClick={createCommentClick}
                      mt={2}
                      color="primary"
                      icon={FaPaperPlane}
                    >
                      {!isManager
                        ? "Add Comment"
                        : displayPrivate
                          ? "Add Private Comment"
                          : "Add Public Comment"}
                    </Button>
                  </Flex>

                  <CommentContainer>
                    {convo.comments.length > 0 ? (
                      convo.comments
                        .filter((x) => x.isPrivate === displayPrivate)
                        .map((item) => {
                          return <Comment comment={item} key={item.id} />;
                        })
                    ) : (
                      <Flex justifyContent={"center"} p={3}>
                        <Text color="grey2" fontWeight={600}>
                          No comments to show
                        </Text>
                      </Flex>
                    )}
                  </CommentContainer>
                </Box>
              </Panel>
            </Flex>
            <Flex
              flexBasis={["100%", "40%"]}
              flexDirection="column"
              flexGrow={1}
            >
              <Flex mb={3}>
                <Panel width="100%">
                  <PanelHeader>
                    <Circle color="primary" icon={FaBolt} mr={2} size="small" />
                    <Text fontWeight={500} fontSize={4}>
                      Conversation Details
                    </Text>
                  </PanelHeader>
                  <Box p={2}>
                    <Text fontWeight={600}>Purpose:</Text>
                    <Flex mb={2}>
                      {!editingPurpose ? (
                        <>
                          <Text style={{ flex: 1 }}>{convo.purpose}</Text>
                          {(isOwner || isManager) && !isComplete && (
                            <IconButton
                              icon={FaPen}
                              onClick={() => {
                                setEditingPurpose(true);
                              }}
                              color="grey"
                              title={`Edit purpose`}
                              ml={1}
                              aria-label="Edit purpose"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Input
                            onChange={handleInputChangePurpose}
                            onKeyUp={handleKeyUp}
                            defaultValue={convo.purpose}
                            mr={2}
                          />
                          <IconButton
                            icon={FaCheck}
                            onClick={() => updateDetails()}
                            color="green"
                            title="Save purpose"
                            aria-label="Save purpose"
                          />
                        </>
                      )}
                    </Flex>
                    <Text fontWeight={600}>Desired Outcome:</Text>
                    <Flex mb={2}>
                      {!editingOutcome ? (
                        <>
                          <Text style={{ flex: 1 }}>{convo.outcome}</Text>
                          {isOwner && !isComplete && (
                            <IconButton
                              icon={FaPen}
                              onClick={() => {
                                setEditingOutcome(true);
                              }}
                              color="grey"
                              title={`Edit outcome`}
                              ml={1}
                              aria-label="Edit outcome"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Input
                            onChange={handleInputChangeOutcome}
                            onKeyUp={handleKeyUp}
                            defaultValue={convo.outcome}
                            mr={2}
                          />
                          <IconButton
                            icon={FaCheck}
                            onClick={() => updateDetails()}
                            color="green"
                            title={"Save outcome"}
                            aria-label="Save outcome"
                          />
                        </>
                      )}
                    </Flex>
                    {convo.preparation.length > 0 && (
                      <>
                        <Text fontWeight={600}>Suggested Preparation:</Text>
                        <Text>{convo.preparation}</Text>
                      </>
                    )}
                    {convo.recurrence && (
                      <>
                        <Text fontWeight={600}>Recurrence:</Text>
                        <Text>
                          {formatRecurrence(
                            convo.recurrence,
                            convo.scheduledDate,
                          )}
                        </Text>
                      </>
                    )}
                    {convo.location && (
                      <>
                        <Text fontWeight={600} mt={2}>
                          Location:
                        </Text>
                        <Text>{convo.location}</Text>
                      </>
                    )}
                  </Box>
                </Panel>
              </Flex>
              <Flex>
                <Panel width="100%" mb={3}>
                  <PanelHeader>
                    <Circle
                      color="primary"
                      icon={FaCommentDots}
                      mr={2}
                      size="small"
                    />
                    <Text fontWeight={500} fontSize={4}>
                      Discussion Points
                    </Text>
                  </PanelHeader>
                  <Box p={2}>
                    <DpContainer>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {sortBy(
                                convo.agenda,
                                (x) => x.addedBy === convo.directReport.id,
                              ).map((x, i) => (
                                <Draggable
                                  key={x.id}
                                  draggableId={
                                    x.id != null ? x.id : i.toString()
                                  }
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      key={x.id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getDndItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                      )}
                                    >
                                      <Flex alignItems="center" mb={0} p={1}>
                                        <Flex
                                          alignItems={"center"}
                                          {...provided.dragHandleProps}
                                          mr={1}
                                        >
                                          <FaGripVertical
                                            display={
                                              !isOwner || isComplete
                                                ? "none"
                                                : ""
                                            }
                                            color="#999"
                                          />
                                        </Flex>
                                        <Circle
                                          color={
                                            x.addedBy === convo.directReport.id
                                              ? "orange"
                                              : "primary"
                                          }
                                          mr={2}
                                          size={10}
                                        />
                                        <Text
                                          style={{ flex: 1 }}
                                          fontWeight={500}
                                        >
                                          {x.name}
                                        </Text>
                                        {x.addedBy ===
                                          convo.directReport.id && (
                                          <Label size="small" color="orange">
                                            Suggested by Direct Report
                                          </Label>
                                        )}
                                        {(isOwner || isManager) &&
                                          !isComplete && (
                                            <IconButton
                                              onClick={() =>
                                                deleteDiscussionPoint(x.id)
                                              }
                                              color={"danger"}
                                              icon={FaTimes}
                                              title={"Delete discussion point"}
                                              aria-label={
                                                "Delete discussion point"
                                              }
                                            />
                                          )}
                                      </Flex>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </DpContainer>
                    {addingDiscussionPoint && (
                      <Flex alignItems="center" mb={0} p={1}>
                        <Input
                          style={{ flex: 1 }}
                          value={discussionPoint}
                          onChange={(ev) => setDiscussionPoint(ev.target.value)}
                          autoFocus
                          maxLength={255}
                          onKeyUp={(ev) => {
                            if (ev.key === "Enter") {
                              saveDiscussionPoint();
                            } else if (ev.key === "Escape") {
                              setAddingDiscussionPoint(false);
                            }
                          }}
                        />

                        <IconButton
                          ml={2}
                          onClick={() => saveDiscussionPoint()}
                          icon={FaCheck}
                          color={"positive"}
                          title={"Save discussion point"}
                        />
                        <IconButton
                          ml={1}
                          onClick={() => setAddingDiscussionPoint(false)}
                          icon={FaTimes}
                          color={"danger"}
                          title={"Cancel"}
                        />
                      </Flex>
                    )}
                    {convo.agenda.length === 0 && (
                      <Flex alignItems="center" p={1}>
                        <Circle color={"grey3"} mr={2} size={10} />
                        <Text
                          style={{ flex: 1 }}
                          fontWeight={500}
                          color="grey2"
                        >
                          {"No Agenda"}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      mt={2}
                      flexDirection={["column", "column", "column", "row"]}
                      style={{ gap: 12 }}
                    >
                      {!isComplete && (
                        <>
                          <AddButton
                            onClick={() => setAddingDiscussionPoint(true)}
                          >
                            {isOwner || isManager
                              ? "Add Discussion Point"
                              : "Suggest Agenda Item"}
                          </AddButton>
                          {(isOwner || isManager) && (
                            <AddButton
                              onClick={() => setAddingCoachingTopics(true)}
                            >
                              Add Question From Library
                            </AddButton>
                          )}
                        </>
                      )}
                    </Flex>
                  </Box>
                </Panel>
              </Flex>
            </Flex>
          </Flex>
        </TabPane>
        {!isComplete && (
          <TabPane defaultValue={terms.goal.asPluralTitle()}>
            {selectedGoal != null ? (
              <>
                <Flex mb={2} flexDirection={"row"}>
                  <BackLink onClick={() => setSelectedGoal(null)} to={null}>
                    <FaChevronLeft />
                    Back to list
                  </BackLink>
                </Flex>
                <GoalReview
                  goalId={selectedGoal}
                  userId={convo.directReport.id}
                />
              </>
            ) : (
              <>
                <GoalList
                  reviewGoal={(goalId) => setSelectedGoal(goalId)}
                  userId={convo.directReport.id}
                />
                <Button
                  mr={2}
                  mt={2}
                  color="primary"
                  onClick={() => setCreatingGoal(true)}
                  icon={FaPlusCircle}
                >
                  Create new {terms.goal.asBody()}
                </Button>
              </>
            )}
          </TabPane>
        )}

        {!isComplete && (
          <TabPane defaultValue="Actions">
            <ActionPane />
          </TabPane>
        )}
        {!isComplete && (
          <TabPane defaultValue="Conversation History">
            <ConversationHistory
              userId={convo.directReport.id}
              convoDate={convo.scheduledDate}
            />
          </TabPane>
        )}
        {!isComplete && (hasLessons || hasMindset) && (
          <TabPane defaultValue="Content">
            <ContentPane isManager={isManager} userId={convo.directReport.id} />
          </TabPane>
        )}
        {!isComplete && (hasFeedback || hasContFeedback) && (
          <TabPane defaultValue="Feedback">
            {hasContFeedback && (
              <Panel mb={5}>
                <PanelHeader p={3}>
                  <Circle mr={2} color="primary">
                    <FaEnvelopeOpen size={20} />
                  </Circle>
                  <Text fontSize={4} fontWeight={600} color="black" as="h2">
                    Continuous Feedback
                  </Text>
                </PanelHeader>
                <ContinuousFeedbackTable userId={convo.directReport.id} />
              </Panel>
            )}
            {hasFeedback && (
              <Panel>
                <PanelHeader p={3}>
                  <Img height={"25px"} src={imgSrc} />
                  <Text
                    ml={3}
                    fontSize={4}
                    fontWeight={600}
                    color="black"
                    as="h2"
                  >
                    Feedback Campaigns
                  </Text>
                </PanelHeader>
                <SortableTable<UserCampaignSummary>
                  columns={cols}
                  data={directReportCampaigns}
                  defaultSort={1}
                  defaultDesc
                  emptyMessage="No Current Campaigns"
                />
              </Panel>
            )}
          </TabPane>
        )}
      </Tabs>
    </>
  );
}
