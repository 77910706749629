import { Flex, Text } from "@coaching-culture/ui";
import { useUser } from "auth";
import { useOrg } from "auth/OrgProvider";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import AccountMenu from "../components/AccountMenu";
import logoSrc from "../img/logo.png";
import { LoopedInWidget } from "./LoopedInWidget";
import NotificationList from "./NotificationList";

const MainLogo = styled.img`
  height: 45px;
  margin-right: 16px;
  display: block;
`;

MainLogo.defaultProps = {
  src: logoSrc,
};

const Container = styled.div<{ impersonating: boolean }>`
  background: ${(props) =>
    props.impersonating ? props.theme.colors.warning : "white"};
  box-shadow: ${(props) => props.theme.shadows[1]};
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  position: relative;
  z-index: 750;
`;

const CircleButtonElem = styled.button<CircleButtonProps>`
  border: 0;
  background: ${(props) => props.theme.colors.grey3};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  ${space};

  &:hover {
    background-color: ${(props) => props.theme.colors.body};
  }

  background-color: ${(props) => (props.active ? props.theme.colors.body : "")};
`;

const UrgentCircle = styled.span`
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.danger};
`;

type CircleButtonProps = {
  active?: boolean;
} & SpaceProps;

const CircleButton = ({ icon: Icon, urgent, active, ...rest }: any) => (
  <CircleButtonElem aria-label="Notifications" {...rest} active={active}>
    <Icon size="0.8em" />
    {urgent && <UrgentCircle />}
  </CircleButtonElem>
);

const Separator = styled.span`
  width: 1px;
  height: 30px;
  background-color: ${(props) => props.theme.colors.grey};
`;

const TopBar = () => {
  const [user] = useUser();
  const [org] = useOrg();
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    axios.get("/api/notifications").then(({ data }) => {
      setNotifications(data);
    });
  }, []);

  if (user == null) {
    return null;
  }

  const impersonating = user.authMethod === "impersonated";

  const logoUrl =
    org.theme.logo == null
      ? logoSrc
      : `${process.env.REACT_APP_STATIC_ROOT}/${org.theme.logo}`;

  return (
    <>
      {showNotifications && (
        <NotificationList
          notifications={notifications}
          onClose={() => setShowNotifications(false)}
          onRemove={(id) =>
            setNotifications((old) => old.filter((x) => x.id !== id))
          }
        />
      )}
      <Container impersonating={impersonating}>
        <Flex>
          <Flex alignItems="center" p={[2, 3]}>
            <Link to="/">
              <MainLogo alt="Company Logo - Dashboard" src={logoUrl} />
            </Link>
          </Flex>
        </Flex>
        {impersonating && (
          <Flex p={[2, 3]}>
            <Text>{`You are currently impersonating ${user.name}`}</Text>
          </Flex>
        )}

        <Flex alignItems="center" p={[2, 3]}>
          <Separator />
          <Route path="/success">
            <LoopedInWidget />
          </Route>
          <CircleButton
            icon={FaBell}
            ml={[2, 3]}
            mr={[2, 3]}
            urgent={notifications.length > 0}
            active={showNotifications}
            onClick={() => setShowNotifications(true)}
          />
          <Separator />
          <AccountMenu />
        </Flex>
      </Container>
    </>
  );
};

export default TopBar;
