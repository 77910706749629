import {
  ContinuousFeedbackDto,
  ContinuousFeedbackRequestDto,
  RequestFeedbackRequest,
  SendFeedbackRequest,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getContinuousFeedbackForUser = (userId?: string) =>
  axios
    .get<
      ContinuousFeedbackDto[]
    >(userId != null ? `/api/v2/continuous-feedback/${userId}` : "/api/v2/continuous-feedback")
    .then(({ data }) => data);

export const useContinuousFeedbackForUser = (userId?: string) =>
  useQuery(["continuous-feedback", userId], () =>
    getContinuousFeedbackForUser(userId),
  );

const getContinuousFeedbackRequest = (id: string) =>
  axios
    .get<ContinuousFeedbackRequestDto>(
      `/api/v2/continuous-feedback/request/${id}`,
    )
    .then(({ data }) => data);

export const useContinuousFeedbackRequest = (id: string) =>
  useQuery(["continuous-feedback-request", id], () =>
    getContinuousFeedbackRequest(id),
  );

const sendFeedback = (spec: SendFeedbackRequest) =>
  axios.post(`/api/v2/continuous-feedback`, spec);

export const useSendFeedback = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(sendFeedback, {
    onError: () => {
      queryClient.invalidateQueries(["continuous-feedback"]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["continuous-feedback"]);
      queryClient.invalidateQueries("team");
    },
  });

  return mutation;
};

const requestFeedback = (spec: RequestFeedbackRequest) =>
  axios.post(`/api/v2/continuous-feedback/request`, spec);

export const useRequestFeedback = () => {
  const mutation = useMutation(requestFeedback, {});

  return mutation;
};
