import { ProductVisibility } from "@coaching-culture/types";
import {
  Button,
  DonutGraph,
  Flex,
  Grid,
  Loader,
  Panel,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import ShareModal, { ShareConfig } from "components/ShareModal";
import { useProductVisibility } from "hooks/useProductVisibility";
import { ContentDetailsTable } from "pages/People/ContentDetailsTable";
import { useUserContent } from "queries/mindset";
import { useMemo, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import lessonsIcon from "../../../img/LessonsBlue.svg";
import mindsetIcon from "../../../img/MindsetBlue.svg";

const ProductIcon = styled.img`
  width: 2em;
  height: 2em;
`;

export type ContentPaneProps = {
  userId: string;
};

export const ContentPane = ({
  userId,
  isManager,
}: {
  userId: string;
  isManager: boolean;
}) => {
  const [shareConfig, setShareConfig] = useState<ShareConfig | null>(null);
  const hasLessons =
    useProductVisibility("lessons") === ProductVisibility.Enabled;
  const hasMindset =
    useProductVisibility("mindset") === ProductVisibility.Enabled;
  const { data: content, isSuccess } = useUserContent(userId);

  const lesson = useMemo(() => {
    if (content != null) return content.filter((x) => x.type === "lesson");
  }, [content]);

  const mindset = useMemo(() => {
    if (content != null) return content.filter((x) => x.type === "module");
  }, [content]);

  const shareModule = (id: string) => {
    setShareConfig({
      type: "module",
      targetId: id,
      itemId: null,
      targetType: "user",
    });
  };

  const shareLesson = (id: string) => {
    setShareConfig({
      type: "lesson",
      targetId: id,
      itemId: null,
      targetType: "user",
    });
  };

  return !isSuccess ? (
    <Loader />
  ) : (
    <>
      {shareConfig && (
        <ShareModal config={shareConfig} onClose={() => setShareConfig(null)} />
      )}
      <Grid
        mb={5}
        gridGap={3}
        gridAutoFlow={["row", "row", "row", "column"]}
        gridAutoColumns="minmax(0, 1fr)"
      >
        {hasMindset && (
          <Panel width={"100%"}>
            <PanelHeader>
              <ProductIcon src={mindsetIcon} />
              <Text ml={2} fontWeight={500} fontSize={4}>
                Mindset
              </Text>
            </PanelHeader>
            <Flex justifyContent="space-evenly" flexDirection="row">
              <Flex height="200px">
                <DonutGraph
                  values={[
                    {
                      label: "In Progress",
                      value: mindset.filter(
                        (x) =>
                          x.attempts.length > 0 &&
                          x.attempts.every((y) => y.completedOn === null)
                      ).length,
                    },
                    {
                      label: "Completed",
                      value: mindset.filter(
                        (x) =>
                          x.attempts.length > 0 &&
                          x.attempts.some((y) => y.completedOn !== null)
                      ).length,
                    },
                    {
                      label: "Not Started",
                      value: mindset.filter((x) => x.attempts.length === 0)
                        .length,
                    },
                  ]}
                />
              </Flex>
              {isManager && (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    onClick={() => shareModule(userId)}
                    icon={FaPlusCircle}
                    color="primary"
                  >
                    Assign Module
                  </Button>
                </Flex>
              )}
            </Flex>
            <ContentDetailsTable items={mindset} contentType="module" />
          </Panel>
        )}
        {hasLessons && (
          <Panel width={"100%"} mr={2}>
            <PanelHeader>
              <ProductIcon src={lessonsIcon} />
              <Text ml={2} fontWeight={500} fontSize={4}>
                Lessons
              </Text>
            </PanelHeader>
            <Flex justifyContent="space-evenly" flexDirection="row">
              <Flex height="200px">
                <DonutGraph
                  values={[
                    {
                      label: "In Progress",
                      value: lesson.filter(
                        (x) =>
                          x.attempts.length > 0 &&
                          x.attempts.every((y) => y.completedOn === null)
                      ).length,
                    },
                    {
                      label: "Completed",
                      value: lesson.filter(
                        (x) =>
                          x.attempts.length > 0 &&
                          x.attempts.some((y) => y.completedOn !== null)
                      ).length,
                    },
                    {
                      label: "Not Started",
                      value: lesson.filter((x) => x.attempts.length === 0)
                        .length,
                    },
                  ]}
                />
              </Flex>
              {isManager && (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    onClick={() => {
                      shareLesson(userId);
                    }}
                    icon={FaPlusCircle}
                    color="primary"
                  >
                    Assign Topic
                  </Button>
                </Flex>
              )}
            </Flex>
            <ContentDetailsTable items={lesson} contentType="lesson" />
          </Panel>
        )}
      </Grid>
    </>
  );
};
