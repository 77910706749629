import { ManagerCampaign } from "@coaching-culture/types";
import { Button, Flex, Loader, Text } from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { UserFlag } from "components/UserFlag";
import React, { useCallback, useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import getUuid from "uuid-by-string";
import { ShareFormData } from "./ShareForm";
import { UserCampaignDisplay } from "./UserCampaignDisplay";
import { UserCampaignWizard } from "./UserCampaignWizard";

export const UserCampaign = () => {
  let { id } = useParams<{ id: string }>();
  const [user] = useUser();

  if (id.length < 10) {
    const s = "feedback_campaign_user-" + id;
    id = getUuid(s);
  }

  const [item, setItem] = useState<ManagerCampaign | null>(null);
  const history = useHistory();

  const loading = item == null;

  useEffect(() => {
    setItem(null);
    axios
      .get<ManagerCampaign>(`/api/feedback/user-campaigns/${id}`)
      .then(({ data }) => {
        setItem(data);
      })
      .catch((err) => {
        history.push("/solutions/feedback");
      });
  }, [id, history]);

  const nudge = useCallback(
    (forwardId: string) => {
      const forward = item.forwards.find((x) => x.id === forwardId);
      forward.lastNudged = new Date();

      setItem({
        ...item,
      });

      axios
        .put(`/api/feedback/user-campaigns/${id}/forwards/${forwardId}/nudge`)
        .then(() => {});
    },
    [id, item]
  );

  if (loading) {
    return <Loader />;
  }

  const share = (data: ShareFormData) => {
    const spec = {
      emails: data.recipients.split("\n").filter((x) => x !== ""),
      subject: data.subject,
      text: data.text,
      deadline: data.deadline,
    };
    axios
      .post(`/api/feedback/user-campaigns/${id}/forwards`, spec)
      .then(({ data }) => {
        setItem(data);
      })
      .catch((err) => {
        window.alert("Error");
      });
  };

  return (
    <CenterColumn>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <Flex flexDirection="column" mb={3}>
          <Text fontWeight={600} fontSize={[5, 6]} mb={2}>
            {item.name}
          </Text>
          <UserFlag user={item.user} />
        </Flex>
        {item.user.id === user.id && (
          <Button
            color="primary"
            to={`/feedback?survey=${item.masterCode}`}
            icon={FaPen}
          >
            Complete survey yourself
          </Button>
        )}
      </Flex>

      {item.forwards.length === 0 && item.user.id === user.id ? (
        <UserCampaignWizard item={item} />
      ) : (
        <UserCampaignDisplay item={item} onNudge={nudge} onShare={share} />
      )}
    </CenterColumn>
  );
};
