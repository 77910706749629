import React, { ComponentProps } from "react";
import { IconType } from "react-icons/lib";
import { useTheme } from "styled-components";

export type IconWrapProps = {
  color: string;
  icon: IconType;
} & ComponentProps<IconType>;

export const IconWrap = ({ color, icon: Icon, ...rest }: IconWrapProps) => {
  const theme = useTheme() as any;

  const col = theme.colors[color] ?? color;

  return <Icon color={col} {...rest} />;
};
