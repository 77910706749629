import {
  Assessment,
  AssessmentCompletion,
  AssessmentOutcome,
  ContentDisplay,
  FormModel,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  Loader,
  Panel,
  RawContent,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { FormCompletionItem } from "components/FormPlayer";
import { SurveyPlayer } from "components/SurveyPlayer";
import { uniqBy } from "lodash";
import { useTodoList } from "queries/todos";
import { useEffect, useRef, useState } from "react";
import { FaPrint } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { Route, Switch, useHistory, useParams } from "react-router";
import { animated, useSpring } from "react-spring";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { clientUrlForContent } from "utils/content";

const ContentImage = styled.img`
  height: 120px;
  display: block;
`;

function SuggestedContent({
  item,
  assessmentCompletionId,
}: {
  item: ContentDisplay;
  assessmentCompletionId: string;
}) {
  const { list, loading, addItem } = useTodoList();

  const onList = list.some(
    (l) =>
      l.completed == null &&
      l.content.id === item.id &&
      l.content.type === item.type,
  );

  const add = () => {
    addItem({
      source: "assessment",
      contentId: item.id,
      contentType: item.type,
      assessmentCompletionId: assessmentCompletionId,
    });
  };

  return (
    <Flex p={3}>
      <ContentImage src={process.env.REACT_APP_STATIC_ROOT + "/" + item.icon} />
      <Flex flexDirection="column" ml={3}>
        <Text fontSize={4} fontWeight={600}>
          {item.name}
        </Text>
        <Box mb={2}>
          <ReactMarkdown children={item.description} />
        </Box>
        <Flex>
          <Button disabled={onList || loading} onClick={add}>
            {loading
              ? "Please wait..."
              : onList
                ? "Already on Todo List"
                : "Add to Todo List"}
          </Button>
          <Button ml={2} color="primary" to={clientUrlForContent(item)}>
            Start Now
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

type AssessmentResultsProps = {
  results: AssessmentOutcome[];
  assessmentCompletionId: string;
};

export const AssessmentResults = ({
  results,
  assessmentCompletionId,
}: AssessmentResultsProps) => {
  const resultsRef = useRef<HTMLDivElement>(null!);

  return (
    <CenterColumn width={800} pt={5}>
      <div style={{ display: "none" }}>
        <div ref={resultsRef}>
          <RawContent>
            <ReactMarkdown children={results.map((x) => x.content).join(" ")} />
          </RawContent>
        </div>
      </div>
      <Flex
        flexDirection={"row"}
        mb={[2, 5]}
        borderBottom={1}
        pb={3}
        justifyContent="space-between"
        alignItems={"flex-end"}
      >
        <Flex justifyContent={"flex-start"} flexDirection="column">
          <Flex>
            <Text
              fontSize={[5, 6]}
              fontWeight={600}
              lineHeight={1}
              color="black"
              as="h1"
            >
              Your Results
            </Text>
          </Flex>
          <Text fontSize={3} color="grey1" fontWeight={500} mt={1}>
            Thank you for completing the assessment
          </Text>
        </Flex>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon={FaPrint}>
              Print
            </Button>
          )}
          content={() => resultsRef.current}
        />
      </Flex>
      <ResultsPanel
        results={results}
        assessmentCompletionId={assessmentCompletionId}
      />
    </CenterColumn>
  );
};

function ResultsPanel({
  results,
  assessmentCompletionId,
}: {
  results: AssessmentOutcome[];
  assessmentCompletionId: string;
}) {
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  let pages: AssessmentOutcome[][] = [[]];
  let currentPage = pages[0];

  for (const o of results) {
    if (o.pageBreak === "before") {
      currentPage = [o];
      pages.push(currentPage);
    } else if (o.pageBreak === "after") {
      currentPage.push(o);
      currentPage = [];
      pages.push(currentPage);
    } else {
      currentPage.push(o);
    }
  }

  pages = pages.filter((x) => x.length > 0);

  const styles = useSpring({
    from: {
      y: 50,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
  });

  const lastPage = page === pages.length - 1;
  const firstPage = page === 0;

  const content = uniqBy(
    pages[page].map((x) => x.suggestedContent).filter((x) => x != null),
    (x) => x.type + x.id,
  );

  return (
    <animated.div style={styles}>
      <Panel p={3} mb={3}>
        <RawContent>
          <ReactMarkdown
            children={pages[page].map((x) => x.content).join(" ")}
          />
        </RawContent>
        {content.length > 0 && (
          <Box mt={2}>
            <Text mb={1} fontSize={4} fontWeight={600}>
              Recommended Content
            </Text>
            <Flex border={1} borderRadius="3px" flexDirection="column">
              {content.map((x) => (
                <SuggestedContent
                  item={x}
                  assessmentCompletionId={assessmentCompletionId}
                />
              ))}
            </Flex>
          </Box>
        )}
        <Flex justifyContent="center" p={3}>
          <Button onClick={() => setPage(page - 1)} disabled={firstPage} mr={2}>
            Back
          </Button>
          {lastPage ? (
            <Button color="primary" to="/solutions/assessments">
              Return to Assessments
            </Button>
          ) : (
            <Button color="primary" onClick={() => setPage(page + 1)}>
              Next
            </Button>
          )}
        </Flex>
      </Panel>
    </animated.div>
  );
}

export function AssessmentPlayer() {
  const [data, setData] = useState<Assessment | null>(null);
  const [form, setForm] = useState<FormModel | null>(null);
  const [results, setResults] = useState<AssessmentCompletion | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [user] = useUser();
  const history = useHistory();
  const { slug, compId } = useParams<{ slug: string; compId: string | null }>();

  useEffect(() => {
    setData(null);
    setResults(null);
    setForm(null);
    axios.get<Assessment>(`/api/assessments/${slug}`).then(({ data }) => {
      setData(data);
      axios.get(`/api/forms/${data.formId}`).then(({ data }) => setForm(data));
    });
  }, [slug]);

  useEffect(() => {
    if (compId != null && results?.id !== compId) {
      axios
        .get(`/api/assessment-completions/${compId}`)
        .then(({ data }) => {
          setResults(data);
        })
        .catch((err) => {
          history.push("/solutions/assessments");
        });
    } else if (compId == null && results != null) {
      setResults(null);
    }
  }, [compId, history, results]);

  const onFormCompletion = (d: FormCompletionItem[]) => {
    setLoading(true);
    axios
      .post(`/api/assessments/${data.id}/completions`, d)
      .then(({ data }) => {
        setResults(data);
        setLoading(false);
        history.push(
          `/solutions/assessments/modules/${slug}/results/${data.id}`,
        );
      });
  };

  if (data == null || form == null || loading) {
    return <Loader />;
  }

  return (
    <>
      <Switch>
        <Route
          path="/solutions/assessments/modules/:slug"
          exact
          render={() => (
            <SurveyPlayer
              email={user.email}
              form={form}
              onCompletion={onFormCompletion}
            />
          )}
        />
        <Route
          path="/solutions/assessments/modules/:slug/results/:compId"
          render={() =>
            results == null ? (
              <Loader />
            ) : (
              <AssessmentResults
                results={results.result}
                assessmentCompletionId={results.id}
              />
            )
          }
        />
      </Switch>
    </>
  );
}
