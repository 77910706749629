import { FormModel } from "@coaching-culture/types";
import {
  Box,
  Text,
  Button,
  Loader,
  Modal,
  Circle,
  Flex,
} from "@coaching-culture/ui";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaQuestion } from "react-icons/fa";

export type FormPreviewModalProps = {
  formId: string;
  onClose: () => void;
};

export function FormPreviewModal({ formId, onClose }: FormPreviewModalProps) {
  const [form, setForm] = useState<FormModel | null>(null);

  useEffect(() => {
    axios.get(`/api/forms/${formId}`).then(({ data }) => {
      setForm(data);
    });
  }, [formId]);

  return (
    <Modal width={500} p={3}>
      {form == null ? (
        <Loader />
      ) : (
        <>
          <Text fontSize={4} mb={3} fontWeight={600}>
            Survey Preview
          </Text>
          <Box mb={3}>
            {form.items
              .filter((x) =>
                ["free_text", "multiple_choice", "rating"].includes(x.type)
              )
              .map((x, i) => (
                <Flex mb={2} alignItems="center">
                  <Circle color="primary" icon={FaQuestion} mr={2} />
                  <div>
                    <Text fontWeight={600} color="grey2" fontSize={2} mb="3px">
                      QUESTION {i + 1}
                    </Text>
                    <Text>{x.content}</Text>
                  </div>
                </Flex>
              ))}
          </Box>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </>
      )}
    </Modal>
  );
}
