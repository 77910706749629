import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import sortBy from "lodash/sortBy";
import {
  typography,
  space,
  color,
  TypographyProps,
  SpaceProps,
  ColorProps,
} from "styled-system";
import isFunction from "lodash/isFunction";
import { usePagination } from "../hooks/usePagination";
import { TablePaginationRow } from "./TablePaginationRow";
import Box from "./Box";
import Flex from "./Flex";
import Text from "./Text";
import { useDebounce } from "../hooks/useDebounced";
import InputWithSearch from "./InputWithSearch";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:last-child td {
    border-bottom: 0;
  }

  tr:hover td {
    background-color: #fafafa;
  }
`;

type TdProps = SpaceProps & ColorProps & TypographyProps;

const Td = styled.td<TdProps>`
  font-size: 13px;
  border: 1px solid ${(props) => props.theme.colors.grey4};
  padding: 6px 18px;
  vertical-align: middle;

  & > * {
    vertical-align: middle;
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  ${typography};
  ${space};
  ${color};
`;

Td.defaultProps = {
  fontSize: [2, 3],
  pt: [1, 1, 1],
  pb: [1, 1, 1],
  pl: [1, 1, 2],
  pr: [1, 1, 2],
};

type ThProps = SpaceProps &
  TypographyProps & {
    sortable?: boolean;
    whiteSpace?: string;
  };

const Th = styled.th<ThProps>`
  text-align: left;
  background-color: ${(props) => props.theme.colors.grey7};
  padding: 6px 18px;
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "nowrap")};
  ${typography};
  ${space};

  ${(props) =>
    props.sortable &&
    css`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `};
`;

Th.defaultProps = {
  fontSize: [2, 3],
  pt: [1, 1, 1],
  pb: [1, 1, 1],
  pl: [1, 1, 2],
  pr: [1, 1, 2],
};

const processProps = (props: object, item: any) => {
  if (props == null) {
    return {};
  }

  return Object.entries(props)
    .map(([key, value]) => {
      if (isFunction(value)) {
        return [key, value(item)];
      } else {
        return [key, value];
      }
    })
    .reduce((acc, val) => {
      acc[val[0]] = val[1];
      return acc;
    }, {} as any);
};

export type SortableTableColumn<T> = {
  name?: string | null;
  label: React.ReactNode;
  exportLabel?: string;
  format?: (item: T) => React.ReactNode;
  exportFormat?: (item: T) => string;
  excludeFromExport?: boolean;
  sortFormat?: (item: T) => string | number | Date;
  headingProps?: any;
  notSortable?: boolean;
  props?: any;
  hidden?: boolean;
};

export type SortableTableProps<T> = {
  rowSearchPlaceholder?: string;
  columns: SortableTableColumn<T>[];
  data: T[];
  defaultSort?: number | null;
  defaultDesc?: boolean;
  emptyMessage?: string;
  disableSort?: boolean;
  rowKey?: keyof T;
  rowStyle?: (item: T) => any;
  rowSearch?: (item: T) => string;
  incrementalRender?: boolean;
  paged?: boolean;
  onExport?: (data: Record<string, string>[]) => void;
};

type RowProps = {
  data: any;
  rowStyle: (item: any) => any;
  columns: any[];
};

const Row = React.memo(({ data, rowStyle, columns }: RowProps) => {
  return (
    <tr style={rowStyle(data)}>
      {columns.map((c, i) => (
        <Td key={i} {...processProps(c.props, data)}>
          {c.format ? c.format(data) : data[c.name]}
        </Td>
      ))}
    </tr>
  );
});

const ident = () => ({});

export function SortableTable<T>({
  columns,
  data,
  rowKey,
  defaultSort = null,
  defaultDesc = false,
  emptyMessage = "",
  disableSort = false,
  rowStyle = ident,
  rowSearch,
  rowSearchPlaceholder = "Search...",
  incrementalRender = false,
  paged = false,
  onExport,
}: SortableTableProps<T>) {
  const [sort, setSort] = useState<number>(defaultSort || 0);
  const [search, setSearch] = useState<string>("");
  const [sortDir, setSortDir] = useState<boolean>(defaultDesc || false);
  const [renderCount, setRenderCount] = useState<number>(0);
  const debouncedSearch = useDebounce(search, 300);

  data = data || [];

  useLayoutEffect(() => {
    setRenderCount(0);
  }, [data, sort, sortDir]);

  useEffect(() => {
    setSearch("");
  }, [data]);

  useEffect(() => {
    const i = setInterval(() => {
      if (renderCount < data.length && incrementalRender) {
        setRenderCount((old) => old + 50);
      }
    }, 2000 / 60);

    return () => {
      clearInterval(i);
    };
  }, [renderCount, data, incrementalRender]);

  const doSort = (index: number) => {
    if (sort === index) {
      setSortDir((x) => !x);
    } else {
      setSortDir(true);
      setSort(index);
    }
  };

  let sorted = useMemo(() => {
    const sortedColumn = columns[sort];

    const sortKey = sortedColumn.sortFormat || sortedColumn.name;

    let sorted = sortKey ? sortBy(data, sortKey) : data;

    if (sortDir) {
      sorted.reverse();
    }

    if (rowSearch && debouncedSearch.trim() !== "") {
      sorted = sorted.filter((x) =>
        rowSearch(x)
          .trim()
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase()),
      );
    }

    return sorted;
  }, [columns, sort, data, sortDir, debouncedSearch, rowSearch]);

  if (incrementalRender) {
    sorted = sorted.slice(0, renderCount);
  }

  const {
    activePage,
    data: pagedData,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = usePagination(sorted, 20, 1);

  if (paged && incrementalRender) {
    throw new Error("A table cannot be paged and incrementally render");
  }

  const tableData = paged ? pagedData : sorted;

  const exportData = () => {
    const data: Record<string, string>[] = sorted.map((row) => {
      const entries = columns
        .filter((x) => !x.excludeFromExport)
        .map(({ label, exportLabel, name, exportFormat, format }) => {
          const byName = row[name as keyof T];
          const value = exportFormat
            ? exportFormat(row)
            : format
              ? format(row)?.toString()
              : byName;
          return [
            exportLabel ?? label?.toString() ?? "",
            String(value ?? ""),
          ] as const;
        });

      return Object.fromEntries(entries);
    });

    if (onExport) {
      onExport(data);
    }
  };

  return (
    <Box>
      {(paged || rowSearch) && (
        <Flex
          p={1}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          borderBottom={1}
        >
          {rowSearch ? (
            <Flex width={["100%", "300px"]} mb={[1, 0]}>
              <InputWithSearch
                placeholder={rowSearchPlaceholder}
                searching={search !== debouncedSearch}
                width="100%"
                ml={2}
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
              />
            </Flex>
          ) : (
            <div />
          )}
          {paged && (
            <Flex justifyContent="flex-end">
              <TablePaginationRow
                pageSize={20}
                itemCount={sorted.length}
                activePage={activePage}
                onNextPage={nextPage}
                onPrevPage={prevPage}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                onExport={onExport ? exportData : undefined}
              />
            </Flex>
          )}
        </Flex>
      )}
      <Box style={{ overflowX: "auto" }}>
        <Table>
          <thead>
            <tr>
              {columns
                .filter((col) => !col.hidden)
                .map(({ label, headingProps, notSortable }, i) => (
                  <Th
                    sortable={!disableSort && !notSortable}
                    key={i}
                    {...(headingProps || {})}
                    onClick={() => !disableSort && !notSortable && doSort(i)}
                  >
                    {label}
                    {sort === i && (sortDir ? " ▼" : " ▲")}
                  </Th>
                ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, i) => (
              <Row
                key={rowKey ? (data[rowKey] as unknown as string) : i}
                data={data}
                rowStyle={rowStyle}
                columns={columns.filter((col) => !col.hidden)}
              />
            ))}
            {sorted.length === 0 && (
              <tr>
                <Text
                  colSpan={columns.length}
                  textAlign="center"
                  as="td"
                  fontWeight={600}
                  color="grey2"
                  py={3}
                >
                  {emptyMessage}
                </Text>
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
}
