import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "scorm-again";
import { useWindowSize } from "react-use";

const Frame = styled.iframe`
  width: 100%;
  height: 600px;
  margin: auto;
  display: block;
  border: 0;
`;

const w: any = window;

export type ContentAssignmentEvent = {
  slug: string;
};

type ScormPlayerProps = {
  contentUrl: string;
  onComplete: () => void;
  onCommit: (ev: any) => void;
  commitUrl: string;
  initialData?: any;
  parentWidth: number;
  fullscreen: boolean;
  onAssignContent?: (ev: ContentAssignmentEvent) => void;
};

const ScormPlayer = ({
  contentUrl,
  onComplete,
  onCommit,
  commitUrl,
  initialData,
  parentWidth,
  fullscreen,
  onAssignContent,
}: ScormPlayerProps) => {
  const iframe = useRef(null);
  const [init, setInit] = useState(false);
  const { height } = useWindowSize();

  useEffect(() => {
    if (!init) {
      w.API = new w.Scorm12API({
        autocommit: true,
        autocommitSeconds: 10,
      });
      if (initialData != null) {
        w.API.loadFromJSON(initialData);
      }
      setInit(true);
    }
  }, [commitUrl, init, initialData]);

  useEffect(() => {
    const handler = () => {
      const data = w.API.renderCMIToJSONObject();
      onCommit(data);
      if (data.cmi.core.lesson_status === "completed") {
        if (iframe.current == null) {
          return;
        }
        onComplete();
      } else {
        console.log("not complete yet");
      }
    };
    w.API.on("LMSCommit", handler);
    const interval = setInterval(() => {
      w.API.lmsCommit();
    }, 10000);

    return () => {
      w.API.off("LMSCommit", handler);
      clearInterval(interval);
    };
  }, [onComplete, onCommit, init]);

  if (!init) return null;

  const onFrameLoad = (ev: React.SyntheticEvent<HTMLIFrameElement>) => {
    const doc = (ev.target as HTMLIFrameElement).contentDocument;
    const innerFrame = doc.getElementById("content-frame") as HTMLIFrameElement;

    const handler = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      try {
        // TODO fix this hacky shit
        if (
          target.tagName === "A" &&
          target.innerHTML.startsWith("SAVE FOR LATER")
        ) {
          ev.preventDefault();
          ev.stopPropagation();

          if (onAssignContent != null) {
            onAssignContent({
              slug: "the-art-of-listening",
            });
          }
        }
      } catch {}
    };

    doc.addEventListener("click", handler);

    if (innerFrame != null) {
      innerFrame.contentWindow.addEventListener("click", handler);
    }
  };

  return (
    <div>
      <Frame
        onLoad={onFrameLoad}
        src={"/mindset-scorm" + contentUrl}
        ref={iframe}
        style={
          fullscreen
            ? { height: height - 100 }
            : {
                height: Math.min(height - 240, parentWidth * 0.6),
              }
        }
      />
    </div>
  );
};

export default ScormPlayer;
