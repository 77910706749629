import { DirectReportDto } from "@coaching-culture/types";
import { Flex, Text } from "@coaching-culture/ui";
import styled from "styled-components";

const Track = styled.div`
  width: 100%;
  height: 16px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.grey4};
  margin: 3px 0;
  position: relative;
`;

const Needle = styled.div`
  height: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 11px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  background-color: ${(props) => props.theme.colors.grey1};
`;

export type MeasuresChartProps = {
  user: DirectReportDto;
};

export const MeasuresChart = ({ user }: MeasuresChartProps) => {
  return (
    <Flex width="100%" mr={3} flexDirection="column">
      {user.measures.map((x) => {
        const index = x.options.findIndex((o) => o.id === x.value);
        const opt = x.options[index];

        const position = index / Math.max(1, x.options.length - 1);

        return (
          <Flex alignItems={"center"}>
            <Text
              mr={2}
              textAlign={"right"}
              fontWeight={500}
              fontSize={"12px"}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "130px",
              }}
            >
              {x.name}
            </Text>
            <Track>
              {index > -1 && (
                <Needle
                  style={{
                    width: `${25 + position * 75}%`,
                  }}
                >
                  {opt?.name}
                </Needle>
              )}
            </Track>
          </Flex>
        );
      })}
    </Flex>
  );
};
