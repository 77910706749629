import { useMemo } from "react";
import { range } from "lodash";
import styled from "styled-components";
export type ActivityMatrixProps = {
  data: number[][];
};

const ActivityTable = styled.table`
  width: 100%;
  table-layout: fixed;

  th {
    text-align: left;
    font-size: 12px;
    color: ${(props) => props.theme.colors.grey3};
  }

  td {
    font-size: 0;
    padding: 0;
  }

  td:first-child {
    overflow: hidden;
    padding: 3px;
    padding-left: 0;
    font-size: 12px;
    width: 50px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.grey3};
    text-align: right;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    td {
      width: calc((100% - 50px) / 24);
    }

    td:first-child {
      width: 50px;
    }
  }
`;

const ActivityBlob = styled.td<{ value: number }>`
  background-color: ${(props) => props.theme.colors.primary};
  opacity: ${(props) => Math.max(0.1, props.value)};
`;

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const order = [1, 2, 3, 4, 5, 6, 0];

export function ActivityMatrix({ data }: ActivityMatrixProps) {
  const max = useMemo(() => {
    return Math.max(...data.flatMap((x) => x), 1);
  }, [data]);

  return (
    <ActivityTable>
      <thead>
        <tr>
          <th />
          {range(0, 24, 6).map((x) => (
            <th colSpan={6} key={x}>
              {x.toString().padStart(2, "0")}:00
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {order.map((x) => (
          <tr key={x}>
            <td>{days[x]}</td>
            {data[x].map((x, i) => (
              <ActivityBlob value={x / max} key={i} />
            ))}
          </tr>
        ))}
      </tbody>
    </ActivityTable>
  );
}
