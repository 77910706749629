export enum ProductVisibility {
  Hidden = 0,
  Locked = 1,
  ComingSoon = 2,
  Enabled = 3,
}

export enum ManagerVisibility {
  Hidden = 0,
  CanView = 1,
  CanShare = 2,
}

export type ProductIdent =
  | "mindset"
  | "lessons"
  | "360"
  | "performance"
  | "surveys"
  | "assessments"
  | "diagnostics"
  | "learncast";

export type InsightsType =
  | "profiles"
  | "form-groups"
  | "dominant-group"
  | "least-dominant"
  | "none";
export type InsightsGraphType = "bar" | "pie";
