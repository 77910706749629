import { UserFilter, UserMeasuresDto } from "@coaching-culture/types";
import {
  Flex,
  IconButton,
  IconWrap,
  Loader,
  OptionSelect,
  Text,
  Panel,
  PanelHeader,
  SortableTable,
  SortableTableColumn,
} from "@coaching-culture/ui";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import { max, range, min } from "lodash";
import { MeasuresTrendsModal } from "pages/Solutions/MeasuresTrendsModal";
import { useAllUserMeasures, useMeasures } from "queries/measures";
import { useMemo, useState } from "react";
import { FaChartLine, FaUsers } from "react-icons/fa";
import { useTheme } from "styled-components";
import { mix } from "polished";
import { saveExportAsCsv } from "utils";
import { useUser } from "auth";

const makeRamp = (colors: string[], count: number) =>
  range(count)
    .map((x) => {
      const p = x / (count - 1);
      const i = Math.floor(p * (colors.length - 1));
      const i2 = min([i + 1, colors.length - 1]);
      const p2 = p * (colors.length - 1) - i;
      const c = mix(p2, colors[i2], colors[i]);
      return c;
    })
    .reverse();

type MeasuresInsightsTableProps = {
  filter: UserFilter;
};

export const MeasuresInsightsTable = ({
  filter,
}: MeasuresInsightsTableProps) => {
  const [showTrends, setShowTrends] = useState<string | null>(null);
  const measures = useMeasures();
  const userMeasures = useAllUserMeasures(filter);
  const theme = useTheme();
  const [user] = useUser();

  const loading = !measures.isSuccess || !userMeasures.isSuccess;

  const cols = useMemo<SortableTableColumn<UserMeasuresDto>[]>(() => {
    if (!measures.data) {
      return [];
    }

    const cols: SortableTableColumn<UserMeasuresDto>[] = [
      {
        name: "name",
        label: "Name",
        format: (x) => <UserFlag user={x.user} />,
        sortFormat: (x) => x.user.name,
        exportFormat: (x) => x.user.name,
        headingProps: {
          style: {
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 2,
          },
        },
        props: {
          style: {
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 2,
          },
        },
      },
      {
        name: "manager",
        label: "Manager",
        format: (x) => <UserFlag user={x.manager} />,
        sortFormat: (x) => x.manager.name,
        exportFormat: (x) => x.manager.name,
      },
      ...measures.data.map((x) => ({
        name: x.name,
        label: x.name,
        exportFormat: (item: UserMeasuresDto) => {
          if (user.id === item.user.id) {
            return "Hidden";
          }
          const val = item.measures.find((i) => i.id === x.id)?.value ?? "";
          const valName = x.options.find((o) => o.id === val)?.name ?? "Unset";

          return valName;
        },
        format: (item: UserMeasuresDto) => {
          if (user.id === item.user.id) {
            return (
              <Text textAlign={"center"} color="grey2" fontWeight={600}>
                Hidden
              </Text>
            );
          }

          const val = item.measures.find((i) => i.id === x.id)?.value ?? "";

          const colorRamp = makeRamp(
            [theme.colors.danger, theme.colors.warning, theme.colors.positive],
            x.options.length,
          );

          const options = x.options.map((x, i) => ({
            value: x.id,
            label: x.name,
            color: colorRamp[i],
          }));

          if (val === "") {
            options.unshift({
              value: "",
              label: "Unset",
              color: "grey2",
            });
          }

          return <OptionSelect readonly value={val} options={options} />;
        },
        sortFormat: (item: UserMeasuresDto) => {
          const val = item.measures.find((i) => i.id === x.id)?.value ?? "";

          return x.options.findIndex((x) => x.id === val);
        },
      })),
      {
        name: "lastUpdated",
        label: "Last Updated",
        exportFormat: (x) => {
          if (user.id === x.user.id) {
            return "Hidden";
          }
          const val = max(x.measures.map((x) => x.lastSet));
          if (val == null) {
            return "Never";
          } else {
            return format(fromUnixTime(val), "yyyy-MM-dd");
          }
        },
        format: (x) => {
          if (user.id === x.user.id) {
            return (
              <Text textAlign={"center"} color="grey2" fontWeight={600}>
                Hidden
              </Text>
            );
          }
          const val = max(x.measures.map((x) => x.lastSet));
          if (val == null) {
            return (
              <Text textAlign={"center"} color="grey2" fontWeight={600}>
                Never
              </Text>
            );
          } else {
            return (
              <Text
                textAlign={"center"}
                color="grey2"
                fontWeight={600}
                fontSize={2}
              >
                {format(fromUnixTime(val), "do MMM yyyy")}
              </Text>
            );
          }
        },
      },
      {
        name: "actions",
        label: "",
        excludeFromExport: true,
        format: (x) => (
          <Flex>
            <IconButton
              icon={FaChartLine}
              onClick={() => setShowTrends(x.relationshipId)}
              color="primary"
              disabled={user.id === x.user.id}
            />
          </Flex>
        ),
      },
    ];

    return cols;
  }, [
    measures.data,
    theme.colors.danger,
    theme.colors.positive,
    theme.colors.warning,
    user.id,
  ]);

  return (
    <>
      {showTrends && (
        <MeasuresTrendsModal
          relationshipId={showTrends}
          onClose={() => setShowTrends(null)}
        />
      )}
      <Panel>
        <PanelHeader alignItems={"center"}>
          <IconWrap icon={FaUsers} color="primary" size="2em" />
          <Text fontWeight={600} ml={3}>
            User Measures
          </Text>
        </PanelHeader>
        {loading ? (
          <Loader />
        ) : (
          <SortableTable
            columns={cols}
            onExport={saveExportAsCsv("CCMeasuresExport.csv")}
            data={userMeasures.data}
            paged
            rowKey="relationshipId"
            rowSearch={(val) => [val.user.name, val.manager.name].join(" ")}
          />
        )}
      </Panel>
    </>
  );
};
