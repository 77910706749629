import { DirectReportDto, SendFeedbackRequest } from "@coaching-culture/types";
import {
  Button,
  Flex,
  FormInput,
  FormInputWrap,
  Loader,
  RichTextEditor,
  SelectButton,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { useSendFeedback } from "queries/continuousFeedback";
import { Controller, useForm } from "react-hook-form";
import { FaCommentDots, FaEnvelope, FaHands } from "react-icons/fa";
import { animated, useSpring } from "react-spring";

import styled from "styled-components";

const SectionIcon = styled.div`
  color: white;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 3px;
`;

const Drawer = animated(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  overflow-y: auto;
`);

export type SendFeedbackDrawerProps = {
  onClose: () => void;
  user: DirectReportDto;
};

export const SendFeedbackDrawer = ({
  onClose,
  user,
}: SendFeedbackDrawerProps) => {
  const [me] = useUser();

  const { register, handleSubmit, control, errors } =
    useForm<SendFeedbackRequest>();

  const styleProps = useSpring({
    y: "0%",
    from: {
      y: "100%",
    },
  });

  const sendFeedback = useSendFeedback();

  const onSubmit = async (values: SendFeedbackRequest) => {
    await sendFeedback.mutateAsync({
      type: values.type,
      summary: values.summary,
      detail: values.detail ?? "",
      senderId: me.id,
      recipientId: user.id,
    });
    onClose();
  };

  return (
    <Drawer style={styleProps}>
      {sendFeedback.isLoading && <Loader overlay />}
      <Flex p={"100px"} py={5} flexDirection="column">
        <Flex justifyContent={"center"}>
          <Flex alignItems={"center"} mb={5}>
            <SectionIcon>
              <FaEnvelope fontSize={"1.5em"} />
            </SectionIcon>
            <Text fontWeight={600} ml={2}>
              Feedback
            </Text>
          </Flex>
        </Flex>
        <Text fontSize={4} fontWeight={600}>
          Give {user.name.split(" ")[0]} some feedback.
        </Text>
        <Text mb={3} fontWeight={500} color="grey1">
          Regular feedback is important
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInputWrap label="Feedback Type" error={errors.type} required>
            <Controller
              control={control}
              name="type"
              defaultValue={""}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <Flex flexDirection={"column"} style={{ gap: 6 }}>
                  <SelectButton
                    icon={FaHands}
                    name="Praise"
                    active={value === "praise"}
                    onSelect={() => onChange("praise")}
                  />
                  <SelectButton
                    icon={FaCommentDots}
                    name="Feedback"
                    active={value === "feedback"}
                    onSelect={() => onChange("feedback")}
                  />
                </Flex>
              )}
            />
          </FormInputWrap>
          <FormInput
            name="summary"
            label="Summary"
            error={errors.summary}
            required
            ref={register({ required: true })}
          />
          <Controller
            name="detail"
            control={control}
            render={({ value, onChange }) => (
              <FormInputWrap label="Detail" error={errors.detail}>
                <RichTextEditor
                  height={300}
                  value={value}
                  onChange={onChange}
                />
              </FormInputWrap>
            )}
          />
          <Flex style={{ gap: 6 }}>
            <Button onClick={onClose} flex={1} type="button">
              Cancel
            </Button>
            <Button
              flex={1}
              color="primary"
              icon={FaEnvelope}
              type="submit"
              disabled={sendFeedback.isLoading}
            >
              Send Feedback
            </Button>
          </Flex>
        </form>
      </Flex>
    </Drawer>
  );
};
