import {
  ActionItemSpec,
  AssessmentActionDto,
  ContinuousFeedbackActionDto,
  discriminate,
  discriminateNeg,
  MeasuresUpdateActionDto,
  ProvideFeedbackActionDto,
  SendFeedbackActionDto,
  SurveyActionDto,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Flex,
  IconButton,
  Label,
  Loader,
  Panel,
  PanelHeader,
  Table,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import { transparentize } from "polished";
import { useAddAction, useRemoveAction, useUserActions } from "queries/actions";
import {
  useChangeActionItem,
  useConversationInterval,
} from "queries/conversations";
import {
  FaBolt,
  FaBullhorn,
  FaCheck,
  FaExternalLinkAlt,
  FaTrash,
} from "react-icons/fa";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { sortBy } from "lodash";
import { useState } from "react";
import { Toggle } from "components/Toggle";
import { ActionItemModal } from "./ActionItemModel";
import { Link } from "react-router-dom";

const Row = styled.tr<{ isComplete: boolean }>`
  position: relative;
  ${(props) =>
    props.isComplete
      ? css`
          & td {
            background-color: ${(props) =>
              transparentize(0.9, props.theme.colors.positive)};
            opacity: 0.8;
          }
        `
      : ""};
`;

export type ActionPaneProps = {
  conversationId: string;
  conversationIndex: number;
};

export const SurveyActionRow = ({
  item,
  showLinks,
}: {
  item: SurveyActionDto;
  showLinks: boolean;
}) => (
  <tr>
    <td>
      <Label color="green">Survey</Label>
    </td>
    <td>
      <UserFlag user={item.survey.sender} />
    </td>
    <td>
      {showLinks ? (
        <Link to={`/surveys/${item.survey.id}`} target="_blank">
          {item.survey.name} <FaExternalLinkAlt fontSize="0.8em" />
        </Link>
      ) : (
        item.survey.name
      )}
    </td>

    <td>No Due Date</td>
  </tr>
);

export const SendFeedbackRow = ({
  item,
  showLinks,
}: {
  item: SendFeedbackActionDto;
  showLinks: boolean;
}) => (
  <tr>
    <td>
      <Label color="blue">Request Feedback</Label>
    </td>
    <td>
      <UserFlag user={item.campaign.sender} />
    </td>
    <td>
      {showLinks ? (
        <Link
          to={`/solutions/feedback/campaigns/${item.campaign.id}`}
          target="_blank"
        >
          {item.campaign.name} <FaExternalLinkAlt fontSize="0.8em" />
        </Link>
      ) : (
        item.campaign.name
      )}
    </td>
    <td>No Due Date</td>
  </tr>
);

export const ContFeedbackRow = ({
  item,
}: {
  item: ContinuousFeedbackActionDto;
}) => (
  <tr>
    <td>
      <Label color="purple">Give User Feedback</Label>
    </td>
    <td>
      <UserFlag user={item.requester} />
    </td>
    <td>{item.requester.name} has requested some feedback</td>
    <td>No Due Date</td>
  </tr>
);

export const AssessmentRow = ({ item }: { item: AssessmentActionDto }) => (
  <tr>
    <td>
      <Label color="pink">Complete Assessment</Label>
    </td>
    <td>
      <UserFlag user={item.assigner} />
    </td>
    <td>{item.content.name}</td>
    <td>No Due Date</td>
  </tr>
);

export const MeasuresRow = ({ item }: { item: MeasuresUpdateActionDto }) => (
  <tr>
    <td>
      <Label color="pink">Update Measures</Label>
    </td>
    <td></td>
    <td>Update Your Measures</td>
    <td>No Due Date</td>
  </tr>
);

export const ProvideFeedbackRow = ({
  item,
  showLinks,
}: {
  item: ProvideFeedbackActionDto;
  showLinks: boolean;
}) => (
  <tr>
    <td>
      <Label color="orange">Provide Feedback</Label>
    </td>
    <td>
      <UserFlag user={item.campaign.user} />
    </td>
    <td>
      {showLinks ? (
        <Link to={`/feedback/${item.code}`} target="_blank">
          {item.campaign.name} <FaExternalLinkAlt fontSize="0.8em" />
        </Link>
      ) : (
        item.campaign.name
      )}
    </td>
    <td>
      {item.dueDate == null
        ? "No Due Date"
        : format(fromUnixTime(item.dueDate), "do MMM yyyy")}
    </td>
  </tr>
);

export const ActionPane = () => {
  const { id, index } = useParams<{ id: string; index: string }>();
  const [creating, setCreating] = useState<boolean>(false);
  const conversation = useConversationInterval(id, parseInt(index, 10));
  const setAction = useChangeActionItem();
  const removeActionMut = useRemoveAction();
  const addActionItem = useAddAction();
  const [user] = useUser();
  const [all, setAll] = useState<boolean>(false);
  const actions = useUserActions(conversation.data?.directReport.id);

  if (!actions.isSuccess || !conversation.isSuccess) {
    return <Loader />;
  }
  const managerId = conversation.data.manager.id;
  const reportId = conversation.data.directReport.id;
  const isManager = user.id === managerId;

  const convoActions = actions.data
    .filter(discriminate("type", "conversation"))
    .filter(
      (x) =>
        x.conversation.manager.id === managerId &&
        x.conversation.directReport.id === reportId
    )
    .filter((x) => all || x.completedOn == null);

  const campaignActions = actions.data.filter(
    discriminateNeg("type", "conversation")
  );

  const completeAction = async (
    conversationId: string,
    actionItemId: string,
    value: boolean
  ) => {
    await setAction.mutateAsync({ conversationId, actionItemId, value });
  };

  const removeAction = async (id: string) => {
    await removeActionMut.mutateAsync(id);
  };

  const createActionItem = async (spec: ActionItemSpec) => {
    setCreating(false);
    await addActionItem.mutateAsync({
      ...spec,
      conversationId: id,
      occurenceIndex: parseInt(index, 10),
    });
  };

  return (
    <>
      <ActionItemModal
        isOpen={creating}
        onCancel={() => setCreating(false)}
        onCreate={createActionItem}
        assigneeOptions={[
          conversation.data.manager,
          conversation.data.directReport,
        ]}
      />

      <Panel mb={3}>
        <PanelHeader justifyContent={"space-between"}>
          <Text fontWeight={600}>
            <Text as="span" color="primary" mr={3}>
              <FaBolt size="1.5em" style={{ top: 5, position: "relative" }} />
            </Text>
            Actions
          </Text>
          <Toggle
            value={all}
            leftLabel={"Outstanding"}
            rightLabel="All"
            onChange={setAll}
            ariaLabel={"View all actions"}
          />
        </PanelHeader>
        <Table>
          <thead>
            <th>Assigned To</th>
            <th>Action</th>
            <th>Due date</th>
            <th />
          </thead>
          <tbody>
            {convoActions.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <Text color="grey2" textAlign={"center"}>
                    No Current Actions
                  </Text>
                </td>
              </tr>
            ) : null}
            {sortBy(convoActions, (x) => x.completedOn != null).map((x) => (
              <Row isComplete={x.completedOn != null}>
                <td>
                  <UserFlag user={x.assignedTo} />
                </td>
                <td>{x.text}</td>
                <td>
                  {x.dueDate == null
                    ? "No Due Date"
                    : format(fromUnixTime(x.dueDate), "do MMM yyyy")}
                </td>
                <td>
                  <Flex alignItems="center" justifyContent={"flex-end"}>
                    <IconButton
                      icon={FaCheck}
                      onClick={() =>
                        completeAction(
                          x.conversation.id,
                          x.id,
                          x.completedOn == null
                        )
                      }
                      title={"Complete action"}
                      aria-label={"Complete action"}
                      color={x.completedOn == null ? "body" : "positive"}
                      mr={2}
                    />
                    <IconButton
                      icon={FaTrash}
                      onClick={() => removeAction(x.id)}
                      color="danger"
                      title={"Delete action"}
                      aria-label={"Delete action"}
                    />
                  </Flex>
                </td>
              </Row>
            ))}
          </tbody>
        </Table>
        <Box p={3}>
          <AddButton onClick={() => setCreating(true)}>
            Add Action Item
          </AddButton>
        </Box>
      </Panel>
      <Panel>
        <PanelHeader>
          <Text fontWeight={600}>
            <Text as="span" color="primary" mr={3}>
              <FaBullhorn
                size="1.5em"
                style={{ top: 5, position: "relative" }}
              />
            </Text>
            Campaigns
          </Text>
        </PanelHeader>
        <Table>
          <thead>
            <th>Type</th>
            <th>From</th>
            <th>Action</th>
            <th>Due Date</th>
          </thead>
          <tbody>
            {campaignActions.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <Text color="grey2" textAlign={"center"}>
                    No Current Actions
                  </Text>
                </td>
              </tr>
            ) : null}
            {campaignActions
              .filter((x) => x.completedOn == null)
              .map((x) =>
                x.type === "survey" ? (
                  <SurveyActionRow item={x} showLinks={!isManager} />
                ) : x.type === "contfeedback" ? (
                  <ContFeedbackRow item={x} />
                ) : x.type === "providefeedback" ? (
                  <ProvideFeedbackRow item={x} showLinks={!isManager} />
                ) : x.type === "requestfeedback" ? (
                  <SendFeedbackRow item={x} showLinks={!isManager} />
                ) : x.type === "assessment" ? (
                  <AssessmentRow item={x} />
                ) : (
                  <MeasuresRow item={x} />
                )
              )}
          </tbody>
        </Table>
      </Panel>
    </>
  );
};
