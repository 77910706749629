import React from "react";
import { useUser } from "../../auth";
import { Box, Text } from "@coaching-culture/ui";
import styled from "styled-components";
import HomeGrid from "components/HomeGrid";

import feedbackIcon from "../../img/feedback.svg";
import peopleOrgIcon from "../../img/peopleorg.svg";
import settingsIcon from "../../img/settings.svg";
import insightsIcon from "../../img/insights.svg";
import surveysIcon from "../../img/surveys.svg";
import performanceIcon from "../../img/performance.svg";
import { ProductIdent, UserPermission } from "@coaching-culture/types";

const MainContainer = styled.div`
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
`;

type Item = {
  name: string;
  content: string;
  cta: string;
  url: string;
  icon: string;
  permission?: UserPermission;
  product?: ProductIdent;
};

const items: Item[] = [
  {
    name: "User Management",
    content: "Add/Remove Users",
    cta: "Start",
    url: "/success/people/users",
    icon: peopleOrgIcon,
  },
  {
    name: "Content",
    content: "Configure Content Access.",
    cta: "Start",
    permission: "org-admin",
    url: "/success/settings/content/mindset",
    icon: settingsIcon,
  },
  {
    name: "Insights",
    content: "Understand your data.",
    cta: "Start",
    permission: "org-admin",
    url: "/success/insights",
    icon: insightsIcon,
  },
  {
    name: "Feedback Campaigns",
    content: "Get feedback.",
    cta: "Start",
    permission: "org-admin",
    url: "/success/feedback",
    product: "360",
    icon: feedbackIcon,
  },
  {
    name: "Surveys",
    content: "Send Surveys.",
    cta: "Start",
    permission: "org-admin",
    url: "/success/surveys",
    product: "surveys",
    icon: surveysIcon,
  },
  {
    name: "Performance",
    content: "Configure Performance.",
    cta: "Start",
    permission: "org-admin",
    url: "/success/performance",
    product: "performance",
    icon: performanceIcon,
  },
];

const Success = () => {
  const [user] = useUser();

  const filteredItems = items.filter((x) => {
    return x.permission == null || user.permissions.includes(x.permission);
  });

  return (
    <MainContainer>
      <Box p={[3, 5]} pt={4}>
        <Text fontSize={[4, 8, 8, 10]} fontWeight={600} mb={[3, 5]}>
          Hi {user.firstName}, let's make it
          <br /> a success!
        </Text>
        <HomeGrid items={filteredItems} />
      </Box>
    </MainContainer>
  );
};

export default Success;
