import { Button, Flex } from "@coaching-culture/ui";
import styled from "styled-components";

const Frame = styled.iframe`
  height: 80vh;
`;

type PdfIFrameProps = {
  path: string;
  onComplete: () => void;
};

export const PdfIFrame = ({ path, onComplete }: PdfIFrameProps) => {
  return (
    <Flex flexDirection="column">
      <Frame src={path} />
      <Flex p={3} borderTop={1} justifyContent={"center"}>
        <Button onClick={onComplete}>Done</Button>
      </Flex>
    </Flex>
  );
};
