import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { NewTodoSpec, TodoListItem } from "@coaching-culture/types";
import { useUser } from "auth";

export type TodoListReturnType = {
  list: TodoListItem[];
  addItem: (spec: NewTodoSpec) => Promise<boolean>;
  loading: boolean;
};

export const TodoListContext = createContext<TodoListReturnType>(null!);

export const TodoListProvider = ({ children }) => {
  const [user] = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<TodoListItem[]>([]);

  const invalidate = useCallback(() => {
    if (user == null) {
      setItems([]);
      return;
    }
    setLoading(true);
    axios.get("/api/todos").then(({ data }) => {
      setItems(data);
      setLoading(false);
    });
  }, [user]);

  const addItem = useCallback(
    async (spec: NewTodoSpec) => {
      setLoading(true);
      try {
        await axios.post("/api/todos", spec).then(() => {
          invalidate();
        });
        return true;
      } catch {
        return false;
      }
    },
    [invalidate]
  );

  useEffect(() => {
    invalidate();
  }, [invalidate]);

  const res = {
    loading,
    addItem,
    list: items,
  };

  return (
    <TodoListContext.Provider value={res}>{children}</TodoListContext.Provider>
  );
};

export const useTodoList = () => useContext(TodoListContext);
