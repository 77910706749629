import { Button, Circle, Flex, Panel, Text } from "@coaching-culture/ui";
import { ContinuousFeedbackTable } from "pages/Solutions/ContinuousFeedback/MyContinuousFeedback";
import { FaArrowLeft, FaArrowRight, FaEnvelopeOpen } from "react-icons/fa";
import styled from "styled-components";
import { grid, GridProps } from "styled-system";

const Widget = styled(Panel)<GridProps>`
  ${grid};
`;
export const FeedbackWidget = () => {
  return (
    <Widget
      gridColumn={["span 1", "span 1", "span 1", "span 2"]}
      width={"100%"}
    >
      <Flex borderBottom={1} height={83} justifyContent={"space-between"} p={4}>
        <Flex alignItems="center">
          <Circle
            icon={FaEnvelopeOpen}
            size={"medium"}
            color="primary"
            mr={2}
          />
          <Text
            fontSize={4}
            fontWeight={600}
            lineHeight={1}
            mr={1}
            color="black"
            as="h1"
          >
            Continuous Feedback
          </Text>
        </Flex>
        <Flex alignItems="end" justifyContent="center" flexDirection="row">
          <Button
            to="/solutions/continuous-feedback/send"
            color="primary"
            icon={FaArrowRight}
          >
            Send Feedback
          </Button>

          <Button
            ml={2}
            to="/solutions/continuous-feedback/request"
            color="primary"
            icon={FaArrowLeft}
          >
            Request Feedback
          </Button>
        </Flex>
      </Flex>

      <ContinuousFeedbackTable />
    </Widget>
  );
};
