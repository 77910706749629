import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const Table = styled.table<SpaceProps>`
  width: 100%;
  border-collapse: collapse;

  & th {
    font-weight: 600;
    text-align: left;
    color: ${(props) => props.theme.colors.body};
    font-size: 14px;
    border-bottom: 2px solid ${(props) => props.theme.colors.grey4};
  }

  & td,
  & th {
    padding: 8px 12px;
  }

  & tr:hover td {
    background-color: #fafafa;
  }

  & tbody td {
    border-bottom: 1px solid #ddd;
  }

  & tfoot td {
    font-weight: 600;
  }

  & td > * {
    vertical-align: middle;
  }

  ${space};
`;

export default Table;
