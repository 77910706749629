import React from "react";
import { IconType } from "react-icons";
import styled from "styled-components";
import Circle from "./Circle";
import Flex from "./Flex";
import Text from "./Text";

const Item = styled.button<{ active: boolean }>`
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.grey3};
  border-radius: 9px;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  padding: 12px 18px;
  text-align: left;
  justify-content: space-between;
  transition: all 0.3s ease;
`;

const Pip = styled.div<{ active: boolean }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.grey3};

  &:after {
    width: 16px;
    height: 16px;
    display: block;
    content: "";
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: ${(props) =>
      props.active ? props.theme.colors.primary : "white"};
  }
`;

export type SelectButtonProps = {
  icon: IconType;
  name: string;
  disabled?: boolean;
  active?: boolean;
  onSelect?: () => void;
};

export const SelectButton = ({
  icon: Icon,
  name,
  disabled,
  active = false,
  onSelect = () => {},
}: SelectButtonProps) => {
  return (
    <Item active={active} onClick={onSelect} type="button">
      <Flex alignItems={"center"}>
        <Circle icon={Icon} size="medium" color="primary" />
        <Text fontWeight={600} ml={3} color={disabled ? "grey3" : "body"}>
          {name} {disabled && "(Coming soon)"}
        </Text>
      </Flex>
      <Pip active={active} />
    </Item>
  );
};
