import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Loader,
  Text,
  TextArea,
} from "@coaching-culture/ui";
import {
  FormModel,
  FreeTextQuestionFormItem,
  HeadingFormItem,
  MultipleChoiceQuestionFormItem,
} from "@coaching-culture/types";

const FormHeading = ({ item }: { item: HeadingFormItem }) => (
  <Text mb={3} fontSize={4} fontWeight={600} pt={2} as="h2">
    {item.content}
  </Text>
);

type FreeTextQuestionProps = {
  item: FreeTextQuestionFormItem;
  setAnswer: (val: string) => void;
  answer: string;
  questionNumber: number;
};

const FreeTextQuestion = ({
  item,
  setAnswer,
  answer,
  questionNumber,
}: FreeTextQuestionProps) => {
  useEffect(() => {
    if (answer == null) {
      setAnswer("");
    }
  }, [answer, setAnswer]);

  return (
    <Box mb={4}>
      <Text
        fontWeight={600}
        mb={1}
        color="#999"
        style={{ letterSpacing: "0.05em" }}
      >
        QUESTION {questionNumber}:
      </Text>
      <Text mb={2}>{item.content}</Text>
      <TextArea
        value={answer}
        onChange={(ev) => setAnswer(ev.target.value)}
        height={200}
      />
    </Box>
  );
};

type MultipleChoiceQuestionProps = {
  item: MultipleChoiceQuestionFormItem;
  selectedAnswer: string | null;
  questionNumber: number;
  setAnswer: (val: string) => void;
  color?: string;
};

const MultipleChoiceQuestion = ({
  item,
  selectedAnswer,
  questionNumber,
  setAnswer,
  color = "primary",
}: MultipleChoiceQuestionProps) => (
  <Box mb={4}>
    <Text
      fontWeight={600}
      mb={1}
      color="#999"
      style={{ letterSpacing: "0.05em" }}
    >
      QUESTION {questionNumber}:
    </Text>
    <Text mb={2}>{item.content}</Text>
    <Flex flexDirection={["column", "row"]}>
      {item.answers.map((x, i, self) => (
        <Button
          key={x.id}
          color={selectedAnswer === x.id ? color : "body"}
          active={selectedAnswer === x.id}
          onClick={() => setAnswer(x.id)}
          style={{ flex: 1 }}
          mb={[1, 0]}
          mr={[0, i < self.length - 1 ? 2 : 0]}
        >
          {x.content}
        </Button>
      ))}
    </Flex>
  </Box>
);

export type FormCompletionItem = {
  id: string;
  answer: string | number;
};

type FormPlayerProps = {
  formId: string;
  onCompletion: (values: FormCompletionItem[]) => void;
  color?: string;
};

export default function FormPlayer({
  formId,
  onCompletion,
  color = "primary",
}: FormPlayerProps) {
  const [form, setForm] = useState<FormModel | null>(null);
  const [answers, setAnswers] = useState<
    Record<number, string | number | null>
  >({});

  useEffect(() => {
    axios.get(`/api/forms/${formId}`).then(({ data }) => {
      setForm(data);
      setAnswers({});
    });
  }, [formId]);

  if (form == null) {
    return <Loader />;
  }

  const allAnswered = () => {
    return form.items
      .filter((x) => x.type === "multiple_choice")
      .every((q) => answers[q.id] != null);
  };

  const getAnswerForQuestion = (qId: string) => {
    return answers[qId];
  };

  const setAnswerForQuestion = (qId: string, answer: string | number) => {
    setAnswers((old) => ({
      ...old,
      [qId]: answer,
    }));
  };

  let qNum = 1;

  return (
    <div>
      <Box mb={6}>
        {form.items.map((x) => {
          if (x.type === "heading") {
            return <FormHeading item={x} key={x.id} />;
          } else if (x.type === "multiple_choice") {
            return (
              <MultipleChoiceQuestion
                key={x.id}
                item={x}
                color={color}
                questionNumber={qNum++}
                selectedAnswer={getAnswerForQuestion(x.id)}
                setAnswer={(answerId) => setAnswerForQuestion(x.id, answerId)}
              />
            );
          } else if (x.type === "free_text") {
            return (
              <FreeTextQuestion
                key={x.id}
                item={x}
                questionNumber={qNum++}
                answer={getAnswerForQuestion(x.id) as string}
                setAnswer={(text) => setAnswerForQuestion(x.id, text)}
              />
            );
          } else {
            return null;
          }
        })}
      </Box>
      <Flex justifyContent="center">
        <Button
          large
          color={color}
          onClick={() =>
            onCompletion(
              Object.entries(answers).map(([qid, aid]) => ({
                id: qid,
                answer: aid,
              })),
            )
          }
          disabled={!allAnswered()}
        >
          Submit
        </Button>
      </Flex>
    </div>
  );
}
