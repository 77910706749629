import {
  ModulePostAssessment,
  ModuleProfile,
  ScoreSet,
} from "@coaching-culture/types";
import {
  BarChart,
  Box,
  Button,
  Flex,
  RawContent,
  StreamCollection,
} from "@coaching-culture/ui";
import Vimeo from "@u-wave/react-vimeo";
import SectionNav from "components/SectionNav";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

export const ProfileBarChart = ({
  color,
  scores,
}: {
  color: string;
  scores: ScoreSet;
}) => {
  const data: StreamCollection[] = scores.groups.map((x) => ({
    label: x.name,
    streams: [{ label: "", value: x.score }],
  }));

  return (
    <BarChart xAxis="" yAxis="" streamLabels={[""]} color={color} data={data} />
  );
};

export type ProfilePlayerProps = {
  color: string;
  profiles: ModuleProfile[];
  postAssessment: ModulePostAssessment;
  scores: ScoreSet;
  onComplete: () => void;
};

export function ProfilePlayer({
  color,
  profiles,
  postAssessment,
  scores,
  onComplete,
}: ProfilePlayerProps) {
  const [stage, setStage] = useState<number>(0);
  const [vidOrTrans, setVidOrTrans] = useState<number>(0);

  const advance = () => {
    if (stage === 0) {
      setStage(1);
    } else {
      onComplete();
    }
  };

  return (
    <div>
      <Box mb={3}>
        {stage === 0 ? (
          profiles.map((x, i) => (
            <Box mb={2} key={i}>
              {x.showAssessmentResults === "form-groups" && (
                <Box p={3} pb={0} maxWidth={1000}>
                  <ProfileBarChart scores={scores} color={color} />
                </Box>
              )}
              <RawContent>
                <ReactMarkdown children={x.content} />
              </RawContent>
            </Box>
          ))
        ) : (
          <>
            <SectionNav
              colors={[color, color]}
              links={["Video", "Transcript"]}
              active={vidOrTrans}
              onSelect={(idx) => setVidOrTrans(idx)}
              mb={3}
            />
            {vidOrTrans === 0 ? (
              <Flex alignItems="center" justifyContent="center">
                <Vimeo
                  video={postAssessment.videoUrl}
                  autoplay={true}
                  width={Math.min(800, window.innerWidth - 64)}
                />
              </Flex>
            ) : (
              <Flex>
                <RawContent>
                  <ReactMarkdown children={postAssessment.transcript} />
                </RawContent>
              </Flex>
            )}
          </>
        )}
      </Box>
      <Flex justifyContent="center">
        <Button onClick={advance}>Continue</Button>
      </Flex>
    </div>
  );
}
