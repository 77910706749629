import {
  FreeTextQuestionFormItemResponses,
  UserDisplay,
} from "@coaching-culture/types";
import {
  Box,
  Flex,
  Label,
  PanelHeader,
  PanelInset,
  Text,
  usePagination,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";

const ResponseContainer = styled(Flex)`
  position: relative;
  page-break-inside: avoid;
`;

export type FreeTextInsightsProps = {
  item: FreeTextQuestionFormItemResponses;
  user: UserDisplay;
  showResponses: boolean;
  paginated: boolean;
};

export function FreeTextInsights({
  item,
  user,
  showResponses,
  paginated,
}: FreeTextInsightsProps) {
  const [currentUser] = useUser();

  const userText = currentUser?.id === user?.id ? "Your" : "User's";
  const yourAnswer = item.responses.find((x) => x.email === user?.email);

  const responses = item.responses;

  const {
    data: pagedResponses,
    pageCount,
    component: Pagination,
  } = usePagination(responses, paginated ? 8 : 99999999, 1);

  const responseCount = responses.filter((x) => x.email !== user?.email).length;

  return (
    <PanelInset mb={3}>
      <PanelHeader>
        <FaQuestionCircle />
        <Text fontWeight={600} ml={2}>
          {item.content}
        </Text>
      </PanelHeader>
      <PanelHeader>
        <Flex justifyContent="space-evenly" width="100%">
          <Flex alignItems="center">
            <Text mr={1}>Responses:</Text>
            <Text as="span" fontSize={5} color="positive" fontWeight={600}>
              {responseCount}
            </Text>
          </Flex>
        </Flex>
      </PanelHeader>
      {user != null && (
        <Flex flexDirection="column" p={3}>
          <Box mb={2}>
            <Text fontWeight={600} color="grey2" mb={1}>
              {userText} Answer:
            </Text>
            <Text>{yourAnswer?.content ?? "<None Given>"}</Text>
          </Box>
        </Flex>
      )}
      {showResponses && responses.length > 0 && (
        <Flex flexDirection="column" p={3} style={{ position: "relative" }}>
          <Text fontSize={4} fontWeight={600} mb={3}>
            Responses:
          </Text>
          {pagedResponses.map((x, i) => (
            <ResponseContainer
              flexDirection="column"
              mb={i === item.responses.length - 1 ? 0 : 3}
            >
              <Flex alignItems="center" mb={1}>
                <Text fontWeight={600}>Respondent: {x.name} </Text>
                {x.email === user?.email ? (
                  <Label size="small" ml={2}>
                    {userText} Response
                  </Label>
                ) : null}
              </Flex>
              <Text>{x.content ?? "<None Given>"}</Text>
            </ResponseContainer>
          ))}
          {pageCount > 1 && (
            <Box p={2}>
              <Pagination />
            </Box>
          )}
        </Flex>
      )}
    </PanelInset>
  );
}
