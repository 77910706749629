import { range } from "lodash";
import { FaStar } from "react-icons/fa";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { Text } from "@coaching-culture/ui";

type StarRatingProps = SpaceProps & {
  value: number | null;
  size?: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${space};
`;

const Star = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
`;

const NoDataContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StarRating = ({ value, size, ...rest }: StarRatingProps) => {
  return (
    <Container {...rest}>
      {range(1, 6).map((x) => (
        <Star
          style={{
            color: value == null ? "#efefef" : value >= x ? "#F7CA00" : "#ccc",
          }}
          key={x}
        >
          <FaStar size={size ?? "1em"} />
        </Star>
      ))}
      {value == null && (
        <NoDataContainer>
          <Text fontWeight={600} color="grey1">
            No Data
          </Text>
        </NoDataContainer>
      )}
    </Container>
  );
};

export default StarRating;
