import {
  ContentCompletionReport,
  ContentType,
  UserField,
  UserFieldType,
  UserFilter,
  UserReportItem,
} from "@coaching-culture/types";
import {
  Box,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
} from "@coaching-culture/ui";
import axios from "axios";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import { uniq } from "lodash";
import Papa from "papaparse";
import qsStringify from "qs-stringify";
import { useUserFields } from "queries/users";
import { useMemo } from "react";
import { FaBan, FaMedal, FaStopwatch, FaTimes } from "react-icons/fa";
import { useQuery } from "react-query";
import styled from "styled-components";
import { downloadContent } from "utils";
import { ReportProps, ReportSelector } from "./ReportSelector";

const ModuleImage = styled.img`
  max-width: 30px;
  max-height: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

type MatrixContent = {
  id: string;
  name: string;
  icon: string;
  slug: string;
};

const getMatrixCols = (
  content: MatrixContent[],
): SortableTableColumn<ContentCompletionReport>[] => [
  {
    label: "Name",
    sortFormat: (x) => `${x.firstName} ${x.lastName}`.toLowerCase(),
    format: (x) => <UserFlag user={x} to={`/people/${x.id}`} />,
  },
  ...content.map<SortableTableColumn<ContentCompletionReport>>((x) => ({
    name: x.slug,
    label: (
      <ModuleImage
        src={process.env.REACT_APP_STATIC_ROOT + "/" + x.icon}
        title={x.name}
      />
    ),
    props: {
      style: {
        textAlign: "center",
      },
    },
    sortFormat: (item) => {
      const s = item.content.find((m) => x.id === m.id)?.completion;
      return s;
    },
    format: (item) => {
      const c = item.content.find((m) => x.id === m.id);
      const s = c?.completion;
      const completionDate =
        c?.completionDate == null
          ? null
          : format(fromUnixTime(c.completionDate), "yyyy-MM-dd");

      if (s === "completed") {
        return (
          <FaMedal color="green" title={`Completed on ${completionDate}`} />
        );
      } else if (s === "not-started") {
        return <FaTimes title="Not Started" color="red" />;
      } else if (s === "not-available") {
        return <FaBan title="Not Available" color="#ccc" />;
      } else {
        return <FaStopwatch title="Started" color="orange" />;
      }
    },
  })),

  {
    name: "notstarted",
    label: "Not Started",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
    format: (x) =>
      x.content
        .filter((x) => content.map((x) => x.id).includes(x.id))
        .filter((x) => x.completion === "not-started").length,
    sortFormat: (x) =>
      x.content
        .filter((x) => content.map((x) => x.id).includes(x.id))
        .filter((x) => x.completion === "not-started").length,
  },
  {
    name: "started",
    label: "Started",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
    format: (x) =>
      x.content
        .filter((x) => content.map((x) => x.id).includes(x.id))
        .filter((x) => x.completion === "started").length,
    sortFormat: (x) =>
      x.content
        .filter((x) => content.map((x) => x.id).includes(x.id))
        .filter((x) => x.completion === "started").length,
  },
  {
    name: "completed",
    label: "Completed",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
    format: (x) =>
      x.content
        .filter((x) => content.map((x) => x.id).includes(x.id))
        .filter((x) => x.completion === "completed").length,
    sortFormat: (x) =>
      x.content
        .filter((x) => content.map((x) => x.id).includes(x.id))
        .filter((x) => x.completion === "completed").length,
  },
  {
    name: "completion",
    label: "% Completed",
    headingProps: { textAlign: "right" },
    props: { textAlign: "right" },
    format: (x) =>
      Math.floor(
        (x.content
          .filter((x) => content.map((x) => x.id).includes(x.id))
          .filter((x) => x.completion === "completed").length /
          content.length) *
          100,
      ) + "%",
    sortFormat: (x) =>
      Math.floor(
        (x.content
          .filter((x) => content.map((x) => x.id).includes(x.id))
          .filter((x) => x.completion === "completed").length /
          content.length) *
          100,
      ),
  },
];

type MatrixReportProps = ReportProps & {
  content: MatrixContent[];
  filter: UserFilter;
  type: ContentType;
};

const matrixSearch = (item: ContentCompletionReport) =>
  [item.email, item.firstName, item.lastName].join(" ");

const useCompletionReport = ({
  filter,
  type,
}: {
  filter: UserFilter;
  type: ContentType;
}) => {
  return useQuery(["completionReport", type, filter], () => {
    const filterString = qsStringify({ filter } as any);
    const t = type === "module" ? "mindset" : type;
    const url = `/api/insights/${t}/matrix?${filterString}`;
    return axios.get<ContentCompletionReport[]>(url).then(({ data }) => data);
  });
};

const downloadMatrixReport = (
  data: ContentCompletionReport[],
  userFields: UserField[],
  content: MatrixContent[],
) => {
  const extractGroups = (input: UserReportItem[]) => {
    const groups = uniq(input.flatMap((x) => x.groups).map((x) => x.name));

    return (item: UserReportItem) => {
      return Object.fromEntries(
        groups.map((x, i) => [
          `group${i + 1}`,
          item.groups.some((g) => g.name === x) ? x : "",
        ]),
      );
    };
  };

  const groupExtractor = extractGroups(data);

  const report = data.map((x) => ({
    email: x.email,
    firstName: x.firstName,
    lastName: x.lastName,
    ...groupExtractor(x),
    ...Object.fromEntries(
      userFields.map((uf) => {
        const v = x.userFields.find((xx) => uf.id === xx.id)?.value;

        return [
          uf.name,
          uf.type === UserFieldType.Select
            ? uf.options.find((x) => x.id === v)?.name
            : v,
        ];
      }),
    ),
    ...Object.fromEntries(
      content.map((l) => [
        l.name,
        x.content.find((m) => m.id === l.id)?.completion,
      ]),
    ),
    "Not Started": x.content
      .filter((x) => content.map((x) => x.id).includes(x.id))
      .filter((x) => x.completion === "not-started").length,
    Started: x.content
      .filter((x) => content.map((x) => x.id).includes(x.id))
      .filter((x) => x.completion === "started").length,
    Completed: x.content
      .filter((x) => content.map((x) => x.id).includes(x.id))
      .filter((x) => x.completion === "completed").length,
    "Completed %":
      Math.floor(
        (x.content
          .filter((x) => content.map((x) => x.id).includes(x.id))
          .filter((x) => x.completion === "completed").length /
          content.length) *
          100,
      ) + "%",
  }));

  const csv = Papa.unparse(report, { delimiter: ",", header: true });
  downloadContent(csv, "report.csv");
};

export const MatrixReport = ({
  content,
  filter,
  type,
  ...rest
}: MatrixReportProps) => {
  const { data = [], isFetched } = useCompletionReport({ filter, type });
  const { data: userFields = [] } = useUserFields();

  const cols = useMemo(() => getMatrixCols(content), [content]);

  console.log(cols);

  const handleExport = () => {
    downloadMatrixReport(data, userFields, content);
  };

  return (
    <>
      <ReportSelector
        {...rest}
        filter={filter}
        onExport={handleExport}
        showFilterButton={type === "assessment"}
      />
      <Panel>
        <Box style={{ overflowX: "auto", position: "relative" }}>
          {!isFetched && <Loader overlay />}
          <SortableTable<ContentCompletionReport>
            key="MatrixTable"
            data={data}
            columns={cols}
            rowSearch={matrixSearch}
            emptyMessage="No Data Available"
            paged
          />
        </Box>
      </Panel>
    </>
  );
};
