import { DirectReportDto } from "@coaching-culture/types";
import {
  Button,
  Circle,
  DatePill,
  DotMenu,
  Flex,
  Panel,
  Text,
  UserProfileImage,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import { useDeleteRelationship } from "queries/team";
import { IconType } from "react-icons";
import {
  FaBan,
  FaBullseye,
  FaCalendarAlt,
  FaClipboard,
  FaCommentDots,
  FaEnvelope,
  FaEye,
  FaHourglass,
  FaHourglassHalf,
  FaRegChartBar,
  FaTrash,
} from "react-icons/fa";
import { useHistory } from "react-router";
import { useMeasure } from "react-use";
import styled from "styled-components";
import { MeasuresQuickChart } from "./MeasuresQuickChart";
import {
  conversationsStatus,
  daysSinceFeedbackFromAnyone,
  daysSinceFeedbackFromMe,
  daysSinceLastConvo,
  daysToNextConvo,
  feedbackStatus,
  goalsBlocked,
  goalsSet,
  goalsStatus,
  measuresLastReviewed,
  measuresStatus,
  nextConvo,
  overdueGoals,
} from "./metrics";

const Header = styled.div<{ pillColor: string }>`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
  border-top: 1px solid ${(props) => props.theme.colors.grey5};
  background-color: ${(props) => props.theme.colors.grey6};
  width: 100%;
  justify-content: flex-start;
  padding: 0px 12px;
  align-items: center;
  font-weight: 600;
  height: 30px;
  position: relative;
  color: #222;

  & svg {
    margin-right: 6px;
    fill: ${(props) => props.theme.colors.primary};
  }

  &:after {
    position: absolute;
    right: 12px;
    height: 12px;
    width: 36px;
    content: "";
    display: block;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors[props.pillColor]};
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  min-height: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 140px;

  &:after {
    position: absolute;
    content: "";
    display: block;
    right: 0;
    height: 40px;
    top: calc(50% - 20px);
    width: 2px;
    background-color: ${(props) => props.theme.colors.grey5};
  }

  &:last-child:after {
    display: none;
  }
`;

type BlockContentProps = {
  icon: IconType;
  count: number | null;
  title: string;
};

const BlockContent = ({ icon: Icon, count, title }: BlockContentProps) => (
  <>
    <Flex alignItems="center">
      <Icon fontSize="1.3em" />
      <Text fontSize={5} fontWeight={600} ml={1}>
        {count ?? "-"}
      </Text>
    </Flex>
    <Text fontSize={2} textAlign="center" lineHeight={1.2}>
      {title}
    </Text>
  </>
);

export type TeamViewCardProps = {
  user: DirectReportDto;
  onSelect: () => void;
  onViewPrivateNotes: () => void;
};

export const TeamViewCard = ({
  user,
  onSelect,
  onViewPrivateNotes,
}: TeamViewCardProps) => {
  const [me] = useUser();
  const terms = useGoalTerminology();
  const delRel = useDeleteRelationship();
  const history = useHistory();
  const [ref, { width }] = useMeasure();

  const isConfirmed =
    user.relationship.toAccepted != null &&
    user.relationship.fromAccepted != null;

  const onItemClick = (idx: number, cmd: string) => {
    if (cmd === "del") {
      if (
        window.confirm("Are you sure you want to remove this relationship?")
      ) {
        delRel.mutateAsync(user.relationship.id);
      }
    } else if (cmd === "profile") {
      history.push(`/solutions/my-team/${user.id}`);
    } else if (cmd === "notes") {
      onViewPrivateNotes();
    }
  };

  return (
    <Panel
      p={0}
      ref={ref}
      onClick={isConfirmed ? onSelect : undefined}
      style={
        isConfirmed
          ? {
              cursor: "pointer",
              userSelect: "none",
            }
          : {}
      }
    >
      <Flex p={3} alignItems="center" borderBottom={1}>
        <UserProfileImage
          profileImage={user.profileImage}
          name={user.name}
          size={100}
          mr={3}
        />
        <Flex flex={1} flexDirection="column">
          <Text
            fontWeight={600}
            fontSize={4}
            style={{
              width: width - 200,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {user.name}
          </Text>
          <Text
            color="grey2"
            style={{
              width: width - 200,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {user.jobTitle ?? user.email}
          </Text>
        </Flex>
        {isConfirmed && (
          <DotMenu
            buttonStyle="filled"
            color="primary"
            items={[
              {
                icon: FaEye,
                label: "View Profile",
                cmd: "profile",
              },
              {
                icon: FaClipboard,
                label: "View Private Notes",
                cmd: "notes",
              },
              {
                icon: FaTrash,
                color: "danger",
                label: "Delete Relationship",
                cmd: "del",
              },
            ]}
            onItemClick={onItemClick}
          />
        )}
      </Flex>
      {!isConfirmed ? (
        <UnconfirmedRelationship
          onDelete={() => delRel.mutateAsync(user.relationship.id)}
        />
      ) : (
        <>
          <Flex flexDirection={"column"}>
            <Header pillColor={goalsStatus(user)}>
              <FaBullseye /> {terms.goal.asPluralTitle()}
            </Header>
            <Flex>
              <Block>
                <BlockContent
                  icon={FaBullseye}
                  count={goalsSet(user)}
                  title={`${terms.goal.asPluralBody()} set`}
                />
              </Block>
              <Block>
                <BlockContent
                  icon={FaBan}
                  count={goalsBlocked(user)}
                  title={`blocked ${terms.goal.asPluralBody()}`}
                />
              </Block>
              <Block>
                <BlockContent
                  icon={FaCalendarAlt}
                  count={overdueGoals(user)}
                  title={`overdue ${terms.goal.asPluralBody()}`}
                />
              </Block>
            </Flex>
          </Flex>
          <Flex flexDirection={"column"}>
            <Header pillColor={conversationsStatus(user)}>
              <FaCommentDots /> Conversations
            </Header>
            <Flex>
              <Block>
                <BlockContent
                  icon={FaHourglass}
                  count={daysSinceLastConvo(user)}
                  title="days since last conversation"
                />
              </Block>
              <Block style={{ flex: 1.2, maxWidth: "unset" }}>
                <Flex alignItems="center" mb={1} p={1}>
                  <DatePill
                    value={nextConvo(user)?.scheduledDate}
                    color={nextConvo(user) == null ? "danger" : "positive"}
                  />
                </Flex>
                <Text fontSize={2} textAlign="center" lineHeight={1.2}>
                  next conversation
                </Text>
              </Block>
              <Block>
                <BlockContent
                  icon={FaCalendarAlt}
                  count={daysToNextConvo(user)}
                  title="days until next conversation"
                />
              </Block>
            </Flex>
          </Flex>
          <Flex flexDirection={"column"}>
            <Header pillColor={feedbackStatus(user)}>
              <FaEnvelope /> Feedback
            </Header>
            <Flex justifyContent={"center"}>
              <Block>
                <BlockContent
                  icon={FaHourglassHalf}
                  count={daysSinceFeedbackFromMe(user, me.id)}
                  title="days since feedback from me"
                />
              </Block>
              <Block>
                <BlockContent
                  icon={FaHourglassHalf}
                  count={daysSinceFeedbackFromAnyone(user)}
                  title="days since feedback from anyone"
                />
              </Block>
            </Flex>
          </Flex>
          <Flex flexDirection={"column"}>
            <Header pillColor={measuresStatus(user)}>
              <FaRegChartBar /> Measures
            </Header>
            <Flex p={3}>
              <Flex flex={1}>
                <MeasuresQuickChart user={user} />
              </Flex>
              <Flex
                flexDirection={"column"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontSize={2} mb={1} fontWeight={600}>
                  Last reviewed:
                </Text>
                <DatePill
                  value={measuresLastReviewed(user)}
                  color={measuresStatus(user)}
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Panel>
  );
};

type UnconfirmedRelationshipProps = {
  onDelete: () => void;
};

const UnconfirmedRelationship = ({
  onDelete,
}: UnconfirmedRelationshipProps) => {
  const del = () => {
    if (window.confirm("Are you sure?")) {
      onDelete();
    }
  };
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection="column"
      p={5}
      pt={5}
      pb={7}
      height={"100%"}
    >
      <Circle color="grey3" icon={FaHourglassHalf} size="xlarge" mb={2} />
      <Text fontSize={4} fontWeight="600" color="grey2">
        Unconfirmed Relationship
      </Text>
      <Text textAlign={"center"} mb={5} color="grey2">
        Waiting for the other user to accept the relationship
      </Text>
      <Button onClick={del} color="danger" icon={FaTrash}>
        Delete Relationship
      </Button>
    </Flex>
  );
};
