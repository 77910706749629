import {
  Modal,
  Flex,
  Circle,
  Text,
  Button,
  FormTextArea,
  FormInput,
  CheckBox,
  FormInputWrap,
  Input,
} from "@coaching-culture/ui";
import { EmailEditor } from "pages/Success/Feedback/EmailEditor";
import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { addDays, startOfDay } from "date-fns";

export type ShareFormData = {
  recipients: string;
  subject: string;
  text: string;
  deadline: Date | null;
};

export type ShareFormProps = {
  onShare: (r: ShareFormData) => void;
  onCancel: () => void;
  defaultText: string;
  defaultSubject: string;
};

export const ShareForm = ({
  onShare,
  onCancel,
  defaultText,
  defaultSubject,
}: ShareFormProps) => {
  const [form, setForm] = useState<ShareFormData>({
    recipients: "",
    subject: defaultSubject,
    text: defaultText,
    deadline: null,
  });

  const onSubmit = () => {
    if (form.recipients === "") {
      window.alert("At least one recipient is required");
    } else {
      onShare(form);
    }
  };

  const setValue = (key: keyof ShareFormData) => (val: string) => {
    setForm((old) => ({
      ...old,
      [key]: val,
    }));
  };

  return (
    <Modal
      width={800}
      p={3}
      onClose={onCancel}
      showCloseButton
      style={{ overflow: "hidden" }}
    >
      <Flex alignItems="center" flexDirection="column" mb={3}>
        <Circle color="primary" icon={FaEnvelope} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Invite More Respondents
        </Text>
      </Flex>
      <FormTextArea
        height={150}
        name="recipients"
        label="Respondent emails (One per line)"
        value={form.recipients}
        onChange={(ev) => setValue("recipients")(ev.target.value)}
      />
      <CheckBox
        value={form.deadline != null}
        onChange={(ev) =>
          setValue("deadline")(
            (!ev ? null : addDays(startOfDay(new Date()), 7)) as any
          )
        }
        label="Include Deadline"
        mb={1}
      />
      <Text mb={2} fontSize={2}>
        This will send a short reminder email just before the deadline.
        Recipients will still be able to fill out the survey after the deadline
        has passed.
      </Text>
      {form.deadline != null && (
        <FormInputWrap label="Deadline">
          <DatePicker
            selected={form.deadline}
            onChange={(date) => setValue("deadline")(date as any)}
            dateFormat="dd/MM/yyyy"
            customInput={<Input />}
            minDate={new Date()}
          />
        </FormInputWrap>
      )}
      <FormInput
        name="subject"
        value={form.subject}
        onChange={(ev) => setValue("subject")(ev.target.value)}
        label="Subject"
      />
      <EmailEditor value={form.text} onChange={setValue("text")} />

      <Flex alignItems="center" justifyContent="center" p={3}>
        <Button type="button" color="primary" onClick={onSubmit}>
          Share
        </Button>
      </Flex>
    </Modal>
  );
};
