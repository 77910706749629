import { Box, RawContent } from "@coaching-culture/ui";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box p={3} pt={5} maxWidth="800px" ml="auto" mr="auto">
      <RawContent>
        <h1>Privacy Policy</h1>
        <p>
          We respect and value the privacy of everyone whose data we process and
          we will only collect and use Personal Data in ways that are described
          here, and in a manner that is consistent with our obligations and your
          rights under the law.
        </p>
        <p>
          The procedures and principles set out in this Policy will be followed
          at all times by us as a company, as well as our employees, agents,
          sub-contractors or other parties working on our behalf.
        </p>
        <p>
          Please read this Privacy Policy carefully and ensure that you
          understand it. Your acceptance of our Privacy Policy is deemed to
          occur upon your first use of our Software. If you do not accept and
          agree with this Privacy Policy, you must stop using our Software
          immediately.
        </p>
        <h2 id="definitions-and-interpretation-in-this-policy.">
          1. Definitions and Interpretation In this Policy.
        </h2>
        <p>The following terms have the following meanings:</p>
        <ul>
          <li>
            <strong>“Client”</strong> means any individual, firm or corporate
            body utilising our Software;
          </li>
          <li>
            <strong>“Client Personal Data”</strong> means the data inputted and
            documents uploaded to the Software by the Client or its authorised
            users;
          </li>
          <li>
            <strong>“Personal Data”</strong> means any and all data that relates
            to an identifiable person who can be directly or indirectly
            identified from that data. This definition shall, where applicable,
            incorporate the definitions provided in the General Data Protection
            Regulation 2016/679 (“GDPR”) and Data Protection Act 2018 (“DPA
            2018”);
          </li>
          <li>
            <strong>“Software”</strong> means the subscription based software
            provided by us;
          </li>
          <li>
            <strong>“User”</strong> means employees uploaded by the client to
            the software;{" "}
          </li>
          <li>
            <strong>“We/Us/Our”</strong> means Coaching Culture Limited a
            company registered in England and Wales under company number
            10855629 whose registered office address is at The Old Chapel, Old
            Cherry Lane, Lymm, Cheshire. WA13 0TA.
          </li>
        </ul>
        <h2 id="what-does-this-policy-cover">
          2. What Does This Policy Cover?
        </h2>
        <p>
          This Privacy Policy applies to our processing of Personal Data when
          you become a Client of ours and when Client Personal Data is uploaded
          to our Software.
        </p>
        <h2 id="rights-under-data-protection-laws">
          3. Rights under Data Protection Laws
        </h2>
        <ol>
          <li>
            All data subjects have the following rights under the data
            protection laws, which this Policy and our use of Personal Data have
            been designed to uphold:
            <ol>
              <li>
                The right to be informed about our collection and use of
                Personal Data;
              </li>
              <li>
                The right of access to the Personal Data we hold about you (see
                section 10);
              </li>
              <li>
                The right to rectification if any Personal Data we hold about
                you is inaccurate or incomplete (please contact us using the
                details in section 11);
              </li>
              <li>
                The right to be forgotten – i.e. the right to ask us to delete
                any Personal Data we hold about you (we only hold your Personal
                Data for a limited time, as explained in section 5 but if you
                would like us to delete it sooner, please contact us using the
                details in section 11);
              </li>
              <li>
                The right to restrict (i.e. prevent) the processing of your
                Personal Data;
              </li>
              <li>
                The right to data portability (obtaining a copy of your Personal
                Data to re-use with another service or organisation);
              </li>
              <li>
                The right to object to us using your Personal Data for
                particular purposes; and
              </li>
              <li>
                Rights with respect to automated decision making and profiling.
              </li>
            </ol>
          </li>
          <li>
            If you have any cause for complaint about our use of your Personal
            Data, please contact us using the details provided in section 11 and
            we will do our best to solve the problem for you. If we are unable
            to help, you also have the right to lodge a complaint with the UK’s
            supervisory authority, the Information Commissioner’s Office.
          </li>
          <li>
            For further information about your rights, please contact the
            Information Commissioner’s Office or your local Citizens’ Advice
            Bureau.
          </li>
        </ol>
        <h2 id="what-data-do-we-collect">4. What Data Do We Collect?</h2>
        <ol>
          <li>
            We collect and process the following Personal Data in order for us
            to provide our Software to you as a Client of ours:
            <ol>
              <li>Name;</li>
              <li>
                Contact information including email address, trading address,
                and telephone number;
              </li>
              <li>
                Billing address and contact information, if this is different
                from your trading address;
              </li>
              <li>
                Account log-in information, including all authorised users’
                usernames (please note we cannot access passwords and if
                necessary, these will need to be reset using the link within the
                Software);
              </li>
              <li>
                Client Personal Data relating to other data subjects that you
                may upload to the Software or otherwise make available to us
                (you will be advised when entering into a contract with us that
                you must first obtain the permission of any data subjects whose
                details you provide or make available to us and warrant that you
                have obtained that permission);
              </li>
              <li>
                Your IP address, web browser type and version and operating
                system.
              </li>
            </ol>
          </li>
          <li>
            We collect and process the following Personal Data for Users added
            to the software platform by the Client:
            <ol>
              <li>Name and email address</li>
              <li>
                User generated content in response to activities, exercises,
                challenges, feedback, questions, surveys, diagnostics, reports
                &amp; psychometrics
              </li>
            </ol>
          </li>
        </ol>
        <h2 id="how-do-we-use-your-data">5. How Do We Use Your Data?</h2>
        <ol>
          <li>
            As administrators of the Software, we (and those authorised by us)
            have access to the back-end of the Software and this allows us to
            view all Client Personal Data that has been inputted into the
            Software. We warrant that all such persons with access will only
            process Client Personal Data to the extent necessary for us to
            provide our support. In any case, it will not be used for any other
            purpose and will not be stored other than for the purposes of
            providing our services to you and for making back-ups.
          </li>
          <li>
            All Client Personal Data will be deleted from the Software within
            one year after and if subscription has ended to ensure any restart
            of the subscription service within this period allows for a smooth
            re-start unless otherwise agreed or requested. All other Personal
            Data will be retained for no longer than is necessary in light of
            the reason(s) for which it was first collected.
          </li>
          <li>
            Our use of your Personal Data will always have a lawful basis,
            either because it is necessary for our performance of a contract
            with you, because you have consented to our use of your Personal
            Data, or because it is in our legitimate interests. Specifically, we
            may use your data for the following purposes:
            <ol>
              <li>
                Supplying our Software and support to you (as set out above, we
                require your Personal Data in order to enter into a contract
                with you);
              </li>
              <li>Replying to emails from you; and</li>
              <li>
                Supplying you with emails that you have opted into (you may
                unsubscribe or opt-out at any time by emailing us directly).
              </li>
            </ol>
          </li>
          <li>
            Any Personal Data inputted for profiling purposes is collected to
            enable us fulfil our obligations to you and provide the relevant
            information needed to ensure you get the most out of your use of the
            Software.
          </li>
          <li>
            With your permission and/or where permitted by law, we may also use
            your data for marketing purposes. We will not, however, send you any
            unsolicited marketing or spam and will take all reasonable steps to
            ensure that we fully protect your rights and comply with our
            obligations under GDPR, DPA 2018 and the Privacy and Electronic
            Communications (EC Directive) Regulations 2003.
          </li>
          <li>
            You have the right to withdraw your consent to us using your
            Personal Data at any time, and to request that we delete it. Unless
            we have reasonable grounds to refuse to delete the Personal Data, we
            will comply with all requests for deletion within one month of
            receipt of your request, and will keep you informed of progress.
          </li>
        </ol>
        <h2 id="how-and-where-do-we-store-your-data">
          6. How and Where Do We Store Your Data?
        </h2>
        <ol>
          <li>
            We only keep your Personal Data for as long as we need to in order
            to use it as described above in sections 4 and 5, and/or for as long
            as we have your permission to keep it.
          </li>
          <li>
            We will only store your data in the UK, however, third party
            processors we use may store data elsewhere, as set out in section 7.
          </li>
          <li>
            Data security is very important to us and to protect your data, we
            have taken suitable measures to safeguard and secure data collected.
          </li>
        </ol>
        <h2 id="do-we-share-your-data">7. Do We Share Your Data?</h2>
        <ol>
          <li>
            We may sometimes contract with other third parties to supply
            services to you on our behalf. In some cases, these third parties
            may require access to some or all of your data. Where any of your
            data is required for such a purpose, we will take all reasonable
            steps to ensure that your data will be handled safely, securely and
            in accordance with your rights, our obligations and the obligations
            of the third party under the law.
          </li>
          <li>
            We may sometimes use third party data processors that are located
            outside of the European Economic Area (“the EEA”) (The EEA consists
            of all EU member states, plus Norway, Iceland, and Liechtenstein).
            Where we transfer any Personal Data outside the EEA, we will take
            all reasonable steps to ensure that your data is treated as safely
            and securely as it would be within the UK and under all data
            protection laws.
          </li>
          <li>
            In certain circumstances, we may be legally required to share
            certain data held by us, which may include your Personal Data, for
            example, where we are involved in legal proceedings, where we are
            complying with legal requirements, a court order or a governmental
            authority.
          </li>
        </ol>
        <h2 id="what-happens-if-our-business-changes-hands">
          8. What Happens If Our Business Changes Hands?
        </h2>
        <ol>
          <li>
            We may, from time to time, expand or reduce our business and this
            may involve the sale and/or the transfer of control of all or part
            of our business. Any Personal Data that you have provided will,
            where it is relevant to any part of our business that is being
            transferred, be transferred along with that part and the new owner
            or newly controlling party will, under the terms of this Privacy
            Policy, be permitted to use that data only for the same purposes for
            which it was originally collected by us.
          </li>
          <li>
            In the event that any of your data is to be transferred in such a
            manner, you will be contacted in advance and informed of the
            changes.
          </li>
        </ol>
        <h2 id="how-can-you-control-your-data">
          9. How Can You Control Your Data?
        </h2>
        <ol>
          <li>
            In addition to your rights, set out in section 3, when you submit
            Personal Data to us, you may be given options to restrict our use of
            your data. In particular, we aim to give you strong controls on our
            use of your data for direct marketing purposes (including the
            ability to opt-out of receiving emails from us which you may do by
            unsubscribing using the links provided in our emails and at the
            point of providing your details).
          </li>
          <li>
            Prior to uploading Client Personal Data into the Software, you are
            responsible for setting permissions for your authorised users within
            the Software.
          </li>
          <li>
            You may also wish to sign up to one or more of the preference
            services operating in the UK: The Telephone Preference Service (“the
            TPS”), the Corporate Telephone Preference Service (“the CTPS”), and
            the Mailing Preference Service (“the MPS”). These may help to
            prevent you receiving unsolicited marketing. Please note, however,
            that these services will not prevent you from receiving marketing
            communications that you have consented to receiving.
          </li>
        </ol>
        <h2 id="how-can-you-access-your-data">
          10. How Can You Access Your Data?
        </h2>
        <p>
          You have the right to ask for a copy of any of your Personal Data held
          by us (where such data is held). Please contact us for more details
          using the contact details below in section
        </p>
        <h2 id="contacting-us">Contacting Us</h2>
        <p>
          If you have any questions about our Site or this Privacy Policy,
          please contact us by email at{" "}
          <a href="mailto:dpo@coachingculture.com">dpo@coachingculture.com</a>.
          Please ensure that your query is clear, particularly if it is a
          request for information about the data we hold about you (as under
          section 10, above).
        </p>
      </RawContent>
    </Box>
  );
};

export default PrivacyPolicy;
