import { Button, Flex, Loader } from "@coaching-culture/ui";
import { FaPlusCircle } from "react-icons/fa";
import { GoalList } from "./GoalDisplay";
import { PageHeader } from "components/PageHeader";
import { useGoalTerminology } from "auth/OrgProvider";
import { useHistory, useParams } from "react-router";
import { CreateGoalModal } from "./CreateGoalModal";
import { useState } from "react";
import { useOrgUser } from "queries/users";
import CenterColumn from "components/CenterColumn";
import { useMyRelationships } from "queries/team";

export const UserGoals = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data: user, isFetched } = useOrgUser(userId);
  const goalTerms = useGoalTerminology();
  const history = useHistory();
  const { data: relationships, isFetched: relationsFetched } =
    useMyRelationships();

  const [creatingGoal, setCreatingGoal] = useState(false);

  if (!isFetched || !relationsFetched) {
    return <Loader />;
  }

  const canEdit = relationships?.some(
    (x) => x.to.id === userId && x.type === "manager"
  );

  const onReviewGoal = (goalId: string) => {
    history.push(`/solutions/people/goals/${goalId}/review/${userId}`);
  };

  return (
    <CenterColumn>
      <CreateGoalModal
        isOpen={creatingGoal}
        onCancel={() => setCreatingGoal(false)}
        directReportId={userId}
      ></CreateGoalModal>
      <PageHeader text={user.name + "'s " + goalTerms.goal.asPluralTitle()} />

      {canEdit && (
        <Button
          onClick={() => {
            setCreatingGoal(true);
          }}
          icon={FaPlusCircle}
          color="primary"
        >
          Create a new {goalTerms.goal.asBody()}
        </Button>
      )}
      <Flex mb={0} width="100%" flexDirection="column" pt={3}>
        <GoalList
          reviewGoal={(goalId) => onReviewGoal(goalId)}
          userId={userId}
        />
      </Flex>
    </CenterColumn>
  );
};
