import {
  Box,
  DatePill,
  Flex,
  IconButton,
  Loader,
  Panel,
  Table,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { ScrollableContainer } from "components/ScrollableContainer";
import WidgetHeader from "components/WidgetHeader";
import { sortBy } from "lodash";
import { GoalProgressDisplay } from "pages/Solutions/Performance/GoalProgressDisplay";
import { useMyGoals } from "queries/performance";
import { FaEye, FaMedal, FaPlusCircle } from "react-icons/fa";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { NoDataWidget } from "./NoDataWidget";

export function GoalWidget() {
  const terms = useGoalTerminology();
  const { data: goals, isFetched } = useMyGoals();
  const history = useHistory();

  const ReviewGoal = (id: string) => {
    history.push(`/solutions/performance/goals/${id}/review`);
  };

  if (!isFetched) {
    return <Loader />;
  }

  const sortedGoals = sortBy(
    goals.filter((x) => x.completedOn == null),
    (x) => (x.completedOn == null ? x.deadline : Number.MAX_VALUE),
  );

  return (
    <Panel>
      <WidgetHeader
        title={terms.goal.asPluralTitle()}
        icon={FaMedal}
        button={{
          text: "Create Goal",
          icon: FaPlusCircle,
          to: "/solutions/performance/goals/create",
        }}
      />
      {goals.length === 0 ? (
        <NoDataWidget text={`No Current ${terms.goal.asPluralTitle()}`} />
      ) : (
        <Box>
          <ScrollableContainer maxHeight={310}>
            <Table>
              <thead
                style={{
                  top: 0,
                  background: "white",
                  position: "sticky",
                  marginBottom: "20px",
                  zIndex: 3,
                }}
              >
                <th>{terms.goal.asTitle()}</th>
                <th>Last Review</th>
                <th>Status</th>
                <th />
              </thead>

              <tbody>
                {sortedGoals.map((x) => (
                  <tr>
                    <td>{x.purpose}</td>
                    <td>
                      <DatePill value={x.updatedOn} />
                    </td>
                    <td>
                      {" "}
                      <Link
                        title={`Review ${terms.goal.asTitle()}`}
                        to={`/solutions/performance/goals/${x.id}/review`}
                      >
                        <GoalProgressDisplay
                          value={x.completedOn != null ? "achieved" : x.status}
                        />
                      </Link>
                    </td>
                    <td>
                      <Flex alignItems="center" justifyContent={"flex-end"}>
                        <IconButton
                          style={{ fontSize: "1.2em" }}
                          color="primary"
                          icon={FaEye}
                          onClick={() => ReviewGoal(x.id)}
                          mr={2}
                        />
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollableContainer>
        </Box>
      )}
    </Panel>
  );
}
