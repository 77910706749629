import {
  FullUser,
  GroupMembershipLevel,
  UserDisplay,
  UserGroup,
} from "@coaching-culture/types";
import { Button, Circle, Flex, Loader, Text } from "@coaching-culture/ui";
import SectionNav from "components/SectionNav";
import UserProfileImage from "components/UserProfileImage";
import { useTeam } from "queries/performance";
import { useGroups } from "queries/users";
import { useState } from "react";
import { FaChevronDown, FaUsers } from "react-icons/fa";
import { ShareTarget } from ".";
import {
  CloseDrawerButton,
  Drawer,
  DrawerFooter,
  DrawerHeader,
  ItemContainer,
  ItemsList,
} from "./Drawer";

type UserItemProps = {
  onClick: () => void;
  user: UserDisplay;
  active: boolean;
};

const UserItem = ({ onClick, user, active }: UserItemProps) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <UserProfileImage name={user.name} mr={2} size="small" />
        <Text
          fontWeight={500}
          fontSize={4}
          color={active ? "positive" : "body"}
        >
          {user.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

type GroupItemProps = {
  onClick: () => void;
  group: UserGroup;
  active: boolean;
};

const GroupItem = ({ onClick, group, active }: GroupItemProps) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex
        width="400px"
        maxWidth="100%"
        alignItems="center"
        color={active ? "positive" : "body"}
      >
        <Circle color="primary" icon={FaUsers} mr={2} />
        <Text fontWeight={500} fontSize={4}>
          {group.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

export type UserDirectoryDrawerProps = {
  onSelect: (items: ShareTarget[]) => void;
  me: FullUser;
};

const UserDirectoryDrawer = ({ onSelect, me }: UserDirectoryDrawerProps) => {
  const [showing, setShowing] = useState("users");
  const [selected, setSelected] = useState<ShareTarget[]>([]);
  const groupData = useGroups();
  const rels = useTeam();

  function toggleItem(item: UserGroup | UserDisplay): void {
    const isUser = (x: UserGroup | UserDisplay): x is UserDisplay =>
      "email" in x;

    setSelected((old) => {
      if (old.some((x) => x.id === item.id)) {
        return old.filter((x) => x.id !== item.id);
      } else if (isUser(item)) {
        return old.concat([
          {
            type: "user",
            ...item,
          },
        ]);
      } else {
        return old.concat([
          {
            type: "group",
            ...item,
          },
        ]);
      }
    });
  }

  const isItemActive = (type: string, id: string) =>
    selected.some((x) => x.type === type && x.id === id);

  const userCount = selected.filter((x) => x.type === "user").length;
  const groupCount = selected.filter((x) => x.type === "group").length;

  const selectText = `Select ${[
    userCount && `${userCount} user${userCount === 1 ? "" : "s"}`,
    groupCount && `${groupCount} group${groupCount === 1 ? "" : "s"}`,
  ]
    .filter(Boolean)
    .join(" and ")}`;

  const groups =
    groupData.data?.filter(
      (x) =>
        me.permissions.includes("org-admin") ||
        x.membershipLevel >= GroupMembershipLevel.Admin,
    ) ?? [];

  const users = rels.data?.directReports
    .filter(
      (x) =>
        x.relationship.toAccepted != null &&
        x.relationship.fromAccepted != null,
    )
    .map((x) => x);

  const loading = groupData.isLoading || rels.isLoading;

  return (
    <Drawer>
      <DrawerHeader>
        <CloseDrawerButton onClick={() => onSelect(null)}>
          <FaChevronDown />
        </CloseDrawerButton>
        <Text textAlign="center" fontSize={5} fontWeight={600}>
          My Team
        </Text>
      </DrawerHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SectionNav
            mt={3}
            active={showing === "users" ? 0 : 1}
            links={["Users", "Groups"]}
            onSelect={(idx) => setShowing(idx === 0 ? "users" : "groups")}
            mb={3}
          />
          {showing === "users" ? (
            <ItemsList>
              {users.length === 0 ? (
                <Text textAlign="center" fontWeight={600} mt={3}>
                  No Team Members
                </Text>
              ) : (
                users.map((x) => (
                  <UserItem
                    onClick={() => toggleItem(x)}
                    user={x}
                    key={x.id}
                    active={isItemActive("user", x.id)}
                  />
                ))
              )}
            </ItemsList>
          ) : (
            <ItemsList>
              {groups.length === 0 ? (
                <Text textAlign="center" fontWeight={600}>
                  No Groups
                </Text>
              ) : (
                groups.map((x) => (
                  <GroupItem
                    onClick={() => toggleItem(x)}
                    group={x}
                    key={x.id}
                    active={isItemActive("group", x.id)}
                  />
                ))
              )}
            </ItemsList>
          )}
        </>
      )}
      <DrawerFooter>
        <Button
          color="primary"
          onClick={() => onSelect(selected)}
          disabled={selected.length === 0}
        >
          {selectText}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default UserDirectoryDrawer;
