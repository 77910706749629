import { CollectiveGoalDto } from "@coaching-culture/types";
import {
  IconToast,
  Loader,
  Panel,
  SingleValueWidget,
  useToast,
  WidgetGrid,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import NotFound from "pages/NotFound";
import {
  useOrgGoalInsights,
  useOrgGoals,
  useUpdateOrgGoal,
} from "queries/performance";
import { useState } from "react";
import { FaSave } from "react-icons/fa";
import { useParams } from "react-router";
import { OrgGoalForm } from "./OrgGoalForm";

export function OrgGoalDetails() {
  const { id } = useParams<{ id: string }>();
  const pushToast = useToast();
  const [saving, setSaving] = useState(false);
  const { data: orgGoals, isFetched } = useOrgGoals();
  const { data: insights, isFetched: insightsFetched } = useOrgGoalInsights(id);
  const terms = useGoalTerminology();

  const updateGoal = useUpdateOrgGoal();

  const onSave = async (spec: CollectiveGoalDto) => {
    setSaving(true);
    try {
      await updateGoal.mutateAsync(spec);
      pushToast({
        content: <IconToast icon={FaSave} iconColor="primary" text="Saved" />,
      });
    } catch (err) {
      window.alert(err);
    } finally {
      setSaving(false);
    }
  };

  const goal = orgGoals?.find((x) => x.id === id);

  return (
    <CenterColumn>
      <PageHeader
        text={`${terms.goal.asTitle()} Details`}
        subtitle={`Set ${terms.goal.asPluralBody()} at the organisation level.`}
      />

      <WidgetGrid
        gridGap={[3, 3]}
        gridTemplateColumns={["1fr", "1fr 1fr"]}
        mb={3}
      >
        {!insightsFetched && <Loader overlay />}
        <SingleValueWidget
          title={`Active Associated ${terms.goal.asPluralTitle()}`}
          value={insights?.activeGoals}
        />
        <SingleValueWidget
          title={`Completed Associated ${terms.goal.asPluralTitle()}`}
          value={insights?.completedGoals}
        />
      </WidgetGrid>
      <Panel p={[2, 3]}>
        {saving && <Loader overlay />}
        {!isFetched ? (
          <Loader />
        ) : goal == null ? (
          <NotFound />
        ) : (
          <OrgGoalForm onSave={onSave} value={goal} />
        )}
      </Panel>
    </CenterColumn>
  );
}
