import {
  Box,
  Button,
  Flex,
  IconButton,
  Img,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
} from "@coaching-culture/ui";
import { useCallback, useMemo, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";
import { FaCopy, FaFileImport, FaPlus, FaTrash } from "react-icons/fa";
import { format, fromUnixTime } from "date-fns";
import { FormLibraryItem, SurveyFormSummary } from "@coaching-culture/types";
import { SurveyLibraryModal } from "./LibraryModal";
import logoSrc from "../../../img/logo.png";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";

const itemSearch = (item: SurveyFormSummary) => item.name;

export const Forms = () => {
  const [items, setItems] = useState<SurveyFormSummary[] | null>(null);
  const [lib, setLib] = useState<boolean>(false);
  const [showLibraryModal, setShowLibraryModal] = useState<boolean>(false);

  const loading = items == null;

  useEffect(() => {
    axios
      .get<FormLibraryItem[]>("/api/form-library")
      .then(({ data }) =>
        setLib(data.filter((x) => x.type === "survey").length > 0)
      );
  }, []);

  const getData = useCallback(() => {
    axios.get(`/api/survey-forms`).then(({ data }) => {
      setItems(sortBy(data, "createdOn"));
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const deleteForm = useCallback(
    (id: string) => {
      if (
        window.confirm(
          "Are you sure you want to delete that form?\nThis will not affect existing surveys using that form."
        )
      ) {
        axios.delete(`/api/survey-forms/${id}`);
        setItems((items ?? []).filter((x) => x.id !== id));
      }
    },
    [items]
  );

  const duplicateForm = useCallback(
    (id: string,name: string) => {
      if (
        window.confirm(
          'Are you sure you want to duplicate the form "'+ name + '"?'
        )
      ) {
        axios.post(`/api/survey-forms/${id}/duplicate`).then( res => {
          getData();
        });
      }
    },
    [getData]
  );

  const cols = useMemo(
    (): SortableTableColumn<SurveyFormSummary>[] => [
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Flex alignItems="center">
            <Link to={`/success/surveys/forms/${x.id}`}>
              <strong>{x.name}</strong>
            </Link>
            {x.importedId != null && (
              <Img
                ml={2}
                src={logoSrc}
                height={20}
                title="Imported from the Coaching Culture Library"
              />
            )}
          </Flex>
        ),
      },
      {
        name: "createdOn",
        label: "Created",
        format: (x) => format(fromUnixTime(x.createdOn), "yyyy-MM-dd"),
      },
      {
        name: "",
        label: "",
        headingProps: {
          width: 30,
        },
        notSortable: true,
        format: (x) => (
          <Flex justifyContent="flex-end">
            <IconButton
              icon={FaCopy}
              onClick={() => duplicateForm(x.id, x.name)}
              color="body"
              title="Duplicate form"
            />
            <IconButton
              icon={FaTrash}
              onClick={() => deleteForm(x.id)}
              color="danger"
              title="Delete form"
              ml={1}
            />
          </Flex>
        ),
      },
    ],
    [deleteForm,duplicateForm]
  );

  const onImport = async (item: FormLibraryItem) => {
    setShowLibraryModal(false);
    const spec = {
      formLibraryItemId: item.id,
    };

    await axios.post("/api/survey-forms/import", spec);
    getData();
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Survey Forms"
        subtitle="Unlocking the power of employee feedback"
        helpIdent="success-surveys-forms-main"
      />
      {showLibraryModal && (
        <SurveyLibraryModal
          onClose={() => setShowLibraryModal(false)}
          onSelect={onImport}
          type="survey"
        />
      )}
      <Box>
        <Flex
          alignItems={["stretch", "center"]}
          justifyContent="flex-end"
          mb={2}
          flexDirection={["column", "row"]}
        >
          {lib && (
            <Button
              color="primary"
              icon={FaFileImport}
              onClick={() => setShowLibraryModal(true)}
              mb={[1, 0]}
            >
              Coaching Culture Library
            </Button>
          )}
          <Button
            ml={[0, 2]}
            to="/success/surveys/forms/create"
            color="primary"
            icon={FaPlus}
          >
            New Form
          </Button>
        </Flex>
        <Panel style={{ overflow: "hidden" }}>
          {loading ? (
            <Loader />
          ) : (
            <SortableTable
              columns={cols}
              data={items}
              paged
              rowSearch={itemSearch}
              defaultSort={1}
              emptyMessage="No Forms Created Yet"
            />
          )}
        </Panel>
      </Box>
    </CenterColumn>
  );
};
