import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useSpring, animated } from "react-spring";
import styled, { useTheme } from "styled-components";
import Text from "./Text";

const Container = styled(animated.button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 18px;
  margin-bottom: 6px;
  font-size: 20px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background: white;
  cursor: pointer;
  width: 100%;
`;

const Icon = animated(FaCheckCircle);
const AniText = animated(Text);

export type SelectionButtonProps = {
  onClick: () => void;
  active: boolean;
  children: React.ReactNode;
};

export const SelectionButton = ({
  onClick,
  active,
  children,
}: SelectionButtonProps) => {
  const theme = useTheme() as any;

  const containerProps = useSpring({
    background: active ? theme.colors.primary : "#ffffff",
    color: active ? "#ffffff" : theme.colors.grey2,
    borderColor: active ? theme.colors.primary : theme.colors.grey2,
  });

  const iconProps = useSpring({
    opacity: active ? 1 : 0,
    color: "white",
    flex: "0 0 auto",
  });

  const textProps = useSpring({
    transform: active
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(-20px, 0px, 0px)",
    fontWeight: active ? 600 : 400,
  });

  return (
    <Container onClick={onClick} style={containerProps} type="button">
      <Icon style={iconProps} />
      <AniText style={textProps} ml={2} fontSize={[3, 3]} textAlign="left">
        {children}
      </AniText>
    </Container>
  );
};
