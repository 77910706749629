import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import {
  space,
  layout,
  typography,
  SpaceProps,
  LayoutProps,
  TypographyProps,
} from "styled-system";

type SelectElemProps = SpaceProps & LayoutProps & TypographyProps;

type SelectProps = SelectElemProps & {
  options: {
    value: string | null;
    label: string;
    disabled?: boolean;
  }[];
} & React.ComponentPropsWithoutRef<"select">;

const SelectWrapper = styled.div<SpaceProps & LayoutProps & TypographyProps>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 3px;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  padding-right: 6px;
  color: ${(props) => props.theme.colors.black};
  ${space};
  ${layout};
  ${typography};

  &:after {
    content: "";
    justify-self: end;
    width: 0.7em;
    height: 0.4em;
    margin-top: 0.1em;
    pointer-events: none;
    margin-right: 0.2em;
    background-color: ${(props) => props.theme.colors.primary};
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
  }

  &:focus-within {
    border: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: inset 0px 0px 5px
      ${(props) => lighten(0.3, props.theme.colors.primary)};
  }
`;

const SelectElem = styled.select<SelectElemProps>`
  appearance: none;
  background-color: transparent;
  padding: 8px 12px;
  margin: 0;
  font-family: inherit;
  border: 0;
  color: ${(props) => props.theme.colors.black};
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  display: block;
  width: 100%;
  grid-area: select;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, size, value, onChange, style, ...rest }, ref) => (
    <SelectWrapper style={style} {...(rest as any)}>
      <SelectElem {...rest} ref={ref} value={value} onChange={onChange}>
        {options.map(({ value, label, disabled }) => (
          <option
            key={value}
            value={value?.toString() || ""}
            disabled={disabled}
          >
            {label}
          </option>
        ))}
      </SelectElem>
    </SelectWrapper>
  ),
);

export default Select;
