import React from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";
import { IconButton } from "./IconButton";

import Input from "./Input";

type ContainerProps = SpaceProps & LayoutProps;

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  ${space};
  ${layout};
`;

const spin = keyframes`
{
  to {transform: rotate(360deg);}
}
`;

const Spinner = styled.span`
  position: absolute;
  top: 18px;
  right: 20px;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid ${(props) => props.theme.colors.primary};
    border-right: 2px solid transparent;
    animation: ${spin} 0.6s linear infinite;
  }
`;

const ClearButton = styled(IconButton)`
  position: absolute;
  top: 9px;
  right: 7px;
`;

type InputWithSearchProps = {
  searching: boolean;
  onClear?: () => void;
} & ContainerProps &
  React.ComponentPropsWithoutRef<"input">;

const InputWithSearch = React.forwardRef<
  HTMLInputElement,
  InputWithSearchProps
>(
  (
    {
      value,
      searching,
      mb,
      ml,
      mr,
      width,
      maxWidth,
      height,
      size,
      onChange,
      onClear,
      ...props
    },
    ref
  ) => {
    return (
      <Container mb={mb} width={width} maxWidth={maxWidth} ml={ml} mr={mr}>
        <FaSearch />
        <Input {...props} ref={ref} ml={2} onChange={onChange} value={value} />
        {searching ? (
          <Spinner />
        ) : value !== "" && onClear != null ? (
          <ClearButton icon={FaTimes} color="body" onClick={onClear} />
        ) : null}
      </Container>
    );
  }
);

export default InputWithSearch;
