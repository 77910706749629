import { lighten } from "polished";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

html {
  font-size: 13px;
  overflow-y: scroll;

  @media(min-width: ${(props) => props.theme.breakpoints[0]}) {
    font-size: 14px;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  body
  {
    margin: 25mm 25mm 25mm 25mm;
  }
  
}

@page {
  size: auto;
  margin: 15mm;
}

body, button {
  font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: ${(props) => props.theme.baseLineHeight};
  padding: 0;
  margin: 0;
  color: ${(props) => props.theme.colors.body};
}

h1,
h2,
h3,
h4,
h5,
h6,
pre,
p {
  margin: 0;
  font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: ${(props) => props.theme.baseLineHeight};
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
  h6 {
  font-family: ${(props) => props.theme.fonts.heading};

  }

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker {
  border-color: ${(props) => props.theme.colors.grey3};
  border-color: transparent;
  border-top: 3px solid ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.body};
  border-radius: 0;
  box-shadow: ${(props) => props.theme.shadows[2]};
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__time-container {
  border-left-color: ${(props) => props.theme.colors.grey3};
}

.react-datepicker__navigation {
  top: 7px;
}

.react-datepicker__header {
  padding-top: 6px;
  background-color: ${(props) => props.theme.colors.grey6};
  border-bottom-color: ${(props) => props.theme.colors.grey3};
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
{
  color: ${(props) => props.theme.colors.body};
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: white;
  color: ${(props) => props.theme.colors.body};
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
{
  background-color: ${(props) => props.theme.colors.primary};
  color: white;

  &:hover {
    background-color: ${(props) => lighten(0.2, props.theme.colors.primary)};
  }
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  color: ${(props) => props.theme.colors.grey3};
}

  .use-spring-carousel-item {
    min-width: 0;
    min-height: 0;
  }

  .react-tiny-popover-container {
    z-index: 100000;
  }

@media print {
    @page {
      margin: 2cm;
    }

    body {
      font: 13pt Georgia, "Times New Roman", Times, serif;
      line-height: 1.3;
      background: #fff !important;
      color: #000;
    }

    a {
      page-break-inside: avoid;
    }
    blockquote {
      page-break-inside: avoid;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      page-break-after: avoid;
      page-break-inside: avoid;
    }

  h1 {
    page-break-before: always
  }
    img {
      page-break-inside: avoid;
      page-break-after: avoid;
    }
    table,
    pre {
      page-break-inside: avoid;
    }
    ul,
    ol,
    dl {
      page-break-before: avoid;
    }
  }
`;

export default GlobalStyle;
