import { FeedbackFormSummary } from "@coaching-culture/types";
import { Flex, Modal, Text, Button, Circle } from "@coaching-culture/ui";
import { format } from "date-fns";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaClipboardCheck, FaQuestion } from "react-icons/fa";
import styled from "styled-components";
import { ItemContainer, ItemsList } from "components/ShareModal/Drawer";

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey3};
`;

const FormItem = ({
  onClick,
  form,
}: {
  onClick: () => void;
  form: FeedbackFormSummary;
}) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <Circle icon={FaClipboardCheck} mr={2} size="small" />
        <Text fontWeight={500} fontSize={4}>
          {form.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

type FeedbackFormSelectorModalProps = {
  onSelect: (form: FeedbackFormSummary | null) => void;
  onCancel: () => void;
  forms: FeedbackFormSummary[];
};

function FeedbackFormSelectorModal({
  onSelect,
  onCancel,
  forms,
}: FeedbackFormSelectorModalProps) {
  return (
    <Modal
      width={600}
      minHeight={300}
      p={0}
      onClose={onCancel}
      showCloseButton
      style={{ overflow: "hidden" }}
    >
      <Flex alignItems="center" flexDirection="column" p={3}>
        <Circle color="primary" icon={FaClipboardCheck} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Choose Feedback Form
        </Text>
      </Flex>
      <ItemsList>
        {forms.map((x) => (
          <FormItem form={x} onClick={() => onSelect(x)} />
        ))}
      </ItemsList>
    </Modal>
  );
}

export type FeedbackFormSelectorProps = {
  value: FeedbackFormSummary | null;
  onChange: (val: FeedbackFormSummary) => void;
};

export function FeedbackFormSelector({
  value,
  onChange,
}: FeedbackFormSelectorProps) {
  const [modal, setModal] = useState<boolean>(false);
  const [forms, setForms] = useState<FeedbackFormSummary[] | null>(null);

  useEffect(() => {
    axios
      .get<FeedbackFormSummary[]>("/api/feedback/forms")
      .then(({ data }) => setForms(data.filter((x) => x.published)));
  }, []);

  const onSelect = (item: FeedbackFormSummary | null) => {
    setModal(false);
    onChange(item);
  };

  return (
    <>
      {modal && (
        <FeedbackFormSelectorModal
          onSelect={onSelect}
          forms={forms}
          onCancel={() => setModal(false)}
        />
      )}
      <Container>
        <Flex alignItems="center" justifyContent="space-between" p={3}>
          <Flex alignItems="center">
            <Circle
              size="large"
              icon={value == null ? FaQuestion : FaClipboardCheck}
              color={value == null ? "grey3" : "primary"}
              mr={3}
            />
            <div>
              <Text fontSize={4}>{value?.name ?? "<None Selected>"}</Text>
              <Text color="grey2">
                {value == null
                  ? ""
                  : `Created on: ${format(
                      new Date(value.createdOn),
                      "yyyy-MM-dd"
                    )}`}
              </Text>
            </div>
          </Flex>
          <Button
            color="primary"
            onClick={() => setModal(true)}
            disabled={forms == null}
          >
            {forms == null ? "Loading" : "Choose"}
          </Button>
        </Flex>
      </Container>
    </>
  );
}
