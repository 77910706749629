import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const Container = styled.div<ContainerProps>`
  max-height: ${(props) => props.maxHeight + "px"};
  overflow-y: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};

    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
  ${space};
`;

type ContainerProps = {
  maxHeight: number;
  children: React.ReactNode;
} & SpaceProps;

export function ScrollableContainer({
  maxHeight,
  children,
  ...rest
}: ContainerProps) {
  return (
    <Container {...rest} maxHeight={maxHeight}>
      {children}
    </Container>
  );
}
