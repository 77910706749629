import { CoachingTopicDto } from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Input,
  Loader,
  Modal,
  MultiSelect,
  Text,
} from "@coaching-culture/ui";
import {
  useCoachingCategoriesForUser,
  useFavouriteTopic,
} from "queries/conversations";
import { useCallback, useState } from "react";
import { FaMinusCircle, FaPlusCircle, FaStar } from "react-icons/fa";
import styled from "styled-components";

const TopicsContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
  ::-webkit-scrollbar {
    width: 5px;
  }
  padding: 24px;
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

export const CoachingTopicsModal = ({
  onCancel,
  onSave,
  isOpen,
}: {
  onCancel: () => void;
  onSave: (topis: Partial<CoachingTopicDto[]>) => void;
  isOpen: boolean;
}) => {
  const { data: categories } = useCoachingCategoriesForUser();
  const favouriteTopic = useFavouriteTopic();
  const [searchText, setSearchText] = useState("");
  const [selectedCats, setSelectedCats] = useState<string[]>([]);
  const [addedTopicsObj, setAddedTopicsObj] = useState<CoachingTopicDto[]>([]);

  const topics = useCallback(() => {
    let temp: CoachingTopicDto[] = [];
    categories?.forEach((cat) =>
      cat.coachingTopics.forEach((topic) => {
        if (searchText.length > 2) {
          if (topic.body.toLowerCase().includes(searchText.toLowerCase())) {
            temp.push(topic);
          }
        } else {
          temp.push(topic);
        }
      }),
    );
    if (selectedCats.length > 0) {
      const favsSeleted = selectedCats.some((cat) => cat === "fav");
      const addedSelected = selectedCats.some((cat) => cat === "added");
      if (selectedCats.length > 0) {
        temp = temp.filter(
          (topic) =>
            selectedCats.some(
              (x) => x === topic.categoryId && x !== "fav" && x !== "added",
            ) ||
            (favsSeleted && topic.favourite) ||
            (addedSelected && addedTopicsObj.some((x) => x.id === topic.id)),
        );
      }
    }
    return temp;
  }, [categories, searchText, selectedCats, addedTopicsObj]);

  const favouriteTopicClick = (topicId: string) => {
    favouriteTopic.mutateAsync(topicId);
  };

  const addTopic = (topic: CoachingTopicDto) => {
    setAddedTopicsObj([...addedTopicsObj, topic]);
  };

  const removeTopic = (topicId: string) => {
    setAddedTopicsObj(addedTopicsObj.filter((x) => x.id !== topicId));
  };

  const save = () => {
    onSave(addedTopicsObj);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal onClose={onCancel} width={500}>
      <Box p={3}>
        <Flex
          mb={3}
          mr={4}
          ml={4}
          mt={3}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          <Text fontSize={24} fontWeight={600} as={"h2"}>
            Coaching Question Library
          </Text>
          <Text
            textAlign={"center"}
            fontSize={14}
            mb={3}
            fontWeight={500}
            as={"h2"}
          >
            Enhance your conversation with great coaching questions...
          </Text>
          <Input
            onChange={(val) => {
              setSearchText(val.target.value);
            }}
            value={searchText}
            mb={1}
            placeholder="Search"
          />
        </Flex>
        {categories == null ? (
          <Loader />
        ) : (
          <>
            <Flex mr={4} ml={4}>
              <Flex width={400}>
                <MultiSelect
                  defaultLabel="Filter..."
                  options={[
                    ...[
                      { label: "Favourites", value: "fav" },
                      { label: "Added", value: "added" },
                    ],
                    ...categories.map((cat) => ({
                      label: cat.name,
                      value: cat.id,
                    })),
                  ]}
                  value={selectedCats}
                  onChange={setSelectedCats}
                />
              </Flex>
            </Flex>
            <TopicsContainer>
              {topics().map((topic) => (
                <Flex
                  borderBottom={1}
                  alignItems={"center"}
                  p={4}
                  flexDirection={"row"}
                  min-height={100}
                >
                  <Flex alignItems={"center"} flexGrow={6}>
                    <Circle mr={2} size={8} color="primary" />
                    <Text>{topic.body}</Text>
                  </Flex>
                  <Flex alignItems="end" justifyContent="center">
                    {topic.favourite ? (
                      <IconButton
                        mr={1}
                        onClick={() => favouriteTopicClick(topic.id)}
                        color="primary"
                        icon={FaStar}
                      />
                    ) : (
                      <IconButton
                        mr={1}
                        onClick={() => favouriteTopicClick(topic.id)}
                        color="grey"
                        icon={FaStar}
                      />
                    )}
                    {addedTopicsObj.some((x) => x.id === topic.id) ? (
                      <IconButton
                        onClick={() => removeTopic(topic.id)}
                        color="danger"
                        icon={FaMinusCircle}
                      />
                    ) : (
                      <IconButton
                        onClick={() => addTopic(topic)}
                        color="primary"
                        icon={FaPlusCircle}
                      />
                    )}
                  </Flex>
                </Flex>
              ))}
            </TopicsContainer>
          </>
        )}
        <Flex
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignContent={"center"}
          mt={2}
        >
          {addedTopicsObj.length > 0 && (
            <Button icon={FaPlusCircle} color="positive" mr={2} onClick={save}>
              Add {addedTopicsObj.length} Question(s)
            </Button>
          )}
          <Button onClick={onCancel}>Close</Button>
        </Flex>
      </Box>
    </Modal>
  );
};
