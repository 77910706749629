import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const DrawerContainer = styled(animated.div)`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type DrawerProps = {
  children?: React.ReactNode;
  onReady?: () => void;
};

export const Drawer = ({ children, onReady }: DrawerProps) => {
  const [isReady, setIsReady] = useState(false);

  const ready = () => {
    if (isReady) {
      return;
    }
    setIsReady(true);
    if (onReady) {
      onReady();
    }
  };

  const containerStyles = useSpring({
    transform: "translate3d(0px,0px,0px)",
    opacity: 1,
    from: {
      transform: "translate3d(0px,300px,0px)",
      opacity: 0.5,
    },
    onRest: ready,
  });

  return <DrawerContainer style={containerStyles}>{children}</DrawerContainer>;
};

export const DrawerFooter = styled.div`
  padding: 18px;
`;

export const DrawerHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
  padding: 18px;
  width: 100%;
`;

export const CloseDrawerButton = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  color: ${(props) => props.theme.colors.grey3};
  background: none;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.grey1};
  }
`;

export const ItemContainer = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  background: none;
  transition: all 0.3s ease;
  align-items: center;
  border: 0;
  &:focus,
  &:active {
    outline: 0;
    background-color: ${(props) => props.theme.colors.grey4};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
`;

export const ItemsList = styled.div`
  padding: 18px;
  padding-top: 0;
  padding-bottom: 0;
  flex: 1;
  width: 100%;
  position: relative;
  overflow-y: scroll;
`;
