import { TeamGoalDto } from "@coaching-culture/types";
import { Circle, DotMenu, Flex, LinkButton } from "@coaching-culture/ui";
import { FaMedal, FaTrash } from "react-icons/fa";

type TeamGoalRowProps = {
  goal: TeamGoalDto;
  onEdit: () => void;
  onRemove: () => void;
};

export const TeamGoalRow = ({ goal, onRemove, onEdit }: TeamGoalRowProps) => {
  const dotMenuItems = [
    {
      label: "Delete",
      icon: FaTrash,
      color: "danger",
      cmd: "del",
    },
  ];

  const onItemClick = (_: number, cmd: string) => {
    if (cmd === "del") {
      onRemove();
    }
  };

  return (
    <Flex
      pl={2}
      pt={2}
      pr={2}
      mb={2}
      alignItems={["stretch", "center"]}
      flexWrap="wrap"
    >
      <Flex width={[11 / 12]} order={0} alignItems="center">
        <Circle icon={FaMedal} color="primary" mr={2} />
        <LinkButton onClick={onEdit} fontWeight={500}>
          {goal.name}
        </LinkButton>
      </Flex>
      <Flex width={1 / 12} justifyContent="flex-end" order={[1, 4]}>
        <DotMenu items={dotMenuItems} onItemClick={onItemClick} />
      </Flex>
    </Flex>
  );
};
