import { GoalSummaryDto } from "@coaching-culture/types";
import {
  Box,
  DatePill,
  Flex,
  IconButton,
  Panel,
  Table,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { ScrollableContainer } from "components/ScrollableContainer";
import WidgetHeader from "components/WidgetHeader";
import { sortBy } from "lodash";
import { NoDataWidget } from "pages/Dashboard/Widgets/NoDataWidget";
import { GoalProgressDisplay } from "pages/Solutions/Performance/GoalProgressDisplay";
import { useState } from "react";
import { FaEye, FaMedal } from "react-icons/fa";
import { GoalDisplayModal } from "./GoalDisplayModal";

export type GoalsWidgetProps = {
  goals: GoalSummaryDto[];
  userId: string;
};

export function GoalsWidget({ goals, userId }: GoalsWidgetProps) {
  const terms = useGoalTerminology();
  const [reviewingGoal, setReviewingGoal] = useState<string | null>(null);

  const sortedGoals = sortBy(
    goals.filter((x) => x.completedOn == null),
    (x) => (x.completedOn == null ? x.deadline : Number.MAX_VALUE),
  );

  return (
    <>
      {reviewingGoal && (
        <GoalDisplayModal
          goalId={reviewingGoal}
          onClose={() => setReviewingGoal(null)}
          userId={userId}
        />
      )}
      <Panel>
        <WidgetHeader title={terms.goal.asPluralTitle()} icon={FaMedal} />
        {goals.length === 0 ? (
          <NoDataWidget text={`No Current ${terms.goal.asPluralTitle()}`} />
        ) : (
          <Box>
            <ScrollableContainer maxHeight={310}>
              <Table>
                <thead
                  style={{
                    top: 0,
                    background: "white",
                    position: "sticky",
                    marginBottom: "20px",
                    zIndex: 3,
                  }}
                >
                  <th>{terms.goal.asTitle()}</th>
                  <th style={{ width: 200, textAlign: "center" }}>
                    Last Review
                  </th>
                  <th style={{ width: 150, textAlign: "center" }}>Status</th>
                  <th style={{ width: 100 }} />
                </thead>

                <tbody>
                  {sortedGoals.map((x) => (
                    <tr>
                      <td>{x.purpose}</td>
                      <td style={{ textAlign: "center" }}>
                        <DatePill
                          value={x.updatedOn}
                          color={x.updatedOn == null ? "warning" : "positive"}
                        />
                      </td>
                      <td>
                        <GoalProgressDisplay
                          value={x.completedOn != null ? "achieved" : x.status}
                        />
                      </td>
                      <td>
                        <Flex alignItems="center" justifyContent={"flex-end"}>
                          <IconButton
                            style={{ fontSize: "1.2em", display: "none" }}
                            color="primary"
                            icon={FaEye}
                            onClick={() => setReviewingGoal(x.id)}
                            mr={2}
                          />
                        </Flex>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </Box>
        )}
      </Panel>
    </>
  );
}
