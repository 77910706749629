import { Redirect, Route, Switch } from "react-router-dom";
import { CreateOrgGoal } from "./CreateOrgGoal";
import { GoalExport } from "./GoalExport";
import { GoalFields } from "./GoalFields";
import { OrgGoalDetails } from "./OrgGoalDetails";
import { OrgGoals } from "./OrgGoals";

export const Performance = () => {
  return (
    <Switch>
      <Route
        path="/success/performance/org-goals/create"
        component={CreateOrgGoal}
      />
      <Route
        path="/success/performance/org-goals/:id"
        component={OrgGoalDetails}
      />
      <Route path="/success/performance/org-goals" component={OrgGoals} />
      <Route path="/success/performance/goals" component={GoalFields} />
      <Route path="/success/performance/goal-export" component={GoalExport} />
      <Redirect to="/success/performance/org-goals" />
    </Switch>
  );
};
