import { UserFilter } from "@coaching-culture/types";
import { Button, Flex } from "@coaching-culture/ui";
import { FilterModal } from "components/FilterPanel";
import { useState } from "react";
import { MeasuresInsightsComparison } from "./MeasuresInsightsComparison";
import { MeasuresInsightsTable } from "./MeasuresInsightsTable";

export const MeasuresInsights = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });

  return (
    <>
      <FilterModal
        isOpen={filtersOpen}
        onClose={(filter) => {
          setFilters(filter);
          setFiltersOpen(false);
        }}
        filter={filters}
      />
      <Flex justifyContent={"flex-end"} mb={3}>
        <Button onClick={() => setFiltersOpen(true)} color="primary">
          Filters ({filters.groups.length + filters.userFields.length})
        </Button>
      </Flex>
      <MeasuresInsightsComparison filter={filters} />
      <MeasuresInsightsTable filter={filters} />
    </>
  );
};
