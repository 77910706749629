import { Loader } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import ContentList from "pages/Mindset/ModuleList";
import { useAvailableContent } from "queries/mindset";

export default function LearnCastList() {
  const { data, isFetched } = useAvailableContent();

  if (!isFetched) {
    return <Loader />;
  }

  const modules = data.filter((x) => x.type === "learncast");

  return (
    <CenterColumn>
      <PageHeader
        text="Learn Cast"
        subtitle="Real conversations with real experts..."
      />
      <ContentList items={modules} />
    </CenterColumn>
  );
}
