import React from "react";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

export type AddButtonProps = {
  children: string;
} & SpaceProps &
  LayoutProps &
  React.ComponentPropsWithoutRef<"button">;

const AddButtonElem = styled.button<SpaceProps>`
  border: 1px dashed ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.grey6};
  color: ${(props) => props.theme.colors.grey2};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 12px 24px;
  width: 100%;
  ${space}
  ${layout}

  &[disabled] {
    opacity: 0.5;
  }

  &:enabled:hover {
    background-color: ${(props) => props.theme.colors.grey4};
  }

  & svg {
    margin-right: 12px;
  }
`;

export function AddButton({ children, ...rest }: AddButtonProps) {
  return (
    <AddButtonElem {...rest} type="button">
      <FaPlus />
      {children}
    </AddButtonElem>
  );
}
