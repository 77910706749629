import { MeasureDto } from "@coaching-culture/types";
import {
  DatePill,
  Flex,
  Grid,
  LinkButton,
  Loader,
  OptionSelect,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { min, range } from "lodash";
import { mix } from "polished";
import {
  useMeasures,
  useUpdateUserMeasures,
  useUserMeasures,
} from "queries/measures";
import { useState } from "react";
import { useTheme } from "styled-components";

const makeRamp = (colors: string[], count: number) =>
  range(count)
    .map((x) => {
      const p = x / (count - 1);
      const i = Math.floor(p * (colors.length - 1));
      const i2 = min([i + 1, colors.length - 1]);
      const p2 = p * (colors.length - 1) - i;
      const c = mix(p2, colors[i2], colors[i]);
      return c;
    })
    .reverse();

export type MeasureValueSelectProps = {
  measure: MeasureDto;
  value: string | null;
  onChange: (value: string) => void;
};

const MeasureValueSelect = ({
  measure,
  value,
  onChange,
}: MeasureValueSelectProps) => {
  const theme = useTheme();

  const colorRamp = makeRamp(
    [theme.colors.danger, theme.colors.warning, theme.colors.positive],
    measure.options.length,
  );

  const options = measure.options.map((x, i) => ({
    value: x.id,
    label: x.name,
    color: colorRamp[i],
  }));

  if (value === "") {
    options.unshift({
      value: "",
      label: "Unset",
      color: "grey2",
    });
  }

  console.log(value);

  return (
    <OptionSelect value={value ?? ""} options={options} onChange={onChange} />
  );
};

export type MeasuresUpdaterProps = {
  relationshipId: string;
  onUpdatedAll: () => void;
};

export const MeasuresUpdater = ({
  relationshipId,
  onUpdatedAll,
}: MeasuresUpdaterProps) => {
  const [me] = useUser();
  const measures = useMeasures();
  const userMeasures = useUserMeasures(me.id);
  const updateMeasures = useUpdateUserMeasures(me.id);
  const [confirming, setConfirming] = useState(false);

  const changeMeasure = async (measureId: string, value: string) => {
    await updateMeasures.mutateAsync({
      measureId: measureId,
      optionId: value,
      relationshipId: relationshipId,
    });
  };

  const confirmMeasures = async () => {
    setConfirming(true);

    const um = userMeasures.data!.find(
      (x) => x.relationshipId === relationshipId,
    );

    for (const m of um.measures) {
      await updateMeasures.mutateAsync({
        relationshipId: relationshipId,
        measureId: m.id,
        optionId: m.value,
      });
    }

    setConfirming(false);
    onUpdatedAll();
  };

  const valueForMeasure = (measureId: string) =>
    userMeasures.data
      ?.find((x) => x.relationshipId === relationshipId)
      ?.measures.find((x) => x.id === measureId)?.value ?? "";

  const lastUpdated = userMeasures.data
    ?.find((x) => x.relationshipId === relationshipId)
    ?.measures.map((x) => x.lastSet)
    .sort()
    .reverse()[0];

  if (measures.isLoading || userMeasures.isLoading) {
    return <Loader />;
  }

  return (
    <>
      {confirming && <Loader overlay />}
      <Grid gridTemplateColumns={"1fr 1fr"} mb={2} gridGap={1}>
        {measures.data.map((x) => (
          <>
            <Flex justifyContent={"flex-end"} alignItems="center">
              <Text textAlign={"right"} fontWeight={600}>
                {x.name}
              </Text>
            </Flex>
            <Flex justifyContent={"flex-start"}>
              <MeasureValueSelect
                measure={x}
                value={valueForMeasure(x.id)}
                onChange={(value) => changeMeasure(x.id, value)}
              />
            </Flex>
          </>
        ))}
      </Grid>
      <Flex flexDirection={"column"} alignItems="center" mb={5}>
        <Text textAlign={"center"} fontWeight={500} mb={"3px"}>
          Measures last updated: <DatePill value={lastUpdated} />
        </Text>
        <Text textAlign={"center"} fontWeight={500}>
          <LinkButton onClick={confirmMeasures}>
            Confirm current measures without change
          </LinkButton>
        </Text>
      </Flex>
    </>
  );
};
