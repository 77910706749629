import { addDays, differenceInDays, startOfDay } from "date-fns";
import { Text } from "@coaching-culture/ui";

export type ReportBackTextProps = {
  challengeStarted: Date;
  challengeDays: number;
};

export function ReportBackText({
  challengeStarted,
  challengeDays,
}: ReportBackTextProps) {
  const today = startOfDay(new Date());

  const challengeEnd = addDays(
    startOfDay(new Date(challengeStarted)),
    challengeDays
  );
  const daysTil = differenceInDays(challengeEnd, today);

  if (daysTil === 1) {
    return (
      <>
        <Text fontSize={4} mb={2} fontWeight={600} color="white">
          Come back tomorrow to report back on your challenge.
        </Text>
        <Text mb={3} color="white">
          If you'd rather report back now click the button below to begin
        </Text>
      </>
    );
  } else if (daysTil > 0) {
    return (
      <>
        <Text fontSize={4} mb={2} fontWeight={600} color="white">
          Come back in {daysTil} days to report back on your challenge.
        </Text>
        <Text mb={3} color="white">
          If you'd rather report back now click the button below to begin
        </Text>
      </>
    );
  } else {
    return (
      <Text fontSize={4} mb={3} fontWeight={600} color="white">
        Click the button below to report back on your challenge
      </Text>
    );
  }
}
