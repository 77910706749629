import { TeamGoalDto } from "@coaching-culture/types";
import {
  Button,
  Flex,
  Loader,
  Panel,
  SectionHeading,
  Text,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import {
  useCreateTeamGoal,
  useDeleteTeamGoal,
  useOwnedTeamGoals,
  useUpdateTeamGoal,
} from "queries/performance";
import { useState } from "react";
import { EditTeamGoalModal } from "./EditTeamGoalModal";
import { TeamGoalRow } from "./TeamGoalRow";

export function TeamGoals() {
  const { data: teamGoals, isFetched } = useOwnedTeamGoals();
  const deleteTeamGoal = useDeleteTeamGoal();
  const createTeamGoal = useCreateTeamGoal();
  const updateTeamGoal = useUpdateTeamGoal();
  const [editing, setEditing] = useState<TeamGoalDto | "new" | null>(null);
  const terms = useGoalTerminology();

  const removeItem = async (id: string) => {
    await deleteTeamGoal.mutateAsync(id);
  };

  const editItem = (id: string) => {
    const item = teamGoals.find((x) => x.id === id);
    setEditing(item);
  };

  const saveGoal = async (values: TeamGoalDto) => {
    setEditing(null);
    if (values.id) {
      await updateTeamGoal.mutateAsync(values);
    } else {
      await createTeamGoal.mutateAsync(values);
    }
  };

  return (
    <>
      {editing && (
        <EditTeamGoalModal
          goal={editing === "new" ? null : editing}
          onCancel={() => setEditing(null)}
          onSave={saveGoal}
        />
      )}
      <Flex alignItems="center" mb={3} justifyContent="space-between">
        <SectionHeading mb={0}>
          Your Team {terms.goal.asPluralTitle()}
        </SectionHeading>
        <Button ml={3} color="primary" onClick={() => setEditing("new")}>
          Add New Team {terms.goal.asTitle()}
        </Button>
      </Flex>
      {!isFetched ? (
        <Loader />
      ) : (
        <Panel mb={5}>
          {teamGoals.length === 0 ? (
            <Text color="grey2" fontWeight={500} p={2} textAlign="center">
              No Team {terms.goal.asPluralTitle()}
            </Text>
          ) : (
            teamGoals.map((x) => (
              <TeamGoalRow
                goal={x}
                key={x.id}
                onRemove={() => removeItem(x.id)}
                onEdit={() => editItem(x.id)}
              />
            ))
          )}
        </Panel>
      )}
    </>
  );
}
