import React from "react";
import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";

export type SectionHeadingProps = SpaceProps & TypographyProps;

export const SectionHeading = styled.h2<SectionHeadingProps>`
  display: flex;
  font-weight: 600;
  align-items: center;
  flex: 1;

  ${typography}
  ${space};

  &:after {
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    background-color: ${(props) => props.theme.colors.grey3};
    margin-left: 12px;
  }
`;

SectionHeading.defaultProps = {
  mb: 3,
  fontSize: [4, 5],
};
