import React, { useCallback, useEffect, useRef } from "react";
import { clamp } from "lodash";
import { useSprings, animated, config } from "react-spring";
import { FormItem, FormItemSpec } from "@coaching-culture/types";
import styled from "styled-components";
import { useDrag } from "@use-gesture/react";
import { FaCopy, FaEdit, FaGripVertical, FaTrash } from "react-icons/fa";
import Flex from "../Flex";
import Label from "../Label";
import Text from "../Text";
import { IconButton } from "../IconButton";

function swap<T>(array: T[], moveIndex: number, toIndex: number): T[] {
  /* #move - Moves an array item from one position in an array to another.
     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.
    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
}

const itemHeight = 80;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
`;

const Item = styled(animated.div)`
  height: ${itemHeight - 12}px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  user-select: none;

  & button {
    transition: all 0.3s ease;
    opacity: 0;
  }

  &:hover button {
    opacity: 1;
  }
`;

const MoveHandle = styled.div`
  font-size: 1.2em;
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: move;
  color: ${(props) => props.theme.colors.grey2};
  & svg {
    touch-action: none;
  }
`;

const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) => {
    return active && index === originalIndex
      ? {
          y: curIndex * itemHeight + y,
          scale: 1,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === "zIndex",
          config: (key: string) =>
            key === "y" ? config.stiff : config.default,
        }
      : {
          y: order.indexOf(index) * itemHeight,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: () => false,
          config: () => config.default,
        };
  };

type FormItemListProps = {
  items: FormItem[] | FormItemSpec[];
  indexOrder: number[];
  onOrderChange: (order: number[]) => void;
  onItemSelect: (itemIndex: number) => void;
  onItemDelete: (itemIndex: number) => void;
  onItemClone: (itemIndex: number) => void;
};

const typeNames = {
  content: "Content",
  rating: "Rating",
  free_text: "Free Text",
  multiple_choice: "Multiple Choice",
  heading: "Section Heading",
};

const ratingTypeNames = {
  stars: "Star Rating",
  smiles: "Faces Rating",
  dots: "Dots Rating",
  number: "Numbers Rating",
};

const getTypeName = (item: FormItem | FormItemSpec) => {
  if (item.type === "rating") {
    return ratingTypeNames[item.ratingType];
  } else {
    return typeNames[item.type];
  }
};

const getDescription = (item: FormItem | FormItemSpec) => {
  if (item.type === "content") {
    return item.title || "<Untitled Content>";
  } else {
    return item.content;
  }
};

export function FormItemList({
  items,
  onOrderChange,
  indexOrder,
  onItemSelect,
  onItemDelete,
  onItemClone,
}: FormItemListProps) {
  const lastLength = useRef(items.length);

  const [springs, api] = useSprings(items.length, fn(indexOrder));

  useEffect(() => {
    api.start(fn(indexOrder));
  }, [api, indexOrder]);

  useEffect(() => {
    const newItem = lastLength.current > items.length;
    lastLength.current = items.length;
    api.start({
      ...fn(indexOrder),
      immediate: newItem,
    });
  }, [items.length, api, indexOrder]);

  const dragCallback = useCallback(
    ({ args: [originalIndex], active, movement: [, y] }: any) => {
      const order = [...indexOrder];
      let curIndex = order.indexOf(originalIndex);
      if (curIndex === -1) {
        curIndex = originalIndex;
      }
      const curRow = clamp(
        Math.round((curIndex * itemHeight + y) / itemHeight),
        0,
        items.length - 1
      );
      const newOrder = swap(order, curIndex, curRow);
      api.start(fn(newOrder, active, originalIndex, curIndex, y));
      if (!active) {
        onOrderChange(newOrder);
      }
    },
    [indexOrder, api, onOrderChange]
  );

  const bind = useDrag(dragCallback);

  const noSections = items.every((x) => x.type !== "heading");

  return (
    <Container style={{ height: items.length * itemHeight }}>
      {springs.map(({ zIndex, shadow, y, scale }, i) => {
        return (
          <Item
            key={i}
            style={{
              touchAction: "none",
              width:
                items[i].type === "heading" || noSections
                  ? "100%"
                  : "calc(100% - 24px)",
              left: items[i].type === "heading" || noSections ? "0px" : "24px",

              zIndex,
              boxShadow: shadow.to(
                (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
              ),
              y,
              scale,
            }}
          >
            <Flex alignItems="center">
              <MoveHandle {...(bind as any)(i)}>
                <FaGripVertical {...(bind as any)(i)} />
              </MoveHandle>
              <Text fontSize={4} color="primary" fontWeight={600} mr={2}>
                {indexOrder.indexOf(i) + 1 || i + 1}.
              </Text>
              <Text fontWeight={items[i].type === "heading" ? 600 : 400}>
                {getDescription(items[i])}
              </Text>
            </Flex>
            <Flex alignItems="center">
              <IconButton
                icon={FaCopy}
                color="body"
                style={{ fontSize: 18 }}
                onClick={() => onItemClone(i)}
              />
              <IconButton
                icon={FaEdit}
                color="body"
                style={{ fontSize: 18 }}
                onClick={() => onItemSelect(i)}
                ml={2}
              />
              <IconButton
                ml={2}
                icon={FaTrash}
                color="danger"
                style={{ fontSize: 18 }}
                onClick={() => onItemDelete(i)}
              />
              <Label ml={2}>{getTypeName(items[i])}</Label>
            </Flex>
          </Item>
        );
      })}
    </Container>
  );
}
