import { Button, Loader, Text } from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export const Office365Auth = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [done, setDone] = useState(false);

  const consent = search.get("admin_consent");
  const tenant = search.get("tenant");

  useEffect(() => {
    if (consent === "True") {
      axios.post("/api/addons/office365", { tenant }).then(() => {
        setDone(true);
      });
    }
  });

  return (
    <CenterColumn>
      {done ? (
        <>
          <Text fontWeight={600} mb={3}>
            {consent === "True"
              ? "Your integration with Office 365 is now enabled."
              : "You declined to enable the Office 365 integration"}
          </Text>
          <Button to="/success/addons">Return to Integrations</Button>
        </>
      ) : (
        <Loader />
      )}
    </CenterColumn>
  );
};
