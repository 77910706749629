import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import range from "lodash/range";
import flatten from "lodash/flatten";
import { useSprings, animated } from "react-spring";

import { GraphAxes } from "./GraphAxes";
import { tint } from "polished";

const BarRect = styled(animated.rect)`
  stroke: none;
`;

const sigFigs = (n: number, sig: number) => {
  var mult = Math.pow(10, sig - Math.floor(Math.log(n) / Math.LN10) - 1);
  return Math.ceil(n * mult) / mult;
};

export type StreamCollection = {
  streams: { label: string; value: number }[];
  label: string;
};

export type BarsProps = {
  interpToX: (val: number) => number;
  interpToY: (val: number) => number;
  data: StreamCollection[];
  max: number;
  gradId: string;
  onBarMouseOver: (idx: number | null) => void;
};

const Bars = ({
  interpToX,
  interpToY,
  data,
  max,
  gradId,
  onBarMouseOver,
}: BarsProps) => {
  const streamCount = data[0].streams.length;

  const spaceWidth = (interpToX(1) - interpToX(0)) / data.length;
  const realZero = interpToY(0) + 10;

  const coords = useMemo(() => {
    const valToY = (val: number) => interpToY(val / max);
    return flatten(
      data
        .map((x) => x.streams)
        .map((stream, outerKey) => {
          return range(0, 3).map((_, innerKey) => [
            interpToX(0) +
              outerKey * spaceWidth +
              spaceWidth * 0.25 +
              ((spaceWidth * 0.5) /
                (innerKey > streamCount - 1 ? 3 : streamCount)) *
                innerKey,
            innerKey > streamCount - 1
              ? realZero
              : valToY(stream[innerKey].value || 0),
            innerKey > streamCount - 1
              ? (spaceWidth * 0.5) / 3
              : (spaceWidth * 0.5) / streamCount,
            innerKey > streamCount - 1
              ? 0
              : realZero - valToY(stream[innerKey].value),
            outerKey,
          ]);
        })
    );
  }, [data]);

  const barProps = useSprings(
    coords.length,
    coords.map(([x, y, width, height]) => ({
      x,
      y,
      width,
      height,
    }))
  );

  const barOver = useCallback(
    (idx: number) => (ev: React.MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      onBarMouseOver(idx);
    },
    [onBarMouseOver]
  );

  const leaveBar = useCallback(() => {
    onBarMouseOver(null);
  }, [onBarMouseOver]);

  return (
    <g>
      {barProps.map((props, i) => {
        return (
          <BarRect
            {...props}
            key={i}
            fill={`url(#${gradId})`}
            onMouseOver={barOver(coords[i][4])}
            onMouseLeave={leaveBar}
          />
        );
      })}
    </g>
  );
};

export type BarGraphProps = {
  xAxis: string;
  yAxis: string;
  data: StreamCollection[];
  streamLabels: string[];
  color: string;
  onValueMouseOver?: (val: { x: string; y: string } | null) => void;
};

export const BarChart = ({
  xAxis,
  yAxis,
  data,
  streamLabels,
  color,
  onValueMouseOver,
}: BarGraphProps) => {
  const max = sigFigs(
    Math.max(5, ...flatten(data.map((x) => x.streams.map((y) => y.value)))),
    1
  );

  const gradId = useMemo(() => Math.random().toString(36).substring(2, 5), []);

  const defs = useMemo(() => {
    const upperCol = tint(0.3, color);
    return (
      <>
        <linearGradient id={gradId} x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" style={{ stopColor: color, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: upperCol, stopOpacity: 1 }} />
        </linearGradient>
      </>
    );
  }, [color, gradId]);

  const onBarMouseOver = useCallback(
    (idx: number | null) => {
      if (onValueMouseOver)
        onValueMouseOver(
          idx == null
            ? null
            : {
                x: data[idx].label,
                y: data[idx].streams
                  .map((x) => x.label + " " + x.value)
                  .toString(),
              }
        );
    },
    [onValueMouseOver, data]
  );

  return (
    <GraphAxes
      defs={defs}
      width={400}
      yAxis={range(0, max + 1, Math.max(max / 10, 1)).map((x) => x.toString())}
      yAxisLabel={yAxis}
      xAxis={data.map((x) => x.label)}
      xAxisLabel={xAxis}
      centralLabels
      legend={streamLabels.map((x, i) => ({
        text: x,
        color: i,
      }))}
    >
      {(interpToX, interpToY) => {
        return (
          <Bars
            gradId={gradId}
            interpToX={interpToX}
            interpToY={interpToY}
            data={data}
            max={max}
            onBarMouseOver={onBarMouseOver}
          />
        );
      }}
    </GraphAxes>
  );
};
