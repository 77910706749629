import { Button, Flex, Input, ReactModal, Text } from "@coaching-culture/ui";
import { CustomCalendarComponent } from "components/CalendarAltStyle";
import { fromUnixTime, format, getUnixTime } from "date-fns";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

export type TimePeriodProps = {
  start: number;
  value: number;
  onChange: (val: number) => void;
  disabled?: boolean;
  maxValue?: Date;
};

const Container = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid transparent;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  transition: all 0.3s ease;

  &:not([disabled]) {
    cursor: pointer;
    &:hover,
    &:focus {
      border: ${(props) => props.theme.borders[1]};
      background-color: rgba(0, 0, 0, 0.02);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

const formatDate = (d: number) => format(fromUnixTime(d), "dd MMM yy");

function DeadlineModal({
  value,
  onChange,
  maxValue,
  onClose,
  isOpen,
}: {
  value: number;
  onChange: (val: number) => void;
  maxValue?: Date;
  onClose: () => void;
  isOpen: boolean;
}) {
  const [val, setVal] = useState<number>(value);

  return (
    <ReactModal isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection={"column"} justifyContent={"center"} p={3}>
        <Text mb={3} fontSize={4} fontWeight={500}>
          Change Deadline
        </Text>
        <CustomCalendarComponent>
          <ReactDatePicker
            maxDate={maxValue}
            minDate={new Date()}
            selected={fromUnixTime(val)}
            onChange={(val) => setVal(getUnixTime(val as Date))}
            customInput={<Input mb={3} />}
            dateFormat={"dd/MM/yyyy"}
            calendarStartDay={1}
            inline
          />
        </CustomCalendarComponent>
        <Button onClick={() => onChange(val)} color="primary">
          Save
        </Button>
      </Flex>
    </ReactModal>
  );
}

export function TimePeriod({
  start,
  value,
  maxValue,
  onChange,
  disabled,
}: TimePeriodProps) {
  const [modal, setModal] = useState<boolean>(false);

  const handleChange = (val: number) => {
    onChange(val);
    setModal(false);
  };

  return (
    <>
      <DeadlineModal
        value={value}
        maxValue={maxValue}
        onChange={handleChange}
        onClose={() => setModal(false)}
        isOpen={modal}
      />
      <Container
        onClick={disabled ? null : () => setModal(true)}
        disabled={disabled}
      >
        {formatDate(start)} - {formatDate(value)}
      </Container>
    </>
  );
}
