import { Flex, Loader, Panel } from "@coaching-culture/ui";
import { TabList } from "components/Tabs";
import WidgetHeader from "components/WidgetHeader";
import { MeasureTrendChart } from "pages/Solutions/MeasureTrendChart";
import { useMeasures, useRelationshipMeasures } from "queries/measures";
import { useState } from "react";
import { FaChartLine } from "react-icons/fa";

export type MeasuresTrendsModalProps = {
  relationshipId: string;
};

export const MeasuresTrendsWidget = ({
  relationshipId,
}: MeasuresTrendsModalProps) => {
  const [measureIndex, setMeasureIndex] = useState(0);
  const measures = useMeasures();
  const data = useRelationshipMeasures(relationshipId);

  const isFetched = data.isFetched && measures.isFetched;

  const activeMeasure = measures.data?.[measureIndex];

  return (
    <Panel width={"100%"} p={0}>
      <WidgetHeader title="Measures Trends" icon={FaChartLine} />
      {!isFetched ? (
        <Loader />
      ) : (
        <Flex flexDirection={"column"} p={3}>
          <TabList
            spread
            options={measures.data.map((x) => x.name)}
            activeIndex={measureIndex}
            onSelect={setMeasureIndex}
          />
          <MeasureTrendChart measure={activeMeasure} data={data.data} />
        </Flex>
      )}
    </Panel>
  );
};
