import {
  ContentItem,
  GroupMembershipLevel,
  UserDisplay,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  DotMenu,
  Flex,
  IconButton,
  Loader,
  Panel,
  PanelHeader,
  Rule,
  Table,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import ShareModal, { ShareConfig } from "components/ShareModal";
import GroupInviteModal, {
  GroupInviteConfig,
} from "components/ShareModal/InviteGroupModal";
import UserProfileImage from "components/UserProfileImage";
import { groupBy, sortBy } from "lodash";
import {
  useAcceptGroupInvitation,
  useChangeMembershipLevel,
  useCloseGroup,
  useGroup,
  useLeaveGroup,
  useRemoveContentFromGroup,
  useRemoveMembership,
  useUpdateGroup,
} from "queries/users";
import React, { useState } from "react";
import {
  FaPen,
  FaShareAlt,
  FaTimes,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

type EditGroupParams = {
  id: string;
};

const levelNames = ["Invited", "Member", "Admin", "Owner"];

const EditGroup = () => {
  const { id } = useParams<EditGroupParams>();
  const { data: group, isSuccess } = useGroup(id);
  const closeGroupMut = useCloseGroup();
  const leaveGroupMut = useLeaveGroup();
  const removeMembershipMut = useRemoveMembership(id);
  const changeMembershipLevelMut = useChangeMembershipLevel(id);
  const acceptGroupInvitationMut = useAcceptGroupInvitation(id);
  const removeContentMut = useRemoveContentFromGroup(id);
  const updateGroup = useUpdateGroup(id);
  const [inviteConfig, setInviteConfig] = useState<GroupInviteConfig | null>(
    null,
  );
  const [shareConfig, setShareConfig] = useState<ShareConfig | null>(null);
  const history = useHistory();
  const [user] = useUser();

  const loading = !isSuccess;

  let userLevel: number = group?.members.find(
    (x) => x.id === user.id,
  )?.membershipLevel;

  if (userLevel == null) {
    userLevel = -1;
  }

  if (user.permissions.includes("org-admin")) {
    userLevel = 3;
  }

  const leaveGroup = async () => {
    if (window.confirm("Are you sure you want to leave the group?")) {
      await leaveGroupMut.mutateAsync(group.id);
      history.push("/solutions/my-groups");
    }
  };

  const closeGroup = async () => {
    if (window.confirm("Are you sure you want to close the group?")) {
      await closeGroupMut.mutateAsync(group.id);
      history.push("/solutions/my-groups");
    }
  };

  const shareModule = (id: string) => {
    setShareConfig({
      type: "module",
      itemId: null,
      targetId: id,
      targetType: "user",
    });
  };

  const removeMember = async (id: string) => {
    if (
      window.confirm(
        "Are you sure you want to remove this user from the group?",
      )
    ) {
      await removeMembershipMut.mutateAsync(id);
    }
  };

  const inviteUser = () => {
    setInviteConfig({
      groupId: group.id,
      userId: null,
    });
  };

  const onInviteClose = () => {
    setInviteConfig(null);
  };

  const shareGroupModule = () => {
    setShareConfig({
      type: "module",
      itemId: null,
      targetId: group.id,
      targetType: "group",
    });
  };

  const setMembershipLevel = async (userId: string, level: number) => {
    await changeMembershipLevelMut.mutateAsync({ userId, level });
  };

  const getItems = (
    u: UserDisplay & { membershipLevel: GroupMembershipLevel },
  ) => {
    if (userLevel === -1) {
      return [];
    }

    return [
      user.id !== u.id &&
        u.membershipLevel > 0 &&
        userLevel > 1 && {
          label: "Share Content",
          cmd: "share",
        },
      userLevel === 3 &&
        user.id !== u.id &&
        u.membershipLevel === 1 && {
          label: "Promote to Admin",
          cmd: "promote",
        },
      userLevel === 3 &&
        user.id !== u.id &&
        u.membershipLevel === 2 && {
          label: "Demote to Member",
          cmd: "demote",
        },
      userLevel > 1 && {
        label: "Remove",
        cmd: "remove",
      },
    ].filter(Boolean);
  };

  const getItemClick = (id: string) => (_: number, cmd: string | undefined) => {
    if (cmd === "share") {
      shareModule(id);
    } else if (cmd === "promote") {
      setMembershipLevel(id, 2);
    } else if (cmd === "demote") {
      setMembershipLevel(id, 1);
    } else if (cmd === "remove") {
      removeMember(id);
    }
  };

  const acceptInvitation = async () => {
    await acceptGroupInvitationMut.mutateAsync();
  };

  const rejectInvitation = async () => {
    await leaveGroup();
  };

  const onShareClose = () => {
    setShareConfig(null);
  };

  const changeName = async () => {
    const name = window.prompt("Enter new group name.");
    if (name) {
      try {
        await updateGroup.mutateAsync({ name });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          window.alert(err.response?.data.message.error);
        }
      }
    }
  };

  const removeModule = async (content: ContentItem) => {
    if (
      window.confirm(
        "Are you sure you want to remove that content from the group?",
      )
    ) {
      await removeContentMut.mutateAsync(content);
    }
  };

  const admins = group?.members.filter((x) => x.membershipLevel > 1) ?? [];
  const modules =
    group?.content.filter(
      (x) => x.type === "module" || x.type === "assessment",
    ) ?? [];
  const lessons =
    Object.entries(
      groupBy(
        group?.content.filter((y) => y.type === "lesson"),
        (x) => x.category.name,
      ),
    ) ?? [];

  return (
    <CenterColumn>
      {loading ? (
        <Loader />
      ) : (
        <>
          {inviteConfig && (
            <GroupInviteModal onClose={onInviteClose} config={inviteConfig} />
          )}
          {shareConfig && (
            <ShareModal onClose={onShareClose} config={shareConfig} />
          )}
          <Flex
            mb={[3, 4]}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Circle size="large" icon={FaUsers} color="primary" mb={2} />
            <Text fontSize={5} mb={3}>
              {group.name}
            </Text>
            {userLevel > GroupMembershipLevel.Member && (
              <Flex flexDirection={["column", "row"]} style={{ gap: 12 }}>
                <Button onClick={changeName} icon={FaPen} color="primary">
                  Change Group Name
                </Button>
                <Button onClick={inviteUser} icon={FaUserPlus} color="primary">
                  Invite User
                </Button>
                <Button
                  onClick={shareGroupModule}
                  icon={FaShareAlt}
                  color="primary"
                >
                  Assign Content
                </Button>
              </Flex>
            )}
          </Flex>
          {userLevel === 0 && (
            <Panel mb={3} p={3}>
              <Flex justifyContent="space-between" alignItems="center">
                <Text mb={0} fontSize={4} fontWeight={600}>
                  You've been invited to join this group
                </Text>
                <Flex alignItems="center">
                  <Button color="positive" onClick={acceptInvitation} mr={1}>
                    Accept
                  </Button>
                  <Button color="danger" onClick={rejectInvitation}>
                    Reject
                  </Button>
                </Flex>
              </Flex>
            </Panel>
          )}
          <Flex flexDirection={["column", "row"]}>
            <Flex
              flexDirection="column"
              width={["100%", 280]}
              alignItems="stretch"
              mr={[0, 3]}
              order={[1, 0]}
            >
              <Panel mb={3}>
                <PanelHeader>
                  <Text fontWeight={600}>Group Info</Text>
                </PanelHeader>
                <Box p={3}>
                  <Text mb={1} fontWeight={600}>
                    <Text as="strong" color="primary" fontSize={4}>
                      {group.members.length}{" "}
                    </Text>
                    member{group.members.length === 1 ? "" : "s"}
                  </Text>
                  <Text>
                    {group.description
                      ? group.description
                      : "No Description Given"}
                  </Text>
                </Box>
              </Panel>
              <Panel mb={userLevel > -1 ? 3 : 0}>
                <PanelHeader>
                  <Text fontWeight={600}>Modules & Assessments</Text>
                </PanelHeader>
                <Flex p={3} flexDirection="column" alignItems="stretch">
                  {modules.length === 0 ? (
                    <Text>None assigned</Text>
                  ) : (
                    modules.map((x, i, self) => (
                      <Flex
                        alignItems="center"
                        key={x.type + x.id}
                        mb={i === self.length - 1 ? 0 : 2}
                      >
                        <Circle
                          image={
                            process.env.REACT_APP_STATIC_ROOT + "/" + x.icon
                          }
                          mr={2}
                        />
                        <Flex flexDirection="column" flex="1">
                          <Link
                            to={
                              x.type === "module"
                                ? `/solutions/mindset/modules/${x.slug}`
                                : `/solutions/assessments/modules/${x.slug}`
                            }
                          >
                            <Text fontWeight={600}>{x.name}</Text>
                          </Link>
                          <Text fontSize={2}>
                            {x.type === "module"
                              ? "Mindset Module"
                              : "Assessment"}
                          </Text>
                        </Flex>
                        {userLevel >= 2 ? (
                          <Flex>
                            <IconButton
                              icon={FaTimes}
                              color={"body"}
                              onClick={() => removeModule(x)}
                            />
                          </Flex>
                        ) : null}
                      </Flex>
                    ))
                  )}
                </Flex>
              </Panel>
              <Panel mb={userLevel > -1 ? 3 : 0}>
                <PanelHeader>
                  <Text fontWeight={600}>Lessons</Text>
                </PanelHeader>
                <Flex p={3} flexDirection="column" alignItems="stretch">
                  {lessons.map((cat, j) => (
                    <>
                      <Text mb={3} fontWeight={600} fontSize={3}>
                        {cat[0]}
                      </Text>

                      {sortBy(cat[1], "orderIndex").map((x, i, self) => (
                        <Flex
                          alignItems="center"
                          key={x.type + x.id}
                          mb={i === self.length - 1 ? 0 : 2}
                        >
                          <Circle
                            image={
                              process.env.REACT_APP_STATIC_ROOT + "/" + x.icon
                            }
                            mr={2}
                          />
                          <Flex flexDirection="column" flex="1">
                            <Link to={`/solutions/lessons/modules/${x.slug}`}>
                              <Text fontWeight={600}>{x.name}</Text>
                            </Link>
                            <Text fontSize={2}>Lesson</Text>
                          </Flex>
                          {userLevel >= 2 ? (
                            <Flex>
                              <IconButton
                                icon={FaTimes}
                                color={"body"}
                                onClick={() => removeModule(x)}
                              />
                            </Flex>
                          ) : null}
                        </Flex>
                      ))}
                      {j !== lessons.length - 1 && <Rule />}
                    </>
                  ))}
                </Flex>
              </Panel>
              <Panel mb={userLevel > -1 ? 3 : 0}>
                <PanelHeader>
                  <Text fontWeight={600}>Group Admins</Text>
                </PanelHeader>
                <Flex p={3} flexDirection="column" alignItems="stretch">
                  {admins.length === 0
                    ? "No admins"
                    : admins.map((x, i, self) => (
                        <Flex
                          alignItems="center"
                          key={x.id}
                          mb={i === self.length - 1 ? 0 : 2}
                        >
                          <UserProfileImage
                            profileImage={null}
                            name={x.name}
                            mr={2}
                            size="small"
                          />
                          <Flex flexDirection="column">
                            <Text fontWeight={600}>{x.name}</Text>
                            <Text fontSize={2}>
                              {levelNames[x.membershipLevel]}
                            </Text>
                          </Flex>
                        </Flex>
                      ))}
                </Flex>
              </Panel>
              {userLevel > -1 && (
                <Panel mb={[3, 0]}>
                  <PanelHeader>
                    <Text fontWeight={600}>Actions</Text>
                  </PanelHeader>
                  <Flex p={3} flexDirection="column" alignItems="stretch">
                    {userLevel === 3 ? (
                      <Button onClick={closeGroup} color="danger">
                        Close Group
                      </Button>
                    ) : (
                      <Button onClick={leaveGroup} color="danger">
                        Leave Group
                      </Button>
                    )}
                  </Flex>
                </Panel>
              )}
            </Flex>
            <Panel flex="1" order={[0, 1]} mb={[3, 0]}>
              <PanelHeader>
                <Text fontWeight={600}>Members</Text>
              </PanelHeader>
              <Box>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {group.members.map((x) => (
                      <tr key={x.id}>
                        <td>
                          {x.name}
                          {user.id === x.id ? " (You)" : ""}
                        </td>
                        <td>{levelNames[x.membershipLevel]}</td>
                        <td style={{ textAlign: "right" }}>
                          {x.id !== user.id && (
                            <DotMenu
                              items={getItems(x)}
                              onItemClick={getItemClick(x.id)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Panel>
          </Flex>
        </>
      )}
    </CenterColumn>
  );
};

export default EditGroup;
