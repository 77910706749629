import {
  ContentType,
  LearnCastAttemptDto,
  LearnCastDto,
  RateContentSpec,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const getLearnCast = (id: string) =>
  axios.get<LearnCastDto>(`/api/v2/learncast/${id}`).then(({ data }) => data);

export const useLearnCast = (id: string) =>
  useQuery(["learncast", id], () => getLearnCast(id));

export const getLatestLearnCastAttempt = (id: string) =>
  axios
    .get<LearnCastAttemptDto | null>(`/api/v2/learncast/${id}/attempt`)
    .then(({ data }) => data);

export const useLatestLearnCastAttempt = (id: string) =>
  useQuery(["learncast", id, "attempt"], () => getLatestLearnCastAttempt(id));

export const startNewLearnCastAttempt = (id: string) =>
  axios
    .post<LearnCastAttemptDto>(`/api/v2/learncast/${id}/attempt`)
    .then(({ data }) => data);

export const useStartNewLearnCastAttempt = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(startNewLearnCastAttempt, {
    onSuccess: (data, id) => {
      queryClient.setQueryData(["learncast", id, "attempt"], data);
    },
  });

  return mutation;
};

export const completeLearnCastAttempt = (id: string) =>
  axios
    .put<LearnCastAttemptDto>(`/api/v2/learncast/${id}/complete`)
    .then(({ data }) => data);

export const useCompleteLearnCastAttempt = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(completeLearnCastAttempt, {
    onSuccess: (data, id) => {
      queryClient.setQueryData(["learncast", id, "attempt"], data);
    },
  });

  return mutation;
};

export const rateContent = (spec: RateContentSpec) =>
  axios.put(`/api/v2/content/${spec.id}/rate`, spec);

export const useRateContent = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(rateContent, {
    onSuccess: (data, spec) => {
      queryClient.setQueryData([spec.type, spec.id, "attempt"], data);
    },
  });

  return mutation;
};
