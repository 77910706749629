import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useUser } from "../auth";
import styled from "styled-components";
import { Flex, Text, Button, Box, Circle } from "@coaching-culture/ui";
import { useSpring, animated } from "react-spring";
import useOnClickOutside from "../hooks/useOnClickOutside";
import UserProfileImage from "./UserProfileImage";
import { formatDistance } from "date-fns";
import { useHistory } from "react-router";
import {
  ConfirmAddressNotification,
  Notification,
  FullUser,
  GroupInviteNotification,
  IncomingRelationshipNotification,
  PostUserSpec,
  ShareNotification,
  FeedbackNotification,
  FeedbackResponseNotification,
  SurveyNotification,
  ConversationNotification,
  ContinuousFeedbackNotification,
  ConversationCommentNotification,
  ConversationAgendaNotification,
} from "@coaching-culture/types";
import { FaCheckCircle, FaQuestion } from "react-icons/fa";
import { sortBy } from "lodash";

const Container = styled(animated.div)`
  background: white;
  box-shadow: ${(props) => props.theme.shadows[2]};
  padding: 12px;
  border-radius: 6px;
  position: absolute;
  right: 24px;
  top: 94px;
  width: 400px;
  max-width: calc(100% - 48px);
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const relationshipNames = {
  manager: ["Line Manager", "Direct Report"],
};

type NotificationProps<T extends Notification> = {
  item: T;
  user: FullUser;
  dismiss: () => void;
  remove: () => void;
  close: () => void;
};

const ConfirmAddress = ({
  item,
  user,
  close,
  remove,
}: NotificationProps<ConfirmAddressNotification>) => {
  const history = useHistory();

  const confirm = () => {
    const spec: PostUserSpec = {
      address1: user.address1,
      address2: user.address2,
      city: user.city,
      postcode: user.postcode,
      county: user.county,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };

    remove();
    close();
    axios.put("/api/users/me", spec);
  };
  const gotoSettings = () => {
    history.push("/account-settings/delivery");
    close();
  };

  return (
    <Flex alignItems="flex-start">
      <Circle icon={FaQuestion} size={"small"} mr={2} />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>Confirm your address</Text>
        <Text mb="2px" fontSize={2}>
          Is this address still correct?
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Text mb={2} fontSize={3}>
          {[user.address1, user.address2, user.city, user.county, user.postcode]
            .filter((x) => x)
            .map((x) => (
              <>
                {x}
                <br />
              </>
            ))}
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={gotoSettings} small>
            Change
          </Button>
          <Button color="positive" onClick={confirm} small>
            Confirm
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const GroupInvite = ({
  item,
  dismiss,
  remove,
}: NotificationProps<GroupInviteNotification>) => {
  const [user] = useUser();

  const accept = () => {
    remove();
    axios.put(`/api/groups/${item.group.id}/memberships/${user.id}/accept`);
  };

  return (
    <Flex alignItems="flex-start">
      <UserProfileImage name={item.sender.name} size="small" mr={2} />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.sender.name}</Text>
        <Text mb="2px" fontSize={2}>
          Invited you to their group <strong>{item.group.name}</strong>
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={accept} small>
            Accept
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const IncomingRelationship = ({
  item,
  user,
  dismiss,
  remove,
}: NotificationProps<IncomingRelationshipNotification>) => {
  const rel = relationshipNames[item.relationship.type];
  const relName = item.relationship.from.id === user.id ? rel[0] : rel[1];

  const accept = () => {
    remove();
    axios.put(`/api/relationships/${item.relationship.id}/accept`);
  };

  return (
    <Flex alignItems="flex-start">
      <UserProfileImage
        profileImage={item.sender.profileImage}
        name={item.sender.name}
        size="small"
        mr={2}
      />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.sender.name}</Text>
        <Text mb="2px" fontSize={2}>
          Invited you to become their <strong>{relName}</strong>
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={accept} small>
            Accept
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ConversationCommentNotificationDisplay = ({
  item,
  user,
  dismiss,
  close,
  remove,
}: NotificationProps<ConversationCommentNotification>) => {
  const history = useHistory();
  const link = `/solutions/performance/conversations/${item.conversationId}/${item.conversationOccurrence}/play`;

  const gotoConvo = () => {
    dismiss();
    close();
    history.push(link);
  };

  return (
    <Flex alignItems="flex-start">
      <UserProfileImage
        profileImage={item.sender.profileImage}
        name={item.sender.name}
        size="small"
        mr={2}
      />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.sender.name}</Text>
        <Text mb="2px" fontSize={2}>
          Added a new comment to your conversation
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoConvo} small>
            Go to Conversation
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ConversationAgendaNotificationDisplay = ({
  item,
  user,
  dismiss,
  close,
  remove,
}: NotificationProps<ConversationAgendaNotification>) => {
  const history = useHistory();
  const link = `/solutions/performance/conversations/${item.conversationId}/${item.conversationOccurrence}/play`;

  const gotoConvo = () => {
    dismiss();
    close();
    history.push(link);
  };

  return (
    <Flex alignItems="flex-start">
      <UserProfileImage
        profileImage={item.sender.profileImage}
        name={item.sender.name}
        size="small"
        mr={2}
      />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.sender.name}</Text>
        <Text mb="2px" fontSize={2}>
          Altered the agenda for your conversation
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoConvo} small>
            Go to Conversation
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ShareNotificationDisplay = ({
  item,
  dismiss,
  close,
}: NotificationProps<ShareNotification>) => {
  const history = useHistory();

  const gotoModule = () => {
    dismiss();
    close();
    history.push(
      `/solutions/${
        item.content.type === "module"
          ? "mindset"
          : item.content.type === "lesson"
            ? "lessons"
            : item.content.type === "learncast"
              ? "learncast"
              : "assessments"
      }/modules/${item.content.slug}`,
    );
  };

  return (
    <Flex alignItems="flex-start">
      <Circle
        image={process.env.REACT_APP_STATIC_ROOT + "/" + item.content.icon}
        size="small"
        mr={2}
      />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.content.name}</Text>
        <Text fontSize={2} mb="2px">
          {item.sender.name} shared{" "}
          {item.content.type === "module"
            ? "a module"
            : item.content.type === "assessment"
              ? "an assessment"
              : item.content.type === "learncast"
                ? "a video"
                : "a topic"}{" "}
          with you.
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoModule} small>
            Begin
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ConversationNotificationDisplay = ({
  item,
  dismiss,
  close,
}: NotificationProps<ConversationNotification>) => {
  const history = useHistory();

  const gotoModule = () => {
    dismiss();
    close();
    history.push(`/solutions/performance`);
  };

  return (
    <Flex alignItems="flex-start">
      <Circle icon={FaCheckCircle} size="small" mr={2} />
      <Flex flexDirection="column" flex="1">
        {item.action === "new" ? (
          <>
            <Text fontWeight={600}>New Conversation</Text>
            <Text fontSize={2} mb="2px">
              {item.sender.name} has scheduled a conversation with you.
            </Text>
          </>
        ) : (
          <>
            <Text fontWeight={600}>Cancelled Conversation</Text>
            <Text fontSize={2} mb="2px">
              {item.sender.name} has cancelled a conversation with you.
            </Text>
          </>
        )}
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoModule} small>
            Goto Performance
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
const NewSurveyNotificationDisplay = ({
  item,
  dismiss,
  close,
}: NotificationProps<SurveyNotification>) => {
  const history = useHistory();

  const gotoModule = () => {
    dismiss();
    close();
    history.push(`/solutions/surveys/${item.surveyId}`);
  };

  return (
    <Flex alignItems="flex-start">
      <Circle icon={FaCheckCircle} size="small" mr={2} />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.name}</Text>
        <Text fontSize={2} mb="2px">
          You've been invited to complete a Survey.
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoModule} small>
            Begin
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ContinuousFeedbackNotificationDisplay = ({
  item,
  dismiss,
  close,
}: NotificationProps<ContinuousFeedbackNotification>) => {
  const history = useHistory();

  const gotoModule = () => {
    dismiss();
    close();
    history.push(`/solutions/continuous-feedback/view/${item.feedbackId}`);
  };

  return (
    <Flex alignItems="flex-start">
      <Circle icon={FaCheckCircle} size="small" mr={2} />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>
          You have recieved some feedback from {item.sender}
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoModule} small>
            View
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const FeedbackNotificationDisplay = ({
  item,
  dismiss,
  close,
}: NotificationProps<FeedbackNotification>) => {
  const history = useHistory();

  const gotoModule = () => {
    dismiss();
    close();
    history.push(`/solutions/feedback/campaigns/${item.userCampaignId}`);
  };

  return (
    <Flex alignItems="flex-start">
      <Circle icon={FaCheckCircle} size="small" mr={2} />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.name}</Text>
        <Text fontSize={2} mb="2px">
          You've been asked to get some feedback.
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoModule} small>
            Begin
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const FeedbackResponseNotificationDisplay = ({
  item,
  dismiss,
  close,
}: NotificationProps<FeedbackResponseNotification>) => {
  const history = useHistory();

  const gotoModule = () => {
    dismiss();
    close();
    history.push(`/solutions/feedback/campaigns/${item.userCampaignId}`);
  };

  return (
    <Flex alignItems="flex-start">
      <Circle icon={FaCheckCircle} size="small" mr={2} />
      <Flex flexDirection="column" flex="1">
        <Text fontWeight={600}>{item.name}</Text>
        <Text fontSize={2} mb="2px">
          Someone has given you feedback.
        </Text>
        <Text fontSize={1} mb={2}>
          {formatDistance(new Date(item.created), new Date())} ago
        </Text>
        <Flex alignItems="center">
          <Button mr={1} onClick={dismiss} small>
            Dismiss
          </Button>
          <Button color="positive" onClick={gotoModule} small>
            Begin
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
const NotificationDisplay = ({
  item,
  ...rest
}: NotificationProps<Notification>) => {
  if (item.type === "incoming_relationship") {
    return <IncomingRelationship item={item} {...rest} />;
  } else if (item.type === "share") {
    return <ShareNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "group") {
    return <GroupInvite item={item} {...rest} />;
  } else if (item.type === "confirm_address") {
    return <ConfirmAddress item={item} {...rest} />;
  } else if (item.type === "new_feedback_campaign") {
    return <FeedbackNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "feedback_response") {
    return <FeedbackResponseNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "new_survey") {
    return <NewSurveyNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "conversation") {
    return <ConversationNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "continuous_feedback_sent") {
    return <ContinuousFeedbackNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "conversation_comment") {
    return <ConversationCommentNotificationDisplay item={item} {...rest} />;
  } else if (item.type === "conversation_agenda") {
    return <ConversationAgendaNotificationDisplay item={item} {...rest} />;
  }

  return null;
};

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 26,
};

type NotificationListProps = {
  notifications: Notification[];
  onClose: () => void;
  onRemove: (id: string) => void;
};

const NotificationList = ({
  notifications,
  onClose,
  onRemove,
}: NotificationListProps) => {
  const ref = useRef();
  const [user] = useUser();

  useOnClickOutside(ref, () => {
    onClose();
  });

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onClose]);

  const spring = useSpring({
    config: springConfig,
    opacity: 1,
    transform: "translate3d(0,0px,0) scale(1, 1)",
    from: {
      opacity: 0,
      transform: "translate3d(0,-100px,0) scale(1, 0.8)",
    },
  });

  const dismiss = (id) => {
    axios.delete(`/api/notifications/${id}`);
    onRemove(id);
  };

  return (
    <Container style={spring} ref={ref}>
      {notifications.length === 0 ? (
        <Text textAlign="center" fontWeight={500}>
          Nothing to see here...
        </Text>
      ) : (
        sortBy(notifications, (x) => new Date(x.created))
          .reverse()
          .map((x, i) => (
            <Box mb={i === notifications.length - 1 ? 0 : 2}>
              <NotificationDisplay
                item={x}
                user={user}
                dismiss={() => dismiss(x.id)}
                remove={() => onRemove(x.id)}
                close={onClose}
              />
            </Box>
          ))
      )}
    </Container>
  );
};

export default NotificationList;
