import { Box, Flex, Text } from "@coaching-culture/ui";
import { lighten } from "polished";
import { Link, useRouteMatch } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import logoSrc from "../img/logo.png";
import logoCommunity from "../img/logoCommunity.png";
import logoSolutions from "../img/logoSolutions.png";
import logoSuccess from "../img/logoSuccess.png";

const Container = styled(Flex)`
  background: white;
  height: 100%;
  width: 100%;
`;

const Strap = styled(Flex)<{ strapColor: string }>`
  background-color: ${(props) =>
    lighten(0.35, props.theme.colors[props.strapColor])};
`;

const Logo = styled.img`
  height: 90px;
`;

const SmallLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.grey};
`;

const FrameElem = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 130%;
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    display: block;
  }
`;

const Frame = ({ colors }: { colors: string[] }) => (
  <FrameElem xmlns="http://www.w3.org/2000/svg" viewBox="0 0 822.667 839.333">
    <path
      d="M424.487 16.683H14.473v606.966h115.389l226.24 188.874V636.868"
      fill="none"
      stroke={colors[0]}
      stroke-width="23.301"
    />
    <circle cx="458.594" cy="16.475" r="13.133" fill={colors[1]} />
    <circle cx="498.287" cy="16.475" r="13.133" fill={colors[2]} />
    <circle cx="537.98" cy="16.475" r="13.133" fill={colors[3]} />
    <circle cx="577.673" cy="16.475" r="13.133" fill={colors[4]} />
  </FrameElem>
);

const Messages = [
  () => (
    <Text
      fontWeight={600}
      fontSize={["36px", "40px", "55px", "70px"]}
      lineHeight={[1.3, 1.1]}
      mb={0}
      color="grey1"
    >
      Your one stop
      <br />
      shop for creating
      <br />
      a coaching
      <br />
      culture
    </Text>
  ),
  () => (
    <Text
      fontWeight={600}
      fontSize={["36px", "60px", "75px"]}
      lineHeight={[1.3, 1.1]}
      mb={0}
      color="grey1"
    >
      Welcome to
      <br />
      the Coaching
      <br />
      Culture
      <br />
      Community
    </Text>
  ),
  () => (
    <Text
      fontWeight={600}
      fontSize={["36px", "60px", "80px"]}
      lineHeight={[1.3, 1.1]}
      mb={0}
      color="grey1"
    >
      Welcome to
      <br />
      the Solutions
      <br />
      Platform
    </Text>
  ),
  () => (
    <Text
      fontWeight={600}
      fontSize={["36px", "60px", "65px", "75px"]}
      lineHeight={[1.3, 1.1]}
      mb={0}
      color="grey1"
    >
      Your Coaching
      <br />
      Culture success
      <br />
      starts here
    </Text>
  ),
];

const LoginFrame = ({ children }) => {
  const solutions = useRouteMatch("/solutions");
  const community = useRouteMatch("/community");
  const success = useRouteMatch("/success");

  const i =
    community != null ? 1 : solutions != null ? 2 : success != null ? 3 : 0;

  const theme = useTheme();
  const Message = Messages[i];
  const color = ["grey1", "orange", "blue", "green"][i];

  const logo = [logoSrc, logoCommunity, logoSolutions, logoSuccess][i];

  const frameColors = Array(5).fill(theme.colors[color], 0, 5);
  if (i === 0) {
    frameColors[1] = theme.colors.orange;
    frameColors[2] = theme.colors.blue;
    frameColors[3] = theme.colors.green;
    frameColors[4] = theme.colors.pink;
  }

  return (
    <Container alignItems={["stretch", "center"]}>
      <Flex flexDirection="column" width="100%" alignItems="center">
        <Flex
          justifyContent="flex-end"
          p={[2, 4]}
          maxWidth="1400px"
          width="100%"
        >
          <Logo src={logo} />
        </Flex>
        <Strap
          strapColor={color}
          alignItems={["flex-start", "center"]}
          justifyContent="center"
          width="100%"
          flex={[1, "auto"]}
        >
          <Flex
            maxWidth="1400px"
            alignItems={["stretch", "center"]}
            width="100%"
            flexDirection={["column", "row"]}
          >
            <Flex flex={[1, 1.2]} p={[4, 6]} pr={[4, 0]} pb={[0, 6]}>
              <Box
                style={{ position: "relative" }}
                p={[0, 5, 7]}
                pr={[0, 0, 0, 0]}
              >
                <Frame colors={frameColors} />
                <Message />
              </Box>
            </Flex>
            <Flex
              flex={["1", "0.8"]}
              flexDirection="column"
              pl={[4, 2, 6]}
              pr={[4, 2, 6]}
              pt={[4, 2, 6]}
              pb={[4, 2, 6]}
            >
              {children}
              <Flex justifyContent="space-between" width="100%">
                <SmallLink to="/privacy-policy">Privacy Policy</SmallLink>
                <SmallLink to="/terms-of-use">Terms of Use</SmallLink>
              </Flex>
            </Flex>
          </Flex>
        </Strap>
        <Flex p={4} display={["none", "flex"]}>
          <Box height="90px" />
        </Flex>
      </Flex>
    </Container>
  );
};

export default LoginFrame;
