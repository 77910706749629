import {
  ContentAccessReason,
  ProductIdent,
  ProductVisibility,
  UserContentDto,
} from "@coaching-culture/types";
import { Flex, Text, UserProfileImage } from "@coaching-culture/ui";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { intersection, sortBy, uniqBy } from "lodash";
import { useAvailableContent } from "queries/mindset";
import React, { useMemo } from "react";
import {
  FaBook,
  FaBroadcastTower,
  FaChartBar,
  FaChartLine,
  FaChevronLeft,
  FaCog,
  FaCommentDots,
  FaEnvelope,
  FaKey,
  FaMedal,
  FaPalette,
  FaRev,
  FaShieldAlt,
  FaSignal,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import { Link, NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import styled from "styled-components";
import assessmentsIcon from "../img/assessments.svg";
import diagnosticsIcon from "../img/diagnostics.svg";
import eventsIcon from "../img/events.svg";
import feedbackIcon from "../img/feedback.svg";
import lessonsIcon from "../img/lessons.svg";
import magazineIcon from "../img/magazine.svg";
import mindsetIcon from "../img/mindset.svg";
import performanceIcon from "../img/performance.svg";
import podcastsIcon from "../img/podcast.svg";
import resourcesIcon from "../img/resources.svg";
import surveysIcon from "../img/surveys.svg";
import webinarsIcon from "../img/webinars.svg";
import { Sidebar } from "./Sidebar";

const ProductIcon = styled.img`
  width: 1.2em;
  height: 1.2em;
`;

const MainNavLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
  padding-left: 80px;
  color: ${(props) => props.theme.colors.grey1};
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.grey6};
  }

  &:before {
    position: absolute;
    width: 3px;
    left: 0;
    top: 0;
    height: 100%;
    content: " ";
  }

  & svg,
  & ${ProductIcon} {
    margin-right: 18px;
    opacity: 0.7;
  }

  &.active {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.grey6};

    &:before {
      background-color: ${(props) => props.theme.colors.primary};
    }

    & svg,
    & ${ProductIcon} {
      opacity: 1;
    }
  }
`;

const MenuSubLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
  padding-left: 115px;
  color: ${(props) => props.theme.colors.grey1};
  font-weight: 500;
  font-size: 13px;
  position: relative;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.grey6};
  }

  &:before {
    content: "";
    height: 100%;
    display: block;
    width: 1px;
    top: 0;
    position: absolute;
    left: 85px;
    background-color: ${(props) => props.theme.colors.grey4};
  }

  & svg {
    margin-right: 18px;
    font-size: 1.2em;
  }

  &.active {
    color: ${(props) => props.theme.colors.body};
    background-color: ${(props) => props.theme.colors.grey6};
  }
`;

const LinkSectionHeader = styled(Text)`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey1};
  padding-left: 50px;
  font-size: 13px;
`;

const SubNavContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 12px;
    pointer-events: none;
  }
`;

type SubNavProps = {
  path: string;
  exact?: boolean;
  children: React.ReactNode;
};

const SubNav = ({ children, path, exact = false }: SubNavProps) => {
  const [ref, { height }] = useMeasure();
  const match = useRouteMatch({ path, exact });

  const style = useSpring({
    from: {
      height: 0,
    },
    height: match ? height : 0,
  });
  if (match !== null) {
    return (
      <SubNavContainer style={style}>
        <div ref={ref}>{children}</div>
      </SubNavContainer>
    );
  }
  return null;
};

const MindsetSubNav = () => {
  const assignedReasons: ContentAccessReason[] = [
    "user",
    "group",
    "admin",
    "userfield",
  ];
  const publicReasons: ContentAccessReason[] = ["public", "manager"];

  const isAssigned = (module: UserContentDto) =>
    assignedReasons.some((x) => module.assignmentReasons.includes(x));

  const isPublic = (module: UserContentDto) =>
    publicReasons.some((x) => module.assignmentReasons.includes(x));

  const { data, isFetched } = useAvailableContent();

  if (!isFetched) {
    return null;
  }

  const publicModules = data
    .filter((x) => x.type === "module")
    .filter(isPublic);
  const assigned = data.filter((x) => x.type === "module").filter(isAssigned);

  const navLinks = [];

  if (assigned.length > 0) {
    navLinks.push({
      label: "My Modules",
      url: "/solutions/mindset/library/me",
    });
  }

  if (publicModules.length > 0) {
    navLinks.push({
      label: "Public Modules",
      url: "/solutions/mindset/library/public",
    });
  }

  navLinks.push({
    label: "My Mindset",
    url: "/solutions/mindset/me",
  });

  return (
    <>
      {navLinks.map((x) => (
        <MenuSubLink key={x.url} to={x.url}>
          {x.label}
        </MenuSubLink>
      ))}
    </>
  );
};

const BetaLabel = styled.span`
  font-size: 8px;
  color: white;
  margin-bottom: 6px;
  font-weight: 500;
  border-radius: 7px;
  margin-left: 6px;
  padding: 0 4px;
  display: inline-block;
  background-color: ${(props) => props.theme.colors.primary};
  height: 13px;
`;

const LessonsSubNav = () => {
  const { data, isFetched } = useAvailableContent();

  if (!isFetched) {
    return null;
  }

  const cats = sortBy(
    uniqBy(
      (data ?? []).filter((x) => x.type === "lesson").map((x) => x.category),
      "id",
    ),
    "orderIndex",
  );

  return (
    <>
      {cats.map((x) => (
        <MenuSubLink key={x.id} to={`/solutions/lessons/${x.id}`}>
          {x.name.replace(" (beta)", "")}
          {x.name.includes("(beta)") && <BetaLabel>BETA</BetaLabel>}
        </MenuSubLink>
      ))}
    </>
  );
};

const BottomAnchor = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
`;

const BottomSpacer = styled.div`
  width: 100%;
  height: 60px;
`;

const ReturnLink = styled(Link)`
  display: flex;
  padding: 12px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  & svg {
    margin-right: 12px;
    margin-left: -24px;
  }
`;

type AccessRequirements = {
  product?: ProductIdent | ProductIdent[];
  permissions?: string[];
};

type MenuSection = {
  label?: string;
  items: (AccessRequirements & {
    label: string;
    url: string;
    icon: React.ReactNode;
    subItems?:
      | (() => React.ReactNode)
      | (AccessRequirements & {
          label: string;
          url: string;
          exact?: boolean;
        })[];
  })[];
};

const MenuSectionDisplay = ({ section }: { section: MenuSection }) => {
  const [user] = useUser();
  const products = useAllProductVisibility();

  const hasProduct = (ident: ProductIdent | ProductIdent[]) => {
    const p = Array.isArray(ident) ? ident : [ident];
    return p.some((x) => products[x] === ProductVisibility.Enabled);
  };

  const isValidItem = (x: AccessRequirements): boolean => {
    if (x.product != null && !hasProduct(x.product)) {
      return false;
    }

    if (
      x.permissions != null &&
      intersection(x.permissions, user.permissions).length === 0
    ) {
      return false;
    }

    return true;
  };

  const validItems = section.items.filter(isValidItem);

  if (validItems.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" alignItems="center" mb={3}>
      <LinkSectionHeader>{section.label}</LinkSectionHeader>
      {validItems.map((x, i) => {
        const renderSubItems = () => {
          if (Array.isArray(x.subItems)) {
            const subItems = (x.subItems ?? []).filter(isValidItem);

            return (
              subItems.length > 0 && (
                <SubNav path={x.url}>
                  {subItems.map((x, i) => (
                    <MenuSubLink to={x.url} exact={x.exact === true} key={i}>
                      {x.label}
                    </MenuSubLink>
                  ))}
                </SubNav>
              )
            );
          } else if (x.subItems == null) {
            return null;
          } else {
            return x.subItems();
          }
        };

        return (
          <React.Fragment key={i}>
            <MainNavLink to={x.url}>
              <>
                {x.icon}
                {x.label}
              </>
            </MainNavLink>
            {renderSubItems()}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

const communityMenu: MenuSection = {
  label: "Community",
  items: [
    {
      url: "/community/magazine",
      icon: <ProductIcon src={magazineIcon} />,
      label: "Magazine",
    },
    {
      url: "/community/podcasts",
      icon: <ProductIcon src={podcastsIcon} />,
      label: "Podcasts",
    },
    {
      url: "/community/webinars",
      icon: <ProductIcon src={webinarsIcon} />,
      label: "Webinars",
    },
    {
      url: "/community/resources",
      icon: <ProductIcon src={resourcesIcon} />,
      label: "Resources",
    },
    {
      url: "/community/events",
      icon: <ProductIcon src={eventsIcon} />,
      label: "Events",
    },
    {
      url: "/community/diagnostics",
      icon: <ProductIcon src={diagnosticsIcon} />,
      product: "diagnostics",
      label: "Diagnostics",
    },
  ],
};

const getSuccessMenu = (goalName: string): MenuSection[] => [
  {
    label: "People",
    items: [
      {
        url: "/success/people/users",
        label: "Users",
        permissions: ["user-admin"],
        icon: <FaUserFriends />,
      },
      {
        url: "/success/people/groups",
        label: "Groups",
        permissions: ["user-admin"],
        icon: <FaUsers />,
      },
    ],
  },
  {
    label: "Insights",
    items: [
      {
        url: "/success/insights",
        label: "General",
        permissions: ["org-admin"],
        icon: <FaChartBar />,
      },
      {
        url: "/success/insights/performance",
        label: "Performance Insights",
        product: "performance",
        permissions: ["org-admin"],
        icon: <ProductIcon src={performanceIcon} />,
      },
      {
        url: "/success/insights/lessons",
        label: "Lesson Reports",
        product: "lessons",
        permissions: ["org-admin"],
        icon: <ProductIcon src={lessonsIcon} />,
      },
      {
        url: "/success/insights/mindset",
        label: "Mindset Reports",
        product: "mindset",
        permissions: ["org-admin"],
        icon: <ProductIcon src={mindsetIcon} />,
      },
      {
        url: "/success/insights/assessments",
        label: "Assessments Reports",
        product: "assessments",
        permissions: ["org-admin"],
        icon: <ProductIcon src={assessmentsIcon} />,
      },
    ],
  },
  {
    label: "Campaigns",
    items: [
      {
        url: "/success/feedback",
        icon: <ProductIcon src={feedbackIcon} />,
        label: "Feedback",
        product: "360",
        permissions: ["org-admin"],
        subItems: [
          {
            url: "/success/feedback/campaigns",
            label: "Campaigns",
          },
          {
            url: "/success/feedback/forms",
            label: "Forms",
          },
        ],
      },
      {
        url: "/success/surveys",
        icon: <ProductIcon src={surveysIcon} />,
        label: "Surveys",
        product: "surveys",
        permissions: ["org-admin"],
        subItems: [
          {
            url: "/success/surveys/campaigns",
            label: "Campaigns",
          },
          {
            url: "/success/surveys/forms",
            label: "Forms",
          },
        ],
      },
    ],
  },
  {
    label: "Comms",
    items: [
      {
        label: "New Email",
        url: "/success/people/comms",
        permissions: ["org-admin"],
        icon: <FaEnvelope />,
      },
    ],
  },

  {
    label: "Platform Settings",
    items: [
      {
        label: "Self Registration",
        url: "/success/people/self-registration",
        permissions: ["org-admin"],
        icon: <FaKey />,
      },
      {
        url: "/success/downloads",
        icon: <ProductIcon src={resourcesIcon} />,
        label: "Downloads",
        permissions: ["org-admin"],
      },
      {
        url: "/success/people/user-fields",
        label: "User Fields",
        permissions: ["org-admin"],
        icon: <FaUsers />,
      },
      {
        url: "/success/performance",
        icon: <FaMedal />,
        label: `${goalName} Configuration`,
        permissions: ["org-admin"],
        product: "performance",
        subItems: [
          {
            url: "/success/performance/org-goals",
            label: `Organisation ${goalName}s`,
          },
          {
            url: "/success/performance/goals",
            label: `${goalName} Configuration`,
          },
          {
            url: "/success/performance/goal-export",
            label: `${goalName} Export`,
          },
        ],
      },
      {
        label: "Conversation Types",
        url: "/success/settings/conversation-types",
        product: "performance",
        icon: <FaCommentDots />,
        permissions: ["org-admin"],
      },
      {
        label: "Measures ",
        url: "/success/settings/measures",
        product: "measures",
        icon: <FaChartLine />,
        permissions: ["org-admin"],
      },
      {
        url: "/success/settings/content",
        icon: <FaBook />,
        label: "Content Access",
        permissions: ["org-admin"],
        subItems: [
          {
            url: "/success/settings/content/mindset",
            label: "Mindset",
            product: "mindset",
          },
          {
            url: "/success/settings/content/lessons",
            label: "Lessons",
            product: "lessons",
          },
          {
            url: "/success/settings/content/assessments",
            label: "Assessments",
            product: "assessments",
          },
          {
            url: "/success/settings/content/learncast",
            label: "Learn Cast",
            product: "learncast",
          },
        ],
      },
      {
        url: "/success/settings/security",
        icon: <FaShieldAlt />,
        label: "Single Sign On",
        permissions: ["org-admin"],
      },
      {
        url: "/success/settings/branding",
        icon: <FaPalette />,
        label: "Branding",
        permissions: ["org-admin"],
        product: "branding",
      },
      {
        url: "/success/addons",
        icon: <FaSignal />,
        label: "Integrations",
        permissions: ["org-admin"],
      },
    ],
  },
];

const makeSolutionsMenu = (goalName: string): MenuSection[] => [
  {
    items: [
      {
        label: "Dashboard",
        icon: <FaRev />,
        url: "/dashboard",
        subItems: [
          {
            url: "/dashboard/content/me",
            label: "My Content",
          },
        ],
      },
      {
        url: "/solutions/my-team",
        icon: <FaUserFriends />,
        label: "My Team",
        product: [
          "mindset",
          "lessons",
          "360",
          "surveys",
          "assessments",
          "performance",
        ],
        subItems: [
          {
            url: "/solutions/my-team",
            label: "Team",
            exact: true,
          },
          {
            url: "/solutions/my-team/insights",
            label: "Insights",
            permissions: ["manager"],
          },
        ],
      },
    ],
  },
  {
    label: "PERFORM",
    items: [
      {
        url: "/solutions/performance/conversations",
        product: "performance",
        icon: <FaCommentDots />,
        label: "Conversations",
      },
      {
        url: "/solutions/performance/goals",
        product: "performance",
        icon: <FaMedal />,
        label: goalName,
      },
      {
        url: "/solutions/feedback",
        icon: <ProductIcon src={feedbackIcon} />,
        product: "360",
        label: "Feedback",
      },
      {
        label: "Continuous Feedback",
        product: "cont-feedback",
        url: "/solutions/continuous-feedback",
        icon: <FaEnvelope />,
      },
    ],
  },
  {
    label: "GROW",
    items: [
      {
        url: "/solutions/mindset",
        icon: <ProductIcon src={mindsetIcon} />,
        label: "Mindset",
        product: "mindset",
        subItems: () => (
          <SubNav path="/solutions/mindset">
            <MindsetSubNav />
          </SubNav>
        ),
      },

      {
        url: "/solutions/lessons",
        icon: <ProductIcon src={lessonsIcon} />,
        label: "Lessons",
        product: "lessons",
        subItems: () => (
          <SubNav path="/solutions/lessons">
            <LessonsSubNav />
          </SubNav>
        ),
      },
      {
        url: "/solutions/assessments",
        product: "assessments",
        icon: <ProductIcon src={assessmentsIcon} />,
        label: "Assessments",
        subItems: [
          {
            url: "/solutions/assessments",
            label: "Library",
          },
          {
            url: "/solutions/assessments/me",
            label: "Previous Results",
          },
        ],
      },
      {
        url: "/solutions/learncast",
        product: "learncast",
        icon: <FaBroadcastTower />,
        label: "LearnCast",
      },
    ],
  },
  {
    label: "RELATIONSHIPS",
    items: [
      {
        url: "/solutions/my-groups",
        product: [
          "mindset",
          "lessons",
          "360",
          "surveys",
          "assessments",
          "performance",
        ],
        icon: <FaUsers />,
        label: "My Groups",
      },
    ],
  },
];

export const MainNav = () => {
  const [user] = useUser();

  const goalTerms = useGoalTerminology();
  const productVis = useAllProductVisibility();

  const hasSolutions = [
    "mindset",
    "lessons",
    "assessments",
    "360",
    "surveys",
    "performance",
  ].some((x) => productVis[x] === ProductVisibility.Enabled);

  const solutionsMenu = useMemo(
    () => makeSolutionsMenu(goalTerms.goal.asPluralTitle()),
    [goalTerms],
  );

  const goalName = goalTerms.goal.asTitle();
  const successMenu = useMemo(() => getSuccessMenu(goalName), [goalName]);

  if (user == null) {
    // We don't have to worry about this because the auth route will redirect away
    // if the user is null.
    return null;
  }

  const isOrgAdmin = user?.permissions.includes("org-admin") ?? false;
  const isUserAdmin = user?.permissions.includes("user-admin") ?? false;

  return (
    <Sidebar side="left" alwaysFloat={false}>
      <Flex flexDirection="column" alignItems="center" pt={5} mb={5}>
        <UserProfileImage
          name={user.name}
          size="xxlarge"
          profileImage={user.profileImage}
          mb={3}
        />
        <Text fontSize={4} fontWeight={600}>
          {user.name}
        </Text>
        {user.jobTitle && (
          <Text fontWeight={500} color="grey1">
            {user.jobTitle}
          </Text>
        )}
        <Text fontSize={2} mt={1}>
          <Link to="/account-settings">Edit Profile</Link>
        </Text>
      </Flex>
      <Switch>
        <Route path="/community">
          <MenuSectionDisplay section={communityMenu} />
        </Route>
        <Route path="/success">
          {successMenu.map((x, i) => (
            <MenuSectionDisplay section={x} key={i} />
          ))}
        </Route>
        <Route>
          {solutionsMenu.map((x, i) => (
            <MenuSectionDisplay section={x} key={i} />
          ))}
        </Route>
      </Switch>
      <BottomSpacer />
      <Switch>
        <Route path="/(success|community)">
          {hasSolutions && (
            <BottomAnchor>
              <ReturnLink to="/">
                <FaChevronLeft />
                Return to Solutions
              </ReturnLink>
            </BottomAnchor>
          )}
        </Route>
        <Route>
          {(isOrgAdmin || isUserAdmin) && (
            <BottomAnchor>
              <ReturnLink to="/success">
                <FaCog />
                Admin
              </ReturnLink>
            </BottomAnchor>
          )}
        </Route>
      </Switch>
    </Sidebar>
  );
};
