import {
  CoachingTopicDto,
  CreateConversationCommand,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Circle,
  Flex,
  FormInput,
  FormInputWrap,
  FormSelect,
  FormTextArea,
  IconButton,
  Img,
  Input,
  Loader,
  ReactModal,
  Panel,
  PanelHeader,
  Rule,
  Text,
  FormToggle,
} from "@coaching-culture/ui";
import { FormDateSelect } from "components/FormDateSelect";
import { FormUserSelect } from "components/FormUserSelect";
import { PageHeader } from "components/PageHeader";
import {
  fromUnixTime,
  getDate,
  getDay,
  getUnixTime,
  startOfDay,
} from "date-fns";
import { isEmpty } from "lodash";
import { useCreateConversation } from "queries/conversations";
import { useConversationTypes } from "queries/conversationTypes";
import { useTeam } from "queries/performance";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ReactDatePicker from "react-datepicker";
import { FaCommentDots, FaGripVertical, FaTimes } from "react-icons/fa";
import { useHistory, useLocation } from "react-router";
import { arrMove, ordinal } from "utils";
import { getDayName } from "utils/dates";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import { CoachingTopicsModal } from "./CoachingTopicsModal";
import participantSelectImg from "../../../img/participantSelect.svg";
import { ConversationTypeSelect } from "./ConversationTypeSelect";

const getDayOcc = (date: Date) => {
  const num = Math.ceil(getDate(date) / 7);

  return num === 5 ? "last" : ordinal(Math.ceil(getDate(date) / 7));
};

export type ParticipantPickerProps = {
  onConfirm: (withManager: boolean) => void;
  onClose: () => void;
  isOpen: boolean;
};

function ParticipantPickerModal({
  onConfirm,
  onClose,
  isOpen,
}: ParticipantPickerProps) {
  return (
    <ReactModal onClose={onClose} isOpen={isOpen} width={600}>
      <Box p={4}>
        <Flex alignItems={"center"} flexDirection={"column"}>
          <Flex mb={3}>
            <Text fontWeight={600} fontSize={4}>
              Who is your conversation going to be with?
            </Text>
          </Flex>
          <Img mb={3} src={participantSelectImg} height={"200px"} />
          <Button mb={2} onClick={() => onConfirm(false)} color="primary">
            Direct Report
          </Button>
          <Button onClick={() => onConfirm(true)} color="primary">
            Manager
          </Button>
        </Flex>
      </Box>
    </ReactModal>
  );
}

export function CreateConversation() {
  const { data: team, isFetched: teamFetched } = useTeam();
  const { data: types, isFetched: typesFetched } = useConversationTypes();
  const create = useCreateConversation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const history = useHistory();
  const { userId } = useLocation<{ userId: string }>().state ?? {};
  const endDatePicker = useRef<ReactDatePicker>(null!);
  const [addingCoachingTopics, setAddingCoachingTopics] = useState(false);
  const [withManager, setWithManager] = useState(false);
  const [showParticipantPicker, setShowParticipantPicker] = useState(false);

  const [spec, setSpec] = useState<Partial<CreateConversationCommand>>({
    directReportId: userId,
    purpose: "",
    outcome: "",
    preparation: "",
    duration: 60,
    location: "",
    isOnlineMeeting: false,
    recurrence: "none",
  });

  useEffect(() => {
    if (team != null) {
      if (team?.directReports.length > 0 && team.lineManagers.length === 0) {
        setWithManager(false);
      } else if (
        team?.directReports.length === 0 &&
        team?.lineManagers.length > 0
      ) {
        setWithManager(true);
      } else if (userId == null) {
        setShowParticipantPicker(true);
      }
    }
  }, [team, userId]);

  const recurrenceOptions = useMemo(() => {
    if (spec.scheduledDate == null) {
      return [{ label: "Does not repeat", value: "none" }];
    } else {
      return [
        { label: "Does not repeat", value: "none" },
        {
          label: `Repeat every ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )}`,
          value: "weekly",
        },
        {
          label: `Repeat every ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every 2 weeks`,
          value: "2weekly",
        },
        {
          label: `Repeat every ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every 3 weeks`,
          value: "3weekly",
        },
        {
          label: `Repeat every ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every 4 weeks`,
          value: "4weekly",
        },
        {
          label: `Repeat every ${getDayOcc(
            fromUnixTime(spec.scheduledDate),
          )} ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every month`,
          value: "monthly",
        },
        {
          label: `Repeat every ${getDayOcc(
            fromUnixTime(spec.scheduledDate),
          )} ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every 2 months`,
          value: "2monthly",
        },
        {
          label: `Repeat every ${getDayOcc(
            fromUnixTime(spec.scheduledDate),
          )} ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every 3 months`,
          value: "3monthly",
        },
        {
          label: `Repeat every ${getDayOcc(
            fromUnixTime(spec.scheduledDate),
          )} ${getDayName(
            getDay(fromUnixTime(spec.scheduledDate)),
          )} every 6 months`,
          value: "6monthly",
        },
        {
          label: `Repeat every year`,
          value: "yearly",
        },
      ];
    }
  }, [spec.scheduledDate]);

  const loading = [teamFetched, typesFetched].some((x) => !x);

  const setSpecItem = (key: keyof CreateConversationCommand) => (val: any) => {
    setSpec({
      ...spec,
      [key]: val,
    });
  };

  const setConversationType = (id: string) => {
    const type = types.find((x) => x.id === id);

    setSpec({
      ...spec,
      conversationTypeId: id,
      purpose: type.name,
      discussionPoints: type.discussionPoints.map((x) => x.name),
    });
  };

  const removeAgendaItem = (idx: number) => {
    setSpec({
      ...spec,
      discussionPoints: spec.discussionPoints.filter((_, i) => i !== idx),
    });
  };

  const addAgendaItem = () => {
    setSpec({
      ...spec,
      discussionPoints: [...spec.discussionPoints, ""],
    });
  };

  const getErrors = (spec: any) => {
    const errors: Record<string, string> = {};

    const required: (keyof CreateConversationCommand)[] = [
      withManager ? "managerId" : "directReportId",
      "conversationTypeId",
      "scheduledDate",
    ];

    for (const r of required) {
      if (spec[r] == null || spec[r] === "") {
        errors[r] = "Value is required";
      }
    }

    if (Number.isNaN(spec.duration)) {
      errors["duration"] = "Must be a number";
    }

    return errors;
  };

  const setAgendaItem = (idx: number, value: string) => {
    const dp = [...spec.discussionPoints];
    dp[idx] = value;
    setSpec({
      ...spec,
      discussionPoints: dp,
    });
  };

  const submit = async () => {
    const e = getErrors(spec);
    setErrors(e);

    if (isEmpty(e)) {
      try {
        await create.mutateAsync(spec as CreateConversationCommand);
        history.push("/solutions/performance/conversations");
      } catch (err) {
        window.alert(err);
      }
    }
  };

  const saveTopics = (topics: Partial<CoachingTopicDto[]>) => {
    const temp = topics.map((x) => x.body);

    setSpec({
      ...spec,
      discussionPoints: [...spec.discussionPoints, ...temp],
    });
    setAddingCoachingTopics(false);
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    setSpec({
      ...spec,
      discussionPoints: arrMove(
        spec.discussionPoints,
        result.source.index,
        result.destination.index,
      ),
    });
  };

  return (
    <>
      <CoachingTopicsModal
        isOpen={addingCoachingTopics}
        onSave={(val) => saveTopics(val)}
        onCancel={() => setAddingCoachingTopics(false)}
      />

      <ParticipantPickerModal
        isOpen={showParticipantPicker}
        onConfirm={(val) => {
          setWithManager(val);
          setShowParticipantPicker(false);
        }}
        onClose={() => {
          return;
        }}
      />
      <PageHeader
        text="Schedule New Conversation"
        subtitle="Schedule regular conversations with your team..."
        backUrl="/solutions/performance/conversations"
      />
      {loading ? (
        <Loader />
      ) : (
        <Flex
          flex={1}
          flexWrap={["wrap", "nowrap"]}
          flexDirection={"row"}
          alignItems={"flex-start"}
        >
          <Flex width={["100%", "40%"]} style={{ minWidth: "450px" }}>
            <Panel width={"100%"} p={[2, 3]}>
              {!withManager ? (
                <FormUserSelect
                  required
                  label="Direct Report"
                  value={spec.directReportId}
                  options={team.directReports.filter(
                    (user) =>
                      user.relationship.toAccepted &&
                      user.relationship.fromAccepted,
                  )}
                  onChange={setSpecItem("directReportId")}
                  error={errors.directReportId}
                />
              ) : (
                <FormUserSelect
                  required
                  label="Manager"
                  value={spec.managerId}
                  options={team.lineManagers.filter(
                    (user) =>
                      user.relationship.toAccepted &&
                      user.relationship.fromAccepted,
                  )}
                  onChange={setSpecItem("managerId")}
                  error={errors.managerId}
                />
              )}
              <FormDateSelect
                required
                label="Scheduled Date"
                minDate={startOfDay(new Date())}
                value={
                  spec.scheduledDate == null
                    ? null
                    : fromUnixTime(spec.scheduledDate)
                }
                onChange={(val) =>
                  setSpecItem("scheduledDate")(getUnixTime(val))
                }
                showTime={true}
                error={errors.scheduledDate}
              />
              <FormInput
                value={spec.duration}
                type="number"
                min={5}
                step={1}
                error={errors.duration}
                onChange={(ev) =>
                  setSpecItem("duration")(ev.target.valueAsNumber)
                }
                label={"Duration (minutes)"}
              />
              <FormToggle
                mb={2}
                label="Is Online Conversation"
                helpText="Takes place using an online provider"
                value={spec.isOnlineMeeting}
                onChange={(val) => setSpecItem("isOnlineMeeting")(val)}
              />
              <FormInput
                value={spec.isOnlineMeeting ? "Online" : spec.location}
                disabled={spec.isOnlineMeeting}
                label="Location"
                onChange={(ev) => setSpecItem("location")(ev.target.value)}
              />
              <FormSelect
                label="Recurrence"
                disabled={spec.scheduledDate == null}
                options={recurrenceOptions}
                value={spec.recurrence}
                onChange={(ev) => setSpecItem("recurrence")(ev.target.value)}
              />
              {spec.recurrence !== "none" && (
                <FormInputWrap label="End Date">
                  <ReactDatePicker
                    ref={endDatePicker}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="No end date"
                    customInput={<Input />}
                    calendarStartDay={1}
                    minDate={fromUnixTime(spec.scheduledDate)}
                    selected={
                      spec.endDate == null ? null : fromUnixTime(spec.endDate)
                    }
                    onChange={(val) =>
                      setSpecItem("endDate")(
                        val == null ? null : getUnixTime(val),
                      )
                    }
                  >
                    <Flex
                      p={2}
                      style={{ clear: "both" }}
                      justifyContent={"center"}
                    >
                      <Button
                        onClick={() => {
                          setSpecItem("endDate")(null);
                          endDatePicker.current.setOpen(false);
                        }}
                      >
                        No end date
                      </Button>
                    </Flex>
                  </ReactDatePicker>
                </FormInputWrap>
              )}

              <ConversationTypeSelect
                label="Conversation Type"
                required
                value={spec.conversationTypeId}
                onChange={setConversationType}
                options={types.filter((x) => x.active)}
                error={errors.conversationTypeId}
              />
            </Panel>
          </Flex>

          <Flex
            ml={[0, 3]}
            mt={[3, 0]}
            style={{ minWidth: "250px" }}
            width={["100%", "60%"]}
          >
            <Panel width={"100%"}>
              <PanelHeader p={5}>
                <Circle mr={2} color="primary">
                  <FaCommentDots size={20} />
                </Circle>
                <Text fontSize={4} fontWeight={600} color="black" as="h2">
                  Conversation Details
                </Text>
              </PanelHeader>
              <Box p={3}>
                <FormInput
                  value={spec.purpose}
                  onChange={(ev) => setSpecItem("purpose")(ev.target.value)}
                  label="What is the purpose of this conversation?"
                  error={errors.purpose}
                  aria-label={"Purpose"}
                />
                <FormInput
                  value={spec.outcome}
                  onChange={(ev) => setSpecItem("outcome")(ev.target.value)}
                  label="What is the desired outcome?"
                  error={errors.outcome}
                  aria-label={"Outcome"}
                />

                <>
                  <Flex
                    alignItems="flex-end"
                    mb={2}
                    mt={2}
                    justifyContent={"space-between"}
                  >
                    <Text fontSize={4} fontWeight={500}>
                      Agenda
                    </Text>
                  </Flex>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {spec?.discussionPoints?.map((x, i) => (
                            <Draggable
                              key={i}
                              draggableId={i.toString()}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <Flex
                                  key={i}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getDndItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                  borderBottom={1}
                                  alignItems="center"
                                  p={2}
                                >
                                  <Flex
                                    {...provided.dragHandleProps}
                                    alignItems="center"
                                    mr={1}
                                  >
                                    <FaGripVertical color="#999" />
                                  </Flex>
                                  <Circle
                                    color="primary"
                                    icon={FaCommentDots}
                                    mr={2}
                                  />
                                  <Input
                                    value={x}
                                    style={{ flex: 1 }}
                                    mr={2}
                                    onChange={(ev) =>
                                      setAgendaItem(i, ev.target.value)
                                    }
                                  />
                                  <IconButton
                                    icon={FaTimes}
                                    color="danger"
                                    onClick={() => removeAgendaItem(i)}
                                    title={"Delete discussion point"}
                                    aria-label={"Delete discussion point"}
                                  />
                                </Flex>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Flex>
                    {spec.conversationTypeId != null ? (
                      <>
                        <AddButton
                          aria-label="Add Discussion Point"
                          mr={2}
                          onClick={addAgendaItem}
                          mt={2}
                        >
                          Add Discussion Point
                        </AddButton>
                        <AddButton
                          onClick={() => setAddingCoachingTopics(true)}
                          mt={2}
                          aria-label="Add Discussion Point From Library"
                        >
                          Add Question From Library
                        </AddButton>
                      </>
                    ) : (
                      <Text>Please select a conversation type</Text>
                    )}
                  </Flex>
                  <Rule />
                  <FormTextArea
                    height={150}
                    value={spec.preparation}
                    onChange={(ev) =>
                      setSpecItem("preparation")(ev.target.value)
                    }
                    label="Any recommended preparation for the conversation (Optional)"
                    error={errors.preparation}
                    mb={0}
                  />
                  <Rule />
                  <Flex>
                    <Button
                      disabled={spec.conversationTypeId == null}
                      onClick={submit}
                      color="primary"
                      mr={2}
                    >
                      Schedule Conversation
                    </Button>
                    <Button to="/solutions/performance/conversations">
                      Cancel
                    </Button>
                  </Flex>
                </>
              </Box>
            </Panel>
          </Flex>
        </Flex>
      )}
    </>
  );
}
