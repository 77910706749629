import { ConversationTypeDto } from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  ControlLabel,
  Flex,
  Modal,
  Text,
} from "@coaching-culture/ui";
import { ScrollableContainer } from "components/ScrollableContainer";
import { useState } from "react";
import { FaCommentDots } from "react-icons/fa";
import styled from "styled-components";
import { SpaceProps } from "styled-system";

const OptionButton = styled.button`
  display: flex;
  border: 0;
  background: none;
  align-items: flex-start;
  padding: 6px 18px;
  width: 100%;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
`;

type ConversationTypeModalProps = {
  options: ConversationTypeDto[];
  onSelect: (val: string) => void;
  onClose: () => void;
};

function ConversationTypeSelectModal({
  options,
  onSelect,
  onClose,
}: ConversationTypeModalProps) {
  return (
    <Modal width={600} onClose={onClose}>
      <Box pb={3}>
        <Text p={3} fontSize={4} fontWeight={600}>
          Conversation Types
        </Text>
        <ScrollableContainer maxHeight={400}>
          {options.map((x) => (
            <OptionButton key={x.id} onClick={() => onSelect(x.id)}>
              <Circle icon={FaCommentDots} color="primary" mr={2} />
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                justifyContent={"flex-start"}
              >
                <Text fontWeight={500}>{x.name}</Text>
                <Text>{x.description}</Text>
              </Flex>
            </OptionButton>
          ))}
        </ScrollableContainer>
      </Box>
    </Modal>
  );
}

type ConversationTypeSelectProps = {
  label?: string;
  value: string | null;
  onChange: (val: string) => void;
  options: ConversationTypeDto[];
  required?: boolean;
  error?: string | null;
} & SpaceProps;

export function ConversationTypeSelect({
  label,
  value,
  onChange,
  required,
  options,
  error,
  ...rest
}: ConversationTypeSelectProps) {
  const [selecting, setSelecting] = useState(false);
  const val = options.find((x) => x.id === value);

  const onSelect = (id: string) => {
    setSelecting(false);
    onChange(id);
  };

  return (
    <>
      {selecting && (
        <ConversationTypeSelectModal
          onClose={() => setSelecting(false)}
          onSelect={onSelect}
          options={options}
        />
      )}
      <Box mb={2} {...rest}>
        <ControlLabel required={required}>{label}</ControlLabel>
        <Flex
          p={2}
          alignItems={"center"}
          border={1}
          borderRadius={6}
          justifyContent={"space-between"}
        >
          <Flex alignItems="center">
            <Circle
              icon={FaCommentDots}
              size={"large"}
              color={value == null ? "grey3" : "primary"}
              mr={2}
            />
            <div>
              <Text fontWeight={500}>
                {val == null ? "Select Type" : val.name}
              </Text>
              <Text fontSize={12} fontWeight={500} color="grey2">
                {value == null
                  ? "Click to change the type"
                  : "Changing this will reset the agenda..."}
              </Text>
            </div>
          </Flex>
          <Button color="primary" onClick={() => setSelecting(true)}>
            Change
          </Button>
        </Flex>
        {error && (
          <Text color="danger" fontSize={2} mt={1}>
            {error}
          </Text>
        )}
      </Box>
    </>
  );
}
