import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

export type ImgProps = {} & SpaceProps & LayoutProps;

export const Img = styled.img<ImgProps>`
  max-width: 100%;
  ${space};
  ${layout};
`;
