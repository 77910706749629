import { UserCampaignSummary } from "@coaching-culture/types";
import {
  Button,
  Flex,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { format, fromUnixTime, parseISO } from "date-fns";
import { sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import defaultImgSrc from "../../../img/defaultImage.svg";
import newCampaignSrc from "../../../img/newCampaigns.svg";

const NewCampaignImage = styled.img`
  flex: 1;
  max-width: 80%;
`;

const CampaignImage = styled.img`
  box-shadow: ${(props) => props.theme.shadows[1]};
  width: 320px;
  max-width: 100%;
`;

const NewCampaigns = ({ data }: { data: UserCampaignSummary[] }) => {
  return (
    <Panel p={[2, 3]} mb={5} pb={[0, 0]}>
      <Flex
        alignItems="center"
        justifyContent="center"
        pb={[3, "60px"]}
        pt={[3, "60px"]}
        pl={[3, "90px"]}
        pr={[3, "90px"]}
        borderBottom={1}
        flexDirection={["column", "row"]}
      >
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flex="1"
          order={[1, 0]}
          mt={[3, 0]}
          mb={[4, 0]}
        >
          <Text fontSize={6} fontWeight={600} maxWidth="400px">
            You've been asked to get some feedback!
          </Text>
          <Text fontSize={4} color="grey2">
            Let's figure out how you're doing.
          </Text>
        </Flex>
        <Flex style={{ position: "relative" }} flex="1" justifyContent="center">
          <NewCampaignImage src={newCampaignSrc} />
        </Flex>
      </Flex>
      {sortBy(data, "createdOn")
        .reverse()
        .map((x, i) => (
          <Flex
            key={x.id}
            alignItems="stretch"
            borderBottom={i === data.length - 1 ? 0 : 1}
            p={[2, 3]}
            pb={[5, 5]}
            pt={[5, 5]}
            width="100%"
            justifyContent="stretch"
            flexDirection={["column", "row"]}
          >
            <CampaignImage
              src={
                x.image
                  ? `${process.env.REACT_APP_STATIC_ROOT}/${x.image}`
                  : defaultImgSrc
              }
            />
            <Flex
              ml={[0, 4]}
              justifyContent="space-between"
              flexDirection="column"
              alignItems="flex-startt"
              flex="1"
              mt={[3, 0]}
            >
              <Flex flexDirection="column" flex="1" width="100%">
                <Text fontSize={5} fontWeight={600}>
                  {x.name}
                </Text>
                <Text fontSize={2} color="grey" mb={3}>
                  Created on{" "}
                  {format(
                    parseISO(x.createdOn as unknown as string),
                    "dd/MM/yyyy"
                  )}
                </Text>
                {(x.description ?? "").trim() !== "" && (
                  <Text mb={3}>
                    {(x.description ?? "").split("\n").map((x, i) => (
                      <React.Fragment key={i}>
                        {x}
                        <br />
                      </React.Fragment>
                    ))}
                  </Text>
                )}
              </Flex>
              <Button
                to={`/solutions/feedback/campaigns/${x.campaignUserId}`}
                color="primary"
                width="160px"
              >
                Get Started
              </Button>
            </Flex>
          </Flex>
        ))}
    </Panel>
  );
};

export const UserCampaigns = () => {
  const [items, setItems] = useState<UserCampaignSummary[] | null>(null);
  const [user] = useUser();

  const loading = items == null;

  useEffect(() => {
    axios.get(`/api/v2/feedback/user-campaigns`).then(({ data }) => {
      setItems(sortBy(data, "createdOn"));
    });
  }, []);

  const cols = useMemo(
    (): SortableTableColumn<UserCampaignSummary>[] => [
      {
        name: "name",
        label: "Name",
        headingProps: {
          style: {
            width: "50%",
          },
        },
        format: (x) => (
          <Link to={`/solutions/feedback/campaigns/${x.campaignUserId}`}>
            <strong>{x.name}</strong>
          </Link>
        ),
      },
      {
        name: "username",
        label: "User",
        format: (x) => x.user.name,
        sortFormat: (x) => x.user.name,
        headingProps: {
          style: {
            width: "25%",
          },
        },
      },
      {
        name: "createdOn",
        label: "Created",
        format: (x) => format(new Date(x.createdOn), "dd-MM-yyyy"),
      },
      {
        name: "earliestDeadline",
        label: "Deadline",
        format: (x) =>
          x.earliestDeadline == null
            ? "None"
            : format(fromUnixTime(x.earliestDeadline), "dd-MM-yyyy"),
      },
    ],
    []
  );

  const newCampaigns = items?.filter(
    (x) => x.respondentCount === 0 && x.user.id === user.id && !x.archived
  );
  const yourCampaigns =
    items?.filter((x) => x.respondentCount > 0 && x.user.id === user.id) ?? [];
  const otherCampaigns =
    items?.filter((x) => x.user.id !== user.id && !x.archived) ?? [];

  return (
    <CenterColumn>
      <PageHeader
        text="Feedback Campaigns"
        subtitle="Campaigns you have taken part in"
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {newCampaigns.length > 0 && <NewCampaigns data={newCampaigns} />}
          <Text fontSize={4} mb={1} fontWeight={500}>
            Previous Campaigns
          </Text>
          <Panel mb={5}>
            <SortableTable<UserCampaignSummary>
              columns={cols}
              data={yourCampaigns}
              defaultSort={1}
              defaultDesc
              emptyMessage="No Current Campaigns"
            />
          </Panel>
          <Text fontSize={4} mb={1} fontWeight={500}>
            Others' Campaigns
          </Text>
          <Panel>
            <SortableTable<UserCampaignSummary>
              columns={cols}
              data={otherCampaigns}
              defaultSort={2}
              defaultDesc
              emptyMessage="No Current Campaigns"
            />
          </Panel>
        </>
      )}
    </CenterColumn>
  );
};
