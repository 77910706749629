import { UserDisplay } from "@coaching-culture/types";
import { Link } from "react-router-dom";
import UserProfileImage from "./UserProfileImage";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import React from "react";
import Flex from "./Flex";
import Box from "./Box";
import { LinkButton } from "./LinkButton";
import Text from "./Text";
import Label from "./Label";

const StyledLink = styled(Link)`
  font-weight: 500;
`;

export type UserFlagProps = {
  user: UserDisplay | null;
  to?: string;
  onClick?: () => void;
  color?: string;
  isYou?: boolean;
} & SpaceProps;

export function UserFlag({
  user,
  to,
  onClick,
  color,
  isYou = false,
  ...rest
}: UserFlagProps) {
  return (
    <Flex alignItems="center" {...rest}>
      <UserProfileImage
        mr={2}
        profileImage={user?.profileImage}
        name={user?.name ?? "?"}
        color={color}
      />
      <Box>
        <Flex alignItems={"center"}>
          {to != null ? (
            <StyledLink to={to} onClick={onClick}>
              {user?.name ?? "Unknown User"}
            </StyledLink>
          ) : onClick != null ? (
            <LinkButton onClick={onClick}>
              {user?.name ?? "Unknown User"}
            </LinkButton>
          ) : (
            <Text fontWeight={500}>{user?.name ?? "Unknown User"}</Text>
          )}
          {isYou && (
            <Label size="tiny" ml={1} color="primary">
              You
            </Label>
          )}
        </Flex>
        <Text fontSize="80%" color="grey2">
          {user?.email ?? ""}
        </Text>
      </Box>
    </Flex>
  );
}
