import axios, { AxiosRequestConfig } from "axios";
import React, { useRef } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import Button from "./Button";

const Container = styled.div<any>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 1px solid #ddd;
  background: #ddd;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  ${space};

  & > img {
    pointer-events: none;
    position: absolute;
    object-fit: contain;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const UploadButton = styled(Button)`
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: 1;
  position: relative;

  ${Container}:hover & {
    opacity: 1;
  }
`;

type ImageUploaderProps = {
  onChange: (value: string) => void;
  value: string | null;
  width: number;
  height: number;
  allowReset?: boolean;
} & SpaceProps;

const ImageUploader = ({
  onChange,
  allowReset = false,
  value,
  width,
  height,
  ...rest
}: ImageUploaderProps) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const uploadImage = async () => {
    const file = (fileRef?.current?.files || [])[0];

    if (file == null) {
      return;
    }

    if (!file.type.startsWith("image")) {
      alert("Must be an image file");
      return;
    }

    const filename = file.name;

    const res = await axios.post<{ url: string; key: string }>(
      "/api/uploads/signed",
      { filename },
    );

    const config: AxiosRequestConfig = {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
    };

    axios
      .put(res.data.url, new File([file], res.data.key), config)
      .then(() => {
        onChange(res.data.key);
      })
      .catch((err) => {
        alert(err.message);
        console.log(err.toJSON());
      });
  };

  const startUpload = () => {
    fileRef.current?.click();
  };

  return (
    <Container width={width} height={height} {...rest}>
      <img
        src={
          value
            ? `${process.env.REACT_APP_STATIC_ROOT}/${value}`
            : `https://placehold.co/${width}x${height}`
        }
      />
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={uploadImage}
      />
      <UploadButton type="button" onClick={startUpload}>
        Change
      </UploadButton>
      {allowReset && (
        <UploadButton onClick={() => onChange("")}>Reset</UploadButton>
      )}
    </Container>
  );
};

export default ImageUploader;
