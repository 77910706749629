import { UserDisplay } from "@coaching-culture/types";
import {
  Box,
  Button,
  ControlLabel,
  Flex,
  InputWithSearch,
  ReactModal,
  Text,
} from "@coaching-culture/ui";
import { sortBy } from "lodash";
import { useState } from "react";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import UserProfileImage from "./UserProfileImage";

const OptionButton = styled.button`
  display: flex;
  border: 0;
  background: none;
  align-items: center;
  padding: 6px 18px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
`;

const UserButton = styled(Flex)<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: ${(props) =>
    !props.disabled ? "" : props.theme.colors.grey5};
  &:hover :focus {
    background-color: ${(props) =>
      props.disabled ? "" : props.theme.colors.grey5};
  }
`;

type UserSelectModalProps = {
  options: UserDisplay[];
  onSelect: (user: UserDisplay) => void;
  onCancel: () => void;
  isOpen: boolean;
};

export function UserSelectModal({
  options,
  onSelect,
  isOpen,
  onCancel,
}: UserSelectModalProps) {
  const [search, setSearch] = useState("");
  let filteredUsers = options;

  if (search.trim() !== "") {
    filteredUsers = options.filter((x) =>
      x.name.toLowerCase().includes(search.toLowerCase()),
    );
  }

  filteredUsers = sortBy(filteredUsers, (x) => x.name.toLowerCase());

  return (
    <ReactModal width={600} isOpen={isOpen} onClose={onCancel}>
      <Flex flexDirection={"column"}>
        <Text fontSize={4} fontWeight={500} p={3}>
          Select User
        </Text>
        <InputWithSearch
          width={400}
          placeholder="Search..."
          searching={false}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          mb={2}
        />
        <Box maxHeight={"300px"} flex={1} overflowY="auto">
          {filteredUsers.map((x) => (
            <OptionButton onClick={() => onSelect(x)}>
              <UserProfileImage
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    onSelect(x);
                    onCancel();
                  }
                }}
                profileImage={x.profileImage}
                name={x.name}
                mr={2}
              />
              <Text fontWeight={500}>{x.name}</Text>
            </OptionButton>
          ))}
        </Box>
      </Flex>
    </ReactModal>
  );
}

type FormUserSelectProps = {
  label?: string;
  value: string | null;
  options: UserDisplay[];
  onChange: (val: string) => void;
  required?: boolean;
  error?: any;
  disabled?: boolean;
} & SpaceProps;

const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error;
  }

  if (error.message) {
    return error.message;
  }

  if (error.type === "minLength") {
    return "Value too short.";
  } else if (error.type === "required") {
    return "Value is required";
  }

  return "Invalid value";
};

export function FormUserSelect({
  label,
  value,
  options,
  onChange,
  required,
  error,
  disabled,
  ...rest
}: FormUserSelectProps) {
  const [selecting, setSelecting] = useState(false);
  const user = options.find((x) => x.id === value);

  const select = () => {
    if (!disabled) setSelecting(true);
  };

  const onSelect = (val: UserDisplay) => {
    onChange(val.id);
    setSelecting(false);
  };

  return (
    <>
      <UserSelectModal
        isOpen={selecting}
        options={options}
        onSelect={onSelect}
        onCancel={() => setSelecting(false)}
      />
      <Box mb={2} {...rest}>
        <ControlLabel required={required}>{label}</ControlLabel>
        <UserButton
          disabled={disabled}
          p={2}
          alignItems={"center"}
          border={1}
          borderRadius={6}
          justifyContent={"space-between"}
        >
          <Flex alignItems="center">
            <UserProfileImage
              size={"large"}
              profileImage={user?.profileImage}
              name={user?.name ?? "?"}
              mr={2}
            />
            <div>
              <Text fontWeight={500}>{user?.name ?? "Select User"}</Text>
              <Text fontSize={12} fontWeight={500} color="grey2">
                {user?.email ?? "Click to select user"}
              </Text>
            </div>
          </Flex>
          <Button
            aria-label={"Change User"}
            type="button"
            color="primary"
            onClick={select}
          >
            Change
          </Button>
        </UserButton>
        {error && (
          <Text color="danger" fontSize={2} mt={1}>
            {getErrorMessage(error)}
          </Text>
        )}
      </Box>
    </>
  );
}
