import React from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 15px;
`;

const StyledMark = styled.div`
  width: 15px;
  height: 15px;
  border: 2px solid rgb(0, 0, 0);
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
`;

const StyledThumb = styled.div`
  height: 15px;
  line-height: 15px;
  width: 15px;
  text-align: center;
  background-color: ${(props: any) =>
    props.disabled ? props.theme.colors.grey : props.theme.colors.primary};
  color: #000;
  border-radius: 50%;
  cursor: grab;
  border: solid 2px #fff;
`;

const StyledMarkText = styled.div`
  display: inline-block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  top: 20px;
  right: -15px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey};
`;

const Thumb = (props: any, disabled: boolean) => (
  <StyledThumb disabled={disabled} {...props}></StyledThumb>
);

const Mark = (props: any, value: number) => (
  <StyledMark {...props}>
    <StyledMarkText>Target {value}</StyledMarkText>
  </StyledMark>
);

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props: any) =>
    props.index === 2
      ? props.disabled
        ? props.theme.colors.primary
        : props.themes.colors.grey
      : props.index === 1
      ? props.theme.colors.grey5
      : props.theme.colors.primary};
  border-radius: 999px;
`;

const Track = (props: any, state: any) => (
  <StyledTrack {...props} index={state.index} disabled={state.disabled} />
);

export const Slider = ({
  onChange,
  value,
  onAfterChange,
  mark,
  disabled,
  max,
  min,
}: {
  onChange: (val: number) => void;
  value: number;
  onAfterChange: () => void;
  mark: number;
  disabled: boolean;
  max: number;
  min: number;
}) => {
  return (
    <StyledSlider
      renderTrack={Track}
      renderThumb={(val) => Thumb(val, disabled)}
      renderMark={(val: any) => Mark(val, mark)}
      value={value}
      onChange={(val) => onChange(+val)}
      onAfterChange={onAfterChange}
      min={min}
      marks={[mark]}
      disabled={disabled}
      max={max}
    />
  );
};
