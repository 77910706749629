import React from "react";
import { Flex } from "./Flex";
import Text from "./Text";
import Circle from "./Circle";

import styled from "styled-components";
import { IconType } from "react-icons";

export type ButtonSelectOption = {
  value: string;
  label: string;
  color: string;
  icon?: IconType;
};

export type ButtonSelectProps = {
  value: string;
  options: ButtonSelectOption[];
  onChange: (val: string) => void;
};

const Item = styled.button<{ active: boolean }>`
  width: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  margin: 6px;
  border-radius: 6px;
  border: ${(props) => props.theme.borders[1]};
  border-color: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.grey4};
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
`;

export function ButtonSelect({ value, options, onChange }: ButtonSelectProps) {
  return (
    <Flex justifyContent="center">
      {options.map((x) => (
        <Item
          onClick={() => onChange(x.value)}
          active={value === x.value}
          style={{ opacity: value === "" || value === x.value ? 1 : 0.5 }}
          type="button"
        >
          <Circle color={x.color} size="medium" mb={1} icon={x.icon} />
          <Text color={value === x.value ? "primary" : "body"} fontWeight={500}>
            {x.label}
          </Text>
        </Item>
      ))}
    </Flex>
  );
}
