import React from "react";
import { Editor } from "@tinymce/tinymce-react";

type EmailEditorProps = {
  value: string;
  onChange: (val: string) => void;
};

export function EmailEditor({ value, onChange }: EmailEditorProps) {
  return (
    <Editor
      apiKey="ilegdcomtktj8vwzz66k8asu4mf90l0u1e0bkg8yr7ihz7kp"
      value={value}
      onEditorChange={(v) => onChange(v)}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
}
