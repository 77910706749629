import { FormItem } from "@coaching-culture/types";
import React from "react";
import {
  FaCommentAlt,
  FaEdit,
  FaHeading,
  FaPollH,
  FaQuestion,
  FaStar,
} from "react-icons/fa";
import styled from "styled-components";
import Circle from "../Circle";
import Flex from "../Flex";
import Modal from "../Modal";
import Text from "../Text";

const TypeButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  background: white;
  cursor: pointer;
  margin-bottom: 12px;

  &:hover {
    background: ${(props) => props.theme.colors.grey6};
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export type ItemSelectModalProps = {
  onSelect: (type: FormItem["type"] | null) => void;
};

const types = [
  {
    type: "heading",
    icon: FaHeading,
    title: "Section Heading",
    description: "A new section of the survey",
  },
  {
    type: "rating",
    icon: FaStar,
    title: "Rating Question",
    description: "A question with various available scales.",
  },
  {
    type: "multiple_choice",
    icon: FaPollH,
    title: "Multiple Choice Question",
    description: "Allow users to choose from a series of options",
  },
  {
    type: "free_text",
    icon: FaCommentAlt,
    title: "Free Text Question",
    description: "A blank box for users to fill in.",
  },
  {
    type: "content",
    icon: FaEdit,
    title: "Rich Content",
    description: "Richly formatted text to display to your users.",
  },
] as const;

export function ItemSelectModal({ onSelect }: ItemSelectModalProps) {
  return (
    <Modal width={600} p={5} onClose={() => onSelect(null)} showCloseButton>
      <Flex alignItems="center" flexDirection="column" mb={5}>
        <Circle icon={FaQuestion} size="large" color="primary" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Choose Item Type
        </Text>
      </Flex>
      <Flex flexDirection="column">
        {types.map((x) => (
          <TypeButton onClick={() => onSelect(x.type)}>
            <Circle icon={x.icon} mr={2} size="large" />
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Text fontWeight={600}>{x.title}</Text>
              <Text color="grey2">{x.description}</Text>
            </Flex>
          </TypeButton>
        ))}
      </Flex>
    </Modal>
  );
}
