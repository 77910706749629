import { lighten } from "polished";
import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";

type TextAreaProps = SpaceProps & LayoutProps;

const TextArea = styled.textarea<TextAreaProps>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  line-height: 1.5;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  box-shadow: rgba(204, 219, 232, 0.6) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
  ${space};
  ${layout};
  max-width: 100%;

  &:disabled,
  &:read-only {
    background-color: ${(props) => props.theme.colors.grey4};
  }

  &:focus,
  &:active {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: inset 0px 0px 5px
      ${(props) => lighten(0.3, props.theme.colors.primary)};
  }

  &:invalid {
    background-color: ${(props) => lighten(0.5, props.theme.colors.danger)};
  }
`;

TextArea.defaultProps = {
  mb: 0,
};

export default TextArea;
