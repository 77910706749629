import { RatingType } from "@coaching-culture/types";
import { Button, Flex, FormTextArea, Text } from "@coaching-culture/ui";
import React from "react";
import { animated, useSpring, useTrail } from "react-spring";
import { Answer } from ".";
import { DotsRating } from "./DotsRating";
import { NumbersRating } from "./NumbersRating";
import { SmilesRating } from "./SmilesRating";
import { StarRating } from "./StarRating";

export type RatingProps = {
  content: string;
  type: RatingType;
  requiresEvidence: boolean;
  value: Answer;
  index: number;
  count: number;
  reverse: boolean;
  reverseScale?: boolean;
  onConfirm: () => void;
  onChange: (ans: Answer) => void;
  sectionName: string | null;
  highEndLabel: string | null;
  lowEndLabel: string | null;
  noEvidenceLabel: string;
  allowNa: boolean;
};

export const Rating = ({
  content,
  type,
  requiresEvidence,
  value,
  onChange,
  index,
  count,
  reverse,
  reverseScale = false,
  onConfirm,
  sectionName,
  highEndLabel,
  lowEndLabel,
  noEvidenceLabel,
  allowNa,
}: RatingProps) => {
  let trail = useTrail(3, {
    from: {
      y: 100 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  const showEvidence = requiresEvidence && value != null && value.answer !== -1;

  const evidenceSpring = useSpring({
    height: showEvidence ? 170 : 0,
    opacity: showEvidence ? 1 : 0,
  });

  const setEvidence = (evidence: string) => {
    onChange({
      answer: value.answer,
      evidence,
    });
  };

  const setValue = (i: number | null) => {
    const current = value ?? { answer: null, evidence: null };

    if (i != null) {
      current.answer = i;
    }
    onChange(current);
    if (i === -1 || !requiresEvidence) {
      setTimeout(() => {
        onConfirm();
      }, 500);
    }
  };

  return (
    <Flex flexDirection="column" mb={3}>
      <animated.div style={trail[0]}>
        <Text fontWeight={600} color="grey2" fontSize={2}>
          {sectionName ? `${sectionName} - ` : ""} {index + 1} / {count}
        </Text>
        <Text mb={[2, 5]} fontSize={4} fontWeight={600}>
          {content}
        </Text>
      </animated.div>
      <animated.div style={trail[1]}>
        {type === "stars" ? (
          <StarRating
            allowNa={allowNa}
            onChange={setValue}
            value={value?.answer as number}
            reverse={reverse}
            requiresEvidence={requiresEvidence}
            noEvidenceLabel={noEvidenceLabel}
          />
        ) : type === "dots" ? (
          <DotsRating
            allowNa={allowNa}
            evidenceLabel={noEvidenceLabel}
            onChange={setValue}
            requiresEvidence={requiresEvidence}
            value={value?.answer as number}
            reverse={reverse}
            reverseScale={reverseScale}
          />
        ) : type === "smiles" ? (
          <SmilesRating
            allowNa={allowNa}
            onChange={setValue}
            requiresEvidence={requiresEvidence}
            value={value?.answer as number}
            reverse={reverse}
            noEvidenceLabel={noEvidenceLabel}
          />
        ) : type === "number" ? (
          <NumbersRating
            allowNa={allowNa}
            onChange={setValue}
            requiresEvidence={requiresEvidence}
            value={value?.answer as number}
            reverse={reverse}
            highEndLabel={highEndLabel}
            lowEndLabel={lowEndLabel}
            noEvidenceLabel={noEvidenceLabel}
          />
        ) : null}
      </animated.div>
      <animated.div
        style={{
          ...trail[2],
          ...evidenceSpring,
        }}
      >
        <FormTextArea
          label="Evidence"
          height="90px"
          onChange={(ev) => setEvidence(ev.target.value)}
          value={value?.evidence ?? ""}
        />
        <Button
          onClick={onConfirm}
          disabled={(value?.evidence ?? "").trim() === ""}
          color="primary"
        >
          Continue
        </Button>
      </animated.div>
    </Flex>
  );
};
