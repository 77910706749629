import React from "react";
import styled from "styled-components";
import { SpaceProps, space } from "styled-system";
import { Flex } from "./Flex";
import Text from "./Text";

export type FormToggleProps = {
  value: boolean;
  onChange: (val: boolean) => void;
  helpText?: string;
  label: string;
} & SpaceProps;

const Container = styled.div<SpaceProps>`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  height: auto;
  padding: 18px;
  align-items: center;
  box-shadow: rgba(204, 219, 232, 0.6) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
  ${space};
`;

const Toggle = styled.div<{ on: boolean }>`
  height: 32px;
  width: 54px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.on ? props.theme.colors.primary : props.theme.colors.grey5};
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 0px inset,
    rgba(255, 255, 255, 0.3) -1px -1px 4px 1px inset;
  transition: all 0.3s ease;
  flex: 0 0 auto;

  &:after {
    content: "";
    display: block;
    width: 24px;
    position: relative;
    top: 4px;
    left: 4px;
    height: 24px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(${(props) => (props.on ? 21 : 0)}px, 0, 0);
    transition: all 0.3s ease;
  }
`;

export function FormToggle({
  value,
  onChange,
  label,
  helpText,
  ...rest
}: FormToggleProps) {
  return (
    <Container {...rest}>
      <Flex flexDirection="column">
        <Text fontWeight={500}>{label}</Text>
        {helpText && (
          <Text fontSize={2} color="grey2">
            {helpText}
          </Text>
        )}
      </Flex>
      <Toggle
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            onChange(!value);
          }
        }}
        tabIndex={0}
        on={value}
        onClick={() => onChange(!value)}
      />
    </Container>
  );
}
