import {
  ContentHubBundleDto,
  ContentHubBundleSpec,
  ContentHubCollectionDto,
  ContentHubCollectionSpec,
  ContentHubTagDto,
  ContentHubTagSpec,
  ContentHubTitleDto,
  ContentHubTitleSpec,
  ContentHubTitleSummaryDto,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";

const getAllCollections = () =>
  axios
    .get<ContentHubCollectionDto[]>(`/api/v2/content-hub/collections`)
    .then(({ data }) => data);

const getCollectionById = async (id: string) => {
  const data = await getAllCollections();

  const item = data.find((x) => x.id === id);

  if (item == null) {
    throw new Error("Not Found");
  }

  return item;
};

const createCollection = (spec: ContentHubCollectionSpec) =>
  axios.post(`/api/v2/content-hub/collections`, spec);

const updateCollection = (spec: ContentHubCollectionSpec) =>
  axios.put(`/api/v2/content-hub/collections/${spec.id}`, spec);

export const getDownloadLinkForResource = (id: string) =>
  axios
    .post<string>(`/api/v2/content-hub/resources/${id}/download`)
    .then(({ data }) => data);

const getAllTitles = () =>
  axios
    .get<ContentHubTitleSummaryDto[]>(`/api/v2/content-hub/titles`)
    .then(({ data }) => data);

const getTitleById = (id: string) =>
  axios
    .get<ContentHubTitleDto>(`/api/v2/content-hub/titles/${id}`)
    .then(({ data }) => data);

const updateTitle = (spec: ContentHubTitleSpec) =>
  axios.put(`/api/v2/content-hub/titles/${spec.id}`, spec);

const deleteTitle = (id: string) =>
  axios.delete(`/api/v2/content-hub/titles/${id}`);

const getAllTags = () =>
  axios
    .get<ContentHubTagDto[]>("/api/v2/content-hub/tags")
    .then(({ data }) => data);

const getTagById = async (id: string) => {
  const data = await getAllTags();

  const item = data.find((x) => x.id === id);

  if (item == null) {
    throw new Error("Not found");
  }

  return item;
};

const createTag = (spec: ContentHubTagSpec) =>
  axios.post("/api/v2/content-hub/tags", spec);

const updateTag = (spec: ContentHubTagSpec) =>
  axios.put(`/api/v2/content-hub/tags/${spec.id}`, spec);

const createTitle = (spec: ContentHubTitleSpec) =>
  axios.post("/api/v2/content-hub/titles", spec);

// Queries
//

export const useAllTags = () => useQuery(["content-tags"], getAllTags);

export const useTag = (id: string) =>
  useQuery(["content-tags", id], () => getTagById(id));

export const useUpdateTag = () => {
  const queryClient = useQueryClient();

  return useMutation(updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-tags"]);
    },
  });
};

export const useCreateTag = () => {
  const queryClient = useQueryClient();

  return useMutation(createTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-tags"]);
    },
  });
};

export const useAllCollections = () =>
  useQuery(["content-collections"], getAllCollections);

export const useCollection = (id: string) =>
  useQuery(["content-collections", id], () => getCollectionById(id));

export const useCreateCollection = () => {
  const queryClient = useQueryClient();

  return useMutation(createCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-collections"]);
    },
  });
};

export const useUpdateCollection = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-collections"]);
    },
  });
};

export const useAllTitles = () => useQuery(["content-titles"], getAllTitles);

export const useTitle = (id: string) =>
  useQuery(["content-titles", id], () => getTitleById(id));

export const useCreateTitle = () => {
  const queryClient = useQueryClient();

  return useMutation(createTitle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-titles"]);
    },
  });
};

export const useUpdateTitle = () => {
  const queryClient = useQueryClient();

  return useMutation(updateTitle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-titles"]);
    },
  });
};

export const useDeleteTitle = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteTitle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-titles"]);
    },
  });
};

export const useCreateDownloadLink = () =>
  useMutation(getDownloadLinkForResource);

// Bundles

const getAllBundles = () =>
  axios
    .get<ContentHubBundleDto[]>("/api/v2/content-hub/bundles")
    .then(({ data }) => data);

const getBundle = (id: string) =>
  getAllBundles().then((data) => data.find((x) => x.id === id)!);

export const useAllBundles = () => useQuery(["content-bundles"], getAllBundles);

export const useBundle = (id: string) =>
  useQuery(["content-bundles", id], () => getBundle(id));

const createBundle = (spec: ContentHubBundleSpec) =>
  axios.post("/api/v2/content-hub/bundles", spec);

const updateBundle = (spec: ContentHubBundleSpec) =>
  axios.put(`/api/v2/content-hub/bundles/${spec.id}`, spec);

export const useCreateBundle = () => {
  const queryClient = useQueryClient();

  return useMutation(createBundle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-bundles"]);
    },
  });
};

export const useUpdateBundle = () => {
  const queryClient = useQueryClient();

  return useMutation(updateBundle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["content-bundles"]);
    },
  });
};

export const grantBundle = ({
  userId,
  bundleCode,
}: {
  userId: string;
  bundleCode: string;
}) => axios.put(`/api/v2/users/${userId}/bundles`, { code: bundleCode });

export const useGrantBundle = () => {
  const queryClient = useQueryClient();

  return useMutation(grantBundle, {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
};

export const getBundleByCode = (code: string) =>
  axios
    .get<ContentHubBundleDto>(`/api/v2/bundles/${code}`)
    .then(({ data }) => data);

export const useBundleByCode = (code?: string | null) =>
  useQuery(["bundles", code], () => (code ? getBundleByCode(code) : null));
