import React from "react";
import Circle, { CircleProps } from "./Circle";
import Text from "./Text";

const getInitials = (name: string) =>
  name
    .split(" ")
    .map((x) => x[0])
    .join("")
    .toUpperCase();

type InitialDisplayProps = {
  text: string;
} & CircleProps;

const fontSizes = {
  tiny: 1,
  small: 2,
  medium: 3,
  large: 5,
  xlarge: 7,
  xxlarge: 8,
} as const;

export const InitialDisplay = ({
  text,
  size = "medium",
  color = "primary",
  ...rest
}: InitialDisplayProps) => {
  const fontSize = typeof size === "number" ? size * 0.4 : fontSizes[size];

  const cols = [
    "#55efc4",
    "#81ecec",
    "#0984e3",
    "#6c5ce7",
    "#e17055",
    "#d63031",
    "#fdcb6e",
    "#00b894",
  ];

  const c = cols[(text.length % cols.length) - 1];

  return (
    <Circle {...rest} color={color} size={size}>
      <Text fontSize={fontSize} fontWeight={600}>
        {getInitials(text)}
      </Text>
    </Circle>
  );
};

export default InitialDisplay;
