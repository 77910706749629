import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { useWindowSize } from "react-use";
import styled, { css, useTheme } from "styled-components";
import { layout, LayoutProps } from "styled-system";

export const FixedSidebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const FixedSidebarMain = styled.main`
  flex: 1;
  background-color: #fafafa;
  overflow-y: auto;
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 6px;
  color: ${(props) => props.theme.colors.body};

  & svg {
    margin-right: 6px;
  }
`;

const Wrapper = styled.div`
  height: calc(100% - 45px);
  overflow-y: auto;
`;

type ContainerProps = {
  alwaysFloat: boolean;
  onLeft: boolean;
};

type HideButtonProps = {
  onLeft: boolean;
};

const Container = styled(animated.div)<ContainerProps>`
  position: fixed;
  ${(props) =>
    props.onLeft
      ? css`
          left: 0;
          box-shadow: 3px 0 3px rgba(0, 0, 0, 0.14);
        `
      : css`
          right: 0;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.14);
        `};
  width: 300px;
  background: white;
  top: 0;
  padding-top: 56px;
  z-index: 4;
  height: 100%; //calc(100% - 56px);
  position: fixed;
  top: 0;
  height: 100%;
  padding-top: 64px;
`;

const HideButton = styled.button<HideButtonProps & LayoutProps>`
  position: absolute;
  border: 1px solid transparent;
  ${(props) =>
    props.onLeft
      ? css`
          left: 100%;
          box-shadow: 3px 0 3px rgba(0, 0, 0, 0.14);
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: 0;
        `
      : css`
          left: -28px;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.14);
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-right: 0;
        `};
  top: 90px;
  width: 28px;
  height: 32px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${layout};
  &:hover {
    background-color: ${(props) => props.theme.colors.body};
    color: white;
  }
`;

type FloatingSidebarProps = {
  children: React.ReactNode;
  side?: string;
  alwaysFloat?: boolean;
};

export const Sidebar = ({
  children,
  side = "right",
  alwaysFloat = true,
}: FloatingSidebarProps) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const floatWidth = parseInt(theme.breakpoints[alwaysFloat ? 2 : 1], 10);
  const [open, setOpen] = useState(!alwaysFloat && width > floatWidth);
  const [scrollTop, setScrollTop] = useState<number>(0);

  useEffect(() => {
    if (!alwaysFloat && width > floatWidth) {
      setOpen(true);
    } else if (width < floatWidth) {
      setOpen(false);
    }
  }, [alwaysFloat, floatWidth, width]);

  useEffect(() => {
    const handler = () => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener("scroll", handler);

    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  const handlers = useSwipeable({
    onSwipedRight: () => side === "right" && setOpen(false),
    onSwipedLeft: () => side === "left" && setOpen(false),
  });

  const toggle = () => {
    setOpen((old) => !old);
  };

  const props = useSpring({
    transform: `translate3d(${
      (open ? 0 : 302) * (side === "left" ? -1 : 1)
    }px, 0px, 0px)`,
  });

  const OpenIcon = side === "left" ? FaChevronLeft : FaChevronRight;
  const CloseIcon = side === "right" ? FaChevronLeft : FaChevronRight;

  return (
    <Container
      style={{
        ...props,
        paddingTop: 60 - scrollTop,
      }}
      {...handlers}
      alwaysFloat={alwaysFloat}
      onLeft={side === "left"}
    >
      <HideButton
        onClick={toggle}
        onLeft={side === "left"}
        display={[
          "flex",
          "flex",
          alwaysFloat ? "flex" : "none",
          alwaysFloat ? "flex" : "none",
        ]}
      >
        {open ? <OpenIcon /> : <CloseIcon />}
      </HideButton>
      {open && <Wrapper>{children}</Wrapper>}
    </Container>
  );
};

export const SidebarLink = styled(NavLink)`
  display: flex;
  padding: 3px;
  padding-left: 32px;
  color: ${(props) => props.theme.colors.body};
  font-weight: 500;
  align-items: center;
  font-size: 13px;
  border-left: 3px solid transparent;
  margin-bottom: 3px;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.grey6};
  }

  &:before {
    display: block;
    background-color: ${(props) => props.theme.colors.body};
    width: 10px;
    height: 10px;
    margin-right: 18px;
    border-radius: 50%;
    content: "";
  }

  &.active {
    background-color: ${(props) => props.theme.colors.grey6};
    border-left: 3px solid ${(props) => props.theme.colors.primary};
  }
`;

SidebarLink.defaultProps = {
  activeClassName: "active",
};
