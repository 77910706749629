import {
  ContentDisplay,
  GoalDto,
  GoalSummaryDto,
  UserContent,
} from "@coaching-culture/types";
import { getUnixTime } from "date-fns";

export const getLastAttempt = (content: UserContent) =>
  content.attempts
    .map((x) => x.startedOn)
    .sort()
    .reverse()[0];

export const getActiveAttempt = (content: UserContent) =>
  content.attempts.find((x) => x.completedOn == null);

export const firstCompletion = (content: UserContent) =>
  content.attempts.filter((x) => x.completedOn != null).sort()[0];

export const hasCompleted = (content: UserContent) =>
  firstCompletion(content) != null;

export const hasStarted = (content: UserContent) => content.attempts.length > 0;

export const assignedReasons = ["group", "user", "userfield"] as const;

export const isAssigned = (content: UserContent) =>
  assignedReasons.some((x) => content.assignmentReasons.includes(x));

export const clientUrlForContent = (content: ContentDisplay) => {
  const urlVal =
    content.type === "module"
      ? "mindset"
      : content.type === "lesson"
      ? "lessons"
      : "assessments";

  return `/solutions/${urlVal}/modules/${content.slug}`;
};

export function isOverdue(goal: GoalDto | GoalSummaryDto): boolean {
  const now = getUnixTime(new Date());
  return goal.deadline < now && goal.completedOn === null;
}
