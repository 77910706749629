import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import Flex from "./Flex";
import Text from "./Text";

const Container = styled.div<SpaceProps>`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  height: auto;
  padding: 18px;
  align-items: center;
  box-shadow:
    rgba(204, 219, 232, 0.6) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
  ${space};
`;

export type FormColorInputProps = {
  label: string;
  subLabel: string;
  value: string | null;
  onChange: (value: string | null) => void;
} & SpaceProps;

export const FormColorInput = ({
  value,
  label,
  subLabel,
  onChange,
  ...rest
}: FormColorInputProps) => {
  return (
    <Container {...rest}>
      <Flex flexDirection="column">
        <Text fontWeight={500}>{label}</Text>
        {subLabel && (
          <Text fontSize={2} color="grey2">
            {subLabel}
          </Text>
        )}
      </Flex>
      <input
        type="color"
        value={value ?? ""}
        onChange={(ev) => onChange(ev.target.value)}
      />
    </Container>
  );
};
