import { UserFieldType, UserFilter } from "@coaching-culture/types";
import {
  Box,
  Flex,
  FormInputWrap,
  Loader,
  MultiSelect,
  Text,
  Button,
  ReactModal,
} from "@coaching-culture/ui";
import { useGroups, useUserFields } from "queries/users";
import React, { useState } from "react";
import { SpaceProps } from "styled-system";
import UserFieldInput from "./UserFieldInput";

type FilterPanelProps = SpaceProps & {
  value: UserFilter;
  onChange: (newFilter: UserFilter) => void;
  filteredUsers?: string[];
};

export const FilterModal = ({
  onClose,
  filter,
  filteredUsers,
  isOpen,
}: {
  onClose: (filter: UserFilter) => void;
  filter: UserFilter;
  filteredUsers?: string[];
  isOpen: boolean;
}) => {
  const [modalFilter, setModalFilter] = useState<UserFilter>(
    filter !== null
      ? filter
      : {
          groups: [],
          userFields: [],
        },
  );

  return (
    <ReactModal isOpen={isOpen} width={500} onClose={() => onClose(null)}>
      <Box p={5}>
        <FilterPanel
          mb={4}
          filteredUsers={filteredUsers}
          value={modalFilter}
          onChange={setModalFilter}
        />
        <Flex justifyContent={"flex-end"}>
          <Button mr={2} color="positive" onClick={() => onClose(modalFilter)}>
            Save
          </Button>
          <Button
            color="warning"
            onClick={() =>
              setModalFilter({
                groups: [],
                userFields: [],
              })
            }
          >
            Clear
          </Button>
        </Flex>
      </Box>
    </ReactModal>
  );
};

const FilterPanel = ({
  value,
  onChange,
  filteredUsers,
  ...rest
}: FilterPanelProps) => {
  const { data: userFields = [], isFetched: userFieldsFetched } =
    useUserFields();
  const { data: groups = [], isFetched: groupsFetched } = useGroups();
  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });
  const loading = [userFieldsFetched, groupsFetched].some((x) => !x);

  const setFilterGroups = (items: string[]) =>
    onChange({
      ...value,
      groups: items,
    });

  const setUserField = (id: string) => (val: string[]) => {
    let uf = [...value.userFields];

    if (val.length === 0) {
      uf = uf.filter((x) => x.id !== id);
    } else {
      const f = uf.find((x) => x.id === id);

      if (f == null) {
        uf.push({
          id: id,
          value: val,
        });
      } else {
        f.value = val;
      }
    }
    let f = {
      ...value,
      userFields: uf,
    };
    onChange(f);
    setFilter(f);
  };
  const userFieldValue = (id: string) =>
    value.userFields.find((x) => x.id === id)?.value;

  const fields = userFields.filter((x) => x.type === UserFieldType.Select);

  return (
    <Box {...rest}>
      <Text fontSize={5} mb={3}>
        Filters
      </Text>
      {loading ? (
        <Loader />
      ) : (
        <>
          <FormInputWrap label="Groups" mb={3}>
            <MultiSelect
              defaultLabel="<Any Group>"
              options={groups.map((x) => ({
                value: x.id.toString(),
                label: x.name,
              }))}
              value={value.groups.map((x) => x.toString())}
              onChange={setFilterGroups}
            />
          </FormInputWrap>
          {userFields.length > 0 && (
            <>
              <Text fontSize={2} mb={2} color="grey2" fontWeight={600}>
                USER FIELDS
              </Text>
              {fields.map((x, i) => (
                <UserFieldInput
                  filteredUsers={filteredUsers}
                  isFilter={true}
                  emptyMessage="<Any>"
                  field={x}
                  error={null}
                  selectedGroups={value.groups}
                  value={
                    userFieldValue(x.id) != null
                      ? (userFieldValue(x.id) as string[])
                      : []
                  }
                  fieldFilter={filter.userFields.filter((uf) => uf.id !== x.id)}
                  onChange={setUserField(x.id)}
                  key={x.id}
                  mb={i < fields.length - 1 ? 2 : 0}
                />
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};
