import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import axios from "axios";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import {
  Box,
  Button,
  Flex,
  FormTextArea,
  IconButton,
  IconToast,
  Loader,
  Panel,
  Text,
  useToast,
} from "@coaching-culture/ui";
import ProgressTimeline from "../Mindset/ProgressTimeline";
import StarRatingInput from "components/StarRatingInput";
import { Link } from "react-router-dom";
import ScormPlayer, { ContentAssignmentEvent } from "components/ScormPlayer";
import {
  Lesson,
  LessonAttempt,
  ModuleRatingSpec,
} from "@coaching-culture/types";
import CenterColumn from "components/CenterColumn";
import { useMeasure } from "react-use";
import { useOrg } from "auth/OrgProvider";
import { PdfIFrame } from "./PdfIFrame";
import {
  FaCheckCircle,
  FaRegWindowClose,
  FaRegWindowMaximize,
  FaTimesCircle,
} from "react-icons/fa";
import { MindsetWelcomeSplash } from "pages/Mindset/MindsetWelcomeSplash";
import { useTodoList } from "queries/todos";
import { useUserContent } from "queries/mindset";
import { useUser } from "auth";

const ModuleImage = styled.img`
  margin-right: 12px;
  width: 80px;
`;

const ContentHeader = ({
  stage,
  module,
}: {
  stage: string;
  module: Lesson;
}) => (
  <Flex alignItems="center" mb={4}>
    <ModuleImage src={process.env.REACT_APP_STATIC_ROOT + "/" + module.icon} />
    <Text>
      <strong>{module.name}</strong>
      <span> | {stage}</span>
    </Text>
  </Flex>
);

const RedirectBasedOnAttempt = ({
  attempt,
  slug,
}: {
  attempt: LessonAttempt;
  slug: string;
}) => {
  if (attempt == null) {
    return <Redirect to={`/solutions/lessons/modules/${slug}/welcome`} />;
  } else if (attempt.programmeCompletedOn == null) {
    return <Redirect to={`/solutions/lessons/modules/${slug}/programme`} />;
  } else {
    return <Redirect to={`/solutions/lessons/modules/${slug}/rate`} />;
  }
};

type LessonsParams = {
  slug: string;
};

const LessonsPlayer = () => {
  const match = useRouteMatch<LessonsParams>();
  const location = useLocation();
  const history = useHistory();
  const slug = match.params.slug;
  const [user] = useUser();

  const [ref, { width }] = useMeasure();

  const container = useRef(null);

  const theme = useTheme();
  const { addItem } = useTodoList();
  const content = useUserContent(user.id);
  const toast = useToast();
  const [module, setModule] = useState<Lesson | null>(null);
  const [attempt, setAttempt] = useState<LessonAttempt | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [rating, setRating] = useState<number | null>(null);
  const [feedback1, setFeedback1] = useState<string>("");
  const [feedback2, setFeedback2] = useState<string>("");
  const [feedback3, setFeedback3] = useState<string>("");
  const [notFound, setNotFound] = useState(false);
  const [fullscreen, setFullScreen] = useState(false);
  const [org] = useOrg();

  useEffect(() => {
    setNotFound(false);
    setLoading(true);
    setAttempt(null);
    setModule(null);
    axios
      .get(`/api/lessons/${slug}`)
      .then(({ data }) => {
        setModule(data);
        axios
          .get(`/api/lessons/${data.id}/attempt`)
          .then(({ data }) => {
            setAttempt(data);
          })
          .catch((err) => {
            if (err?.response.status === 404) {
              setAttempt(null);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setNotFound(true);
          setLoading(false);
        }
      });
  }, [slug, history]);

  useEffect(() => {
    const handleFullscreenEvent = () => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenEvent);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenEvent);
    };
  }, []);

  const startNewAttempt = () => {
    setLoading(true);
    axios.post(`/api/lessons/${module.id}/attempt`).then(({ data }) => {
      setAttempt(data);
      setLoading(false);
      history.push(`/solutions/lessons/modules/${slug}/programme`);
    });
  };

  const lessonComplete = () => {
    setLoading(true);
    axios.put(`/api/lessons/${module.id}/attempt/complete`).then(({ data }) => {
      setAttempt(data);
      if (org.collectRatings) {
        setLoading(false);
        history.push(`/solutions/lessons/modules/${slug}/rate`);
      } else {
        const spec = {
          rating: null,
          feedback1: null,
          feedback2: null,
          feedback3: null,
        };
        axios
          .put(`/api/lessons/${module.id}/attempt/rate`, spec)
          .then(({ data }) => {
            setLoading(false);
            setAttempt(data);
            history.push(`/solutions/lessons/modules/${slug}/complete`);
          });
      }
    });
  };

  const handleAssignContent = async (ev: ContentAssignmentEvent) => {
    const item = content.data?.find((x) => x.slug === ev.slug);

    if (item == null) {
      toast({
        content: (
          <IconToast
            icon={FaTimesCircle}
            text="No Access to that Content"
            iconColor="danger"
          />
        ),
      });
    } else {
      const res = await addItem({
        contentType: item.type,
        contentId: item.id,
        source: "content",
      });
      if (res) {
        toast({
          content: (
            <IconToast
              icon={FaCheckCircle}
              text="Content Assigned"
              iconColor="positive"
            />
          ),
        });
      } else {
        toast({
          content: (
            <IconToast
              icon={FaTimesCircle}
              text="Unable to assign content."
              iconColor="danger"
            />
          ),
        });
      }
    }
  };

  const onFullScreenClick = () => {
    if (!document.fullscreenElement) {
      container.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const submitRating = () => {
    const spec: ModuleRatingSpec = {
      rating,
      feedback1,
      feedback2,
      feedback3,
    };

    axios
      .put(`/api/lessons/${module.id}/attempt/rate`, spec)
      .then(({ data }) => {
        setAttempt(data);
        history.push(`/solutions/lessons/modules/${slug}/complete`);
      });
  };

  const commitSuspendData = (data: any) => {
    axios.post(`/api/lessons/${module.id}/scorm`, data.cmi);
  };

  if (notFound) {
    return (
      <Flex p={5} flexDirection="column" alignItems="center">
        <Text fontSize={5} fontWeight={600}>
          Module not found or unavailable
        </Text>
        <Link to="/solutions/lessons">Back to Modules</Link>
      </Flex>
    );
  }

  if (loading || module.slug !== slug) {
    return <Loader />;
  }

  let step = 0;

  if (location.pathname.includes("/programme")) {
    step = 1;
  } else if (location.pathname.includes("/rate")) {
    step = 2;
  } else if (location.pathname.includes("/complete")) {
    step = 3;
  }

  const steps = org.collectRatings
    ? ["Welcome", "Programme", "Rate", "Finish"]
    : ["Welcome", "Programme", "Finish"];

  return (
    <CenterColumn ref={ref}>
      <Panel ref={container} p={0} color={"primary"}>
        <ProgressTimeline color={"primary"} steps={steps} activeStep={step} />
        <Switch>
          <Route path="/solutions/lessons/modules/:slug" exact>
            <RedirectBasedOnAttempt attempt={attempt} slug={slug} />
          </Route>
          <Route path="/solutions/lessons/modules/:slug/welcome" exact>
            {attempt != null ? (
              <RedirectBasedOnAttempt attempt={attempt} slug={slug} />
            ) : (
              <MindsetWelcomeSplash
                name={module.name}
                color={theme.colors.primary}
                icon={module.icon}
                onStart={startNewAttempt}
              />
            )}
          </Route>
          <Route path="/solutions/lessons/modules/:slug/programme" exact>
            <Flex flexDirection="row-reverse" m={2}>
              <IconButton
                onClick={onFullScreenClick}
                type="button"
                icon={fullscreen ? FaRegWindowClose : FaRegWindowMaximize}
                color="black"
                title={fullscreen ? "Minimize" : "Maximize"}
              ></IconButton>
            </Flex>
            {attempt == null || attempt.programmeCompletedOn != null ? (
              <Redirect to={`/solutions/lessons/modules/${slug}`} />
            ) : module.path.startsWith("http") ? (
              <PdfIFrame path={module.path} onComplete={lessonComplete} />
            ) : (
              <ScormPlayer
                parentWidth={width}
                fullscreen={fullscreen}
                commitUrl={`/api/lessons/${module.id}/scorm`}
                contentUrl={module.path}
                onCommit={commitSuspendData}
                onComplete={lessonComplete}
                initialData={attempt.lmsData}
                onAssignContent={handleAssignContent}
              />
            )}
          </Route>
          <Route path={`/solutions/lessons/modules/${slug}/rate`} exact>
            {attempt == null || attempt.programmeCompletedOn == null ? (
              <Redirect to={`/solutions/lessons/modules/${slug}`} />
            ) : (
              <Box p={3}>
                <ContentHeader stage={"Rating"} module={module} />
                <Flex p={3} pb={5} flexDirection="column" alignItems="center">
                  <Text fontSize={5} fontWeight={600} mb={3}>
                    We hope you enjoyed this content. <br />
                    Please take a moment to leave some feedback.
                  </Text>
                  <StarRatingInput value={rating} onChange={setRating} mb={2} />
                  <FormTextArea
                    error={null}
                    label="What did you enjoy most about this module?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback1}
                    onChange={(ev) => setFeedback1(ev.target.value)}
                  />
                  <FormTextArea
                    error={null}
                    label="How will this module benefit you in the future?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback2}
                    onChange={(ev) => setFeedback2(ev.target.value)}
                  />
                  <FormTextArea
                    error={null}
                    label="If anything, what would you change about this module?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback3}
                    onChange={(ev) => setFeedback3(ev.target.value)}
                  />
                  <Button onClick={submitRating}>Continue</Button>
                </Flex>
              </Box>
            )}
          </Route>
          <Route path={`/solutions/lessons/modules/${slug}/complete`} exact>
            <Box p={3}>
              <ContentHeader stage={"Complete"} module={module} />
              <Flex p={3} pb={5} flexDirection="column" alignItems="center">
                <Text fontSize={5} fontWeight={600} mb={5}>
                  Thank you for completing this module
                </Text>
                <Button to="/solutions/lessons">Return to Modules</Button>
              </Flex>
            </Box>
          </Route>
        </Switch>
      </Panel>
    </CenterColumn>
  );
};

export default LessonsPlayer;
