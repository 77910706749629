import CenterColumn from "components/CenterColumn";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConversationDetails } from "./ConversationDetails";
import { ConversationPlayer } from "./ConversationPlayer";
import { Conversations } from "./Conversations";
import { CreateConversation } from "./CreateConversation";
import { CreateGoal } from "./CreateGoal";
import { Goals } from "./Goals";

export const Performance = () => {
  return (
    <CenterColumn>
      <Switch>
        <Route
          path="/solutions/performance/conversations/create"
          exact
          component={CreateConversation}
        />
        <Route
          path="/solutions/performance/conversations"
          exact
          component={Conversations}
        />
        <Route
          path="/solutions/performance/conversations/:id/:index"
          exact
          component={ConversationDetails}
        />
        <Route
          path="/solutions/performance/conversations/:id/:index/play"
          exact
          component={ConversationPlayer}
        />
        <Route path="/solutions/performance/goals" exact component={Goals} />
        <Route
          path="/solutions/performance/goals/create"
          exact
          component={CreateGoal}
        />
        <Redirect to="/solutions/performance/goals" />
      </Switch>
    </CenterColumn>
  );
};
