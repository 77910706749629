import React, { useRef } from "react";
import styled from "styled-components";
import { space, layout, LayoutProps, SpaceProps } from "styled-system";
import { FaTimes } from "react-icons/fa";
import { lighten } from "polished";
import { IconType } from "react-icons/lib";

const Container = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 6px;
  border-radius: 4px;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  max-width: 100%;
  box-shadow: rgba(204, 219, 232, 0.6) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;

  &:focus-within {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: inset 0px 0px 5px
      ${(props) => lighten(0.3, props.theme.colors.primary)};
  }

  ${space};
  ${layout};
`;

const Input = styled.input`
  margin-bottom: 6px;
  margin-top: 0px;
  margin-left: 6px;
  height: 30px;
  border: 0;
  flex: 1;
  padding: 0;

  &:focus {
    outline: 0;
  }
`;

const Option = styled.span<{ color: "green" | "blue" | "orange" }>`
  background-color: ${(props) => props.theme.colors[props.color]};
  color: white;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  padding: 8px;
  line-height: 1;
  padding-left: 12px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  border-radius: 3px;
  margin: 6px;
  margin-top: 0;
  margin-right: 0;
`;

const DeleteButton = styled.button`
  padding: 0;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin: 0 6px;
  margin-right: 3px;
  color: white;
  opacity: 0.7;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export type LabelInputOption = {
  label: string;
  color: "green" | "blue" | "orange";
  icon?: IconType;
};

export type LabelInputProps = {
  items: LabelInputOption[];
  onItemRemove: (index: number) => void;
  value: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const LabelInput = ({
  items,
  onItemRemove,
  value,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}: LabelInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null!);

  return (
    <Container onClick={() => inputRef.current.focus()}>
      {items.map(({ icon: Icon, color, label }, i) => (
        <Option key={i} color={color}>
          {Icon && <Icon style={{ marginRight: 9 }} />}
          {label}
          <DeleteButton onClick={() => onItemRemove(i)} type="button">
            <FaTimes />
          </DeleteButton>
        </Option>
      ))}
      <Input
        ref={inputRef}
        autoComplete="off"
        type="search"
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder="Start typing..."
        autoFocus
      />
    </Container>
  );
};
