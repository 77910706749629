import { useSpring, animated } from "react-spring";
import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type MultiToggleProps = {
  items: string[];
  active: number;
  onSelect: (i: number) => void;
  disabled?: boolean;
} & SpaceProps;

const Wrapper = styled.div<SpaceProps>`
  ${space};
`;

const Container = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  position: relative;
  align-items: center;
  background: ${(props) =>
    props.disabled ? props.theme.colors.grey5 : props.theme.colors.grey0};
  border-radius: 3px;
  height: 34px;

  box-shadow: rgb(204, 219, 232) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
`;

const Item = styled(animated.div)<{ disabled: boolean }>`
  width: 120px;
  text-align: center;
  padding: 3px 6px;
  flex: 1 0 120px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  color: ${(props) =>
    props.disabled ? props.theme.colors.grey0 : props.theme.colors.grey2};
`;

const Needle = styled(animated.div)<{ disabled: boolean }>`
  position: absolute;
  display: inline-flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 120px;
  height: 100%;
  overflow: hidden;
  background: ${(props) =>
    props.disabled ? props.theme.colors.grey2 : props.theme.colors.primary};
  border-radius: 3px;
  pointer-events: none;
`;

export function MultiToggle({
  items,
  active,
  onSelect,
  disabled = false,
  ...rest
}: MultiToggleProps) {
  const needleProps = useSpring({
    transform: `translate3d(${active * 120}px, 0px, 0px)`,
  });

  const itemProps = useSpring({
    transform: `translate3d(${active * -120}px, 0px, 0px)`,
  });

  return (
    <Wrapper {...rest}>
      <Container disabled={disabled}>
        {items.map((x, i) => (
          <Item
            disabled={disabled}
            key={i}
            onClick={() => {
              if (!disabled) {
                onSelect(i);
              }
            }}
          >
            {x}
          </Item>
        ))}
        <Needle disabled={disabled} style={needleProps}>
          {items.map((x, i) => (
            <Item
              disabled={disabled}
              key={i}
              style={{
                ...itemProps,
                color: "white",
              }}
            >
              {x}
            </Item>
          ))}
        </Needle>
      </Container>
    </Wrapper>
  );
}
