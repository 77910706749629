import { CollectiveGoalDto } from "@coaching-culture/types";
import { Loader, Panel } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useCreateOrgGoal } from "queries/performance";
import { useState } from "react";
import { useHistory } from "react-router";
import { OrgGoalForm } from "./OrgGoalForm";
import { useGoalTerminology } from "auth/OrgProvider";

export function CreateOrgGoal() {
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  const createGoal = useCreateOrgGoal();
  const terms = useGoalTerminology();

  const onSave = async (spec: CollectiveGoalDto) => {
    setSaving(true);
    try {
      const newId = await createGoal.mutateAsync(spec);
      history.push(`/success/performance/org-goals/${newId}`);
    } catch (err) {
      window.alert(err);
      setSaving(false);
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text={`Create Organisation ${terms.goal.asTitle()}`}
        subtitle={`Set ${terms.goal.asPluralBody()} at the organisation level.`}
      />
      <Panel p={[2, 3]}>
        {saving && <Loader overlay />}
        <OrgGoalForm onSave={onSave} />
      </Panel>
    </CenterColumn>
  );
}
