import {
  Button,
  Flex,
  Text,
  Circle,
  Panel,
  Loader,
} from "@coaching-culture/ui";
import styled from "styled-components";
import { lighten } from "polished";
import { FaFileUpload } from "react-icons/fa";
import Papa from "papaparse";
import jschardet from "jschardet";
import { useState } from "react";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useOrgUsers } from "queries/users";
import axios from "axios";

const UploadBox = styled(Flex)`
  border: 3px dashed ${(props) => props.theme.colors.primary};
  border-radius: 6px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => lighten(0.44, props.theme.colors.primary)};
`;

const FileButton = styled.label`
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 24px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
  }

  & input {
    display: none;
  }
`;

type CsvLine = Record<string, string>;
type DeleteResult = { deletedUsers: string[]; notFoundUser: string[] };

export const BulkDelete = () => {
  const [data, setData] = useState<CsvLine[] | null>(null);
  const [deleteResult, setDeleteResult] = useState<DeleteResult>({
    deletedUsers: [],
    notFoundUser: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);

  const { data: users, isFetched } = useOrgUsers();

  const handleFileChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files[0];
    if (file == null) {
      return window.alert("Unable to read file");
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const csvResult = e.target.result as string;
      const enc = jschardet.detect(csvResult).encoding;
      Papa.parse<CsvLine>(file, {
        delimiter: " ",
        header: true,
        encoding: enc,
        skipEmptyLines: true,
        complete: (data) => {
          if (data.errors.length > 0) {
            ev.target.value = "";
            window.alert(
              "There was an error reading the CSV:" + data.errors[0].message
            );
          } else {
            setData(data.data);
          }
        },
      });
    };
    reader.readAsBinaryString(file);
  };

  const deleteUsers = () => {
    setLoading(true);
    const toDelete: string[] = [];
    data.forEach((user) => {
      const foundUser = users.find((x) => x.email === user.Email);
      if (foundUser) {
        setDeleteResult((prevState) => ({
          ...prevState,
          deletedUsers: [...prevState.deletedUsers, user.Email],
        }));
        toDelete.push(foundUser.id);
      } else {
        setDeleteResult((prevState) => ({
          ...prevState,
          notFoundUser: [...prevState.notFoundUser, user.Email],
        }));
      }
    });

    Promise.all(toDelete.map((x) => axios.delete(`/api/users/${x}`))).then(
      (res) => {
        setLoading(false);
        setShowResult(true);
      }
    );
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Bulk User Delete"
        subtitle="Upload a CSV of a list of emails to delete a large number of users quickly"
        backUrl="/success/people/users"
      />
      <Panel p={3}>
        {loading || !isFetched ? (
          <Loader />
        ) : showResult ? (
          <>
            <Text fontWeight={600} fontSize={4}>
              Deleted users:
            </Text>
            <ul>
              {deleteResult.deletedUsers.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
            <Text fontWeight={600} fontSize={4}>
              Users not found:
            </Text>
            <ul>
              {deleteResult.notFoundUser.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
            <Flex mt={2} alignItems={"flex-start"}>
              <Button to="/success/people/users">Return to Users</Button>
            </Flex>
          </>
        ) : data === null ? (
          <UploadBox
            justifyContent="center"
            alignItems="center"
            p={5}
            flexDirection="column"
          >
            <Circle icon={FaFileUpload} size="xlarge" color="primary" mb={3} />
            <Text fontWeight={600} fontSize={4} textAlign="center">
              Upload CSV
            </Text>
            <Text mb={4} width={300} textAlign="center">
              Ensure there is only one column with a header of 'Email'
            </Text>
            <Flex alignItems="center">
              <Button mr={2} href="/BulkDelete.csv">
                Example CSV
              </Button>
              <FileButton as="label">
                Select CSV File
                <input type="file" onChange={handleFileChange} />
              </FileButton>
            </Flex>
          </UploadBox>
        ) : (
          <>
            <Text fontWeight={600} fontSize={4}>
              Users to delete
            </Text>
            <ul>
              {data.map((x) => (
                <li>{x.Email}</li>
              ))}
            </ul>
            <Flex alignItems={"flex-start"}>
              <Button color="danger" onClick={deleteUsers}>
                Delete Users
              </Button>
            </Flex>
          </>
        )}
      </Panel>
    </CenterColumn>
  );
};
