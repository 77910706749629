import {
  Text,
  Button,
  Input,
  Panel,
  SectionHeading,
  Loader,
  useToast,
  IconToast,
} from "@coaching-culture/ui";
import { MeasuresConfigurationDto } from "@coaching-culture/types";

import { useForm } from "react-hook-form";
import { useMeasuresConfig, useUpdateMeasuresConfig } from "queries/measures";
import { useEffect } from "react";
import { FaSave } from "react-icons/fa";

export const ReminderScheduleForm = () => {
  const config = useMeasuresConfig();
  const updateConfig = useUpdateMeasuresConfig();
  const toast = useToast();

  const { register, handleSubmit, reset } = useForm<MeasuresConfigurationDto>();

  useEffect(() => {
    if (config.data) {
      reset(config.data);
    }
  }, [config.data, reset]);

  const onSubmit = async (values: MeasuresConfigurationDto) => {
    await updateConfig.mutateAsync({
      ...config.data,
      reminderSchedule: values.reminderSchedule,
    });
    toast({
      content: <IconToast icon={FaSave} text="Saved!" iconColor="primary" />,
    });
  };

  return (
    <>
      <SectionHeading>Reminder Schedule</SectionHeading>
      <Panel p={3} style={{ position: "relative" }}>
        {config.isLoading ? <Loader overlay /> : null}
        <Text>
          The platform will send email reminders to managers on a schedule to
          remind them to update the measures for their team. This ensures
          measures are always fresh.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text fontWeight={500} fontSize={3} mb={3}>
            Remind managers to update the measures for their team every{" "}
            <Input
              style={{ display: "inline-block" }}
              width={80}
              ref={register({
                valueAsNumber: true,
                required: true,
              })}
              name="reminderSchedule"
            />{" "}
            days.
          </Text>
          <Button type="submit" color="primary">
            Save
          </Button>
        </form>
      </Panel>
    </>
  );
};
