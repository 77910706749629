import { SurveyFormSummary, SurveySpec } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormInput,
  FormToggle,
  IconProgress,
  Panel,
  RichTextEditor,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { FormDateSelect } from "components/FormDateSelect";
import { PageHeader } from "components/PageHeader";
import { getUnixTime } from "date-fns";
import { useCreateSurveyCampaign } from "queries/surveys";
import { useState } from "react";
import { FaClipboardCheck, FaCogs, FaEnvelope, FaUsers } from "react-icons/fa";
import { useHistory } from "react-router";
import { SurveyFormSelector } from "./SurveyFormSelector";
import { UserSelectionTable } from "./UserSelectionTable";

const defaultEmail = `
<h2>Please complete this survey</h2>
<p>
  Please click the link below to begin the survey.
</p>
`;

export type Recipient = {
  id: string;
  name: string;
  email: string;
};

type SurveyState = {
  name: string;
  description: string;
  thumbnail: string;
  surveyForm: SurveyFormSummary;
  recipients: Recipient[];
  email: string;
  emailSubject: string;
  sendEmail: boolean;
  deadline: Date | null;
};

export default function CreateSurvey() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [spec, setSpec] = useState<Partial<SurveyState>>({
    name: "",
    description: "",
    recipients: [],
    email: defaultEmail,
    emailSubject: "Please complete this survey.",
    sendEmail: true,
    deadline: null,
  });
  const [step, setStep] = useState<number>(0);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const createSurvey = useCreateSurveyCampaign();

  const submit = (v: Partial<SurveyState>, isDraft: boolean) => {
    const values = v as SurveyState;

    const spec: SurveySpec = {
      name: values.name,
      description: values.description,
      thumbnail: values.thumbnail,
      emailBody: v.email,
      emailSubject: v.emailSubject,
      surveyFormId: v.surveyForm.id,
      recipients: v.recipients.map((x) => x.id),
      sendEmail: v.sendEmail,
      isDraft: isDraft,
      deadline: v.deadline == null ? null : getUnixTime(v.deadline),
    };

    setSubmitting(true);
    createSurvey
      .mutateAsync(spec)
      .then(({ data }) => {
        history.push(`/success/surveys/campaigns/${data.id}`);
      })
      .catch((err) => {
        setSubmitting(false);
        window.alert("There was an error");
      });
  };

  const setValue = (key: keyof SurveyState) => (val: any) => {
    setSpec((old) => ({
      ...old,
      [key]: val,
    }));
    setErrors((old) => ({
      ...old,
      [key]: null,
    }));
  };

  const calcErrors = () => {
    if (step === 0) {
      return {
        surveyForm: spec.surveyForm == null ? "A form is required" : null,
      };
    } else if (step === 1) {
      return {
        name: spec.name ? null : "Name is required",
      };
    }
    return {};
  };

  const advance = (isDraft: boolean) => {
    const errors = calcErrors();

    if (Object.values(errors).some((x) => x != null)) {
      setErrors(errors);
    } else if (step === 2) {
      if (spec.recipients.length === 0) {
        window.alert("At least one user is required");
      } else {
        setStep((old) => old + 1);
      }
    } else if (step < 3) {
      setErrors({});
      setStep((old) => old + 1);
    } else {
      submit(spec, isDraft);
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Send Survey"
        subtitle="Send a new survey to members of your organisation"
        backUrl="/success/surveys"
      />
      <Panel p={3}>
        <Flex mb={5} mt={2} width="80%" maxWidth="500px" ml="auto" mr="auto">
          <IconProgress
            icons={[FaClipboardCheck, FaCogs, FaUsers, FaEnvelope]}
            activeIndex={step}
          />
        </Flex>
        <Box mb={3}>
          {step === 0 ? (
            <>
              <Text fontSize={4}>Select Form</Text>
              <Text color="grey1" mb={3}>
                Select a form to use in your Survey
              </Text>
              <SurveyFormSelector
                value={spec.surveyForm}
                onChange={(v) => {
                  setValue("surveyForm")(v);
                  setValue("name")(v.name);
                }}
              />
              {errors.feedbackForm ? (
                <Text color="danger" mt={1}>
                  {errors.feedbackForm}
                </Text>
              ) : null}
            </>
          ) : step === 1 ? (
            <>
              <Text fontSize={4}>Customise Survey</Text>
              <Text color="grey1" mb={3}>
                Change how your survey behaves
              </Text>
              <FormInput
                required
                name="name"
                label="Survey Name"
                width="500px"
                error={errors.name}
                value={spec.name}
                onChange={(ev) => setValue("name")(ev.target.value)}
              />
              <FormDateSelect
                label="Due Date (Leave clear for no due date)"
                value={spec.deadline}
                onChange={setValue("deadline")}
                minDate={new Date()}
                allowClear
              />
            </>
          ) : step === 2 ? (
            <>
              <Text fontSize={4}>Select Users</Text>
              <Text color="grey1" mb={3}>
                Select who to distribute the Campaign to
              </Text>
              <UserSelectionTable
                value={spec.recipients}
                onChange={setValue("recipients")}
              />
            </>
          ) : step === 3 ? (
            <>
              <FormToggle
                value={spec.sendEmail}
                mb={3}
                label="Send system emails"
                helpText="This cannot be changed after creation"
                onChange={setValue("sendEmail")}
              ></FormToggle>
              {spec.sendEmail && (
                <>
                  <Text fontSize={4}>Email Template</Text>
                  <Text color="grey1" mb={3}>
                    This is the email that will be sent to your recipients.
                  </Text>
                  <FormInput
                    name="subject"
                    label="Email Subject"
                    value={spec.emailSubject}
                    onChange={(ev) => setValue("emailSubject")(ev.target.value)}
                  />
                  <RichTextEditor
                    value={spec.email}
                    onChange={setValue("email")}
                  />
                </>
              )}
            </>
          ) : null}
        </Box>
        <Flex justifyContent="center">
          <Button
            mr={1}
            disabled={step === 0}
            onClick={() => setStep((old) => old - 1)}
          >
            Back
          </Button>
          <Button
            color="primary"
            ml={1}
            onClick={() => advance(false)}
            disabled={submitting}
          >
            {step === 3 ? "Submit & Send" : "Next"}
          </Button>
          {step === 3 && (
            <Button
              color="primary"
              ml={2}
              onClick={() => advance(true)}
              disabled={submitting}
            >
              Save as Draft
            </Button>
          )}
        </Flex>
      </Panel>
    </CenterColumn>
  );
}
