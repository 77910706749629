import {
  FeedbackCampaign,
  FeedbackSummaryInsights,
  FormResponses,
  FreeTextQuestionFormItemResponses,
  MultipleChoiceQuestionFormItemResponses,
  RatingFormItemResponses,
  UserSummary,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Loader,
  Modal,
  MultiSelect,
  Panel,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import {
  FeedbackInsightsDisplay,
  groupBySection,
} from "components/FeedbackInsights";
import { PageHeader } from "components/PageHeader";
import { RatingStars } from "components/RatingInsights";
import { UserFlag } from "components/UserFlag";
import { getUnixTime } from "date-fns";
import { uniqBy } from "lodash";
import { normalizeRating } from "pages/Solutions/Feedback/UserCampaignDisplay";
import Papa from "papaparse";
import { useDeleteUserFromCampaign } from "queries/feedback";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaTrash, FaUserPlus } from "react-icons/fa";
import { useParams } from "react-router";
import { downloadContent } from "utils";
import { UserSelectModal } from "./UserSelectModal";

const searchUsers = (item: FeedbackSummaryInsights["users"][number]) =>
  [item.name, item.email].join(" ");

function DeleteCampaignUserModal({
  email,
  onCancel,
  onConfirm,
}: {
  email: string;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const [confirmEmail, setConfirmEmail] = useState("");

  return (
    <Modal width={400}>
      <Box p={4}>
        <Text mb={2} fontWeight={600} fontSize={4}>
          Remove User
        </Text>
        <Text fontSize={3}>
          Are you sure you want to remove the user from this campaign? This will
          completely remove all results gathered for this user and is
          irreversible. If you are sure then please enter the users email
          address into the box to confirm.
        </Text>

        <Text fontWeight={600} mt={2}>
          Email Address
        </Text>
        <Input
          value={confirmEmail}
          onChange={(val) => setConfirmEmail(val.target.value)}
        />
        <Flex mt={3} alignItems={"center"} flexDirection={"column"}>
          <Flex>
            <Button
              disabled={confirmEmail !== email}
              mr={2}
              onClick={onConfirm}
              color={"danger"}
            >
              Delete
            </Button>
            <Button onClick={() => onCancel()} color="primary">
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
}

export function Campaign() {
  const { id } = useParams<{ id: string }>();
  const [item, setItem] = useState<FeedbackCampaign | null>(null);
  const [report, setReport] = useState<FeedbackSummaryInsights | null>(null);
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [deletingUser, setDeleteingUser] = useState<{
    email: string;
    id: string;
  }>(null);
  const [user] = useUser();
  const deleteUserFromCampaign = useDeleteUserFromCampaign(id);

  const canView = useMemo(() => {
    if (item == null) {
      return false;
    }

    if (item.resultsVisibility.includes("admin")) {
      return true;
    }

    return item.viewers.map((x) => x.id).includes(user.id);
  }, [item, user]);

  const groupOptions = useMemo(() => {
    if (report == null) {
      return [];
    }

    return uniqBy(
      report.users.flatMap((x) => x.groups),
      (x) => x.id
    ).map((x) => ({
      value: x.id.toString(),
      label: x.name,
    }));
  }, [report]);

  const nudgeUser = useCallback(
    (feedbackUserId: string) => {
      axios
        .put(`/api/v2/feedback-campaigns/${id}/users/${feedbackUserId}/nudge`)
        .then(() => {
          const u = report.users.find(
            (x) => x.feedbackCampaignUserId === feedbackUserId
          );
          u.lastNudged = getUnixTime(new Date());
          setReport({ ...report });
        });
    },
    [id, report]
  );

  const users = useMemo(() => {
    if (report == null) {
      return [];
    }

    if (groupFilter.length === 0) {
      return report.users;
    }

    return report.users.filter((x) =>
      x.groups.map((x) => x.id).some((x) => groupFilter.includes(x))
    );
  }, [groupFilter, report]);

  const pull = useCallback((id: string) => {
    axios.get(`/api/feedback/campaigns/${id}`).then(({ data }) => {
      setItem(data);
    });
    axios.get(`/api/feedback/campaigns/${id}/report`).then(({ data }) => {
      setReport(data);
    });
  }, []);

  const items = useMemo(() => {
    if (report == null) {
      return [];
    }

    const compIds = users.flatMap((x) => x.completionIds);

    const items = report.items.map<FormResponses>((x) => {
      if ("responses" in x) {
        return {
          ...x,
          responses: (x.responses as any).filter((x) =>
            compIds.includes(x.completionId)
          ),
        };
      } else {
        return x;
      }
    });

    return items;
  }, [users, report]);

  const exportCallback = useCallback(
    (userId: string) => {
      const getRelationship = (id: string) => {
        return item.relationships.find((z) => z.id === id)?.name ?? "";
      };

      const userReport = report.users.find((x) => x.id === userId);
      const sections = groupBySection(items as FormResponses[]);
      let relationRow = { Section: "", Question: "", Type: "" };

      let result = sections.flatMap((section) =>
        section.items
          .filter((y) => y.type !== "heading" && y.type !== "content")
          .map((x, i) => {
            let row = {
              Section: section.heading?.content,
              Question: x.content,
              Type: x.type,
            };
            if (i === 0) {
              const temp = x as any;
              temp.responses
                .filter((y) =>
                  userReport.completionIds.some((z) => z === y.completionId)
                )
                .forEach((y, j) => {
                  relationRow = {
                    ...relationRow,
                    [item.anonType === "transparent"
                      ? y.name
                      : `Respondent ${j + 1}`]: getRelationship(
                      y.feedbackRelationshipId
                    ),
                  };
                });
            }
            if (x.type === "rating") {
              const rating = x as RatingFormItemResponses;
              const arr = rating.responses
                .filter((y) =>
                  userReport.completionIds.some((z) => z === y.completionId)
                )
                .map((y, j) => {
                  return {
                    [item.anonType === "transparent"
                      ? y.name
                      : `Respondent ${j + 1}`]: normalizeRating(
                      y.rating,
                      x.ratingType
                    ),
                  };
                });
              return {
                ...row,
                ...Object.fromEntries(arr.flatMap(Object.entries)),
              };
            } else if (x.type === "free_text") {
              const textResult = x as FreeTextQuestionFormItemResponses;
              const arr = textResult.responses
                .filter((y) =>
                  userReport.completionIds.some((z) => z === y.completionId)
                )
                .map((y, j) => ({
                  [item.anonType === "transparent"
                    ? y.name
                    : `Respondent ${j + 1}`]: y.content,
                }));
              return {
                ...row,
                ...Object.fromEntries(arr.flatMap(Object.entries)),
              };
            } else {
              const multipleResult =
                x as MultipleChoiceQuestionFormItemResponses;
              const arr = multipleResult.responses
                .filter((y) =>
                  userReport.completionIds.some((z) => z === y.completionId)
                )
                .map((y, j) => ({
                  [item.anonType === "transparent"
                    ? y.name
                    : `Respondent ${j + 1}`]: multipleResult.answers.find(
                    (x) => x.id === y.answer
                  ).content,
                }));
              return {
                ...row,
                ...Object.fromEntries(arr.flatMap(Object.entries)),
              };
            }
          })
      );

      if (item.collectRelationship) {
        result.splice(0, 0, relationRow as any);
      }

      const csv = Papa.unparse(result, { delimiter: ",", header: true });
      downloadContent(csv, `feedback.csv`);
    },
    [report, items, item]
  );

  const cols = useMemo<
    SortableTableColumn<FeedbackSummaryInsights["users"][number]>[]
  >(() => {
    const getCampaignId = (userId: string) =>
      item.users.find((x) => x.id === userId)?.campaignUserId;

    return [
      {
        name: "name",
        label: "User",
        format: (x) => (
          <UserFlag
            user={x}
            to={
              canView
                ? `/solutions/feedback/campaigns/${getCampaignId(x.id)}`
                : undefined
            }
          />
        ),
        sortFormat: (x) => x.name.toLowerCase(),
      },
      {
        name: "respondentCount",
        label: "Respondents",
        headingProps: {
          textAlign: "right",
        },
        props: {
          textAlign: "right",
        },
        format: (x) => (
          <Flex alignItems={"center"} justifyContent={"flex-end"}>
            {x.respondentCount === 0 && (
              <Button
                tiny
                onClick={() => nudgeUser(x.feedbackCampaignUserId)}
                mr={2}
                disabled={x.lastNudged != null}
              >
                {x.lastNudged == null ? "Nudge?" : "Nudged"}
              </Button>
            )}
            {x.respondentCount}
          </Flex>
        ),
      },
      {
        name: "responseCount",
        label: "Responses",
        headingProps: {
          textAlign: "right",
        },
        props: {
          textAlign: "right",
        },
      },
      {
        name: "averageScore",
        label: "Average Score",
        headingProps: {
          textAlign: "right",
        },
        props: {
          textAlign: "right",
        },
        sortFormat: (x) => x.averageScore?.value ?? -1,
        format: (x) =>
          x.averageScore == null ? (
            "N/A"
          ) : x.averageScore.type === "stars" ? (
            <Flex justifyContent="flex-end" style={{ fontSize: "0.8em" }}>
              <RatingStars value={x.averageScore.value * 5} />
            </Flex>
          ) : (
            <Text>{(x.averageScore.value * 10).toFixed(1)}</Text>
          ),
      },
      {
        name: "actions",
        label: "",
        headingProps: {
          textAlign: "right",
          width: "200",
        },
        format: (x) =>
          !canView ? (
            ""
          ) : (
            <Flex justifyContent={"center"}>
              {!item.archived && item.users.length > 1 && (
                <IconButton
                  title="Delete"
                  mr={2}
                  icon={FaTrash}
                  color={"danger"}
                  onClick={() => setDeleteingUser({ email: x.email, id: x.id })}
                />
              )}
              <Button tiny onClick={() => exportCallback(x.id)}>
                Export
              </Button>
            </Flex>
          ),
      },
    ];
  }, [item, canView, nudgeUser, exportCallback]);

  useEffect(() => {
    pull(id);
  }, [id, pull]);

  if (item == null) {
    return <Loader />;
  }

  const addUsers = (users: UserSummary[]) => {
    setShowAddUserModal(false);
    if (users.length > 0) {
      axios
        .post(`/api/v2/feedback-campaigns/${id}/users`, {
          userIds: users.map((x) => x.id),
        })
        .then(() => {
          pull(id);
        });
    }
  };

  return (
    <>
      {showAddUserModal && <UserSelectModal onSelect={addUsers} />}
      {deletingUser && (
        <DeleteCampaignUserModal
          email={deletingUser.email}
          onCancel={() => setDeleteingUser(null)}
          onConfirm={() => {
            deleteUserFromCampaign
              .mutateAsync(deletingUser.id)
              .then(() => pull(id));
            setDeleteingUser(null);
          }}
        />
      )}
      <CenterColumn>
        <PageHeader
          text={item.name}
          subtitle="An overview of this campaign"
          backUrl="/success/feedback"
        />
        <Flex justifyContent="space-between" mb={1}>
          <Flex alignItems="center">
            <Text fontSize={4} fontWeight={500}>
              Users
            </Text>
            <Button
              ml={2}
              icon={FaUserPlus}
              color="primary"
              onClick={() => setShowAddUserModal(true)}
            >
              Add Users
            </Button>
          </Flex>
          <Flex alignItems="center">
            <Text mr={2}>Filter By Group</Text>
            <MultiSelect
              disabled={groupOptions.length === 0}
              value={groupFilter}
              onChange={setGroupFilter}
              options={groupOptions}
              defaultLabel="All Groups"
            />
          </Flex>
        </Flex>
        {!item.sendEmail && (
          <Text>Please note user emails are disabled for this campaign</Text>
        )}
        {item.isDraft && (
          <Text fontWeight={600} color={"warning"}>
            This campaign is currently a draft and has not been sent to users
          </Text>
        )}

        <Panel mb={3}>
          {report == null ? (
            <Loader />
          ) : (
            <SortableTable
              columns={cols}
              data={users}
              paged
              rowSearch={searchUsers}
            />
          )}
        </Panel>
        <FeedbackInsightsDisplay
          title="Campaign users overall report"
          insights={items}
          relationships={[]}
          highlightedUser={null}
          showResponses={false}
          campaign={item}
        />
      </CenterColumn>
    </>
  );
}
