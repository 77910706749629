import { useUser } from "auth";
import CenterColumn from "components/CenterColumn";
import NavBar from "components/NavBar";
import { PageHeader } from "components/PageHeader";
import { Route, Switch } from "react-router";
import { DeliverySettings } from "./DeliverySettings";
import { ProfileSettings } from "./ProfileSettings";

const AccountSettings = () => {
  const [user] = useUser();

  const navLinks = [
    {
      label: "Profile",
      url: "/account-settings",
      exact: true,
    },
    user.permissions.includes("community") && {
      label: "Magazine",
      url: "/account-settings/delivery",
    },
  ].filter(Boolean);

  return (
    <CenterColumn>
      <PageHeader
        text="Account Settings"
        subtitle="Edit your public profile here"
      />
      <NavBar links={navLinks} activeColor="primary" />
      <Switch>
        <Route path="/account-settings" exact component={ProfileSettings} />
        {user.permissions.includes("community") && (
          <Route
            path="/account-settings/delivery"
            exact
            component={DeliverySettings}
          />
        )}
      </Switch>
    </CenterColumn>
  );
};

export default AccountSettings;
