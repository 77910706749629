import { DirectReportDto } from "@coaching-culture/types";
import { Flex } from "@coaching-culture/ui";
import { min, range } from "lodash";
import { mix } from "polished";
import styled, { useTheme } from "styled-components";

const Track = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.grey4};
  margin: 3px 0;
  position: relative;
`;

const Needle = styled.div`
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.2em;
`;

export type MeasuresQuickChartProps = {
  user: DirectReportDto;
};

const makeRamp = (colors: string[], count: number) =>
  range(count)
    .map((x) => {
      const p = x / (count - 1);
      const i = Math.floor(p * (colors.length - 1));
      const i2 = min([i + 1, colors.length - 1]);
      const p2 = p * (colors.length - 1) - i;
      const c = mix(p2, colors[i2], colors[i]);
      return c;
    })
    .reverse();

export const MeasuresQuickChart = ({ user }: MeasuresQuickChartProps) => {
  const theme = useTheme();

  return (
    <Flex width="100%" mr={3} flexDirection="column">
      {user.measures.map((x) => {
        const colorRamp = makeRamp(
          [theme.colors.danger, theme.colors.warning, theme.colors.positive],
          x.options.length,
        ).reverse();

        const index = x.options.findIndex((o) => o.id === x.value);

        const position = index / Math.max(1, x.options.length - 1);

        return (
          <Track>
            {index > -1 && (
              <Needle
                style={{
                  left: `calc(${position * 100}% - 4px)`,
                  backgroundColor: colorRamp[index],
                }}
              />
            )}
          </Track>
        );
      })}
    </Flex>
  );
};
