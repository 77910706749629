import { range } from "lodash";
import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type PipState = "active" | "passed" | "inactive";

type PipsContainerProps = SpaceProps;

const Container = styled.div<PipsContainerProps>`
  height: 12px;
  display: flex;
  gap: 6px;
  ${space};
`;

const Pip = styled.div<{ state: PipState }>`
  flex: 1;
  height: 100%;
  border-radius: 2px;
  transition: all 0.3s ease;
  background-color: ${(props) =>
    props.state === "active"
      ? props.theme.colors.primary
      : props.state === "passed"
        ? lighten(0.2, props.theme.colors.primary)
        : props.theme.colors.grey3};
`;

export type PipsProps = {
  value: number;
  count: number;
} & PipsContainerProps;

export const Pips = ({ value, count, ...rest }: PipsProps) => {
  if (count <= 0) {
    return null;
  }

  return (
    <Container {...rest}>
      {range(0, count).map((x) => (
        <Pip
          state={value === x ? "active" : value > x ? "passed" : "inactive"}
        />
      ))}
    </Container>
  );
};
