import { Button, Flex, Input, Text, TextArea } from "@coaching-culture/ui";
import React from "react";
import { animated, useTrail } from "react-spring";

export type FreeTextProps = {
  content: string;
  fieldType: "multiline" | "singleline";
  value: string;
  onChange: (val: string) => void;
  index: number;
  count: number;
  reverse: boolean;
  sectionName: string | null;
  onConfirm: () => void;
};

export const FreeText = ({
  content,
  fieldType,
  value,
  onChange,
  index,
  count,
  reverse,
  sectionName,
  onConfirm
}: FreeTextProps) => {
  let trail = useTrail(3, {
    from: {
      y: 100 * (reverse ? -1 : 1),
      opacity: 0
    },
    to: {
      y: 0,
      opacity: 1
    }
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex flexDirection="column" mb={3}>
      <animated.div style={trail[0]}>
        <Text fontWeight={600} color="grey2" fontSize={2}>
          {sectionName ? `${sectionName} - ` : ""} {index + 1} / {count}
        </Text>
        <Text mb={3} fontSize={4} fontWeight={600}>
          {content}
        </Text>
      </animated.div>
      <animated.div style={trail[1]}>
        {fieldType === "multiline" ? (
          <TextArea
            mb={3}
            value={value ?? ""}
            onChange={(ev) => onChange(ev.target.value)}
          />
        ) : (
          <Input
            mb={3}
            value={value ?? ""}
            onChange={(ev) => onChange(ev.target.value)}
          />
        )}
      </animated.div>
      <animated.div style={trail[1]}>
        <Flex justifyContent="center">
          <Button
            color="primary"
            onClick={onConfirm}
            disabled={(value ?? "") === ""}
          >
            Continue
          </Button>
        </Flex>
      </animated.div>
    </Flex>
  );
};
