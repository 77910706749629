import {
  ContinuousFeedbackType,
  SendFeedbackRequest,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  Flex,
  FormInput,
  FormInputWrap,
  Img,
  Loader,
  Modal,
  Panel,
  PanelHeader,
  RichTextEditor,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import CenterColumn from "components/CenterColumn";
import { FormFeedbackTypeSelect } from "components/FormFeedbackTypeSelect";
import { FormUserSelect } from "components/FormUserSelect";
import { PageHeader } from "components/PageHeader";
import { isEmpty } from "lodash";
import {
  useContinuousFeedbackRequest,
  useSendFeedback,
} from "queries/continuousFeedback";
import { useOrgUsers } from "queries/users";
import imgSrc from "../../../img/continuous-feedback.svg";
import sentFeedbackImage from "../../../img/continuous-feedback-sent-feedback.svg";
import { useState } from "react";
import { FaCommentDots } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

export type SentFeedbackModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

const ReasonBox = styled.div`
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 12px;
  background-color: ${(props) => props.theme.colors.grey5};
`;

function FeedbackSentModal({ onCancel, onConfirm }: SentFeedbackModalProps) {
  const history = useHistory();
  return (
    <Modal onClose={() => onCancel} width={600}>
      <Box p={4}>
        <Flex alignItems={"center"} flexDirection={"column"}>
          <Flex mb={3}>
            <Text fontWeight={600} fontSize={4}>
              Your feedback has been sent
            </Text>
          </Flex>
          <Img mb={3} src={sentFeedbackImage} height={"200px"} />
          <Button mb={2} onClick={() => onConfirm()} color="primary">
            Send more feedback
          </Button>
          <Button
            onClick={() => history.push("/solutions/continuous-feedback")}
            color="primary"
          >
            Go back to my feedback
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

export function SendContinuousFeedback() {
  const { userId } = useParams<{ userId?: string }>();
  const { requestId } = useParams<{ requestId?: string }>();

  const { data: users, isFetched: orgUsersFetched } = useOrgUsers();
  const sendFeedback = useSendFeedback();
  const [me] = useUser();
  const { data: feedbackRequest, isFetched: requestFetched } =
    useContinuousFeedbackRequest(requestId);

  const [feedbackSent, setFeedbackSent] = useState(false);
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [feedback, setFeedback] = useState<Partial<SendFeedbackRequest>>({
    recipientId: userId,
    type: null,
    summary: "",
    detail: "",
    requestId: requestId,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const getErrors = (spec: any) => {
    const errors = {};

    const required: (keyof SendFeedbackRequest)[] = [
      "summary",
      "recipientId",
      "type",
    ];

    for (const r of required) {
      if (spec[r] == null || spec[r] === "") {
        errors[r] = "Value is required";
      }
    }

    return errors;
  };

  const submit = async () => {
    const e = getErrors(feedback);
    setErrors(e);

    if (isEmpty(e)) {
      try {
        setSendingFeedback(true);
        sendFeedback.mutateAsync(feedback as SendFeedbackRequest).then(() => {
          setSendingFeedback(false);
          setFeedbackSent(true);
        });
      } catch (err) {
        setSendingFeedback(false);
        window.alert(err);
      }
    }
  };

  return (
    <CenterColumn>
      {feedbackSent && (
        <FeedbackSentModal
          onCancel={() => {
            setFeedbackSent(false);
          }}
          onConfirm={() => {
            setFeedback({
              recipientId: "",
              type: null,
              summary: "",
              detail: "",
            });
            setFeedbackSent(false);
          }}
        />
      )}
      <PageHeader
        text={
          feedbackRequest
            ? `Give ${feedbackRequest.requester.name} Feedback `
            : "Give Someone Feedback"
        }
        subtitle="Share and receive insights on strengths, areas of development or give praise."
        backUrl="/solutions/continuous-feedback"
        imageSrc={imgSrc}
      />
      <Flex flexDirection={["column", "column", "column", "row"]}>
        <Panel
          width={["100%", "100%", "100%", "30%"]}
          mb={[3, 3, 3, 0]}
          mr={[0, 0, 0, 3]}
        >
          <Box p={3}>
            {!orgUsersFetched && !requestFetched ? (
              <Loader />
            ) : (
              <>
                <FormUserSelect
                  label={
                    userId
                      ? "Who you are giving feedback to:"
                      : "Choose who you want to send feedback to:"
                  }
                  value={feedback.recipientId}
                  options={
                    users != null
                      ? users
                          .filter((x) => x.id !== me.id)
                          .map((x) => ({
                            id: x.id,
                            name: x.name,
                            email: x.email,
                            profileImage: x.profileImage,
                          }))
                      : []
                  }
                  error={errors.recipientId}
                  required
                  mb={2}
                  disabled={userId ? true : false}
                  onChange={(val) =>
                    setFeedback({ ...feedback, recipientId: val })
                  }
                />
                {feedbackRequest && (
                  <>
                    <Text fontSize={"13px"} fontWeight={500}>
                      What they would like feedback on:
                    </Text>
                    <ReasonBox>
                      <Text>{feedbackRequest.reason}</Text>
                    </ReasonBox>
                  </>
                )}

                <FormFeedbackTypeSelect
                  mt={2}
                  label="Choose what type of feedback to give:"
                  value={feedback.type}
                  required
                  onChange={(val) =>
                    setFeedback({
                      ...feedback,
                      type: val as ContinuousFeedbackType,
                    })
                  }
                  error={errors.type}
                />
              </>
            )}
          </Box>
        </Panel>
        <Panel width={["100%", "100%", "100%", "70%"]}>
          <PanelHeader p={3}>
            <Circle mr={2} color="primary">
              <FaCommentDots size={20} />
            </Circle>
            <Text fontSize={4} fontWeight={600} color="black" as="h2">
              Feedback
            </Text>
          </PanelHeader>
          <Box p={3}>
            <FormInput
              label="Summary"
              required
              value={feedback.summary}
              onChange={(val) =>
                setFeedback({ ...feedback, summary: val.target.value })
              }
              error={errors.summary}
              maxLength={64}
            />
            <FormInputWrap label="Detail" error={errors.detail}>
              <RichTextEditor
                height={300}
                value={feedback.detail}
                onChange={(val) => setFeedback({ ...feedback, detail: val })}
              />
            </FormInputWrap>
            <Button
              disabled={sendingFeedback}
              color="primary"
              onClick={() => submit()}
            >
              Send Feedback
            </Button>
          </Box>
        </Panel>
      </Flex>
    </CenterColumn>
  );
}
