import { UserGroup } from "@coaching-culture/types";
import {
  Button,
  CheckBox,
  Circle,
  Flex,
  FormTextArea,
  Loader,
  Modal,
  Panel,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { format, parseISO } from "date-fns";
import { useBulkCloseGroups, useGroups } from "queries/users";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPlus, FaQuestion, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

const levels = ["Invited", "Member", "Admin", "Owner"];

type ImportModalProps = {
  onClose: (reload: boolean) => void;
};

type ImportModalForm = {
  names: string;
};

const ImportModal = ({ onClose }: ImportModalProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { handleSubmit, errors, register } = useForm<ImportModalForm>();

  const onSubmit = async (values: ImportModalForm) => {
    setSubmitting(true);
    const specs = values.names
      .split("\n")
      .map((x) => x.trim())
      .filter((x) => x !== "")
      .map((name) => ({ name, description: "" }));
    await Promise.all(
      specs.map((x) => axios.post("/api/groups", x).then(({ data }) => data.id))
    );
    onClose(true);
  };

  return (
    <Modal
      width={600}
      p={3}
      onClose={() => onClose(false)}
      showCloseButton
      style={{ overflow: "hidden" }}
    >
      {submitting && <Loader overlay />}
      <Flex alignItems="center" flexDirection="column" mb={3}>
        <Circle color="primary" icon={FaQuestion} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Enter Group Names
        </Text>
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextArea
          height={150}
          name="names"
          label="Group names (One per line)"
          error={errors.names}
          ref={register({ required: true })}
          mb={1}
        />
        <Text fontSize={2} color="grey2" mb={2}>
          Note: You will not be made a member of these groups automatically
        </Text>
        <Flex alignItems="center" justifyContent="center" p={3}>
          <Button type="submit" color="primary">
            Create
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export const Groups = () => {
  const groupsQ = useGroups();
  const [importing, setImporting] = useState<boolean>(false);
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const closeGroups = useBulkCloseGroups();

  const loading = !groupsQ.isSuccess;

  const toggleSelected = useCallback((id: string, v: boolean) => {
    setSelected((old) => ({
      ...old,
      [id]: v,
    }));
  }, []);

  const cols = useMemo(
    (): SortableTableColumn<UserGroup>[] => [
      {
        name: "selected",
        label: "",
        excludeFromExport: true,
        props: {
          style: {
            width: "1px",
          },
        },
        format: (x) => (
          <CheckBox
            mb={0}
            value={selected[x.id] || false}
            onChange={(v) => toggleSelected(x.id, v)}
          />
        ),
      },
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Link to={`/success/people/groups/${x.id}`}>
            <strong>{x.name}</strong>
          </Link>
        ),
      },
      {
        name: "createdOn",
        label: "Created On",
        format: (x) => format(parseISO(x.createdOn as any), "dd/MM/yyyy"),
        props: {
          textAlign: "right",
        },
        headingProps: {
          textAlign: "right",
        },
      },
      {
        name: "membershipLevel",
        label: "Your Role",
        format: (x) =>
          x.membershipLevel == null
            ? "Not a member"
            : levels[x.membershipLevel],
        props: {
          textAlign: "right",
          style: {
            width: "20%",
          },
        },
        headingProps: {
          textAlign: "right",
        },
      },
    ],
    [toggleSelected, selected]
  );

  const onImport = (reload: boolean) => {
    setImporting(false);
    if (reload) {
      groupsQ.refetch();
    }
  };

  const closeSelected = async () => {
    const ids = Object.entries(selected)
      .filter((x) => x[1])
      .map((x) => x[0]);

    if (
      window.confirm(
        `Are you sure you want to close ${ids.length} groups?\n\nAll content access associated with those groups will be removed.`
      )
    ) {
      await closeGroups.mutateAsync(ids);
    }
  };

  return (
    <>
      {importing && <ImportModal onClose={onImport} />}
      <CenterColumn>
        <PageHeader
          text="Groups"
          subtitle="Reviews the groups within your organisation"
        />
        <Flex justifyContent="flex-end" mb={2}>
          <Button
            color="danger"
            icon={FaTrash}
            onClick={closeSelected}
            disabled={!Object.values(selected).some((x) => x)}
            mr={2}
          >
            Close Selected Groups
          </Button>
          <Button
            color="primary"
            icon={FaPlus}
            onClick={() => setImporting(true)}
          >
            Create Groups
          </Button>
        </Flex>
        <Panel style={{ overflow: "hidden" }}>
          {loading ? (
            <Loader />
          ) : (
            <SortableTable<UserGroup> columns={cols} data={groupsQ.data} />
          )}
        </Panel>
      </CenterColumn>
    </>
  );
};
