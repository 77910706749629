import { MeasureSpec } from "@coaching-culture/types";
import { IconToast, Panel, useToast } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useCreateMeasure } from "queries/measures";
import { FaSave } from "react-icons/fa";
import { useHistory } from "react-router";
import { MeasureForm } from "./MeasureForm";

const defaultSpec = {
  name: "",
  options: [],
};

export function CreateMeasure() {
  const createMeasure = useCreateMeasure();
  const history = useHistory();
  const pushToast = useToast();

  const onSubmit = async (values: MeasureSpec) => {
    await createMeasure.mutateAsync(values);
    pushToast({
      content: <IconToast icon={FaSave} text="Saved" iconColor="primary" />,
    });

    history.push("/success/settings/measures");
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Create Measure"
        subtitle="Create a new Measure for your organisation"
        backUrl="/success/settings/measures"
      />
      <Panel p={[2, 3]}>
        <MeasureForm
          value={defaultSpec}
          onSave={onSubmit}
          disabled={createMeasure.isLoading}
        />
      </Panel>
    </CenterColumn>
  );
}
