import React from "react";
import styled from "styled-components";
import range from "lodash/range";
import uniq from "lodash/uniq";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 6px;
`;

const PageButton = styled.button<{ active: boolean }>`
  display: flex;
  padding: 6px 6px;
  min-width: 30px;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.active ? props.theme.colors.primary : "white"};
  color: ${(props) => (props.active ? "white" : props.theme.colors.body)};
  border: 1px solid #ccc;
  margin: 3px;
  margin-bottom: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.1s ease;
  border-color: ${(props) =>
    props.active ? props.theme.colors.primary : "#ccc"};

  &:hover {
    color: ${(props) => (props.active ? "white" : props.theme.colors.primary)};
  }
`;

const Break = styled.span`
  padding: 6px 12px;
`;

const getPages = (count: number, page: number) => {
  const ALLOWED = 10;
  const MIDDLEMARGIN = 2;

  if (count < ALLOWED) {
    return range(1, count + 1);
  } else {
    const left = range(1, ALLOWED / 2);
    const right = range(count - ALLOWED / 2, count + 1);
    let middle = range(page - MIDDLEMARGIN, page + MIDDLEMARGIN + 1);

    return uniq(left.concat(middle).concat(right))
      .filter((x) => x > 0)
      .filter((x) => x <= count)
      .sort((a, b) => a - b);
  }
};

export type PaginationProps = {
  pageCount: number;
  page: number;
  onChange: (newPage: number) => void;
};

export const Pagination = ({ pageCount, page, onChange }: PaginationProps) => {
  if (pageCount === 0) {
    return null;
  }

  const pages = getPages(pageCount, page);

  return (
    <Container>
      {pages.map((n, i) => {
        const last = pages[i - 1] || n;
        return (
          <React.Fragment key={i}>
            {Math.abs(n - last) > 1 ? <Break>...</Break> : null}
            <PageButton key={n} active={n === page} onClick={() => onChange(n)}>
              {n}
            </PageButton>
          </React.Fragment>
        );
      })}
    </Container>
  );
};
