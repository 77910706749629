import { UserFilter } from "@coaching-culture/types";
import {
  Box,
  Flex,
  IconWrap,
  Loader,
  Panel,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import { NoData } from "components/NoData";
import { DualOptionSelect } from "pages/Solutions/DualOptionSelect";
import { MeasuresTable } from "pages/Solutions/MeasuresTable";
import { useMeasures, useAllUserMeasures } from "queries/measures";
import { useEffect, useState } from "react";
import { FaChartLine } from "react-icons/fa";

type MeasuresInsightsComparisonProps = {
  filter: UserFilter;
};

export const MeasuresInsightsComparison = ({
  filter,
}: MeasuresInsightsComparisonProps) => {
  const [selected, setSelected] = useState<[string, string]>(["", ""]);
  const measures = useMeasures();
  const userMeasures = useAllUserMeasures(filter);

  useEffect(() => {
    if (measures.data) {
      setSelected([measures.data?.[0].id ?? "", measures.data?.[1].id ?? ""]);
    }
  }, [measures.data]);

  const loading = !measures.isSuccess || !userMeasures.isSuccess;

  const noData = measures.data?.length < 2;

  return (
    <Panel mb={3}>
      <PanelHeader alignItems={"center"}>
        <IconWrap icon={FaChartLine} color="primary" size="2em" />
        <Text fontWeight={600} ml={3}>
          Measures Insights
        </Text>
      </PanelHeader>
      {loading ? (
        <Loader />
      ) : noData ? (
        <NoData />
      ) : (
        <Flex p={3} alignItems={"center"} flexDirection={["column", "row"]}>
          <Box p={4} ml={2} mr={5}>
            <DualOptionSelect
              options={measures.data.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              value={selected}
              onChange={setSelected}
            />
          </Box>
          <Box overflowX={"auto"} flex="1">
            <MeasuresTable
              comparison={selected}
              measures={measures.data}
              userMeasures={userMeasures.data}
            />
          </Box>
        </Flex>
      )}
    </Panel>
  );
};
