import React from "react";
import styled from "styled-components";

const Container = styled.svg`
  width: 100%;
  height: 100%;
`;

const colors = ["black"];

const Line = styled.line`
  stroke: ${(props) => props.color};
  stroke-width: 0.6;
  stroke-dasharray: 1;
  stroke: ${(props) => props.theme.colors.grey2};
`;

const AxisLine = styled.line`
  stroke: ${(props) => props.theme.colors.grey2};
  stroke-width: 0.5;
`;

const AxisValue = styled.text`
  font-size: 6px;
  text-transform: uppercase;
  font-weight: 600;
  fill: ${(props) => props.theme.colors[props.color || "grey2"]};
`;

const AxisLabel = styled.text`
  font-size: 6px;
  text-transform: uppercase;
  font-weight: 600;
  fill: ${(props) => props.theme.colors.grey2};
`;

const Legend = styled.text<{ blockColor: number }>`
  font-size: 8px;
  font-weight: 600;
  fill: ${(props) => colors[props.blockColor]};
`;

const Point = styled.circle<{ blockColor: number }>`
  stroke: ${(props) => colors[props.blockColor]};
  stroke-width: 2;
  fill: ${(props) => props.theme.colors.background};
`;

const BoundingRect = styled.rect`
  & ~ line {
    stroke: ${(props) => props.theme.colors.grey3};
    pointer-events: none;
  }

  &:hover ~ line {
    stroke: black;
  }
`;

export type InterpFunc = (val: number) => number;

export type GraphLegendItem = {
  text: string;
  color: number;
};

export type GraphAxesProps = {
  xAxis: string[];
  yAxis: string[];
  xAxisLabel: string;
  yAxisLabel: string;
  children: (x: InterpFunc, y: InterpFunc) => React.ReactNode;
  legend: GraphLegendItem[];
  vertLines?: boolean;
  horizLines?: boolean;
  centralLabels?: boolean;
  defs?: any;
  onStreamRemove?: (index: number) => void;
  width: number;
  maxXLabels?: number;
};

const XLabelComponent = styled(AxisValue)``;

export function GraphAxes({
  xAxis,
  yAxis,
  xAxisLabel,
  yAxisLabel,
  children,
  vertLines = false,
  horizLines = false,
  centralLabels = false,
  defs,
  width,
  maxXLabels = 10,
}: GraphAxesProps) {
  const w = width;
  const h = 300;
  const graphWidth = 310;
  const graphHeight = 210;
  const graphX = 50;
  const graphY = 260;

  const interpToY = (x: number) => graphY - x * graphHeight;
  const interpToX = (x: number) => graphX + x * graphWidth;

  return (
    <Container viewBox={`0 30 ${w} ${h}`}>
      <defs>{defs}</defs>
      <g>
        <AxisLine
          x1={graphX}
          x2={graphWidth + 50}
          y1={graphY + 10}
          y2={graphY + 10}
        />
        <AxisLine
          x1={graphX - 10}
          y1={graphY}
          x2={graphX - 10}
          y2={graphY - graphHeight}
        />
        {yAxis.map((x, i, self) => {
          const y = interpToY(i / (self.length - 1));

          return (
            <g key={i}>
              {horizLines && (
                <Line x1={graphX} y1={y} x2={graphWidth + 50} y2={y} />
              )}
              <AxisLine x1={graphX - 10} y1={y} x2={graphX - 15} y2={y} />
              <AxisValue
                textAnchor="end"
                alignmentBaseline="middle"
                x={graphX - 18}
                y={y}
              >
                {x}
              </AxisValue>
            </g>
          );
        })}
      </g>
      <g>
        {xAxis.map((val, i, self) => {
          if (i % Math.ceil(xAxis.length / maxXLabels) !== 0) {
            return "";
          }

          let cx = 0;
          let lineX = 0;
          const gap = graphWidth / self.length;
          const cy = graphY + 25;

          if (centralLabels) {
            cx = graphX + (gap * i + gap / 2);
            lineX = graphX + gap * i;
          } else {
            cx = lineX = (i / (self.length - 1)) * graphWidth + graphX;
          }

          return (
            <g key={i}>
              {vertLines && (
                <Line
                  x1={lineX}
                  y1={graphY}
                  x2={lineX}
                  y2={graphY - graphHeight}
                />
              )}
              <AxisLine
                x1={lineX}
                y1={graphY + 10}
                x2={lineX}
                y2={graphY + 15}
              />
              {centralLabels && i === self.length - 1 && (
                <AxisLine
                  x1={lineX + gap}
                  y1={graphY + 10}
                  x2={lineX + gap}
                  y2={graphY + 15}
                />
              )}
              <g transform={`translate(${cx}, ${cy + 8})`}>
                <XLabelComponent
                  x={0}
                  y={0}
                  textAnchor="middle"
                  width={gap}
                  style={{ transform: "rotate(-20deg)" }}
                >
                  {val}
                </XLabelComponent>
              </g>
            </g>
          );
        })}
      </g>
      <g>
        <AxisLabel
          x="10"
          y="142"
          textAnchor="middle"
          alignmentBaseline="middle"
          transform={`rotate(270, 15, 150)`}
        >
          {yAxisLabel}
        </AxisLabel>
        <AxisLabel
          x={graphX + graphWidth / 2}
          y={graphY + 55}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {xAxisLabel}
        </AxisLabel>
      </g>
      {children && children(interpToX, interpToY)};
    </Container>
  );
}
