import {
  ContinuousFeedbackDto,
  DirectReportDto,
  ProductVisibility,
  UserDisplay,
} from "@coaching-culture/types";
import { Circle, DotMenu, Flex, IconButton, Text } from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime, isFuture, isPast, isToday } from "date-fns";
import { useProductVisibility } from "hooks/useProductVisibility";
import { maxBy, minBy } from "lodash";
import { useState } from "react";
import {
  FaBook,
  FaChevronDown,
  FaChevronRight,
  FaCommentDots,
  FaExclamation,
  FaFileInvoice,
  FaTrash,
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { isOverdue } from "utils/content";
import { ConversationCommentsModal } from "./ConversationCommentsModal";

const Fill = styled.hr`
  flex: 1;
  margin: 6px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey3};
  border: 0;
`;

const Line = styled.span`
  position: absolute;
  left: 18px;
  width: 2px;
  display: block;
  background-color: ${(props) => props.theme.colors.grey4};
`;

const ObjLine = styled(Line)`
  bottom: 50%;
  top: 10px;

  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 23px;
    left: 0px;
    background-color: ${(props) => props.theme.colors.grey4};
    position: absolute;
    top: 50%;
  }
`;

const ActionLine = styled(ObjLine)`
  &:after {
    top: 100%;
  }
`;

const DownLine = styled(Line)`
  bottom: -18px;
  top: calc(50% + 14px);
  height: 30px;
`;

export type ManagerItemProps = {
  manager: UserDisplay;
  confirmed: boolean;
};

export const ManagerItem = ({ manager, confirmed }: ManagerItemProps) => {
  return (
    <Flex pl={2} pr={2} mb={2}>
      <Flex alignItems="center" flex={1}>
        <UserFlag user={manager} />
        {!confirmed && (
          <Text
            textAlign="center"
            color="grey2"
            ml={2}
            fontWeight={500}
            fontSize={[2, 3]}
            style={{ flex: 1 }}
          >
            (Not Accepted Yet)
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const NoData = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.grey3};
`;

export type TeamMemberProps = {
  member: DirectReportDto;
  onRemove: () => void;
  onShare: () => void;
  child: boolean;
};

export const TeamMember = ({
  member,
  onRemove,
  onShare,
  child,
}: TeamMemberProps) => {
  const history = useHistory();
  const activeGoals = member.goals.filter((x) => x.completedOn == null);
  const hasPerf =
    useProductVisibility("performance") === ProductVisibility.Enabled;
  const overdue = member.goals.filter(isOverdue);
  const isConfirmed =
    member.relationship.toAccepted != null &&
    member.relationship.fromAccepted != null;
  const nextConvo = minBy(
    member.conversations
      .filter((x) => x.completedOn == null && x.cancelledOn == null)
      .filter((x) => {
        const d = fromUnixTime(x.scheduledDate);
        return isFuture(d) || isToday(d);
      }),
    (x) => x.scheduledDate,
  );

  const lastConvo = maxBy(
    member.conversations
      .filter((x) => x.cancelledOn == null)
      .filter((x) => {
        const d = fromUnixTime(x.scheduledDate);
        return isPast(d) || isToday(d);
      }),
    (x) => x.scheduledDate,
  );

  const goalTerms = useGoalTerminology();
  const [open, setOpen] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState("");
  const terms = useGoalTerminology();
  const lessons = member.content.filter((x) => x.type === "lesson");
  const modules = member.content.filter((x) => x.type === "module");
  const completeLessons = lessons.filter((x) =>
    x.attempts.some((y) => y.completedOn !== null),
  );
  const completeModules = modules.filter((x) =>
    x.attempts.some((y) => y.completedOn !== null),
  );

  const dotMenuItems = [
    {
      label: "New Conversation",
      icon: FaCommentDots,
      cmd: "new-convo",
    },
    {
      label: "Assign Content",
      icon: FaBook,
      cmd: "assign",
    },
    {
      label: "View Private Notes",
      icon: FaFileInvoice,
      cmd: "notes",
    },
    {
      label: "Remove Relationship",
      icon: FaTrash,
      color: "danger",
      cmd: "del",
    },
  ];

  const unconfirmdDotMenuItems = [
    {
      label: "Remove Relationship",
      icon: FaTrash,
      color: "danger",
      cmd: "del",
    },
  ];

  const getLatestFeedback = (data: ContinuousFeedbackDto[]): string | null => {
    if (data != null && data.length > 0) {
      const sortedResults = data.sort((a, b) => a.sentOn - b.sentOn);

      return format(fromUnixTime(sortedResults[0].sentOn), "do MMM yy");
    }
    return null;
  };

  const onItemClick = (_: number, cmd: string) => {
    if (cmd === "del") {
      onRemove();
    } else if (cmd === "assign") {
      onShare();
    } else if (cmd === "notes") {
      setShowCommentsModal(member.id);
    } else if (cmd === "new-convo") {
      history.push(`/solutions/performance/conversations/create`, {
        userId: member.id,
      });
    }
  };

  return (
    <>
      <tr className={child ? "child" : ""}>
        <td>
          {member.directReports !== null &&
            member.directReports?.length > 0 && (
              <>
                <IconButton
                  icon={open ? FaChevronDown : FaChevronRight}
                  color="body"
                  onClick={() => setOpen((old) => !old)}
                />
                {open && <DownLine />}
              </>
            )}
          {child && <ActionLine />}
        </td>
        <td>
          <UserFlag user={member} to={`/people/${member.id}`} />
        </td>
        {!isConfirmed ? (
          <td colSpan={hasPerf ? 7 : 2}>
            <Fill />
            <Text color="grey2" fontWeight={500} textAlign="center">
              Unconfirmed Relationship
            </Text>
            <Fill />
          </td>
        ) : (
          <>
            {hasPerf && (
              <td>
                <Flex>
                  <Link
                    to={`/solutions/people/goals/${member.id}`}
                    color={activeGoals.length === 0 ? "danger" : "body"}
                  >
                    {activeGoals.length} Active {terms.goal.asPluralTitle()}
                  </Link>
                  {overdue.length > 0 && (
                    <Text
                      title={`${
                        overdue.length
                      } Overdue ${goalTerms.goal.asPluralTitle()}`}
                      color={"danger"}
                    >
                      <FaExclamation />
                    </Text>
                  )}
                </Flex>
              </td>
            )}
            <td>
              <>
                <Link to={`/people/${member.id}`}>
                  {completeLessons.length} / {lessons.length}
                </Link>
              </>
            </td>
            <td>
              <>
                <Link to={`/people/${member.id}`}>
                  {completeModules.length} / {modules.length}
                </Link>
              </>
            </td>
            {hasPerf && (
              <>
                <td>
                  {lastConvo == null ? (
                    <NoData />
                  ) : (
                    <Flex alignItems={"center"}>
                      <Circle
                        icon={FaCommentDots}
                        color="primary"
                        mr={2}
                        size="small"
                      />
                      <Text
                        fontWeight={500}
                        style={{ textTransform: "uppercase" }}
                      >
                        {!child ? (
                          <Link
                            to={`/solutions/performance/conversations/${lastConvo.id}/${lastConvo.index}/play`}
                          >
                            {format(
                              fromUnixTime(lastConvo.scheduledDate),
                              "do MMM yy",
                            )}
                          </Link>
                        ) : (
                          <Text>
                            {format(
                              fromUnixTime(lastConvo.scheduledDate),
                              "do MMM yy",
                            )}
                          </Text>
                        )}
                      </Text>
                    </Flex>
                  )}
                </td>
                <td>
                  {nextConvo == null ? (
                    <NoData />
                  ) : (
                    <Flex alignItems={"center"}>
                      <Circle
                        icon={FaCommentDots}
                        color="primary"
                        mr={2}
                        size="small"
                      />
                      <Text
                        fontWeight={500}
                        style={{ textTransform: "uppercase" }}
                      >
                        {!child ? (
                          <Link
                            to={`/solutions/performance/conversations/${nextConvo.id}/${nextConvo.index}/play`}
                          >
                            {format(
                              fromUnixTime(nextConvo.scheduledDate),
                              "do MMM yy",
                            )}
                          </Link>
                        ) : (
                          <Text>
                            {format(
                              fromUnixTime(nextConvo.scheduledDate),
                              "do MMM yy",
                            )}
                          </Text>
                        )}
                      </Text>
                    </Flex>
                  )}
                </td>
                <td>
                  {member.sentFeedback.length === 0 ? (
                    <NoData />
                  ) : (
                    <>
                      <Text
                        fontWeight={500}
                        style={{ textTransform: "uppercase" }}
                      >
                        <Text>{getLatestFeedback(member.sentFeedback)}</Text>
                      </Text>
                    </>
                  )}
                </td>
                <td>
                  {member.receivedFeedback.length === 0 ? (
                    <NoData />
                  ) : (
                    <>
                      <Text
                        fontWeight={500}
                        style={{ textTransform: "uppercase" }}
                      >
                        <Text>
                          {getLatestFeedback(member.receivedFeedback)}
                        </Text>
                      </Text>
                    </>
                  )}
                </td>
              </>
            )}
          </>
        )}
        <td>
          {!child && (
            <DotMenu
              items={
                hasPerf && isConfirmed ? dotMenuItems : unconfirmdDotMenuItems
              }
              onItemClick={onItemClick}
            />
          )}
        </td>
      </tr>
      {open &&
        member.directReports.map((x) => (
          <TeamMember
            child={true}
            member={x}
            onRemove={function (): void {
              throw new Error("Function not implemented.");
            }}
            onShare={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        ))}
      {showCommentsModal !== "" && (
        <ConversationCommentsModal
          userId={showCommentsModal}
          onCancel={() => setShowCommentsModal("")}
        />
      )}
    </>
  );
};
