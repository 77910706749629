import { UserFilter } from "@coaching-culture/types";
import { Button, Flex, Select } from "@coaching-culture/ui";
import { FilterModal } from "components/FilterPanel";
import { useCallback, useState } from "react";

export type ReportProps = {
  options: { value: string; label: string }[];
  selected: string;
  onChange: (val: string) => void;
  filter: UserFilter;
  onFilterChange: (newFilter: UserFilter) => void;
  showFilterButton?: boolean;
};

export type ReportSelectorProps = ReportProps & {
  onExport: () => void;
};

export const ReportSelector = ({
  options,
  selected,
  onExport,
  onChange,
  filter,
  onFilterChange,
  showFilterButton,
}: ReportSelectorProps) => {
  const [showFilters, setShowFilters] = useState(false);

  const onCancel = useCallback(
    (result: UserFilter) => {
      setShowFilters(false);
      if (result) {
        onFilterChange(result);
      }
    },
    [onFilterChange],
  );

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        filter={filter}
        onClose={(result) => {
          onCancel(result);
        }}
      />
      <Flex mb={2} flexDirection={["column", "row"]}>
        <Select
          options={options}
          value={selected}
          mb={[2, 0]}
          onChange={(ev) => onChange(ev.target.value)}
          style={{ flex: 1 }}
        />
        <Button ml={[0, 2]} onClick={onExport} color="primary" minWidth={160}>
          Export CSV
        </Button>
        {showFilterButton && (
          <Button
            ml={[0, 2]}
            color="primary"
            onClick={() => setShowFilters(true)}
          >
            Filters ({filter.groups.length + filter.userFields.length})
          </Button>
        )}
      </Flex>
    </>
  );
};
