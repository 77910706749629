import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  Link,
  Route,
  Redirect,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import { FaLock } from "react-icons/fa";
import LoginFrame from "../../components/LoginFrame";
import { useUser } from "../../auth";
import {
  Flex,
  Button,
  Text,
  FormInput,
  Input,
  ControlLabel,
  Box,
} from "@coaching-culture/ui";
import Axios from "axios";
import { useForm } from "react-hook-form";
import axios from "axios";

const OrLine = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:before,
  &:after {
    flex: 1;
    content: "";
    height: 1px;
    background: ${(props) => props.theme.colors.grey2};
    display: block;
    margin: 0 20px;
  }
`;

const ForgotPassword = styled(Link)`
  font-size: 13px;
`;

const LoginSection = ({ rootPath }: { rootPath: string }) => {
  const [user, setUser] = useUser();
  const location = useLocation<any>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [sso, setSso] = useState<string | null>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { from } = location.state || { from: { pathname: rootPath } };

  if (user != null) {
    return <Redirect to={from.pathname} />;
  }

  const doLogin = (user) => {
    setUser(user.token);
  };

  const getLogin = (ev) => {
    ev.preventDefault();
    setError("");
    if (step === 0) {
      if (email === "") {
        setError("Email is required");
        return;
      }
      setLoading(true);
      setSso(null);
      axios
        .get(`/api/sso/user?email=${email}`)
        .then(({ data }) => {
          if (data.saml === "require") {
            window.location = data.url;
          } else {
            setSso(data.url + `?redirect=${from.pathname}`);
            setLoading(false);
            setStep(1);
            setTimeout(() => {
              passwordRef.current?.focus();
            }, 200);
          }
        })
        .catch(() => {
          setLoading(false);
          setStep(1);
          setTimeout(() => {
            passwordRef.current?.focus();
          }, 200);
        });
    } else {
      if (password === "") {
        setError("Password is required.");
        return;
      }
      Axios.post("/api/auth/token", { email, password })
        .then((x) => {
          doLogin(x.data);
        })
        .catch((err) => {
          if (Axios.isAxiosError(err)) {
            if (err.response.status === 401) {
              setError("Invalid Password.");
              return;
            } else if (err.response.status === 403) {
              setError("Account Disabled");
              return;
            }
          }
          setError("Unknown Error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form onSubmit={getLogin} style={{ width: "100%" }}>
        {step === 0 ? (
          <FormInput
            label="Email Address"
            type="email"
            name="email"
            mb={3}
            disabled={loading}
            value={email}
            error={null}
            placeholder="Enter your email address..."
            onChange={(ev) => setEmail(ev.target.value)}
          />
        ) : (
          <>
            {sso && (
              <>
                <Flex justifyContent="center" mb={4}>
                  <Button href={sso}>Login with SSO</Button>
                </Flex>
                <OrLine>Or</OrLine>
              </>
            )}
            <Flex flexDirection="column" mb={3}>
              <Flex justifyContent="space-between">
                <ControlLabel>Password</ControlLabel>
                <ForgotPassword to={rootPath + "forgot-password"}>
                  <FaLock size="0.8em" style={{ marginRight: 3 }} /> Forgot?
                </ForgotPassword>
              </Flex>
              <Input
                type="password"
                name="password"
                ref={passwordRef}
                placeholder="Enter your password"
                disabled={loading}
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
              />
            </Flex>
          </>
        )}
        <Flex mb={4} alignItems="center">
          <Button type="submit" disabled={loading} color="primary" mr={3}>
            {loading ? "Please Wait" : step === 0 ? "Continue" : "Sign In"}
          </Button>
          <Text color="danger">{error}&nbsp;</Text>
        </Flex>
      </form>
    </>
  );
};

const ChangeTempPasswordSection = () => {
  const [user, , setUser] = useUser();
  const { register, errors, watch, handleSubmit } = useForm();

  const history = useHistory();
  const location = useLocation<any>();
  const { from } = location.state || { from: { pathname: "/" } };

  const onSubmit = (values) => {
    const spec = {
      password: values.password,
    };

    Axios.post("/api/auth/reset-password", spec)
      .then(() => {
        setUser({
          ...user,
          forcePasswordReset: false,
        });
        history.push(from.pathname);
      })
      .catch(() => {
        window.alert("There was an error.");
      });
  };

  if (user == null || !user.forcePasswordReset) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Text
        as="h1"
        color="primary"
        fontSize={5}
        mb={2}
        fontWeight={700}
        textAlign="center"
      >
        Change your temporary password
      </Text>
      <Text as="p" mb={3} fontSize={2} textAlign="center">
        Enter your new password.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Password"
          type="password"
          name="password"
          error={errors.password}
          ref={register({
            required: true,
            minLength: 8,
          })}
        />
        <FormInput
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          error={errors.confirmPassword}
          mb={4}
          ref={register({
            required: true,
            validate: (value) =>
              value === watch("password") ? null : "Must match password",
          })}
        />
        <Flex alignItems="center" mb={3} flexDirection="column">
          <Button type="submit" mb={2}>
            Reset Password
          </Button>
        </Flex>
      </form>
    </>
  );
};

const ResetPasswordSection = () => {
  const { register, errors, watch, handleSubmit } = useForm();

  const [done, setDone] = useState(false);
  const location = useLocation();

  const q = new URLSearchParams(location.search);

  const token = q.get("token");

  const onSubmit = (values) => {
    const spec = {
      token,
      password: values.password,
    };

    Axios.post("/api/auth/reset-password", spec)
      .then(() => {
        setDone(true);
      })
      .catch(() => {
        window.alert("Invalid or expired reset token");
      });
  };

  if (token == null || token === "") {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Text as="h1" color="primary" fontSize={5} mb={2} fontWeight={700}>
        Reset your password
      </Text>
      {done ? (
        <>
          <Text textAlign="center" mb={2}>
            Ok, your password has been reset. Please return to login and login
            with your new password.
          </Text>
          <Link to="/login">Back to login</Link>
        </>
      ) : (
        <>
          <Text as="p" mb={3} fontSize={2} textAlign="center">
            Enter your new password.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              label="Password"
              type="password"
              name="password"
              error={errors.password}
              ref={register({
                required: true,
                minLength: 8,
              })}
            />
            <FormInput
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              error={errors.confirmPassword}
              mb={4}
              ref={register({
                required: true,
                validate: (value) =>
                  value === watch("password") ? null : "Must match password",
              })}
            />
            <Flex alignItems="center" mb={3} flexDirection="column">
              <Button type="submit" mb={2}>
                Reset Password
              </Button>
              <Link to="/login">Back to login</Link>
            </Flex>
          </form>
        </>
      )}
    </>
  );
};

const ForgotPasswordSection = ({ rootPath }: { rootPath: string }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);

  const sendReminder = () => {
    setError("");
    if (email === "") {
      setError("Email is required");
      return;
    }

    Axios.post("/api/auth/forgot-password", { email })
      .then(() => {
        setDone(true);
      })
      .catch(() => {
        setError("Email not found");
      });
  };

  return (
    <>
      <Text as="h1" color="primary" fontSize={5} mb={2} fontWeight={700}>
        Forgot your password?
      </Text>
      {done ? (
        <Box mb={3}>
          <Text mb={2}>
            If you have an account at Coaching Culture, we've sent you an email
            with instructions on resetting your password
          </Text>
          <Link to="/login">Back to login</Link>
        </Box>
      ) : (
        <>
          <Text as="p" mb={3} fontSize={2}>
            Enter your email address and we'll send you a link to reset your
            password.
          </Text>
          <FormInput
            label="Email Address"
            type="email"
            name="email"
            value={email}
            mb={4}
            error={null}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <Flex alignItems="center" mb={3} flexDirection="row">
            <Button onClick={sendReminder} mb={0} mr={2} color="primary">
              Send Reset Email
            </Button>
            <Link to={rootPath + "login"}>Back to login</Link>
          </Flex>
          <Text color="danger" mb={3}>
            {error}&nbsp;
          </Text>
        </>
      )}
    </>
  );
};

const LoginPage = () => {
  const params = useParams<{ section?: string }>();

  const rootPath = params.section == null ? "/" : `/${params.section}/`;

  const getPathArray = (suffix: string): string[] =>
    ["/", "/:section/"].map((x) => x + suffix);

  return (
    <LoginFrame>
      <Route path={getPathArray("login")} exact>
        <LoginSection rootPath={rootPath} />
      </Route>
      <Route path={getPathArray("forgot-password")} exact>
        <ForgotPasswordSection rootPath={rootPath} />
      </Route>
      <Route path={getPathArray("reset-password")} exact>
        <ResetPasswordSection />
      </Route>
      <Route path={getPathArray("change-password")} exact>
        <ChangeTempPasswordSection />
      </Route>
    </LoginFrame>
  );
};

export default LoginPage;
