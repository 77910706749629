import {
  Flex,
  LabelInput,
  Text,
  Panel,
  Button,
  FormInputWrap,
  Label,
} from "@coaching-culture/ui";
import { useEffect, useMemo, useState } from "react";
import { FaAddressBook, FaCogs, FaUser, FaUsers } from "react-icons/fa";
import {
  AddressBookItem,
  discriminate,
  UserFieldType,
  UserGroup,
  UserSummary,
} from "@coaching-culture/types";
import { AddressBookModal } from "./AddressBookModal";
import axios from "axios";
import styled from "styled-components";
import { uniqBy } from "lodash";
import { useUserFields } from "queries/users";

export type AddressBookInputProps = {
  value: AddressBookItem[];
  onChange: (items: AddressBookItem[]) => void;
};

const ListPanel = styled(Panel)`
  position: absolute;
  width: 80%;
  left: 0;
  top: calc(100% + 5px);
  z-index: 2000;
  box-shadow: ${(props) => props.theme.shadows[2]};
`;

const ListItem = ({
  item,
  active,
  ...rest
}: {
  item: AddressBookItem;
  active: boolean;
} & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <Flex
      p={1}
      style={{ backgroundColor: active ? "#eee" : "white", cursor: "pointer" }}
      alignItems="center"
      borderRadius={4}
      {...rest}
    >
      <Label
        color={
          item.type === "group"
            ? "blue"
            : item.type === "userfield"
            ? "orange"
            : "green"
        }
        mr={2}
        size="small"
      >
        {item.type === "group"
          ? "GROUP"
          : item.type === "user"
          ? "USER"
          : "USERFIELD"}
      </Label>
      <Text mr={2} fontWeight={600}>
        {item.name}
      </Text>
      {item.type === "user" && (
        <Text fontWeight={600} color="grey2">
          {item.email}
        </Text>
      )}
    </Flex>
  );
};

export function AddressBookInput({ value, onChange }: AddressBookInputProps) {
  const [search, setSearch] = useState("");
  const userFields = useUserFields();

  const userFieldOptions = (userFields.data ?? [])
    .filter(discriminate("type", UserFieldType.Select))
    .flatMap((x) =>
      x.options.map((o) => ({
        id: o.id,
        name: o.name,
        fieldName: x.name,
      }))
    );
  const [users, setUsers] = useState<UserSummary[]>([]);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [showingAddressBook, setShowingAddressBook] = useState(false);

  useEffect(() => {
    axios.get("/api/users").then(({ data }) => setUsers(data));
    axios.get("/api/groups").then(({ data }) => setGroups(data));
  }, []);

  const onItemRemove = (idx: number) =>
    onChange(value.filter((x, i) => i !== idx));

  const openAddressBook = () => {
    setShowingAddressBook(true);
  };

  const makeUnique = (items: AddressBookItem[]) =>
    uniqBy(items, (x) => `${x.type}-${x.id}`);

  const onSelect = (items: AddressBookItem[]) => {
    setShowingAddressBook(false);
    onChange(makeUnique(value.concat(items)));
  };

  const available = useMemo(() => {
    if (search.length < 3) {
      return [];
    }

    const lowerSearch = search.toLowerCase();
    const availableGroups = groups
      .filter((x) => x.name.toLowerCase().includes(lowerSearch))
      .map<AddressBookItem>((x) => ({
        type: "group",
        name: x.name,
        id: x.id,
      }));
    const availableUsers = users
      .filter(
        (x) =>
          x.name.toLowerCase().includes(lowerSearch) ||
          x.email.includes(lowerSearch)
      )
      .map<AddressBookItem>((x) => ({
        type: "user",
        name: x.name,
        email: x.email,
        id: x.id,
      }));
    const availableUserFields = userFieldOptions
      .filter((x) => x.name.toLowerCase().includes(lowerSearch))
      .map<AddressBookItem>((x) => ({
        id: x.id,
        name: `${x.fieldName} = ${x.name}`,
        type: "userfield",
      }));

    return [...availableUserFields, ...availableGroups, ...availableUsers];
  }, [search, users, groups, userFieldOptions]);

  useEffect(() => {
    if (activeIndex >= available.length) {
      setActiveIndex(0);
    }
  }, [available, activeIndex]);

  const select = (i: number) => {
    onChange(makeUnique([...value, available[i]]));
    setSearch("");
  };

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if ([" ", "Enter"].includes(ev.key)) {
      if (available[activeIndex] != null) {
        select(activeIndex);
        ev.preventDefault();
      }
    } else if (
      ((!ev.shiftKey && ev.key === "Tab") || ev.key === "ArrowDown") &&
      available.length > 0
    ) {
      setActiveIndex((old) => (old + 1) % available.length);
      ev.preventDefault();
    } else if (
      ((ev.shiftKey && ev.key === "Tab") || ev.key === "ArrowUp") &&
      available.length > 0
    ) {
      setActiveIndex((old) => (old === 0 ? available.length - 1 : old - 1));
      ev.preventDefault();
    } else if (
      ev.key === "Backspace" &&
      search.length === 0 &&
      value.length > 0
    ) {
      onChange(value.slice(0, -1));
    }
  };

  return (
    <>
      <AddressBookModal
        onSelect={onSelect}
        onCancel={() => setShowingAddressBook(false)}
        isOpen={showingAddressBook}
      />

      <div style={{ position: "relative" }}>
        <FormInputWrap label="To">
          <Flex alignItems="stretch">
            <LabelInput
              onKeyDown={onKeyDown}
              items={value.map((x) => ({
                label: x.email ?? x.name,
                color:
                  x.type === "group"
                    ? "blue"
                    : x.type === "userfield"
                    ? "orange"
                    : "green",
                icon:
                  x.type === "group"
                    ? FaUsers
                    : x.type === "userfield"
                    ? FaCogs
                    : FaUser,
              }))}
              value={search}
              onItemRemove={onItemRemove}
              onChange={(ev) => setSearch(ev.currentTarget.value)}
              onFocus={() => setHasFocus(true)}
              onBlur={() => {
                setHasFocus(false);
              }}
            />
            <Button
              ml={2}
              minWidth={0}
              icon={FaAddressBook}
              onClick={openAddressBook}
              color="primary"
            />
          </Flex>
        </FormInputWrap>
        {hasFocus && available.length > 0 && (
          <ListPanel p={1}>
            {available.map((x, i) => (
              <ListItem
                key={x.id}
                item={x}
                active={activeIndex === i}
                onMouseEnter={() => setActiveIndex(i)}
                onMouseDown={(ev) => ev.preventDefault()}
                onClick={() => select(i)}
              />
            ))}
          </ListPanel>
        )}
      </div>
    </>
  );
}
