import { ContentAccessReason } from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  Flex,
  Label,
  Panel,
  PopOver,
  Text,
} from "@coaching-culture/ui";
import { format } from "date-fns";
import React from "react";
import {
  FaBookOpen,
  FaCheck,
  FaQuestionCircle,
  FaShare,
  FaUser,
  FaUserCog,
  FaUsers,
  FaUserShield,
} from "react-icons/fa";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { assignedReasons } from "utils/content";

type ContentImageProps = {
  src: string;
} & SpaceProps &
  LayoutProps;

export const ContentImage = styled.div<ContentImageProps>`
  position: relative;
  width: 320px;
  height: 220px;
  max-width: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  ${space};
  ${layout};
`;

const Container = styled(Flex)`
  border-bottom: 1px solid #eee;
`;

export type ContentCardProps = {
  title: string;
  description?: string;
  image: string;
  lastAttempt?: Date | null;
  activeAttempt?: boolean;
  completed?: Date | null;
  onSelect: () => void;
  onShare?: () => void;
  canShare?: boolean;
  accessReasons?: ContentAccessReason[];
  displayMode?: "list" | "grid";
};

const reasons = {
  public: {
    text: "This is a public module.",
    icon: FaBookOpen,
  },
  admin: {
    text: "You're an admin.",
    icon: FaUserCog,
  },
  manager: {
    text: "You're a manager.",
    icon: FaUserShield,
  },
  group: {
    text: "It was assigned to your group.",
    icon: FaUsers,
  },
  user: {
    text: "It was assigned to you personally.",
    icon: FaUser,
  },
  userfield: {
    text: "It was assigned to a user field",
    icon: FaUser,
  },
};

const ContentCardGrid = ({
  title,
  image,
  description,
  lastAttempt,
  activeAttempt,
  completed,
  onSelect,
  accessReasons,
  onShare,
  canShare,
}: ContentCardProps) => {
  const isAssigned = assignedReasons.some((x) => accessReasons.includes(x));

  return (
    <Panel style={{ overflow: "hidden" }} color="none">
      <ContentImage
        src={process.env.REACT_APP_STATIC_ROOT + "/" + image}
        width={"100%"}
      >
        {completed != null && (
          <Circle
            icon={FaCheck}
            color="positive"
            size="large"
            style={{ position: "absolute", bottom: 12, right: 12 }}
          />
        )}
      </ContentImage>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        flex="1"
        p={3}
      >
        <div>
          <Flex alignItems={"center"} justifyContent="space-between">
            <Flex alignItems="center">
              <Text fontWeight={600} fontSize={5} mr={1} color="black">
                {title}
              </Text>
              {accessReasons && (
                <div>
                  <FaQuestionCircle id={title} />
                  <PopOver
                    target={title}
                    title="Why am I seeing this?"
                    render={() => (
                      <>
                        {accessReasons.map((x) => (
                          <Text fontSize={2}>{reasons[x].text}</Text>
                        ))}
                      </>
                    )}
                  />
                </div>
              )}
            </Flex>
            {isAssigned && (
              <Label color="primary" ml={2}>
                Assigned
              </Label>
            )}
          </Flex>
          <Flex alignItems="center" mb={2}>
            <Text fontSize={2}>
              Last attempt:{" "}
              {lastAttempt ? format(lastAttempt, "dd.MM.yyyy") : "Never"}
            </Text>
            {completed != null && (
              <Text fontSize={2} ml={2}>
                Completed: {format(completed, "dd.MM.yyyy")}
              </Text>
            )}
          </Flex>
          <Box mb={4}>
            {(description ?? "")
              .split("\n")
              .filter((x) => x.trim())
              .map((x) => (
                <Text fontSize={3} mb={1}>
                  {x}
                </Text>
              ))}
          </Box>
        </div>
        <Flex alignItems="center">
          <Button onClick={onSelect} mr={1} color="primary">
            {activeAttempt
              ? "Resume"
              : completed
                ? "Restart Module"
                : "Start Now"}
          </Button>
          {canShare ? (
            <Button onClick={onShare} icon={FaShare}>
              Assign
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Panel>
  );
};

const ContentCardList = ({
  title,
  image,
  description,
  lastAttempt,
  activeAttempt,
  completed,
  onSelect,
  onShare,
  canShare,
  accessReasons,
}: ContentCardProps) => {
  const isAssigned = assignedReasons.some((x) => accessReasons.includes(x));

  return (
    <Container p={[2, 4]} flexDirection={["column", "row"]}>
      <ContentImage
        src={process.env.REACT_APP_STATIC_ROOT + "/" + image}
        mr={[0, 4]}
        mb={[2, 0]}
        width={["100%", "320px"]}
      >
        {completed != null && (
          <Circle
            icon={FaCheck}
            color="positive"
            size="large"
            style={{ position: "absolute", bottom: 12, right: 12 }}
          />
        )}
      </ContentImage>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        flex="1"
      >
        <div>
          <Flex alignItems={"center"} justifyContent="space-between">
            <Flex alignItems="center">
              <Text fontWeight={600} fontSize={5} mr={1} color="black">
                {title}
              </Text>
              {accessReasons && (
                <div>
                  <FaQuestionCircle id={title} />
                  <PopOver
                    target={title}
                    title="Why am I seeing this?"
                    render={() => (
                      <>
                        {accessReasons.map((x) => (
                          <Text fontSize={2}>{reasons[x].text}</Text>
                        ))}
                      </>
                    )}
                  />
                </div>
              )}
            </Flex>
            {isAssigned && <Label color="primary">Assigned</Label>}
          </Flex>
          <Flex alignItems="center" mb={2}>
            <Text fontSize={2}>
              Last attempt:{" "}
              {lastAttempt ? format(lastAttempt, "dd.MM.yyyy") : "Never"}
            </Text>
            {completed != null && (
              <Text fontSize={2} ml={2}>
                Completed: {format(completed, "dd.MM.yyyy")}
              </Text>
            )}
          </Flex>
          <Box mb={4}>
            {(description ?? "").split("\n").map((x) => (
              <Text fontSize={3} mb={1}>
                {x}
              </Text>
            ))}
          </Box>
        </div>
        <Flex alignItems="center">
          <Button onClick={onSelect} mr={1} color="primary">
            {activeAttempt
              ? "Resume"
              : completed
                ? "Restart Module"
                : "Start Now"}
          </Button>
          {canShare && (
            <Button onClick={onShare} icon={FaShare}>
              Assign
            </Button>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export function ContentCard({
  displayMode = "list",
  ...rest
}: ContentCardProps) {
  return displayMode === "list" ? (
    <ContentCardList {...rest} />
  ) : (
    <ContentCardGrid {...rest} />
  );
}
