import { Redirect, Route, Switch } from "react-router-dom";
import { Campaigns } from "./Campaigns";
import { Forms } from "./Forms";

const Surveys = () => {
  return (
    <Switch>
      <Route path="/success/surveys/forms" component={Forms} />
      <Route path="/success/surveys/campaigns" component={Campaigns} />
      <Redirect to="/success/surveys/campaigns" />
    </Switch>
  );
};

export default Surveys;
