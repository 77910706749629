import { ManagerCampaign } from "@coaching-culture/types";
import {
  Box,
  Button,
  Text,
  Flex,
  FormInput,
  FormTextArea,
  IconProgress,
  Panel,
  ShareLink,
  Loader,
  CheckBox,
  FormInputWrap,
  Input,
} from "@coaching-culture/ui";
import axios from "axios";
import { addDays } from "date-fns";
import { EmailEditor } from "pages/Success/Feedback/EmailEditor";
import React, { useState } from "react";
import { FaCheck, FaEnvelope, FaInfo } from "react-icons/fa";
import { FormPreviewModal } from "./FormPreviewModal";
import DatePicker from "react-datepicker";
import { uniq } from "lodash";
import { useUser } from "auth";

export type UserCampaignWizardProps = {
  item: ManagerCampaign;
};

export function UserCampaignWizard({ item }: UserCampaignWizardProps) {
  const [user] = useUser();
  const [step, setStep] = useState<number>(0);
  const [modal, setModal] = useState<"" | "preview">("");
  const [emails, setEmails] = useState<string>("");
  const [subject, setSubject] = useState<string>(item.respondentEmailSubject);
  const [text, setText] = useState<string>(
    item.respondentEmailTemplate.replace(/\*\|SENDERS_NAME\|\*/g, user.name)
  );
  const [sharing, setSharing] = useState<boolean>(false);
  const [deadline, setDeadline] = useState<Date | null>(null);

  const forwardSurvey = () => {
    const spec = {
      emails: uniq(
        emails
          .split("\n")
          .map((x) => x.trim())
          .filter((x) => x !== "")
          .map((x) => x.toLowerCase())
      ),
      subject: subject,
      text: text,
      deadline: deadline,
    };
    if (spec.emails.some((x) => !x.includes("@"))) {
      return window.alert("Some of the emails are not valid");
    }
    if (spec.emails.length < item.minimumForwards) {
      return window.alert(
        `A minimum of ${item.minimumForwards} unique emails are required`
      );
    }
    setSharing(true);
    axios
      .post(
        `/api/feedback/user-campaigns/${item.userCampaignId}/forwards`,
        spec
      )
      .then(() => {
        setSharing(false);
        setStep(2);
      })
      .catch((err) => {
        window.alert("Error");
      });
  };

  return (
    <>
      {modal === "preview" && (
        <FormPreviewModal formId={item.formId} onClose={() => setModal("")} />
      )}
      <Panel p={3}>
        <Flex mb={5} mt={2} width="80%" maxWidth="500px" ml="auto" mr="auto">
          <IconProgress
            icons={[FaInfo, FaEnvelope, FaCheck]}
            activeIndex={step}
          />
        </Flex>
        {step === 0 ? (
          <Flex alignItems="center" flexDirection="column">
            <Box width={600} maxWidth="100%" mb={5}>
              <Text mb={3} fontSize={5}>
                You've been asked to collect some feedback.
              </Text>
              <Text mb={1}>
                We're going to ask you for a list of email addresses to whom
                we'll send a survey to collect feedback on your recent
                performance
              </Text>
              <Text>
                You can click the button below to preview the questions your
                respondents will be asked, otherwise click Continue to get
                started.
              </Text>
            </Box>
            <Flex mb={3} justifyContent="center">
              <Button m={2} onClick={() => setModal("preview")} type="button">
                Preview Survey
              </Button>
              <Button
                color="primary"
                m={2}
                type="button"
                onClick={() => setStep(1)}
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        ) : step === 1 ? (
          <Flex alignItems="center" flexDirection="column">
            {sharing && <Loader overlay />}
            <Box width={600} mb={5} maxWidth="100%">
              <Text fontSize={5} mb={3}>
                Send the survey to respondents.
              </Text>
              <Text mb={3}>
                We'll send an email to the people you enter below with a link to
                the survey. You can also include a special note in the email
                before or just use the template that your admins have provided
              </Text>
              <FormTextArea
                height={150}
                name="recipients"
                label={`Email Addresses (minimum: ${item.minimumForwards}, one per line)`}
                value={emails}
                onChange={(ev) => setEmails(ev.target.value)}
              />
              <CheckBox
                value={deadline != null}
                onChange={(val) =>
                  setDeadline(val ? addDays(new Date(), 7) : null)
                }
                label="Include Deadline"
                mb={1}
              />
              <Text mb={2} fontSize={2}>
                This will send a reminder email just before the deadline.
                Recipients will still be able to complete the survey after the
                deadline has passed.
              </Text>
              {deadline != null && (
                <FormInputWrap label="Deadline">
                  <DatePicker
                    selected={deadline}
                    onChange={(date) => setDeadline(date as any)}
                    dateFormat="dd/MM/yyyy"
                    customInput={<Input />}
                    minDate={new Date()}
                  />
                </FormInputWrap>
              )}
              <FormInput
                name="subject"
                value={subject}
                onChange={(ev) => setSubject(ev.target.value)}
                label="Subject"
              />
              <EmailEditor value={text} onChange={setText} />
              <Flex justifyContent="center" p={3}>
                <Button
                  color="primary"
                  type="button"
                  onClick={forwardSurvey}
                  disabled={emails === ""}
                >
                  Continue
                </Button>
              </Flex>
            </Box>
          </Flex>
        ) : step === 2 ? (
          <Flex alignItems="center" flexDirection="column">
            <Box width={600} mb={5} maxWidth="100%">
              <Text fontSize={5} mb={3}>
                Thank you!
              </Text>
              <Text mb={1}>
                If you'd like to share the survey with others you can send them
                the link below:
              </Text>
              <ShareLink
                mb={3}
                url={`${process.env.REACT_APP_PLATFORM_ROOT}/feedback?survey=${item.masterCode}`}
              />
              <Text mb={5}>
                You can also complete the survey yourself so you can compare
                your answers to others'. Click the button below if you would
                like to do so.
              </Text>
              <Flex justifyContent="center" flexDirection={["column", "row"]}>
                <Button
                  m={1}
                  to={`${process.env.REACT_APP_PLATFORM_ROOT}/feedback?survey=${item.masterCode}`}
                >
                  Complete the Survey Yourself
                </Button>
                <Button
                  color="primary"
                  m={1}
                  onClick={() => window.location.reload()}
                >
                  Go to survey dashboard
                </Button>
              </Flex>
            </Box>
          </Flex>
        ) : null}
      </Panel>
    </>
  );
}
