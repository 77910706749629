import {
  CoachingCategoryDto,
  ConversationDto,
  ConversationOccurenceViewDto,
  CreateConversationCommand,
  DiscussionPointDto,
  RescheduleConversationSpec,
  UpdateConversationDetailsCommand,
} from "@coaching-culture/types";
import axios from "axios";
import { getUnixTime } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getConversations = () =>
  axios
    .get<ConversationDto[]>("/api/v2/conversations")
    .then(({ data }) => data);

const getConversationOccurence = (id: string, index: number) =>
  axios
    .get<ConversationOccurenceViewDto>(`/api/v2/conversations/${id}/${index}`)
    .then(({ data }) => data);

const completeConversation = (id: string, index: number) =>
  axios.put(`/api/v2/conversations/${id}/${index}/complete`);

const updateConversationDetails = (
  id: string,
  spec: UpdateConversationDetailsCommand,
  index: number,
) => axios.put(`/api/v2/conversations/${id}/${index}/details`, spec);

const cancelConversation = (id: string) =>
  axios.delete(`/api/v2/conversations/${id}`);

const cancelConversationOccurrence = (id: string, index: number) =>
  axios.delete(`/api/v2/conversations/${id}/${index}`);

const createConversation = (spec: CreateConversationCommand) =>
  axios.post<string>("/api/v2/conversations", spec).then(({ data }) => data);

const rescheduleConversation = (
  id: string,
  index: number,
  spec: RescheduleConversationSpec,
) => axios.put(`/api/v2/conversations/${id}/${index}/reschedule`, spec);

const favouriteTopic = (topicId: string) =>
  axios.put(`/api/v2/coaching-categories/topic/${topicId}/favourite`);

const changeActionItemStatus = (
  convoId: string,
  actionItemId: string,
  value: boolean,
) =>
  axios.put(`/api/v2/conversations/${convoId}/actions/${actionItemId}`, {
    value,
  });

const changeAgenda = (
  id: string,
  index: number,
  items: Partial<DiscussionPointDto>[],
) =>
  axios.put(`/api/v2/conversations/${id}/${index}/agenda`, {
    discussionPoints: items,
  });

export const useConversations = () =>
  useQuery(["conversations"], getConversations);

export const useConversation = (id: string, index: number) =>
  useQuery(["conversations", id, index], () =>
    getConversationOccurence(id, index),
  );

export const useConversationInterval = (id: string, index: number) =>
  useQuery(
    ["conversations", id, index],
    () => getConversationOccurence(id, index),
    {
      refetchInterval: 30000,
    },
  );

export const useCreateConversation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createConversation, {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
      queryClient.invalidateQueries("myteam");
    },
  });

  return mutation;
};

export const useRescheduleConversation = (id: string, index: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: RescheduleConversationSpec) =>
      rescheduleConversation(id, index, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
        queryClient.invalidateQueries("myteam");
      },
    },
  );

  return mutation;
};

export const useCompleteConversation = (id: string, index: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(() => completeConversation(id, index), {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
      queryClient.invalidateQueries("myteam");
    },
  });

  return mutation;
};

export const useUpdateConversationDetails = (id: string, index: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: UpdateConversationDetailsCommand) =>
      updateConversationDetails(id, spec, index),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
        queryClient.invalidateQueries(["actions"]);
      },
    },
  );

  return mutation;
};

export const useChangeAgenda = (convoId: string, index: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (items: Partial<DiscussionPointDto>[]) =>
      changeAgenda(convoId, index, items),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
      },
    },
  );

  return mutation;
};

type ChangeActionItemProps = {
  conversationId: string;
  actionItemId: string;
  value: boolean;
};

export const useChangeActionItem = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ conversationId, actionItemId, value }: ChangeActionItemProps) =>
      changeActionItemStatus(conversationId, actionItemId, value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
        queryClient.invalidateQueries(["actions"]);
      },
    },
  );

  return mutation;
};

export const useCancelConversation = (convoId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(() => cancelConversation(convoId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
      queryClient.invalidateQueries("myteam");
    },
  });

  return mutation;
};

export const useFavouriteTopic = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((topicId: string) => favouriteTopic(topicId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["coaching-categories"]);
    },
  });

  return mutation;
};

export const useCancelConversationOccurrence = (
  convoId: string,
  index: number,
) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => cancelConversationOccurrence(convoId, index),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
        queryClient.invalidateQueries("myteam");
      },
    },
  );

  return mutation;
};

const getCoachingCategories = () =>
  axios
    .get<CoachingCategoryDto[]>("/api/v2/coaching-categories")
    .then(({ data }) => data);

export const useCoachingCategories = () =>
  useQuery(["coaching-categories"], () => getCoachingCategories());

const getCoachingCategoriesForUser = () =>
  axios
    .get<CoachingCategoryDto[]>("/api/v2/coaching-categories/user")
    .then(({ data }) => data);

export const useCoachingCategoriesForUser = () =>
  useQuery(["coaching-categories"], () => getCoachingCategoriesForUser());

//Comments
// Goal Comments

async function deleteConversationComment(
  conversationId: string,
  index: number,
  conversationCommentId: string,
) {
  return axios.delete(
    `/api/v2/conversations/${conversationId}/${index}/comments/${conversationCommentId}`,
  );
}

export function useDeleteConversationComment(
  conversationId: string,
  index: number,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (commentId: string) =>
      deleteConversationComment(conversationId, index, commentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversations"]);
      },
    },
  );

  return mutation;
}

async function createConversationComment(spec: {
  body: string;
  isPrivate: boolean;
  index: number;
  convoId: string;
}) {
  return axios.post(
    `/api/v2/conversations/${spec.convoId}/${spec.index}/comments`,
    spec,
  );
}

export function useCreateConversationComment() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: {
      body: string;
      isPrivate: boolean;
      index: number;
      convoId: string;
    }) => createConversationComment(spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("conversations");
      },
    },
  );

  return mutation;
}
