import { Flex, Panel } from "@coaching-culture/ui";
import WidgetHeader from "components/WidgetHeader";
import { MeasuresUpdater } from "pages/TeamView/MeasuresUpdater";
import { FaChartLine } from "react-icons/fa";

export type MeasuresWidgetProps = {
  relationshipId: string;
};

export const MeasuresWidget = ({ relationshipId }: MeasuresWidgetProps) => {
  return (
    <Panel width={"100%"} p={0}>
      <WidgetHeader title="Update Measures" icon={FaChartLine} />
      <Flex flexDirection={"column"} p={3} pt={5}>
        <MeasuresUpdater
          relationshipId={relationshipId}
          onUpdatedAll={() => {}}
        />
      </Flex>
    </Panel>
  );
};
