import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  position,
  layout,
  space,
  SpaceProps,
  LayoutProps,
  PositionProps,
} from "styled-system";
import { useHistory, useParams } from "react-router";
import {
  Box,
  Button,
  Flex,
  FormInput,
  Img,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "../../auth";
import welcomeSrc from "../../img/welcome.png";
import Dots from "./Dots";
import { useSpring, animated } from "react-spring";
import { PanelCarousel, PanelCarouselSlide } from "./PanelCarousel";
import { chunk } from "lodash";
import UserFieldInput from "components/UserFieldInput";
import { Link } from "react-router-dom";
import logoSrc from "../../img/logo.png";

type FormParams = {
  id: string;
};

const Background = styled.div<PositionProps & LayoutProps & SpaceProps>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #c9d6ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #e2e2e2, #c9d6ff);
  background: linear-gradient(to bottom, #e2e2e2, #c9d6ff);
  ${position};
  ${layout};
  ${space};
`;

const AnimPanel = animated(Panel);

const RegistrationPage = () => {
  const { id } = useParams<FormParams>();
  const [form, setForm] = useState(null);
  const history = useHistory();
  const [user, setToken] = useUser();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [res, setRes] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [step, setStep] = useState(0);

  const formFields = (form?.fields || []).filter((x) => x.forcedValue == null);
  const fieldPages = chunk<any>(formFields, 3);
  const slideCount = fieldPages.length + 2;

  useEffect(() => {
    axios.get(`/api/self-registration-forms/${id}`).then(({ data }) => {
      setForm(data);
      setLoading(false);
    });
  }, [id]);

  const panelStyles = useSpring({
    transform: "translateY(0px)",
    opacity: 1,
    from: {
      transform: "translateY(30px)",
      opacity: 0,
    },
  });

  const validateStep = async () => {
    const req = (value: string) =>
      (value || "").trim() === "" ? "Value is required" : null;

    if (step === 0) {
      return {};
    } else if (step === 1) {
      const e = {
        firstName: req(res.firstName),
        lastName: req(res.lastName),
        email: req(res.email),
        password: req(res.password),
        confirmPassword: req(res.confirmPassword),
      };

      try {
        await axios.get(`/api/auth/check-email?email=${res.email}`);
        e.email = "Email already exists";
      } catch {}

      if (e.email == null && !res.email.includes("@")) {
        e.email = "Not a valid email";
      }

      if (e.password == null && res.password.length < 8) {
        e.password = "Password must be at least 8 characters";
      }

      if (res.confirmPassword !== res.password) {
        e.confirmPassword = "Must match password";
      }

      return e;
    } else {
      const fields = fieldPages[step - 2];
      return Object.fromEntries(
        fields.map((x) => [`uf_${x.id}`, req(res[`uf_${x.id}`])])
      );
    }
  };

  const advance = async () => {
    const e = await validateStep();

    if (Object.values(e).every((x) => x == null)) {
      setErrors({});
      if (step === slideCount - 1) {
        if (user != null) {
          window.alert("The form would now be successfully submitted");
        } else {
          setLoading(true);

          axios.post(`/api/register/${form.url}`, res).then(({ data }) => {
            console.log(data);
            setToken(data.token);
            history.push("/");
          });
        }
      } else {
        setStep((old) => (old + 1) % 5);
      }
    } else {
      setErrors(e);
    }
  };

  const setResValue = (key) => (ev) => {
    const val = ev.target ? ev.target.value : ev;
    setRes((old) => ({
      ...old,
      [key]: val,
    }));
  };

  return (
    <Background
      position={["static", "fixed"]}
      height={["auto", "100%"]}
      p={3}
      minHeight="100%"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {user != null && (
            <AnimPanel
              width={600}
              maxWidth="100%"
              p={3}
              alignItems="center"
              style={panelStyles}
              mb={3}
            >
              <Text fontSize={[3, 4]} textAlign="center" mb={2}>
                You're already logged in as <strong>{user.email}</strong>. You
                can test this form but the submission will have no effect.
              </Text>
              <Text mb={2}>If this is not you click below to log out</Text>
              <Button color="danger" onClick={() => setToken(null)}>
                Log out
              </Button>
            </AnimPanel>
          )}
          <AnimPanel
            width={600}
            maxWidth="100%"
            p={5}
            alignItems="center"
            style={panelStyles}
          >
            <Flex
              justifyContent="space-between"
              alignItems="flex-start"
              width="100%"
              mb={5}
            >
              <Flex flexDirection="column">
                <Text fontSize={5}>Let's get started...</Text>
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </Flex>
              <Img src={logoSrc} width={150} />
            </Flex>
            <Box mb={5} width="100%">
              <PanelCarousel index={step}>
                <PanelCarouselSlide>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <img
                      src={welcomeSrc}
                      alt="Welcome"
                      width={500}
                      style={{ maxWidth: "100%" }}
                    />
                  </Flex>
                </PanelCarouselSlide>
                <PanelCarouselSlide>
                  <Flex justifyContent="center">
                    <Box width={["100%", 400]}>
                      <Text fontSize={4} mb={3}>
                        Personal Information
                      </Text>
                      <FormInput
                        label="First Name"
                        value={res.firstName}
                        onChange={setResValue("firstName")}
                        error={errors.firstName}
                        required
                      />
                      <FormInput
                        label="Last Name"
                        value={res.lastName}
                        onChange={setResValue("lastName")}
                        error={errors.lastName}
                        required
                      />
                      <FormInput
                        label="Email"
                        value={res.email}
                        onChange={setResValue("email")}
                        error={errors.email}
                        required
                      />
                      <FormInput
                        label="Password"
                        type="password"
                        autoComplete="new-password"
                        value={res.password}
                        onChange={setResValue("password")}
                        error={errors.password}
                        required
                      />
                      <FormInput
                        label="Confirm Password"
                        type="password"
                        autoComplete="new-password"
                        value={res.confirmPassword}
                        error={errors.confirmPassword}
                        onChange={setResValue("confirmPassword")}
                        required
                      />
                    </Box>
                  </Flex>
                </PanelCarouselSlide>
                {fieldPages.map((x, i) => (
                  <PanelCarouselSlide key={i}>
                    <Flex justifyContent="center">
                      <Box width={["100%", 400]}>
                        <Text mb={3} fontSize={4}>
                          Just a bit more info...
                        </Text>
                        {x.map((f) => (
                          <UserFieldInput
                            isFilter={false}
                            key={f.id}
                            field={f.userField}
                            value={res[`uf_${f.id}`]}
                            error={errors[`uf_${f.id}`]}
                            onChange={setResValue(`uf_${f.id}`)}
                          />
                        ))}
                      </Box>
                    </Flex>
                  </PanelCarouselSlide>
                ))}
              </PanelCarousel>
            </Box>
            <Dots count={slideCount} index={step} mb={3} />
            <Button type="button" color="primary" onClick={advance}>
              {step === slideCount - 1 ? "Submit" : "Continue"}
            </Button>
          </AnimPanel>
        </>
      )}
    </Background>
  );
};

export default RegistrationPage;
