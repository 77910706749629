import { DirectReportDto } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  Grid,
  Label,
  Modal,
  Text,
  UserProfileImage,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import { format, fromUnixTime } from "date-fns";
import { darken, lighten } from "polished";
import { useState } from "react";
import { IconType } from "react-icons";
import {
  FaBan,
  FaBullseye,
  FaCalendarAlt,
  FaChartBar,
  FaCheckCircle,
  FaCommentDots,
  FaEnvelope,
  FaExclamationTriangle,
  FaHourglass,
  FaHourglassHalf,
  FaInfoCircle,
} from "react-icons/fa";
import styled from "styled-components";
import { MeasuresChart } from "./MeasuresChart";
import {
  conversationsStatus,
  daysSinceFeedbackFromAnyone,
  daysSinceFeedbackFromMe,
  daysSinceLastConvo,
  daysToNextConvo,
  feedbackStatus,
  goalsBlocked,
  goalsSet,
  goalsStatus,
  measuresLastReviewed,
  measuresStatus,
  nextConvo,
  overdueGoals,
} from "./metrics";
import { ReviewMeasuresDrawer } from "./ReviewMeasuresDrawer";
import { ScheduleConversationDrawer } from "./ScheduleConversationDrawer";
import { SendFeedbackDrawer } from "./SendFeedbackDrawer";
import { SetGoalDrawer } from "./SetGoalDrawer";

const Header = styled.div<{ headerColor?: string }>`
  display: flex;
  transition: all 0.5s ease;
  border: 1px solid
    ${(props) =>
      props.headerColor == null
        ? props.theme.colors.grey5
        : props.theme.colors[props.headerColor]};
  border-radius: 3px;
  background-color: ${(props) =>
    props.headerColor == null
      ? "#fcfcfc"
      : lighten(0.45, props.theme.colors[props.headerColor])};
  width: 100%;
  justify-content: ${(props) =>
    props.headerColor == null ? "flex-start" : "center"};
  padding: 0px 12px;
  align-items: center;
  font-weight: 600;
  height: 30px;
  position: relative;
  color: ${(props) =>
    props.headerColor == null
      ? "#222"
      : darken(0.2, props.theme.colors[props.headerColor])};
  & svg {
    margin-right: 6px;
    fill: ${(props) =>
      props.headerColor ? "current-color" : props.theme.colors["primary"]};
  }
`;

const Block = styled.div`
  display: flex;
  flex: 1;
  min-height: 80px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 130px;

  &:after {
    position: absolute;
    content: "";
    display: block;
    right: 0;
    height: 40px;
    top: calc(50% - 20px);
    width: 2px;
    background-color: ${(props) => props.theme.colors.grey5};
  }

  &:last-child:after {
    display: none;
  }
`;

type BlockContentProps = {
  icon: IconType;
  count: number | null;
  title: string;
};

const BlockContent = ({ icon: Icon, count, title }: BlockContentProps) => (
  <>
    <Flex alignItems="center">
      <Icon fontSize="1.3em" />
      <Text fontSize={5} fontWeight={600} ml={1}>
        {count ?? "-"}
      </Text>
    </Flex>
    <Text fontSize={2} textAlign="center" lineHeight={1.2}>
      {title}
    </Text>
  </>
);

const StatusHeader = ({ status }: { status: string }) => {
  const statuses = {
    danger: "Action required",
    warning: "Check it out",
    positive: "Looks good",
  };

  const icons = {
    danger: FaExclamationTriangle,
    warning: FaInfoCircle,
    positive: FaCheckCircle,
  };

  const Icon = icons[status];

  return (
    <Header headerColor={status}>
      <Icon /> {statuses[status]}
    </Header>
  );
};

const DatePill = ({ value }: { value: Date | number | null }) => {
  const asDate = typeof value === "number" ? fromUnixTime(value) : value;

  return (
    <Label icon={FaCalendarAlt} color="grey3" size="small" textColor="#666">
      <Text as="span" fontWeight={500}>
        {asDate == null ? "Never" : format(asDate, "dd MMM 'at' h:mm a")}
      </Text>
    </Label>
  );
};

const Well = styled(Flex)`
  background-color: ${(props) => props.theme.colors.grey5};
`;

export type TeamViewModalProps = {
  user: DirectReportDto;
  onClose: () => void;
};

export const TeamViewModal = ({ user, onClose }: TeamViewModalProps) => {
  const [me] = useUser();
  const terms = useGoalTerminology();
  const [drawer, setDrawer] = useState("");

  const mlr = measuresLastReviewed(user);

  return (
    <Modal onClose={onClose} showCloseButton width={800}>
      <Flex p={3} alignItems="center" borderBottom={1}>
        <UserProfileImage
          profileImage={user.profileImage}
          name={user.name}
          size={100}
          mr={3}
        />
        <div>
          <Text fontWeight={600} fontSize={4}>
            {user.name}
          </Text>
          <Text color="grey2">{user.jobTitle ?? user.email}</Text>
        </div>
      </Flex>
      <Box style={{ position: "relative", overflow: "hidden" }}>
        {drawer === "feedback" && (
          <SendFeedbackDrawer onClose={() => setDrawer("")} user={user} />
        )}
        {drawer === "measures" && (
          <ReviewMeasuresDrawer onClose={() => setDrawer("")} user={user} />
        )}
        {drawer === "goals" && (
          <SetGoalDrawer onClose={() => setDrawer("")} user={user} />
        )}
        {drawer === "conversation" && (
          <ScheduleConversationDrawer
            onClose={() => setDrawer("")}
            user={user}
          />
        )}
        <Box p={3}>
          <Grid gridTemplateColumns={"2fr 1fr"} gridGap={1}>
            <div>
              <Header>
                <FaBullseye />
                {terms.goal.asPluralTitle()}
              </Header>
            </div>
            <div>
              <StatusHeader status={goalsStatus(user)} />
            </div>

            <Flex>
              <Block>
                <BlockContent
                  icon={FaBullseye}
                  count={goalsSet(user)}
                  title={`${terms.goal.asPluralBody()} set`}
                />
              </Block>
              <Block>
                <BlockContent
                  icon={FaBan}
                  count={goalsBlocked(user)}
                  title={`blocked ${terms.goal.asPluralBody()}`}
                />
              </Block>
              <Block>
                <BlockContent
                  icon={FaCalendarAlt}
                  count={overdueGoals(user)}
                  title={`overdue ${terms.goal.asPluralBody()}`}
                />
              </Block>
            </Flex>
            <Flex alignItems={"center"}>
              <Button
                icon={FaBullseye}
                large
                width="100%"
                color="primary"
                onClick={() => setDrawer("goals")}
              >
                Set {terms.goal.asTitle()}
              </Button>
            </Flex>
            <div>
              <Header>
                <FaCommentDots />
                Conversations
              </Header>
            </div>
            <div>
              <StatusHeader status={conversationsStatus(user)} />
            </div>
            <Flex>
              <Block>
                <BlockContent
                  icon={FaHourglass}
                  count={daysSinceLastConvo(user)}
                  title="days since last conversation"
                />
              </Block>
              <Block style={{ flex: 1.2, maxWidth: "160px" }}>
                <Flex alignItems="center" mb={1} p={1}>
                  <DatePill value={nextConvo(user)?.scheduledDate} />
                </Flex>
                <Text fontSize={2} textAlign="center" lineHeight={1.2}>
                  next conversation
                </Text>
              </Block>
              <Block>
                <BlockContent
                  icon={FaCalendarAlt}
                  count={daysToNextConvo(user)}
                  title="days until next conversation"
                />
              </Block>
            </Flex>
            <Flex alignItems={"center"}>
              <Button
                icon={FaCommentDots}
                large
                width="100%"
                color="primary"
                onClick={() => setDrawer("conversation")}
              >
                Schedule a Conversation
              </Button>
            </Flex>

            <div>
              <Header>
                <FaEnvelope />
                Feedback
              </Header>
            </div>
            <div>
              <StatusHeader status={feedbackStatus(user)} />
            </div>
            <Flex>
              <Block>
                <BlockContent
                  icon={FaHourglassHalf}
                  count={daysSinceFeedbackFromMe(user, me.id)}
                  title="days since feedback from me"
                />
              </Block>
              <Block>
                <BlockContent
                  icon={FaHourglassHalf}
                  count={daysSinceFeedbackFromAnyone(user)}
                  title="days since feedback from anyone"
                />
              </Block>
            </Flex>
            <Flex alignItems={"center"}>
              <Button
                icon={FaEnvelope}
                large
                width="100%"
                color="primary"
                onClick={() => setDrawer("feedback")}
              >
                Give Feedback
              </Button>
            </Flex>

            <div>
              <Header>
                <FaChartBar />
                Measures
              </Header>
            </div>
            <div>
              <StatusHeader status={measuresStatus(user)} />
            </div>
            <div>
              <MeasuresChart user={user} />
            </div>
            <Flex width="100%" flexDirection={"column"} justifyContent="center">
              <Well
                mb={1}
                p={3}
                flexDirection="column"
                alignItems={"center"}
                justifyContent="center"
                borderRadius={3}
              >
                <Flex alignItems={"center"} mb={1}>
                  <FaCalendarAlt />
                  <Text ml={1} fontWeight={500} fontSize={2}>
                    Last reviewed
                  </Text>
                </Flex>
                <Text fontWeight={600}>
                  {mlr == null
                    ? "Never"
                    : format(fromUnixTime(mlr), "do MMM yy 'at' h:mm a")}
                </Text>
              </Well>
              <Button
                large
                icon={FaChartBar}
                color="primary"
                onClick={() => setDrawer("measures")}
              >
                Review Measures
              </Button>
            </Flex>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
