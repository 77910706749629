import { ContinuousFeedbackDto } from "@coaching-culture/types";
import {
  Box,
  Circle,
  Flex,
  Loader,
  ReactModal,
  Text,
} from "@coaching-culture/ui";
import { ScrollableContainer } from "components/ScrollableContainer";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import ReactHtmlParser from "react-html-parser";
import { FaComment, FaPrayingHands } from "react-icons/fa";
import styled from "styled-components";

export type ContinuousFeedbackModalProps = {
  feedback: ContinuousFeedbackDto;
  onCancel: () => void;
  isOpen: boolean;
};

const DetailContent = styled.div`
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 12px;
  min-height: 100px;
`;

export const ContinuousFeedbackModal = ({
  feedback,
  onCancel,
  isOpen,
}: ContinuousFeedbackModalProps) => {
  return (
    <ReactModal onClose={onCancel} isOpen={isOpen} width={600}>
      {feedback == null ? (
        <Loader />
      ) : (
        <Box p={4}>
          <Flex mb={3} alignItems={"center"}>
            <Circle
              color={feedback.type === "praise" ? "positive" : "body"}
              icon={feedback.type === "praise" ? FaPrayingHands : FaComment}
              size="large"
              ml={2}
              mr={2}
              title={feedback.type === "praise" ? `Praise` : `Feedback`}
            />
            <Text fontWeight={600} fontSize={4}>
              {feedback.summary}
            </Text>
          </Flex>

          <Text fontWeight={600} fontSize={3}>
            Sender:
          </Text>
          <UserFlag mb={3} user={feedback.sentBy} />
          <Text fontWeight={600} fontSize={3}>
            Date received:
          </Text>
          <Text mb={3} fontSize={3}>
            {format(fromUnixTime(feedback.sentOn), "do MMM yyyy")}
          </Text>
          <Text fontWeight={600} fontSize={3}>
            Detail
          </Text>

          <DetailContent>
            <ScrollableContainer maxHeight={200}>
              {ReactHtmlParser(feedback.detail)}
            </ScrollableContainer>
          </DetailContent>
        </Box>
      )}
    </ReactModal>
  );
};
