import CenterColumn from "components/CenterColumn";
import { TabPane, Tabs } from "components/Tabs";
import { PageHeader } from "components/PageHeader";
import { PerformUserInsights } from "./PerformUserInsights";
import { PerformManagerInsights } from "./PerformManagerInsights";
import { useProductVisibility } from "hooks/useProductVisibility";
import { ProductVisibility } from "@coaching-culture/types";
import { MeasuresInsights } from "./MeasuresInsights";

export const PerformanceInsights = () => {
  const hasMeasures =
    useProductVisibility("measures") === ProductVisibility.Enabled;

  return (
    <CenterColumn>
      <PageHeader text="My Organisation" />
      <Tabs>
        <TabPane defaultValue={"Activity"}>
          <PerformUserInsights />
        </TabPane>
        {hasMeasures && (
          <TabPane defaultValue={"Measures"}>
            <MeasuresInsights />
          </TabPane>
        )}
        <TabPane defaultValue={"Managers"}>
          <PerformManagerInsights />
        </TabPane>
      </Tabs>
    </CenterColumn>
  );
};
