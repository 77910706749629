import { RatingFormItemResponses, UserDisplay } from "@coaching-culture/types";
import {
  Box,
  Flex,
  Label,
  PanelHeader,
  PanelInset,
  Text,
  usePagination,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { max, mean, min, range } from "lodash";
import { mix } from "polished";
import {
  FaFrown,
  FaFrownOpen,
  FaGrin,
  FaMeh,
  FaQuestionCircle,
  FaSmile,
  FaStar,
} from "react-icons/fa";
import styled from "styled-components";
import { fontSize, FontSizeProps } from "styled-system";

const StarContainer = styled.div<{ active: boolean } & FontSizeProps>`
  margin: 3px;
  display: flex;
  color: ${(props) =>
    props.active ? props.theme.colors.star : props.theme.colors.grey2};
  ${fontSize};
`;

const IconContainer = styled.div<{ iconColor: number } & FontSizeProps>`
  margin: 3px;
  display: flex;
  color: ${(props) =>
    props.iconColor === -1
      ? props.theme.colors.grey2
      : mix(
          (props.iconColor - 1) / 4,
          props.theme.colors.positive,
          props.theme.colors.danger,
        )};
  ${fontSize};
`;

const Dot = styled.div<{ dotColor: number }>`
  width: 12px;
  height: 12px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.dotColor === -1
      ? props.theme.colors.grey2
      : mix(
          (props.dotColor - 1) / 6,
          props.theme.colors.positive,
          props.theme.colors.danger,
        )};
`;

export const dotNames = [
  "Strongly Disagree",
  "Disagree",
  "Somewhat Disagree",
  "Neutral",
  "Somewhat Agree",
  "Agree",
  "Strongly Agree",
];

function RatingNumber({ value }: { value: number }) {
  return (
    <Text fontWeight={600} fontSize={4}>
      {value.toFixed(1)}
    </Text>
  );
}

export function RatingDots({
  value,
  reverseScale,
}: {
  value: number;
  reverseScale: boolean;
}) {
  return (
    <>
      <Text fontWeight={600} fontSize={4}>
        {
          dotNames[
            reverseScale
              ? Math.floor(Math.abs(value - 7))
              : Math.floor(value) - 1
          ]
        }
      </Text>
      <Flex justifyContent="center">
        {range(7).map((x) => (
          <Dot
            key={x}
            dotColor={
              reverseScale
                ? Math.floor(Math.abs(value - 7)) === x
                  ? value
                  : -1
                : Math.floor(value) === x + 1
                  ? value
                  : -1
            }
          />
        ))}
      </Flex>
    </>
  );
}

const smiles = [FaFrownOpen, FaFrown, FaMeh, FaSmile, FaGrin];

export function RatingSmiles({ value }: { value: number }) {
  return (
    <Flex justifyContent="center" alignItems="center">
      {smiles.map((Icon, x) => (
        <IconContainer
          key={x}
          iconColor={Math.floor(value) === x + 1 ? value : -1}
          fontSize={["16px", "18px", "24px"]}
        >
          <Icon />
        </IconContainer>
      ))}
    </Flex>
  );
}

export function RatingStars({ value }: { value: number }) {
  return (
    <Flex justifyContent="center" alignItems="center">
      {range(1, 6).map((x) => (
        <StarContainer
          key={x}
          active={Math.floor(value) >= x}
          fontSize={["16px", "18px", "24px"]}
        >
          <FaStar />
        </StarContainer>
      ))}
    </Flex>
  );
}

function Rating({
  value,
  label,
  type,
  last,
  reverseScale,
}: {
  value: number | null;
  label: string;
  type: string;
  last?: boolean;
  reverseScale: boolean;
}) {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      borderRight={[0, !last ? 1 : 0]}
      borderBottom={[!last ? 1 : 0, 0]}
      p={[3, 0]}
      flex={1}
    >
      <Text mb={1} color="grey2" fontWeight={600}>
        {label}:
      </Text>
      {value == null ? (
        <Text>None Given</Text>
      ) : type === "dots" ? (
        <RatingDots reverseScale={reverseScale} value={value} />
      ) : type === "smiles" ? (
        <RatingSmiles value={value} />
      ) : type === "stars" ? (
        <RatingStars value={value} />
      ) : type === "number" ? (
        <RatingNumber value={value} />
      ) : null}
    </Flex>
  );
}

const ForceRowFlex = styled(Flex)`
  @media print {
    flex-direction: row !important;
  }
`;

export type RatingInsightsProps = {
  item: RatingFormItemResponses;
  user: UserDisplay | null;
  showResponses: boolean;
  paginated: boolean;
};

export function RatingInsights({
  item,
  user,
  showResponses,
  paginated,
}: RatingInsightsProps) {
  const [currentUser] = useUser();

  const userText = currentUser?.id === user?.id ? "Your" : "User's";
  const myAnswer = item.responses.find((x) => x.email === user?.email);

  const responses = item.responses;

  const {
    data: pagedResponses,
    component: Pagination,
    pageCount,
  } = usePagination(responses, paginated ? 8 : 99999, 1);

  const withoutMe = responses.filter((x) => x.email !== user?.email);

  const allowNa = item.allowNa;
  const respondentCount = withoutMe.length;
  const responseCount = withoutMe.filter((x) => x.rating != null).length;
  const noOppCount = respondentCount - responseCount;
  const ratings = withoutMe
    .filter((x) => x.rating != null)
    .map((x) => x.rating);
  const averageRating = ratings.length === 0 ? null : mean(ratings);
  const highestAnswer = max(ratings);
  const lowestAnswer = min(ratings);

  return (
    <PanelInset mb={3}>
      <PanelHeader>
        <FaQuestionCircle />
        <Text fontWeight={600} ml={2}>
          {item.content}
        </Text>
      </PanelHeader>
      <PanelHeader height="auto">
        <ForceRowFlex
          justifyContent="space-between"
          width="100%"
          flexDirection={["column", "row"]}
        >
          <Flex alignItems="center" flex="1" justifyContent="center">
            <Text mr={1}>Respondents:</Text>
            <Text as="span" fontSize={5} color="positive" fontWeight={600}>
              {respondentCount}
            </Text>
          </Flex>
          <Flex alignItems="center" flex="1" justifyContent="center">
            <Text mr={1}>Responses:</Text>
            <Text as="span" fontSize={5} color="positive" fontWeight={600}>
              {responseCount}
            </Text>
          </Flex>
          <Flex alignItems="center" flex="1" justifyContent="center">
            {allowNa && (
              <>
                <Text mr={1}>No opportunity to observe:</Text>
                <Text as="span" fontSize={5} color="positive" fontWeight={600}>
                  {noOppCount}
                </Text>{" "}
              </>
            )}
          </Flex>
        </ForceRowFlex>
      </PanelHeader>
      <ForceRowFlex
        justifyContent="stretch"
        p={3}
        flexDirection={["column", "row"]}
        style={{ borderBottom: "1px solid #eee" }}
      >
        {user != null && (
          <Rating
            value={myAnswer?.rating}
            label={`${userText} Rating`}
            type={item.ratingType}
            reverseScale={item.reverseScale}
          />
        )}
        <Rating
          value={averageRating}
          label="Average Rating"
          type={item.ratingType}
          reverseScale={item.reverseScale}
        />
        <Rating
          value={highestAnswer}
          label="Highest Rating"
          type={item.ratingType}
          reverseScale={item.reverseScale}
        />
        <Rating
          value={lowestAnswer}
          label="Lowest Rating"
          type={item.ratingType}
          reverseScale={item.reverseScale}
          last
        />
      </ForceRowFlex>
      {showResponses && item.requiresEvidence && responses.length > 0 && (
        <Flex flexDirection="column" p={3}>
          <Text fontSize={4} fontWeight={600} mb={3}>
            Responses:
          </Text>
          {pagedResponses.map((x, i) => (
            <Flex
              flexDirection="column"
              key={i}
              mb={i === item.responses.length - 1 ? 0 : 3}
            >
              <Flex alignItems="center" mb={1}>
                <Text fontWeight={600}>Respondent: {x.name} </Text>
                {x.email === user?.email ? (
                  <Label size="small" ml={2}>
                    {userText} Response
                  </Label>
                ) : null}
              </Flex>
              <Text>{x.evidence ?? "<None Given>"}</Text>
            </Flex>
          ))}
          {pageCount > 1 && (
            <Box p={2}>
              <Pagination />{" "}
            </Box>
          )}
        </Flex>
      )}
    </PanelInset>
  );
}
