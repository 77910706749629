import React from "react";
import { IconType } from "react-icons";
import Circle from "./Circle";
import Flex from "./Flex";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";

export type IconProgressProps = {
  icons: IconType[];
  activeIndex: number;
};

const ProgressContainer = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: 10px;
  width: calc(100% - 20px);
  height: 16px;
  background-color: ${(props) => props.theme.colors.grey3};
`;

const Progress = styled(animated.div)`
  background-color: ${(props) => props.theme.colors.primary};
  transform-origin: 0% 50%;
  width: 100%;
  height: 100%;
`;

export function IconProgress({ icons, activeIndex }: IconProgressProps) {
  const style = useSpring({
    transform: `scale3d(${activeIndex / (icons.length - 1)}, 1, 1)`,
  });

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      style={{ position: "relative" }}
    >
      <ProgressContainer>
        <Progress style={style} />
      </ProgressContainer>
      {icons.map((x, i) => (
        <Circle
          key={i}
          style={{ zIndex: 1 }}
          icon={x}
          size="large"
          color={activeIndex >= i ? "primary" : "grey2"}
        />
      ))}
    </Flex>
  );
}
