import {
  ContentReportItem,
  ContentType,
  UserFieldType,
  UserFilter,
} from "@coaching-culture/types";
import {
  Box,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
} from "@coaching-culture/ui";
import axios from "axios";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import { uniq } from "lodash";
import Papa from "papaparse";
import qsStringify from "qs-stringify";
import { useAvailableContent } from "queries/mindset";
import { useUserFields } from "queries/users";
import { useQuery } from "react-query";
import { downloadContent } from "utils";
import { ReportProps, ReportSelector } from "./ReportSelector";

const statuses = {
  started: "Started",
  "not-started": "Not Started",
  completed: "Completed",
  "not-available": "Not Available",
};

const cols: SortableTableColumn<ContentReportItem>[] = [
  {
    label: "Name",
    sortFormat: (x) => x.name.toLowerCase(),
    format: (x) => <UserFlag user={x} to={`/people/${x.id}`} />,
  },
  {
    label: "Status",
    name: "status",
    format: (x) => statuses[x.completion],
  },
  {
    label: "Completion Date",
    name: "dateCompleted",
    props: {
      textAlign: "right",
    },
    headingProps: {
      textAlign: "right",
    },

    format: (x) =>
      x.dateCompleted == null
        ? "N/A"
        : format(new Date(fromUnixTime(x.dateCompleted)), "yyyy-MM-dd"),
  },
];

type ContentReportProps = ReportProps & {
  contentId: string;
  filter: UserFilter;
  type: ContentType;
};

const search = (item: ContentReportItem) =>
  [item.email, item.firstName, item.lastName].join(" ");

const useContentReport = ({
  filter,
  type,
  contentId,
}: {
  filter: UserFilter;
  type: ContentType;
  contentId: string;
}) => {
  return useQuery(["completionReport", type, filter, contentId], () => {
    const filterString = qsStringify({ filter } as any);
    const t = type === "module" ? "mindset" : type;
    const url = `/api/insights/${t}/${contentId}?${filterString}`;
    const data = axios.get<ContentReportItem[]>(url).then(({ data }) => data);
    return data;
  });
};

export const ContentReport = ({
  contentId,
  filter,
  type,
  ...rest
}: ContentReportProps) => {
  const { data = [], isFetched } = useContentReport({
    filter,
    type,
    contentId,
  });
  const content = useAvailableContent();
  const userFields = useUserFields();

  const handleExport = () => {
    type Item = { groups: { id: string; name: string }[] };
    type ItemInUser = { user: Item };

    const extractGroups = (input: Item[] | ItemInUser[]) => {
      const groups = uniq(
        input
          .flatMap((x: Item | ItemInUser) =>
            "groups" in x ? x.groups : x.user.groups,
          )
          .map((x) => x.name),
      );

      return (item: Item | ItemInUser) => {
        return Object.fromEntries(
          groups.map((x, i) => [
            `group${i + 1}`,
            ("groups" in item ? item.groups : item.user.groups).some(
              (g) => g.name === x,
            )
              ? x
              : "",
          ]),
        );
      };
    };

    const groupExtractor = extractGroups(data);

    const activeModule = content.data.find((x) => x.id === contentId);
    const csvData = data.map((x) => ({
      email: x.email,
      name: x.name,
      ...groupExtractor(x),
      ...Object.fromEntries(
        userFields.data.map((uf) => {
          const v = x.userFields.find((xx) => uf.id === xx.id)?.value;

          return [
            uf.name,
            uf.type === UserFieldType.Select
              ? uf.options.find((x) => x.id === v)?.name
              : v,
          ];
        }),
      ),
      lesson: activeModule.name,
      status: x.completion,
      completionDate: x.dateCompleted
        ? format(fromUnixTime(x.dateCompleted), "yyyy-MM-dd")
        : null,
    }));

    const csv = Papa.unparse(csvData, { delimiter: ",", header: true });
    downloadContent(csv, "report.csv");
  };

  return (
    <>
      <ReportSelector
        {...rest}
        filter={filter}
        onExport={handleExport}
        showFilterButton={type === "assessment"}
      />
      <Panel>
        <Box style={{ overflowX: "auto", position: "relative" }}>
          {!isFetched && <Loader overlay />}
          <SortableTable<ContentReportItem>
            data={data}
            columns={cols}
            rowSearch={search}
            emptyMessage="No Data Available"
            paged
          />
        </Box>
      </Panel>
    </>
  );
};
