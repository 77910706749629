import {
  FullUser,
  Permission,
  UpdateUserDto,
  UserDisplay,
  UserField,
  UserGroup,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  CheckBox,
  Flex,
  FormInput,
  IconButton,
  IconToast,
  Loader,
  Panel,
  PanelInset,
  Select,
  Table,
  Text,
  useToast,
} from "@coaching-culture/ui";

import Axios from "axios";
import CenterColumn from "components/CenterColumn";
import { UserSelectModal } from "components/FormUserSelect";
import { PageHeader } from "components/PageHeader";
import UserFieldInput from "components/UserFieldInput";
import { useCreateUser, useOrgUsers } from "queries/users";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaThumbsUp, FaTrash } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";

const GroupTable = styled(Table)`
  td:first-child {
    font-weight: 600;
    color: ${(props) => props.theme.colors.grey1};
  }

  th {
    color: ${(props) => props.theme.colors.body};
  }
`;

const NEWAPI = true;

type Params = {
  id: string;
};

type PostUserForm = {
  firstName: string;
  lastName: string;
  email: string;
  sendEmail: boolean;
  permissions: {
    [key: string]: boolean;
  };
  userFields: {
    [key: number]: string;
  };
};

const levelOptions = [
  {
    value: "",
    label: "Not a member",
  },
  {
    value: "0",
    label: "Invited to group",
  },
  {
    value: "1",
    label: "Member",
  },
  {
    value: "2",
    label: "Admin",
  },
  {
    value: "3",
    label: "Owner",
  },
];

type GroupMembership = {
  id: string;
  name: string;
  level: number;
};

export default function EditUser() {
  const match = useRouteMatch<Params>();
  const id = match.params.id;
  const isEditing = id !== "create";
  const [perms, setPerms] = useState<Permission[]>([]);
  const [userFields, setUserFields] = useState<UserField[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [userPerms, setUserPerms] = useState<string[]>([]);
  const [managers, setManagers] = useState<UserDisplay[]>([]);
  const [directReports, setDirectReports] = useState<UserDisplay[]>([]);
  const [activeGroups, setActiveGroups] = useState<GroupMembership[]>([]);
  const [addingManager, setAddingManager] = useState(false);
  const [addingDirectReport, setAddingDirectReport] = useState(false);
  const history = useHistory();
  const createUser = useCreateUser();
  const pushToast = useToast();
  const { data: users, isFetched } = useOrgUsers();
  const { errors, setError, reset, handleSubmit, register, control, watch } =
    useForm<PostUserForm>();

  useEffect(() => {
    Axios.get("/api/groups").then(({ data }) => setGroups(data));
  }, []);

  useEffect(() => {
    const p = [];

    p.push(
      Axios.get<Permission[]>("/api/permissions").then(({ data }) =>
        setPerms(data),
      ),
    );
    p.push(
      Axios.get<UserField[]>("/api/user-fields").then(({ data }) =>
        setUserFields(data),
      ),
    );

    if (id !== "create") {
      p.push(
        Axios.get<FullUser>(`/api/v2/users/${id}`).then(({ data }) => {
          const user: PostUserForm = {
            ...data,
            sendEmail: id === "create",
            permissions: data.permissions.reduce((acc, val) => {
              acc[val] = true;
              return acc;
            }, {}),
            userFields: data.userFields.reduce((acc, val) => {
              acc[val.id] = val.value;
              return acc;
            }, {}),
          };
          setActiveGroups(data.groups);
          setManagers(data.managers);
          setDirectReports(data.directReports);
          setUserPerms(data.permissions);
          reset(user);
        }),
      );
    } else {
      setActiveGroups([]);
    }

    Promise.all(p).then(() => setLoading(false));
  }, [id, reset]);

  const onSubmit = (values: PostUserForm) => {
    const spec: UpdateUserDto = {
      ...values,
      sendWelcomeEmail: values.sendEmail ? "platform" : "none",
      permissions: Object.entries(values.permissions)
        .filter((x) => x[1])
        .map((x) => x[0]),
      userFields: Object.entries(values.userFields ?? {})
        .filter((x) => x[0] != null)
        .map((x) => ({
          id: x[0],
          value: x[1],
        })),
      groups: activeGroups.map((x) => ({
        id: x.id,
        membershipLevel: ["invited", "member", "admin", "owner"][x.level],
        level: x.level,
      })),
      managers: managers.map((x) => x.id),
      directReports: directReports.map((x) => x.id),
    };

    if (isEditing && spec.sendWelcomeEmail === "platform") {
      if (
        !window.confirm("This will reset this user's password. Are you sure?")
      ) {
        return;
      }
    }

    setSubmitting(true);
    let p: Promise<any>;

    if (isEditing) {
      p = Axios.put(`/api/v2/users/${id}`, spec);
    } else {
      if (NEWAPI) {
        p = createUser.mutateAsync({
          firstName: spec.firstName,
          lastName: spec.lastName,
          email: spec.email,
          permissions: spec.permissions,
          sendWelcomeEmail: spec.sendWelcomeEmail,
          userFields: spec.userFields,
          groups: spec.groups,
          managers: spec.managers.map((x) => x),
          directReports: spec.directReports.map((x) => x),
        });
      } else {
        p = Axios.post(`/api/users`, spec);
      }
    }

    p.then(() => {
      history.push("/success/people/users");
    }).catch((err) => {
      if (err.response?.status === 409) {
        setError("email", {
          type: "server",
          message: "Email taken",
        });
      } else {
        window.alert("There was an error");
      }
      setSubmitting(false);
    });
  };

  const clearData = () => {
    if (
      window.confirm(
        "Are you sure you want to clear all module data from this user?",
      )
    ) {
      Axios.put(`/api/users/${id}/clear-data`).then(() => {
        pushToast({
          content: (
            <IconToast
              icon={FaThumbsUp}
              text="Data cleared!"
              iconColor="positive"
            />
          ),
        });
      });
    }
  };

  const deleteManager = (id: string) => {
    setManagers(managers.filter((x) => x.id !== id));
  };

  const deleteDirectReport = (id: string) => {
    setDirectReports(directReports.filter((x) => x.id !== id));
  };

  const setGroupLevel = (id: string, level: string) => {
    if (level === "") {
      setActiveGroups(activeGroups.filter((x) => x.id !== id));
    } else {
      const gs = [...activeGroups];
      let g = gs.find((x) => x.id === id);
      if (g == null) {
        const gg = groups.find((x) => x.id === id);
        g = { id: gg.id, name: gg.name, level: parseInt(level, 10) };
        gs.push(g);
      }
      g.level = parseInt(level, 10);
      setActiveGroups(gs);
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text={isEditing ? "Edit User" : "Create User"}
        subtitle="Edit a user's personal configuration"
      />
      {loading || !isFetched ? (
        <Loader />
      ) : (
        <Panel p={3}>
          {submitting && <Loader overlay />}

          <UserSelectModal
            isOpen={addingManager}
            onSelect={(user) => {
              setManagers([...managers, user]);
              setAddingManager(false);
            }}
            options={users
              .filter(
                (user) =>
                  !directReports.some((x) => x.id === user.id) &&
                  user.id !== id &&
                  !managers.some((x) => x.id === user.id),
              )
              .map((user) => ({
                id: user.id,
                profileImage: user.profileImage,
                name: user.name,
                email: user.email,
              }))}
            onCancel={() => setAddingManager(false)}
          />

          <UserSelectModal
            isOpen={addingDirectReport}
            onSelect={(user) => {
              setDirectReports([...directReports, user]);
              setAddingDirectReport(false);
            }}
            options={users
              .filter(
                (user) =>
                  !managers.some((x) => x.id === user.id) &&
                  user.id !== id &&
                  !directReports.some((x) => x.id === user.id),
              )
              .map((user) => ({
                id: user.id,
                profileImage: user.profileImage,
                name: user.name,
                email: user.email,
              }))}
            onCancel={() => setAddingDirectReport(false)}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Text fontSize={4} mb={2} fontWeight={600}>
              Details
            </Text>
            <FormInput
              label="Email Address"
              name="email"
              error={errors.email}
              type="email"
              ref={register({ required: true })}
            />
            <FormInput
              label="First Name"
              name="firstName"
              error={errors.firstName}
              ref={register({ required: true })}
            />
            <FormInput
              label="Last Name"
              name="lastName"
              error={errors.lastName}
              ref={register({ required: true })}
            />
            <Controller
              control={control}
              name="sendEmail"
              defaultValue={!isEditing}
              render={({ ref, ...props }) => (
                <Box mb={2}>
                  <CheckBox {...props} label="Send Welcome Email" mb={1} />
                  {isEditing ? (
                    <Text fontSize={2}>
                      If this is checked, the users password will be reset and
                      they will be sent a welcome email
                    </Text>
                  ) : (
                    <Text fontSize={2}>
                      If this is checked, the user will receive a temporary
                      password in their email.
                      <br />
                      If it is NOT checked then the user will be unable to log
                      in until a welcome email is sent.
                    </Text>
                  )}
                </Box>
              )}
            />
            <Text fontSize={4} mb={2} mt={4} fontWeight={600}>
              Permissions
            </Text>
            <Box mb={3}>
              {perms.map((x) => (
                <Controller
                  key={x.key}
                  control={control}
                  name={`permissions[${x.key}]`}
                  defaultValue={false}
                  render={({ value, onChange, onBlur }) => (
                    <Box mb={2}>
                      <CheckBox
                        value={
                          ["manager", "user-admin"].includes(x.key)
                            ? watch("permissions[org-admin]") || value
                            : value
                        }
                        disabled={
                          ["manager", "user-admin"].includes(x.key)
                            ? watch("permissions[org-admin]")
                            : false
                        }
                        onChange={(val) => {
                          onChange(val);
                          if (val) {
                            setUserPerms([...userPerms, x.key]);
                          } else {
                            setUserPerms(userPerms.filter((y) => y !== x.key));
                          }

                          onBlur();
                        }}
                        label={x.label}
                        mb="3px"
                      />
                      <Text fontSize={2}>{x.description}</Text>
                    </Box>
                  )}
                />
              ))}
            </Box>
            {userFields.length > 0 && (
              <>
                <Text fontSize={4} mb={2} mt={4} fontWeight={600}>
                  User Fields
                </Text>
                <Box mb={3}>
                  {userFields.map((x) => (
                    <Controller
                      control={control}
                      name={`userFields[${x.id}]`}
                      defaultValue={""}
                      key={x.id}
                      render={({ value, onChange }) => (
                        <UserFieldInput
                          isFilter={false}
                          field={x}
                          value={value}
                          onChange={onChange}
                          error={null}
                        />
                      )}
                    />
                  ))}
                </Box>{" "}
              </>
            )}
            {groups.length > 0 && (
              <>
                <Text fontSize={4} mb={2} mt={4} fontWeight={600}>
                  Groups
                </Text>
                <GroupTable mb={3}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th style={{ width: 250 }}>Membership Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.map((x) => (
                      <tr key={x.id}>
                        <td>{x.name}</td>
                        <td>
                          <Select
                            options={levelOptions}
                            value={
                              activeGroups
                                .find((g) => g.id === x.id)
                                ?.level.toString() ?? ""
                            }
                            onChange={(ev) =>
                              setGroupLevel(x.id, ev.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </GroupTable>
              </>
            )}

            <>
              <Flex alignItems={"flex-start"} justifyContent={"space-between"}>
                <Text fontSize={4} mb={2} mt={4} fontWeight={600}>
                  Managers
                </Text>

                <Button
                  justifyContent={"flex-end"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAddingManager(true);
                  }}
                >
                  Add Manager
                </Button>
              </Flex>
              <GroupTable mb={3}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th style={{ width: 50 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {managers.map((x) => (
                    <tr key={x.id}>
                      <td>{x.name}</td>
                      <td>
                        <IconButton
                          onClick={() => deleteManager(x.id)}
                          icon={FaTrash}
                          color={"danger"}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </GroupTable>
            </>
            {userPerms.includes("manager") && (
              <>
                <Flex
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                >
                  <Text fontSize={4} mb={2} mt={4} fontWeight={600}>
                    Direct Reports
                  </Text>

                  <Button
                    justifyContent={"flex-end"}
                    onClick={(e) => {
                      e.preventDefault();
                      setAddingDirectReport(true);
                    }}
                  >
                    Add Direct Report
                  </Button>
                </Flex>
                <GroupTable mb={3}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th style={{ width: 50 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {directReports.map((x) => (
                      <tr key={x.id}>
                        <td>{x.name}</td>
                        <td>
                          <IconButton
                            onClick={() => deleteDirectReport(x.id)}
                            icon={FaTrash}
                            color={"danger"}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </GroupTable>
              </>
            )}

            {id !== "create" && (
              <>
                <Text fontSize={4} mb={2} mt={3} fontWeight={600}>
                  Actions
                </Text>
                <PanelInset p={2} mb={3}>
                  <Flex alignItems="center">
                    <Button onClick={clearData} color="danger" type="button">
                      Clear Data
                    </Button>
                    <Text ml={2} fontWeight={500}>
                      Clear all content attempts for user (Non-reversible)
                    </Text>
                  </Flex>
                </PanelInset>
              </>
            )}
            <Button type="submit" color="primary" mr={2} disabled={submitting}>
              Submit
            </Button>
          </form>
        </Panel>
      )}
    </CenterColumn>
  );
}
