import {
  Box,
  Button,
  Circle,
  ControlLabel,
  Flex,
  Modal,
  Text,
} from "@coaching-culture/ui";
import {
  format,
  formatDistanceToNow,
  getWeek,
  isThisWeek,
  isToday,
  isTomorrow,
} from "date-fns";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { SpaceProps } from "styled-system";
import { CustomCalendarComponent } from "./CalendarAltStyle";

type FormDateSelectProps = {
  label?: string;
  value: Date | null;
  onChange: (val: Date | null) => void;
  minDate?: Date;
  required?: boolean;
  disabled?: boolean;
  buttonText?: string;
  allowClear?: boolean;
  showTime?: boolean;
  onCalendarClose?: () => void;
  error?: any | null;
} & SpaceProps;

const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error;
  }

  if (error.message) {
    return error.message;
  }

  if (error.type === "minLength") {
    return "Value too short.";
  } else if (error.type === "required") {
    return "Value is required";
  }

  return "Invalid value";
};

const humanizeDateDistance = (value: Date, includeTime: boolean) => {
  const time = includeTime ? `@ ${format(value, "p")}` : "";
  if (isTomorrow(value)) {
    return `Tomorrow ${time}`;
  } else if (isToday(value)) {
    return `Today ${time}`;
  } else if (isThisWeek(value)) {
    return `${format(value, "EEEE")} ${time}`;
  } else if (getWeek(value) - getWeek(new Date()) === 1) {
    return `Next ${format(value, "EEEE")} ${time}`;
  } else {
    return formatDistanceToNow(value, { addSuffix: true });
  }
};

export function FormDateSelect({
  label,
  value,
  onChange,
  minDate,
  required,
  onCalendarClose,
  disabled,
  allowClear,
  buttonText = "Change",
  showTime,
  error,
  ...rest
}: FormDateSelectProps) {
  const [pickingDate, setPickingDate] = useState(false);

  return (
    <>
      {pickingDate && (
        <Modal onClose={() => setPickingDate(false)} p={3}>
          <CustomCalendarComponent>
            <ReactDatePicker
              selected={value}
              disabled={disabled}
              onCalendarClose={onCalendarClose}
              onChange={(val) => {
                onChange(val);
              }}
              showTimeSelect={showTime}
              timeIntervals={15}
              minDate={minDate}
              calendarStartDay={1}
              inline
            />
          </CustomCalendarComponent>
          <Flex justifyContent={"center"} mt={2}>
            <Button onClick={() => setPickingDate(false)}>Close</Button>
          </Flex>
        </Modal>
      )}
      <Box mb={2} {...rest}>
        <ControlLabel required={required}>{label}</ControlLabel>
        <Flex
          p={2}
          alignItems={"center"}
          border={1}
          borderRadius={6}
          justifyContent={"space-between"}
        >
          <Flex alignItems="center">
            <Circle
              icon={FaCalendar}
              size={"large"}
              color={value == null ? "grey3" : "primary"}
              mr={2}
            />
            <div>
              <Text fontWeight={500}>
                {value == null
                  ? "Select Date"
                  : format(value, showTime ? "do MMMM yyyy p" : "do MMMM yyyy")}
              </Text>
              <Text fontWeight={500} fontSize={12} color="grey2">
                {value == null
                  ? "Click to change the date"
                  : humanizeDateDistance(value, showTime)}
              </Text>
            </div>
          </Flex>
          {value != null && allowClear ? (
            <Button
              color="primary"
              onClick={() => onChange(null)}
              type="button"
              aria-label={"Clear Date"}
            >
              Clear
            </Button>
          ) : (
            <Button
              aria-label={"Change Date"}
              color="primary"
              type="button"
              onClick={() => setPickingDate(true)}
            >
              Change
            </Button>
          )}
        </Flex>
        {error && (
          <Text color="danger" fontSize={2} mt={1}>
            {getErrorMessage(error)}
          </Text>
        )}
      </Box>
    </>
  );
}
