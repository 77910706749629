import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as pendo from "./setupPendo";

declare global {
  namespace NodeJS {
    export interface ProcessEnv {
      readonly NODE_ENV: "development" | "production" | "test";
      readonly REACT_APP_STATIC_ROOT: string;
      readonly REACT_APP_PLATFORM_ROOT: string;
      readonly REACT_APP_ENABLE_DOWNTIME: string;
    }
  }
  interface Window {
    pendo: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//Setup pendo
pendo.Setup();
