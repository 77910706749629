import { lighten } from "polished";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { FaEllipsisV } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { IconButton, IconButtonProps } from "./IconButton";
import Panel from "./Panel";

type DotMenuItem = {
  label: string;
  icon?: IconType;
  color?: string;
  disabled?: boolean;
  cmd?: string;
};

type DotMenuProps = {
  items: DotMenuItem[];
  onItemClick: (idx: number, cmd: string | undefined) => void;
};

type ItemListProps = {
  items: DotMenuItem[];
  onItemSelect: (idx: number, cmd: string | undefined) => any;
};

const Container = styled.div`
  position: fixed;
`;

const ItemListContainer = styled(animated(Panel))`
  position: absolute;
  top: calc(100% + 16px);
  width: 240px;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

const Item = styled.button<{ color: string }>`
  background: none;
  border: 0;
  padding: 6px 12px;
  text-align: left;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${(props) => lighten(0.2, props.theme.colors[props.color])};

  & svg {
    margin-right: 6px;
  }

  &:not([disabled]) {
    cursor: pointer;

    &:hover {
      color: ${(props) =>
        props.theme.colors[props.color] ?? props.theme.colors.body};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 26,
};

const ItemList = ({ items, onItemSelect }: ItemListProps) => {
  const styles = useSpring({
    config: springConfig,
    from: {
      y: -10,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
  });

  return (
    <ItemListContainer style={styles} p={1} border={1} boxShadow={1}>
      {items.map(({ label, cmd, icon: Icon, color = "body", disabled }, i) => (
        <Item
          key={i}
          color={color}
          disabled={disabled}
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            onItemSelect(i, cmd);
          }}
        >
          {Icon && <Icon />}
          {label}
        </Item>
      ))}
    </ItemListContainer>
  );
};

function MenuPortal({ children }: { children: React.ReactNode }) {
  const modalEl = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    const root = document.getElementById("root");
    const el = modalEl.current;
    root?.parentNode?.appendChild(el);

    return () => {
      root?.parentNode?.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(children, modalEl.current);
}

const DotMenu = ({
  items,
  onItemClick,
  ...rest
}: DotMenuProps & Pick<IconButtonProps, "buttonStyle" | "color">) => {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState<[number, number]>([0, 0]);
  const ref = useRef<HTMLDivElement>(null!);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const openMenu = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    const rect = (ev.target as HTMLButtonElement).getBoundingClientRect();
    setPos([rect.x, rect.y]);
    setOpen(true);
  };

  if (items == null || items.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton
        icon={FaEllipsisV}
        onClick={openMenu}
        color="body"
        {...rest}
      />
      {open && (
        <MenuPortal>
          <Container ref={ref} style={{ left: pos[0], top: pos[1] }}>
            <ItemList
              items={items}
              onItemSelect={(idx, cmd) => {
                setOpen(false);
                onItemClick(idx, cmd);
              }}
            />
          </Container>
        </MenuPortal>
      )}
    </>
  );
};

export default DotMenu;
