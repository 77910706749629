import {
  CampaignSpec,
  FeedbackCampaign,
  FeedbackResultsVisibility,
  UserDisplay,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  CheckBox,
  ConfirmationModal,
  ControlLabel,
  Flex,
  FormInput,
  FormInputWrap,
  FormTextArea,
  IconButton,
  ImageUploader,
  Input,
  Label,
  Loader,
  MultiToggle,
  Panel,
  RichTextEditor,
  Rule,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { isEmpty, uniqBy } from "lodash";
import { useUpdateFeedbackCampaign } from "queries/feedback";
import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaTrash, FaUser } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { UserSelectModal } from "./UserSelectModal";

const emailMergeFields = [
  {
    description: "User's First Name",
    value: "*|FNAME|*",
  },
  {
    description: "User's Last Name",
    value: "*|LNAME|*",
  },
  {
    description: "User's Email",
    value: "*|EMAIL|*",
  },
  {
    description: "Platform URL",
    value: "https://platform.coachingculture.com",
  },
];

const respondentMergeFields = [
  {
    description: "Senders Name",
    value: "*|SENDERS_NAME|*",
  },
];

export function EditFeedbackCampaign() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const updateCampaign = useUpdateFeedbackCampaign(id);

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [allowSpecificUsers, setAllowSpecificUsers] = useState<boolean>(false);
  const [collectRelationship, setCollectRelationship] =
    useState<boolean>(false);
  const [addingViewers, setAddingViewers] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [spec, setSpec] = useState<Partial<CampaignSpec>>(null);

  useEffect(() => {
    axios
      .get<FeedbackCampaign>(`/api/feedback/campaigns/${id}`)
      .then(({ data }) => {
        setSpec({
          name: data.name,
          description: data.description,
          image: data.thumbnail,
          feedbackFormId: data.formId,
          minimumForwards: data.minimumForwards,
          resultsVisibility: data.resultsVisibility,
          anonType: data.anonType,
          users: data.users.map((x) => x.id),
          email: data.userEmailTemplate,
          emailSubject: data.userEmailSubject,
          respondentEmail: data.respondentEmailTemplate,
          respondentEmailSubject: data.respondentEmailSubject,
          relationships: data.relationships,
          viewers: data.viewers,
          sendEmail: data.sendEmail,
          isDraft: data.isDraft,
        });
        setCollectRelationship(data.relationships.length > 0);
      });
  }, [id]);

  if (spec == null) {
    return <Loader />;
  }

  const getErrors = () => {
    const errors = {};

    const required: (keyof CampaignSpec)[] = ["name", "resultsVisibility"];

    for (const r of required) {
      if (spec[r] == null || spec[r] === "") {
        errors[r] = "Value is required";
      }
      if (r === "resultsVisibility") {
        if ((spec[r] as Array<string>).length === 0) {
          errors[r] = "At least 1 option must be selected";
        }
      }
    }
    return errors;
  };

  const submit = (isDraft: boolean) => {
    const e = getErrors();
    setErrors(e);
    spec.isDraft = isDraft;
    if (isEmpty(e)) {
      updateCampaign.mutateAsync(spec).then(() => {
        history.push(`/success/feedback/campaigns`);
      });
    }
  };

  const setValue = (key: keyof CampaignSpec) => (val: any) => {
    setSpec((old) => ({
      ...old,
      [key]: val,
    }));
    setErrors((old) => ({
      ...old,
      [key]: null,
    }));
  };

  const setVisibility = (name: FeedbackResultsVisibility) => (val: boolean) => {
    setSpec((old) => ({
      ...old,
      resultsVisibility: val
        ? old.resultsVisibility.concat([name])
        : old.resultsVisibility.filter((x) => x !== name),
    }));
    setErrors((old) => ({
      ...old,
      resultsVisibility: null,
    }));
  };

  const setRelationship =
    (idx: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      const v = ev.target.value;
      setSpec((old) => ({
        ...old,
        relationships: old.relationships.map((x, i) =>
          i === idx ? { name: v, id: "" } : x
        ),
      }));
    };

  const removeRelationship = (idx: number) => {
    setSpec((old) => ({
      ...old,
      relationships:
        old.relationships.length === 1
          ? null
          : old.relationships.filter((x, i) => i !== idx),
    }));
  };

  const addRelationship = () => {
    setSpec((old) => ({
      ...old,
      relationships: [...old.relationships, { name: "", id: "" }],
    }));
  };

  const setSpecificUsers = (val: boolean) => {
    if (!val) {
      setValue("viewers")([]);
    }
    setAllowSpecificUsers(val);
  };

  const addViewers = (users: UserDisplay[]) => {
    if (users) {
      setValue("viewers")(
        uniqBy(users.map((x) => x).concat(spec.viewers.map((x) => x)), "id")
      );
    }
    setAddingViewers(false);
  };

  const removeViewer = (id: string) => {
    setValue("viewers")(spec.viewers.filter((x) => x.id !== id));
  };

  return (
    <>
      {addingViewers && <UserSelectModal onSelect={addViewers} />}

      <ConfirmationModal
        isOpen={showConfirm}
        onCancel={() => setShowConfirm(false)}
        buttons={[
          {
            text: "Yes",
            color: "danger",
            onClick: () => {
              setValue("relationships")([]);
              setShowConfirm(false);
              setCollectRelationship(false);
            },
          },
        ]}
        modalText={
          "Changing this setting will remove all current relationships. Are you sure you want to do this?"
        }
      />

      <CenterColumn>
        <PageHeader
          text={"Edit Feedback Campaign"}
          subtitle="Change settings for an existing feedback campaign."
          backUrl="/success/feedback/campaigns"
        />
        <Panel p={3}>
          <>
            {spec.isDraft && (
              <Text mb={2} fontWeight={600} color={"warning"}>
                This campaign is currently a draft and has not been sent to
                users
              </Text>
            )}
            <FormInput
              required
              name="name"
              label="Campaign Name"
              width="500px"
              error={errors.name}
              value={spec.name}
              onChange={(ev) => setValue("name")(ev.target.value)}
            />
            <FormTextArea
              name="description"
              height="150px"
              label="Description"
              value={spec.description}
              onChange={(ev) => setValue("description")(ev.target.value)}
            />
            <FormInputWrap label="Image (Optional)">
              <ImageUploader
                value={spec.image}
                onChange={setValue("image")}
                width={320}
                height={180}
              />
            </FormInputWrap>
            <MultiToggle
              disabled={true}
              items={["Anonymous", "Transparent"]}
              active={spec.anonType === "anonymous" ? 0 : 1}
              mb={1}
              onSelect={() => {}}
            />
            <FormInput
              mb={2}
              disabled
              label="Minimum Respondents"
              width="300px"
              value={spec.minimumForwards}
            />
            <CheckBox
              label="Collect Respondent Relationship"
              mb={1}
              value={collectRelationship}
              onChange={(val) => {
                if (!val && spec.relationships.length > 0) {
                  setShowConfirm(true);
                } else {
                  setCollectRelationship(val);
                }
              }}
            />
            <Flex alignItems="center" mb={2}>
              <FaInfoCircle />
              <Text fontSize={3} color="grey1" ml={1}>
                Collect how a respondent is related to the user.
              </Text>
            </Flex>
            {collectRelationship && (
              <Box mb={3}>
                <ControlLabel>Available Relationships</ControlLabel>
                <Flex flexDirection="column">
                  {spec.relationships.map((x, i) => (
                    <Flex alignItems="center" mb={1}>
                      <Input
                        value={x.name}
                        onChange={setRelationship(i)}
                        width={500}
                      />
                      <IconButton
                        color="danger"
                        icon={FaTrash}
                        onClick={() => removeRelationship(i)}
                        ml={1}
                      />
                    </Flex>
                  ))}
                  <AddButton width={500} p={1} onClick={addRelationship}>
                    Add Relationship
                  </AddButton>
                </Flex>
              </Box>
            )}
            <ControlLabel mb={2}>Who can see the results?</ControlLabel>
            <CheckBox
              label="The User"
              mb={1}
              value={spec.resultsVisibility.includes("self")}
              onChange={setVisibility("self")}
            />
            <CheckBox
              label="Their Line Manager"
              mb={1}
              value={spec.resultsVisibility.includes("manager")}
              onChange={setVisibility("manager")}
            />
            <CheckBox
              label="Organisation Admins"
              mb={1}
              value={spec.resultsVisibility.includes("admin")}
              onChange={setVisibility("admin")}
            />
            <CheckBox
              label="Specific Users"
              mb={1}
              value={allowSpecificUsers || spec.viewers.length > 0}
              onChange={setSpecificUsers}
            />
            {errors.resultsVisibility && (
              <Text mt={1} color="danger">
                {errors.resultsVisibility}
              </Text>
            )}
            {(allowSpecificUsers || spec.viewers.length > 0) && (
              <>
                <Flex flexWrap="wrap" style={{ gap: 3 }} mb={2} mt={2}>
                  {spec.viewers.map((x) => (
                    <Label icon={FaUser} onRemove={() => removeViewer(x.id)}>
                      {x.name}
                    </Label>
                  ))}
                  {spec.viewers.length === 0 && (
                    <Text color="grey2">No Users</Text>
                  )}
                </Flex>

                <Button
                  mb={2}
                  width="300px"
                  onClick={() => setAddingViewers(true)}
                >
                  Add Users
                </Button>
              </>
            )}

            <Rule />
            {spec.sendEmail && (
              <>
                <Text fontSize={4}>User Email Template</Text>
                <Text color="grey1" mb={3}>
                  This is the email that will be sent to your users.
                </Text>
                <FormInput
                  name="subject"
                  label="Email Subject"
                  value={spec.emailSubject}
                  onChange={(ev) => setValue("emailSubject")(ev.target.value)}
                />
                <RichTextEditor
                  value={spec.email}
                  onChange={setValue("email")}
                  mergeFields={emailMergeFields}
                />
              </>
            )}
            <Text mt={2} fontSize={4}>
              Respondent Email Template
            </Text>
            <Text color="grey1" mb={3}>
              This is the email that will be sent to the users respondents.
            </Text>
            <FormInput
              name="subject"
              label="Email Subject"
              value={spec.respondentEmailSubject}
              onChange={(ev) =>
                setValue("respondentEmailSubject")(ev.target.value)
              }
            />
            <RichTextEditor
              value={spec.respondentEmail}
              onChange={setValue("respondentEmail")}
              mergeFields={respondentMergeFields}
            />
          </>

          <Flex mt={2}>
            <Button color="positive" onClick={() => submit(false)}>
              {spec.isDraft ? "Save & Send" : "Save"}
            </Button>
            {spec.isDraft && (
              <Button ml={2} color="positive" onClick={() => submit(true)}>
                Save as Draft
              </Button>
            )}
            <Button ml={2} color="warning" to="/success/feedback/campaigns">
              Cancel
            </Button>
          </Flex>
        </Panel>
      </CenterColumn>
    </>
  );
}
