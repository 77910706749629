import { Button, Flex, RawContent, Text } from "@coaching-culture/ui";
import { animated, useTrail } from "react-spring";

export type ContentProps = {
  index: number;
  count: number;
  title: string;
  content: string;
  onConfirm: () => void;
  reverse: boolean;
  sectionName: string | null;
};

export const Content = ({
  index,
  count,
  title,
  content,
  onConfirm,
  reverse,
  sectionName,
}: ContentProps) => {
  let trail = useTrail(3, {
    from: {
      y: 100 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex flexDirection="column" mb={3}>
      <animated.div style={trail[0]}>
        <Text fontWeight={600} color="grey2" fontSize={2}>
          {sectionName ? `${sectionName} - ` : ""} {index + 1} / {count}
        </Text>
        <Text mb={[2, 3]} fontSize={4} fontWeight={600}>
          {title}
          &nbsp;
        </Text>
      </animated.div>
      <animated.div style={trail[1]}>
        <RawContent dangerouslySetInnerHTML={{ __html: content }} />
      </animated.div>
      <animated.div style={trail[2]}>
        <Flex justifyContent="center">
          <Button onClick={onConfirm} color="primary">
            Continue
          </Button>
        </Flex>
      </animated.div>
    </Flex>
  );
};
