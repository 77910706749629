import React from "react";
import styled from "styled-components";
import { Text } from "@coaching-culture/ui";

import catSrc from "../../img/cat.jpg";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  flex: 1;
`;

const Cat = styled.img`
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  max-width: 100%;
  width: 500px;
`;

const NotFound = () => {
  return (
    <Container>
      <Text fontSize={7} mb={1} fontWeight={600}>
        Not Found
      </Text>
      <Text mb={4}>
        You've found a place that doesn't exist. Here's a picture of a cat.
      </Text>
      <Cat src={catSrc} />
    </Container>
  );
};

export default NotFound;
