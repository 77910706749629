import { ProductIdent, ProductVisibility } from "@coaching-culture/types";
import { Grid } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { ActionsWidget } from "./Widgets/ActionsWidget";
import { ConversationsWidget } from "./Widgets/ConversationsWidget";
import { FeedbackWidget } from "./Widgets/FeedbackWidget";
import { GoalWidget } from "./Widgets/GoalsWidget";
import { LessonModuleWidget } from "./Widgets/LessonModuleWidget";
import { TeamGoalWidget } from "./Widgets/TeamGoalWidget";

export function Dashboard() {
  const products = useAllProductVisibility();

  const hasProduct = (ident: ProductIdent) => {
    return products[ident] === ProductVisibility.Enabled;
  };

  const couldHaveActions = (["360", "surveys", "performance"] as const).some(
    (x) => hasProduct(x),
  );

  return (
    <CenterColumn>
      <PageHeader text={"Dashboard"} />
      <Grid
        gridGap={[3, 3]}
        gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
        display={["block", "grid"]}
      >
        {hasProduct("performance") && (
          <>
            <ConversationsWidget />
            <GoalWidget />
            <TeamGoalWidget />
          </>
        )}
        {hasProduct("cont-feedback") && <FeedbackWidget />}
        {couldHaveActions && <ActionsWidget />}
        {hasProduct("mindset") && <LessonModuleWidget contentType="module" />}
        {hasProduct("lessons") && <LessonModuleWidget contentType="lesson" />}
        {hasProduct("assessments") && (
          <LessonModuleWidget contentType="assessment" />
        )}
        {hasProduct("learncast") && (
          <LessonModuleWidget contentType="learncast" />
        )}
      </Grid>
    </CenterColumn>
  );
}
