import { useUpdateContentAccess } from "@coaching-culture/api-client";
import {
  ContentAccessConditionDto,
  ContentAccessDto,
  ContentType,
  ProductVisibility,
  UpdateContentAccessSpec,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  CheckBox,
  Circle,
  Flex,
  FormToggle,
  IconButton,
  Loader,
  Panel,
  PanelInset,
  Table,
  Text,
} from "@coaching-culture/ui";
import { useOrg } from "auth/OrgProvider";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import {
  useAllProductVisibility,
  useProductVisibility,
} from "hooks/useProductVisibility";
import { groupBy, sortBy } from "lodash";
import { useContentAccessSettings } from "queries/orgs";
import { useUserFields } from "queries/users";
import React, { useEffect, useState } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { Redirect, useHistory, useParams } from "react-router";
import {
  UserFieldCondition,
  UserFieldConditionModal,
} from "./UserFieldConditionModal";

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const ContentConfiguration = () => {
  const [org] = useOrg();
  const { data } = useContentAccessSettings();
  const { data: userFields } = useUserFields();
  const [editingConditions, setEditingConditions] = useState<string | null>(
    null,
  );
  const [config, setConfig] = useState<ContentAccessDto[] | null>(null);
  const [allowSuggestedContent, setAllowSuggestedContent] = useState<boolean>(
    org.allowSuggestedContent,
  );
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  const productAccess = useAllProductVisibility();
  const updateContent = useUpdateContentAccess();

  const hasProductAccess =
    useProductVisibility(type) === ProductVisibility.Enabled;

  const contentType: ContentType =
    type === "mindset"
      ? "module"
      : type === "lessons"
        ? "lesson"
        : type === "assessments"
          ? "assessment"
          : type === "learncast"
            ? "learncast"
            : null;

  const loading = config == null;

  useEffect(() => {
    setConfig(null);

    if (data != null) {
      setConfig(data.filter((x) => x.content.type === contentType));
    }
  }, [data, contentType]);

  if (type == null) {
    const firstAvailable = [
      "mindset",
      "lessons",
      "assessments",
      "learncast",
    ].find((x) => productAccess[x] === ProductVisibility.Enabled);
    if (firstAvailable != null) {
      return <Redirect to={`/success/settings/content/${firstAvailable}`} />;
    }
  }

  if (!hasProductAccess) {
    return <Redirect to="/" />;
  }

  const formatRoleText = (
    condition: ContentAccessConditionDto,
  ): React.ReactNode => {
    if (condition.type === "permission") {
      return toTitleCase(condition.permission.replace("-", " "));
    } else {
      const uf = userFields?.find(
        (x) =>
          "options" in x &&
          x.options.some((o) => o.id === condition.userFieldOptionId),
      );
      // Just incase the user fields havent loaded yet or are refreshing
      if (uf == null) return null;
      if ("options" in uf) {
        const opt = uf.options.find(
          (x) => x.id === condition.userFieldOptionId,
        );
        return (
          <Text>
            <strong>{uf.name}</strong> is equal to <strong>{opt.name}</strong>
          </Text>
        );
      }
    }
  };

  const setPublic = (ids: string[]) => (value: boolean) => {
    setConfig((old) =>
      old.map((x) => ({
        ...x,
        public: ids.includes(x.content.id) ? value : x.public,
      })),
    );
  };

  const save = async () => {
    const data: UpdateContentAccessSpec = {
      content: config.map((x) => ({
        id: x.content.id,
        type: x.content.type,
        public: x.public,
        conditions: x.conditions,
      })),
    };

    if (contentType === "assessment") {
      data.allowSuggestedContent = allowSuggestedContent;
    }

    await updateContent.mutateAsync({ orgId: org.id, ...data });

    history.push("/success");
  };

  const cats = Object.entries(groupBy(config ?? [], "content.category.id")).map(
    ([cat, items]) => ({
      name: items[0].content.category.name,
      items,
    }),
  );

  const addCondition = (value: UserFieldCondition) => {
    const item = config.find((x) => x.content.id === editingConditions);
    setEditingConditions(null);

    const newOptions = value.userFieldOptionIds.filter(
      (f) =>
        !item.conditions.some(
          (x) => x.type === "userfield" && x.userFieldOptionId === f,
        ),
    );

    item.conditions.push(
      ...newOptions.map((x) => ({
        type: "userfield" as const,
        userFieldOptionId: x,
        canView: true,
        canShare: false,
      })),
    );

    setConfig([...config]);
  };

  const setCanView = (contentId: string, idx: number) => (val: boolean) => {
    const item = config.find((x) => x.content.id === contentId);
    item.conditions[idx].canView = val;
    setConfig([...config]);
  };

  const setCanShare = (contentId: string, idx: number) => (val: boolean) => {
    const item = config.find((x) => x.content.id === contentId);
    item.conditions[idx].canShare = val;
    setConfig([...config]);
  };

  const removeCondition = (contentId: string, idx: number) => {
    const item = config.find((x) => x.content.id === contentId);
    item.conditions = item.conditions.filter((_, i) => i !== idx);
    setConfig([...config]);
  };

  return (
    <CenterColumn p={[2, 5]} pt={[5, 5]}>
      {editingConditions && (
        <UserFieldConditionModal
          fields={userFields}
          onCancel={() => setEditingConditions(null)}
          onSave={addCondition}
        />
      )}
      <PageHeader
        text="Content Configuration"
        subtitle="Change the visibility of content within the platform"
        helpIdent="content-main"
      />
      {loading ? (
        <Loader />
      ) : config.length === 0 ? (
        <Text fontSize={4} textAlign="center">
          No Content Available
        </Text>
      ) : (
        <>
          {contentType === "assessment" && (
            <FormToggle
              mb={3}
              helpText="Let's Assessments assign content to users even if they otherwise wouldn't have access to the content"
              label="Allow Suggested Content"
              value={allowSuggestedContent}
              onChange={setAllowSuggestedContent}
            />
          )}
          {cats.map((x) => (
            <Box key={x.name}>
              <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
                <Text fontWeight={500} fontSize={4} as="h3" lineHeight="1.25">
                  {x.name}
                </Text>
                <CheckBox
                  mb={0}
                  value={x.items.every((x) => x.public)}
                  onChange={setPublic(x.items.map((x) => x.content.id))}
                  label="All Public"
                />
              </Flex>
              <Panel mb={3}>
                {sortBy(x.items, (x) => x.content.orderIndex).map((x) => (
                  <Flex
                    key={x.content.id + x.content.type}
                    flexDirection="column"
                    borderBottom={1}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                      style={{ cursor: "pointer" }}
                    >
                      <Flex alignItems="center">
                        <Circle
                          mr={2}
                          image={
                            process.env.REACT_APP_STATIC_ROOT +
                            "/" +
                            x.content.icon
                          }
                        />
                        <Text fontWeight={600}>{x.content.name}</Text>
                      </Flex>
                      <CheckBox
                        label="Public Module"
                        value={x.public}
                        onChange={setPublic([x.content.id])}
                        mb={0}
                      />
                    </Flex>
                    {!x.public && (
                      <Box p={2}>
                        <PanelInset>
                          <Table>
                            <thead>
                              <tr>
                                <th>Platform Access</th>
                                <th style={{ textAlign: "center", width: 100 }}>
                                  Can View
                                </th>
                                <th style={{ textAlign: "center", width: 100 }}>
                                  Can Share
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {x.conditions.map((c, i) => (
                                <tr key={i}>
                                  <td>
                                    <Flex>
                                      <Text fontWeight={600}>
                                        {formatRoleText(c)}
                                      </Text>
                                      {c.type === "userfield" && (
                                        <IconButton
                                          ml={1}
                                          onClick={() =>
                                            removeCondition(x.content.id, i)
                                          }
                                          icon={FaTrash}
                                          color="danger"
                                        />
                                      )}
                                    </Flex>
                                  </td>
                                  <td>
                                    <Flex justifyContent={"center"}>
                                      <CheckBox
                                        value={c.canView}
                                        disabled={c.canShare}
                                        mb={0}
                                        onChange={setCanView(x.content.id, i)}
                                      />
                                    </Flex>
                                  </td>
                                  <td>
                                    <Flex justifyContent={"center"}>
                                      <CheckBox
                                        value={c.canShare}
                                        mb={0}
                                        onChange={setCanShare(x.content.id, i)}
                                      />
                                    </Flex>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={3}>
                                  <Button
                                    icon={FaPlusCircle}
                                    small
                                    onClick={() =>
                                      setEditingConditions(x.content.id)
                                    }
                                  >
                                    Add Access Condition
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </PanelInset>
                      </Box>
                    )}
                  </Flex>
                ))}
              </Panel>
            </Box>
          ))}
          <Button color="primary" onClick={save}>
            Save Changes
          </Button>
        </>
      )}
    </CenterColumn>
  );
};

export default ContentConfiguration;
