import { tint } from "polished";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { IconType } from "react-icons/lib";
import { animated, config, useSpring, useSprings } from "react-spring";
import styled, { css } from "styled-components";

export type OptionSelectValue = {
  value: string;
  label: string;
  color: string;
  icon?: IconType;
};

const labelSize = 30;
const minWidth = 150;

const Container = styled.button`
  border: 0;
  background: none;
  min-width: ${minWidth}px;
  height: ${labelSize}px;
  border-radius: 3px;
  position: relative;
  z-index: 100;
  text-align: left;
`;

const FixedContainer = styled(animated.div)`
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Label = styled(animated.div)<{
  color: string;
  disabled: boolean;
  readonly: boolean;
}>`
  height: ${labelSize}px;
  position: absolute;
  width: calc(100% - 12px);
  top: 0;
  left: 6px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding-left: 12px;
  background-color: ${(props) =>
    props.theme.colors[props.color] ?? props.color};
  user-select: none;

  ${(props) => {
    const hoverColor = tint(
      0.2,
      props.theme.colors[props.color] ?? props.color,
    );
    return !(props.disabled || props.readonly)
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${hoverColor};
          }
        `
      : "";
  }}

  & svg {
    font-size: 1.1em;
    margin-right: 6px;
  }
`;

const Backing = styled(animated.div)`
  background: white;
  box-shadow: ${(props) => props.theme.shadows[1]};
  pointer-events: none;
  border-radius: 3px;
`;

const LabelContainer = styled.div`
  position: absolute;
`;

function Portal({ children }: { children: React.ReactNode }) {
  const modalEl = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    const root = document.getElementById("root");
    const el = modalEl.current;
    root?.parentNode?.appendChild(el);

    return () => {
      root?.parentNode?.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(children, modalEl.current);
}

export type OptionSelectProps = {
  options: OptionSelectValue[];
  value: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  readonly?: boolean;
};

type PortalPosition = {
  top: number;
  left: number;
  width: number;
  open: boolean;
};

export function OptionSelect({
  value,
  options,
  onChange,
  readonly,
  disabled,
}: OptionSelectProps) {
  const [open, setOpen] = useState<PortalPosition>({
    top: 0,
    left: 0,
    width: 0,
    open: false,
  });
  const [ani, setAni] = useState<boolean>(false);
  const ref = useRef<HTMLElement>(null!);

  const valIndex = options.findIndex((x) => x.value === value);
  const isOpen = open.open;
  const activeOption = options[valIndex];

  const springs = useSprings(
    options.length,
    options.map((_, i) => ({
      y: isOpen ? (i - valIndex) * (labelSize + 6) : 0,
      onStart: () => setAni(true),
      onRest: () => setAni(false),
      config: config.stiff,
    })),
  );

  const backingSpring = useSpring({
    opacity: isOpen ? 1 : 0,
    y: -valIndex * (labelSize + 6) - 6,
    scaleY: isOpen ? 1 : 0.3333,
    config: config.stiff,
  });

  const containerSpring = useSpring({
    backgroundColor: `rgba(0,0,0,${isOpen ? 0.1 : 0})`,
  });

  const openMenu = () => {
    if (ref.current == null) {
      return;
    }

    const { top, left, width } = ref.current.getBoundingClientRect();

    setOpen({
      top,
      left,
      width,
      open: true,
    });
  };

  const closeMenu = () => {
    setAni(true);
    setOpen((old) => ({
      ...old,
      open: false,
    }));
  };

  const set = (val: number) => {
    if (disabled || readonly) return;
    closeMenu();
    if (onChange && options[val].value !== value) {
      onChange(options[val].value);
    }
  };

  return (
    <Container
      onClick={readonly || disabled || isOpen ? undefined : openMenu}
      style={{ zIndex: ani || isOpen ? 3000 : 1, opacity: disabled ? 0.5 : 1 }}
      ref={ref as any}
    >
      {open.open || ani ? (
        <Portal>
          <FixedContainer
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              closeMenu();
            }}
            style={containerSpring}
          >
            <LabelContainer
              style={{
                top: open.top,
                left: open.left,
                width: open.width,
              }}
            >
              <Backing
                style={{
                  ...backingSpring,
                  height: `${
                    labelSize * options.length + (options.length + 1) * 6
                  }px`,
                  transformOrigin:
                    valIndex === 0
                      ? "top"
                      : valIndex === 1
                        ? "center"
                        : "bottom",
                }}
              />
              {options.map(({ color, label, icon: Icon }, i) => (
                <Label
                  key={i}
                  style={{
                    ...springs[i],
                    zIndex: valIndex === i ? 1 : 0,
                  }}
                  color={color}
                  disabled={disabled ?? false}
                  readonly={false}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    set(i);
                  }}
                >
                  {Icon && <Icon />}
                  {label}
                </Label>
              ))}
            </LabelContainer>
          </FixedContainer>
        </Portal>
      ) : (
        <Label
          color={activeOption!.color}
          disabled={disabled ?? false}
          readonly={readonly ?? false}
        >
          {activeOption.label}
        </Label>
      )}
    </Container>
  );
}
