import { ToastProvider } from "@coaching-culture/ui";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { defaults } from "chart.js";
import { PendoUser } from "components/PendoUser";
import { RightSidebar } from "components/RightSidebar";
import { SafeRedirect } from "components/SafeRedirect";
import AccountSettings from "pages/AccountSettings";
import { Dashboard } from "pages/Dashboard";
import { FeedbackSurveyRoute } from "pages/Feedback";
import { HoldingPage } from "pages/HoldingPage";
import Impersonate from "pages/Impersonate";
import LearnCastList from "pages/LearnCast";
import { LearnCastPlayer } from "pages/LearnCast/LearnCastPlayer";
import LessonsList from "pages/Lessons";
import LessonsPlayer from "pages/Lessons/LessonsPlayer";
import { UserProfilePage } from "pages/People/UserProfile";
import { RedeemSso } from "pages/RedeemSso";
import { RelationshipProfile } from "pages/RelationshipProfile";
import Assessments from "pages/Solutions/Assessments";
import { AssessmentPlayer } from "pages/Solutions/Assessments/AssessmentPlayer";
import { MyContinuousFeedback } from "pages/Solutions/ContinuousFeedback/MyContinuousFeedback";
import { RequestContinuousFeedback } from "pages/Solutions/ContinuousFeedback/RequestContinuousFeedback";
import { SendContinuousFeedback } from "pages/Solutions/ContinuousFeedback/SendContinuousFeedback";
import { UserCampaign } from "pages/Solutions/Feedback/UserCampaign";
import { UserCampaigns } from "pages/Solutions/Feedback/UserCampaigns";
import { Performance } from "pages/Solutions/Performance";
import { CreateGoal } from "pages/Solutions/Performance/CreateGoal";
import { GoalReviewContainer } from "pages/Solutions/Performance/GoalReviewContainer";
import { UserGoals } from "pages/Solutions/Performance/UserGoals";
import { SurveyRoute } from "pages/Solutions/Surveys";
import { SurveyList } from "pages/Solutions/Surveys/SurveyList";
import { AddonsPage } from "pages/Success/Addons";
import { Office365Auth } from "pages/Success/Addons/Office365Auth";
import { BulkDelete } from "pages/Success/BulkDelete";
import { DownloadsList } from "pages/Success/Downloads";
import EditSelfReg from "pages/Success/EditSelfReg";
import { CreateUserField, EditUserField } from "pages/Success/EditUserField";
import Feedback from "pages/Success/Feedback";
import { Campaign } from "pages/Success/Feedback/Campaign";
import CreateCampaign from "pages/Success/Feedback/CreateCampaign";
import { EditFeedbackCampaign } from "pages/Success/Feedback/EditFeedbackCampaign";
import { EditFeedbackForm } from "pages/Success/Feedback/EditFeedbackForm";
import { Groups } from "pages/Success/Groups";
import Insights from "pages/Success/Insights";
import { MeasuresConfiguration } from "pages/Success/Measures";
import { CreateMeasure } from "pages/Success/Measures/CreateMeasure";
import { EditMeasure } from "pages/Success/Measures/EditMeasure";
import MindsetConfiguration from "pages/Success/MindsetConfiguration";
import { Performance as SuccessPerformance } from "pages/Success/Performance";
import { ConversationTypes } from "pages/Success/Performance/ConversationTypes";
import {
  CreateConversationType,
  EditConversationType,
} from "pages/Success/Performance/CreateConversationType";
import SelfReg from "pages/Success/SelfReg";
import { SendEmail } from "pages/Success/SendEmail";
import { BrandingConfiguration } from "pages/Success/Settings/Branding";
import { OrgSecurity } from "pages/Success/Settings/OrgSecurity";
import Surveys from "pages/Success/Surveys";
import CreateSurvey from "pages/Success/Surveys/CreateSurvey";
import EditSurvey from "pages/Success/Surveys/EditSurveyCampaign";
import { EditSurveyForm } from "pages/Success/Surveys/EditSurveyForm";
import { SurveyResults } from "pages/Success/Surveys/SurveyResults";
import UserFields from "pages/Success/UserFields";
import { Users } from "pages/Success/Users";
import { TodoListProvider } from "queries/todos";
import { useMemo } from "react";
import Modal from "react-modal";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { UserProvider } from "./auth";
import AuthenticatedRoute from "./auth/AuthenticatedRoute";
import { OrgProvider, useOrg } from "./auth/OrgProvider";
import GlobalStyle from "./components/GlobalStyle";
import MainContainer from "./components/MainContainer";
import LoginPage from "./pages/LoginPage";
import Logout from "./pages/Logout";
import Mindset from "./pages/Mindset";
import MindsetPlayer from "./pages/Mindset/MindsetPlayer";
import NotFound from "./pages/NotFound";
import OrgPeople from "./pages/People/OrgPeople";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Registration from "./pages/Registration";
import SolutionsHome from "./pages/Solutions";
import EditGroup from "./pages/Solutions/EditGroup";
import MyGroups from "./pages/Solutions/MyGroups";
import MyTeam from "./pages/Solutions/MyTeam";
import Success from "./pages/Success";
import BulkUpload from "./pages/Success/BulkUpload";
import EditUser from "./pages/Success/EditUser";
import TermsOfUse from "./pages/TermsOfUse";
import getTheme from "./theme";

Sentry.init({
  dsn: "https://0a386c82e227437daf64527680489eea@o1374253.ingest.sentry.io/6681500",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

const ThemeInjector = ({ children }) => {
  const [org] = useOrg();
  const t = getTheme();

  defaults.font.family = "Montserrat,Helvetica,sans-serif";
  defaults.font.size = 12;
  const communityRoute = useRouteMatch("/community");
  const successRoute = useRouteMatch("/success");
  const loginRoute = useRouteMatch([
    "/login",
    "/forgot-password",
    "/reset-password",
    "/change-password",
  ]);

  Modal.setAppElement("#root");

  const primaryColor =
    communityRoute != null
      ? "primary"
      : successRoute != null
        ? "green"
        : loginRoute != null
          ? "body"
          : "blue";

  const primary =
    primaryColor === "blue"
      ? (org?.theme.primaryColor ?? t.colors.blue)
      : t.colors[primaryColor];

  const theme = useMemo(() => {
    return {
      ...t,
      colors: {
        ...t.colors,
        primary,
      },
    };
  }, [primary, t]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const Routing = () => {
  const loginRoutes = [
    "login",
    "forgot-password",
    "reset-password",
    "change-password",
  ].flatMap((x) =>
    ["/", "/:section(solutions|community|success)/"].map((y) => y + x),
  );

  return (
    <Router>
      <ThemeInjector>
        <ToastProvider>
          <GlobalStyle />
          {process.env.NODE_ENV === "production" && <PendoUser />}
          <Switch>
            <Route path="/redeem-sso" exact component={RedeemSso} />
            <Route path="/impersonate" exact component={Impersonate} />
            <Route path={loginRoutes} exact component={LoginPage} />
            <Route path="/register/:id" exact component={Registration} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/terms-of-use" exact component={TermsOfUse} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/feedback" exact component={FeedbackSurveyRoute} />
            <Route path="/" exact component={SafeRedirect} />
            <Route>
              <MainContainer>
                <RightSidebar />
                <Switch>
                  <AuthenticatedRoute
                    product={["mindset", "lessons", "360", "surveys"]}
                    path="/solutions"
                    exact
                    component={SolutionsHome}
                  />
                  <AuthenticatedRoute
                    product="assessments"
                    path={[
                      "/solutions/assessments",
                      "/solutions/assessments/me",
                    ]}
                    exact
                    component={Assessments}
                  />
                  <AuthenticatedRoute
                    product="assessments"
                    exact
                    path={[
                      "/solutions/assessments/modules/:slug",
                      "/solutions/assessments/modules/:slug/results/:compId",
                    ]}
                    component={AssessmentPlayer}
                  />
                  <AuthenticatedRoute
                    product="360"
                    path="/solutions/feedback"
                    exact
                    component={UserCampaigns}
                  />
                  <AuthenticatedRoute
                    product="360"
                    path="/solutions/feedback/campaigns/:id"
                    exact
                    component={UserCampaign}
                  />
                  <AuthenticatedRoute
                    product={[
                      "mindset",
                      "lessons",
                      "360",
                      "performance",
                      "cont-feedback",
                    ]}
                    path={["/solutions/my-team", "/solutions/my-team/insights"]}
                    exact
                    component={MyTeam}
                  />
                  <AuthenticatedRoute
                    product={[
                      "mindset",
                      "lessons",
                      "360",
                      "performance",
                      "cont-feedback",
                    ]}
                    path="/solutions/my-team/:userId"
                    exact
                    component={RelationshipProfile}
                  />
                  <AuthenticatedRoute
                    product={["mindset", "lessons", "360"]}
                    path="/solutions/my-groups"
                    exact
                    component={MyGroups}
                  />
                  <AuthenticatedRoute
                    product={["mindset", "lessons", "360"]}
                    path={[
                      "/success/people/groups/:id",
                      "/solutions/my-groups/:id",
                    ]}
                    exact
                    component={EditGroup}
                  />
                  <AuthenticatedRoute
                    path="/solutions/mindset/modules/:slug"
                    product="mindset"
                    component={MindsetPlayer}
                  />
                  <AuthenticatedRoute
                    path="/solutions/mindset"
                    product="mindset"
                    component={Mindset}
                  />
                  <AuthenticatedRoute
                    path="/solutions/learncast/modules/:slug"
                    product="learncast"
                    component={LearnCastPlayer}
                  />
                  <AuthenticatedRoute
                    path="/solutions/learncast"
                    product="learncast"
                    component={LearnCastList}
                  />

                  <AuthenticatedRoute
                    path="/solutions/lessons/:category?"
                    product="lessons"
                    component={LessonsList}
                    exact
                  />
                  <AuthenticatedRoute
                    path="/solutions/lessons/modules/:slug"
                    product="lessons"
                    component={LessonsPlayer}
                  />
                  <AuthenticatedRoute
                    path="/solutions/surveys"
                    exact
                    product={["surveys"]}
                    component={SurveyList}
                  />
                  <AuthenticatedRoute
                    path="/solutions/surveys/:id"
                    exact
                    product={["surveys"]}
                    component={SurveyRoute}
                  />
                  <AuthenticatedRoute
                    path="/solutions/performance/goals/create"
                    product={["performance"]}
                    component={CreateGoal}
                  />
                  <AuthenticatedRoute
                    path="/solutions/performance/goals/:id/review"
                    exact
                    product={["performance"]}
                    component={GoalReviewContainer}
                  />
                  <AuthenticatedRoute
                    path="/solutions/people/goals/:userId"
                    exact
                    product={["performance"]}
                    component={UserGoals}
                  />
                  <AuthenticatedRoute
                    path="/solutions/people/goals/:id/review/:userId"
                    exact
                    product={["performance"]}
                    component={GoalReviewContainer}
                  />
                  <AuthenticatedRoute
                    path="/solutions/performance/(goals|conversations)?"
                    product={["performance"]}
                    component={Performance}
                  />
                  <AuthenticatedRoute
                    path={[
                      "/solutions/continuous-feedback",
                      "/solutions/continuous-feedback/view/:feedbackId?",
                    ]}
                    product={["cont-feedback"]}
                    component={MyContinuousFeedback}
                    exact
                  />
                  <AuthenticatedRoute
                    path="/solutions/continuous-feedback/send/:userId?/:requestId?"
                    product={["cont-feedback"]}
                    component={SendContinuousFeedback}
                  />
                  <AuthenticatedRoute
                    path="/solutions/continuous-feedback/request/:userId?"
                    product={["cont-feedback"]}
                    component={RequestContinuousFeedback}
                  />
                  <AuthenticatedRoute
                    path="/success"
                    exact
                    product={["mindset", "lessons", "360", "surveys"]}
                    component={Success}
                    requireRole={["org-admin", "user-admin"]}
                  />

                  <AuthenticatedRoute
                    path="/success/downloads"
                    component={DownloadsList}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/addons"
                    exact
                    component={AddonsPage}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/addons/office365-auth"
                    component={Office365Auth}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/performance"
                    product="performance"
                    component={SuccessPerformance}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/surveys/campaigns/create"
                    product="surveys"
                    exact
                    component={CreateSurvey}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/surveys/campaigns/:id"
                    product="surveys"
                    exact
                    component={SurveyResults}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/surveys/campaigns/edit/:id"
                    product="surveys"
                    exact
                    component={EditSurvey}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    product="surveys"
                    path="/success/surveys/forms/:id"
                    component={EditSurveyForm}
                    exact
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    product="surveys"
                    path="/success/surveys"
                    component={Surveys}
                  />
                  <AuthenticatedRoute
                    path="/success/feedback/forms/:id"
                    product="360"
                    exact
                    component={EditFeedbackForm}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/feedback/campaigns/create"
                    product="360"
                    exact
                    component={CreateCampaign}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/feedback/campaigns/edit/:id"
                    product="360"
                    exact
                    component={EditFeedbackCampaign}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/feedback/campaigns/:id"
                    product="360"
                    exact
                    component={Campaign}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/feedback"
                    product="360"
                    component={Feedback}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/branding"
                    exact
                    component={BrandingConfiguration}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/measures"
                    exact
                    component={MeasuresConfiguration}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/measures/create"
                    exact
                    component={CreateMeasure}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/measures/:id"
                    exact
                    component={EditMeasure}
                    requireRole={["org-admin"]}
                  />

                  <AuthenticatedRoute
                    path="/success/settings/content/:type?"
                    exact
                    component={MindsetConfiguration}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/security"
                    exact
                    component={OrgSecurity}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/insights"
                    requireRole={["org-admin"]}
                    component={Insights}
                  />
                  <AuthenticatedRoute
                    path="/success/people/self-registration"
                    exact
                    component={SelfReg}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/people/self-registration/:id"
                    exact
                    component={EditSelfReg}
                    requireRole={["org-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/people/user-fields"
                    requireRole={["user-admin"]}
                    exact
                    component={UserFields}
                  />
                  <AuthenticatedRoute
                    path="/success/people/user-fields/create"
                    exact
                    requireRole={["user-admin"]}
                    component={CreateUserField}
                  />
                  <AuthenticatedRoute
                    path="/success/people/user-fields/:id"
                    exact
                    requireRole={["user-admin"]}
                    component={EditUserField}
                  />
                  <AuthenticatedRoute
                    path="/success/people/users"
                    exact
                    component={Users}
                    requireRole={["user-admin"]}
                  />
                  <Redirect
                    path="/success/people"
                    exact
                    to="/success/people/users"
                  />
                  <AuthenticatedRoute
                    path="/success/people/groups"
                    component={Groups}
                    requireRole={["user-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/people/comms"
                    component={SendEmail}
                    requireRole={["user-admin"]}
                  />
                  <AuthenticatedRoute
                    path="/success/people/users/bulk-upload"
                    requireRole={["user-admin"]}
                    exact
                    component={BulkUpload}
                  />
                  <AuthenticatedRoute
                    path="/success/people/users/bulk-delete"
                    requireRole={["user-admin"]}
                    exact
                    component={BulkDelete}
                  />
                  <AuthenticatedRoute
                    path="/success/people/users/:id"
                    requireRole={["user-admin"]}
                    exact
                    component={EditUser}
                  />

                  <AuthenticatedRoute
                    path="/account-settings"
                    component={AccountSettings}
                  />

                  <AuthenticatedRoute
                    path="/people/org"
                    component={OrgPeople}
                  />
                  <AuthenticatedRoute
                    path={["/people/:id", "/dashboard/content/:id"]}
                    component={UserProfilePage}
                  />
                  <AuthenticatedRoute
                    path={"/dashboard"}
                    component={Dashboard}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/conversation-types"
                    exact
                    component={ConversationTypes}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/conversation-types/create"
                    exact
                    component={CreateConversationType}
                  />
                  <AuthenticatedRoute
                    path="/success/settings/conversation-types/:id"
                    exact
                    component={EditConversationType}
                  />
                  <AuthenticatedRoute component={NotFound} />
                </Switch>
              </MainContainer>
            </Route>
          </Switch>
        </ToastProvider>
      </ThemeInjector>
    </Router>
  );
};

const App = () => (
  <>
    <Routing />
  </>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Providers = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <UserProvider>
        <OrgProvider>
          <TodoListProvider>{children}</TodoListProvider>
        </OrgProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};

const AppRoot = () => (
  <Providers>
    <App />
  </Providers>
);

const Root = () => {
  const DOWNTIME = process.env.REACT_APP_ENABLE_DOWNTIME === "true";

  if (DOWNTIME) {
    return (
      <ThemeProvider theme={getTheme()}>
        <GlobalStyle />
        <HoldingPage />;
      </ThemeProvider>
    );
  }

  return <AppRoot />;
};

export default Root;
