import {
  GoalOutcomeOptions,
  GoalReviewStatusOptions,
  GoalStatusHistoryDto,
} from "@coaching-culture/types";
import { Box, Flex, Label, Text } from "@coaching-culture/ui";
import { UserFlag } from "components/UserFlag";
import { formatDate } from "utils/dates";

export const GoalStatusUpdate = ({
  statusUpdate,
}: {
  statusUpdate: GoalStatusHistoryDto;
}) => {
  const isOutcome = (x: GoalReviewStatusOptions | GoalOutcomeOptions) =>
    x === "missed" ||
    x === "nla" ||
    x === "completedachieved" ||
    x === "partially" ||
    x === "exceeded" ||
    x === "open";

  const getFriendlyName = (status: string) => {
    switch (status) {
      case "inprogress":
        return (
          <Label size="small" color={"warning"}>
            In Progress
          </Label>
        );
      case "notstarted":
        return (
          <Label size="small" color={"grey"}>
            Not Started
          </Label>
        );
      case "achieved":
        return (
          <Label size="small" color={"positive"}>
            Achieved
          </Label>
        );
      case "blocked":
        return (
          <Label size="small" color={"danger"}>
            Blocked
          </Label>
        );
      case "onhold":
        return (
          <Label size="small" color={"grey"}>
            On Hold
          </Label>
        );
      case "completedachieved":
        return (
          <Label size="small" color={"positive"}>
            Completed - Achieved
          </Label>
        );
      case "partially":
        return (
          <Label size="small" color={"warning"}>
            Closed - Partially Met
          </Label>
        );
      case "exceeded":
        return (
          <Label size="small" color={"positive"}>
            Completed - Exceeded
          </Label>
        );
      case "missed":
        return (
          <Label size="small" color={"danger"}>
            Closed - Missed
          </Label>
        );
      case "nla":
        return (
          <Label size="small" color={"grey"}>
            Closed - No Longer Applicable
          </Label>
        );
      case "open":
        return (
          <Label size="small" color={"warning"}>
            Reopened
          </Label>
        );
    }
  };

  return (
    <Flex mr={0} mt={2} pl={1} flexDirection="column" borderBottom={1}>
      <Flex justifyContent={"space-between"} mb={1}>
        <UserFlag user={statusUpdate.user} />
      </Flex>
      <Box pl={"42px"}>
        {isOutcome(statusUpdate.newStatus) ? (
          <Text>
            Outcome of <strong>{statusUpdate.goalPurpose}</strong> set to{" "}
            {getFriendlyName(statusUpdate.newStatus)}
          </Text>
        ) : statusUpdate.newStatus === null ? (
          <Text>
            Progress of <strong>{statusUpdate.goalPurpose}</strong> updated from{" "}
            {statusUpdate.previousProgress} to {statusUpdate.progress}
          </Text>
        ) : statusUpdate.previousStatus != null ? (
          <Text>
            Status of <strong>{statusUpdate.goalPurpose}</strong> updated from:{" "}
            {getFriendlyName(statusUpdate.previousStatus)} to:{" "}
            {getFriendlyName(statusUpdate.newStatus)}
          </Text>
        ) : (
          <Text>
            Status of <strong>{statusUpdate.goalPurpose}</strong> changed to:{" "}
            {getFriendlyName(statusUpdate.newStatus)}
          </Text>
        )}
        <Flex mb={2} mt={1}>
          <Text fontSize={2} color={"grey"} mr={2} fontWeight={500}>
            {formatDate(statusUpdate.createdOn, "dd/MM/yy : HH:mm")}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
