import {
  FreeTextQuestionFormItemResponses,
  MultipleChoiceQuestionFormItemResponses,
  RatingFormItemResponses,
  SurveyCampaignDto,
  UserSummary,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Loader,
  ReactModal,
  Panel,
  SectionHeading,
  SortableTable,
  SortableTableColumn,
  Text,
  usePagination,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import StarRating from "components/StarRating";
import { dotItems } from "components/SurveyPlayer/DotsRating";
import { UserFlag } from "components/UserFlag";
import { differenceInHours, fromUnixTime } from "date-fns";
import { mean, range } from "lodash";
import Papa from "papaparse";
import { mix } from "polished";
import {
  useAddRespondentsToSurvey,
  useDeleteUserFromSurvey,
  useNudgeAllSurveyRespondents,
  useNudgeSurveyRespondent,
  useSurvey,
  useSurveyReport,
} from "queries/surveys";
import React, { useMemo, useRef, useState } from "react";
import {
  FaBell,
  FaFrown,
  FaFrownOpen,
  FaGrin,
  FaMeh,
  FaPrint,
  FaSmile,
  FaTrash,
  FaUserPlus,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import styled, { useTheme } from "styled-components";
import { downloadContent } from "utils";
import { formatDate } from "utils/dates";
import { FreqChart } from "../../../components/FreqChart";
import noDataSrc from "../../../img/waiting.svg";
import { UserSelectModal } from "../Feedback/UserSelectModal";

const NewCampaignImage = styled.img`
  flex: 1;
  max-width: 80%;
`;

const NoData = () => {
  return (
    <Panel p={[2, 3]} mb={5} pb={[0, 0]}>
      <Flex
        alignItems="center"
        justifyContent="center"
        pb={[3, "60px"]}
        pt={[3, "60px"]}
        pl={[3, "90px"]}
        pr={[3, "90px"]}
        borderBottom={1}
        flexDirection={["column", "row"]}
      >
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flex="1"
          order={[1, 0]}
          mt={[3, 0]}
          mb={[4, 0]}
        >
          <Text fontSize={6} fontWeight={600} maxWidth="400px">
            No data yet!
          </Text>
          <Text fontSize={4} color="grey2" mb={2}>
            No one has completed the survey yet.
            <br />
            Check back here later.
          </Text>
          <Link to="/success">Back to Success</Link>
        </Flex>
        <Flex style={{ position: "relative" }} flex="1" justifyContent="center">
          <NewCampaignImage src={noDataSrc} />
        </Flex>
      </Flex>
    </Panel>
  );
};

function DeleteSurveyUserModal({
  email,
  onCancel,
  onConfirm,
  isOpen,
}: {
  email: string;
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}) {
  const [confirmEmail, setConfirmEmail] = useState("");

  return (
    <ReactModal isOpen={isOpen} onClose={onCancel} width={400}>
      <Box p={4}>
        <Text mb={2} fontWeight={600} fontSize={4}>
          Remove User
        </Text>
        <Text fontSize={3}>
          Are you sure you want to remove the user from this survey? This will
          completely remove all results gathered for this user and is
          irreversible. If you are sure then please enter the users email
          address into the box to confirm.
        </Text>

        <Text fontWeight={600} mt={2}>
          Email Address
        </Text>
        <Input
          value={confirmEmail}
          onChange={(val) => setConfirmEmail(val.target.value)}
        />
        <Flex mt={3} alignItems={"center"} flexDirection={"column"}>
          <Flex>
            <Button
              disabled={confirmEmail !== email}
              mr={2}
              onClick={onConfirm}
              color={"danger"}
            >
              Delete
            </Button>
            <Button onClick={() => onCancel()} color="primary">
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Box>
    </ReactModal>
  );
}

function MultipleChoiceReport({
  item,
}: {
  item: MultipleChoiceQuestionFormItemResponses;
}) {
  const data = useMemo(() => {
    return item.answers.map((a) => ({
      y: a.content,
      x: item.responses.filter((x) => x.answer === a.id).length,
    }));
  }, [item]);

  return (
    <Box mb={5} style={{ pageBreakInside: "avoid" }}>
      <Text fontSize={4} mb={1} fontWeight={500}>
        {item.content}
      </Text>
      <Panel p={3}>
        <FreqChart data={data} />
      </Panel>
    </Box>
  );
}

function StarRatingReport({ item }: { item: RatingFormItemResponses }) {
  const data = useMemo(() => {
    let d = [
      {
        y: (
          <Text fontWeight={500} fontSize={2} mr={1}>
            N/A
          </Text>
        ),
        x: item.responses.filter((x) => x.rating == null).length,
      },
      ...range(1, 6).map((a) => ({
        y: <StarRating value={a} />,
        x: item.responses.filter((x) => x.rating === a).length,
      })),
    ];

    if (!item.allowNa) {
      d = d.slice(1);
    }

    return d;
  }, [item]);

  const average =
    mean(item.responses.filter((x) => x.rating != null).map((x) => x.rating)) ??
    0;

  return (
    <>
      <FreqChart data={data} />
      <Flex borderTop={1} mt={2} p={2} alignItems="center" pb={0}>
        <Text mr={2} fontWeight={600}>
          Average Rating:
        </Text>
        <StarRating value={average} />
      </Flex>
    </>
  );
}

function DotsRatingReport({ item }: { item: RatingFormItemResponses }) {
  const data = useMemo(() => {
    let d = [
      {
        y: (
          <Text fontWeight={500} fontSize={2} mr={1}>
            N/A
          </Text>
        ),
        x: item.responses.filter((x) => x.rating == null).length,
      },
      ...dotItems.map((a, i) => ({
        y: <Text fontSize={2}>{a}</Text>,
        x: item.responses.filter((x) =>
          item.reverseScale ? Math.abs(x.rating - 7) === i : x.rating - 1 === i
        ).length,
      })),
    ];
    if (!item.allowNa) {
      d = d.slice(1);
    }

    return d;
  }, [item]);

  const average = mean(
    item.responses.filter((x) => x.rating != null).map((x) => x.rating)
  );
  const averageDisplay = Number.isNaN(average)
    ? "No results"
    : dotItems[
        item.reverseScale
          ? Math.abs(Math.round(average) - 7)
          : Math.round(average) - 1
      ];

  return (
    <>
      <FreqChart data={data} />
      <Flex borderTop={1} mt={2} p={2} alignItems="center" pb={0}>
        <Text mr={2} fontWeight={600}>
          Average Rating:
        </Text>
        <Text ml={2}>{averageDisplay}</Text>
      </Flex>
    </>
  );
}
const opts = [FaFrownOpen, FaFrown, FaMeh, FaSmile, FaGrin] as const;

function SmilesRatingReport({ item }: { item: RatingFormItemResponses }) {
  const theme = useTheme();

  const colors = useMemo(
    () =>
      range(0, 5).map((x) =>
        mix(x / 4, theme.colors.positive, theme.colors.danger)
      ),
    [theme]
  );

  const data = useMemo(() => {
    let d = [
      {
        y: (
          <Text fontWeight={500} fontSize={2} mr={1}>
            N/A
          </Text>
        ),
        x: item.responses.filter((x) => x.rating == null).length,
      },
      ...opts.map((Icon, i) => ({
        y: <Icon fontSize="1.5em" color={colors[i]} />,
        x: item.responses.filter((x) => x.rating - 1 === i).length,
      })),
    ];
    if (!item.allowNa) {
      d = d.slice(1);
    }

    return d;
  }, [item, colors]);

  const average = mean(item.responses.map((x) => x.rating));
  const AverageDisplay = average == null ? null : opts[Math.round(average) - 1];

  return (
    <>
      <FreqChart data={data} />
      <Flex borderTop={1} mt={2} p={2} alignItems="center" pb={0}>
        <Text mr={2} fontWeight={600}>
          Average Rating:
        </Text>
        {AverageDisplay == null ? (
          <Text ml={2}>No Results</Text>
        ) : (
          <AverageDisplay
            fontSize="1.5em"
            color={colors[Math.round(average) - 1]}
          />
        )}
      </Flex>
    </>
  );
}

function NumbersRatingReport({ item }: { item: RatingFormItemResponses }) {
  const data = useMemo(() => {
    let d = [
      {
        y: (
          <Text fontWeight={500} fontSize={2} mr={1}>
            N/A
          </Text>
        ),
        x: item.responses.filter((x) => x.rating == null).length,
      },
      ...range(1, 11)
        .reverse()
        .map((x) => ({
          y: <Text fontSize={2}>{x}</Text>,
          x: item.responses.filter((i) => i.rating === x).length,
        })),
    ];

    if (!item.allowNa) {
      d = d.slice(1);
    }

    return d;
  }, [item]);

  const average = mean(
    item.responses.filter((x) => x.rating != null).map((x) => x.rating)
  );

  return (
    <>
      <FreqChart data={data} />
      <Flex borderTop={1} mt={2} p={2} alignItems="center" pb={0}>
        <Text mr={2} fontWeight={600}>
          Average Rating:
        </Text>
        <Text ml={2}>
          {Number.isNaN(average) ? "No Results" : average.toFixed(2)}
        </Text>
      </Flex>
    </>
  );
}

function RatingReport({ item }: { item: RatingFormItemResponses }) {
  let content = <></>;

  if (item.ratingType === "stars") {
    content = <StarRatingReport item={item} />;
  } else if (item.ratingType === "dots") {
    content = <DotsRatingReport item={item} />;
  } else if (item.ratingType === "smiles") {
    content = <SmilesRatingReport item={item} />;
  } else if (item.ratingType === "number") {
    content = <NumbersRatingReport item={item} />;
  }

  return (
    <Box mb={5} style={{ pageBreakInside: "avoid" }}>
      <Text fontSize={4} mb={1} fontWeight={500}>
        {item.content}
      </Text>
      <Panel p={3}>{content}</Panel>
    </Box>
  );
}

function FreeTextReport({
  item,
  paginate,
}: {
  item: FreeTextQuestionFormItemResponses;
  paginate: boolean;
}) {
  const pageSize = 5;
  const {
    data,
    activePage,
    pageCount,
    component: Pagination,
  } = usePagination(item.responses, pageSize, 1);

  const d = paginate ? data : item.responses;

  return (
    <Box mb={5}>
      <Text fontSize={4} mb={1} fontWeight={500}>
        {item.content}
      </Text>
      <Panel p={3}>
        {d.map((x, i) => (
          <React.Fragment key={i}>
            <Text mb="3px" fontSize={2} fontWeight={600} color="grey2">
              Response {(activePage - 1) * pageSize + i + 1} /{" "}
              {item.responses.length}
            </Text>
            <Flex
              flexDirection="column"
              border={1}
              borderRadius={2}
              p={3}
              mb={pageCount > 1 ? 3 : i === data.length - 1 ? 0 : 3}
              style={{ pageBreakInside: "avoid" }}
            >
              {x.content}
            </Flex>
          </React.Fragment>
        ))}
        {pageCount > 1 && paginate && <Pagination />}
      </Panel>
    </Box>
  );
}

type SurveyRespondent = SurveyCampaignDto["recipients"][number];

const search = (x: SurveyRespondent) => x.name + x.email;

const cantNudge = (time: number | null, completion: number | null) =>
  completion != null ||
  (time != null && differenceInHours(new Date(), fromUnixTime(time)) < 24);

export const SurveyResults = () => {
  const { id } = useParams<{ id: string }>();
  const { data: survey, isFetched } = useSurvey(id);
  const { data: results, isFetched: isReportFetched } = useSurveyReport(id);
  const addRespondents = useAddRespondentsToSurvey(id);
  const nudgeRespondent = useNudgeSurveyRespondent(id);
  const nudgeAll = useNudgeAllSurveyRespondents(id);
  const [printing, setPrinting] = useState(false);
  const [adding, setAdding] = useState(false);
  const resultsRef = useRef<HTMLDivElement>(null!);
  const [deletingUser, setDeleteingUser] = useState<{
    email: string;
    id: string;
  }>({ id: null, email: null });

  const deleteUser = useDeleteUserFromSurvey(id);

  const cols = useMemo<SortableTableColumn<SurveyRespondent>[]>(
    () => [
      {
        label: "Name",
        name: "name",
        format: (x) => <UserFlag user={x} />,
      },
      {
        label: "Date Completed",
        name: "completedOn",
        headingProps: {
          textAlign: "right",
        },
        props: {
          textAlign: "right",
        },
        format: (x) => (
          <Flex alignItems={"center"} justifyContent={"flex-end"}>
            {!survey.archived && survey.recipients.length > 1 && (
              <IconButton
                title="Delete"
                mr={2}
                icon={FaTrash}
                color={"danger"}
                onClick={() => setDeleteingUser({ email: x.email, id: x.id })}
              />
            )}
            <Button
              tiny
              disabled={
                cantNudge(x.lastNudged, x.completedOn) ||
                nudgeRespondent.isLoading ||
                nudgeAll.isLoading
              }
              onClick={() => nudgeRespondent.mutateAsync(x.id)}
            >
              Nudge
            </Button>

            <Text ml={1}>
              {x.completedOn == null
                ? "Not completed yet"
                : formatDate(x.completedOn, "dd/MM/yyyy")}
            </Text>
          </Flex>
        ),
      },
    ],
    [nudgeRespondent, nudgeAll, setDeleteingUser, survey]
  );

  const loading =
    !isFetched && !isReportFetched && results == null && survey == null;

  if (loading) {
    return <Loader />;
  }

  const addUsers = (users: UserSummary[]) => {
    if (users.length > 0) {
      addRespondents.mutateAsync(users.map((x) => x.id));
    }
    setAdding(false);
  };

  const responseCount =
    (results as any[])?.find((x) => "responses" in x)?.responses.length ?? 0;
  const recipientCount = survey?.recipients.length;
  const responseRate = ((responseCount / recipientCount) * 100).toFixed(0);

  const exportResults = () => {
    let result = results
      .filter((y) => y.type !== "heading" && y.type !== "content")
      .map((x, i) => {
        let row = {
          Question: x.content,
          Type: x.type,
          "Rating Type": (x as any).ratingType,
        };
        if (x.type === "rating") {
          const rating = x as RatingFormItemResponses;
          const arr = rating.responses.map((y, j) => {
            return {
              [`Respondent ${j + 1}`]: y.rating,
            };
          });
          return {
            ...row,
            ...Object.fromEntries(arr.flatMap(Object.entries)),
          };
        } else if (x.type === "free_text") {
          const textResult = x as FreeTextQuestionFormItemResponses;
          const arr = textResult.responses.map((y, j) => ({
            [`Respondent ${j + 1}`]: y.content,
          }));
          return {
            ...row,
            ...Object.fromEntries(arr.flatMap(Object.entries)),
          };
        } else {
          const multipleResult = x as MultipleChoiceQuestionFormItemResponses;
          const arr = multipleResult.responses.map((y, j) => ({
            [`Respondent ${j + 1}`]: multipleResult.answers.find(
              (x) => x.id === y.answer
            ).content,
          }));
          return {
            ...row,
            ...Object.fromEntries(arr.flatMap(Object.entries)),
          };
        }
      });
    const csv = Papa.unparse(result, { delimiter: ",", header: true });
    downloadContent(csv, `${survey?.name}.csv`);
  };

  return (
    <CenterColumn>
      {adding && <UserSelectModal onSelect={addUsers} />}

      <DeleteSurveyUserModal
        isOpen={deletingUser.id != null}
        email={deletingUser.email}
        onCancel={() => setDeleteingUser({ id: null, email: null })}
        onConfirm={() => {
          deleteUser.mutateAsync(deletingUser.id);
          setDeleteingUser({ id: null, email: null });
        }}
      />
      <PageHeader
        text={`${survey?.name} Results`}
        subtitle={`Results are based on ${responseCount} responses, a ${responseRate}% response rate`}
        backUrl="/success/surveys/campaigns"
      />
      {survey?.isDraft && (
        <Text fontWeight={600} color={"warning"}>
          This campaign is currently a draft and has not been sent to users
        </Text>
      )}
      <Flex alignItems="center" mb={3}>
        <SectionHeading mb={0}>Respondents</SectionHeading>
        <Flex alignItems="center">
          <Button
            color="primary"
            icon={FaUserPlus}
            ml={2}
            onClick={() => setAdding(true)}
          >
            Add Respondents
          </Button>
          <Button
            color="primary"
            ml={2}
            onClick={() => nudgeAll.mutateAsync()}
            disabled={
              nudgeAll.isLoading ||
              survey?.recipients.every((x) =>
                cantNudge(x.lastNudged, x.completedOn)
              )
            }
            icon={FaBell}
          >
            Nudge All
          </Button>
        </Flex>
      </Flex>
      {!survey?.sendEmail && (
        <Text>Please note user emails are disabled for this survey</Text>
      )}

      <Panel p={0} mb={5}>
        <SortableTable
          columns={cols}
          data={survey?.recipients}
          paged
          rowSearch={search}
        />
      </Panel>
      <div ref={resultsRef}>
        <Flex alignItems="center" mb={3}>
          <SectionHeading mb={0}>Results</SectionHeading>
          <Button ml={2} mr={2} onClick={() => exportResults()} color={"body"}>
            Export
          </Button>
          <ReactToPrint
            onBeforeGetContent={() => {
              setPrinting(true);
              return new Promise((res) => setTimeout(res));
            }}
            onAfterPrint={() => setPrinting(false)}
            trigger={() => (
              <Button color="primary" icon={FaPrint}>
                Print
              </Button>
            )}
            content={() => resultsRef.current}
          />
        </Flex>
        {responseCount === 0 ? (
          <NoData />
        ) : (
          survey?.form.form.items.map((x) => {
            const res = results.find((r) => r.id === x.id) as any;
            return x.type === "heading" ? (
              <Flex borderBottom={1} mb={5}>
                <Text fontWeight={600} fontSize={5}>
                  {x.content}
                </Text>
              </Flex>
            ) : x.type === "multiple_choice" ? (
              <MultipleChoiceReport item={res} />
            ) : x.type === "rating" ? (
              <RatingReport item={res} />
            ) : x.type === "free_text" ? (
              <FreeTextReport item={res} paginate={!printing} />
            ) : null;
          })
        )}
      </div>
    </CenterColumn>
  );
};
