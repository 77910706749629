import { HelpItemIdent } from "@coaching-culture/types";
import { Flex, Text } from "@coaching-culture/ui";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HelpItemButton } from "./HelpItemButton";

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-top: 12px;

  & svg {
    margin-right: 6px;
  }
`;

const PanelImage = styled.img`
  flex: 1;
  max-height: 80px;
`;

export type PageHeaderProps = {
  text: string;
  subtitle?: string;
  backUrl?: string;
  helpIdent?: HelpItemIdent;
  imageSrc?: any;
  children?: React.ReactNode;
};

export const PageHeader = ({
  text,
  subtitle,
  backUrl,
  helpIdent,
  imageSrc,
  children,
}: PageHeaderProps) => (
  <Flex
    flexDirection={"row"}
    mb={5}
    borderBottom={1}
    pb={3}
    justifyContent="space-between"
    alignItems={"flex-end"}
  >
    <Flex justifyContent={"flex-start"} flexDirection="column">
      <Flex>
        <Text
          fontSize={[5, 6]}
          fontWeight={600}
          lineHeight={1}
          color="black"
          as="h1"
        >
          {text}
        </Text>
        {helpIdent && <HelpItemButton ident={helpIdent} style={{ top: -6 }} />}
      </Flex>
      {subtitle && (
        <Text fontSize={3} color="grey1" fontWeight={500} mt={1}>
          {subtitle}
        </Text>
      )}
      {backUrl && (
        <BackLink to={backUrl} key={backUrl}>
          <FaChevronLeft />
          Back
        </BackLink>
      )}
    </Flex>
    {imageSrc && (
      <Flex marginTop={"auto"} marginLeft={"auto"}>
        <PanelImage src={imageSrc} />
      </Flex>
    )}
    {children}
  </Flex>
);
