import { CampaignSummary } from "@coaching-culture/types";
import {
  Button,
  ConfirmationModal,
  ExpandableContainer,
  Flex,
  IconButton,
  Label,
  Loader,
  Panel,
  PanelHeader,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { PageHeader } from "components/PageHeader";
import { format } from "date-fns";
import { sortBy } from "lodash";
import {
  useDeleteFeedbackCampaign,
  useFeedbackCampaigns,
} from "queries/feedback";
import { useCallback, useState } from "react";
import {
  FaArchive,
  FaChevronDown,
  FaChevronUp,
  FaEdit,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const campSearch = (item: CampaignSummary) => item.name;

const AccordianButton = styled.div`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const Campaigns = () => {
  const { data: campaigns, isFetched } = useFeedbackCampaigns();
  const deleteCampaignMut = useDeleteFeedbackCampaign();
  const [campaignId, setCampaignId] = useState<string>(null);
  const [showArchived, setShowArchived] = useState(false);
  const [modalText, setModalText] = useState("");
  const history = useHistory();

  const cols = useCallback(
    (archive: boolean): SortableTableColumn<CampaignSummary>[] => {
      return [
        {
          name: "name",
          label: "Name",
          format: (x) => (
            <Flex alignItems={"center"}>
              {x.isDraft && (
                <Label mr={2} color="warning" size={"small"}>
                  Draft
                </Label>
              )}
              <Link to={`/success/feedback/campaigns/${x.id}`}>
                <strong>{x.name}</strong>
              </Link>
            </Flex>
          ),
        },
        {
          name: "userCount",
          label: "User Count",
        },
        {
          name: "responseCount",
          label: "Response Count",
        },
        {
          name: "createdOn",
          label: "Created",
          format: (x) => format(new Date(x.createdOn), "dd/MM/yyyy"),
          props: {
            textAlign: "right",
          },
          headingProps: {
            width: 150,
            textAlign: "right",
          },
        },
        {
          label: "",
          name: "actions",
          headingProps: {
            width: 50,
            textAlign: "center",
          },
          format: (x) => (
            <Flex>
              <IconButton
                mr={2}
                title={"Edit"}
                onClick={() =>
                  history.push(`/success/feedback/campaigns/edit/${x.id}`)
                }
                color="body"
                icon={FaEdit}
              />
              <IconButton
                title={archive ? "Archive" : "Delete"}
                color={archive ? "body" : "danger"}
                onClick={() => {
                  archive
                    ? setModalText(
                        "Are you sure you want to archive this campaign?"
                      )
                    : setModalText(
                        "Are you sure you want to delete this campaign? All associated data will be permantly removed."
                      );
                  setCampaignId(x.id);
                }}
                icon={archive ? FaArchive : FaTrash}
              />
            </Flex>
          ),
        },
      ];
    },
    [history]
  );

  return (
    <>
      <PageHeader
        text="Feedback Campaigns"
        subtitle="Create and review feedback campaigns for your organisation"
        helpIdent="success-feedback-campaigns-main"
      />
      <Flex
        alignItems={["stretch", "center"]}
        justifyContent="flex-end"
        mb={2}
        flexDirection={["column", "row"]}
      >
        <Button
          to="/success/feedback/campaigns/create"
          color="primary"
          icon={FaPlus}
        >
          New Campaign
        </Button>
      </Flex>

      <ConfirmationModal
        isOpen={campaignId != null}
        onCancel={() => setCampaignId(null)}
        buttons={[
          {
            text: "Yes",
            color: "danger",
            onClick: () => {
              deleteCampaignMut.mutateAsync(campaignId);
              setCampaignId(null);
            },
          },
        ]}
        modalText={modalText}
      />
      {!isFetched || campaigns == null ? (
        <Loader />
      ) : (
        <>
          <Panel mb={3} style={{ overflow: "hidden" }}>
            <SortableTable<CampaignSummary>
              columns={cols(true)}
              data={sortBy(
                campaigns?.filter((x) => !x.archived),
                "createdOn"
              )}
              paged
              rowSearch={campSearch}
              rowSearchPlaceholder="Search Campaigns..."
              defaultSort={3}
              defaultDesc
              emptyMessage="No Campaigns Created Yet"
            />
          </Panel>

          <Panel style={{ overflow: "hidden" }}>
            <PanelHeader>
              <Text fontWeight={600}>Archived Campaigns</Text>
              <Flex
                alignItems="end"
                justifyContent="center"
                flexDirection="column"
                flexGrow={1}
              >
                {showArchived ? (
                  <AccordianButton onClick={() => setShowArchived(false)}>
                    <Flex alignItems={"center"}>
                      <Text fontWeight={600} mr={2}>
                        Hide
                      </Text>
                      <FaChevronUp />
                    </Flex>
                  </AccordianButton>
                ) : (
                  <AccordianButton onClick={() => setShowArchived(true)}>
                    <Flex alignItems={"center"}>
                      <Text fontWeight={600} mr={2}>
                        Show
                      </Text>
                      <FaChevronDown />
                    </Flex>
                  </AccordianButton>
                )}
              </Flex>
            </PanelHeader>
            <ExpandableContainer expanded={showArchived}>
              <SortableTable<CampaignSummary>
                columns={cols(false)}
                data={sortBy(
                  campaigns?.filter((x) => x.archived),
                  "createdOn"
                )}
                paged
                rowSearch={campSearch}
                rowSearchPlaceholder="Search Campaigns..."
                defaultSort={3}
                defaultDesc
                emptyMessage="No archived campaigns..."
              />
            </ExpandableContainer>
          </Panel>
        </>
      )}
    </>
  );
};
