export interface CoachingCultureColors {
  black: string;
  body: string;
  pink: string;
  bg: string;
  bgLight: string;
  primaryLight: string;
  star: string;
  secondary: string;
  primary: string;
  orange: string;
  blue: string;
  green: string;
  grey: string;
  grey0: string;
  grey1: string;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  grey6: string;
  grey7: string;
  danger: string;
  positive: string;
  warning: string;
  hub: string;
  [key: string]: string;
}

export type CoachingCultureColorName = Extract<
  keyof CoachingCultureColors,
  string
>;

export interface CoachingCultureTheme {
  baseline: number;
  baseLineHeight: number;
  baseFontSize: number;
  borderRadius: number[];
  button: {
    borderRadius: number;
    defaultColor: string;
  };
  panel: {
    borderRadius: number;
    defaultColor: string;
    backgroundColor: keyof CoachingCultureColors;
  };
  breakpoints: string[];
  shadows: string[];
  borders: string[];
  fonts: Record<string, string>;
  fontSizes: number[] | string[];
  colors: CoachingCultureColors;
  space: number[];
}

const baseline = 6;
const baseFontSize = 14;

// Major third typography
const calcFontsize = (base: number, order: number) =>
  base * Math.pow(1.25, order);

const defaultColors: CoachingCultureColors = {
  black: "#444",
  body: "#535455",
  bg: "#2F4858",
  bgLight: "#466375",
  primaryLight: "#FABA4C",
  star: "#F7CA00",
  secondary: "#64B0B2",
  primary: "#E69833",
  pink: "#DF837A",
  orange: "#E69833",
  blue: "#48B2D2",
  green: "#65AC67",
  grey: "#aaa",
  grey0: "#222",
  grey1: "#888",
  grey2: "#aaa",
  grey3: "#ddd",
  grey4: "#eee",
  grey5: "#efefef",
  grey6: "#f6f6f6",
  grey7: "#fcfcfc",
  danger: "#c0392b",
  positive: "#71BC44",
  warning: "#E69833",
  hub: "#0C7695",
};

export const getDefaultThemeColors = () => ({ ...defaultColors });

const defaultTheme: CoachingCultureTheme = {
  baseline,
  baseLineHeight: 1.5, //calcLineHeight(baseFontSize),
  baseFontSize,
  borderRadius: [0, 6, 8, 12],
  button: {
    borderRadius: 6,
    defaultColor: "body",
  },
  panel: {
    borderRadius: 2,
    defaultColor: "primary",
    backgroundColor: "white",
  },
  breakpoints: ["768px", "1024px", "1400px", "1920px"],
  shadows: [
    "none",
    "0 2px 5px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2)",
    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  ],
  fonts: {
    heading: "Montserrat,Helvetica,sans-serif",
    body: "Montserrat,Helvetica,sans-serif",
  },
  fontSizes: [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map(
    (x) => calcFontsize(1, x) + "em",
  ),
  colors: getDefaultThemeColors(),
  borders: ["none", `1px solid ${getDefaultThemeColors().grey3}`],
  space: [0, 6, 12, 18, 24, 36, 48, 72, 96],
};

export const getDefaultTheme = () => ({ ...defaultTheme });
