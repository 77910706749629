import { SurveySpec } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormInput,
  FormInputWrap,
  FormTextArea,
  ImageUploader,
  Loader,
  Panel,
  RichTextEditor,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useSurvey, useUpdateSurveyCampaign } from "queries/surveys";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

export type Recipient = {
  id: string;
  name: string;
  email: string;
};

export default function EditSurvey() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [spec, setSpec] = useState<Partial<SurveySpec>>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const updateSurvey = useUpdateSurveyCampaign(id);
  const { data, isFetched } = useSurvey(id);

  useEffect(() => {
    if (isFetched) {
      setSpec({
        name: data.name,
        description: data.description,
        thumbnail: data.thumbnail,
        emailBody: data.emailBody,
        emailSubject: data.emailSubject,
        recipients: data.recipients.map((x) => x.id),
        sendEmail: data.sendEmail,
        isDraft: data.isDraft,
      });
    }
  }, [isFetched, data, id]);

  const submit = (isDraft: boolean) => {
    setSubmitting(true);
    spec.isDraft = isDraft;
    updateSurvey
      .mutateAsync(spec)
      .then(({ data }) => {
        history.push(`/success/surveys/campaigns/${data.id}`);
      })
      .catch((err) => {
        setSubmitting(false);
        window.alert("There was an error");
      });
  };

  const setValue = (key: keyof SurveySpec) => (val: any) => {
    setSpec((old) => ({
      ...old,
      [key]: val,
    }));
    setErrors((old) => ({
      ...old,
      [key]: null,
    }));
  };

  return (
    <CenterColumn>
      {!isFetched || spec == null ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            text="Edit Survey"
            subtitle="Update an existing survey"
            backUrl="/success/surveys"
          />
          <Panel p={3}>
            {spec.isDraft && (
              <Text mb={2} fontWeight={600} color={"warning"}>
                This campaign is currently a draft and has not been sent to
                users
              </Text>
            )}
            <Box mb={3}>
              <>
                <Text fontSize={4}>Customise Survey</Text>
                <Text color="grey1" mb={3}>
                  Change how your survey behaves
                </Text>
                <FormInput
                  required
                  name="name"
                  label="Survey Name"
                  width="500px"
                  error={errors.name}
                  value={spec.name}
                  onChange={(ev) => setValue("name")(ev.target.value)}
                />
                <FormTextArea
                  required
                  name="description"
                  height="150px"
                  label="Description"
                  value={spec.description}
                  onChange={(ev) => setValue("description")(ev.target.value)}
                />
                <FormInputWrap label="Image (Optional)">
                  <ImageUploader
                    value={spec.thumbnail}
                    onChange={setValue("thumbnail")}
                    width={320}
                    height={180}
                  />
                </FormInputWrap>
              </>
              <>
                {spec.sendEmail && (
                  <>
                    <Text fontSize={4}>Email Template</Text>
                    <Text color="grey1" mb={3}>
                      This is the email that will be sent to your recipients.
                    </Text>
                    <FormInput
                      name="subject"
                      label="Email Subject"
                      value={spec.emailSubject}
                      onChange={(ev) =>
                        setValue("emailSubject")(ev.target.value)
                      }
                    />
                    <RichTextEditor
                      value={spec.emailBody}
                      onChange={setValue("emailBody")}
                    />
                  </>
                )}
              </>
            </Box>
            <Flex>
              <Button
                color="primary"
                ml={1}
                onClick={() => submit(false)}
                disabled={submitting}
              >
                {spec.isDraft ? "Save & Send" : "Save"}
              </Button>
              {spec.isDraft && (
                <Button
                  color="primary"
                  ml={1}
                  onClick={() => submit(true)}
                  disabled={submitting}
                >
                  Save as Draft
                </Button>
              )}
            </Flex>
          </Panel>
        </>
      )}
    </CenterColumn>
  );
}
