import {
  discriminate,
  UserFieldDto,
  UserFieldType,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormInputWrap,
  FormSelect,
  Modal,
  MultiSelect,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import { useEffect, useState } from "react";

export type UserFieldCondition = {
  userFieldId: string;
  userFieldOptionIds: string[];
};

export type UserFieldConditionModalProps = {
  fields: UserFieldDto[];
  onCancel: () => void;
  onSave: (condition: UserFieldCondition) => void;
};

export const UserFieldConditionModal = ({
  fields,
  onCancel,
  onSave,
}: UserFieldConditionModalProps) => {
  const [field, setField] = useState<string>("");
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    setValue([]);
  }, [fields, field]);

  const selectFields = fields.filter(
    discriminate("type", UserFieldType.Select)
  );

  const fieldOptions = [
    {
      label: "<Select One>",
      value: "",
    },
    ...selectFields.map((x) => ({
      label: x.name,
      value: x.id,
    })),
  ];

  const selectedField = selectFields.find((x) => x.id === field);

  const valueOptions =
    selectedField == null
      ? [{ value: "", label: "<Select Field>" }]
      : [
          ...selectedField.options.map((x) => ({
            value: x.id,
            label: x.name,
          })),
        ];

  const save = () => {
    onSave({
      userFieldId: field,
      userFieldOptionIds: value,
    });
  };

  const canSave = field !== "" && value.length > 0;

  const noFields = selectFields.length === 0;

  return (
    <Modal onClose={onCancel} showCloseButton width={400}>
      <PanelHeader>
        <Text fontSize={4} fontWeight={500}>
          User Field Condition
        </Text>
      </PanelHeader>
      <Box p={3}>
        {noFields ? (
          <Text textAlign={"center"} fontWeight={500}>
            No User Fields of the "Select" type.
          </Text>
        ) : (
          <>
            <FormSelect
              label="Field"
              options={fieldOptions}
              value={field}
              onChange={(ev) => setField(ev.target.value)}
            />
            <FormInputWrap label={"Is equal to"} mb={4}>
              <MultiSelect
                disabled={selectedField == null}
                options={valueOptions}
                value={value}
                onChange={setValue}
              />
            </FormInputWrap>
            <Flex>
              <Button onClick={save} disabled={!canSave} color="primary">
                Save
              </Button>
              <Button onClick={onCancel} ml={1}>
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Modal>
  );
};
