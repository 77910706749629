import { ProductVisibility } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  GaugeChart,
  Grid,
  Loader,
  Panel,
  SectionHeading,
  Table,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import ShareModal, { ShareConfig } from "components/ShareModal";
import { addDays, fromUnixTime, isFuture, isToday } from "date-fns";
import { useProductVisibility } from "hooks/useProductVisibility";
import { useTeam } from "queries/performance";
import {
  useDeleteRelationship,
  useMyRelationships,
  useReorderTeam,
} from "queries/team";
import { useState } from "react";
import { FaList, FaUserPlus } from "react-icons/fa";
import styled from "styled-components";
import { isOverdue } from "utils/content";
import AddRelationshipModal from "./AddRelationshipModal";
import { ReorderTeamModal } from "./ReorderTeamModal";
import { ManagerItem, TeamMember } from "./TeamMember";

const TeamMemberTable = styled(Table)`
  th,
  td {
    font-size: 12px;
    position: relative;
  }

  td {
    border-bottom: 0 !important;
    border-top: 1px solid ${(props) => props.theme.colors.grey3};
  }

  tr.child td {
    border-top: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    th,
    td {
      font-size: 14px;
    }
  }
`;

export const OldTeamView = () => {
  const [user] = useUser();
  const hasPerf =
    useProductVisibility("performance") === ProductVisibility.Enabled;
  const { data: team, refetch: refetchTeam, isFetched } = useTeam();
  const {
    data: relationships,
    isFetched: relsFeteched,
    refetch: refetchRels,
  } = useMyRelationships();
  const [showModal, setShowModal] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [shareConfig, setShareConfig] = useState<ShareConfig | null>(null);

  const deleteRelationshipMut = useDeleteRelationship();
  const terms = useGoalTerminology();
  const reorderTeamMut = useReorderTeam();

  const deleteRelationship = async (id: string) => {
    if (window.confirm("Are you sure?")) {
      await deleteRelationshipMut.mutateAsync(id);
      refetchTeam();
    }
  };

  const shareModule = (id: string) => {
    setShareConfig({
      type: "module",
      targetId: id,
      itemId: null,
      targetType: "user",
    });
  };

  if (!isFetched || !relsFeteched) {
    return <Loader />;
  }

  const managers = relationships.filter(
    (x) => x.type === "manager" && x.from.id !== user.id,
  );

  const acceptedTeam = team.directReports.filter(
    (x) =>
      x.relationship.toAccepted != null && x.relationship.fromAccepted != null,
  );

  const activeGoals = acceptedTeam.filter((x) =>
    x.goals.some((x) => x.completedOn == null),
  );

  const overdueGoals = acceptedTeam.filter((x) => x.goals.some(isOverdue));

  const nextConvo = acceptedTeam.filter((x) =>
    x.conversations.some(
      (y) =>
        y.completedOn == null &&
        y.cancelledOn == null &&
        (isFuture(fromUnixTime(y.scheduledDate)) ||
          isToday(fromUnixTime(y.scheduledDate))),
    ),
  );

  const feedbackReceived = team.directReports.filter((user) =>
    user.sentFeedback.some(
      (feedback) => fromUnixTime(feedback.sentOn) > addDays(new Date(), -30),
    ),
  );

  const reorderTeam = async (newOrder: string[]) => {
    await reorderTeamMut.mutateAsync(newOrder);
    setShowReorderModal(false);
  };

  return (
    <>
      {shareConfig && (
        <ShareModal config={shareConfig} onClose={() => setShareConfig(null)} />
      )}
      {showReorderModal && (
        <ReorderTeamModal
          onClose={() => setShowReorderModal(false)}
          onReorder={reorderTeam}
        />
      )}
      {showModal && (
        <AddRelationshipModal
          onClose={(newRelationship) => {
            if (newRelationship) {
              refetchTeam();
              refetchRels();
            }
            setShowModal(false);
          }}
        />
      )}

      <Grid
        gridGap={2}
        mb={5}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
      >
        <Flex flexDirection={"column"}>
          <Panel p={3} height="100%">
            <Text fontWeight={600} textAlign={"center"}>
              Direct reports with:
            </Text>
            <Text color={"primary"} fontWeight={600} textAlign={"center"}>
              Active Goals
            </Text>
            <GaugeChart
              value={activeGoals.length}
              total={team.directReports.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex flexDirection={"column"}>
          <Panel p={3} height="100%">
            <Text fontWeight={600} textAlign={"center"}>
              Direct reports with:
            </Text>
            <Text color={"primary"} fontWeight={600} textAlign={"center"}>
              Overdue Goals
            </Text>
            <GaugeChart
              value={overdueGoals.length}
              total={team.directReports.length}
              invertColours={true}
            />
          </Panel>
        </Flex>
        <Flex flexDirection={"column"}>
          <Panel p={3} height="100%">
            <Text fontWeight={600} textAlign={"center"}>
              Direct reports with:
            </Text>
            <Text color={"primary"} fontWeight={600} textAlign={"center"}>
              Upcoming Conversations
            </Text>
            <GaugeChart
              value={nextConvo.length}
              total={team.directReports.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex flexDirection={"column"}>
          <Panel p={3} height="100%">
            <Text fontWeight={600} textAlign={"center"}>
              Direct reports with:
            </Text>
            <Text color={"primary"} fontWeight={600} textAlign={"center"}>
              Feedback sent in the last 30 days
            </Text>
            <GaugeChart
              value={feedbackReceived.length}
              total={team.directReports.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
      </Grid>

      <Flex alignItems="center" mb={3} justifyContent="space-between">
        <SectionHeading mb={0}>Your Direct Reports</SectionHeading>
        <Flex>
          <Button
            ml={3}
            color="primary"
            onClick={() => setShowModal(true)}
            icon={FaUserPlus}
          >
            Add Direct Report
          </Button>
          <Button
            ml={3}
            color="primary"
            onClick={() => setShowReorderModal(true)}
            icon={FaList}
          >
            Reorder
          </Button>
        </Flex>
      </Flex>

      <Panel mb={5} pt={[2, hasPerf ? 0 : 2]}>
        <Box overflowX="auto">
          <TeamMemberTable>
            <thead>
              <tr>
                <th />
                <th>Name</th>
                {hasPerf && <th>{terms.goal.asPluralTitle()}</th>}
                <th>Lessons</th>
                <th>Mindset</th>
                {hasPerf && (
                  <>
                    <th>Last Conversation</th>
                    <th>Next Conversation</th>
                    <th>Last Sent Feedback</th>
                    <th>Last Received Feedback</th>
                  </>
                )}
                <th />
              </tr>
            </thead>
            <tbody>
              {team.directReports.map((x) => (
                <TeamMember
                  child={false}
                  key={x.relationship.id}
                  onShare={() => shareModule(x.id)}
                  member={x}
                  onRemove={() => deleteRelationship(x.relationship.id)}
                />
              ))}
              {team.directReports.length === 0 && (
                <td colSpan={9}>No Direct Reports</td>
              )}
            </tbody>
          </TeamMemberTable>
        </Box>
      </Panel>

      <SectionHeading>Your Managers</SectionHeading>
      <Panel pt={2}>
        {managers.length === 0 ? (
          <Text
            color="grey2"
            fontWeight={500}
            pl={2}
            pr={2}
            pb={2}
            textAlign="center"
          >
            No Line Manager Assigned
          </Text>
        ) : (
          managers.map((x) => (
            <ManagerItem
              key={x.id}
              manager={x.from}
              confirmed={x.fromAccepted != null && x.toAccepted != null}
            />
          ))
        )}
      </Panel>
    </>
  );
};
