import { UserPermission } from "@coaching-culture/types";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { Route, Redirect, RouteProps, useLocation } from "react-router";
import { useUser } from "./index";
import { useOrg } from "./OrgProvider";

export type AuthenticatedRouteProps = {
  requireRole?: UserPermission[];
  product?: string | string[] | null;
} & RouteProps;

const AuthenticatedRoute = ({
  requireRole = [],
  product = null,
  ...props
}: AuthenticatedRouteProps) => {
  const [user] = useUser();
  const [org] = useOrg();
  const location = useLocation();
  const products = useAllProductVisibility();

  const path = location.pathname;

  const redirectRoot = path.startsWith("/solutions")
    ? "/solutions/"
    : path.startsWith("/community")
      ? "/community/"
      : path.startsWith("/success")
        ? "/success/"
        : "/";

  if (user == null || org == null) {
    return (
      <Redirect
        to={{
          pathname: redirectRoot + "login",
          state: { from: props.location },
        }}
      />
    );
  }

  if (user.forcePasswordReset && user.impersonator == null) {
    return (
      <Redirect
        to={{
          pathname: redirectRoot + "change-password",
          state: { from: props.location },
        }}
      />
    );
  }

  if (product != null) {
    const availableProducts = Object.entries(products)
      .filter(([, vis]) => vis === 3)
      .map(([ident]) => ident);

    let p = Array.isArray(product) ? product : [product];

    if (!availableProducts.some((x) => p.includes(x))) {
      return <Redirect to="/" />;
    }
  }

  if (
    requireRole.length > 0 &&
    !requireRole.some((x) => user.permissions.includes(x))
  ) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

export default AuthenticatedRoute;
