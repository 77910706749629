import { Flex, Text } from "@coaching-culture/ui";
import { range } from "lodash";
import React, { useState } from "react";
import { FaBan } from "react-icons/fa";
import { animated, config, useSpring, useTrail } from "react-spring";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

const DotButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 6px 12px;
  margin-bottom: 12px;
`;

const DotButtonElem = styled(animated.button)<
  { color: string } & SpaceProps & LayoutProps
>`
  border: 0;
  cursor: pointer;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: white;
  margin: 12px;
  font-weight: 600;
  background: ${(props) => props.theme.colors[props.color]};
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${space};
  ${layout};
`;

type RatingTypeProps = {
  reverse: boolean;
  value: number;
  onChange: (val: number) => void;
  requiresEvidence: boolean;
  highEndLabel: string | null;
  lowEndLabel: string | null;
  noEvidenceLabel: string;
  allowNa: boolean;
};

type RatingButtonProps = {
  active: boolean;
  text: string;
  onClick: () => void;
};

const NoEvidenceButton = ({
  active,
  onClick,
  label,
}: {
  active: boolean;
  onClick: () => void;
  label: string;
}) => {
  const [hov, setHov] = useState<boolean>(false);

  const props = useSpring({
    scale: active ? 1.3 : 1,
    opacity: active || hov ? 1 : 0.3,
  });

  return (
    <DotButtonContainer>
      <Text textAlign="center" fontSize="12px" mb={1}>
        {label}
      </Text>
      <DotButtonElem
        color="danger"
        onClick={onClick}
        style={props}
        onMouseEnter={() => setHov(true)}
        onMouseLeave={() => setHov(false)}
      >
        <FaBan />
      </DotButtonElem>
    </DotButtonContainer>
  );
};

const DotButton = ({ active, text, onClick }: RatingButtonProps) => {
  const [hov, setHov] = useState<boolean>(false);

  const props = useSpring({
    scale: active ? 1.3 : 1,
    opacity: active || hov ? 1 : 0.3,
  });

  return (
    <DotButtonElem
      color="positive"
      onClick={onClick}
      style={props}
      onMouseEnter={() => setHov(true)}
      onMouseLeave={() => setHov(false)}
      m={["4px", 2]}
      width={["30px", "38px"]}
      height={["30px", "38px"]}
    >
      {text}
    </DotButtonElem>
  );
};

export const NumbersRating = ({
  requiresEvidence,
  reverse,
  value,
  onChange,
  lowEndLabel,
  highEndLabel,
  noEvidenceLabel,
  allowNa,
}: RatingTypeProps) => {
  const items = range(1, 11).map((x) => ({
    text: x.toString(),
  }));

  let trail = useTrail(requiresEvidence || allowNa ? 11 : 10, {
    config: config.stiff,
    from: {
      y: 20 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex justifyContent="center" flexWrap="wrap" mb={[0, 5]}>
      <Flex flexDirection="column">
        <Flex>
          {trail.slice(0, 10).map((styles, i) => (
            <animated.div style={styles}>
              <DotButton
                {...items[i]}
                active={i + 1 === value}
                onClick={() => onChange(i + 1)}
              />
            </animated.div>
          ))}
        </Flex>
        <Flex justifyContent="space-between" p={2}>
          <Text fontWeight={600}>{lowEndLabel}</Text>
          <Text fontWeight={600}>{highEndLabel}</Text>
        </Flex>
      </Flex>
      {(requiresEvidence || allowNa) && (
        <animated.div style={trail[10]}>
          <NoEvidenceButton
            label={allowNa ? "N/A" : noEvidenceLabel}
            active={value === -1}
            onClick={() => onChange(-1)}
          />
        </animated.div>
      )}
    </Flex>
  );
};
