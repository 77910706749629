import CenterColumn from "components/CenterColumn";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { AssessmentList } from "./AssessmentList";
import { PreviousResults } from "./PreviousResults";

export default function Assessments() {
  return (
    <CenterColumn>
      <Switch>
        <Route path="/solutions/assessments" exact component={AssessmentList} />
        <Route
          path="/solutions/assessments/me"
          exact
          component={PreviousResults}
        />
      </Switch>
    </CenterColumn>
  );
}
