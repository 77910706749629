import { TeamGoalDto } from "@coaching-culture/types";
import {
  Button,
  Flex,
  FormInput,
  FormTextArea,
  Modal,
  Text,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export type EditTeamGoalModalProps = {
  goal?: TeamGoalDto | null;
  onSave: (goal: TeamGoalDto) => void;
  onCancel: () => void;
};

export function EditTeamGoalModal({
  goal,
  onSave,
  onCancel,
}: EditTeamGoalModalProps) {
  const { handleSubmit, reset, errors, register } = useForm<TeamGoalDto>();
  const terms = useGoalTerminology();

  useEffect(() => {
    if (goal != null) {
      reset(goal);
    }
  }, [goal, reset]);

  const onSubmit = (values: TeamGoalDto) => {
    const spec = {
      ...values,
      id: goal == null ? undefined : goal.id,
    };
    onSave(spec);
  };

  return (
    <Modal width={600} showCloseButton onClose={onCancel} p={5}>
      <Text fontSize={5} fontWeight={600} mb={4}>
        {goal == null
          ? `Create Team ${terms.goal.asTitle()}`
          : `Edit Team ${terms.goal.asTitle()}`}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          ref={register({ required: true })}
          name="name"
          label="Name"
          error={errors.name}
          required
        />
        <FormTextArea
          ref={register}
          name="description"
          label="Description"
          mb={5}
        />
        <Flex>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button ml={2} type="button" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      </form>
    </Modal>
  );
}
