import { Section } from "@coaching-culture/types";
import {
  Label,
  Panel,
  PanelHeader,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { meanBy } from "lodash";
import { useMemo } from "react";
import { normalizeRating } from "./UserCampaignDisplay";

export const FeedbackQuestionList = ({
  sections,
  campaignUserEmail,
}: {
  sections: Section[];
  campaignUserEmail: string;
}) => {
  const data = sections.flatMap((section) => {
    return section.items
      .filter((x) => x.type === "rating")
      .map((item) => ({
        ...item,
        section:
          section.heading != null ? section.heading.content : "No Section",
      }));
  });

  const cols = useMemo<SortableTableColumn<any>[]>(() => {
    return [
      {
        label: "Section",
        name: "section",
        format: (x) => <Label color="primary">{x.section}</Label>,
      },
      {
        label: "Question",
        name: "question",
        format: (x) => <Text>{x.content}</Text>,
      },
      {
        label: "Score",
        name: "score",
        sortFormat: (x) =>
          meanBy(
            x.responses.filter(
              (x) => x.rating != null && x.email !== campaignUserEmail,
            ),
            (y) => normalizeRating((y as any).rating, x.ratingType),
          ).toFixed(2),
        format: (x) => (
          <Text color={"primary"} fontWeight={600}>
            {x.responses.length === 0
              ? "No Responses"
              : meanBy(
                  x.responses.filter(
                    (x) => x.rating != null && x.email !== campaignUserEmail,
                  ),
                  (y) => normalizeRating((y as any).rating, x.ratingType),
                ).toFixed(2)}
          </Text>
        ),
      },
    ];
  }, [campaignUserEmail]);

  return (
    <Panel>
      <PanelHeader>
        <Text fontWeight={600} fontSize={3}>
          Respondent Average Score - List View
        </Text>
      </PanelHeader>
      <SortableTable columns={cols} data={data} />
    </Panel>
  );
};
