import {
  FormResponses,
  SurveyCampaignDto,
  SurveySpec,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getSurvey = (id: string) =>
  axios
    .get<SurveyCampaignDto>(`/api/v2/surveys/${id}`)
    .then(({ data }) => data);

const addRespondentsToSurvey = (id: string, userIds: string[]) =>
  axios.post(`/api/v2/surveys/${id}/respondents`, userIds);

const nudgeUser = (surveyId: string, userId: string) =>
  axios.put(`/api/v2/surveys/${surveyId}/respondents/${userId}/nudge`);

const nudgeAll = (surveyId: string) =>
  axios.put(`/api/v2/surveys/${surveyId}/nudge`);

// queries

export const useSurvey = (id: string) =>
  useQuery(["surveys", id], () => getSurvey(id));

export const useAddRespondentsToSurvey = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (userIds: string[]) => addRespondentsToSurvey(id, userIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["surveys"]);
      },
    }
  );

  return mutation;
};

export const useNudgeSurveyRespondent = (surveyId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (userId: string) => nudgeUser(surveyId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["surveys"]);
      },
    }
  );

  return mutation;
};

export const useNudgeAllSurveyRespondents = (surveyId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(() => nudgeAll(surveyId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["surveys"]);
    },
  });

  return mutation;
};

const deleteSurveyCampaign = (id: string) =>
  axios.delete(`/api/v2/surveys/${id}`);

export const useDeleteSurveyCampaign = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => deleteSurveyCampaign(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("surveys");
    },
  });

  return mutation;
};

const getSurveyCampaigns = () =>
  axios.get<SurveyCampaignDto[]>(`/api/surveys`).then(({ data }) => data);

export const useSurveyCampaigns = () =>
  useQuery(["surveys"], () => getSurveyCampaigns());

const updateSurveyCampaign = (id: string, spec: Partial<SurveySpec>) =>
  axios.put(`/api/v2/surveys/${id}`, spec);

export const useUpdateSurveyCampaign = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: Partial<SurveySpec>) => updateSurveyCampaign(id, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["surveys"]);
      },
    }
  );

  return mutation;
};

const createSurveyCampaign = (spec: Partial<SurveySpec>) =>
  axios.post(`/api/v2/surveys`, spec);

export const useCreateSurveyCampaign = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: Partial<SurveySpec>) => createSurveyCampaign(spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["surveys"]);
      },
    }
  );

  return mutation;
};

const deleteUserFromSurvey = (id: string, userId: string) =>
  axios.delete(`/api/v2/surveys/${id}/users/${userId}`);

export const useDeleteUserFromSurvey = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (userId: string) => deleteUserFromSurvey(id, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("surveys");
      },
    }
  );

  return mutation;
};

const getSurveyReport = (id: string) =>
  axios
    .get<FormResponses[]>(`/api/surveys/${id}/report`)
    .then(({ data }) => data);
export const useSurveyReport = (id: string) =>
  useQuery(["surveys", id, "report"], () => getSurveyReport(id));
