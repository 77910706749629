import {
  discriminate,
  UserField,
  UserFieldType,
  UserSummary,
} from "@coaching-culture/types";
import {
  Button,
  CheckBox,
  Flex,
  IconButton,
  InputWithSearch,
  Label,
  Loader,
  Panel,
  Select,
  SortableTable,
  SortableTableColumn,
  useDebounce,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { UserFlag } from "components/UserFlag";
import { format } from "date-fns";
import { sortBy, uniqBy } from "lodash";
import { useUserFields } from "queries/users";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaTrash, FaUserCircle, FaUserPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { saveExportAsCsv } from "utils";
import { useOrg } from "../../auth/OrgProvider";

const userRole = (user: UserSummary) =>
  user.permissions.includes("org-admin")
    ? "Admin"
    : user.permissions.includes("manager")
      ? "Manager"
      : "User";

const userRoleColors = {
  Admin: "danger",
  Manager: "warning",
  User: "blue",
};

export const Users = () => {
  const [users, setUsers] = useState<UserSummary[] | null>(null);
  const { data: userFields, isFetched } = useUserFields();
  const [search, setSearch] = useState<string>("");
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [filteredGroup, setFilteredGroup] = useState<string>("");
  const history = useHistory();
  const [org] = useOrg();

  const loading = users == null || isFetched === false;

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    axios.get(`/api/v2/users`).then(({ data }) => {
      setSelected({});
      setUsers(sortBy(data, "name"));
    });
  }, [org.id]);

  const selectFields = (userFields || []).filter(
    discriminate("type", UserFieldType.Select),
  );

  const toggleSelected = useCallback((id: string, v: boolean) => {
    setSelected((old) => ({
      ...old,
      [id]: v,
    }));
  }, []);

  const filtered = useMemo(() => {
    let filtered =
      debouncedSearch.trim() === ""
        ? users || []
        : (users || []).filter((x) =>
            [x.name, x.email]
              .map((x) => x.toLowerCase())
              .some((x) => x.includes(debouncedSearch.toLowerCase())),
          );

    if (filteredGroup !== "") {
      filtered = filtered.filter((x) =>
        x.groups.some((g) => g.id.toString() === filteredGroup),
      );
    }
    return filtered;
  }, [debouncedSearch, users, filteredGroup]);

  const groups = uniqBy(
    (users ?? []).flatMap((x) => x.groups),
    "id",
  );

  const groupOptions = [
    { value: "", label: "(All Groups)" },
    ...groups.map((x) => ({
      value: x.id.toString(),
      label: x.name,
    })),
  ];

  const filteredSelected = filtered.filter((x) => selected[x.id]);

  const bulkDelete = () => {
    const toDelete = filteredSelected.map((x) => x.id);

    if (
      toDelete.length > 0 &&
      window.confirm(
        `Are you sure you want to delete ${toDelete.length} users?`,
      )
    ) {
      setUsers((old) => old.filter((x) => !toDelete.includes(x.id)));
      Promise.all(toDelete.map((x) => axios.delete(`/api/users/${x}`)));
    }
  };

  const deleteUser = useCallback((user: UserSummary) => {
    if (window.confirm(`Are you sure you want to delete user: ${user.name}`)) {
      setUsers((old) => old.filter((x) => x.id !== user.id));
      axios.delete(`/api/users/${user.id}`);
    }
  }, []);

  const formatUserField = useCallback(
    (user: UserSummary, userFieldDef: UserField) => {
      const field = user.userFields?.find((uf) => uf.id === userFieldDef.id);
      if (field != null) {
        if (userFieldDef.type === UserFieldType.Select) {
          const selectField = selectFields.find(
            (f) => f.id === userFieldDef.id,
          );
          return selectField.options.find((option) => option.id === field.value)
            ?.name;
        } else {
          return field.value as string;
        }
      }
      return "";
    },
    [selectFields],
  );

  const getUserFieldCols = useMemo(() => {
    return userFields?.map((y) => ({
      label: userFields?.find((ufo) => ufo.id === y.id).name,
      headingProps: {
        style: {
          textAlign: "center",
          whiteSpace: "nowrap",
        },
      },
      name: y.id,
      props: {
        style: {
          textAlign: "center",
        },
      },
      hidden: true,
      sortFormat: (x: UserSummary) => {
        return formatUserField(x, y);
      },
      format: (x: UserSummary) => {
        return formatUserField(x, y);
      },
      exportFormat: (x: UserSummary) => {
        return formatUserField(x, y);
      },
    }));
  }, [userFields, formatUserField]);

  const cols = useMemo(
    (): SortableTableColumn<UserSummary>[] => [
      {
        name: "selected",
        label: "",
        excludeFromExport: true,
        props: {
          style: {
            width: "1px",
          },
        },
        format: (x) => (
          <CheckBox
            mb={0}
            value={selected[x.id] || false}
            onChange={(v) => toggleSelected(x.id, v)}
          />
        ),
      },
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Link to={`/success/people/users/${x.id}`}>
            <UserFlag
              user={{
                id: x.id,
                name: x.name,
                email: null,
                profileImage: x.profileImage,
              }}
            />
          </Link>
        ),
        sortFormat: (x) => x.name.toLowerCase(),
        exportFormat: (x) => x.name,
      },
      {
        name: "manager",
        label: "Manager",
        format: (x) =>
          x.managers.map((manager, i) => (
            <Link to={`/success/people/users/${manager.id}`}>
              <UserFlag
                mb={i !== x.managers.length - 1 ? 1 : 0}
                user={{
                  id: manager.id,
                  name: manager.name,
                  email: null,
                  profileImage: manager.profileImage,
                }}
              />
            </Link>
          )),

        sortFormat: (x) =>
          x.managers.length === 0
            ? ""
            : x.managers.sort()[0].name.toLowerCase(),
        exportFormat: (x) =>
          x.managers.map((manager) => manager.name).toString(),
      },
      {
        name: "email",
        label: "Email",
        props: {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        },
      },
      {
        name: "lastAccess",
        label: "Last Accessed",
        props: { fontSize: "13px", color: "grey1" },
        format: (x) =>
          x.lastAccess == null
            ? "Never"
            : format(new Date(x.lastAccess), "dd/MM/yyyy HH:mm"),
        sortFormat: (x) =>
          x.lastAccess == null ? 0 : new Date(x.lastAccess).getTime(),
      },
      {
        name: "dateCreated",
        label: "User Created",
        props: { fontSize: "13px", color: "grey1" },
        format: (x) => format(new Date(x.createdAt), "dd/MM/yyyy HH:mm"),
        sortFormat: (x) => new Date(x.createdAt).getTime(),
      },
      {
        name: "groups",
        label: "Groups",
        format: (x) =>
          sortBy(x.groups, "id").map((x, i, self) => (
            <>
              <Link key={x.id} to={`/success/people/groups/${x.id}`}>
                {x.name}
              </Link>
              {i < self.length - 1 && " | "}
            </>
          )),
        exportFormat: (x) => x.groups.map((x) => x.name).join(" | "),
        sortFormat: (x) => x.groups.map((x) => x.name).join(" | "),
      },
      {
        name: "role",
        label: "Role",
        format: (x) => (
          <Label size="small" color={userRoleColors[userRole(x)]}>
            {userRole(x)}
          </Label>
        ),
        sortFormat: (x) => userRole(x),
        exportFormat: (x) =>
          x.permissions.includes("org-admin")
            ? "org-admin"
            : x.permissions.includes("manager")
              ? "manager"
              : "user",
      },
      {
        name: "controls",
        label: "",
        excludeFromExport: true,
        props: {
          style: {
            textAlign: "right",
            width: "1px",
          },
        },
        format: (x) => (
          <Flex alignItems="center">
            <IconButton
              color="body"
              icon={FaUserCircle}
              onClick={() => history.push(`/people/${x.id}`)}
            />
            <IconButton
              ml={2}
              color="danger"
              icon={FaTrash}
              onClick={() => deleteUser(x)}
            />
          </Flex>
        ),
      },
    ],
    [deleteUser, selected, toggleSelected, history],
  );

  return (
    <CenterColumn>
      <PageHeader
        text="Users Management"
        subtitle="Create and manage users within the platform"
      />
      <Flex
        justifyContent="space-between"
        alignItems={["stretch", "center"]}
        mb={2}
        flexDirection={["column", "row"]}
      >
        <Flex
          alignItems={["stretch", "center"]}
          justifyContent="stretch"
          flexDirection={["column", "row"]}
        >
          <Button
            to="/success/people/users/create"
            mr={[0, 1]}
            mb={[1, 0]}
            color="primary"
            icon={FaUserPlus}
          >
            Add New User
          </Button>
          <Button
            mb={[1, 0]}
            to="/success/people/users/bulk-upload"
            color="primary"
            icon={FaUserPlus}
            mr={[0, 1]}
          >
            Bulk Upload
          </Button>
          <Button
            mb={[1, 0]}
            to="/success/people/users/bulk-delete"
            color="danger"
            icon={FaTrash}
            mr={[0, 1]}
          >
            Bulk Delete
          </Button>

          {filteredSelected.length > 0 && (
            <Button color="danger" onClick={bulkDelete} mb={[1, 0]}>
              Delete Selected
            </Button>
          )}
        </Flex>
        <Flex alignItems="center" flexDirection={["column", "row"]}>
          <InputWithSearch
            onClear={() => setSearch("")}
            searching={search !== debouncedSearch}
            width={[1, 400] as any}
            placeholder="Search Users..."
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            mr={[0, 2]}
            mb={[1, 0]}
          />
          <Select
            options={groupOptions}
            value={filteredGroup}
            onChange={(ev) => setFilteredGroup(ev.target.value)}
          />
        </Flex>
      </Flex>
      <Panel style={{ overflow: "hidden" }}>
        {loading ? (
          <Loader />
        ) : (
          <SortableTable<UserSummary>
            columns={cols.concat(getUserFieldCols)}
            data={filtered}
            defaultSort={1}
            rowKey="id"
            paged
            onExport={saveExportAsCsv("CCUserExport.csv")}
          />
        )}
      </Panel>
    </CenterColumn>
  );
};
