import React from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const InnerContainer = styled(animated.div)`
  width: 100%;
  display: flex;
`;

export const PanelCarouselSlide = styled.div`
  flex: 0 0 100%;
`;

export const PanelCarousel = ({ index, children }) => {
  const styles = useSpring({
    transform: `translateX(-${index * 100}%)`
  });

  return (
    <Container>
      <InnerContainer style={styles}>{children}</InnerContainer>
    </Container>
  );
};
