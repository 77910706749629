import {
  CampaignSpec,
  CampaignSummary,
  FeedbackCampaign,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getFeedbackCampaigns = () =>
  axios
    .get<CampaignSummary[]>(`/api/feedback/campaigns`)
    .then(({ data }) => data);

export const useFeedbackCampaigns = () =>
  useQuery(["feedback-campaigns"], () => getFeedbackCampaigns());

const getFeedbackCampaign = (id: string) =>
  axios
    .get<FeedbackCampaign>(`/api/feedback/campaigns/${id}`)
    .then(({ data }) => data);

export const useFeedbackCampaign = (id: string) =>
  useQuery(["feedback-campaigns"], () => getFeedbackCampaign(id));

const deleteFeedbackCampaign = (id: string) =>
  axios.delete(`/api/v2/feedback-campaigns/${id}`);

export const useDeleteFeedbackCampaign = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => deleteFeedbackCampaign(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("feedback-campaigns");
    },
  });

  return mutation;
};

const deleteUserFromCampaign = (id: string, userId: string) =>
  axios.delete(`/api/v2/feedback-campaigns/${id}/users/${userId}`);

export const useDeleteUserFromCampaign = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (userId: string) => deleteUserFromCampaign(id, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("feedback-campaigns");
      },
    }
  );

  return mutation;
};

const updateFeedbackCampaign = (id: string, spec: Partial<CampaignSpec>) =>
  axios.put(`/api/v2/feedback-campaigns/${id}`, spec);

export const useUpdateFeedbackCampaign = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: Partial<CampaignSpec>) => updateFeedbackCampaign(id, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["feedback-campaigns"]);
      },
    }
  );

  return mutation;
};
