import { useUser } from "auth";
import { useOrg } from "auth/OrgProvider";
import { useEffect } from "react";

export const PendoUser = () => {
  const [user] = useUser();
  const [org] = useOrg();

  useEffect(() => {
    if (user !== null) {
      window.pendo.initialize({
        visitor: {
          id: user.id,
          email: user.email,
          full_name: user.name,
          orgName: user.org.name,
          role: user.permissions.toString(),
        },
        account: {
          id: org.id,
          name: org.name,
          type: org.status,
        },
      });
    }
  }, [user, org]);
  return null;
};
