import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router";
import { animated, useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { useWindowSize } from "react-use";
import styled, { css } from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

export const FixedSidebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const FixedSidebarMain = styled.main`
  flex: 1;
  background-color: #fafafa;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};

    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

type ContainerProps = {
  alwaysFloat: boolean;
  onLeft: boolean;
  width: number;
};

type HideButtonProps = {
  onLeft: boolean;
};

const Container = styled(animated.div)<ContainerProps>`
  ${(props) =>
    props.onLeft
      ? css`
          left: 0;
          box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
        `
      : css`
          right: 0;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
        `};
  width: ${(props) => props.width}px;
  background: white;
  z-index: 50;
  position: fixed;
  height: 100%;
`;

const HideButton = styled.button<HideButtonProps & LayoutProps>`
  position: absolute;
  border: 1px solid transparent;
  ${(props) =>
    props.onLeft
      ? css`
          left: 100%;
          box-shadow: 3px 0 3px rgba(0, 0, 0, 0.14);
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: 0;
        `
      : css`
          left: -28px;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.14);
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-right: 0;
        `};
  top: 90px;
  width: 28px;
  height: 32px;
  background-color: ${(props) => props.theme.colors.bg};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${layout};
`;

type FloatingSidebarProps = {
  children: React.ReactNode;
  side: string;
  floatWidth: number;
  width: number;
  alwaysFloat?: boolean;
  className?: string;
};

export const Sidebar = ({
  children,
  side,
  floatWidth,
  width,
  alwaysFloat,
  className,
}: FloatingSidebarProps) => {
  const { width: windowWidth } = useWindowSize();
  const [open, setOpen] = useState(!alwaysFloat && windowWidth > floatWidth);

  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null!);
  const shouldFloat = alwaysFloat || windowWidth < floatWidth;

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (!alwaysFloat && windowWidth > floatWidth) {
      setOpen(true);
    } else if (windowWidth < floatWidth) {
      setOpen(false);
    }
  }, [alwaysFloat, floatWidth, windowWidth]);

  const handlers = useSwipeable({
    onSwipedRight: () => setOpen(side === "left"),
    onSwipedLeft: () => setOpen(side === "right"),
  });

  useEffect(() => {
    (handlers as any).ref(document);
  });

  const toggle = () => {
    setOpen((old) => !old);
  };

  const props = useSpring({
    transform: `translate3d(${
      (open || !shouldFloat ? 0 : width + 2) * (side === "left" ? -1 : 1)
    }px, 0px, 0px)`,
  });

  const OpenIcon = side === "left" ? FaChevronLeft : FaChevronRight;
  const CloseIcon = side === "right" ? FaChevronLeft : FaChevronRight;

  return (
    <Container
      className={className}
      style={{
        ...props,
      }}
      {...handlers}
      ref={ref}
      width={width}
      alwaysFloat={!!alwaysFloat}
      onLeft={side === "left"}
    >
      <HideButton
        onClick={toggle}
        onLeft={side === "left"}
        display={windowWidth < floatWidth || alwaysFloat ? "flex" : "none"}
      >
        {open ? <OpenIcon /> : <CloseIcon />}
      </HideButton>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};
