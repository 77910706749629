import {
  Box,
  Button,
  Flex,
  FormToggle,
  ReactModal,
  Text,
} from "@coaching-culture/ui";
import { useState } from "react";

export type CancelConversationModalProps = {
  onCancel: () => void;
  onConfirm: (cancelAll: boolean) => void;
  isOpen: boolean;
};

export const CancelConversationModal = ({
  onCancel,
  onConfirm,
  isOpen,
}: CancelConversationModalProps) => {
  const [all, setAll] = useState(false);

  return (
    <ReactModal onClose={onCancel} isOpen={isOpen} width={600}>
      <Box p={4}>
        <Text fontWeight={600} mb={5} fontSize={4}>
          Are you sure you'd like to cancel this conversation?
        </Text>
        <FormToggle
          mb={5}
          label="Cancel all?"
          helpText="Cancel every instance of this conversation"
          value={all}
          onChange={setAll}
        />
        <Flex style={{ gap: 6 }} justifyContent={"flex-end"}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button color="danger" onClick={() => onConfirm(all)}>
            Confirm
          </Button>
        </Flex>
      </Box>
    </ReactModal>
  );
};
