import { Button, Circle, Flex, Panel, Text } from "@coaching-culture/ui";
import { ContinuousFeedbackTable } from "pages/Solutions/ContinuousFeedback/MyContinuousFeedback";
import { FaArrowLeft, FaArrowRight, FaEnvelopeOpen } from "react-icons/fa";
import { useHistory } from "react-router";
import styled from "styled-components";
import { grid, GridProps } from "styled-system";

const Widget = styled(Panel)<GridProps>`
  ${grid};
`;

export type FeedbackWidgetProps = {
  userId: string;
};

export const FeedbackWidget = ({ userId }: FeedbackWidgetProps) => {
  const history = useHistory();

  const requestFeedback = () => {
    history.push(`/solutions/continuous-feedback/request/${userId}`);
  };

  const giveFeedback = () => {
    history.push(`/solutions/continuous-feedback/send/${userId}`);
  };

  return (
    <Widget width={"100%"}>
      <Flex borderBottom={1} height={83} justifyContent={"space-between"} p={4}>
        <Flex alignItems="center">
          <Circle
            icon={FaEnvelopeOpen}
            size={"medium"}
            color="primary"
            mr={2}
          />
          <Text
            fontSize={4}
            fontWeight={600}
            lineHeight={1}
            mr={1}
            color="black"
            as="h1"
          >
            Continuous Feedback
          </Text>
        </Flex>
        <Flex alignItems="end" justifyContent="center" flexDirection="row">
          <Button color="primary" icon={FaArrowRight} onClick={giveFeedback}>
            Send Feedback
          </Button>

          <Button
            ml={2}
            onClick={requestFeedback}
            color="primary"
            icon={FaArrowLeft}
          >
            Request Feedback
          </Button>
        </Flex>
      </Flex>

      <ContinuousFeedbackTable userId={userId} />
    </Widget>
  );
};
