import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

export type MergeField = {
  description: string;
  value: string;
};

type RichTextEditorProps = {
  value: string;
  onChange: (val: string) => void;
  mergeFields?: MergeField[];
  height?: number;
};

export function RichTextEditor({
  value,
  onChange,
  mergeFields,
  height,
}: RichTextEditorProps) {
  const [key, setKey] = useState(uuid());

  useEffect(() => {
    setKey(uuid());
  }, [mergeFields]);

  return (
    <Editor
      key={key}
      apiKey="ilegdcomtktj8vwzz66k8asu4mf90l0u1e0bkg8yr7ihz7kp"
      value={value}
      onEditorChange={(v) => onChange(v)}
      init={{
        height: height != null ? height : 500,
        menubar: false,
        relative_urls: false,
        remove_script_host: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        automatic_uploads: true,
        toolbar:
          "undo redo | formatselect | image |" +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          `removeformat | help ${mergeFields?.length ? "| mergefields" : ""}`,
        images_upload_handler: (blobInfo, success, failure, progress) => {
          axios
            .post<{ url: string; key: string }>("/api/uploads/signed", {
              filename: blobInfo.filename(),
            })
            .then(({ data }) => {
              axios
                .request({
                  url: data.url,
                  method: "PUT",
                  data: new File([blobInfo.blob()], data.key),
                  transformRequest: (data, headers) => {
                    delete headers.common["Authorization"];
                    return data;
                  },
                  onUploadProgress: (p) => {
                    if (progress != null) {
                      progress((p.loaded / p.total) * 100);
                    }
                  },
                })
                .then(() => {
                  success(process.env.REACT_APP_STATIC_ROOT + "/" + data.key);
                })
                .catch(() => {
                  failure("Upload failed");
                });
            });
        },
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        setup: (editor) => {
          editor.ui.registry.addMenuButton("mergefields", {
            text: "Merge Fields",
            fetch: function (callback) {
              const items = (mergeFields ?? []).map(
                ({ description, value }) => {
                  return {
                    type: "menuitem" as const,
                    text: description,
                    onAction: () => {
                      editor.insertContent(value);
                    },
                  };
                },
              );
              callback(items);
            },
          });
        },
      }}
    />
  );
}
