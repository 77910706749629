import {
  Box,
  Loader,
  Panel,
  Text,
  SortableTable,
  SortableTableColumn,
  Flex,
  InputWithSearch,
  Pagination,
} from "@coaching-culture/ui";
import React, { useMemo, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useOrg } from "../../auth/OrgProvider";
import { UserSummary } from "@coaching-culture/types";
import UserProfileImage from "components/UserProfileImage";
import { Link } from "react-router-dom";
import CenterColumn from "components/CenterColumn";

type UserIdentProps = {
  user: {
    profileImage?: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  to?: string;
};

const UserIdent = ({ user, to }: UserIdentProps) => (
  <Flex alignItems="center">
    <UserProfileImage
      mr={2}
      profileImage={user.profileImage}
      name={`${user.firstName} ${user.lastName}`}
    />
    <Box>
      {to ? (
        <Link to={to}>{`${user.firstName} ${user.lastName}`}</Link>
      ) : (
        <Text fontWeight={600}>{`${user.firstName} ${user.lastName}`}</Text>
      )}
      <Text fontSize="80%" color="grey2">
        {user.email}
      </Text>
    </Box>
  </Flex>
);

const OrgPeople = () => {
  const [users, setUsers] = useState<UserSummary[] | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [org] = useOrg();

  const loading = users == null;

  const cols = useMemo<SortableTableColumn<UserSummary>[]>(
    () => [
      {
        label: "User",
        name: "user",
        format: (x) => <UserIdent user={x} />,
      },
      {
        label: "Job Title",
        name: "jobTitle",
        format: (x) => x.jobTitle || "<None>",
      },
    ],
    []
  );

  useEffect(() => {
    axios.get(`/api/orgs/${org.id}/users`).then(({ data }) => {
      setUsers(data);
    });
  }, [org.id]);

  const filteredData = useMemo<UserSummary[]>(
    () =>
      search.trim() === ""
        ? users ?? []
        : (users ?? []).filter((x) =>
            [x.firstName, x.lastName]
              .join(" ")
              .toLowerCase()
              .includes(search.toLowerCase())
          ),
    [search, users]
  );

  const pageSize = 10;
  const pageCount = filteredData.length / pageSize;
  const pagedData = filteredData.slice(
    (page - 1) * pageSize,
    (page - 1) * pageSize + pageSize
  );

  return (
    <CenterColumn>
      <Box p={[2, 5]} pt={5}>
        <Text textAlign="center" mb={5} fontSize={[5, 6]}>
          People Directory
        </Text>
        <InputWithSearch
          searching={false}
          placeholder="Search..."
          mb={2}
          width={250}
          ml={0}
          mr={0}
          value={search}
          onChange={(ev) => {
            setSearch(ev.target.value);
            setPage(1);
          }}
        />
        <Panel mb={3}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <SortableTable columns={cols} data={pagedData} />
            </>
          )}
        </Panel>
        <Pagination pageCount={pageCount} page={page} onChange={setPage} />
      </Box>
    </CenterColumn>
  );
};

export default OrgPeople;
