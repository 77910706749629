import { range } from "lodash";
import React from "react";
import { FaStar } from "react-icons/fa";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type StarRatingInputProps = SpaceProps & {
  value: number | null;
  onChange: (newVal: number) => void;
};

type StarButtonProps = {
  active: boolean;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${space};
`;

const StarButton = styled.button<StarButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  background: none;
  border: 0;
  color: ${(props) => (props.active ? "#F7CA00" : props.theme.colors.grey4)};
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.active ? "#F7CA00" : props.theme.colors.grey3)};
  }

  &:focus,
  &:active {
    outline: 0;
  }
`;

const StarRatingInput = ({
  value,
  onChange,
  ...rest
}: StarRatingInputProps) => {
  return (
    <Container {...rest}>
      {range(1, 6).map((x) => (
        <StarButton onClick={() => onChange(x)} active={value >= x} key={x}>
          <FaStar size="3em" />
        </StarButton>
      ))}
    </Container>
  );
};

export default StarRatingInput;
