import {
  Box,
  Button,
  Flex,
  Loader,
  Panel,
  Table,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

type UserGroup = {
  id: number;
  name: string;
  createdOn: Date;
  membershipLevel: number;
};

const levelNames = ["Invited", "Member", "Admin", "Owner"];

const MyGroups = () => {
  const [user] = useUser();
  const [groups, setGroups] = useState<UserGroup[] | null>(null);

  useEffect(() => {
    axios.get("/api/groups").then(({ data }) => {
      setGroups(data.filter((x) => x.membershipLevel != null));
    });
  }, []);

  const loading = groups == null;

  const acceptInvitation = (x: UserGroup) => {
    axios.put(`/api/groups/${x.id}/memberships/${user.id}/accept`).then(() => {
      setGroups((old) =>
        old.map((o) => ({
          ...o,
          membershipLevel: o.id === x.id ? 1 : o.membershipLevel,
        })),
      );
    });
  };

  const rejectInvitation = (x: UserGroup) => {
    axios.delete(`/api/groups/${x.id}/memberships/${user.id}`);
    setGroups((old) => old.filter((o) => o.id !== x.id));
  };

  const activeGroups = groups?.filter((x) => x.membershipLevel > 0);
  const invitedGroups = groups?.filter((x) => x.membershipLevel === 0);

  return (
    <>
      <CenterColumn>
        <PageHeader
          text="My Groups"
          subtitle="Groups that you currently belong to"
        />
        <Box>
          {loading ? (
            <Loader />
          ) : (
            <>
              {invitedGroups.length > 0 && (
                <>
                  <Text mb={2} fontSize={4} fontWeight={500}>
                    Group Invitations
                  </Text>
                  <Panel color="primary" mb={3}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {invitedGroups.map((x) => (
                          <tr key={x.id}>
                            <td>
                              <Link
                                to={`/solutions/my-groups/${x.id}`}
                                style={{ fontWeight: 600 }}
                              >
                                {x.name}
                              </Link>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <Button
                                onClick={() => acceptInvitation(x)}
                                color="positive"
                                mr={1}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => rejectInvitation(x)}
                                color="danger"
                              >
                                Reject
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Panel>
                </>
              )}
              <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
                <Text mb={0} fontSize={[3, 4]} fontWeight={500}>
                  Active Groups
                </Text>
              </Flex>
              {activeGroups.length > 0 ? (
                <Panel color="primary">
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeGroups.map((x) => (
                        <tr key={x.id}>
                          <td>
                            <Link
                              to={`/solutions/my-groups/${x.id}`}
                              style={{ fontWeight: 600 }}
                            >
                              {x.name}
                            </Link>
                          </td>
                          <td>{levelNames[x.membershipLevel]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Panel>
              ) : (
                <Panel p={3} alignItems="center" color="primary">
                  <Text fontSize={4} fontWeight={600}>
                    No Active Groups
                  </Text>
                </Panel>
              )}
            </>
          )}
        </Box>
      </CenterColumn>
    </>
  );
};

export default MyGroups;
