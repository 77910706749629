import { ContentAccessDto } from "@coaching-culture/types";
import axios from "axios";
import { useQuery } from "react-query";

const getContentAccessSettings = () =>
  axios
    .get<ContentAccessDto[]>(`/api/v2/orgs/me/content-access`)
    .then(({ data }) => data);

export const useContentAccessSettings = () =>
  useQuery(["contentaccess"], getContentAccessSettings);
