import { ContentAccessReason, UserContentDto } from "@coaching-culture/types";
import { Loader } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useAvailableContent } from "queries/mindset";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ContentList from "./ModuleList";
import MyMindset from "./MyMindset";

const assignedReasons: ContentAccessReason[] = [
  "user",
  "group",
  "admin",
  "userfield",
];
const publicReasons: ContentAccessReason[] = ["public", "manager"];

const isAssigned = (module: UserContentDto) =>
  assignedReasons.some((x) => module.assignmentReasons.includes(x));

const isPublic = (module: UserContentDto) =>
  publicReasons.some((x) => module.assignmentReasons.includes(x));

export default function Mindset() {
  const { data, isFetched } = useAvailableContent();

  if (!isFetched) {
    return <Loader />;
  }

  const modules = data.filter((x) => x.type === "module");

  const publicModules = modules.filter(isPublic);
  const assigned = modules.filter(isAssigned);

  const defaultRoute =
    assigned.length > 0
      ? "/solutions/mindset/library/me"
      : "/solutions/mindset/library/public";

  return (
    <CenterColumn>
      <Switch>
        <Route path="/solutions/mindset/library/me" exact>
          <PageHeader
            text="Assigned Modules"
            subtitle="Modules directly assigned to you"
          />
          <ContentList items={assigned} />
        </Route>
        <Route path="/solutions/mindset/library/public" exact>
          <PageHeader
            text="Public Modules"
            subtitle="Modules that are public within your organisation"
          />
          <ContentList items={publicModules} />
        </Route>

        <Route path="/solutions/mindset/me" exact>
          <PageHeader
            text="My Mindset"
            subtitle="Your Mindset results so far..."
          />
          <MyMindset />
        </Route>
        <Redirect to={defaultRoute} />
      </Switch>
    </CenterColumn>
  );
}
