export type Axes = {
  label: string;
  max?: number;
  min?: number;
  stepSize?: number;
};

export const defaultChartOptions = (xAxes: Axes, yAxes: Axes) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y1: {
        max: yAxes.max,
        min: yAxes.min,
        stepSize: yAxes.stepSize,
        ticks: {
          callback: function (value) {
            const label = this.getLabelForValue(value) as string;

            return label != null
              ? label.length > 10
                ? label.substring(0, 10) + "..."
                : label
              : "";
          },
          color: "rgb(170, 170, 170)",
          font: {
            weight: "bold",
            lineHeight: 1.2,
          },
        },
        title: {
          display: true,
          text: yAxes.label,
          color: "rgb(170, 170, 170)",
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
          font: {
            weight: "bold",
            lineHeight: 1.2,
          },
        },
      },
      x1: {
        max: xAxes.max,
        min: xAxes.min,
        stepSize: xAxes.stepSize,
        ticks: {
          color: "rgb(170, 170, 170)",
          font: {
            weight: "bold",
            lineHeight: 1.2,
          },
        },
        title: {
          text: xAxes.label,
          display: true,
          color: "rgb(170, 170, 170)",
          font: {
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };
};
