import { range } from "lodash";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useTrail, useSprings, animated } from "react-spring";
import { space, SpaceProps } from "styled-system";

const Container = styled.ul<SpaceProps>`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${space};
`;

const Dot = styled(animated.li)`
  display: block;
  height: 12px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 6px;
`;

type DotsProps = {
  index: number;
  count: number;
} & SpaceProps;

const Dots = ({ index, count, ...rest }: DotsProps) => {
  const theme = useTheme();

  const trail = useTrail(count, {
    transform: "scale(1)",
    from: {
      transform: "scale(0.2)"
    }
  });

  const springs = useSprings(
    count,
    range(count).map((x) => ({
      width: x === index ? 18 : 12,
      backgroundColor: x === index ? theme.colors.grey2 : theme.colors.grey4
    }))
  );

  return (
    <Container {...rest}>
      {trail.map((x, i) => (
        <Dot
          key={i}
          style={{
            ...x,
            ...springs[i]
          }}
        />
      ))}
    </Container>
  );
};

export default Dots;
