import { Circle, Flex, Text } from "@coaching-culture/ui";
import React from "react";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 12px 24px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
`;

const FlexLine = styled.div<{ color: string }>`
  flex: 1;
  height: 3px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
  margin: 12px;
`;

export type ProgressTimelineProps = {
  steps: string[];
  activeStep: number;
  color?: string;
};

const ProgressTimeline = ({
  steps,
  activeStep,
  color = "primary",
}: ProgressTimelineProps) => {
  const theme = useTheme();
  return (
    <Container>
      {steps.map((x, i) => (
        <Flex alignItems="center" flex={1} key={x}>
          <Circle color={activeStep >= i ? color : undefined} mr={2}>
            {i + 1}
          </Circle>
          <Text
            fontWeight={600}
            color={activeStep === i ? color : activeStep > i ? "body" : "grey3"}
          >
            {x}
          </Text>
          <FlexLine
            color={
              activeStep > i ? theme.colors[color] ?? color : theme.colors.grey3
            }
          />
        </Flex>
      ))}
    </Container>
  );
};

export default ProgressTimeline;
