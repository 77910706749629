import { UserSummary } from "@coaching-culture/types";
import {
  Button,
  Circle,
  Flex,
  IconButton,
  Modal,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import { ItemContainer, ItemsList } from "components/ShareModal/Drawer";
import UserProfileImage from "components/UserProfileImage";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { FaTimes, FaUser } from "react-icons/fa";
import { UserSearchInput } from "./UserSearchInput";

export type UserSelectModalProps = {
  onSelect: (users: UserSummary[]) => void;
};

const UserItem = ({
  user,
  onRemove,
}: {
  user: UserSummary;
  onRemove: () => void;
}) => {
  return (
    <ItemContainer>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex width="400px" flex={1} alignItems="center">
          <UserProfileImage
            name={user.name}
            mr={2}
            size="small"
            color="primary"
          />
          <Text fontWeight={500} fontSize={3} color="primary">
            {user.name}
          </Text>
        </Flex>
        <IconButton color="danger" icon={FaTimes} onClick={onRemove} />
      </Flex>
    </ItemContainer>
  );
};

export function UserSelectModal({ onSelect }: UserSelectModalProps) {
  const [users, setUsers] = useState<UserSummary[]>([]);
  const [selected, setSelected] = useState<UserSummary[]>([]);

  useEffect(() => {
    axios.get("/api/users").then(({ data }) => setUsers(data));
  }, []);

  const confirm = () => {
    onSelect(selected);
  };

  const addUser = (user: UserSummary) => {
    setSelected((old) => uniqBy([...old, user], (user) => user.id));
  };

  const removeUser = (user: UserSummary) => {
    setSelected(selected.filter((x) => x.id !== user.id));
  };

  return (
    <Modal
      width={500}
      minHeight={500}
      height={600}
      p={0}
      onClose={() => onSelect([])}
      showCloseButton
      style={{ overflow: "hidden" }}
    >
      <Flex alignItems="center" flexDirection="column" p={3}>
        <Circle color="primary" icon={FaUser} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Select Users
        </Text>
        <Text>{selected.length} user(s) selected.</Text>
      </Flex>
      <Flex p={3}>
        <UserSearchInput options={users} onSelect={addUser} />
      </Flex>
      <ItemsList style={{ padding: 0 }}>
        {selected.map((x) => (
          <UserItem user={x} onRemove={() => removeUser(x)} />
        ))}
      </ItemsList>
      <Flex alignItems="center" justifyContent="center" p={3}>
        <Button onClick={confirm} color="primary">
          Select
        </Button>
      </Flex>
    </Modal>
  );
}
