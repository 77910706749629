import {
  CoachingTopicDto,
  ConversationTypeDto,
  DiscussionPointTemplateDto,
} from "@coaching-culture/types";
import {
  AddButton,
  Button,
  Circle,
  Flex,
  FormInput,
  FormTextArea,
  IconButton,
  IconToast,
  Loader,
  ReactModal,
  Panel,
  Rule,
  Text,
  useToast,
  Box,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import NotFound from "pages/NotFound";
import { CoachingTopicsModal } from "pages/Solutions/Performance/CoachingTopicsModal";
import {
  useConversationTypes,
  useCreateConversationType,
  useUpdateConversationType,
} from "queries/conversationTypes";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import {
  FaCommentDots,
  FaGripVertical,
  FaSave,
  FaTrash,
  FaPen,
} from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import { arrMove } from "utils/index";

type EditDiscussionPointModalProps = {
  value: Partial<DiscussionPointTemplateDto> | null;
  onSave: (values: Partial<DiscussionPointTemplateDto>) => void;
  onClose: () => void;
  isOpen: boolean;
};

function EditDiscussionPointModal({
  value,
  onSave,
  onClose,
  isOpen,
}: EditDiscussionPointModalProps) {
  const { register, handleSubmit, reset, errors } =
    useForm<DiscussionPointTemplateDto>();

  useEffect(() => {
    if (value != null) {
      reset(value);
    }
  }, [value, reset]);

  const onSubmit = (values: Partial<DiscussionPointTemplateDto>) => {
    onSave(values);
  };

  return (
    <ReactModal isOpen={isOpen} width={600} onClose={onClose}>
      <Box p={5}>
        <Text fontSize={5} fontWeight={600} mb={4}>
          Edit Discussion Point
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            ref={register({ required: true, maxLength: 255 })}
            name="name"
            label="Name"
            required
            error={errors.name}
          />
          <Rule />
          <Flex>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button ml={2} type="button" onClick={onClose}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Box>
    </ReactModal>
  );
}

type ConversationTypeFormProps = {
  value: ConversationTypeDto | null;
  onSave: (values: Partial<ConversationTypeDto>) => void;
  disabled: boolean;
};

function ConversationTypeForm({
  value,
  onSave,
  disabled,
}: ConversationTypeFormProps) {
  const { register, handleSubmit, errors, reset } =
    useForm<ConversationTypeDto>();
  const [points, setPoints] = useState<DiscussionPointTemplateDto[]>([]);
  const [editing, setEditing] = useState<"new" | null | number>(null);
  const [addingCoachingTopics, setAddingCoachingTopics] = useState(false);

  useEffect(() => {
    if (value != null) {
      setPoints(value.discussionPoints);
      reset(value);
    }
  }, [reset, value]);

  const onSubmit = async (values: Partial<ConversationTypeDto>) => {
    if (points.length === 0) {
      return window.alert("At least one discussion point must be added");
    }

    const spec: Partial<ConversationTypeDto> = {
      name: values.name,
      description: values.description,
      discussionPoints: points,
    };

    onSave(spec);
  };

  const newPoint = () => {
    setEditing("new");
  };

  const remove = (idx: number) => {
    setPoints(points.filter((_, i) => i !== idx));
  };

  const onDragEnd = async (result: DropResult) => {
    setPoints(arrMove(points, result.source.index, result.destination.index));
  };

  const savePoint = (values: DiscussionPointTemplateDto) => {
    if (editing === "new") {
      setPoints([...points, values]);
    } else {
      setPoints(points.map((x, i) => (i === editing ? values : x)));
    }
    setEditing(null);
  };

  const saveTopics = (topics: Partial<CoachingTopicDto[]>) => {
    const temp = topics.map(
      (x) => ({ name: x.body } as DiscussionPointTemplateDto)
    );
    setPoints([...points, ...temp]);
    setAddingCoachingTopics(false);
  };

  return (
    <>
      <EditDiscussionPointModal
        isOpen={editing != null}
        value={editing === "new" ? null : points[editing]}
        onSave={savePoint}
        onClose={() => setEditing(null)}
      />

      <CoachingTopicsModal
        isOpen={addingCoachingTopics}
        onSave={saveTopics}
        onCancel={() => setAddingCoachingTopics(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          required
          label="Name"
          name="name"
          ref={register({ maxLength: 255, required: true })}
          error={errors.name}
        />
        <FormTextArea
          name="description"
          label="Description"
          ref={register({
            maxLength: 1000,
          })}
        />
        <Text fontWeight={500} fontSize={4} mb={2}>
          Discussion Points
        </Text>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {points.map((x, i) => (
                  <Draggable key={i} draggableId={i.toString()} index={i}>
                    {(provided, snapshot) => (
                      <Flex
                        border={1}
                        borderRadius={6}
                        p={2}
                        mb={2}
                        alignItems="center"
                        key={i}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getDndItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Flex {...provided.dragHandleProps} alignItems="center">
                          <FaGripVertical color="#999" />
                        </Flex>
                        <Circle
                          icon={FaCommentDots}
                          color="primary"
                          mr={2}
                          ml={2}
                        />
                        <Text style={{ flex: 1 }} fontWeight={500}>
                          {x.name}
                        </Text>
                        <IconButton
                          icon={FaPen}
                          color="grey"
                          mr={1}
                          onClick={() => setEditing(i)}
                        />
                        <IconButton
                          icon={FaTrash}
                          color="danger"
                          onClick={() => remove(i)}
                        />
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Flex mb={3}>
          <AddButton mr={2} onClick={newPoint}>
            Add New Discussion Point
          </AddButton>
          <AddButton onClick={() => setAddingCoachingTopics(true)}>
            Add From Coaching Library
          </AddButton>
        </Flex>
        <Flex>
          <Button type="submit" color="primary" mr={2} disabled={disabled}>
            Save
          </Button>
          <Button to="/success/settings/conversation-types">Cancel</Button>
        </Flex>
      </form>
    </>
  );
}

export function CreateConversationType() {
  const createConversationType = useCreateConversationType();
  const history = useHistory();
  const pushToast = useToast();

  const onSubmit = async (values: Partial<ConversationTypeDto>) => {
    await createConversationType.mutateAsync(values);
    pushToast({
      content: <IconToast icon={FaSave} text="Saved" iconColor="primary" />,
    });

    history.push("/success/settings/conversation-types");
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Create Conversation Type"
        subtitle="Make and save a new type of conversation"
        backUrl="/success/settings/conversation-types"
      />
      <Panel p={[2, 3]}>
        <ConversationTypeForm
          value={null}
          onSave={onSubmit}
          disabled={createConversationType.isLoading}
        />
      </Panel>
    </CenterColumn>
  );
}

export function EditConversationType() {
  const { id } = useParams<{ id: string }>();
  const { data, isFetched } = useConversationTypes();
  const ct = (data ?? []).find((x) => x.id === id);
  const updateConversationType = useUpdateConversationType(id);
  const history = useHistory();
  const pushToast = useToast();

  const onSubmit = async (values: Partial<ConversationTypeDto>) => {
    await updateConversationType.mutateAsync(values);
    pushToast({
      content: <IconToast icon={FaSave} text="Saved" iconColor="primary" />,
    });

    history.push("/success/settings/conversation-types");
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Edit Conversation Type"
        subtitle="Update a type of conversation"
        backUrl="/success/settings/conversation-types"
      />
      {!isFetched ? (
        <Loader />
      ) : ct == null ? (
        <NotFound />
      ) : (
        <Panel p={[2, 3]}>
          <ConversationTypeForm
            value={ct}
            onSave={onSubmit}
            disabled={updateConversationType.isLoading}
          />
        </Panel>
      )}
    </CenterColumn>
  );
}
