import React from "react";
import styled from "styled-components";
import range from "lodash/range";
import { GraphAxes, InterpFunc } from "./GraphAxes";

const FitLine = styled.path<{ blockColor: number }>`
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
  stroke-width: 1.5;
`;

const sigFigs = (n: number, sig: number) => {
  var mult = Math.pow(10, sig - Math.floor(Math.log(n) / Math.LN10) - 1);
  return Math.ceil(n * mult) / mult;
};

export type GraphStream = [string, number[]][];

export type LineGraphProps = {
  xAxis: string;
  yAxis: string;
  data: GraphStream;
  streamLabels: string[];
  onStreamRemove?: (index: number) => void;
  maxXLabels?: number;
};

export function LineChart({
  xAxis,
  yAxis,
  data,
  streamLabels,
  onStreamRemove,
  maxXLabels = 10,
}: LineGraphProps) {
  const max = sigFigs(Math.max(1, ...data.map((x) => Math.max(...x[1]))), 1);

  const GraphFunc = function (interpToX: InterpFunc, interpToY: InterpFunc) {
    const paths: string[] = [];

    for (
      let streamIndex = 0;
      streamIndex < streamLabels.length;
      streamIndex++
    ) {
      const pathCoords = data.map(([_, val], i, self) => [
        interpToX(i / (self.length - 1)),
        interpToY((val[streamIndex] || 0) / max),
      ]);

      if (pathCoords.length === 0) {
        continue;
      }

      const path = `M ${pathCoords[0][0]} ${pathCoords[0][1]} L ${pathCoords
        .slice(1)
        .map(([x, y]) => `${x} ${y}`)
        .join(" ")}`;

      paths.push(path);
    }

    return (
      <g>
        {streamLabels.map((_, streamIndex) => (
          <g key={streamIndex}>
            <FitLine blockColor={streamIndex} d={paths[streamIndex]} />
          </g>
        ))}
      </g>
    );
  };

  return (
    <GraphAxes
      width={400}
      yAxis={range(0, 1.1, 0.1).map((x) => Math.round(x * max).toString())}
      xAxis={data.map((x) => x[0])}
      yAxisLabel={yAxis}
      xAxisLabel={xAxis}
      legend={streamLabels.map((x, i) => ({
        text: x,
        color: i,
      }))}
      horizLines
      onStreamRemove={onStreamRemove}
      maxXLabels={maxXLabels}
    >
      {GraphFunc}
    </GraphAxes>
  );
}
