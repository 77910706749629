import React from "react";
import Input from "./Input";
import FormInputWrap, { FormInputWrapProps } from "./FormInputWrap";

type FormInputProps = FormInputWrapProps &
  React.ComponentPropsWithoutRef<"input">;

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      name,
      label,
      placeholder,
      type,
      error,
      defaultValue,
      required,
      value,
      disabled,
      readOnly,
      autoComplete,
      ...rest
    }: FormInputProps,
    ref
  ) => (
    <FormInputWrap
      name={name}
      label={label}
      error={error}
      required={required}
      {...rest}
    >
      <Input
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={ref}
        type={type}
        defaultValue={defaultValue}
        disabled={disabled}
        readOnly={readOnly}
      />
    </FormInputWrap>
  )
);

export default FormInput;
