import { Flex, Loader, Modal, Text } from "@coaching-culture/ui";
import { TabList } from "components/Tabs";
import { useMeasures, useRelationshipMeasures } from "queries/measures";
import { useState } from "react";
import { MeasureTrendChart } from "./MeasureTrendChart";

export type MeasuresTrendsModalProps = {
  relationshipId: string;
  onClose: () => void;
};

export const MeasuresTrendsModal = ({
  relationshipId,
  onClose,
}: MeasuresTrendsModalProps) => {
  const [measureIndex, setMeasureIndex] = useState(0);
  const measures = useMeasures();
  const data = useRelationshipMeasures(relationshipId);

  const isFetched = data.isFetched && measures.isFetched;

  const activeMeasure = measures.data?.[measureIndex];

  return (
    <Modal width={1000} minHeight={500} onClose={onClose} p={6} showCloseButton>
      {!isFetched ? (
        <Loader />
      ) : (
        <Flex flexDirection={"column"}>
          <Text fontSize={6} fontWeight={800} mb={5}>
            {data.data.user.name} Markers Trend
          </Text>
          <TabList
            spread
            options={measures.data.map((x) => x.name)}
            activeIndex={measureIndex}
            onSelect={setMeasureIndex}
          />
          <MeasureTrendChart measure={activeMeasure} data={data.data} />
        </Flex>
      )}
    </Modal>
  );
};
