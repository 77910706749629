import { ProductVisibility } from "@coaching-culture/types";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { Redirect, Route, Switch } from "react-router";
import { AssessmentsReport } from "./AssessmentsReport";
import LessonsReport from "./LessonsReport";
import { MindsetReport } from "./MindsetReport";
import OrgInsights from "./OrgInsights";
import { PerformanceInsights } from "./PerformanceInsights";

const Insights = () => {
  const products = useAllProductVisibility();

  const hasLessons = products.lessons === ProductVisibility.Enabled;
  const hasMindset = products.mindset === ProductVisibility.Enabled;
  const hasAssessments = products.assessments === ProductVisibility.Enabled;
  const hasPerformnace = products.performance === ProductVisibility.Enabled;

  return (
    <Switch>
      <Route path="/success/insights" exact component={OrgInsights} />
      {hasLessons && (
        <Route
          path="/success/insights/lessons"
          exact
          component={LessonsReport}
        />
      )}
      {hasMindset && (
        <Route
          path="/success/insights/mindset"
          exact
          component={MindsetReport}
        />
      )}
      {hasAssessments && (
        <Route
          path="/success/insights/assessments"
          exact
          component={AssessmentsReport}
        />
      )}
      {hasPerformnace && (
        <Route
          path="/success/insights/performance"
          exact
          component={PerformanceInsights}
        />
      )}
      <Redirect to="/success/insights" />
    </Switch>
  );
};

export default Insights;
