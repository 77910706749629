import { useUser } from "auth";
import axios from "axios";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const RedeemSso = () => {
  const [, setToken] = useUser();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const redirect = query.get("redirect");

  useEffect(() => {
    axios
      .post(`/api/sso/redeem/${token}`)
      .then(({ data }) => {
        setToken(data.token);
        history.push(redirect ?? "/");
      })
      .catch(() => {
        window.alert("The SSO token is expired or invalid");
      });
  }, [token, history, setToken, redirect]);

  return null;
};
