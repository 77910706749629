import { FeedbackInsights } from "@coaching-culture/types";
import { Circle, Loader, Panel, Text } from "@coaching-culture/ui";
import { FaBan, FaHourglassHalf } from "react-icons/fa";

export const CampaignInsightsWrap = ({
  insights,
  render,
}: {
  insights: null | false | "not-available-yet" | FeedbackInsights;
  render: () => JSX.Element;
}) => {
  return insights === false ? (
    <Panel
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"center"}
      p={5}
    >
      <Circle size="xxlarge" color="danger" icon={FaBan} />
      <Text textAlign="center" fontSize={5} mt={3}>
        Results for this campaign are not visible to you.
      </Text>
    </Panel>
  ) : insights === "not-available-yet" ? (
    <Panel
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"center"}
      p={5}
    >
      <Circle icon={FaHourglassHalf} color="primary" size="xxlarge" />
      <Text textAlign="center" fontSize={5} mt={3}>
        Results on an anonymous survey are not available until at least 5
        respondents have completed it
      </Text>
    </Panel>
  ) : insights == null ? (
    <Loader />
  ) : (
    render()
  );
};
