import {
  ContentItem,
  CreateUserDto,
  GroupDto,
  UpdateGroupDto,
  UserField,
  UserFieldDto,
  UserGroup,
  UserSummary,
} from "@coaching-culture/types";
import { useUser } from "auth";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const createUser = (spec: CreateUserDto) =>
  axios.post<string>("/api/v2/users", spec).then(({ data }) => data);

export function useCreateUser() {
  const queryClient = useQueryClient();

  const mutation = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("orgUsers");
    },
  });

  return mutation;
}

export const getUsers = () =>
  axios.get<UserSummary[]>("/api/users").then(({ data }) => data);

export function useOrgUsers() {
  return useQuery("orgUsers", getUsers);
}

export function useOrgUser(userId: string) {
  return useQuery(["orgUser", userId], () => getOrgUser(userId));
}

const getOrgUser = (userId: string) =>
  axios.get<UserSummary>(`/api/users/${userId}`).then(({ data }) => data);

const getGroups = () =>
  axios.get<UserGroup[]>(`/api/groups`).then(({ data }) => data);

const getGroup = (id: string) =>
  axios.get<GroupDto>(`/api/v2/groups/${id}`).then(({ data }) => data);

const closeGroup = (id: string) => axios.delete(`/api/groups/${id}`);

const leaveGroup = (groupId: string, userId: string) =>
  axios.delete(`/api/groups/${groupId}/memberships/${userId}`);

const acceptGroupInvitation = (groupId: string, userId: string) =>
  axios.put(`/api/groups/${groupId}/memberships/${userId}/accept`);

const removeContentFromGroup = (groupId: string, content: ContentItem) =>
  axios.delete(`/api/groups/${groupId}/content/${content.type}/${content.id}`);

const inviteUserToGroup = (groupId: string, userId: string) =>
  axios.post(`/api/groups/${groupId}/memberships`, { userId });

const updateGroup = (groupId: string, spec: UpdateGroupDto) =>
  axios.put(`/api/v2/groups/${groupId}`, spec);

const closeGroups = (groupIds: string[]) =>
  axios.post(`/api/v2/groups/bulk-close`, { ids: groupIds });

export const useBulkCloseGroups = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(closeGroups, {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"]);
      queryClient.invalidateQueries(["content"]);
    },
  });

  return mutation;
};

export const useUpdateGroup = (groupId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: UpdateGroupDto) => updateGroup(groupId, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );

  return mutation;
};

export const useInviteUserToGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ groupId, userId }: { groupId: string; userId: string }) =>
      inviteUserToGroup(groupId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );

  return mutation;
};

export const useRemoveContentFromGroup = (groupId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (item: ContentItem) => removeContentFromGroup(groupId, item),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );

  return mutation;
};

export const useAcceptGroupInvitation = (groupId: string) => {
  const [user] = useUser();
  const queryClient = useQueryClient();

  const mutation = useMutation(() => acceptGroupInvitation(groupId, user.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"]);
    },
  });

  return mutation;
};

const changeMembershipLevel = (
  groupId: string,
  userId: string,
  level: number
) =>
  axios.put(`/api/groups/${groupId}/memberships/${userId}`, {
    membershipLevel: level,
  });

export const useChangeMembershipLevel = (groupId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ userId, level }: { userId: string; level: number }) =>
      changeMembershipLevel(groupId, userId, level),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );

  return mutation;
};

export const useLeaveGroup = () => {
  const [user] = useUser();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (groupId: string) => leaveGroup(groupId, user.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );

  return mutation;
};

export const useRemoveMembership = (groupId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (userId: string) => leaveGroup(groupId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );

  return mutation;
};

export const useCloseGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(closeGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"]);
    },
  });

  return mutation;
};

export const useGroups = () => useQuery(["groups"], getGroups);

export const useGroup = (id: string, disabled?: boolean) =>
  useQuery(["groups", id], () => getGroup(id), {
    enabled: !disabled,
  });

const getUserFields = () =>
  axios.get<UserFieldDto[]>("/api/user-fields").then(({ data }) => data);

export const useUserFields = () => useQuery(["userfields"], getUserFields);

export const getUserFieldById = (id: string) =>
  axios.get<UserField>(`/api/user-fields/${id}`).then(({ data }) => data);

export const useUserField = (id: string) =>
  useQuery(["userfields", id], () => getUserFieldById(id));

export const updateUserField = (id: string) => (data: UserFieldDto) =>
  axios.put(`/api/v2/user-fields/${id}`, data);

export const createUserField = (data: UserFieldDto) =>
  axios.post(`/api/v2/user-fields`, data);

export const reorderUserFields = (data: string[]) =>
  axios.put(`/api/v2/user-fields/reorder`, data);

export const useUpdateUserField = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateUserField(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("userfields");
      queryClient.invalidateQueries(["userfields", id]);
    },
  });

  return mutation;
};

export const useReorderUserFields = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(reorderUserFields, {
    onSuccess: () => {
      queryClient.invalidateQueries("userfields");
    },
  });

  return mutation;
};
export const useCreateUserField = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createUserField, {
    onSuccess: () => {
      queryClient.invalidateQueries("userfields");
    },
  });

  return mutation;
};

export const deleteUserField = (id: string) =>
  axios.delete(`/api/user-fields/${id}`);

export const useDeleteUserField = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteUserField, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries("userfields");
      queryClient.invalidateQueries(["userfields", id]);
    },
  });

  return mutation;
};
