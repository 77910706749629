import { useOrgById } from "@coaching-culture/api-client";
import { OrgDto } from "@coaching-culture/types";
import React, { useContext, useMemo } from "react";
import { useUser } from ".";

type ContextType = [OrgDto | null];

const OrgContext = React.createContext<ContextType>(null);

export const OrgProvider = ({ children }) => {
  const [user] = useUser();
  const org = useOrgById(user?.org.id ?? null);
  const loading = org.isLoading;

  return (
    <OrgContext.Provider value={[org.data ?? null]}>
      {loading ? null : children}
    </OrgContext.Provider>
  );
};

export const useOrg = () => useContext(OrgContext);

const term = (base: string) => ({
  asBody: () => base.toLowerCase(),
  asTitle: () => base,
  asPluralBody: () => base.toLowerCase() + "s",
  asPluralTitle: () => base + "s",
});

export const useGoalTerminology = () => {
  const [org] = useOrg();

  const obj = useMemo(
    () => ({
      goal: term(org?.goalName ?? "Goal"),
      objective: term(org?.objectiveName ?? "Objective"),
    }),
    [org],
  );

  return obj;
};
