import {
  ContentDisplay,
  ContentItem,
  ProductVisibility,
} from "@coaching-culture/types";
import { Box, Button, Flex, Loader, Rule, Text } from "@coaching-culture/ui";
import NavActions from "components/NavActions";
import { ScrollableContainer } from "components/ScrollableContainer";
import { useProductVisibility } from "hooks/useProductVisibility";
import { groupBy, sortBy } from "lodash";
import { NoDataWidget } from "pages/Dashboard/Widgets/NoDataWidget";
import { useAvailableContent, useUserContent } from "queries/mindset";
import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";
import {
  CloseDrawerButton,
  Drawer,
  DrawerFooter,
  DrawerHeader,
  ItemContainer,
} from "./Drawer";

const ModuleImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
  display: block;
`;

type ModuleItemProps = {
  onClick: () => void;
  module: ContentItem;
  active: boolean;
};

const ModuleItem = ({ onClick, module, active }: ModuleItemProps) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="500px" maxWidth="100%" alignItems="center">
        <ModuleImage
          src={process.env.REACT_APP_STATIC_ROOT + "/" + module.icon}
        />
        <Text
          fontWeight={500}
          fontSize={4}
          color={active ? "positive" : "body"}
          textAlign={"left"}
        >
          {module.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

const ModuleDirectoryDrawer = ({
  onSelect,
  userId,
}: {
  onSelect: (items: ContentDisplay[]) => void;
  userId?: string;
}) => {
  const { data: content, isFetched: contentFetched } = useUserContent(
    userId,
    userId == null
  );
  const { data: availableContent, isFetched: availableFetched } =
    useAvailableContent();
  const [selected, setSelected] = useState<ContentDisplay[]>([]);
  const hasMindset =
    useProductVisibility("mindset") === ProductVisibility.Enabled;
  const hasLessons =
    useProductVisibility("lessons") === ProductVisibility.Enabled;
  const hasAssessments =
    useProductVisibility("assessments") === ProductVisibility.Enabled;
  const [showing, setShowing] = useState(
    hasMindset ? "mindset" : hasLessons ? "lessons" : "assessments"
  );

  const loading =
    (!contentFetched && userId != null) ||
    !availableFetched ||
    (userId == null && !availableFetched);

  const filteredContent =
    userId != null
      ? availableContent?.filter((x) => {
          const temp = content?.find((y) => y.id === x.id);
          if (temp != null && temp.assignments.length > 0) {
            return false;
          }
          return true;
        })
      : availableContent;

  const toggleItem = (type: string, item: any) => {
    setSelected((old) => {
      if (old.some((x) => x.type === type && x.id === item.id)) {
        return old.filter((x) => !(x.type === type && x.id === item.id));
      } else {
        return old.concat([
          {
            type,
            ...item,
          },
        ]);
      }
    });
  };

  const cats = [
    hasMindset && "mindset",
    hasLessons && "lessons",
    hasAssessments && "assessments",
  ].filter(Boolean);

  const isSelected = (type: string, id: string) =>
    selected.some((x) => x.type === type && x.id === id);

  const moduleCount = selected.filter((x) => x.type === "module").length;
  const lessonCount = selected.filter((x) => x.type === "lesson").length;
  const assCount = selected.filter((x) => x.type === "assessment").length;
  const selectText =
    selected.length === 0
      ? "Select"
      : `Select ${[
          moduleCount > 0 && `${moduleCount} Mindset modules`,
          lessonCount > 0 && `${lessonCount} lessons`,
          assCount > 0 && `${assCount} assessments`,
        ]
          .filter(Boolean)
          .join(" and ")}`;

  return (
    <Drawer>
      <DrawerHeader>
        <CloseDrawerButton onClick={() => onSelect(null)}>
          <FaChevronDown />
        </CloseDrawerButton>
        <Text textAlign="center" fontSize={5} fontWeight={600}>
          Content
        </Text>
      </DrawerHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box mt={3}>
            <NavActions
              items={cats}
              active={cats.indexOf(showing)}
              onSelect={(idx) => setShowing(cats[idx])}
            />
          </Box>
          <Flex height={"264px"}>
            <ScrollableContainer p={3} mr={2} maxHeight={400}>
              {showing === "mindset" ? (
                filteredContent.filter((x) => x.type === "module").length ===
                0 ? (
                  <NoDataWidget text="No Content"></NoDataWidget>
                ) : (
                  sortBy(filteredContent, "name")
                    .filter((x) => x.type === "module")
                    .map((x) => (
                      <ModuleItem
                        onClick={() => toggleItem("module", x)}
                        module={x}
                        key={"mindset" + x.id}
                        active={isSelected("module", x.id)}
                      />
                    ))
                )
              ) : showing === "lessons" ? (
                filteredContent.filter((x) => x.type === "lesson").length ===
                0 ? (
                  <NoDataWidget text="No Content"></NoDataWidget>
                ) : (
                  Object.entries(
                    groupBy(
                      filteredContent.filter((x) => x.type === "lesson"),
                      (x) => x.category.name
                    )
                  ).map((x) => (
                    <>
                      <Text
                        as={"h2"}
                        textAlign={"center"}
                        fontWeight={600}
                        fontSize={4}
                        mb={2}
                      >
                        {x[0]}
                      </Text>
                      {sortBy(x[1], "orderIndex").map((y) => (
                        <ModuleItem
                          onClick={() => toggleItem("lesson", y)}
                          module={y}
                          key={"lessons" + y.id}
                          active={isSelected("lesson", y.id)}
                        />
                      ))}
                      <Rule />
                    </>
                  ))
                )
              ) : showing === "assessments" ? (
                filteredContent
                  .filter((x) => x.type === "assessment")
                  .map((x) => (
                    <ModuleItem
                      onClick={() => toggleItem("assessment", x)}
                      module={x}
                      key={"assessments" + x.id}
                      active={isSelected("assessment", x.id)}
                    />
                  ))
              ) : null}
            </ScrollableContainer>
          </Flex>
          <DrawerFooter>
            <Button
              onClick={() => onSelect(selected)}
              disabled={selected.length === 0}
              color="primary"
            >
              {selectText}
            </Button>
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};

export default ModuleDirectoryDrawer;
