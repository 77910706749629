import { useEffect } from "react";
const useScript = (resourceUrl: string, defer: boolean) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    script.defer = defer;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, defer]);
};
export default useScript;
