import { FeedbackFormSummary, FormLibraryItem } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
} from "@coaching-culture/ui";
import axios from "axios";
import { PageHeader } from "components/PageHeader";
import { format } from "date-fns";
import { sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FaCheckCircle,
  FaCopy,
  FaFileImport,
  FaPlus,
  FaTimesCircle,
  FaTrash,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { SurveyLibraryModal } from "../Surveys/LibraryModal";

const formSearch = (item: FeedbackFormSummary) => item.name;

export const Forms = () => {
  const [items, setItems] = useState<FeedbackFormSummary[] | null>(null);
  const [showLibraryModal, setShowLibraryModal] = useState<boolean>(false);
  const [lib, setLib] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get<FormLibraryItem[]>("/api/form-library")
      .then(({ data }) =>
        setLib(data.filter((x) => x.type === "feedback").length > 0)
      );
  }, []);

  const loading = items == null;

  const getData = useCallback(() => {
    axios.get(`/api/feedback/forms`).then(({ data }) => {
      setItems(sortBy(data, "createdOn"));
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);  

  const duplicateForm = useCallback(
    (id: string, name: string) => {
      if (
        window.confirm(
          'Are you sure you want to duplicate the form "'+ name + '"?'
        )
      ) {
        axios.post(`/api/feedback/forms/${id}/duplicate`).then( res => {
         getData();
        });
      }
    },
    [getData]
  );

  const deleteForm = useCallback(
    (id: string) => {
      if (
        window.confirm(
          "Are you sure you want to delete that form?\nThis will not affect existing campaigns using that form."
        )
      ) {
        axios.delete(`/api/feedback/forms/${id}`);
        setItems((items ?? []).filter((x) => x.id !== id));
      }
    },
    [items]
  );

  const cols = useMemo(
    (): SortableTableColumn<FeedbackFormSummary>[] => [
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Link to={`/success/feedback/forms/${x.id}`}>
            <strong>{x.name}</strong>
          </Link>
        ),
      },
      {
        name: "published",
        label: "Published",
        headingProps: {
          width: 100,
        },
        props: {
          textAlign: "center",
        },
        format: (x) =>
          x.published ? <FaCheckCircle color="green" /> : <FaTimesCircle />,
      },
      {
        label: "Campaign Count",
        name: "campaignCount",
        headingProps: {
          textAlign: "right",
          width: 150,
        },
        props: {
          textAlign: "right",
        },
      },
      {
        name: "creator",
        label: "Creator",
        format: (x) =>
          x.creator == null
            ? "<Deleted>"
            : `${x.creator.firstName} ${x.creator.lastName}`,
      },
      {
        name: "createdOn",
        label: "Created",
        headingProps: {
          textAlign: "right",
          width: 150,
        },
        props: {
          textAlign: "right",
        },
        format: (x) => format(new Date(x.createdOn), "dd/MM/yyyy"),
      },

      {
        name: "",
        label: "",
        headingProps: {
          width: 30,
        },
        notSortable: true,
        format: (x) => (
          <Flex justifyContent="flex-end">
            <IconButton
              icon={FaCopy}
              onClick={() => duplicateForm(x.id, x.name)}
              color="body"
              title="Duplicate form"
            />
            <IconButton
              icon={FaTrash}
              onClick={() => deleteForm(x.id)}
              color="danger"
              title="Delete form"
              ml={1}
            />
          </Flex>
        ),
      },
    ],
    [deleteForm,duplicateForm]
  );

  const onImport = async (item: FormLibraryItem) => {
    setShowLibraryModal(false);
    const spec = {
      formLibraryItemId: item.id,
    };

    await axios.post("/api/feedback/forms/import", spec);
    getData();
  };

  return (
    <>
      {showLibraryModal && (
        <SurveyLibraryModal
          onClose={() => setShowLibraryModal(false)}
          onSelect={onImport}
          type="feedback"
        />
      )}
      <PageHeader
        helpIdent="success-feedback-forms-main"
        text="Feedback Forms"
        subtitle="Create new forms to be used in Feedback campaigns"
      />
      <Box>
        <Flex
          alignItems={["stretch", "center"]}
          justifyContent="flex-end"
          flexDirection={["column", "row"]}
          mb={2}
        >
          {lib && (
            <Button
              color="primary"
              icon={FaFileImport}
              onClick={() => setShowLibraryModal(true)}
              mb={[1, 0]}
            >
              Coaching Culture Library
            </Button>
          )}
          <Button
            to="/success/feedback/forms/create"
            color="primary"
            icon={FaPlus}
            ml={1}
          >
            New Form
          </Button>
        </Flex>
        <Panel style={{ overflow: "hidden" }}>
          {loading ? (
            <Loader />
          ) : (
            <SortableTable<FeedbackFormSummary>
              paged
              columns={cols}
              data={items}
              rowSearch={formSearch}
              defaultSort={1}
              emptyMessage="No Forms Created Yet"
            />
          )}
        </Panel>
      </Box>
    </>
  );
};
