import { Loader } from "@coaching-culture/ui";
import { PageHeader } from "components/PageHeader";
import ContentList from "pages/Mindset/ModuleList";
import { useAvailableContent } from "queries/mindset";

export function AssessmentList() {
  const { data: content, isFetched } = useAvailableContent();

  const assessments = (content ?? []).filter((x) => x.type === "assessment");

  return (
    <>
      <PageHeader
        text="Assessment Library"
        subtitle="Assess your coaching capabilities"
      />
      {!isFetched ? <Loader /> : <ContentList items={assessments} />}
    </>
  );
}
