import { ContentType } from "@coaching-culture/types";
import { Box, Button, Circle, Flex, Loader, Text } from "@coaching-culture/ui";
import { Sidebar } from "components/Sidebar";
import {
  isToday,
  isThisWeek,
  isThisMonth,
  format,
  fromUnixTime,
} from "date-fns";
import { groupBy, sortBy } from "lodash";
import { useTodoList } from "queries/todos";
import { FaRegClock } from "react-icons/fa";
import styled from "styled-components";
import { clientUrlForContent } from "utils/content";

const TimelineHeader = styled.p`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.grey2};
  font-style: italic;
  margin-bottom: 12px;
  font-weight: 500;

  &:after {
    content: "";
    display: block;
    height: 2px;
    flex: 1;
    margin-left: 12px;
    background-color: ${(props) => props.theme.colors.grey4};
  }
`;

const TimelineLine = styled.div`
  flex: 1;
  width: 2px;
  margin-bottom: 6px;
  background-color: ${(props) => props.theme.colors.grey4};
`;

type TimelineItemProps = {
  title: string;
  date: Date;
  link: string;
  icon: string;
  completed: number;
  type: ContentType;
};

const TimelineItem = ({
  title,
  date,
  link,
  icon,
  completed,
  type,
}: TimelineItemProps) => {
  return (
    <Flex mb={1}>
      <Flex alignItems="center" flexDirection="column" mr={2}>
        <Circle size="small" mb={1} image={icon} />
        <TimelineLine />
      </Flex>
      <Box>
        <Text fontWeight={600}>
          {type === "module"
            ? "Mindset Self-Assessment"
            : type === "lesson"
            ? "Lesson"
            : ""}
        </Text>
        <Text>{title}</Text>
        <Text fontSize={2} mb={1}>
          {format(date, "do MMM yyyy")}
        </Text>
        <Button
          to={link}
          tiny
          mb={3}
          color="positive"
          disabled={completed != null}
        >
          {completed != null ? "Complete" : "Start"}
        </Button>
      </Box>
    </Flex>
  );
};

const humaniseDate = (date: Date): string => {
  if (isToday(date)) {
    return "Today";
  } else if (isThisWeek(date)) {
    return "This Week";
  } else if (isThisMonth(date)) {
    return "This Month";
  } else {
    return format(date, "MMM yyyy");
  }
};

const SolutionsSidebar = () => {
  const { list, loading } = useTodoList();

  const items = sortBy(
    Object.entries(groupBy(list, (x) => humaniseDate(fromUnixTime(x.date)))),
    (x) => x[1][0].date
  ).reverse();

  return (
    <Sidebar side="right" alwaysFloat={true}>
      <Box p={3} pt={5}>
        <Text fontSize={4} fontWeight={600} textAlign="center" mb={3}>
          To-Do
        </Text>
        {loading ? (
          <Loader />
        ) : list.length > 0 ? (
          items.map(([key, list]) => (
            <Box mb={3} key={key}>
              <TimelineHeader>{key}</TimelineHeader>
              {list.map((x) => (
                <TimelineItem
                  key={x.content.type + x.content.id}
                  type={x.content.type}
                  title={x.content.name}
                  date={fromUnixTime(x.date)}
                  link={clientUrlForContent(x.content)}
                  icon={`${process.env.REACT_APP_STATIC_ROOT}/${x.content.icon}`}
                  completed={x.completed}
                />
              ))}
            </Box>
          ))
        ) : (
          <Flex alignItems="center" flexDirection="column" pt={2}>
            <Text color="grey2" mb={1}>
              <FaRegClock size="2em" />
            </Text>
            <Text color="grey2" fontWeight={600}>
              Nothing here yet.
            </Text>
          </Flex>
        )}
      </Box>
    </Sidebar>
  );
};

export default SolutionsSidebar;
