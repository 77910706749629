import { Flex, Panel, Button } from "@coaching-culture/ui";
import useOnClickOutside from "hooks/useOnClickOutside";
import { lighten } from "polished";
import React, { useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

type DotMenuItem = {
  label: string;
  icon?: IconType;
  color?: string;
  disabled?: boolean;
  cmd?: string;
};

type DotMenuProps = {
  items: DotMenuItem[];
  text: string;
  color: string;
  onItemClick: (idx: number, cmd: string | undefined) => void;
};

type ItemListProps = {
  items: DotMenuItem[];
  onItemSelect: (idx: number, cmd: string | undefined) => any;
};

type DotButtonProps = {
  activeColor?: string;
};

const Container = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
`;

const ItemListContainer = styled(animated(Panel))`
  position: absolute;
  top: calc(100% + 12px);
  width: 240px;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

const Item = styled.button<{ color: string }>`
  background: none;
  border: 0;
  padding: 6px 12px;
  text-align: left;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${(props) => lighten(0.2, props.theme.colors[props.color])};

  & svg {
    margin-right: 6px;
  }

  &:not([disabled]) {
    cursor: pointer;

    &:hover {
      color: ${(props) =>
        props.theme.colors[props.color] ?? props.theme.colors.body};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 26,
};

const ItemList = ({ items, onItemSelect }: ItemListProps) => {
  const styles = useSpring({
    config: springConfig,
    from: {
      y: -10,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
  });

  return (
    <ItemListContainer style={styles} p={1} border={1} boxShadow={1}>
      {items.map(({ label, cmd, icon: Icon, color = "body", disabled }, i) => (
        <Item
          key={i}
          color={color}
          disabled={disabled}
          onClick={() => {
            onItemSelect(i, cmd);
          }}
        >
          {Icon && <Icon />}
          {label}
        </Item>
      ))}
    </ItemListContainer>
  );
};

const DotMenuText = ({
  items,
  onItemClick,
  text,
  color,
}: DotMenuProps & DotButtonProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null!);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  if (items == null || items.length === 0) {
    return null;
  }

  return (
    <Container ref={ref}>
      <Flex>
        <Button
          icon={FaEllipsisV}
          onClick={() => {
            setOpen((old) => !old);
          }}
          color={color}
          small
        >
          {text}
        </Button>
      </Flex>
      {open && (
        <ItemList
          items={items}
          onItemSelect={(idx, cmd) => {
            setOpen(false);
            onItemClick(idx, cmd);
          }}
        />
      )}
    </Container>
  );
};

export default DotMenuText;
