import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type ContainerProps = SpaceProps;

const NavContainer = styled.div<ContainerProps>`
  display: flex;
  max-width: 100%;
  width: 600px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
  justify-content: center;
  margin: auto;
  margin-bottom: 24px;
  ${space};
`;

const StyledLink = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey};
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
  transform: translateY(1px);
  padding: 12px 24px;
  transition: all 0.3s ease;
  ${space}

  &:hover {
    border-bottom: 4px solid ${(props) => props.theme.colors.primary};
    text-decoration: none;
    color: ${(props) => props.theme.colors.body};
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &.active {
    color: ${(props) => props.theme.colors.body};
    border-bottom-color: ${(props) => props.theme.colors.primary};
  }
`;

type NavActionsProps = {
  items: string[];
  active: number;
  onSelect: (idx: number) => void;
};

const NavBar = ({ items, active, onSelect }: NavActionsProps) => {
  return (
    <NavContainer>
      {items.map((x, i) => (
        <StyledLink
          tabIndex={0}
          onClick={() => onSelect(i)}
          className={active === i ? "active" : ""}
          key={i}
        >
          {x}
        </StyledLink>
      ))}
    </NavContainer>
  );
};

export default NavBar;
