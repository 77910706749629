import { ContinuousFeedbackDto } from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Loader,
  Panel,
  PanelHeader,
  Rule,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import CenterColumn from "components/CenterColumn";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import { useContinuousFeedbackForUser } from "queries/continuousFeedback";
import { useEffect, useMemo, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaComment,
  FaEnvelope,
  FaEnvelopeOpen,
  FaEye,
  FaInfoCircle,
  FaPrayingHands,
} from "react-icons/fa";
import { useParams } from "react-router";
import { ContinuousFeedbackModal } from "./ViewContinuousFeedbackModal";

const NoFeedback = () => (
  <Flex alignItems="center" justifyContent="center" p={6} flex="1">
    <Text color="grey3" fontSize="3em" mr={2} mb={0} lineHeight={0}>
      <FaInfoCircle />
    </Text>
    <Text fontSize={4} fontWeight={600} color="grey2">
      No Feedback
    </Text>
  </Flex>
);

export function ContinuousFeedbackTable({ userId }: { userId?: string }) {
  const [selectedFeedback, setSelectedFeedback] =
    useState<ContinuousFeedbackDto>(null);
  const [me] = useUser();
  const { data, isFetched } = useContinuousFeedbackForUser(userId);

  userId = userId ?? me.id;
  const myFeedback = data
    ? data.filter((x) => x.recievedBy.id === userId)
    : null;

  const receivedCols = useMemo<
    SortableTableColumn<ContinuousFeedbackDto>[]
  >(() => {
    const cols: SortableTableColumn<ContinuousFeedbackDto>[] = [
      {
        name: "type",
        label: "Type",
        format: (x) => (
          <Circle
            color={x.type === "praise" ? "positive" : "body"}
            icon={x.type === "praise" ? FaPrayingHands : FaComment}
            size="small"
            ml={2}
            mr={2}
            title={x.type === "praise" ? `Praise` : `Feedback`}
          />
        ),
        sortFormat: (x) => x.type,
        headingProps: { width: "50px" },
      },
      {
        name: "from",
        label: "From",
        format: (x) => <UserFlag user={x.sentBy} />,
        sortFormat: (x) => x.sentBy.name,
      },
      {
        name: "summary",
        label: "Summary",
        format: (x) => <Text>{x.summary}</Text>,
        sortFormat: (x) => x.sentBy.name,
      },
      {
        name: "dateReceived",
        label: "Date Received",
        format: (x) => {
          return <Text>{format(fromUnixTime(x.sentOn), "do MMM yyyy")}</Text>;
        },
        sortFormat: (x) => x.sentOn,
      },
      {
        name: "actions",
        label: "",
        format: (x) => {
          return (
            <IconButton
              color="body"
              icon={FaEye}
              onClick={() => setSelectedFeedback(x)}
            />
          );
        },
        headingProps: { width: "50px" },
      },
    ];

    return cols;
  }, []);

  return (
    <>
      {!isFetched ? (
        <Loader />
      ) : (
        <Box mt={2}>
          <ContinuousFeedbackModal
            isOpen={selectedFeedback != null}
            onCancel={() => setSelectedFeedback(null)}
            feedback={selectedFeedback}
          />
          {myFeedback.length > 0 ? (
            <SortableTable paged columns={receivedCols} data={myFeedback} />
          ) : (
            <NoFeedback />
          )}
        </Box>
      )}
    </>
  );
}

export function MyContinuousFeedback() {
  const { feedbackId } = useParams<{ feedbackId?: string }>();
  const { data, isFetched } = useContinuousFeedbackForUser();
  const [user] = useUser();
  let sentFeedback: ContinuousFeedbackDto[] = [];
  const [selectedFeedback, setSelectedFeedback] =
    useState<ContinuousFeedbackDto>(null);

  if (isFetched && data != null) {
    sentFeedback = data.filter((x) => x.sentBy.id === user.id);
  }

  useEffect(() => {
    if (feedbackId && data != null) {
      setSelectedFeedback(data.find((x) => x.id === feedbackId));
    }
  }, [data, feedbackId]);

  const cols = useMemo<SortableTableColumn<ContinuousFeedbackDto>[]>(() => {
    const cols: SortableTableColumn<ContinuousFeedbackDto>[] = [
      {
        name: "type",
        label: "Type",
        format: (x) => (
          <Circle
            color={x.type === "praise" ? "positive" : "body"}
            icon={x.type === "praise" ? FaPrayingHands : FaComment}
            size="small"
            ml={2}
            mr={2}
            title={x.type === "praise" ? `Praise` : `Feedback`}
          />
        ),
        sortFormat: (x) => x.type,
        headingProps: { width: "50px" },
      },
      {
        name: "sentTo",
        label: "Sent To",
        format: (x) => <UserFlag user={x.recievedBy} />,
        sortFormat: (x) => x.recievedBy.name,
      },
      {
        name: "summary",
        label: "Summary",
        format: (x) => <Text>{x.summary}</Text>,
        sortFormat: (x) => x.sentBy.name,
      },
      {
        name: "dateReceived",
        label: "Date Sent",
        format: (x) => {
          return <Text>{format(fromUnixTime(x.sentOn), "do MMM yyyy")}</Text>;
        },
        sortFormat: (x) => x.sentOn,
      },
      {
        name: "actions",
        label: "",
        format: (x) => {
          return (
            <IconButton
              color="body"
              icon={FaEye}
              onClick={() => setSelectedFeedback(x)}
            />
          );
        },
        headingProps: { width: "50px" },
      },
    ];

    return cols;
  }, []);

  return (
    <CenterColumn>
      <ContinuousFeedbackModal
        isOpen={selectedFeedback != null}
        onCancel={() => setSelectedFeedback(null)}
        feedback={selectedFeedback}
      />

      <Flex mb={2} flexDirection={"row"}>
        <Flex flexGrow={8}>
          <Flex flexDirection={"column"}>
            <Flex>
              <Text
                fontSize={[5, 6]}
                fontWeight={600}
                lineHeight={1}
                mr={1}
                color="black"
                as="h1"
              >
                My Continuous Feedback
              </Text>
            </Flex>
            <Text fontSize={3} color="grey1" fontWeight={500} mt={1}>
              Share and receive insights on strengths, areas of development or
              give praise.
            </Text>
          </Flex>
        </Flex>

        <Flex alignItems="end" justifyContent="center" flexDirection="row">
          <Button
            to="/solutions/continuous-feedback/send"
            color="primary"
            icon={FaArrowRight}
          >
            Send Feedback
          </Button>

          <Button
            ml={2}
            to="/solutions/continuous-feedback/request"
            color="primary"
            icon={FaArrowLeft}
          >
            Request Feedback
          </Button>
        </Flex>
      </Flex>
      <Rule />

      {!isFetched ? (
        <Loader />
      ) : (
        <>
          <Panel mb={3}>
            <PanelHeader p={3}>
              <Circle mr={2} color="primary">
                <FaEnvelopeOpen size={20} />
              </Circle>
              <Text fontSize={4} fontWeight={600} color="black" as="h2">
                Your feedback
              </Text>
            </PanelHeader>
            {!isFetched ? <Loader /> : <ContinuousFeedbackTable />}
          </Panel>
          <Panel>
            <PanelHeader p={3}>
              <Circle mr={2} color="primary">
                <FaEnvelope size={20} />
              </Circle>
              <Text fontSize={4} fontWeight={600} color="black" as="h2">
                Feedback you have sent
              </Text>
            </PanelHeader>

            <Box mt={2}>
              {sentFeedback.length > 0 ? (
                <SortableTable paged columns={cols} data={sentFeedback} />
              ) : (
                <NoFeedback />
              )}
            </Box>
          </Panel>
        </>
      )}
    </CenterColumn>
  );
}
