import { Img, Text } from "@coaching-culture/ui";
import styled from "styled-components";
import meditationSrc from "../../img/meditation.svg";
import logoSrc from "../../img/logo.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 32px;
`;

export const HoldingPage = () => {
  return (
    <Container>
      <Img
        src={logoSrc}
        width={100}
        style={{ position: "absolute", top: 20, left: 20 }}
      />
      <Img src={meditationSrc} mb={3} />
      <Text fontSize={6} fontWeight={600} mb={1}>
        Take a moment to reflect...
      </Text>
      <Text fontWeight={500}>
        We're making some changes so the platform is currently unavailable.
        Check back soon
      </Text>
    </Container>
  );
};
