import { FormModel, FormModelSpec } from "@coaching-culture/types";
import {
  Alert,
  Box,
  FormEditor,
  FormInput,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { SurveyPlayer } from "components/SurveyPlayer";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

type SurveyFormSpec = {
  name: string;
  form: FormModelSpec;
};

export function EditSurveyForm() {
  const { id } = useParams<{ id: string }>();
  const [form, setForm] = useState<Partial<SurveyFormSpec> | null>(null);
  const history = useHistory();
  const [previewing, setPreviewing] = useState<FormModelSpec | null>(null);

  useEffect(() => {
    if (id === "create") {
      setForm({
        name: "",
        form: {
          name: "",
          items: [],
        },
      });
    } else {
      axios.get(`/api/survey-forms/${id}`).then(({ data }) => {
        setForm(data);
      });
    }
  }, [id]);

  if (form == null) {
    return <Loader />;
  }

  const submit = (newForm: FormModel) => {
    if ((form.name?.trim() ?? "") === "") {
      return window.alert("The form must have a name.");
    }

    const spec = {
      ...form,
      form: newForm,
    };

    if (spec.form.items.length === 0) {
      return window.alert("A form cannot have 0 items");
    } else if (spec.form.items[spec.form.items.length - 1].type === "heading") {
      return window.alert(
        "The last item of a form cannot be a section heading"
      );
    }

    if (id === "create") {
      axios.post("/api/survey-forms", spec).then(() => {
        history.push("/success/surveys/forms");
      });
    } else {
      axios.put(`/api/survey-forms/${id}`, spec).then(() => {
        history.push("/success/surveys/forms");
      });
    }
  };

  return (
    <>
      {previewing && (
        <SurveyPlayer
          form={previewing}
          onCompletion={() => setPreviewing(null)}
          onClose={() => setPreviewing(null)}
        />
      )}
      <CenterColumn style={{ display: previewing ? "none" : undefined }}>
        <PageHeader
          text={`${id === "create" ? "Create" : "Edit"} Survey Form`}
          subtitle="Configure a form to be used as part of a survey"
          backUrl="/success/surveys"
        />
        <Panel p={3}>
          <Box>
            {id !== "create" && (
              <Alert color="danger" mb={3}>
                <Text fontWeight="600" mb={1}>
                  Caution
                </Text>
                <p>
                  Be careful when editing forms used in existing surveys.
                  Modifying existing questions may result in invalid or
                  incomplete data.
                </p>
              </Alert>
            )}
            <Text fontSize={4} mb={2}>
              Details
            </Text>
            <FormInput
              label="Survey Form Name"
              name="name"
              mb={3}
              type="text"
              value={form.name}
              onChange={(ev) =>
                setForm((old) => ({ ...old, name: ev.target.value }))
              }
            />
            <FormEditor
              form={form.form}
              onPreview={setPreviewing}
              allowEvidence={false}
              onSave={submit}
              onChange={(form) =>
                setForm((old) => ({
                  ...old,
                  form,
                }))
              }
            />
          </Box>
        </Panel>
      </CenterColumn>
    </>
  );
}
