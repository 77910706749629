import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "scorm-again";
import { useWindowSize } from "react-use";

const Frame = styled.iframe`
  width: 100%;
  height: 600px;
  margin: auto;
  display: block;
  border: 0;
`;

const w: any = window;

const ProgrammePlayer = ({
  programme,
  onComplete,
  onCommit,
  initialData,
  parentWidth,
  fullscreen
}) => {
  const { url } = programme;
  const [init, setInit] = useState(false);
  const iframe = useRef(null);
  const { height } = useWindowSize();

  useEffect(() => {
    if (!init) {
      w.API = new w.Scorm12API({});
      if (initialData) {
        w.API.loadFromJSON(JSON.parse(initialData));
      }
      w.ScormEvents = new EventTarget();

      w.API.on("LMSCommit", (c) => {
        const ev = new CustomEvent<any>("LMSCommit", {
          detail: w.API.renderCMIToJSONObject(),
        });
        w.ScormEvents.dispatchEvent(ev);
      });
      setInit(true);
    }
  }, [init, initialData]);

  useEffect(() => {
    const handler = (ev) => {
      onCommit(ev.detail.cmi);
      if (["passed", "completed"].includes(ev.detail.cmi.core.lesson_status)) {
        if (iframe.current == null) {
          return;
        }
        const player = iframe.current.contentWindow.window.GetPlayer();
        const vars = programme.questions.map((x) => {
          let value = "";
          try {
            value = player.GetVar(x.varName);
          } catch {
            console.log("Couldn't find VAR " + x.varName);
          }
          return {
            id: x.id,
            varName: x.varName,
            value: value,
          };
        });
        onComplete({
          challengeDays: parseInt(
            player.GetVar(programme.challengeDaysVarName),
            10
          ),
          variables: vars,
        });
      } else {
        console.log("not complete yet");
      }
    };

    w.ScormEvents.addEventListener("LMSCommit", handler);

    return () => {
      w.ScormEvents.removeEventListener("LMSCommit", handler);
    };
  }, [onComplete, programme, onCommit]);

  return (
    <div>
      {init ? (
        <Frame
          src={"/mindset-scorm" + url}
          ref={iframe}
          style={fullscreen ? {height: height - 150} : { height: Math.min(height - 310, parentWidth * 0.6) }}
        />
      ) : null}
    </div>
  );
};

export default ProgrammePlayer;
