import React from "react";
import styled from "styled-components";
import {
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";
import Panel from "./Panel";
import Text from "./Text";

export const WidgetGrid = styled.div<GridProps & SpaceProps & LayoutProps>`
  display: grid;
  ${grid};
  ${layout};
  ${space};
`;

export type SingleValueWidgetProps = {
  title: string;
  value: number;
};

export const SingleValueWidget = ({ title, value }: SingleValueWidgetProps) => (
  <Panel p={2}>
    <Text fontSize={2} color="grey2" fontWeight={600} mb={1}>
      {title.toUpperCase()}
    </Text>
    <Text fontSize={4} fontWeight={600}>
      {value ?? "-"}
    </Text>
  </Panel>
);
