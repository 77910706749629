import { Button, Text } from "@coaching-culture/ui";
import { lighten, linearGradient } from "polished";
import styled, { keyframes } from "styled-components";

export const SplashContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  margin: 24px;
  align-items: center;
  padding: 18px;
  justify-content: center;
  border-radius: 6px;
  aspect-ratio: 16/9;
  background: ${(props) =>
    linearGradient({
      colorStops: [props.color, lighten(0.3, props.color)],
      toDirection: "20deg",
    })};
`;

const spin = keyframes`
  
  0% {
    opacity: 0;
    transform: rotateY(0deg);
  }

  20% {
    transform: rotateY(-45deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(720deg);
  }


`;

const MainText = styled(Text)`
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
`;

const WelcomeImage = styled.img`
  margin-bottom: 24px;
  width: 100%;
  animation: ${spin} 2s ease;
`;

const WelcomeImageContainer = styled.div`
  perspective: 500px;
  width: 30%;
`;

export type MindsetWelcomeSplashProps = {
  icon: string;
  color: string;
  name: string;
  onStart: () => void;
};

export function MindsetWelcomeSplash({
  icon,
  color,
  name,
  onStart,
}: MindsetWelcomeSplashProps) {
  return (
    <SplashContainer color={color}>
      <WelcomeImageContainer>
        <WelcomeImage src={process.env.REACT_APP_STATIC_ROOT + "/" + icon} />
      </WelcomeImageContainer>
      <MainText
        fontSize={5}
        fontWeight={600}
        textAlign="center"
        mb={3}
        color="white"
      >
        Welcome to {name}
      </MainText>
      <Button onClick={onStart} color={color} large width={180}>
        Begin
      </Button>
    </SplashContainer>
  );
}
