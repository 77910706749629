import React from "react";
import styled from "styled-components";
import { FaCheck, FaLock, FaClock } from "react-icons/fa";
import { space, SpaceProps } from "styled-system";
import { lighten } from "polished";

const Label = styled.label<SpaceProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  ${space};
`;

Label.defaultProps = {
  mb: 2,
};

type BoxProps = SpaceProps & {
  active: boolean;
};

const Box = styled.span<BoxProps>`
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid
    ${(props) => (props.active ? props.theme.colors.primary : "#ccc")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: ${(props) => (props.active ? props.theme.colors.primary : "#ccc")};
  cursor: pointer;
  flex: 0 0 24px;
  box-shadow: rgba(204, 219, 232, 0.6) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
  ${space};

  &:active,
  &:focus {
    outline: 0;
    box-shadow: inset 0px 0px 5px
      ${(props) => lighten(0.3, props.theme.colors.primary)};
  }
`;

type CheckboxProps = SpaceProps & {
  value: boolean;
  label?: string;
  onChange?: (newValue: boolean) => void;
  name?: string;
  waiting?: boolean;
  disabled?: boolean;
};

const CheckBox = ({
  value,
  label,
  onChange,
  name,
  waiting,
  disabled,
  ...rest
}: CheckboxProps) => {
  return (
    <Label
      {...rest}
      onClick={(ev) => {
        disabled ? null : onChange != null && onChange(!value);
      }}
    >
      <input type="hidden" name={name} value={value ? 1 : 0} />
      <Box active={value} mr={label ? 2 : 0}>
        {disabled ? (
          <FaLock />
        ) : waiting ? (
          <FaClock />
        ) : value ? (
          <FaCheck />
        ) : null}
      </Box>
      {label}
    </Label>
  );
};

export default CheckBox;
