import styled from "styled-components";
import { CoachingCultureColorName } from "../theme";

export type RuleProps = {
  ruleColor?: CoachingCultureColorName;
};

export const Rule = styled.hr<RuleProps>`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 2px;
  border: 0;
  background-color: ${(props) =>
    props.theme.colors[props.ruleColor ?? "grey5"]};
`;
