import {
  Button,
  Circle,
  Flex,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { FaMicrosoft } from "react-icons/fa";
import { useOrgAddons } from "@coaching-culture/api-client";
import { useOrg } from "auth/OrgProvider";

export const AddonsPage = () => {
  const returnUrl = `${process.env.REACT_APP_PLATFORM_ROOT}/success/addons/office365-auth`;
  const clientId = "7c39d4b7-06bb-4c26-80c1-4bb452ef7247";
  const msUrl = `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&redirect_uri=${returnUrl}`;

  const addons = useOrgAddons();
  const [org] = useOrg();

  const allowed = org.integrationsEnabled;

  return (
    <CenterColumn>
      <PageHeader
        text="Integrations"
        subtitle="Enhance the Coaching Culture platform."
      />
      {addons.isSuccess ? (
        <Panel p={[2, 3]}>
          <Flex mb={0} width="100%" alignItems="center">
            <Circle icon={FaMicrosoft} color="primary" mr={2} />
            <Flex flexDirection={"column"} flex="1" justifyContent={"center"}>
              <Text fontWeight={600}>Office 365</Text>
              <Text>
                Allow access to your organisations calendar for conversations
              </Text>
            </Flex>
            <Button
              color="primary"
              href={msUrl}
              disabled={!allowed || addons.data?.msTenant != null}
            >
              {addons.data?.msTenant != null
                ? "Enabled"
                : !allowed
                ? "Not Available"
                : "Authorise"}
            </Button>
          </Flex>
        </Panel>
      ) : (
        <Loader />
      )}
    </CenterColumn>
  );
};
