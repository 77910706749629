import { Box, Circle, ControlLabel, Flex, Text } from "@coaching-culture/ui";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useRef, useState } from "react";
import { FaCommentDots, FaPrayingHands } from "react-icons/fa";
import styled from "styled-components";
import { SpaceProps } from "styled-system";

type FormFeedbackTypeSelectProps = {
  label?: string;
  value: string | null;
  onChange: (val: string) => void;
  required?: boolean;
  error?: string | null;
} & SpaceProps;

const OptionButton = styled(Flex)`
  background: none;
  align-items: center;
  padding: 6px 18px;
  width: 100%;
  cursor: pointer;
  min-height: 74px;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
`;

const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 6px;
`;

export function FormFeedbackTypeSelect({
  label,
  value,
  onChange,
  required,
  error,
  ...rest
}: FormFeedbackTypeSelectProps) {
  const [selecting, setSelecting] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setSelecting(false);
  });

  return (
    <Box ref={ref} mb={2} {...rest}>
      <ControlLabel required={required}>{label}</ControlLabel>
      {!selecting ? (
        <OptionButton
          p={2}
          alignItems={"center"}
          border={1}
          borderRadius={6}
          justifyContent={"space-between"}
          onClick={() => setSelecting(!selecting)}
        >
          <Flex alignItems="center">
            <Circle
              color={value === "praise" ? "positive" : ""}
              size={"large"}
              mr={2}
            >
              {value === "praise" ? (
                <FaPrayingHands size={25} />
              ) : value === "feedback" ? (
                <FaCommentDots size={25} />
              ) : (
                <Text fontWeight={600} fontSize={5}>
                  ?
                </Text>
              )}
            </Circle>
            <div>
              <Text fontWeight={500}>
                {value === "praise"
                  ? "Praise"
                  : value === "feedback"
                  ? "Feedback"
                  : "Select Feedback Type"}
              </Text>
              <Text fontSize={12} fontWeight={500} color="grey2">
                {value === "praise"
                  ? "Show some love or give praise for a job well done"
                  : value === "feedback"
                  ? "Give some advice on what to improve on for next time"
                  : "Click to select feedback type"}
              </Text>
            </div>
          </Flex>
        </OptionButton>
      ) : (
        <Container>
          <OptionButton
            p={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={() => {
              setSelecting(false);
              onChange("praise");
            }}
          >
            <Flex alignItems="center">
              <Circle color="positive" size={"large"} mr={2}>
                <FaPrayingHands size={25} />
              </Circle>
              <div>
                <Text fontWeight={500}>Praise</Text>
                <Text fontSize={12} fontWeight={500} color="grey2">
                  Show some love or give praise for a job well done
                </Text>
              </div>
            </Flex>
          </OptionButton>
          <OptionButton
            p={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={() => {
              setSelecting(false);
              onChange("feedback");
            }}
          >
            <Flex alignItems="center">
              <Circle size={"large"} mr={2}>
                <FaCommentDots size={25} />
              </Circle>
              <div>
                <Text fontWeight={500}>Feedback</Text>
                <Text fontSize={12} fontWeight={500} color="grey2">
                  Give some advice on what to improve on for next time
                </Text>
              </div>
            </Flex>
          </OptionButton>
        </Container>
      )}
      {error && (
        <Text color="danger" fontSize={2} mt={1}>
          {error}
        </Text>
      )}
    </Box>
  );
}
