import React, { ReactNode } from "react";
import { IconType } from "react-icons/lib";
import styled from "styled-components";
import { Circle } from "..";
import { Flex } from "./Flex";

export type TimelineItemProps = {
  color?: "positive" | "danger" | "primary";
  icon?: IconType;
  children?: ReactNode;
};

const Line = styled.div`
  min-height: 60px;
  margin-right: 12px;
  position: relative;

  &:before {
    content: "";
    top: 6px;
    left: 8px;
    width: 2px;
    background-color: ${(props) => props.theme.colors.grey3};
    height: calc(100% - 5px);
    position: absolute;
    z-index: 0;
  }
`;

const Content = styled.div`
  flex: 1;
`;

export const TimelineItem = ({
  color = "primary",
  icon,
  children,
}: TimelineItemProps) => (
  <Flex width="100%">
    <Line>
      <Circle
        icon={icon}
        color={color}
        style={{ zIndex: 1 }}
        mt="1px"
        size={17}
      />
    </Line>
    <Content>{children}</Content>
  </Flex>
);
