import { Flex } from "@coaching-culture/ui";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

type TabsProps = {
  children: ReactElement[];
};

type TabButtonProps = {
  active: boolean;
};

export const TabButton = styled.button<TabButtonProps>`
  padding: 12px;
  cursor: pointer;
  background: none;
  border: 0;
  font-weight: ${(props) => (props.active ? 600 : 500)};
`;

const TabListContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 24px;
  flex-direction: row;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 2px;

  &:after {
    display: block;
    height: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.grey3};
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const ActiveMarker = styled(animated.div)`
  height: 2px;
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  bottom: 0px;
  z-index: 1;
`;

export type TabListProps = {
  options: string[];
  activeIndex: number;
  onSelect: (tabIndex: number) => void;
  spread?: boolean;
};

export const TabList = ({
  options,
  activeIndex,
  onSelect,
  spread,
}: TabListProps) => {
  const buttons = useRef<HTMLButtonElement[]>([]);
  const [pos, setPos] = useState<[number, number]>([0, 0]);

  const style = useSpring({
    left: pos[0],
    width: pos[1],
  });

  const optionsHash = options.join("");

  useEffect(() => {
    const item = buttons.current[activeIndex];
    setPos([item?.offsetLeft ?? 0, item?.offsetWidth ?? 0]);
  }, [activeIndex, optionsHash]);

  const handleKeyDown = (index: number) => (key: React.KeyboardEvent) => {
    if (key.code === "Enter") {
      onSelect(index);
    }
  };

  return (
    <TabListContainer>
      {options.map((x, i) => (
        <TabButton
          ref={(el) => (buttons.current[i] = el)}
          active={i === activeIndex}
          onClick={() => onSelect(i)}
          tabIndex={0}
          onKeyDown={handleKeyDown(i)}
          style={{ flex: spread ? 1 : undefined }}
        >
          {x}
        </TabButton>
      ))}
      <ActiveMarker style={style} />
    </TabListContainer>
  );
};

export const TabPane = styled.div`
  width: 100%;
`;

export const Tabs = ({ children }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <TabList
        options={children
          .filter((child) => React.isValidElement(child))
          .map((x) => x.props.defaultValue)}
        activeIndex={selectedTab}
        onSelect={setSelectedTab}
      />
      <Flex>
        {children.filter((child) => React.isValidElement(child))[selectedTab]}
      </Flex>
    </>
  );
};
