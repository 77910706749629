import { ActionItemSpec, UserDisplay } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormInput,
  PanelHeader,
  ReactModal,
  Text,
} from "@coaching-culture/ui";
import { FormDateSelect } from "components/FormDateSelect";
import { FormUserSelect } from "components/FormUserSelect";
import { getUnixTime } from "date-fns";

import { Controller, useForm } from "react-hook-form";

type ActionItemForm = {
  dueDate: Date | null;
  text: string;
  assignedTo: string;
};

type ActionItemModalProps = {
  assigneeOptions: UserDisplay[];
  onCreate: (item: ActionItemSpec) => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const ActionItemModal = ({
  onCreate,
  onCancel,
  assigneeOptions,
  isOpen,
}: ActionItemModalProps) => {
  const { register, errors, handleSubmit, control } = useForm<ActionItemForm>();

  const onSubmit = (spec: ActionItemForm) => {
    onCreate({
      dueDate: spec.dueDate == null ? null : getUnixTime(spec.dueDate),
      text: spec.text,
      assignedTo: spec.assignedTo,
      conversationId: "",
      occurenceIndex: 0,
    });
  };

  return (
    <ReactModal isOpen={isOpen} onClose={onCancel} width={600}>
      <>
        <PanelHeader>
          <Text fontWeight={600}>Add Action Item</Text>
        </PanelHeader>
        <Box p={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              name="text"
              aria-label="Action name"
              label="Action"
              maxLength={255}
              error={errors.text}
              ref={register({
                required: "Name is required",
                maxLength: { value: 255, message: "Limited to 255 characters" },
              })}
              required
            />
            <Controller
              control={control}
              rules={{ required: true }}
              name="assignedTo"
              render={({ value, onChange }) => (
                <FormUserSelect
                  label="Assignee"
                  value={value}
                  onChange={onChange}
                  error={errors.assignedTo}
                  options={assigneeOptions}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="dueDate"
              render={({ value, onChange }) => (
                <FormDateSelect
                  allowClear
                  label="Due Date (optional)"
                  value={value}
                  onChange={onChange}
                  mb={5}
                />
              )}
            />

            <Flex justifyContent={"flex-end"}>
              <Button type="button" onClick={onCancel} mr={2}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </Flex>
          </form>
        </Box>
      </>
    </ReactModal>
  );
};
