import {
  CoachingTopicDto,
  CreateConversationCommand,
  DirectReportDto,
} from "@coaching-culture/types";
import {
  AddButton,
  Button,
  Circle,
  Flex,
  FormInput,
  FormSelect,
  FormTextArea,
  FormToggle,
  IconButton,
  Input,
  Loader,
  Pips,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { FormDateSelect } from "components/FormDateSelect";
import { fromUnixTime, getDate, getDay, getUnixTime } from "date-fns";
import { CoachingTopicsModal } from "pages/Solutions/Performance/CoachingTopicsModal";
import { ConversationTypeSelect } from "pages/Solutions/Performance/ConversationTypeSelect";
import { useCreateConversation } from "queries/conversations";
import { useConversationTypes } from "queries/conversationTypes";
import { useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaCommentDots, FaGripVertical, FaTimes } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { animated, useSpring } from "react-spring";

import styled from "styled-components";
import { arrMove, ordinal } from "utils";
import { getDayName } from "utils/dates";
import { getDndItemStyle } from "utils/dragAndDropUtil";

const SectionIcon = styled.div`
  color: white;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 3px;
`;

const Drawer = animated(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  overflow-y: auto;
`);

const getDayOcc = (date: Date) => {
  const num = Math.ceil(getDate(date) / 7);

  return num === 5 ? "last" : ordinal(Math.ceil(getDate(date) / 7));
};

const getRecurrenceOptions = (date: number | null) => {
  if (date == null) {
    return [{ label: "Does not repeat", value: "none" }];
  } else {
    return [
      { label: "Does not repeat", value: "none" },
      {
        label: `Repeat every ${getDayName(getDay(fromUnixTime(date)))}`,
        value: "weekly",
      },
      {
        label: `Repeat every ${getDayName(
          getDay(fromUnixTime(date)),
        )} every 2 weeks`,
        value: "2weekly",
      },
      {
        label: `Repeat every ${getDayName(
          getDay(fromUnixTime(date)),
        )} every 3 weeks`,
        value: "3weekly",
      },
      {
        label: `Repeat every ${getDayName(
          getDay(fromUnixTime(date)),
        )} every 4 weeks`,
        value: "4weekly",
      },
      {
        label: `Repeat every ${getDayOcc(fromUnixTime(date))} ${getDayName(
          getDay(fromUnixTime(date)),
        )} every month`,
        value: "monthly",
      },
      {
        label: `Repeat every ${getDayOcc(fromUnixTime(date))} ${getDayName(
          getDay(fromUnixTime(date)),
        )} every 2 months`,
        value: "2monthly",
      },
      {
        label: `Repeat every ${getDayOcc(fromUnixTime(date))} ${getDayName(
          getDay(fromUnixTime(date)),
        )} every 3 months`,
        value: "3monthly",
      },
      {
        label: `Repeat every ${getDayOcc(fromUnixTime(date))} ${getDayName(
          getDay(fromUnixTime(date)),
        )} every 6 months`,
        value: "6monthly",
      },
      {
        label: `Repeat every year`,
        value: "yearly",
      },
    ];
  }
};

export type ScheduleConversationDrawerProps = {
  onClose: () => void;
  user: DirectReportDto;
};

export const ScheduleConversationDrawer = ({
  onClose,
  user,
}: ScheduleConversationDrawerProps) => {
  const [me] = useUser();
  const { data: types, isFetched: typesFetched } = useConversationTypes();
  const [addingCoachingTopics, setAddingCoachingTopics] = useState(false);

  const styleProps = useSpring({
    y: "0%",
    from: {
      y: "100%",
    },
  });

  const [spec, setSpec] = useState<Partial<CreateConversationCommand>>({
    directReportId: user.id,
    managerId: me.id,
    purpose: "",
    outcome: "",
    preparation: "",
    location: "",
    isOnlineMeeting: false,
    duration: 60,
    recurrence: "none",
  });

  const [step, setStep] = useState(0);

  const recurrenceOpt = useMemo(
    () => getRecurrenceOptions(spec.scheduledDate ?? null),
    [spec.scheduledDate],
  );

  const scheduleConvo = useCreateConversation();
  const qc = useQueryClient();

  const onSave = async () => {
    await scheduleConvo.mutateAsync(spec as CreateConversationCommand);
    qc.invalidateQueries("team");
    onClose();
  };

  const setConversationType = (id: string) => {
    const type = types.find((x) => x.id === id);

    setSpec({
      ...spec,
      conversationTypeId: id,
      purpose: type.name,
      discussionPoints: type.discussionPoints.map((x) => x.name),
    });
  };

  const goBack = () => {
    if (step === 0) {
      onClose();
    } else {
      setStep(step - 1);
    }
  };

  const goForth = async () => {
    if (step === 3) {
      onSave();
    } else if (step === 0) {
      if (Number.isNaN(spec.duration)) {
        return window.alert("Duration must be a number");
      }
      if (spec.duration <= 0) {
        return window.alert("Duration must be greater than 0");
      }
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };

  const canAdvance = () => {
    if (step === 0) {
      return spec.scheduledDate != null;
    }
    if (step === 1) {
      return spec.conversationTypeId != null;
    }
    return true;
  };

  const setSpecItem = (key: keyof CreateConversationCommand) => (val: any) => {
    setSpec({
      ...spec,
      [key]: val,
    });
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    setSpec({
      ...spec,
      discussionPoints: arrMove(
        spec.discussionPoints,
        result.source.index,
        result.destination.index,
      ),
    });
  };

  const setAgendaItem = (idx: number, value: string) => {
    const dp = [...spec.discussionPoints];
    dp[idx] = value;
    setSpec({
      ...spec,
      discussionPoints: dp,
    });
  };

  const removeAgendaItem = (idx: number) => {
    setSpec({
      ...spec,
      discussionPoints: spec.discussionPoints.filter((_, i) => i !== idx),
    });
  };

  const addAgendaItem = () => {
    setSpec({
      ...spec,
      discussionPoints: [...spec.discussionPoints, ""],
    });
  };

  const saveTopics = (topics: Partial<CoachingTopicDto[]>) => {
    const temp = topics.map((x) => x.body);

    setSpec({
      ...spec,
      discussionPoints: [...spec.discussionPoints, ...temp],
    });
    setAddingCoachingTopics(false);
  };

  return (
    <Drawer style={styleProps}>
      {!typesFetched && <Loader overlay />}
      <CoachingTopicsModal
        isOpen={addingCoachingTopics}
        onSave={(val) => saveTopics(val)}
        onCancel={() => setAddingCoachingTopics(false)}
      />
      <Flex p={"100px"} py={5} flexDirection="column">
        <Flex justifyContent={"center"}>
          <Flex alignItems={"center"} mb={5}>
            <SectionIcon>
              <FaCommentDots fontSize={"1.5em"} />
            </SectionIcon>
            <Text fontWeight={600} ml={2}>
              Conversations
            </Text>
          </Flex>
        </Flex>
        <Text fontSize={4} fontWeight={600}>
          Schedule a new conversation with {user.name.split(" ")[0]}.
        </Text>
        <Text mb={3} fontWeight={500} color="grey1">
          Regular feedback is important
        </Text>
        <Pips value={step} count={4} mb={3} />
        {step === 0 ? (
          <>
            <FormDateSelect
              showTime
              label="Scheduled Date"
              value={
                spec.scheduledDate == null
                  ? null
                  : fromUnixTime(spec.scheduledDate)
              }
              onChange={(val) =>
                setSpec({ ...spec, scheduledDate: getUnixTime(val) })
              }
            />
            <FormSelect
              label="Recurrence"
              options={recurrenceOpt}
              value={spec.recurrence}
              onChange={(ev) => {
                setSpec({ ...spec, recurrence: ev.target.value as any });
              }}
            />
            <FormInput
              value={spec.duration}
              type="number"
              min={5}
              step={1}
              onChange={(ev) =>
                setSpecItem("duration")(ev.target.valueAsNumber)
              }
              label={"Duration (minutes)"}
            />
            <FormToggle
              mb={2}
              label="Is Online Conversation"
              helpText="Takes place using an online provider"
              value={spec.isOnlineMeeting}
              onChange={(val) => setSpecItem("isOnlineMeeting")(val)}
            />
            <FormInput
              value={spec.isOnlineMeeting ? "Online" : spec.location}
              disabled={spec.isOnlineMeeting}
              label="Location"
              onChange={(ev) => setSpecItem("location")(ev.target.value)}
              mb={5}
            />
          </>
        ) : step === 1 ? (
          <>
            <ConversationTypeSelect
              label="Conversation Type"
              value={spec.conversationTypeId}
              onChange={setConversationType}
              options={types ?? []}
              required
              mb={3}
            />
            <FormInput
              value={spec.purpose}
              onChange={(ev) => setSpecItem("purpose")(ev.target.value)}
              label="What is the purpose of this conversation?"
              aria-label={"Purpose"}
            />
            <FormInput
              value={spec.outcome}
              onChange={(ev) => setSpecItem("outcome")(ev.target.value)}
              label="What is the desired outcome?"
              aria-label={"Outcome"}
              mb={3}
            />
          </>
        ) : step === 2 ? (
          <>
            <Text fontSize={4} fontWeight={500}>
              Agenda
            </Text>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {spec?.discussionPoints?.map((x, i) => (
                      <Draggable key={i} draggableId={i.toString()} index={i}>
                        {(provided, snapshot) => (
                          <Flex
                            key={i}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDndItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                            borderBottom={1}
                            alignItems="center"
                            p={2}
                          >
                            <Flex
                              {...provided.dragHandleProps}
                              alignItems="center"
                              mr={1}
                            >
                              <FaGripVertical color="#999" />
                            </Flex>
                            <Circle
                              color="primary"
                              icon={FaCommentDots}
                              mr={2}
                            />
                            <Input
                              value={x}
                              style={{ flex: 1 }}
                              mr={2}
                              onChange={(ev) =>
                                setAgendaItem(i, ev.target.value)
                              }
                            />
                            <IconButton
                              icon={FaTimes}
                              color="danger"
                              onClick={() => removeAgendaItem(i)}
                              title={"Delete discussion point"}
                              aria-label={"Delete discussion point"}
                            />
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Flex mb={3}>
              <AddButton
                aria-label="Add Discussion Point"
                mr={2}
                onClick={addAgendaItem}
                mt={2}
              >
                Add Discussion Point
              </AddButton>
              <AddButton
                onClick={() => setAddingCoachingTopics(true)}
                mt={2}
                aria-label="Add Discussion Point From Library"
              >
                Add Question From Library
              </AddButton>
            </Flex>
          </>
        ) : step === 3 ? (
          <FormTextArea
            height={150}
            value={spec.preparation}
            onChange={(ev) => setSpecItem("preparation")(ev.target.value)}
            label="Any recommended preparation for the conversation (Optional)"
            mb={3}
          />
        ) : null}
        <Flex width="100%" style={{ gap: 6 }}>
          <Button flex={1} onClick={goBack}>
            {step === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            flex={1}
            onClick={goForth}
            color="primary"
            disabled={!canAdvance()}
          >
            {step === 3 ? "Submit" : "Next"}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};
