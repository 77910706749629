import { ModuleProgramme } from "@coaching-culture/types";
import { sortBy } from "lodash";
import { Button, Circle, Flex, Text } from "@coaching-culture/ui";
import React from "react";
import { FaQuestion, FaStar } from "react-icons/fa";

export type ProgrammeSelectorProps = {
  programmes: ModuleProgramme[];
  color: string;
  onSelect: (id: string) => void;
  recommended: string | null;
};

export function ProgrammeSelector({
  programmes,
  color,
  onSelect,
  recommended,
}: ProgrammeSelectorProps) {
  return (
    <Flex p={5} alignItems="center" flexDirection="column">
      <Flex alignItems="center" flexDirection="column" mb={5}>
        <Circle icon={FaQuestion} color={color} size="large" mb={2} />
        <Text fontSize={5} fontWeight={600} mb={1}>
          Select Programme
        </Text>
        {recommended != null && (
          <Flex alignItems="center">
            Recommended
            <Text color="star" as="span" ml={1}>
              <FaStar />
            </Text>
          </Flex>
        )}
      </Flex>
      {sortBy(programmes, (x) => x.id === recommended)
        .reverse()
        .map((x) => (
          <Button
            width="240px"
            onClick={() => onSelect(x.id)}
            mb={2}
            color={color}
          >
            {x.name}
            {x.id === recommended ? (
              <Text color="star" as="span" ml={1}>
                <FaStar />
              </Text>
            ) : null}
          </Button>
        ))}
    </Flex>
  );
}
