import { Flex, Text } from "@coaching-culture/ui";
import { mix } from "polished";
import React, { useState } from "react";
import { animated, config, useSpring, useTrail } from "react-spring";
import styled, { useTheme } from "styled-components";

export const dotItems = [
  "Strongly Disagree",
  "Disagree",
  "Somewhat Disagree",
  "Neutral",
  "Somewhat Agree",
  "Agree",
  "Strongly Agree",
];

const DotButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 6px 12px;
  margin-bottom: 12px;
  min-width: 65px;
`;

const DotButtonElem = styled(animated.button)<{ color: string }>`
  border: 0;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

type RatingTypeProps = {
  reverse: boolean;
  value: number;
  onChange: (val: number) => void;
  requiresEvidence: boolean;
  evidenceLabel: string;
  allowNa: boolean;
  reverseScale: boolean;
};

type RatingButtonProps = {
  active: boolean;
  color: string;
  text: string;
  onClick: () => void;
};

const NoEvidenceButton = ({
  active,
  onClick,
  label,
}: {
  active: boolean;
  onClick: () => void;
  label: string;
}) => {
  const [hov, setHov] = useState<boolean>(false);

  const props = useSpring({
    scale: active ? 1.3 : 1,
    opacity: active || hov ? 1 : 0.3,
  });

  return (
    <DotButtonContainer>
      <Text textAlign="center" fontSize="12px" mb={1} maxWidth="110px">
        {label}
      </Text>
      <DotButtonElem
        onClick={onClick}
        color={"#ccc"}
        style={props}
        onMouseEnter={() => setHov(true)}
        onMouseLeave={() => setHov(false)}
      />
    </DotButtonContainer>
  );
};

const DotButton = ({ active, color, text, onClick }: RatingButtonProps) => {
  const [hov, setHov] = useState<boolean>(false);

  const props = useSpring({
    scale: active ? 1.3 : 1,
    opacity: active || hov ? 1 : 0.3,
  });

  return (
    <DotButtonContainer>
      <Text textAlign="center" fontSize="12px" mb={1}>
        {text.split(" ").map((x) => (
          <>
            {x}
            <br />
          </>
        ))}
      </Text>
      <DotButtonElem
        onClick={onClick}
        color={color}
        style={props}
        onMouseEnter={() => setHov(true)}
        onMouseLeave={() => setHov(false)}
      />
    </DotButtonContainer>
  );
};

export const DotsRating = ({
  requiresEvidence,
  reverse,
  value,
  onChange,
  evidenceLabel,
  allowNa,
  reverseScale,
}: RatingTypeProps) => {
  const theme = useTheme();

  const items = dotItems.map((x, i, self) => ({
    text: x,
    color: reverseScale
      ? mix(i / (self.length - 1), theme.colors.danger, theme.colors.positive)
      : mix(i / (self.length - 1), theme.colors.positive, theme.colors.danger),
  }));

  let trail = useTrail(allowNa || requiresEvidence ? 8 : 7, {
    config: config.stiff,
    from: {
      y: 20 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex justifyContent="center" flexWrap="wrap" mb={[0, 5]}>
      {trail.slice(0, 7).map((styles, i) => (
        <animated.div
          style={{
            ...styles,
            flex: "1",
          }}
        >
          <DotButton
            {...items[i]}
            active={i + 1 === value}
            onClick={() => onChange(i + 1)}
          />
        </animated.div>
      ))}
      {(requiresEvidence || allowNa) && (
        <animated.div style={trail[7]}>
          <NoEvidenceButton
            label={allowNa ? "N/A" : evidenceLabel}
            active={value === -1}
            onClick={() => onChange(-1)}
          />
        </animated.div>
      )}
    </Flex>
  );
};
