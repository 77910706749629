import {
  CommentDto,
  ConversationDto,
  ConversationOccurrenceDto,
} from "@coaching-culture/types";
import {
  Box,
  Circle,
  ExpandableContainer,
  Flex,
  Label,
  LinkButton,
  Loader,
  Panel,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { Comment } from "components/Comment";
import { Toggle } from "components/Toggle";
import {
  format,
  fromUnixTime,
  getWeek,
  isThisWeek,
  isToday,
  isYesterday,
} from "date-fns";
import { groupBy, sortBy } from "lodash";
import { useConversations } from "queries/conversations";
import { useMemo, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaCommentDots,
  FaInfoCircle,
} from "react-icons/fa";
import styled from "styled-components";

const DateContainer = styled.div<{ completed: boolean; cancelled: boolean }>`
  padding-left: 18px;
  margin-left: 18px;
  padding-bottom: 12px;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};

  &:before {
    content: "";
    display: block;
    background-color: ${(props) =>
      props.completed
        ? props.theme.colors.positive
        : props.cancelled
        ? props.theme.colors.danger
        : props.theme.colors.primary};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
    left: -9px;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    background-color: ${(props) => props.theme.colors.grey3};
    height: calc(100% - 10px);
    position: absolute;
    left: 0;
    top: 6px;
  }
`;

const CommentContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

type ConvoAndInstance = ConversationDto & ConversationOccurrenceDto;

function Convo({ conversation }: { conversation: ConvoAndInstance }) {
  const [user] = useUser();
  const [displayPrivate, setDisplayPrivate] = useState(false);

  return (
    <Flex mb={2} flexDirection={["column", "row"]}>
      <Flex justifyContent={"space-between"} flexBasis={["100%", "50%"]}>
        <div>
          <Text mt={2} fontWeight={600}>
            Purpose
          </Text>
          <Text>{conversation.purpose}</Text>
          <Text mt={2} fontWeight={600}>
            Outcome
          </Text>
          <Text>{conversation.outcome}</Text>
          <Text mt={2} fontWeight={600}>
            Discussion Points
          </Text>

          {conversation.agenda.map((dp) => (
            <Flex alignItems="center" mb={0} p={1}>
              <Circle
                color={
                  dp.addedBy === conversation.directReport.id
                    ? "orange"
                    : "primary"
                }
                mr={2}
                size={10}
              />
              <Text style={{ flex: 1 }} fontWeight={500}>
                {dp.name}
              </Text>
              {dp.addedBy === conversation.directReport.id && (
                <Label size="small" color="orange">
                  Suggested by Direct Report
                </Label>
              )}
            </Flex>
          ))}
        </div>
      </Flex>
      <Flex flexDirection={"column"} flexBasis={["100%", "50%"]}>
        <Text mt={2} fontWeight={600}>
          Notes
        </Text>
        {user.id === conversation.manager.id && (
          <Toggle
            mb={3}
            mt={2}
            value={displayPrivate}
            onChange={setDisplayPrivate}
            rightLabel={"Private"}
            leftLabel={"Public"}
          />
        )}
        <CommentContainer>
          {conversation.comments.length > 0 ? (
            conversation.comments
              .filter((x) => x.isPrivate === displayPrivate)
              .map((item, i) => {
                return <Comment comment={item as CommentDto} />;
              })
          ) : (
            <Flex alignItems="center" justifyContent="center" flex="1">
              <Text color="grey3" fontSize="2em" mr={2} mb={0} lineHeight={0}>
                <FaInfoCircle />
              </Text>
              <Text fontSize={3} fontWeight={600} color="grey2">
                No Comments
              </Text>
            </Flex>
          )}
        </CommentContainer>
      </Flex>
    </Flex>
  );
}

export function ConversationHistory({
  userId,
  convoDate,
}: {
  userId: string;
  convoDate: number;
}) {
  const { data: convos, isFetched } = useConversations();
  const [selectedConversation, setSelectedConversation] = useState({
    id: "",
    index: 0,
  });

  const [user] = useUser();

  const allInstances = (convos ?? []).flatMap((x) =>
    x.occurences.map((o) => ({
      ...x,
      ...o,
      cancelledOn: x.cancelledOn ?? o.cancelledOn,
    }))
  );
  const filteredConvos = useMemo(() => {
    return allInstances.filter(
      (x) =>
        x.cancelledOn === null &&
        x.scheduledDate < convoDate &&
        x.directReport.id === userId
    );
  }, [allInstances, userId, convoDate]);

  const humanizeDate = (value: Date) => {
    if (value != null) {
      if (isYesterday(value)) {
        return "Yesterday";
      } else if (isToday(value)) {
        return "Today";
      } else if (isThisWeek(value)) {
        return "This Week";
      } else if (getWeek(new Date()) - getWeek(value) === 1) {
        return "Last Week";
      } else if (getWeek(new Date()) - getWeek(value) === -1) {
        return "Next Week";
      } else {
        return format(value, "MMMM yyyy");
      }
    }
    return "";
  };

  const groupConvos = (data: ConvoAndInstance[]) => {
    const entries = Object.entries(
      groupBy(data, (x) => humanizeDate(fromUnixTime(x.scheduledDate)))
    );

    return sortBy(entries, (x) => x[1][0].scheduledDate).map((x) => ({
      label: x[0],
      items: x[1],
    }));
  };

  if (!isFetched) {
    return <Loader />;
  }

  return (
    <>
      <Flex flex={1} alignItems={"flex-start"} flexDirection={"row"}>
        <Panel width={"100%"}>
          <PanelHeader p={5}>
            <Circle mr={2} color="primary">
              <FaCommentDots size={20} />
            </Circle>
            <Text fontSize={4} fontWeight={600} color="black" as="h2">
              Past Conversations
            </Text>
          </PanelHeader>

          <Box mt={2} p={4}>
            {filteredConvos.length > 0 && (
              <>
                {groupConvos(filteredConvos)
                  .reverse()
                  .map((x) => (
                    <>
                      <Text mt={2} fontSize={4} fontWeight={500} mb={2}>
                        {x.label}
                      </Text>
                      {sortBy(x.items, (x) => x.scheduledDate)
                        .reverse()
                        .map((x, i, self) => (
                          <Box mb={i === self.length - 1 ? 0 : 2}>
                            <DateContainer
                              cancelled={x.cancelledOn !== null}
                              completed={x.completedOn !== null}
                            >
                              <Flex mb={2} flexDirection={"row"}>
                                <Flex flexGrow={8}>
                                  <Flex flexDirection={"column"}>
                                    <Flex alignItems="center" mb={1}>
                                      <Text fontWeight={600}>
                                        {format(
                                          fromUnixTime(x.scheduledDate),
                                          "do MMMM yyyy @ p"
                                        )}
                                        {x.recurrence !== "none" && (
                                          <Text
                                            as="span"
                                            color="grey2"
                                            fontSize={2}
                                            ml={1}
                                          >
                                            (Recurring)
                                          </Text>
                                        )}
                                      </Text>
                                    </Flex>
                                    <Flex alignItems="center" mb={1}>
                                      <Text fontWeight={500}>
                                        {x.type}{" "}
                                        {x.completedOn !== null
                                          ? "(Complete)"
                                          : x.cancelledOn !== null
                                          ? "(Cancelled)"
                                          : ""}
                                      </Text>
                                    </Flex>
                                    <Flex alignItems="center">
                                      <Text
                                        fontStyle={"italic"}
                                        fontWeight={500}
                                      >
                                        With{" "}
                                        {x.manager.id === user.id
                                          ? x.directReport.name
                                          : x.manager.name}
                                        {user.id === x.directReport.id
                                          ? "(Manager)"
                                          : "(Direct Report)"}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>

                                <Flex width={1 / 4} justifyContent={"flex-end"}>
                                  <LinkButton
                                    onClick={() =>
                                      setSelectedConversation({
                                        id: x.id,
                                        index: x.index,
                                      })
                                    }
                                  >
                                    <Text as="span" mr={1} fontWeight={600}>
                                      Expand
                                    </Text>
                                    {selectedConversation.id === x.id &&
                                    selectedConversation.index === x.index ? (
                                      <FaChevronUp
                                        style={{ position: "relative", top: 2 }}
                                      />
                                    ) : (
                                      <FaChevronDown
                                        style={{ position: "relative", top: 2 }}
                                      />
                                    )}
                                  </LinkButton>
                                </Flex>
                              </Flex>

                              <ExpandableContainer
                                expanded={
                                  selectedConversation.id === x.id &&
                                  selectedConversation.index === x.index
                                }
                              >
                                {selectedConversation.id === x.id &&
                                  selectedConversation.index === x.index && (
                                    <Convo conversation={x}></Convo>
                                  )}
                              </ExpandableContainer>
                            </DateContainer>
                          </Box>
                        ))}
                    </>
                  ))}
              </>
            )}
          </Box>
        </Panel>
      </Flex>
    </>
  );
}
