import { MeasureSpec } from "@coaching-culture/types";
import {
  Modal,
  Text,
  Button,
  Flex,
  Rule,
  FormInput,
} from "@coaching-culture/ui";
import { useForm } from "react-hook-form";

type MeasureOptionSpec = MeasureSpec["options"][number];

export type MeasureOptionModalProps = {
  value: MeasureOptionSpec;
  onSave: (value: MeasureOptionSpec) => void;
  onCancel: () => void;
};

export const MeasureOptionModal = ({
  value,
  onSave,
  onCancel,
}: MeasureOptionModalProps) => {
  const { register, handleSubmit, errors } = useForm<MeasureOptionSpec>({
    defaultValues: value,
  });

  const onSubmit = (values: MeasureOptionSpec) => {
    onSave(values);
  };

  return (
    <Modal width={600} showCloseButton onClose={onCancel} p={5}>
      <Text fontSize={5} fontWeight={600} mb={4}>
        Edit Measure Option
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input ref={register} name="id" type="hidden" />
        <FormInput
          ref={register({ required: true, maxLength: 255 })}
          name="name"
          label="Name"
          required
          error={errors.name}
        />
        <Rule />
        <Flex>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button ml={2} type="button" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
