import { Button, Circle } from "@coaching-culture/ui";
import { FaUserPlus } from "react-icons/fa";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grey2};
  border-radius: 12px;
  border: 3px dashed ${(props) => props.theme.colors.grey3};
  padding: 32px;
`;

export type AddTeamMemberButtonProps = {
  onClick: () => void;
};

export const AddTeamMemberButton = ({ onClick }: AddTeamMemberButtonProps) => {
  return (
    <Container>
      <Circle icon={FaUserPlus} size="xxlarge" color="grey3" mb={5} />
      <Button onClick={onClick} color="primary">
        Add Direct Report
      </Button>
    </Container>
  );
};
