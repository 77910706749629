import { sanitizeEmail } from "@coaching-culture/types";
import {
  Alert,
  Box,
  Button,
  Flex,
  FormInput,
  Panel,
  PanelInset,
  SelectionButton,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import UserProfileImage from "components/UserProfileImage";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";

export type FeedbackIntroResult = {
  id?: string;
  name: string;
  email: string;
  relationshipId: string | null;
};

export type FeedbackIntroProps = {
  sender: string;
  senderId: string;
  onComplete: (res: FeedbackIntroResult) => void;
  relationships: { id: string; name: string }[] | null;
  questionCount: number;
  estimatedDuration: number;
};

const AniPanel = animated(Panel);

export function FeedbackIntro({
  sender,
  senderId,
  onComplete,
  relationships,
  questionCount,
  estimatedDuration,
}: FeedbackIntroProps) {
  const [user] = useUser();
  const [override, setOverride] = useState<boolean>(false);
  const [relationship, setRelationship] = useState<string | null>(null);
  const [dirty, setDirty] = useState<boolean>(false);

  const spring = useSpring({
    from: {
      y: 50,
    },
    y: 0,
  });

  const { register, handleSubmit, errors } = useForm<FeedbackIntroResult>();

  const aboutYou = user != null && user.id === senderId && !override;

  const onSubmit = (values: FeedbackIntroResult) => {
    if (relationships != null && relationship == null && !aboutYou) {
      setDirty(true);
      return;
    }
    onComplete({
      ...values,
      email: sanitizeEmail(values.email),
      relationshipId: relationship,
    });
  };

  const defaultUser = () => {
    if (relationships != null && relationship == null && !aboutYou) {
      setDirty(true);
      return;
    }

    onComplete({
      id: user.id,
      email: sanitizeEmail(user.email),
      name: `${user.firstName} ${user.lastName}`,
      relationshipId: relationship,
    });
  };

  if (aboutYou) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <AniPanel p={3} width={600} maxWidth="100%" style={spring} m={2}>
          <Text as="h1" fontSize={5} textAlign="center" mb={5} mt={2}>
            Complete this survey about yourself
          </Text>
          <Text mb={2} fontSize={4}>
            This survey is about you:
          </Text>
          <PanelInset p={2} mb={3}>
            <Flex alignItems="center">
              <UserProfileImage
                name={user.name}
                image={user.profileImage}
                size="large"
                mr={2}
              />
              <Flex flexDirection="column" justifyContent="center">
                <Text fontWeight={600}>{user.name}</Text>
                <Text color="grey2">{user.email}</Text>
              </Flex>
            </Flex>
          </PanelInset>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection={["column", "row"]}
          >
            <Button onClick={() => setOverride(true)} m={1}>
              Use different details
            </Button>
            <Button color="primary" m={1} onClick={defaultUser}>
              Continue as this user
            </Button>
          </Flex>
        </AniPanel>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <AniPanel p={3} width={600} maxWidth="100%" m={2} style={spring}>
        <Text as="h1" fontSize={[4, 5]} textAlign="center" mb={3} mt={2}>
          {sender} has invited you to
          <br /> complete this survey
        </Text>
        <Alert icon={FaInfoCircle} color="primary" mb={3}>
          <Text fontWeight={600}>
            This survey has {questionCount} questions
          </Text>
          <Text>It should take about {estimatedDuration} minutes</Text>
        </Alert>
        {user != null && !override ? (
          <>
            <Text mb={2} fontSize={[3, 4]}>
              You're currently logged in as the below user:
            </Text>
            <PanelInset p={2} mb={3}>
              <Flex alignItems="center">
                <UserProfileImage
                  name={user.name}
                  image={user.profileImage}
                  size="large"
                  mr={2}
                />
                <Flex flexDirection="column" justifyContent="center">
                  <Text fontWeight={600}>{user.name}</Text>
                  <Text color="grey2">{user.email}</Text>
                </Flex>
              </Flex>
            </PanelInset>
            {relationships != null && (
              <Box mb={3}>
                <Text fontSize={[3, 4]} mb={2}>
                  What is your relationship with {sender}?
                </Text>
                <Flex flexDirection="column">
                  {relationships.map((x) => (
                    <SelectionButton
                      active={relationship === x.id}
                      onClick={() => setRelationship(x.id)}
                    >
                      {x.name}
                    </SelectionButton>
                  ))}
                </Flex>
                {dirty && relationship == null && (
                  <Text color="danger">Please select a relationship</Text>
                )}
              </Box>
            )}
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection={["column", "row"]}
            >
              <Button onClick={() => setOverride(true)} m={1}>
                Use different details
              </Button>
              <Button color="primary" m={1} onClick={defaultUser}>
                Continue as this user
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Text mb={2}>
              Please complete the details below before beginning
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                name="name"
                label="Name"
                error={errors.name}
                ref={register({ required: true })}
              />
              <FormInput
                name="email"
                label="Email"
                type="email"
                error={errors.email}
                mb={5}
                ref={register({ required: true })}
              />
              {relationships != null && (
                <Box mb={3}>
                  <Text fontSize={[3, 4]} mb={2}>
                    What is your relationship with {sender}?
                  </Text>
                  <Flex flexDirection="column">
                    {relationships.map((x) => (
                      <SelectionButton
                        active={relationship === x.id}
                        onClick={() => setRelationship(x.id)}
                      >
                        {x.name}
                      </SelectionButton>
                    ))}
                  </Flex>
                  {dirty && relationship == null && (
                    <Text color="danger">Please select a relationship</Text>
                  )}
                </Box>
              )}
              <Flex justifyContent="space-between" alignItems="center">
                <Button type="submit">Continue</Button>
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </Flex>
            </form>
          </>
        )}
      </AniPanel>
    </Flex>
  );
}
