import { GoalOutcomeOptions } from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  Img,
  ReactModal,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { useState } from "react";
import imgSrc from "../../../img/complete_goal.svg";

type CompleteGoalModalProps = {
  goalId: string;
  onComplete: (outcome: GoalOutcomeOptions) => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const CompleteGoalModal = ({
  onComplete,
  onCancel,
  isOpen,
}: CompleteGoalModalProps) => {
  const terms = useGoalTerminology();
  const [achieved, setAchieved] = useState(false);
  const [closed, setClosed] = useState(false);

  return (
    <ReactModal onClose={onCancel} isOpen={isOpen} width={600}>
      <>
        <PanelHeader>
          <Text fontSize={18} fontWeight={600}>
            Has the {terms.goal.asBody()} been achieved?
          </Text>
        </PanelHeader>
        <Box p={3}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Img width={"200px"} src={imgSrc} />
            {!achieved && !closed && (
              <Flex
                width="250px"
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  mb={2}
                  onClick={() => setAchieved(true)}
                  color="primary"
                >
                  Yes, the {terms.goal.asBody()} was achieved
                </Button>
                <Button onClick={() => setClosed(true)}>
                  No, the {terms.goal.asBody()} wasn't quite met
                </Button>
              </Flex>
            )}

            {achieved && (
              <Flex
                width="250px"
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => onComplete("completedachieved")}
                  color="positive"
                  mb={2}
                >
                  The {terms.goal.asBody()} was completed
                </Button>
                <Button onClick={() => onComplete("exceeded")} color="positive">
                  The {terms.goal.asBody()} was exceeded
                </Button>
              </Flex>
            )}

            {closed && (
              <Flex
                width="250px"
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => onComplete("partially")}
                  mb={2}
                  color="warning"
                >
                  The {terms.goal.asBody()} was partially met
                </Button>
                <Button
                  onClick={() => onComplete("missed")}
                  mb={2}
                  color="danger"
                >
                  The {terms.goal.asBody()} was missed
                </Button>
                <Button onClick={() => onComplete("nla")} color="primary">
                  The {terms.goal.asBody()} is no longer applicable
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex mt={2} justifyContent={"flex-end"}>
            <Button type="button" onClick={onCancel} mr={2}>
              Cancel
            </Button>
            {(closed || achieved) && (
              <Button
                onClick={() => {
                  setClosed(false);
                  setAchieved(false);
                }}
                color="primary"
              >
                Back
              </Button>
            )}
          </Flex>
        </Box>
      </>
    </ReactModal>
  );
};
