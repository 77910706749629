import { MeasureDto } from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Circle,
  Flex,
  IconButton,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { ExplainImage } from "components/ExplainImage";
import { PageHeader } from "components/PageHeader";
import {
  useDeleteMeasure,
  useMeasures,
  useMeasuresConfig,
  useUpdateMeasuresConfig,
} from "queries/measures";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaChartLine, FaGripVertical, FaPen, FaTrash } from "react-icons/fa";
import { useHistory } from "react-router";
import { arrMove } from "utils";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import convoSrc from "../../../img/conversations.svg";
import { ReminderScheduleForm } from "./ReminderScheduleForm";

export function MeasuresConfiguration() {
  const { data, isFetched } = useMeasures();
  const config = useMeasuresConfig();
  const updateConfig = useUpdateMeasuresConfig();
  const history = useHistory();
  const deleteMeasure = useDeleteMeasure();

  const [opts, setOpts] = useState<MeasureDto[]>([]);

  useEffect(() => {
    setOpts(data ?? []);
  }, [data]);

  const newItem = () => {
    history.push(`/success/settings/measures/create`);
  };

  const editItem = (id: string) => {
    history.push(`/success/settings/measures/${id}`);
  };

  const onDeleteMeasure = (id: string) => {
    if (window.confirm("Are you sure you want to delete this measure?")) {
      deleteMeasure.mutateAsync(id);
    }
  };

  const onDragEnd = async (result: DropResult) => {
    const newOrder = arrMove(
      opts,
      result.source.index,
      result.destination.index
    );
    setOpts(newOrder);
    updateConfig.mutateAsync({
      ...config.data,
      measuresOrder: newOrder.map((x) => x.id),
    });
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Measures"
        subtitle="Define the different measures used by your organisation."
      />
      <Panel p={[2, 3]} mb={3}>
        <ExplainImage
          src={convoSrc}
          title={"Measures"}
          body={
            "Measures are a great way to continuously track performance giving you trends over time\nUse this section to customise the measures that are most important to your organisation"
          }
        />
        {!isFetched ? (
          <Loader />
        ) : (
          <Box mt={2}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {opts.map((x, i) => (
                      <Draggable key={i} draggableId={i.toString()} index={i}>
                        {(provided, snapshot) => (
                          <Flex
                            border={1}
                            borderRadius={6}
                            p={2}
                            mb={2}
                            alignItems="center"
                            key={i}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDndItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Flex
                              {...provided.dragHandleProps}
                              alignItems="center"
                            >
                              <FaGripVertical color="#999" />
                            </Flex>
                            <Circle
                              icon={FaChartLine}
                              color="primary"
                              mr={2}
                              ml={2}
                            />
                            <Text style={{ flex: 1 }} fontWeight={500}>
                              {x.name}
                            </Text>
                            <IconButton
                              color="body"
                              icon={FaPen}
                              onClick={() => editItem(x.id)}
                            />
                            <IconButton
                              ml={1}
                              color="danger"
                              icon={FaTrash}
                              onClick={() => onDeleteMeasure(x.id)}
                            />
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Flex width="100%" flexDirection="column">
              <AddButton onClick={newItem}>Add New Measure</AddButton>
            </Flex>
          </Box>
        )}
      </Panel>
      <ReminderScheduleForm />
    </CenterColumn>
  );
}
