import React, { useState, useEffect } from "react";
import { useUser } from "../../auth";
import axios from "axios";
import {
  Modal,
  Button,
  Text,
  Flex,
  Loader,
  Circle,
} from "@coaching-culture/ui";
import UserProfileImage from "../../components/UserProfileImage";
import { FaUsers } from "react-icons/fa";
import OrgDirectoryDrawer from "./OrgDirectory";
import ModuleDirectoryDrawer from "./ModuleDirectory";
import MenuItem from "./MenuItem";
import { useInviteUserToGroup } from "queries/users";

export type GroupInviteConfig = {
  groupId: string | null;
  userId: string | null;
};

export type GroupInviteModalProps = {
  onClose: () => any;
  config: GroupInviteConfig;
};

const GroupInviteModal = ({ onClose, config }: GroupInviteModalProps) => {
  const [me] = useUser();
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  const [drawerShown, setDrawerShown] = useState(null);
  const inviteUser = useInviteUserToGroup();

  const { userId, groupId } = config;

  useEffect(() => {
    const ps = [];
    setLoading(true);
    if (userId != null) {
      const p = axios.get(`/api/users/${userId}`).then(({ data }) => {
        setUser(data);
      });
      ps.push(p);
    }

    if (groupId != null) {
      const p = axios.get(`/api/groups/${groupId}`).then(({ data }) => {
        setGroup(data);
      });
      ps.push(p);
    }

    Promise.all(ps).then(() => setLoading(false));
  }, [userId, groupId]);

  const ready = user != null && group != null;
  const userDisabled = config.userId != null;
  const itemDisabled = config.groupId != null;

  const doInvite = async () => {
    try {
      await inviteUser.mutateAsync({ groupId: group.id, userId: user.id });
      onClose();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 409) {
        window.alert("Already a member/invited");
      } else {
        window.alert(err);
      }
      setLoading(false);
    }
  };

  const onUserSelect = (user) => {
    if (user) {
      setUser(user);
    }
    setDrawerShown(null);
  };

  const onGroupSelect = (item) => {
    if (item) {
      setGroup(item);
    }
    setDrawerShown(null);
  };

  return (
    <Modal
      color="primary"
      width={600}
      p={[2, 5]}
      pt={5}
      pb={5}
      onClose={() => onClose()}
      showCloseButton={!loading}
      style={{ overflow: "hidden" }}
    >
      {loading && <Loader overlay color="primary" />}
      {drawerShown === "user" && (
        <OrgDirectoryDrawer onSelect={onUserSelect} me={me} />
      )}
      {drawerShown === "item" && (
        <ModuleDirectoryDrawer onSelect={onGroupSelect} />
      )}
      <Flex alignItems="center" flexDirection="column" mb={5}>
        <Circle color="primary" icon={FaUsers} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Invite User To Group
        </Text>
      </Flex>
      <Flex mb={5} flexDirection="column">
        <MenuItem
          title={group ? group.name : "Select Group"}
          image={
            <Circle
              mr={2}
              size="medium"
              icon={group ? FaUsers : null}
              color={group ? "blue" : null}
            />
          }
          subtitle={group ? "Group selected" : "Select Group"}
          disabled={itemDisabled}
          onClick={() => setDrawerShown("item")}
        />
        <MenuItem
          image={
            user != null ? (
              <UserProfileImage name={user.name} mr={2} color="blue" />
            ) : (
              <Circle mr={2} size="medium" />
            )
          }
          disabled={userDisabled}
          title={user ? user.name : "Who would you like to invite?"}
          subtitle={user ? user.email : "Select the user to invite."}
          onClick={() => setDrawerShown("user")}
        />
      </Flex>
      <Flex justifyContent="center">
        <Button onClick={doInvite} disabled={!ready} color="blue">
          Invite
        </Button>
      </Flex>
    </Modal>
  );
};

export default GroupInviteModal;
