import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export type ControlLabelProps = SpaceProps & {
  required?: boolean;
};

export const ControlLabel = styled.label<ControlLabelProps>`
  display: block;
  font-weight: 500;
  font-size: 13px;
  ${space};

  &:after {
    content: "${(props) => (props.required ? " *" : "")}";
    color: ${(props) => props.theme.colors.danger};
  }
`;

ControlLabel.defaultProps = {
  mb: 1,
};

export default ControlLabel;
