import {
  discriminate,
  FeedbackCampaign,
  FormResponses,
  FreeTextQuestionFormItemResponses,
  ManagerCampaign,
  MultipleChoiceQuestionFormItemResponses,
  RatingFormItemResponses,
  Section,
  UserDisplay,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  CheckBox,
  Flex,
  Img,
  Label,
  Loader,
  Panel,
  PanelInset,
  Text,
} from "@coaching-culture/ui";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { groupBy, mean, meanBy, uniq } from "lodash";
import { normalizeRating } from "pages/Solutions/Feedback/UserCampaignDisplay";
import Papa from "papaparse";
import { useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { FaPrint } from "react-icons/fa";
import ReactToPrint from "react-to-print";
import styled, { useTheme } from "styled-components";
import { downloadContent } from "utils";
import { defaultChartOptions } from "utils/chartJsUtil";
import { formatDate } from "utils/dates";
import imgSrc from "../img/logo.png";
import { FreeTextInsights } from "./FreeTextInsights";
import { MultipleChoiceInsights } from "./MultipleChoiceInsights";
import {
  dotNames,
  RatingInsights,
  RatingSmiles,
  RatingStars,
} from "./RatingInsights";

const PrintDocument = styled.div`
  @media print {
    a[href]:after {
      content: none !important;
    }
    html,
    body {
      height: auto;
    }
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  ...defaultChartOptions(
    { label: "SCORE", max: 10, min: 0, stepSize: 1 },
    { label: "QUESTION" },
  ),
  ...{
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  },
};

const PageBreak = styled.div`
  @media all {
    display: none;
  }

  @media print {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
    a[href]:after {
      content: none !important;
    }
  }
`;

const HeaderDiv = styled.div`
  @media screen {
    overflow: hidden;
    height: 0;
  }
`;

type RelationshipFilterProps = {
  options: {
    id: string;
    name: string;
  }[];
  value: string[];
  onChange: (val: string[]) => void;
};

function RelationshipFilter({
  options,
  value,
  onChange,
}: RelationshipFilterProps) {
  const toggleOption = (id: string) => (val: boolean) => {
    if (val) {
      onChange([...value, id]);
    } else {
      onChange(value.filter((x) => x !== id));
    }
  };

  return (
    <PanelInset p={3} mb={3}>
      <Text mb={3} fontSize={4}>
        Filter
      </Text>
      <Flex flexWrap="wrap">
        <CheckBox
          label="<All>"
          mr={2}
          value={value.length === 0}
          mb={0}
          onChange={(val) => {
            val && onChange([]);
          }}
        />
        {options.map((x) => (
          <CheckBox
            label={x.name}
            mr={2}
            value={value.includes(x.id)}
            onChange={toggleOption(x.id)}
            mb={0}
          />
        ))}
      </Flex>
    </PanelInset>
  );
}

const ForceRowFlex = styled(Flex)`
  @media print {
    flex-direction: row !important;
  }
`;

export function groupBySection(items: FormResponses[]): Section[] {
  const sections: Section[] = [];
  let activeSection: Section = { items: [] };

  items.forEach((x) => {
    if (x.type === "heading") {
      if (activeSection.items.length > 0) {
        sections.push(activeSection);
      }
      activeSection = { heading: x, items: [] };
    } else if (x.type !== "content") {
      activeSection.items.push(x);
    }
  });

  if (activeSection.items.length > 0) {
    sections.push(activeSection);
  }

  return sections;
}

const SectionContainer = styled.div`
  padding-left: 24px;
  margin-bottom: 32px;
  border-left: 3px solid ${(props) => props.theme.colors.primary};
`;

const getAverage = (section: Section, userToExclude: string) => {
  const allRatings = section.items.every(
    (x) => x.type === "rating" || x.type === "free_text",
  );
  const ratingItems = section.items.filter(discriminate("type", "rating"));
  const sameType =
    allRatings &&
    ratingItems
      .map((x) => x.ratingType)
      .every((x, _, self) => x !== "dots" && x === self[0]);

  if (!sameType) {
    return [null, 0] as const;
  }

  const ratings = ratingItems
    .map((x) =>
      mean(
        x.responses
          .filter((x) => x.email !== userToExclude)
          .map((r) => r.rating)
          .filter((x) => x != null),
      ),
    )
    .filter((x) => !Number.isNaN(x));

  if (ratings.length === 0) {
    return [null, 0] as const;
  }

  const type = ratingItems[0]?.ratingType;

  const av = mean(ratings);

  return [type, av] as const;
};

export const SectionHeading = ({
  section,
  userToExclude,
}: {
  section: Section;
  userToExclude: string;
}) => {
  if (section.heading == null) {
    return null;
  }

  const [type, av] = getAverage(section, userToExclude);

  return (
    <>
      <ForceRowFlex
        justifyContent="space-between"
        flexDirection={["column", "row"]}
        alignItems="center"
        mb={3}
        borderBottom={1}
        pb={1}
      >
        <Text fontSize={5} fontWeight={600}>
          {section.heading.content}{" "}
          <small>
            ({section.items.length} item{section.items.length === 1 ? "" : "s"})
          </small>
        </Text>
        {type != null && (
          <Flex alignItems="flex-end">
            <Text fontSize={3} fontWeight={600} mr={1} lineHeight={1.13}>
              Section Avg:
            </Text>
            {type === "stars" ? (
              <RatingStars value={av} />
            ) : type === "dots" ? (
              <Text fontSize={4} fontWeight={600} lineHeight={1}>
                {dotNames[Math.floor(av) - 1]}
              </Text>
            ) : type === "smiles" ? (
              <RatingSmiles value={av} />
            ) : type === "number" ? (
              <Text fontSize={4} fontWeight={600} lineHeight={1}>
                {av.toFixed(1)}
              </Text>
            ) : null}
          </Flex>
        )}
      </ForceRowFlex>
    </>
  );
};

const AverageTotal = ({
  sections,
  userToExclude,
}: {
  sections: Section[];
  userToExclude: string;
}) => {
  const avs = sections
    .map((x) => getAverage(x, userToExclude))
    .filter((x) => x[0] != null);

  if (avs.length === 0) {
    return null;
  }
  const allSame = avs.every((x, _, self) => x[0] === self[0][0]);

  if (!allSame) {
    return null;
  }

  const av = mean(avs.map((x) => x[1]));
  const type = avs[0][0];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      border={1}
      p={3}
      mb={3}
      borderRadius="6px"
    >
      <Text fontSize={5} fontWeight={600} mr={3}>
        Overall Average Score:
      </Text>
      {type === "stars" ? (
        <RatingStars value={av} />
      ) : type === "dots" ? (
        <Text fontSize={4} fontWeight={600} lineHeight={1}>
          {dotNames[Math.floor(av) - 1]}
        </Text>
      ) : type === "smiles" ? (
        <RatingSmiles value={av} />
      ) : type === "number" ? (
        <Text fontSize={4} fontWeight={600} lineHeight={1}>
          {av.toFixed(1)}
        </Text>
      ) : null}
    </Flex>
  );
};

export type FeedbackInsightsProps = {
  insights: FormResponses[] | false | "not-available-yet" | null;
  relationships: { id: string; name: string }[];
  highlightedUser: UserDisplay | null;
  showResponses: boolean;
  title?: string;
  campaign: ManagerCampaign | FeedbackCampaign;
};

export type InsightsDataProps = {
  insights: FormResponses[];
  relationships: { id: string; name: string }[];
  highlightedUser: UserDisplay | null;
  showResponses: boolean;
  printing: boolean;
  campaign: ManagerCampaign | FeedbackCampaign;
  relationshipFilter?: string[];
};

const InsightsData = ({
  insights,
  relationships,
  highlightedUser,
  showResponses,
  printing,
  campaign,
  relationshipFilter = [],
}: InsightsDataProps) => {
  const [insightsFilter, setInsightsFilter] =
    useState<string[]>(relationshipFilter);
  const theme = useTheme();

  const items = insights.map<FormResponses>((x) => {
    if ("responses" in x) {
      return {
        ...x,
        responses:
          insightsFilter.length === 0
            ? x.responses
            : (x.responses as any[]).filter((x) =>
                insightsFilter.includes(x.feedbackRelationshipId),
              ),
      } as any;
    } else {
      return x;
    }
  });

  const userEmail =
    (campaign as ManagerCampaign).user != null
      ? (campaign as ManagerCampaign).user.email
      : "";

  const sections = groupBySection(items);

  const getUserAnswers = (obj: RatingFormItemResponses) => {
    if (obj.responses.some((x) => x.email === userEmail)) {
      return normalizeRating(
        obj.responses.find((x) => x.email === userEmail).rating,
        obj.ratingType,
      );
    } else {
      return 0;
    }
  };

  const getRelationshipAverages = (obj: RatingFormItemResponses) => {
    const filtered =
      relationshipFilter.length > 0
        ? obj.responses.filter((x) =>
            relationshipFilter.some((y) => y === x.feedbackRelationshipId),
          )
        : obj.responses.filter(
            (x) => x.email !== userEmail && x.feedbackRelationshipId != null,
          );

    const grouped = groupBy(filtered, (x) => x.feedbackRelationshipId);
    const data = Object.entries(grouped).map(([relName, items]) => {
      if (items.length > 0) {
        return +meanBy(
          items.filter((x) => x.email !== userEmail && x.rating != null),
          (z) => {
            return normalizeRating(z.rating, obj.ratingType);
          },
        ).toFixed(2);
      }
      return 0;
    });
    return data;
  };

  return (
    <>
      {relationships.length > 0 ? (
        !printing ? (
          <RelationshipFilter
            options={relationships}
            value={insightsFilter}
            onChange={setInsightsFilter}
          />
        ) : (
          <Text fontSize={5} fontWeight={600}>
            {relationshipFilter.length > 0
              ? relationships.find((x) => x.id === relationshipFilter[0]).name
              : "All Relationships"}
          </Text>
        )
      ) : null}
      <AverageTotal
        sections={sections}
        userToExclude={highlightedUser?.email ?? ""}
      />
      {sections.map((section, i) => (
        <div>
          <SectionHeading
            section={section}
            userToExclude={highlightedUser?.email ?? ""}
          />
          <SectionContainer>
            {section.items.map((x, j) => {
              if (x.type === "rating") {
                return (
                  <>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-evenly"}
                      flexDirection={["column", "column", "column", "row"]}
                    >
                      <Box
                        width={
                          relationships.length > 0
                            ? ["100%", "100%", "100", "60%"]
                            : "100%"
                        }
                      >
                        <RatingInsights
                          item={x}
                          user={highlightedUser}
                          showResponses={showResponses}
                          paginated={!printing}
                        />
                      </Box>
                      {relationships.length > 0 && (
                        <Box p={3} width={["100%", "100%", "100", "40%"]}>
                          <Bar
                            height={relationships.length * 100}
                            options={options}
                            data={{
                              labels: [
                                "My Score (" + userEmail + ")",
                                ...uniq(
                                  x.responses
                                    .filter(
                                      (z) => z.feedbackRelationshipId != null,
                                    )
                                    .map((x) =>
                                      relationships
                                        .find(
                                          (y) =>
                                            y.id === x.feedbackRelationshipId,
                                        )
                                        .name.toUpperCase(),
                                    ),
                                ),
                              ],
                              datasets: [
                                {
                                  yAxisID: "y1",
                                  xAxisID: "x1",
                                  label: "Average Score/ Score",
                                  backgroundColor: [
                                    theme.colors.body,
                                    theme.colors.primary,
                                    theme.colors.positive,
                                    theme.colors.warning,
                                  ],
                                  barThickness: 40,
                                  data: [
                                    getUserAnswers(
                                      insights.find(
                                        (y) => y.id === x.id,
                                      ) as RatingFormItemResponses,
                                    ),
                                    ...getRelationshipAverages(x),
                                  ],
                                },
                              ],
                            }}
                          />
                        </Box>
                      )}
                    </Flex>
                    {j !== section.items.length - 1 && <PageBreak />}
                  </>
                );
              } else if (x.type === "multiple_choice") {
                return (
                  <>
                    <MultipleChoiceInsights item={x} user={highlightedUser} />
                    {j !== section.items.length - 1 && <PageBreak />}
                  </>
                );
              } else if (x.type === "free_text") {
                return (
                  <>
                    <FreeTextInsights
                      item={x}
                      user={highlightedUser}
                      showResponses={showResponses}
                      paginated={!printing}
                    />
                    {j !== section.items.length - 1 && <PageBreak />}
                  </>
                );
              } else {
                return null;
              }
            })}
          </SectionContainer>
          {i !== sections.length - 1 && <PageBreak />}
        </div>
      ))}
    </>
  );
};

export const FeedbackInsightsDisplay = ({
  insights,
  title = "Report",
  campaign,
  ...rest
}: FeedbackInsightsProps) => {
  const insightsRef = useRef<HTMLDivElement>(null!);
  const [printing, setPrinting] = useState<boolean>(false);

  const exportResults = () => {
    const items = insights as FormResponses[];
    const item = campaign;

    const getRelationship = (id: string) => {
      return item.relationships.find((z) => z.id === id)?.name ?? "";
    };

    const sections = groupBySection(items as FormResponses[]);
    let relationRow = { Section: "", Question: "", Type: "" };

    let result = sections.flatMap((section) =>
      section.items
        .filter((y) => y.type !== "heading" && y.type !== "content")
        .map((x, i) => {
          let row = {
            Section: section.heading?.content,
            Question: x.content,
            Type: x.type,
          };
          if (i === 0) {
            const temp = x as any;
            temp.responses.forEach((y, j) => {
              relationRow = {
                ...relationRow,
                [item.anonType === "transparent"
                  ? y.name
                  : `Respondent ${j + 1}`]: getRelationship(
                  y.feedbackRelationshipId,
                ),
              };
            });
          }
          if (x.type === "rating") {
            const rating = x as RatingFormItemResponses;
            const arr = rating.responses.map((y, j) => {
              return {
                [item.anonType === "transparent"
                  ? y.name
                  : `Respondent ${j + 1}`]: normalizeRating(
                  y.rating,
                  x.ratingType,
                ),
              };
            });
            return {
              ...row,
              ...Object.fromEntries(arr.flatMap(Object.entries)),
            };
          } else if (x.type === "free_text") {
            const textResult = x as FreeTextQuestionFormItemResponses;
            const arr = textResult.responses.map((y, j) => ({
              [item.anonType === "transparent"
                ? y.name
                : `Respondent ${j + 1}`]: y.content,
            }));
            return {
              ...row,
              ...Object.fromEntries(arr.flatMap(Object.entries)),
            };
          } else {
            const multipleResult = x as MultipleChoiceQuestionFormItemResponses;
            const arr = multipleResult.responses.map((y, j) => ({
              [item.anonType === "transparent"
                ? y.name
                : `Respondent ${j + 1}`]: multipleResult.answers.find(
                (x) => x.id === y.answer,
              ).content,
            }));
            return {
              ...row,
              ...Object.fromEntries(arr.flatMap(Object.entries)),
            };
          }
        }),
    );

    if (item.collectRelationship) {
      result.splice(0, 0, relationRow as any);
    }

    const csv = Papa.unparse(result, { delimiter: ",", header: true });
    downloadContent(csv, `feedback.csv`);
  };

  return (
    <>
      <Flex justifyContent="space-between" mb={1} alignItems="flex-end">
        <Text fontSize={4} fontWeight={500}>
          {title}
        </Text>
        {insights && (
          <Flex justifyContent={"flex-end"}>
            <ReactToPrint
              onBeforeGetContent={() => {
                setPrinting(true);
                return new Promise((res) => setTimeout(res));
              }}
              onAfterPrint={() => setPrinting(false)}
              trigger={() => (
                <Button color="primary" icon={FaPrint}>
                  Print
                </Button>
              )}
              content={() => insightsRef.current}
            />
            <Button ml={3} onClick={() => exportResults()}>
              Export
            </Button>
          </Flex>
        )}
      </Flex>
      <Panel p={3}>
        <PrintDocument ref={insightsRef}>
          <HeaderDiv>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Img
                ml={"auto"}
                mr={"auto"}
                mt={6}
                src={imgSrc}
                width={"200px"}
              />
              <Text
                mt={6}
                mb={3}
                textAlign={"center"}
                fontSize={6}
                fontWeight={600}
              >
                {title}
              </Text>
              {(campaign as ManagerCampaign).user != null && (
                <Text
                  mt={6}
                  mb={3}
                  textAlign={"center"}
                  fontSize={5}
                  fontWeight={600}
                >
                  {(campaign as ManagerCampaign).user.name}
                </Text>
              )}

              <Flex
                justifyContent={"center"}
                mb={3}
                alignItems={"center"}
                style={{ gap: 3 }}
              >
                <Text mr={3} fontWeight={500}>
                  Report Visible To:
                </Text>
                {campaign.resultsVisibility.includes("admin") && (
                  <Label color="primary">Admins</Label>
                )}
                {campaign.resultsVisibility.includes("manager") && (
                  <Label color="primary">User's Manager</Label>
                )}
                {campaign.resultsVisibility.includes("self") && (
                  <Label color="primary">The User</Label>
                )}
              </Flex>
              {campaign.createdOn != null && (
                <Text textAlign={"center"}>
                  Created:{" "}
                  {formatDate(
                    new Date(campaign.createdOn),
                    "dd/MM/yyyy : HH:mm",
                  )}
                </Text>
              )}
              <PageBreak />
            </Flex>
          </HeaderDiv>
          {insights === false ? (
            <Text textAlign="center" fontSize={5}>
              Results for this campaign are not visible to you.
            </Text>
          ) : insights === "not-available-yet" ? (
            <Text textAlign="center" fontSize={5}>
              Results on an anonymous survey are not available until at least{" "}
              {campaign.minimumForwards} respondents have completed it
            </Text>
          ) : insights == null ? (
            <Loader />
          ) : (
            <>
              <InsightsData
                campaign={campaign}
                insights={insights}
                {...rest}
                printing={printing}
              />
              {rest.relationships.map((relation) => (
                <div
                  style={
                    !printing
                      ? {
                          position: "absolute",
                          top: "-9999px",
                          left: "-9999px",
                        }
                      : {}
                  }
                >
                  <PageBreak />
                  <InsightsData
                    relationshipFilter={
                      rest.relationships.length > 0 ? [relation.id] : []
                    }
                    insights={insights as FormResponses[]}
                    {...rest}
                    printing={printing}
                    campaign={campaign}
                  />
                </div>
              ))}
            </>
          )}
        </PrintDocument>
      </Panel>
    </>
  );
};
