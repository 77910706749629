import React from "react";
import { FaChevronLeft, FaChevronRight, FaFileExport } from "react-icons/fa";
import styled from "styled-components";
import Button from "./Button";
import Flex from "./Flex";
import Text from "./Text";

const PageButton = styled(Button)`
  min-width: 0 !important;
  height: 28px;
  font-size: 0.9em;
  flex: 0 0 auto;
`;

export type TablePaginationRowProps = {
  activePage: number;
  pageSize: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  itemCount: number;
  onExport?: () => void;
  onPageSizeChange?: (newVal: number) => void;
};

export function TablePaginationRow({
  activePage,
  pageSize,
  onNextPage,
  itemCount,
  onPrevPage,
  hasPrevPage,
  hasNextPage,
  onExport,
}: TablePaginationRowProps) {
  const start = (activePage - 1) * pageSize + 1;
  const end = Math.min(itemCount, start + pageSize - 1);

  return (
    <Flex alignItems="center">
      <Text mt={"4px"} fontWeight={500} fontSize={2} color="grey1">
        {start}-{end} of {itemCount}
      </Text>
      <PageButton
        onClick={onPrevPage}
        color="primary"
        ml={2}
        disabled={!hasPrevPage}
      >
        <FaChevronLeft />
      </PageButton>
      <PageButton
        onClick={onNextPage}
        color="primary"
        ml={1}
        disabled={!hasNextPage}
      >
        <FaChevronRight />
      </PageButton>
      {onExport && (
        <PageButton
          onClick={onExport}
          ml={1}
          size="small"
          color="primary"
          icon={FaFileExport}
        >
          Export
        </PageButton>
      )}
    </Flex>
  );
}
