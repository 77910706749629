import React, { useState } from "react";
import styled from "styled-components";
import { sum } from "lodash";

const Container = styled.svg`
  height: 100%;
  width: 100%;
`;

const MainPath = styled.path<{ blockColor: string }>`
  stroke: ${(props) => props.theme.colors[props.blockColor]};
`;

const Legend = styled.text<{ blockColor: string }>`
  font-size: 12px;
  font-weight: 600;
  fill: ${(props) => props.theme.colors[props.blockColor]};
`;

const Point = styled.circle<{ blockColor: string }>`
  stroke: ${(props) => props.theme.colors[props.blockColor]};
  stroke-width: 2;
  fill: white;
`;

const Value = styled.text<{ blockColor: string }>`
  fill: ${(props) => props.theme.colors[props.blockColor]};
  font-size: 13px;
  & tspan:first-child {
    font-weight: 600;
    font-size: 20px;
  }
`;

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInRadians: number
) {
  angleInRadians -= Math.PI / 2;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

export type GaugeChartProps = {
  value: number;
  total: number;
  invertColours: boolean;
};

export const GaugeChart = ({
  value,
  total,
  invertColours,
}: GaugeChartProps) => {
  let ratio = value / total;
  if (ratio === 0) {
    ratio = 0.1;
  }
  let theta = -1.5708;
  let size = ratio * Math.PI;
  const valueArc = describeArc(0, 0, 90, theta, theta + size);
  theta += size;
  const totalArc = describeArc(0, 0, 90, theta, 1.5708);

  const colour = (): string => {
    if (invertColours) {
      return ratio < 0.01
        ? "positive"
        : ratio > 0.01 && ratio < 0.5
        ? "warning"
        : "danger";
    }
    return ratio < 0.5
      ? "danger"
      : ratio > 0.5 && ratio < 0.99
      ? "warning"
      : "positive";
  };

  return (
    <Container viewBox={`20 0 160 60`}>
      <g transform="translate(100,60),scale(0.5)">
        <g>
          <MainPath
            blockColor={colour()}
            d={valueArc}
            fill="transparent"
            strokeWidth={25}
            stroke="#43B5C0"
          />
          <MainPath
            blockColor={"grey"}
            d={totalArc}
            fill="transparent"
            strokeWidth={25}
            stroke="#43B5C0"
          />
        </g>
        <g>
          <Value x="0" y="-4" blockColor={colour()} textAnchor="middle">
            <tspan>
              {value} / {total}
            </tspan>
          </Value>
        </g>
      </g>
    </Container>
  );
};

export type DonutGraphProps = {
  values: {
    label: string;
    value: number;
  }[];
};

const DonutGraph = ({ values }: DonutGraphProps) => {
  const [over, setOver] = useState<number | null>(null);

  const total = sum(values.map((x) => x.value));

  const arcs: string[] = [];
  let theta = 0;

  const sizes = values.map((x) => Math.round((x.value / total) * 100));
  const lengths = values.map((x) => x.label);

  for (const val of values) {
    const size = (val.value / total) * Math.PI * 2;
    arcs.push(describeArc(0, 0, 90, theta, theta + size));
    theta += size;
  }

  const mouseOver = (idx: number | null) => {
    setOver(idx);
  };

  const colors = [
    "blue",
    "green",
    "orange",
    "pink",
    "grey",
    "positive",
    "danger",
    "warning",
  ];

  return (
    <Container viewBox={`0 0 400 280`}>
      <g transform="translate(130,140),scale(0.9)">
        <g onMouseOut={() => mouseOver(null)}>
          <circle
            cx={0}
            cy={0}
            r={90}
            fill="none"
            strokeWidth={50}
            stroke="rgba(128,128,128,0.5)"
          />
          {arcs.map((x, i) => (
            <MainPath
              blockColor={colors[i]}
              d={x}
              fill="transparent"
              strokeWidth="50"
              stroke="#43B5C0"
              onMouseOver={() => mouseOver(i)}
            />
          ))}
          <circle
            cx={0}
            cy={0}
            r={65}
            fill="rgba(0,0,0,0.01)"
            onMouseOver={() => mouseOver(null)}
          />
        </g>
        {over != null && (
          <g>
            <Value x="0" y="-4" blockColor={colors[over]} textAnchor="middle">
              <tspan>{sizes[over]}</tspan>
              <tspan dx="2">%</tspan>
            </Value>
            <Value
              x="0"
              y="17"
              fill="#655"
              blockColor={colors[over]}
              textAnchor="middle"
            >
              {lengths[over]}
            </Value>
          </g>
        )}
      </g>
      {values.length === 0 ? (
        <Legend
          x={340}
          y={110}
          width={50}
          alignmentBaseline="middle"
          blockColor={colors[0]}
        >
          No Data
        </Legend>
      ) : (
        values.map((x, i, self) => {
          const s = 153 - (self.length / 2) * 21;
          return (
            <g transform="translate(-70,0)" key={i}>
              <Point cx={340} cy={s + i * 20} r="4" blockColor={colors[i]} />
              <Legend
                x={350}
                y={s + i * 20 + 1}
                width={50}
                alignmentBaseline="middle"
                blockColor={colors[i]}
              >
                {x.label.length > 16
                  ? x.label.substring(0, 13) + "..."
                  : x.label}
              </Legend>
            </g>
          );
        })
      )}
    </Container>
  );
};

export default DonutGraph;
