import React from "react";
import { FaCopy, FaExclamationTriangle, FaThumbsUp } from "react-icons/fa";
import styled from "styled-components";
import { SpaceProps, space } from "styled-system";
import { IconToast, useToast } from "./Toast";

const Container = styled.div<SpaceProps>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  max-width: 100%;
  background-color: ${(props) => props.theme.colors.grey4};
  box-shadow: rgb(204, 219, 232) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
  ${space};

  & span {
    padding: 8px 12px;
  }
`;

const CopyButton = styled.button`
  border: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${(props) => props.theme.colors.grey3};
  color: ${(props) => props.theme.colors.grey2};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const TextContainer = styled.span`
  flex: 1;
  min-width: 0;
  overflow: auto;
`;

export type ShareLinkProps = {
  url: string;
} & SpaceProps;

export function ShareLink({ url, ...rest }: ShareLinkProps) {
  const toast = useToast();

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      toast({
        content: (
          <IconToast
            icon={FaThumbsUp}
            iconColor="positive"
            text="Copied to clipboard"
          />
        ),
      });
    } catch (e) {
      toast({
        content: (
          <IconToast
            icon={FaExclamationTriangle}
            iconColor="danger"
            text="Failed to copy to clipboard"
          />
        ),
      });
    }
  };

  const selectAll = (ev: React.MouseEvent) => {
    window.getSelection()?.selectAllChildren(ev.currentTarget);
  };

  return (
    <Container {...rest}>
      <TextContainer onClick={selectAll} style={{ flex: 1 }}>
        {url}
      </TextContainer>
      <CopyButton onClick={copy}>
        <FaCopy />
      </CopyButton>
    </Container>
  );
}
