import React from "react";
import { useUser } from "../../auth";
import { Box, Text } from "@coaching-culture/ui";
import HomeGrid from "components/HomeGrid";

import mindsetIcon from "../../img/mindset.svg";
import lessonsIcon from "../../img/lessons.svg";
import feedbackIcon from "../../img/feedback.svg";
import surveysIcon from "../../img/surveys.svg";
import assessmentsIcon from "../../img/assessments.svg";
import performanceIcon from "../../img/performance.svg";

const items = [
  {
    name: "Mindset",
    content: "Self-coach tool",
    cta: "Start Now",
    product: "mindset",
    url: "/solutions/mindset",
    icon: mindsetIcon,
  },
  {
    name: "Lessons",
    content: "Learn how to coach",
    cta: "Start Now",
    product: "lessons",
    url: "/solutions/lessons",
    icon: lessonsIcon,
  },
  {
    name: "Feedback",
    content: "Get some feedback",
    cta: "Start Now",
    product: "360",
    url: "/solutions/feedback",
    icon: feedbackIcon,
  },
  {
    name: "Surveys",
    content: "Complete outstanding surveys",
    cta: "Start Now",
    product: "surveys",
    url: "/solutions/surveys",
    icon: surveysIcon,
  },
  {
    name: "Assessments",
    content: "Assess yourself",
    cta: "Start Now",
    product: "assessments",
    url: "/solutions/assessments",
    icon: assessmentsIcon,
  },
  {
    name: "Performance",
    content: "Performance management",
    cta: "Start Now",
    product: "performance",
    url: "/solutions/performance",
    icon: performanceIcon,
  },
];

const Solutions = () => {
  const [user] = useUser();

  return (
    <Box p={[3, 5]} pt={4}>
      <Text fontSize={[4, 8, 8, 10]} fontWeight={600} mb={[3, 5, 6]}>
        Hi {user.firstName}, ready to release
        <br /> your potential?
      </Text>
      <HomeGrid items={items} />
    </Box>
  );
};

export default Solutions;
