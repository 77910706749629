import {
  Text,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
  Flex,
  Button,
  SectionHeading,
} from "@coaching-culture/ui";
import React, { useMemo, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { sortBy } from "lodash";
import { format, fromUnixTime } from "date-fns";
import { UserSurvey } from "@coaching-culture/types";
import CenterColumn from "components/CenterColumn";
import styled from "styled-components";
import newCampaignSrc from "../../../img/choose.svg";
import defaultImgSrc from "../../../img/defaultImage.svg";
import { ExplainImage } from "components/ExplainImage";
import { PageHeader } from "components/PageHeader";

const CampaignImage = styled.img`
  box-shadow: ${(props) => props.theme.shadows[1]};
  width: 320px;
  object-fit: contain;
`;

const NoNewSurveys = () => {
  return (
    <Panel p={[2, 3]} mb={5} pb={[0, 0]}>
      <ExplainImage
        src={newCampaignSrc}
        title="You're all done!"
        body="You don't currently have any surveys to complete"
      />
    </Panel>
  );
};

const NewSurveys = ({ data }: { data: UserSurvey[] }) => {
  return (
    <Panel p={[2, 3]} mb={5} pb={[0, 0]}>
      <ExplainImage
        src={newCampaignSrc}
        title="You've been sent a survey!"
        body="Select a survey below to continue"
      />
      {sortBy(data, "createdOn")
        .reverse()
        .map((x, i) => (
          <Flex
            key={i}
            alignItems="stretch"
            borderBottom={i === data.length - 1 ? 0 : 1}
            p={[2, 3]}
            pb={[5, 5]}
            pt={[5, 5]}
            width="100%"
            justifyContent="stretch"
            flexDirection={["column", "row"]}
          >
            <CampaignImage
              src={
                x.thumbnail
                  ? `${process.env.REACT_APP_STATIC_ROOT}/${x.thumbnail}`
                  : defaultImgSrc
              }
            />
            <Flex
              ml={[0, 4]}
              justifyContent="space-between"
              flexDirection="column"
              alignItems="flex-startt"
              flex="1"
              mt={[3, 0]}
            >
              <Flex flexDirection="column" flex="1" width="100%">
                <Text fontSize={5} fontWeight={600}>
                  {x.name}
                </Text>
                <Text fontSize={2} color="grey" mb={3}>
                  Created on {format(fromUnixTime(x.createdOn), "dd/MM/yyyy")}
                </Text>
                {(x.description ?? "").trim() !== "" && (
                  <Text mb={3}>
                    {(x.description ?? "").split("\n").map((x) => (
                      <>
                        {x}
                        <br />
                      </>
                    ))}
                  </Text>
                )}
              </Flex>
              <Button
                to={`/solutions/surveys/${x.id}`}
                color="primary"
                width="160px"
              >
                Get Started
              </Button>
            </Flex>
          </Flex>
        ))}
    </Panel>
  );
};

export const SurveyList = () => {
  const [items, setItems] = useState<UserSurvey[] | null>(null);

  const loading = items == null;

  useEffect(() => {
    axios.get(`/api/surveys/me`).then(({ data }) => {
      setItems(sortBy(data, "createdOn"));
    });
  }, []);

  const cols = useMemo(
    (): SortableTableColumn<UserSurvey>[] => [
      {
        name: "name",
        label: "Name",
        headingProps: {
          style: {
            width: "50%",
          },
        },
      },
      {
        name: "completedOn",
        label: "Completed On",
        format: (x) => format(fromUnixTime(x.completedOn), "yyyy-MM-dd"),
      },
    ],
    []
  );

  const newCampaigns = items?.filter((x) => x.completedOn == null);
  const oldCampaigns = items?.filter((x) => x.completedOn != null);

  return (
    <CenterColumn>
      <PageHeader text="Surveys" subtitle="Surveys you've been sent" />
      {loading ? (
        <Loader />
      ) : (
        <>
          {newCampaigns.length > 0 ? (
            <NewSurveys data={newCampaigns} />
          ) : (
            <NoNewSurveys />
          )}
          <SectionHeading>Previous Surveys</SectionHeading>
          <Panel mb={5}>
            <SortableTable<UserSurvey>
              columns={cols}
              data={oldCampaigns}
              defaultSort={1}
              defaultDesc
              emptyMessage="No Current Campaigns"
            />
          </Panel>
        </>
      )}
    </CenterColumn>
  );
};
