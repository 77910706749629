import { UserFilter } from "@coaching-culture/types";
import { Box, Flex, Text } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useAvailableContent } from "queries/mindset";
import { useMemo, useState } from "react";
import { ContentReport } from "./ContentReport";
import { MatrixReport } from "./MatrixReport";

export const AssessmentsReport = () => {
  const [selected, setSelected] = useState<string>("matrix");
  const { data: content = [] } = useAvailableContent();

  const assessments = useMemo(
    () => content.filter((x) => x.type === "assessment"),
    [content],
  );

  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });

  const options = [
    {
      value: "matrix",
      label: "Summary By User",
    },
    ...assessments.map((x) => ({
      value: x.id.toString(),
      label: x.name,
    })),
  ];

  const commonProps = {
    options,
    selected,
    filter,
    onChange: setSelected,
    onFilterChange: setFilter,
  };

  return (
    <>
      <CenterColumn>
        <PageHeader
          text="Insights"
          subtitle="An overview of the data collected by the platform"
        />
        <Box>
          <Flex justifyContent={"space-between"} flex={1}>
            <Text mb={2} fontSize={5}>
              Assessments
            </Text>
          </Flex>
          {selected === "matrix" ? (
            <MatrixReport
              {...commonProps}
              type={"assessment"}
              content={assessments}
            />
          ) : (
            <ContentReport
              {...commonProps}
              type="assessment"
              contentId={selected}
            />
          )}
        </Box>
      </CenterColumn>
    </>
  );
};
