import {
  CommentDto,
  CreateGoalCommentCommand,
  GoalAssociationDto,
  GoalDto,
  GoalReviewStatusOptions,
  GoalStatusHistoryDto,
  ObjectiveDto,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Input,
  Label,
  Loader,
  Modal,
  OptionSelect,
  Panel,
  PanelHeader,
  RichTextEditor,
  Rule,
  Select,
  Slider,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { useGoalTerminology } from "auth/OrgProvider";
import { ScrollableContainer } from "components/ScrollableContainer";
import { useMyRelationships } from "queries/team";
import { useEffect, useState } from "react";
import {
  FaBullseye,
  FaChevronDown,
  FaChevronRight,
  FaEdit,
  FaFrown,
  FaGrinWink,
  FaLeaf,
  FaMedal,
  FaPaperPlane,
  FaPen,
  FaSmile,
  FaTrash,
} from "react-icons/fa";
import styled from "styled-components";
import { formatDate } from "utils/dates";
import {
  useApplicableCollectiveGoals,
  useCompleteGoal,
  useCreateGoalComment,
  useDeleteGoalComment,
  useGoalFields,
  useGoalsForUser,
  useReopenGoal,
  useUpdateGoal,
} from "../../../queries/performance";
import NotFound from "../../NotFound";
import { Comment } from "./../../../components/Comment";
import { CompleteGoalModal } from "./CompleteGoalModal";
import { EditGoalModal, EditObjectiveModal } from "./GoalEditor";
import { GoalStatusUpdate } from "./GoalStatusUpdate";
import { TimePeriod } from "./TimePeriod";

type AssociateGoalModalProps = {
  goals: GoalAssociationDto[];
  onSelect: (goalId: string) => void;
  onCancel: () => void;
};

const GoalButton = styled(Flex)`
  width: 100%;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey4};
  }
`;

const CommentContainer = styled.div`
  max-height: 800px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

function AssociateGoalModal({
  goals,
  onSelect,
  onCancel,
}: AssociateGoalModalProps) {
  const [selectedGoal, setselectedGoal] = useState<string>("");

  useEffect(() => {
    if (goals != null && goals.length > 0) {
      setselectedGoal(goals[0].collectiveGoalId);
    }
  }, [goals]);
  return (
    <Modal onClose={onCancel} width={400}>
      <Flex p={2} flexDirection="column">
        {goals.map((x) => (
          <GoalButton
            p={2}
            border={1}
            borderRadius={3}
            mb={1}
            onClick={() => {
              x.collectiveGoalId === selectedGoal
                ? setselectedGoal(null)
                : setselectedGoal(x.collectiveGoalId);
            }}
            flexDirection="column"
          >
            <Flex alignItems="center" justifyContent="center">
              {selectedGoal === x.collectiveGoalId ? (
                <FaChevronDown />
              ) : (
                <FaChevronRight />
              )}
              <Text ml={1} fontWeight={500} style={{ flex: 1 }} mr={2}>
                {x.name}
              </Text>
              <Label color="primary">
                {x.hasConditions ? "Shared" : x.type === "org" ? "Org" : "Team"}
              </Label>
            </Flex>
            {selectedGoal === x.collectiveGoalId && (
              <>
                <Text mt={2} mb={2}>
                  {x.description}
                </Text>

                <Flex justifyContent="center">
                  <Button
                    color="primary"
                    onClick={() => onSelect(x.collectiveGoalId)}
                  >
                    Add
                  </Button>
                </Flex>
              </>
            )}
          </GoalButton>
        ))}
        <Button mt={1} onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}

export type Activity = CommentDto | GoalStatusHistoryDto;

export type GoalEditorProps = {
  goal: GoalDto | null;
  onSave: (values: Partial<GoalDto>) => void;
  loading: boolean;
};

export const GoalReview = ({
  userId,
  goalId,
}: {
  userId?: string;
  goalId: string;
}) => {
  const [user] = useUser();
  const userIdVal = userId != null ? userId : user.id;
  const { data: goals, isFetched } = useGoalsForUser(userIdVal);
  const createGoalCommentMut = useCreateGoalComment();
  const { data: goalFields, isFetched: goalFieldsFetched } = useGoalFields();
  const { data: relationships, isFetched: relationsFetched } =
    useMyRelationships();

  const terms = useGoalTerminology();
  const updateGoal = useUpdateGoal(userIdVal);
  const completeGoal = useCompleteGoal();
  const { orgGoals, teamGoals } = useApplicableCollectiveGoals();

  const [choosingGoal, setChoosingGoal] = useState(false);
  const [completingGoal, setCompletingGoal] = useState(false);
  const [editingObj, setEditingObj] = useState<ObjectiveDto | "new" | null>(
    null,
  );
  const [editingGoal, setEditingGoal] = useState<GoalDto | null>(null);
  const [commentText, setCommentText] = useState<string>("");

  const reopenGoalMut = useReopenGoal();
  const deleteCommentMutator = useDeleteGoalComment();

  const [goalState, setGoalState] = useState<GoalDto>();
  let goal = (goals ?? []).find((x) => x.id === goalId);
  const [progress, setStateProgress] = useState(goal?.progress);
  const [activityFilter, setActivityFilter] = useState("all");
  const [activity, setActivity] = useState<Activity[]>([]);

  const options = [
    { value: "all", label: "All" },
    { value: "updates", label: "Updates" },
    { value: "comments", label: "Comments" },
  ];

  const statusOptions = [
    {
      value: "notstarted",
      label: "Not Started",
      color: "grey",
      icon: FaFrown,
    },

    {
      value: "inprogress",
      label: "In Progress",
      color: "warning",
      icon: FaGrinWink,
    },
    {
      value: "achieved",
      label: "Achieved",
      color: "positive",
      icon: FaSmile,
    },

    {
      value: "onhold",
      label: "On Hold",
      color: "grey",
      icon: FaFrown,
    },
    {
      value: "blocked",
      label: "Blocked",
      color: "danger",
      icon: FaFrown,
    },
  ];

  useEffect(() => {
    if (goal != null) {
      let temp = [...goal?.comments, ...goal?.statusHistory];
      for (const obj of goal.objectives) {
        temp = temp.concat(obj.statusHistory);
      }
      setGoalState(goal);
      temp.sort((a, b) => b.createdOn - a.createdOn);
      setActivity(temp);
      setStateProgress(goal.progress);
    }
  }, [goal]);

  const colGoals = orgGoals
    .map<GoalAssociationDto>((x) => ({
      name: x.name,
      description: x.description,
      type: "org",
      collectiveGoalId: x.id,
      lineManagerId: null,
      hasConditions: x.conditions.length > 0,
    }))
    .concat(
      teamGoals.map<GoalAssociationDto>((x) => ({
        name: x.name,
        description: x.description,
        type: "team",
        collectiveGoalId: x.id,
        lineManagerId: x.lineManagerId,
        hasConditions: false,
      })),
    );

  const applicableGoals = () => {
    if (colGoals != null) {
      return colGoals.filter(
        (x) =>
          !goal.associations.some(
            (a) => a.collectiveGoalId === x.collectiveGoalId,
          ),
      );
    }
  };

  const deleteGoal = (id: string) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      const spec = {
        ...goal,
        objectives: goal.objectives.filter((x) => x.id !== id),
      };
      updateGoal.mutateAsync(spec);
    }
  };

  const setObjectiveStatus = (
    val: GoalReviewStatusOptions,
    objectiveId: string,
  ) => {
    const currentStatus = goal.objectives.find(
      (obj) => obj.id === objectiveId,
    ).status;
    if (currentStatus !== val) {
      goal.objectives.find((obj) => obj.id === objectiveId).status = val;
      updateGoal.mutateAsync(goal);
    }
  };

  const saveObj = (values: ObjectiveDto) => {
    if (editingObj === "new") {
      goal.objectives = [...goal.objectives, values];
    } else {
      goal.objectives = goal.objectives.map((x) =>
        x.id === values.id ? values : x,
      );
    }
    updateGoal.mutateAsync(goal);
    setEditingObj(null);
  };

  const updateOverall = (val: GoalReviewStatusOptions) => {
    if (val !== goal.status) {
      goal.status = val;
      updateGoal.mutateAsync(goal);
    }
  };

  const removeAssociation = (id: string) => {
    goal.associations = goal.associations.filter(
      (x) => x.collectiveGoalId !== id,
    );
    updateGoal.mutateAsync(goal);
  };

  const addAssociation = (id: string) => {
    const assGoal = colGoals.find((x) => x.collectiveGoalId === id);
    goal.associations.push(assGoal);
    setChoosingGoal(false);
    updateGoal.mutateAsync(goal);
  };

  const setDeadline = (val: number) => {
    goal.deadline = val;
    updateGoal.mutateAsync(goal);
  };

  const updateProgress = (val) => {
    goal.progress = val;
    if (val >= goal.target) {
      goal.status = "achieved";
    } else if (
      goal.measureType !== "subgoals" &&
      (goal.status === "achieved" || goal.status === "notstarted")
    ) {
      goal.status = "inprogress";
    }
    updateGoal.mutateAsync(goal);
  };

  const createComment = () => {
    if (commentText.length > 2048) {
      return window.alert("Goal comments are capped at 2048 characters");
    }

    const goalComment: CreateGoalCommentCommand = {
      body: commentText,
      goalId: goal.id,
    };
    createGoalCommentMut.mutateAsync(goalComment);
    setCommentText("");
  };

  const deleteComment = (id: string) => {
    deleteCommentMutator.mutateAsync(id);
  };

  const saveGoal = (values: GoalDto) => {
    goal = {
      ...goal,
      deadline: values.deadline,
      purpose: values.purpose,
      target: values.target,
      type: values.type,
      measureType: values.measureType,
      canExceed: values.canExceed,
      progress: 0,
      fields: values.fields,
      status: "notstarted",
    };
    updateGoal.mutateAsync(goal);
    setEditingGoal(null);
  };

  const updateObjectiveProgress = (id: string, val: number) => {
    const objectives = goalState.objectives.map((obj) => {
      if (obj.id === id) {
        obj.progress = val as number;
        if (val >= obj.target) {
          obj.status = "achieved";
        } else if (obj.status === "achieved" || obj.status === "notstarted") {
          obj.status = "inprogress";
        }
      }
      return obj;
    });

    setGoalState({
      ...goalState,
      objectives: objectives,
    });
  };

  const isComment = (x: Activity): x is CommentDto => "body" in x;

  const isLoading = !isFetched || !goalFieldsFetched || !relationsFetched;

  const objFields = goalFields?.filter((x) => x.usage !== "goal") ?? [];

  const canEdit =
    goal?.userId === user.id ||
    relationships?.some((x) => x.to.id === userIdVal && x.type === "manager");

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : goal == null ? (
        <NotFound />
      ) : (
        <>
          {choosingGoal && (
            <AssociateGoalModal
              goals={applicableGoals()}
              onCancel={() => setChoosingGoal(false)}
              onSelect={addAssociation}
            />
          )}
          {editingObj != null && (
            <EditObjectiveModal
              value={editingObj === "new" ? null : editingObj}
              onClose={() => setEditingObj(null)}
              onSave={saveObj}
              fields={objFields}
              goalDeadline={goal.deadline}
            />
          )}
          {editingGoal != null && (
            <EditGoalModal
              value={editingGoal}
              onClose={() => setEditingGoal(null)}
              onSave={saveGoal}
              fields={goalFields?.filter((x) => x.usage !== "objective")}
            />
          )}

          <CompleteGoalModal
            isOpen={completingGoal}
            goalId={goal.id}
            onComplete={(result) => {
              completeGoal.mutateAsync({ id: goalId, outcome: result });
              setCompletingGoal(false);
            }}
            onCancel={() => setCompletingGoal(false)}
          />

          <Flex mb={2} justifyContent={"end"}>
            {goal.completedOn == null ? (
              <Button
                color="primary"
                onClick={() => setCompletingGoal(true)}
                icon={FaMedal}
              >
                Complete {terms.goal.asTitle()}
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  reopenGoalMut.mutateAsync(goal.id);
                }}
              >
                Reopen
              </Button>
            )}
          </Flex>
          <Flex flexWrap={["wrap", "wrap", "wrap", "nowrap"]}>
            <Flex
              alignItems={"flex-start"}
              mr={[0, 0, 0, 3]}
              mb={[3, 3, 3, 0]}
              width={[1, 1, 1, 7 / 12]}
            >
              <Panel width={"100%"}>
                <PanelHeader>
                  <Circle
                    color="primary"
                    icon={goal.type === "development" ? FaLeaf : FaBullseye}
                    mr={2}
                    size="medium"
                    title={
                      goal.type === "performance"
                        ? `Performance ${terms.goal.asTitle()}`
                        : `Development ${terms.goal.asTitle()}`
                    }
                  />
                  <Text fontWeight={600} fontSize={4}>
                    {terms.goal.asTitle()} Details
                  </Text>
                </PanelHeader>
                <Box p={4}>
                  <Flex alignItems={"center"} flexDirection={"row"}>
                    <Flex flexGrow={6}>
                      <Flex flexDirection={"column"}>
                        <Flex>
                          <>
                            <Text fontSize={4} color="primary" fontWeight={600}>
                              {goal.purpose}
                            </Text>
                            {canEdit && (
                              <IconButton
                                icon={FaPen}
                                onClick={() => setEditingGoal(goal)}
                                color="grey"
                                title={`Edit ${terms.goal.asBody()}`}
                                ml={1}
                                disabled={goal.completedOn !== null}
                              />
                            )}
                          </>
                        </Flex>
                        {goal.updatedOn != null && (
                          <Text fontSize={12} color="grey">
                            {goal.updatedOn != null &&
                              goal.completedOn == null &&
                              "Last status update: " +
                                formatDate(
                                  goal.updatedOn,
                                  "dd/MM/yyyy : HH:mm",
                                )}
                            {goal.completedOn != null &&
                              "Completed on: " +
                                formatDate(
                                  goal.completedOn,
                                  " dd/MM/yyyy : HH:mm",
                                )}
                          </Text>
                        )}
                        <Flex alignItems={"center"}>
                          <Text>Created on/Deadline: </Text>
                          <TimePeriod
                            start={goal.createdOn}
                            disabled={goal.completedOn != null || !canEdit}
                            value={goal.deadline}
                            onChange={setDeadline}
                          />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      alignItems={"center"}
                      justifyContent="end"
                      flexGrow={1}
                    >
                      <OptionSelect
                        disabled={goal.completedOn != null || !canEdit}
                        value={goal.status}
                        onChange={(val) =>
                          updateOverall(val as GoalReviewStatusOptions)
                        }
                        options={statusOptions}
                      />
                    </Flex>
                  </Flex>
                  <Rule />
                  <Text>Progress:</Text>
                  <Flex alignItems={"center"} mb={3}>
                    <Slider
                      value={progress}
                      onChange={setStateProgress}
                      onAfterChange={() => updateProgress(progress)}
                      min={0}
                      mark={goal.target}
                      disabled={
                        goal.completedOn !== null ||
                        goal.measureType === "subgoals"
                      }
                      max={
                        goal.canExceed !== null ? goal.canExceed : goal.target
                      }
                    />
                    <Flex ml={2} alignItems={"center"}>
                      <Input
                        width={"100px"}
                        ml={2}
                        disabled={
                          goal.completedOn !== null ||
                          goal.measureType === "subgoals"
                        }
                        type="number"
                        value={progress}
                        onChange={(val) => {
                          setStateProgress(+val.target.value as number);
                        }}
                        onBlur={(val) => updateProgress(progress)}
                        max={
                          goal.canExceed !== null ? goal.canExceed : goal.target
                        }
                      />
                      {goal.measureType === "percentage" && <Text>%</Text>}
                    </Flex>
                  </Flex>

                  {goal.fields.length > 0 && (
                    <>
                      <Text fontWeight={600} fontSize={3}>
                        Details
                      </Text>

                      <Flex flexDirection="column" p={2} pb={0}>
                        {goal.fields
                          .map((x) => ({
                            field: goalFields.find((f) => f.id === x.id),
                            value: x.value,
                          }))
                          .map((x, i, self) => (
                            <Box mb={i < self.length - 1 ? 2 : 0}>
                              <Text fontWeight={500} color="black" fontSize={3}>
                                {x.field.name}:
                              </Text>
                              <Text>{x.value || "<Not Given>"}</Text>
                            </Box>
                          ))}
                      </Flex>
                      <Rule />
                    </>
                  )}
                  <>
                    <Text fontWeight={600} fontSize={3}>
                      Associated {terms.goal.asPluralTitle()}
                    </Text>
                    {goal.associations.map((x) => (
                      <Flex mt={2} mb={2} alignItems="center">
                        <Circle icon={FaMedal} color="primary" mr={2} />
                        <Text style={{ flex: 1 }} fontWeight={500}>
                          {x.name}
                        </Text>
                        <Label mr={2} color="primary">
                          {x.hasConditions
                            ? "Shared"
                            : x.type === "org"
                              ? "Org"
                              : "Team"}
                        </Label>
                        <IconButton
                          icon={FaTrash}
                          color="danger"
                          onClick={() => removeAssociation(x.collectiveGoalId)}
                          disabled={goal.completedOn !== null}
                        />
                      </Flex>
                    ))}
                    {applicableGoals().length > 0 &&
                      goal.completedOn == null && (
                        <AddButton
                          disabled={!canEdit}
                          mb={3}
                          onClick={() => setChoosingGoal(true)}
                        >
                          {`Associate with Collective ${terms.goal.asTitle()}`}
                        </AddButton>
                      )}
                  </>
                  <Rule />
                  <Text fontWeight={600}>
                    {terms.objective.asPluralTitle()}
                  </Text>
                  <ScrollableContainer mb={2} maxHeight={700}>
                    <Flex flexDirection={"column"}>
                      {goalState?.objectives.map((x, i) => (
                        <>
                          <Flex mr={2} flexDirection={"row"}>
                            <Circle
                              icon={FaBullseye}
                              color="orange"
                              mt={2}
                              mr={2}
                            />
                            <Flex width={"100%"} flexDirection={"column"}>
                              <Flex mt={2} mb={2} flexDirection={"row"}>
                                <Flex alignItems={"center"} flexGrow={6}>
                                  <Flex flexDirection={"column"}>
                                    <Flex alignItems={"center"}>
                                      <Text fontWeight={500}>{x.purpose}</Text>
                                      {canEdit && (
                                        <IconButton
                                          icon={FaPen}
                                          onClick={() => setEditingObj(x)}
                                          color="grey"
                                          title={`Edit ${terms.goal.asBody()}`}
                                          ml={1}
                                          disabled={goal.completedOn !== null}
                                        />
                                      )}
                                    </Flex>

                                    <Text fontSize={12} color="grey">
                                      {x.updatedOn != null &&
                                        x.completedOn == null &&
                                        "Last status update: " +
                                          formatDate(
                                            x.updatedOn,
                                            "dd/MM/yyyy : HH:mm",
                                          )}
                                      {x.completedOn != null &&
                                        "Completed on: " +
                                          formatDate(
                                            x.completedOn,
                                            " dd/MM/yyyy : HH:mm",
                                          )}
                                    </Text>

                                    <Text fontSize={12} color="grey">
                                      {x.createdOn != null &&
                                        x.deadline != null &&
                                        "Created/Deadline: " +
                                          formatDate(
                                            x.createdOn,
                                            "dd/MM/yyyy",
                                          ) +
                                          " - " +
                                          formatDate(x.deadline, "dd/MM/yyyy")}
                                    </Text>
                                  </Flex>
                                </Flex>

                                <Flex
                                  alignItems={"center"}
                                  justifyContent={"end"}
                                >
                                  <OptionSelect
                                    disabled={
                                      goal.completedOn !== null || !canEdit
                                    }
                                    value={x.status}
                                    onChange={(val) =>
                                      setObjectiveStatus(
                                        val as GoalReviewStatusOptions,
                                        x.id,
                                      )
                                    }
                                    options={statusOptions}
                                  />
                                  <IconButton
                                    disabled={goal.completedOn !== null}
                                    icon={FaTrash}
                                    color="danger"
                                    onClick={() => deleteGoal(x.id)}
                                  />
                                </Flex>
                              </Flex>
                              <Flex alignItems={"center"} mb={3}>
                                <Slider
                                  value={x.progress}
                                  onChange={(val) => {
                                    updateObjectiveProgress(x.id, val);
                                  }}
                                  min={0}
                                  mark={x.target}
                                  disabled={goal.completedOn !== null}
                                  max={
                                    x.canExceed !== null
                                      ? x.canExceed
                                      : x.target
                                  }
                                  onAfterChange={() =>
                                    updateGoal.mutateAsync(goal)
                                  }
                                />
                                <Flex ml={2} alignItems={"center"}>
                                  <Input
                                    width={"100px"}
                                    ml={2}
                                    disabled={goal.completedOn !== null}
                                    type="number"
                                    value={x.progress}
                                    onChange={(val) => {
                                      updateObjectiveProgress(x.id, +val);
                                    }}
                                    onBlur={(val) =>
                                      updateGoal.mutateAsync(goal)
                                    }
                                    max={
                                      x.canExceed !== null
                                        ? x.canExceed
                                        : x.target
                                    }
                                  />
                                  {goal.measureType === "percentage" && (
                                    <Text>%</Text>
                                  )}
                                </Flex>
                              </Flex>

                              {x.fields.length > 0 && (
                                <>
                                  <Text fontWeight={600} fontSize={3}>
                                    Details
                                  </Text>

                                  <Flex flexDirection="column" p={2} pb={0}>
                                    {x.fields
                                      .map((x) => ({
                                        field: goalFields.find(
                                          (f) => f.id === x.id,
                                        ),
                                        value: x.value,
                                      }))
                                      .map((x) => (
                                        <Box mb={2}>
                                          <Text
                                            fontWeight={500}
                                            color="black"
                                            fontSize={3}
                                          >
                                            {x.field.name}:
                                          </Text>
                                          <Text>
                                            {x.value || "<Not Given>"}
                                          </Text>
                                        </Box>
                                      ))}
                                  </Flex>
                                  <Rule />
                                </>
                              )}
                            </Flex>
                          </Flex>
                          {i !== goal.objectives.length && <Rule />}
                        </>
                      ))}
                    </Flex>
                  </ScrollableContainer>
                  {goal.completedOn == null && (
                    <AddButton
                      disabled={!canEdit}
                      mb={3}
                      onClick={() => setEditingObj("new")}
                    >
                      {`Create new ${terms.objective.asBody()}`}
                    </AddButton>
                  )}
                </Box>
              </Panel>
            </Flex>
            <Flex alignItems={"flex-start"} width={[1, 1, 1, 5 / 12]}>
              <Panel width={"100%"}>
                <PanelHeader>
                  <Circle color="primary" icon={FaEdit} mr={2} size="small" />
                  <Text fontWeight={500} fontSize={4}>
                    Review
                  </Text>
                </PanelHeader>
                <Box p={4}>
                  {canEdit && (
                    <>
                      <RichTextEditor
                        value={commentText}
                        onChange={setCommentText}
                        height={300}
                      />
                      <Flex mb={2} justifyContent={"end"}>
                        <Button
                          mt={2}
                          color="primary"
                          icon={FaPaperPlane}
                          onClick={createComment}
                        >
                          Add Comment
                        </Button>
                      </Flex>
                    </>
                  )}
                  <Flex mb={2} alignItems={"center"}>
                    <Text mr={2}>Activity Filter</Text>
                    <Select
                      width={300}
                      options={options}
                      value={activityFilter}
                      onChange={(event) =>
                        setActivityFilter(event.target.value)
                      }
                    />
                  </Flex>
                  <CommentContainer>
                    {activity != null && activity.length > 0 ? (
                      activity.map((item, i) => {
                        if (
                          isComment(item) &&
                          (activityFilter === "comments" ||
                            activityFilter === "all")
                        ) {
                          return (
                            <Comment
                              deleteComment={(id) => deleteComment(id)}
                              comment={item as CommentDto}
                            />
                          );
                        } else if (
                          !isComment(item) &&
                          (activityFilter === "updates" ||
                            activityFilter === "all")
                        ) {
                          return (
                            <GoalStatusUpdate
                              statusUpdate={item as GoalStatusHistoryDto}
                            />
                          );
                        }
                        return <></>;
                      })
                    ) : (
                      <Flex justifyContent={"center"}>No activity to show</Flex>
                    )}
                  </CommentContainer>
                </Box>
              </Panel>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};
