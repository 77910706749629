import {
  GoalAssociationDto,
  GoalDto,
  GoalFieldDto,
  ObjectiveDto,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Circle,
  ControlLabel,
  Flex,
  FormInput,
  FormSelect,
  IconButton,
  Label,
  Loader,
  Modal,
  PanelInset,
  Rule,
  Text,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { FormDateSelect } from "components/FormDateSelect";
import { fromUnixTime, getUnixTime } from "date-fns";
import {
  useApplicableCollectiveGoals,
  useGoalFields,
} from "queries/performance";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  FaBullseye,
  FaChevronDown,
  FaChevronRight,
  FaMedal,
  FaPen,
  FaQuestionCircle,
  FaTrash,
} from "react-icons/fa";
import styled from "styled-components";

function ObjectiveListItem({
  obj,
  onEdit,
  onRemove,
}: {
  obj: ObjectiveDto;
  onEdit: () => void;
  onRemove: () => void;
}) {
  return (
    <Flex
      p={2}
      justifyContent="space-between"
      border={1}
      borderRadius={1}
      mb={2}
    >
      <Flex alignItems="center">
        <Circle icon={FaBullseye} color="orange" />
        <Text fontWeight={500} ml={2}>
          {obj.purpose}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <IconButton icon={FaPen} color="body" onClick={onEdit} />
        <IconButton icon={FaTrash} color="danger" onClick={onRemove} ml={1} />
      </Flex>
    </Flex>
  );
}

type GoalForm = GoalDto & {
  [id: string]: any;
};

type GoalFormProps = {
  goalFields: GoalFieldDto[];
  goalName?: string;
  isObjective: boolean;
  maxDeadline?: Date;
};

function GoalFormFields({
  isObjective,
  goalFields,
  goalName = "goal",
  maxDeadline,
}: GoalFormProps) {
  const { register, errors, control } = useFormContext<GoalForm>();
  const [help, setHelp] = useState<string[]>([]);
  const [measureType, setMeasureType] = useState("");
  const terms = useGoalTerminology();

  const toggleHelp = (id: string) => {
    if (help.includes(id)) {
      setHelp(help.filter((x) => x !== id));
    } else {
      setHelp([...help, id]);
    }
  };

  return (
    <>
      <input ref={register} name="id" type="hidden" />
      <FormInput
        ref={register({ required: "Must be completed" })}
        required
        label={`What is your ${goalName}?`}
        name="purpose"
        error={errors.purpose}
      />
      {!isObjective && (
        <FormSelect
          error={errors.type}
          name="type"
          ref={register({ required: "Must be completed" })}
          required
          label={`What type of ${goalName} is it?`}
          options={[
            { label: "Performance", value: "performance" },
            { label: "Development", value: "development" },
          ]}
        />
      )}
      <Controller
        control={control}
        rules={{ required: "Must be completed", valueAsNumber: true }}
        name="deadline"
        render={({ value, onChange }) => (
          <FormDateSelect
            label={`When would you like to achieve this ${goalName} by?`}
            error={errors.deadline}
            value={value == null ? null : fromUnixTime(value)}
            onChange={(value) => onChange(getUnixTime(value))}
            minDate={new Date()}
            required
          />
        )}
      />
      <FormSelect
        error={errors.measureType}
        name="measureType"
        onChange={(val) => setMeasureType(val.target.value)}
        ref={register({ required: "Must be completed" })}
        required
        label={`How will you measure your ${goalName}?`}
        options={
          !isObjective
            ? [
                { label: "Number", value: "number" },
                { label: "Percentage", value: "percentage" },
                {
                  label: `Calculated from ${terms.objective.asPluralBody()}`,
                  value: "subgoals",
                },
              ]
            : [
                { label: "Number", value: "number" },
                { label: "Percentage", value: "percentage" },
              ]
        }
      />
      {measureType !== "subgoals" && (
        <>
          <FormInput
            mr={2}
            width={"100%"}
            ref={register({
              required: "Must be completed",
              valueAsNumber: true,
            })}
            required
            label={`Target`}
            name="target"
            error={errors.target}
            type="number"
          />

          <FormInput
            ref={register({ valueAsNumber: true })}
            label={`What is the max value for the ${goalName}?`}
            name="canExceed"
            error={errors.canExceed}
          />
        </>
      )}

      {goalFields.map((x) => (
        <Box mb={2}>
          <Flex alignItems="flex-start" mb={1}>
            <ControlLabel mb={0} required={x.required}>
              {x.name}
            </ControlLabel>
            {x.prompt !== "" && (
              <IconButton
                ml={"3px"}
                icon={FaQuestionCircle}
                color="body"
                onClick={() => toggleHelp(x.id)}
              />
            )}
          </Flex>
          <FormInput
            ref={register({
              required: x.required ? "Must be completed" : false,
            })}
            required={x.required}
            label={null}
            name={x.id}
            error={errors[x.id]}
          />
          {help.includes(x.id) && (
            <PanelInset p={2} pb={1}>
              <Text fontWeight={500} mb={1} lineHeight={1.25} color="grey2">
                Help
              </Text>
              {x.prompt.split("\n").map((x, i) => (
                <Text fontSize={2} key={i} mb={1} lineHeight={1.25}>
                  {x}
                </Text>
              ))}
            </PanelInset>
          )}
        </Box>
      ))}
    </>
  );
}

type EditObjectiveModalProps = {
  value: ObjectiveDto | null;
  onClose: () => void;
  onSave: (vals: Partial<ObjectiveDto>) => void;
  fields: GoalFieldDto[];
  goalDeadline?: number;
};

type EditGoalModalProps = {
  value: GoalDto | null;
  onClose: () => void;
  onSave: (vals: Partial<GoalDto>) => void;
  fields: GoalFieldDto[];
};

export function EditObjectiveModal({
  value,
  onClose,
  onSave,
  goalDeadline,
  fields,
}: EditObjectiveModalProps) {
  const methods = useForm<GoalForm>();
  const { reset, handleSubmit } = methods;
  const { objective } = useGoalTerminology();

  const onSubmit = (values: GoalForm) => {
    if (values.canExceed !== null && values.canExceed < values.target) {
      alert("Max value cannot be less than the target value");
      return;
    }
    if (goalDeadline != null && values.deadline > goalDeadline) {
      alert("Objective deadline cannot be after goal deadline");
      return;
    }
    onSave({
      id: values.id,
      purpose: values.purpose,
      deadline: values.deadline,
      completedOn: values.completedOn,
      status: value != null ? value.status : "notstarted",
      fields: fields.map((x) => ({
        id: x.id,
        name: x.name,
        value: values[x.id],
      })),
      measureType: values.measureType,
      target: values.target,
      canExceed: values.canExceed,
      progress: 0,
    });
  };

  useEffect(() => {
    if (value != null) {
      const spec = {
        ...value,
        ...Object.fromEntries(value.fields.map((x) => [x.id, x.value])),
      };
      reset(spec);
    }
  }, [value, reset]);

  return (
    <Modal width={600} onClose={onClose}>
      <Box p={5}>
        <Text fontSize={5} fontWeight={600} mb={4}>
          Edit {objective.asTitle()}
        </Text>
        {value !== null && (
          <Text mb={2} color="danger">
            Please note, editing an existing {objective.asBody()} will set the
            progress to 0 and reset the status
          </Text>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <GoalFormFields
              isObjective={true}
              goalFields={fields}
              goalName={objective.asBody()}
              maxDeadline={fromUnixTime(goalDeadline)}
            />
            <Rule />
            <Flex>
              <Button type="submit" color="primary">
                Save {objective.asTitle()}
              </Button>
              <Button ml={2} type="button" onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}

export function EditGoalModal({
  value,
  onClose,
  onSave,
  fields,
}: EditGoalModalProps) {
  const methods = useForm<GoalForm>();
  const { reset, handleSubmit } = methods;
  const { goal } = useGoalTerminology();

  const onSubmit = (values: GoalForm) => {
    if (values.canExceed !== null && values.canExceed < values.target) {
      alert("Max value cannot be less than the target value");
      return;
    }
    onSave({
      id: values.id,
      purpose: values.purpose,
      deadline: values.deadline,
      completedOn: values.completedOn,
      status: value != null ? value.status : "notstarted",
      fields: fields.map((x) => ({
        id: x.id,
        name: x.name,
        value: values[x.id],
      })),
      measureType: values.measureType,
      type: values.type,
      target: values.target,
      canExceed: values.canExceed,
      progress: 0,
    });
  };

  useEffect(() => {
    if (value !== null) {
      const spec = {
        ...value,
        ...Object.fromEntries(value.fields.map((x) => [x.id, x.value])),
      };
      reset(spec);
    }
  }, [value, reset]);

  return (
    <Modal width={600} onClose={onClose}>
      <Box p={5}>
        <Text fontSize={5} fontWeight={600} mb={4}>
          Edit {goal.asTitle()}
        </Text>
        {value !== null && (
          <Text mb={2} color="danger">
            Please note, editing an existing {goal.asBody()} will set the
            progress to 0 and reset the status
          </Text>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <GoalFormFields
              isObjective={false}
              goalFields={fields}
              goalName={goal.asBody()}
            />
            <Rule />
            <Flex>
              <Button type="submit" color="primary">
                Save {goal.asTitle()}
              </Button>
              <Button ml={2} type="button" onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}

type AssociateGoalModalProps = {
  goals: GoalAssociationDto[];
  onSelect: (goalId: string) => void;
  onCancel: () => void;
};

const GoalButton = styled(Flex)`
  width: 100%;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey4};
  }
`;

function AssociateGoalModal({
  goals,
  onSelect,
  onCancel,
}: AssociateGoalModalProps) {
  const [selectedGoal, setselectedGoal] = useState<string>("");

  useEffect(() => {
    if (goals != null && goals.length > 0) {
      setselectedGoal(goals[0].collectiveGoalId);
    }
  }, [goals]);
  return (
    <Modal onClose={onCancel} width={400}>
      <Flex p={2} flexDirection="column">
        {goals.map((x) => (
          <GoalButton
            tabIndex={0}
            p={2}
            border={1}
            borderRadius={3}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                x.collectiveGoalId === selectedGoal
                  ? setselectedGoal(null)
                  : setselectedGoal(x.collectiveGoalId);
              }
            }}
            mb={1}
            onClick={() => {
              x.collectiveGoalId === selectedGoal
                ? setselectedGoal(null)
                : setselectedGoal(x.collectiveGoalId);
            }}
            flexDirection="column"
          >
            <Flex alignItems="center" justifyContent="center">
              {selectedGoal === x.collectiveGoalId ? (
                <FaChevronDown />
              ) : (
                <FaChevronRight />
              )}
              <Text ml={1} fontWeight={500} style={{ flex: 1 }} mr={2}>
                {x.name}
              </Text>
              <Label color="primary">
                {x.hasConditions ? "Shared" : x.type === "org" ? "Org" : "Team"}
              </Label>
            </Flex>
            {selectedGoal === x.collectiveGoalId && (
              <>
                <Text mt={2} mb={2}>
                  {x.description}
                </Text>

                <Flex justifyContent="center">
                  <Button
                    color="primary"
                    onClick={() => onSelect(x.collectiveGoalId)}
                  >
                    Add
                  </Button>
                </Flex>
              </>
            )}
          </GoalButton>
        ))}
        <Button mt={1} onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}

export type GoalEditorProps = {
  goal: GoalDto | null;
  onSave: (values: Partial<GoalDto>) => void;
  onCancel: () => void;
  loading: boolean;
};

export function GoalEditor({
  goal,
  onSave,
  onCancel,
  loading,
}: GoalEditorProps) {
  const { data: goalFields, isFetched } = useGoalFields();

  const methods = useForm<GoalDto>();
  const { reset, handleSubmit, watch, getValues } = methods;
  const [obj, setObj] = useState<number | "new" | null>(null);
  const [objectives, setObjectives] = useState<ObjectiveDto[]>([]);
  const [associations, setAssociations] = useState<GoalAssociationDto[]>([]);
  const [choosingGoal, setChoosingGoal] = useState(false);
  const { orgGoals, teamGoals } = useApplicableCollectiveGoals();
  const terms = useGoalTerminology();

  const colGoals = orgGoals
    .map<GoalAssociationDto>((x) => ({
      name: x.name,
      description: x.description,
      type: "org",
      collectiveGoalId: x.id,
      lineManagerId: null,
      hasConditions: x.conditions.length > 0,
    }))
    .concat(
      teamGoals.map<GoalAssociationDto>((x) => ({
        name: x.name,
        description: x.description,
        type: "team",
        collectiveGoalId: x.id,
        lineManagerId: x.lineManagerId,
        hasConditions: false,
      })),
    );

  const applicableGoals = colGoals.filter(
    (x) => !associations.some((a) => a.collectiveGoalId === x.collectiveGoalId),
  );

  useEffect(() => {
    if (goal != null) {
      const spec = {
        ...goal,
        ...Object.fromEntries(goal.fields.map((x) => [x.id, x.value])),
      };
      setObjectives(goal.objectives);
      setAssociations(goal.associations);
      reset(spec);
    }
  }, [goal, reset]);

  const goalDeadline = watch("deadline", NaN);

  const onSubmit = (values: GoalForm) => {
    const spec: Partial<GoalDto> = {
      id: values.id,
      purpose: values.purpose,
      deadline: values.deadline,
      completedOn: values.completedOn,
      associations: associations,
      status: values.status != null ? values.status : "notstarted",
      fields: goalFields
        .filter((x) => x.usage !== "objective")
        .map((x) => ({
          id: x.id,
          name: x.name,
          value: values[x.id],
        })),
      objectives,
      type: values.type,
      measureType: values.measureType,
      target: values.target,
      canExceed: values.canExceed,
      progress: 0,
    };
    if (values.canExceed !== null && values.canExceed < values.target) {
      alert("Max value cannot be less than the target value");
      return;
    }
    onSave(spec);
  };

  const addObjective = () => {
    setObj("new");
  };

  const addAssociation = (id: string) => {
    const goal = colGoals.find((x) => x.collectiveGoalId === id);
    setAssociations([...associations, goal]);
    setChoosingGoal(false);
  };

  const removeAssociation = (goal: GoalAssociationDto) => {
    setAssociations(
      associations.filter((x) => x.collectiveGoalId !== goal.collectiveGoalId),
    );
  };

  const onSaveObjective = (vals: ObjectiveDto) => {
    if (obj === "new") {
      setObjectives([...objectives, vals]);
    } else {
      objectives[obj] = vals;
      setObjectives([...objectives]);
    }
    setObj(null);
  };

  if (!isFetched) {
    return <Loader />;
  }

  return (
    <Box style={{ position: "relative" }}>
      {choosingGoal && (
        <AssociateGoalModal
          goals={applicableGoals}
          onCancel={() => setChoosingGoal(false)}
          onSelect={addAssociation}
        />
      )}
      {obj != null && (
        <EditObjectiveModal
          value={obj === "new" ? null : objectives[obj]}
          goalDeadline={getValues().deadline}
          onClose={() => setObj(null)}
          onSave={onSaveObjective}
          fields={goalFields.filter((x) => x.usage !== "goal")}
        />
      )}

      {loading && <Loader overlay />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <GoalFormFields
              isObjective={false}
              goalFields={
                goalFields?.filter((x) => x.usage !== "objective") ?? []
              }
              goalName={terms.goal.asBody()}
            />
          </Box>
          {(applicableGoals.length > 0 || associations.length > 0) && (
            <Box mb={3}>
              <Text fontSize={4} fontWeight={500} mb={3}>
                Collective {terms.goal.asTitle()} Associations
              </Text>
              {associations.map((x) => (
                <Flex
                  alignItems="center"
                  border={1}
                  borderRadius={3}
                  p={2}
                  mb={1}
                >
                  <Circle icon={FaMedal} color="primary" mr={2} />
                  <Text fontWeight={500} style={{ flex: 1 }}>
                    {x.name}
                  </Text>
                  <IconButton
                    icon={FaTrash}
                    color="danger"
                    onClick={() => removeAssociation(x)}
                  />
                </Flex>
              ))}
              {applicableGoals.length > 0 && (
                <AddButton mb={3} onClick={() => setChoosingGoal(true)}>
                  {`Associate with Collective ${terms.goal.asTitle()}`}
                </AddButton>
              )}
            </Box>
          )}
          <Text fontSize={4} fontWeight={500} mb={3}>
            {terms.objective.asPluralTitle()}
          </Text>
          {objectives.map((x, i) => (
            <ObjectiveListItem
              obj={x}
              onEdit={() => setObj(i)}
              onRemove={() => setObjectives(objectives.filter((o) => o !== x))}
            />
          ))}
          <AddButton
            disabled={isNaN(goalDeadline)}
            mb={3}
            onClick={addObjective}
          >
            {`Add ${terms.objective.asTitle()}`}
          </AddButton>
          <Flex style={{ gap: 6 }}>
            <Button type="button" onClick={onCancel} flex={1}>
              Cancel
            </Button>
            <Button type="submit" color="primary" flex={1}>
              Save
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
}
