import { UserMeasuresDto } from "@coaching-culture/types";
import {
  Box,
  Flex,
  IconButton,
  IconToast,
  IconWrap,
  Loader,
  OptionSelect,
  Panel,
  PanelHeader,
  SortableTable,
  SortableTableColumn,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { UserFlag } from "components/UserFlag";
import { format, fromUnixTime } from "date-fns";
import { max, min, range } from "lodash";
import { mix } from "polished";
import {
  useMeasures,
  useUpdateUserMeasures,
  useUserMeasures,
} from "queries/measures";
import { useCallback, useMemo, useState } from "react";
import { FaChartLine, FaCheck, FaCheckCircle, FaUsers } from "react-icons/fa";
import { useTheme } from "styled-components";
import { MeasuresComparison } from "./MeasuresComparison";
import { MeasuresTrendsModal } from "./MeasuresTrendsModal";

const makeRamp = (colors: string[], count: number) =>
  range(count)
    .map((x) => {
      const p = x / (count - 1);
      const i = Math.floor(p * (colors.length - 1));
      const i2 = min([i + 1, colors.length - 1]);
      const p2 = p * (colors.length - 1) - i;
      const c = mix(p2, colors[i2], colors[i]);
      return c;
    })
    .reverse();

const MeasuresTable = () => {
  const [user] = useUser();
  const [showTrends, setShowTrends] = useState<string | null>(null);
  const measures = useMeasures();
  const userMeasures = useUserMeasures(user.id);
  const updateMeasures = useUpdateUserMeasures(user.id);
  const theme = useTheme();
  const toast = useToast();

  const loading = !measures.isSuccess || !userMeasures.isSuccess;

  const changeMeasure = useCallback(
    (relationshipId: string, measureId: string, optionId: string) => {
      if (optionId === "") {
        return;
      }

      updateMeasures.mutateAsync({
        relationshipId,
        measureId,
        optionId,
      });
    },
    [updateMeasures],
  );

  const confirmMeasures = useCallback(
    async (relationshipId: string) => {
      const um = userMeasures.data!.find(
        (x) => x.relationshipId === relationshipId,
      );

      for (const m of um.measures) {
        await updateMeasures.mutateAsync({
          relationshipId,
          measureId: m.id,
          optionId: m.value,
        });
      }

      toast({
        content: (
          <IconToast
            icon={FaCheckCircle}
            iconColor="primary"
            text="Measures Confirmed"
          />
        ),
      });
    },
    [updateMeasures, userMeasures.data, toast],
  );

  const cols = useMemo<SortableTableColumn<UserMeasuresDto>[]>(() => {
    if (!measures.data) {
      return [];
    }

    const cols: SortableTableColumn<UserMeasuresDto>[] = [
      {
        name: "teamOrder",
        label: "#",
        sortFormat: (x) => x.teamOrder,
        headingProps: {
          textAlign: "center",
          style: {
            position: "sticky",
            left: 0,
            zIndex: 2,
            backgroundColor: "white",
            minWidth: 45,
          },
        },
        props: {
          textAlign: "center",
          fontWeight: 600,
          color: "grey2",
          style: {
            position: "sticky",
            left: 0,
            zIndex: 2,
            backgroundColor: "white",
            width: 45,
          },
        },
      },
      {
        name: "name",
        label: "Name",
        format: (x) => <UserFlag user={x.user} />,
        sortFormat: (x) => x.user.name,
        headingProps: {
          style: {
            position: "sticky",
            left: 44,
            zIndex: 2,
            backgroundColor: "white",
          },
        },
        props: {
          style: {
            position: "sticky",
            left: 44,
            zIndex: 2,
            backgroundColor: "white",
          },
        },
      },
      ...measures.data.map((x) => ({
        name: x.name,
        label: x.name,
        format: (item: UserMeasuresDto) => {
          const val = item.measures.find((i) => i.id === x.id)?.value ?? "";

          const colorRamp = makeRamp(
            [theme.colors.danger, theme.colors.warning, theme.colors.positive],
            x.options.length,
          );

          const options = x.options.map((x, i) => ({
            value: x.id,
            label: x.name,
            color: colorRamp[i],
          }));

          if (val === "") {
            options.unshift({
              value: "",
              label: "Unset",
              color: "grey2",
            });
          }

          return (
            <OptionSelect
              value={val}
              options={options}
              onChange={(val) => changeMeasure(item.relationshipId, x.id, val)}
            />
          );
        },
        sortFormat: (item: UserMeasuresDto) => {
          const val = item.measures.find((i) => i.id === x.id)?.value ?? "";

          return x.options.findIndex((x) => x.id === val);
        },
      })),
      {
        name: "lastUpdated",
        label: "Last Updated",
        format: (x) => {
          const val = max(x.measures.map((x) => x.lastSet));
          if (val == null) {
            return "Never";
          } else {
            return (
              <Text fontSize={2}>
                {format(fromUnixTime(val), "do MMM yyyy")}
              </Text>
            );
          }
        },
      },
      {
        name: "actions",
        label: "",
        format: (x) => (
          <Flex style={{ gap: 6 }}>
            <IconButton
              icon={FaChartLine}
              onClick={() => setShowTrends(x.relationshipId)}
              color="primary"
              title="View Trends"
            />
            <IconButton
              icon={FaCheck}
              onClick={() => confirmMeasures(x.relationshipId)}
              color="primary"
              title="Confirm Current Values"
            />
          </Flex>
        ),
      },
    ];

    return cols;
  }, [
    measures.data,
    theme.colors.danger,
    theme.colors.warning,
    theme.colors.positive,
    changeMeasure,
    confirmMeasures,
  ]);

  return (
    <>
      {showTrends && (
        <MeasuresTrendsModal
          relationshipId={showTrends}
          onClose={() => setShowTrends(null)}
        />
      )}
      <Panel>
        <PanelHeader alignItems={"center"}>
          <IconWrap icon={FaUsers} color="primary" size="2em" />
          <Text fontWeight={600} ml={3}>
            Your Direct Reports
          </Text>
        </PanelHeader>
        {loading ? (
          <Loader />
        ) : (
          <SortableTable columns={cols} data={userMeasures.data} />
        )}
      </Panel>
    </>
  );
};

export const MeasuresPane = () => {
  return (
    <>
      <MeasuresComparison />
      <MeasuresTable />
      {/* This is just to push down the page so that the selects are always visible */}
      <Box mb={"200px"} />
    </>
  );
};
