import {
  MeasureDto,
  UserDisplay,
  UserMeasuresDto,
} from "@coaching-culture/types";
import {
  Box,
  Circle,
  Flex,
  Loader,
  Modal,
  PanelHeader,
  PopOver,
  Text,
} from "@coaching-culture/ui";
import { UserFlag } from "components/UserFlag";
import { useState } from "react";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    height: 30px;
    text-align: center;
  }

  th:first-child {
    text-align: right;
    width: 0;
    white-space: nowrap;
    padding-right: 12px;
  }

  td {
    min-width: 20px;
    height: 60px;
    border: 1px solid ${(props) => props.theme.colors.grey3};

    &:nth-child(2) {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  tr:first-child td {
    border-top: 0;
  }

  tr.prebottom {
    th,
    td {
      border-bottom: 0;
      height: 20px;
    }
  }

  tr.bottomaxis th {
    color: ${(props) => props.theme.colors.grey2};
  }
`;

const YAxis = styled.p`
  position: absolute;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey2};
  transform: translateX(-50%) rotate(-90deg);
  left: -20px;
  z-index: 1000;
  top: calc(50% - 50px);
`;

type UserListModalProps = {
  users: UserDisplay[];
  onClose: () => void;
};

const UserListModal = ({ users, onClose }: UserListModalProps) => (
  <Modal showCloseButton onClose={onClose} width={400}>
    <PanelHeader>
      <Text fontWeight={600}>Users</Text>
    </PanelHeader>
    <Flex p={2} flexDirection="column" style={{ gap: 12 }}>
      {users.map((x) => (
        <UserFlag user={x} key={x.id} />
      ))}
    </Flex>
  </Modal>
);

export type MeasuresTableProps = {
  comparison: [string, string];
  measures: MeasureDto[];
  userMeasures: UserMeasuresDto[];
};

export const MeasuresTable = ({
  comparison,
  measures,
  userMeasures,
}: MeasuresTableProps) => {
  const [showUserList, setShowUserList] = useState<UserDisplay[] | null>(null);
  const [left, bottom] = comparison.map((x) =>
    measures.find((m) => m.id === x)
  );

  if (left == null || bottom == null) {
    return <Loader />;
  }

  const bottomOptions = [...bottom.options].reverse();

  const matrix = left.options.map((l) =>
    bottomOptions.map((b) =>
      userMeasures.filter(
        (x) =>
          x.measures.some((m) => m.value === l.id) &&
          x.measures.some((m) => m.value === b.id)
      )
    )
  );

  const maxCount = Math.max(1, ...matrix.flat().map((x) => x.length));

  const getSize = (value: number) => {
    return 24 + (value / maxCount) * 18;
  };

  const MAX_USER_COUNT = 8;

  return (
    <>
      {showUserList && (
        <UserListModal
          users={showUserList}
          onClose={() => setShowUserList(null)}
        />
      )}
      <Box style={{ position: "relative" }} ml={5}>
        <YAxis>{left.name}</YAxis>
        <Table>
          <tbody>
            {left.options.map((l, i) => (
              <tr key={l.id}>
                <th>{l.name}</th>
                <td />
                {bottomOptions.map((b, j) => (
                  <td key={b.id} width={(1 / bottomOptions.length) * 100 + "%"}>
                    {matrix[i][j].length > 0 ? (
                      <Flex alignItems="center" justifyContent="center">
                        <Circle
                          size={getSize(matrix[i][j].length)}
                          color="primary"
                          id={`${l.id}-${b.id}`}
                          onClick={() =>
                            setShowUserList(matrix[i][j].map((x) => x.user))
                          }
                        >
                          <Text fontWeight={600}>{matrix[i][j].length}</Text>
                        </Circle>
                        <PopOver
                          target={`${l.id}-${b.id}`}
                          render={() => (
                            <Flex style={{ gap: 6 }} flexDirection={"column"}>
                              {matrix[i][j]
                                .slice(0, MAX_USER_COUNT)
                                .map((x) => (
                                  <UserFlag user={x.user} key={x.user.id} />
                                ))}
                              {matrix[i][j].length > MAX_USER_COUNT && (
                                <Text
                                  fontWeight={600}
                                  color="grey"
                                  textAlign={"center"}
                                >
                                  +{matrix[i][j].length - MAX_USER_COUNT} More
                                </Text>
                              )}
                            </Flex>
                          )}
                        />
                      </Flex>
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="prebottom">
              <th />
              <th />
              {bottomOptions.map((_, i) => (
                <td key={i} />
              ))}
            </tr>
            <tr className="bottom">
              <th />
              <th />
              {bottomOptions.map((x) => (
                <th key={x.id}>{x.name}</th>
              ))}
            </tr>
            <tr className="bottomaxis">
              <th />
              <th />
              <th colSpan={bottomOptions.length}>{bottom.name}</th>
            </tr>
          </tbody>
        </Table>
      </Box>
    </>
  );
};
