import { clamp } from "lodash";
import React, { Children, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div``;

export type CarouselItemProps = {};

export const CarouselItem = styled.div<CarouselItemProps>``;

export type CarouselProps = React.PropsWithChildren<{}>;

export function Carousel({ children }: CarouselProps) {
  const [active, setActive] = useState<number>(0);
  const childCount = Children.toArray(children).length;

  useEffect(() => {
    setActive(clamp(active, 0, childCount - 1));
  }, [childCount]);

  return <Container>{children}</Container>;
}
