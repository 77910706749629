import {
  useCompleteLearnCastAttempt,
  useLatestLearnCastAttempt,
  useLearnCast,
  useRateContent,
  useStartNewLearnCastAttempt,
} from "@coaching-culture/api-client";
import {
  LearnCastAttemptDto,
  LearnCastDto,
  RateContentSpec,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormTextArea,
  IconButton,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import { useOrg } from "auth/OrgProvider";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import ScormPlayer from "components/ScormPlayer";
import StarRatingInput from "components/StarRatingInput";
import { MindsetWelcomeSplash } from "pages/Mindset/MindsetWelcomeSplash";
import { useEffect, useRef, useState } from "react";
import {
  FaChevronRight,
  FaRegWindowClose,
  FaRegWindowMaximize,
} from "react-icons/fa";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { useMeasure } from "react-use";
import styled, { useTheme } from "styled-components";
import ProgressTimeline from "../Mindset/ProgressTimeline";

const ModuleImage = styled.img`
  margin-right: 12px;
  width: 80px;
`;

const ContentHeader = ({
  stage,
  module,
}: {
  stage: string;
  module: LearnCastDto;
}) => (
  <Flex alignItems="center" mb={4}>
    <ModuleImage src={process.env.REACT_APP_STATIC_ROOT + "/" + module.icon} />
    <Text>
      <strong>{module.name}</strong>
      <span> | {stage}</span>
    </Text>
  </Flex>
);

const RedirectBasedOnAttempt = ({
  attempt,
  slug,
}: {
  attempt: LearnCastAttemptDto;
  slug: string;
}) => {
  if (attempt == null) {
    return <Redirect to={`/solutions/learncast/modules/${slug}/welcome`} />;
  } else if (attempt.programmeCompletedOn == null) {
    return <Redirect to={`/solutions/learncast/modules/${slug}/programme`} />;
  } else {
    return <Redirect to={`/solutions/learncast/modules/${slug}/rate`} />;
  }
};

type Params = {
  slug: string;
};

export const LearnCastPlayer = () => {
  const match = useRouteMatch<Params>();
  const location = useLocation();
  const history = useHistory();
  const slug = match.params.slug;

  const [ref, { width }] = useMeasure();

  const container = useRef(null);

  const theme = useTheme();
  const module = useLearnCast(match.params.slug);
  const attempt = useLatestLearnCastAttempt(match.params.slug);
  const startNewAttemptMut = useStartNewLearnCastAttempt();
  const completeAttemptMut = useCompleteLearnCastAttempt();
  const rateContent = useRateContent();
  const [rating, setRating] = useState<number | null>(null);
  const [feedback1, setFeedback1] = useState<string>("");
  const [feedback2, setFeedback2] = useState<string>("");
  const [feedback3, setFeedback3] = useState<string>("");
  const [fullscreen, setFullScreen] = useState(false);
  const [org] = useOrg();

  useEffect(() => {
    const handleFullscreenEvent = () => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenEvent);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenEvent);
    };
  }, []);

  const startNewAttempt = async () => {
    await startNewAttemptMut.mutateAsync(slug);
    history.push(`/solutions/learncast/modules/${slug}/programme`);
  };

  const lessonComplete = async () => {
    await completeAttemptMut.mutateAsync(slug);
    if (org.collectRatings) {
      history.push(`/solutions/learncast/modules/${slug}/rate`);
    } else {
      history.push(`/solutions/learncast/modules/${slug}/complete`);
    }
  };

  const onFullScreenClick = () => {
    if (!document.fullscreenElement) {
      container.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const submitRating = async () => {
    const spec: RateContentSpec = {
      id: module.data.id,
      type: "learncast",
      rating,
      feedback1,
      feedback2,
      feedback3,
    };

    await rateContent.mutateAsync(spec);
    history.push(`/solutions/learncast/modules/${slug}/complete`);
  };

  const commitSuspendData = (data: any) => {
    axios.post(`/api/learncast/${module.data.id}/scorm`, data.cmi);
  };

  if (module.isLoading || !module.isSuccess) {
    return <Loader />;
  }

  let step = 0;

  if (location.pathname.includes("/programme")) {
    step = 1;
  } else if (location.pathname.includes("/rate")) {
    step = 2;
  } else if (location.pathname.includes("/complete")) {
    step = 3;
  }

  const steps = org.collectRatings
    ? ["Welcome", "Programme", "Rate", "Finish"]
    : ["Welcome", "Programme", "Finish"];

  return (
    <CenterColumn ref={ref}>
      <Panel ref={container} p={0} color={"primary"}>
        <ProgressTimeline color={"blue"} steps={steps} activeStep={step} />
        <Switch>
          <Route path="/solutions/learncast/modules/:slug" exact>
            <RedirectBasedOnAttempt attempt={attempt.data} slug={slug} />
          </Route>
          <Route path="/solutions/learncast/modules/:slug/welcome" exact>
            {attempt.data != null ? (
              <RedirectBasedOnAttempt attempt={attempt.data} slug={slug} />
            ) : (
              <MindsetWelcomeSplash
                name={module.data.name}
                color={theme.colors.primary}
                icon={module.data.icon}
                onStart={startNewAttempt}
              />
            )}
          </Route>
          <Route path="/solutions/learncast/modules/:slug/programme" exact>
            <Flex flexDirection="row-reverse" m={2}>
              <IconButton
                onClick={onFullScreenClick}
                type="button"
                icon={fullscreen ? FaRegWindowClose : FaRegWindowMaximize}
                color="black"
                title={fullscreen ? "Minimize" : "Maximize"}
              ></IconButton>
            </Flex>
            {attempt.data == null ||
            attempt.data.programmeCompletedOn != null ? (
              <Redirect to={`/solutions/learncast/modules/${slug}`} />
            ) : (
              <Flex flexDirection={"column"}>
                <ScormPlayer
                  parentWidth={width}
                  fullscreen={fullscreen}
                  commitUrl={`/api/learncast/${module.data.id}/scorm`}
                  contentUrl={module.data.path}
                  onCommit={commitSuspendData}
                  onComplete={lessonComplete}
                  initialData={attempt.data.lmsData}
                />
                <Flex p={3} justifyContent="flex-end">
                  <Button
                    color="primary"
                    onClick={() => lessonComplete()}
                    icon={FaChevronRight}
                  >
                    Complete Module
                  </Button>
                </Flex>
              </Flex>
            )}
          </Route>
          <Route path={`/solutions/learncast/modules/${slug}/rate`} exact>
            {attempt.data == null ||
            attempt.data.programmeCompletedOn == null ? (
              <Redirect to={`/solutions/learncast/modules/${slug}`} />
            ) : (
              <Box p={3}>
                <ContentHeader stage={"Rating"} module={module.data} />
                <Flex p={3} pb={5} flexDirection="column" alignItems="center">
                  <Text fontSize={5} fontWeight={600} mb={3}>
                    We hope you enjoyed this content. <br />
                    Please take a moment to leave some feedback.
                  </Text>
                  <StarRatingInput value={rating} onChange={setRating} mb={2} />
                  <FormTextArea
                    error={null}
                    label="What did you enjoy most about this module?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback1}
                    onChange={(ev) => setFeedback1(ev.target.value)}
                  />
                  <FormTextArea
                    error={null}
                    label="How will this module benefit you in the future?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback2}
                    onChange={(ev) => setFeedback2(ev.target.value)}
                  />
                  <FormTextArea
                    error={null}
                    label="If anything, what would you change about this module?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback3}
                    onChange={(ev) => setFeedback3(ev.target.value)}
                  />
                  <Button onClick={submitRating}>Continue</Button>
                </Flex>
              </Box>
            )}
          </Route>
          <Route path={`/solutions/learncast/modules/${slug}/complete`} exact>
            <Box p={3}>
              <ContentHeader stage={"Complete"} module={module.data} />
              <Flex p={3} pb={5} flexDirection="column" alignItems="center">
                <Text fontSize={5} fontWeight={600} mb={5}>
                  Thank you for completing this module
                </Text>
                <Button to="/solutions/learncast">Return to Learn Cast</Button>
              </Flex>
            </Box>
          </Route>
        </Switch>
      </Panel>
    </CenterColumn>
  );
};
