import { ModuleProfile, ScoreSet } from "@coaching-culture/types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";
import { Flex, Button, Box, RawContent } from "@coaching-culture/ui";
import { useState } from "react";
import { animated, useSpring } from "react-spring";
import ReactMarkdown from "react-markdown";
import { ProfileBarChart } from "./ProfilePlayer";

const Container = styled.div``;

const ProfileContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  transform-origin: bottom;
  overflow-y: auto;
  padding: 18px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
`;

export type ProfileReviewProps = {
  profiles: ModuleProfile[];
  children: React.ReactNode;
  scores: ScoreSet;
  color: string;
};

export function ProfileReview({
  profiles,
  children,
  scores,
  color,
}: ProfileReviewProps) {
  const [showProfile, setShowProfile] = useState<boolean>(false);

  const spring = useSpring({
    y: showProfile ? "0%" : "100%",
    opacity: showProfile ? 1 : 0,
  });

  return (
    <Container>
      <Box style={{ position: "relative", overflow: "hidden" }}>
        {children}
        <ProfileContainer style={spring}>
          {profiles.map((x, i) => (
            <Box mb={2} key={i}>
              {x.showAssessmentResults === "form-groups" && (
                <Box p={3} pb={0}>
                  <ProfileBarChart scores={scores} color={color} />
                </Box>
              )}
              <RawContent>
                <ReactMarkdown children={x.content} />
              </RawContent>
            </Box>
          ))}
        </ProfileContainer>
      </Box>
      <Flex justifyContent="flex-end" p={3}>
        <Button
          icon={showProfile ? FaChevronDown : FaChevronUp}
          color={color}
          onClick={() => setShowProfile((old) => !old)}
        >
          {showProfile ? "Hide Profile" : "Show Profile"}
        </Button>
      </Flex>
    </Container>
  );
}
