import React from "react";
import Box from "./Box";
import Button from "./Button";
import Flex from "./Flex";
import Modal from "./Modal";
import { ReactModal } from "./ReactModal";
import Text from "./Text";

export const ConfirmationModal = ({
  onCancel,
  buttons,
  isOpen,
  title = "Are you sure?",
  cancelText = "No",
  modalText = "Are you sure?",
}: {
  onCancel(): void;
  cancelText?: string;
  title?: string;
  isOpen: boolean;
  modalText?: string;
  buttons: { text: string; color: string; onClick: () => void }[];
}) => {
  return (
    <ReactModal onClose={onCancel} isOpen={isOpen}>
      <Box p={4}>
        <Text fontWeight={600} fontSize={4}>
          {title}
        </Text>
        <Text fontSize={3}>{modalText}</Text>
        <Flex mt={3} alignItems={"center"} flexDirection={"column"}>
          <Flex>
            {buttons.map((x) => (
              <Button mr={2} onClick={x.onClick} color={x.color}>
                {x.text}
              </Button>
            ))}
            <Button onClick={() => onCancel()} color="primary">
              {cancelText}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </ReactModal>
  );
};
