import React from "react";
import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";
import ControlLabel from "./ControlLabel";
import HelpBlock from "./HelpBlock";

type ContainerProps = SpaceProps & LayoutProps;

export type FormInputWrapProps = ContainerProps & {
  name?: string;
  label?: string;
  error?: any;
  children?: React.ReactNode;
  required?: boolean;
};

const Container = styled.div<ContainerProps>`
  ${space};
  ${layout};
  max-width: 100%;
`;

Container.defaultProps = {
  mb: 2
};

const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error;
  }

  if (error.message) {
    return error.message;
  }

  if (error.type === "minLength") {
    return "Value too short.";
  } else if (error.type === "required") {
    return "Value is required";
  }

  return "Invalid value";
};

const FormInputWrap = ({
  name,
  label,
  error,
  children,
  required,
  ...rest
}: FormInputWrapProps) => {
  return (
    <Container {...rest}>
      {label && (
        <ControlLabel htmlFor={name} required={required}>
          {label}
        </ControlLabel>
      )}
      {children}
      {error && (
        <HelpBlock color="danger" mt={1}>
          {getErrorMessage(error)}
        </HelpBlock>
      )}
    </Container>
  );
};

export default FormInputWrap;
