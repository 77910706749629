import { ProductIdent, ProductVisibility } from "@coaching-culture/types";
import {
  Loader,
  UserProfileImage,
  Text,
  Box,
  Flex,
  Button,
  Grid,
} from "@coaching-culture/ui";
import { useAllProductVisibility } from "hooks/useProductVisibility";
import { LessonModuleWidget } from "pages/Dashboard/Widgets/LessonModuleWidget";
import { lighten } from "polished";
import { useTeam } from "queries/performance";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router";
import styled from "styled-components";
import { ConversationsWidget } from "./ConversationsWidget";
import { FeedbackWidget } from "./FeedbackWidget";
import { GoalsWidget } from "./GoalsWidget";
import { MeasuresWidget } from "./MeasuresWidget";
import { MeasuresTrendsWidget } from "./MeasureTrendsWidget";

const TopBanner = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${(props) => lighten(0.2, props.theme.colors.primary)};
  border-radius: 12px;
  margin-bottom: -100px;
  background: linear-gradient(
    to top,
    #6dd5ed,
    ${(props) => props.theme.colors.primary}
  );
`;

export const RelationshipProfile = () => {
  const { userId } = useParams<{ userId: string }>();
  const team = useTeam();
  const products = useAllProductVisibility();

  const hasProduct = (ident: ProductIdent) => {
    return products[ident] === ProductVisibility.Enabled;
  };

  const teamUser = team.data?.directReports.find((x) => x.id === userId);

  if (teamUser == null) {
    return <Loader />;
  }

  const onDelete = () => {
    if (window.confirm("Are you sure you want to remove this relationship?")) {
    }
  };

  return (
    <Box p={3} maxWidth={1200}>
      <TopBanner />
      <Flex p={3} borderBottom={1} flexDirection="column" mb={3}>
        <UserProfileImage
          profileImage={teamUser.profileImage}
          name={teamUser.name}
          size="xxlarge"
          style={{ border: "3px solid white" }}
          mb={1}
        />
        <Flex justifyContent={"space-between"} alignItems="flex-end">
          <div>
            <Text fontSize={4} fontWeight={600}>
              {teamUser.name}
            </Text>
            <Text fontWeight={500} color="grey1">
              {teamUser.jobTitle || teamUser.email}
            </Text>
          </div>
          <Button icon={FaTrash} color="danger" onClick={onDelete}>
            Delete Relationship
          </Button>
        </Flex>
      </Flex>
      <Grid gridGap={3} gridTemplateColumns="1fr">
        {hasProduct("performance") && (
          <>
            <GoalsWidget goals={teamUser.goals} userId={teamUser.id} />
            <ConversationsWidget withUser={teamUser.id} />
            <MeasuresWidget relationshipId={teamUser.relationship.id} />
            <MeasuresTrendsWidget relationshipId={teamUser.relationship.id} />
          </>
        )}
        {hasProduct("cont-feedback") && <FeedbackWidget userId={teamUser.id} />}
        {hasProduct("mindset") && (
          <LessonModuleWidget contentType="module" userId={teamUser.id} />
        )}
        {hasProduct("lessons") && (
          <LessonModuleWidget contentType="lesson" userId={teamUser.id} />
        )}
        {hasProduct("learncast") && (
          <LessonModuleWidget contentType="learncast" userId={teamUser.id} />
        )}
      </Grid>
    </Box>
  );
};
