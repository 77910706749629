import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useTransition, animated } from "react-spring";
import styled from "styled-components";
import Panel from "./Panel";
import Flex from "./Flex";
import Text from "./Text";
import { IconType } from "react-icons";
import { FaSave } from "react-icons/fa";

export type ToastSpec = {
  content: React.ReactNode;
};

type ToastPusher = (input: ToastSpec) => void;

type Toast = { ts: number } & ToastSpec;

export const ToastContext = createContext<ToastPusher>(() => {});

const NotificationPanel = styled(animated(Panel))`
  position: fixed;
  right: 25px;
  width: 80%;
  max-width: 250px;
  bottom: 0;
  z-index: 2000;
`;

const ToastNotification = ({
  children,
  style,
}: React.ComponentProps<typeof NotificationPanel>) => {
  return <NotificationPanel style={style}>{children}</NotificationPanel>;
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const pushToast = useCallback(
    (input: ToastSpec) => {
      const t = {
        ...input,
        ts: new Date().getTime(),
      };

      setToasts((old) => old.concat([t]));
    },
    [setToasts]
  );

  useEffect(() => {
    const handler = () => {
      const d = new Date().getTime();
      setToasts((old) =>
        old.length === 0 ? old : old.filter((x) => d - x.ts < 5000)
      );
    };

    const i = setInterval(handler, 1000);

    return () => {
      clearInterval(i);
    };
  }, []);

  const activeToasts = toasts.slice(0, 1);

  const transitions = useTransition(activeToasts, {
    from: { transform: "translateY(100%)" },
    enter: { transform: "translateY(0)" },
    leave: { transform: "translateY(100%)" },
  });

  return (
    <>
      {transitions(
        (props, item) =>
          item && (
            <ToastNotification style={props}>{item.content}</ToastNotification>
          )
      )}
      <ToastContext.Provider value={pushToast}>
        {children}
      </ToastContext.Provider>
    </>
  );
};

export type IconToastProps = {
  text: string;
  icon: IconType;
  iconColor?: string;
};

export const IconToast = ({ text, icon: Icon, iconColor }: IconToastProps) => (
  <Flex alignItems="center" p={3} justifyContent="center">
    <Text as="span" color={iconColor}>
      <Icon color={iconColor} size={"1.5em"} />
    </Text>
    <Text ml={2} fontWeight={600} fontSize={3}>
      {text}
    </Text>
  </Flex>
);

export const useToast = () => useContext(ToastContext);

export const SavedToast = () => (
  <IconToast icon={FaSave} iconColor="primary" text="Saved" />
);
