import { GoalDto } from "@coaching-culture/types";
import { Box, ReactModal, Text } from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { useCreateGoal } from "queries/performance";

import { GoalEditor } from "./GoalEditor";

export type CreateGoalModalProps = {
  directReportId: string;
  onCancel: () => void;
  isOpen: boolean;
};

export const CreateGoalModal = ({
  onCancel,
  directReportId,
  isOpen,
}: CreateGoalModalProps) => {
  const createGoal = useCreateGoal(directReportId);
  const terms = useGoalTerminology();

  const onGoalCreate = (goal: GoalDto) => {
    goal.userId = directReportId;
    createGoal.mutateAsync(goal);
    onCancel();
  };

  return (
    <ReactModal isOpen={isOpen} width={600} onClose={onCancel}>
      <Box p={4}>
        <Text mb={2} fontSize={18}>
          Create {terms.goal.asTitle()}
        </Text>
        <GoalEditor
          goal={null}
          loading={false}
          onSave={onGoalCreate}
          onCancel={onCancel}
        />
      </Box>
    </ReactModal>
  );
};
