import { RequestFeedbackRequest } from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  Flex,
  FormInputWrap,
  IconToast,
  Loader,
  Panel,
  PanelHeader,
  Text,
  TextArea,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import CenterColumn from "components/CenterColumn";
import { FormUserSelect } from "components/FormUserSelect";
import { PageHeader } from "components/PageHeader";
import { isEmpty } from "lodash";
import { useRequestFeedback } from "queries/continuousFeedback";
import { useOrgUsers } from "queries/users";
import imgSrc from "../../../img/continuous-feedback.svg";
import { useState } from "react";
import { FaCommentDots, FaEnvelope } from "react-icons/fa";
import { useHistory, useParams } from "react-router";

export function RequestContinuousFeedback() {
  const { data: users, isFetched } = useOrgUsers();
  const requestFeedback = useRequestFeedback();
  const { userId } = useParams<{ userId: string }>();
  const [me] = useUser();
  const history = useHistory();

  const [feedbackRequest, setFeedbackRequest] = useState<
    Partial<RequestFeedbackRequest>
  >({
    recipientId: userId ?? "",
    reason: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const toastr = useToast();

  const getErrors = (spec: any) => {
    const errors = {};

    const required: (keyof RequestFeedbackRequest)[] = [
      "recipientId",
      "reason",
    ];

    for (const r of required) {
      if (spec[r] == null || spec[r] === "") {
        errors[r] = "Value is required";
      }
    }

    return errors;
  };

  const submit = async () => {
    const e = getErrors(feedbackRequest);
    setErrors(e);

    if (isEmpty(e)) {
      try {
        requestFeedback
          .mutateAsync(feedbackRequest as RequestFeedbackRequest)
          .then(() => {
            toastr({
              content: (
                <IconToast
                  icon={FaEnvelope}
                  text="Request Sent"
                  iconColor="positive"
                />
              ),
            });
            history.goBack();
          });
      } catch (err) {
        window.alert(err);
      }
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Request some feedback"
        subtitle="Ask for insights on strengths or areas of development"
        backUrl="/solutions/continuous-feedback"
        imageSrc={imgSrc}
      />
      <Flex flexDirection={["column", "column", "column", "row"]}>
        <Panel
          width={["100%", "100%", "100%", "30%"]}
          mr={[0, 0, 0, 3]}
          mb={[3, 3, 3, 0]}
        >
          <Box p={3}>
            {!isFetched ? (
              <Loader />
            ) : (
              <>
                <FormUserSelect
                  label="Choose who you want to request feedback from:"
                  value={feedbackRequest.recipientId}
                  options={users
                    .filter((x) => x.id !== me.id)
                    .map((x) => ({
                      id: x.id,
                      name: x.name,
                      email: x.email,
                      profileImage: x.profileImage,
                    }))}
                  error={errors.recipientId}
                  required
                  onChange={(val) =>
                    setFeedbackRequest({ ...feedbackRequest, recipientId: val })
                  }
                />
              </>
            )}
          </Box>
        </Panel>
        <Panel width={["100%", "100%", "100%", "70%"]}>
          <PanelHeader p={3}>
            <Circle mr={2} color="primary">
              <FaCommentDots size={20} />
            </Circle>
            <Text fontSize={4} fontWeight={600} color="black" as="h2">
              Feedback
            </Text>
          </PanelHeader>
          <Box p={3}>
            <FormInputWrap required label="Reason" error={errors.detail}>
              <TextArea
                height={150}
                value={feedbackRequest.reason}
                onChange={(val) =>
                  setFeedbackRequest({
                    ...feedbackRequest,
                    reason: val.target.value,
                  })
                }
              />
            </FormInputWrap>
            <Button color="primary" onClick={() => submit()}>
              Request Feedback
            </Button>
          </Box>
        </Panel>
      </Flex>
    </CenterColumn>
  );
}
