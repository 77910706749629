import {
  ModuleAttempt,
  ModuleRatingSpec,
  ModuleSummary,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormTextArea,
  IconButton,
  Loader,
  Panel,
  RawContent,
  Text,
} from "@coaching-culture/ui";
import { useOrg } from "auth/OrgProvider";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import StarRatingInput from "components/StarRatingInput";
import React, { useEffect, useRef, useState } from "react";
import { FaRegWindowClose, FaRegWindowMaximize } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router";
import { Link } from "react-router-dom";
import { useMeasure } from "react-use";
import styled from "styled-components";
import FormPlayer, { FormCompletionItem } from "../../components/FormPlayer";
import { ChallengeSplash } from "./ChallengeSplash";
import { MindsetWelcomeSplash } from "./MindsetWelcomeSplash";
import { ProfilePlayer } from "./ProfilePlayer";
import { ProfileReview } from "./ProfileReview";
import ProgrammePlayer from "./ProgrammePlayer";
import { ProgrammeSelector } from "./ProgrammeSelector";
import ProgressTimeline from "./ProgressTimeline";

const ModuleImage = styled.img`
  margin-right: 12px;
  width: 80px;
`;

const ContentHeader = ({
  stage,
  module,
}: {
  stage: string;
  module: ModuleSummary;
}) => (
  <Flex alignItems="center" mb={4}>
    <ModuleImage src={process.env.REACT_APP_STATIC_ROOT + "/" + module.icon} />
    <Text>
      <strong>{module.name}</strong>
      <span> | {stage}</span>
    </Text>
  </Flex>
);

const RedirectBasedOnAttempt = ({ attempt, slug }) => {
  if (attempt == null) {
    return <Redirect to={`/solutions/mindset/modules/${slug}/welcome`} />;
  } else if (attempt.formCompletionId == null) {
    return <Redirect to={`/solutions/mindset/modules/${slug}/assessment`} />;
  } else if (attempt.challengeStarted == null) {
    return <Redirect to={`/solutions/mindset/modules/${slug}/profile`} />;
  } else if (attempt.challengeCompletionId == null) {
    return <Redirect to={`/solutions/mindset/modules/${slug}/challenge`} />;
  } else if (attempt.completed == null) {
    return <Redirect to={`/solutions/mindset/modules/${slug}/rate`} />;
  } else {
    return <Redirect to={`/solutions/mindset/modules`} />;
  }
};

type MindsetParams = {
  slug: string;
};

function MindsetPlayer() {
  const { slug } = useParams<MindsetParams>();
  const location = useLocation();
  const history = useHistory();

  const container = useRef(null);

  const [org] = useOrg();
  const [ref, { width }] = useMeasure();
  const [module, setModule] = useState<ModuleSummary | null>(null);
  const [attempt, setAttempt] = useState<ModuleAttempt | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [rating, setRating] = useState<number | null>(null);
  const [feedback1, setFeedback1] = useState<string>("");
  const [feedback2, setFeedback2] = useState<string>("");
  const [feedback3, setFeedback3] = useState<string>("");
  const [notFound, setNotFound] = useState<boolean>(false);
  const [fullscreen, setFullScreen] = useState(false);

  useEffect(() => {
    setNotFound(false);
    setLoading(true);
    setAttempt(null);
    setModule(null);
    axios
      .get(`/api/mindset/${slug}`)
      .then(({ data }) => {
        setModule(data);
        setNotFound(false);
        axios
          .get(`/api/mindset/${data.id}/attempt`)
          .then(({ data }) => {
            setAttempt(data);
            setNotFound(false);
          })
          .catch((err) => {
            if (err?.response.status === 404) {
              setAttempt(null);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setNotFound(true);
          setLoading(false);
        }
      });
  }, [slug, history]);

  useEffect(() => {
    const handleFullscreenEvent = () => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenEvent);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenEvent);
    };
  }, []);

  const startNewAttempt = () => {
    setLoading(true);
    axios.post(`/api/mindset/${module.id}/attempt`).then(({ data }) => {
      setAttempt(data);
      setLoading(false);
      history.push(`/solutions/mindset/modules/${slug}/assessment`);
    });
  };

  const onProgrammeSelect = (id: string) => {
    setLoading(true);
    axios.put(`/api/mindset/${module.id}/select-programme`, { id }).then(() => {
      const p = attempt.availableProgrammes.find((x) => x.id === id);
      setAttempt((old) => ({
        ...old,
        programme: p,
      }));
      setLoading(false);
    });
  };

  const onChallengeComplete = (values: FormCompletionItem[]) => {
    setLoading(true);
    axios
      .post(`/api/mindset/${module.id}/challenge`, values)
      .then(({ data }) => {
        setAttempt((old) => ({
          ...old,
          ...data,
        }));
        if (org.collectRatings) {
          setLoading(false);
          history.push(`/solutions/mindset/modules/${slug}/rate`);
        } else {
          axios
            .put(`/api/mindset/${module.id}/rating`, {
              rating: null,
              feedback1: null,
              feedback2: null,
              feedback3: null,
            })
            .then(() => {
              setLoading(false);
              history.push(`/solutions/mindset/modules/${slug}/complete`);
            });
        }
      });
  };

  const onFormComplete = (values: FormCompletionItem[]) => {
    setLoading(true);
    axios
      .post(`/api/mindset/${module.id}/assessment`, values)
      .then(({ data }) => {
        setAttempt((old) => ({
          ...old,
          ...data,
        }));
        setLoading(false);
      });
  };

  const onCompleteProfile = () => {
    history.push(`/solutions/mindset/modules/${slug}/programme`);
  };

  const onCompleteProgramme = (data: any) => {
    if (loading) {
      // Ensure we don't get two events
      return;
    }
    setLoading(true);
    axios.post(`/api/mindset/${module.id}/programme`, data).then(({ data }) => {
      setAttempt((old) => ({
        ...old,
        ...data,
      }));
      setLoading(false);
      history.push(`/solutions/mindset/modules/${slug}/challenge`);
    });
  };

  const onFullScreenClick = () => {
    if (!document.fullscreenElement) {
      container.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const submitRating = () => {
    const spec: ModuleRatingSpec = {
      rating,
      feedback1,
      feedback2,
      feedback3,
    };

    axios.put(`/api/mindset/${module.id}/rating`, spec).then(() => {
      history.push(`/solutions/mindset/modules/${slug}/complete`);
    });
  };

  const commit = (data: any) => {
    axios.put(`/api/mindset/${module.id}/scorm`, data);
  };

  if (notFound) {
    return (
      <Flex p={5} flexDirection="column" alignItems="center">
        <Text fontSize={5} fontWeight={600}>
          Module not found or unavailable
        </Text>
        <Link to="/solutions/mindset/module">Back to Modules</Link>
      </Flex>
    );
  }

  if (slug.includes("_")) {
    return (
      <Redirect to={`/solutions/mindset/modules/${slug.replace(/_/g, "-")}`} />
    );
  }

  if (loading || module.slug !== slug) {
    return <Loader />;
  }

  let step = 0;

  if (location.pathname.includes("/assessment")) {
    step = 1;
  } else if (location.pathname.includes("/profile")) {
    step = 2;
  } else if (location.pathname.includes("/programme")) {
    step = 3;
  } else if (location.pathname.includes("/challenge")) {
    step = 4;
  } else if (location.pathname.includes("/rate")) {
    step = 5;
  } else if (location.pathname.includes("/complete")) {
    step = 5;
  }

  return (
    <CenterColumn ref={ref}>
      <Panel ref={container} p={0} color={module.mainColor || "blue"}>
        <ProgressTimeline
          color={module.mainColor || "blue"}
          steps={[
            "Welcome",
            "Self-assessment",
            "Profile",
            "Programme",
            "Challenge",
            "Finish",
          ]}
          activeStep={step}
        />
        <Switch>
          <Route path="/solutions/mindset/modules/:slug" exact>
            <RedirectBasedOnAttempt attempt={attempt} slug={slug} />
          </Route>
          <Route path="/solutions/mindset/modules/:slug/welcome" exact>
            {attempt != null ? (
              <RedirectBasedOnAttempt attempt={attempt} slug={slug} />
            ) : (
              <MindsetWelcomeSplash
                name={module.name}
                color={module.mainColor}
                icon={module.icon}
                onStart={startNewAttempt}
              />
            )}
          </Route>
          <Route path="/solutions/mindset/modules/:slug/assessment" exact>
            {attempt?.formCompletionId != null ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/profile`} />
            ) : (
              <Box p={3}>
                <ContentHeader stage={"Self-assessment"} module={module} />
                <FormPlayer
                  formId={module.formId}
                  onCompletion={onFormComplete}
                  color={module.mainColor}
                />
              </Box>
            )}
          </Route>
          <Route path="/solutions/mindset/modules/:slug/profile" exact>
            <Box p={3}>
              <ContentHeader stage={"Profile"} module={module} />
              <ProfilePlayer
                scores={attempt?.assessmentScores}
                color={module.mainColor}
                profiles={attempt?.profiles}
                postAssessment={attempt?.postAssessment}
                onComplete={onCompleteProfile}
              />
            </Box>
          </Route>
          <Route path="/solutions/mindset/modules/:slug/programme" exact>
            {attempt?.availableProgrammes?.length === 0 ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/assessment`} />
            ) : attempt?.challengeStarted != null ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/challenge`} />
            ) : attempt?.programme != null ? (
              <Box style={{ position: "relative" }}>
                <Flex flexDirection="row-reverse" m={2}>
                  <IconButton
                    onClick={onFullScreenClick}
                    type="button"
                    icon={fullscreen ? FaRegWindowClose : FaRegWindowMaximize}
                    color="black"
                    title={fullscreen ? "Minimize" : "Maximize"}
                  ></IconButton>
                </Flex>
                <ProfileReview
                  profiles={attempt?.profiles}
                  color={module.mainColor}
                  scores={attempt?.assessmentScores}
                >
                  <ProgrammePlayer
                    programme={attempt.programme}
                    onComplete={onCompleteProgramme}
                    initialData={attempt.lmsData}
                    onCommit={commit}
                    parentWidth={width}
                    fullscreen={fullscreen}
                  />
                </ProfileReview>
              </Box>
            ) : (
              <ProgrammeSelector
                color={module.mainColor}
                programmes={attempt?.availableProgrammes}
                onSelect={onProgrammeSelect}
                recommended={attempt?.recommendedProgramme}
              />
            )}
          </Route>
          <Route path="/solutions/mindset/modules/:slug/challenge" exact>
            {attempt?.challengeStarted == null ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/profile`} />
            ) : attempt?.challengeCompletionId != null ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/rate`} />
            ) : (
              <ChallengeSplash module={module} attempt={attempt} />
            )}
          </Route>
          <Route
            path="/solutions/mindset/modules/:slug/challenge-response"
            exact
          >
            {attempt?.challengeStarted == null ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/profile`} />
            ) : attempt.challengeCompletionId != null ? (
              <Redirect to={`/solutions/mindset/modules/${slug}/complete`} />
            ) : (
              <Box p={3}>
                <ContentHeader stage={"Challenge"} module={module} />
                <Flex
                  border={1}
                  borderRadius={6}
                  flexDirection="column"
                  p={2}
                  mb={3}
                >
                  <Text fontWeight={500} color="grey2" mb={2}>
                    Challenge Reminder
                  </Text>
                  <RawContent>
                    <ReactMarkdown children={attempt.programme.challengeText} />
                  </RawContent>
                </Flex>
                <FormPlayer
                  formId={attempt.programme.challengeFormId}
                  onCompletion={onChallengeComplete}
                />
              </Box>
            )}
          </Route>
          <Route path={`/solutions/mindset/modules/${slug}/rate`} exact>
            {attempt?.challengeCompletionId == null ? (
              <RedirectBasedOnAttempt attempt={attempt} slug={slug} />
            ) : (
              <Box p={3}>
                <ContentHeader stage={"Rating"} module={module} />
                <Flex p={3} pb={5} flexDirection="column" alignItems="center">
                  <Text fontSize={5} fontWeight={600} mb={3}>
                    We hope you enjoyed this content. <br />
                    Please take a moment to leave some feedback.
                  </Text>
                  <StarRatingInput value={rating} onChange={setRating} mb={2} />
                  <FormTextArea
                    error={null}
                    label="What did you enjoy most about this module?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback1}
                    onChange={(ev) => setFeedback1(ev.target.value)}
                  />
                  <FormTextArea
                    error={null}
                    label="How will this module benefit you in the future?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback2}
                    onChange={(ev) => setFeedback2(ev.target.value)}
                  />
                  <FormTextArea
                    error={null}
                    label="If anything, what would you change about this module?"
                    width={["auto", "500px"]}
                    height={150}
                    value={feedback3}
                    onChange={(ev) => setFeedback3(ev.target.value)}
                  />
                  <Button onClick={submitRating}>Continue</Button>
                </Flex>
              </Box>
            )}
          </Route>
          <Route path={`/solutions/mindset/modules/${slug}/complete`} exact>
            <Box p={3}>
              <ContentHeader stage={"Complete"} module={module} />
              <Flex p={3} pb={5} flexDirection="column" alignItems="center">
                <Text fontSize={5} fontWeight={600} mb={5}>
                  Thank you for completing this Mindset module
                </Text>
                <Button to="/solutions/mindset/modules">
                  Return to Modules
                </Button>
              </Flex>
            </Box>
          </Route>
        </Switch>
      </Panel>
    </CenterColumn>
  );
}

export default MindsetPlayer;
