import { Flex, Text } from "@coaching-culture/ui";
import { mix } from "polished";
import React, { useState } from "react";
import {
  FaBan,
  FaFrown,
  FaFrownOpen,
  FaGrin,
  FaMeh,
  FaSmile,
} from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { animated, config, useSpring, useTrail } from "react-spring";
import styled, { useTheme } from "styled-components";
import { fontSize, FontSizeProps } from "styled-system";

const opts = [FaFrownOpen, FaFrown, FaMeh, FaSmile, FaGrin];

const SmileButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 6px 12px;
  margin-bottom: 12px;
`;

const SmileButtonElem = styled(animated.button)<
  { color: string } & FontSizeProps
>`
  border: 0;
  cursor: pointer;
  background: none;
  color: ${(props) => props.color};
  ${fontSize};
`;

type RatingTypeProps = {
  reverse: boolean;
  value: number;
  onChange: (val: number) => void;
  requiresEvidence: boolean;
  noEvidenceLabel: string;
  allowNa: boolean;
};

type RatingButtonProps = {
  active: boolean;
  color: string;
  onClick: () => void;
  icon: IconType;
  text?: string;
};

const SmileButton = ({
  active,
  color,
  onClick,
  icon: Icon,
  text,
}: RatingButtonProps) => {
  const [hov, setHov] = useState<boolean>(false);

  const props = useSpring({
    scale: active ? 1.3 : 1,
    opacity: active || hov ? 1 : 0.3,
  });

  return (
    <SmileButtonContainer>
      <SmileButtonElem
        onClick={onClick}
        color={color}
        style={props}
        fontSize={["50px", "70px"]}
        onMouseEnter={() => setHov(true)}
        onMouseLeave={() => setHov(false)}
      >
        <Icon />
      </SmileButtonElem>
      {text && (
        <Text textAlign="center" maxWidth="128px">
          {text.split("\n").map((x) => (
            <>
              {x}
              <br />
            </>
          ))}
        </Text>
      )}
    </SmileButtonContainer>
  );
};

export const SmilesRating = ({
  requiresEvidence,
  reverse,
  value,
  onChange,
  noEvidenceLabel,
  allowNa,
}: RatingTypeProps) => {
  const theme = useTheme();

  const items = opts.map((x, i, self) => ({
    icon: x,
    color: mix(
      i / (self.length - 1),
      theme.colors.positive,
      theme.colors.danger
    ),
  }));

  let trail = useTrail(allowNa || requiresEvidence ? 6 : 5, {
    config: config.stiff,
    from: {
      y: 20 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex justifyContent="center" flexWrap="wrap" mb={[0, 3]}>
      {trail.slice(0, 5).map((styles, i) => (
        <animated.div style={styles}>
          <SmileButton
            {...items[i]}
            active={i + 1 === value}
            onClick={() => onChange(i + 1)}
          />
        </animated.div>
      ))}
      {(allowNa || requiresEvidence) && (
        <animated.div style={trail[5]}>
          <SmileButton
            color={theme.colors.danger}
            text={allowNa ? "N/A" : noEvidenceLabel}
            icon={FaBan}
            active={-1 === value}
            onClick={() => onChange(-1)}
          />
        </animated.div>
      )}
    </Flex>
  );
};
