import styled from "styled-components";

export const CustomCalendarComponent = styled.div`
  .react-datepicker {
    border: none;
    box-shadow: none;
  }

  .react-datepicker-time__header {
    color: ${(props) => props.theme.colors.primary};
  }

  .react-datepicker__header.react-datepicker__header--time {
    background-color: white;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      border: none;
      background-color: white;
    }

    .react-datepicker__day-name {
      color: ${(props) => props.theme.colors.primary};
    }

    .react-datepicker__current-month {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
