import { Loader, Modal } from "@coaching-culture/ui";
import { useGoalsForUser } from "queries/performance";
import { GoalDisplaySummary } from "./GoalDisplaySummary";

export type GoalDisplayModalProps = {
  goalId: string;
  userId: string;
  onClose: () => void;
};

export const GoalDisplayModal = ({
  goalId,
  userId,
  onClose,
}: GoalDisplayModalProps) => {
  const goals = useGoalsForUser(userId);

  const goal = (goals.data ?? []).find((x) => x.id === goalId);

  return (
    <Modal onClose={onClose}>
      {goal == null ? <Loader /> : <GoalDisplaySummary goal={goal} />}
    </Modal>
  );
};
