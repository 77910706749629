import React, { ReactNode } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import styled from "styled-components";

const Container = styled(animated.div)`
  overflow: hidden;
`;

export type ExpandableContainerProps = {
  expanded: boolean;
  children: ReactNode;
};

export const ExpandableContainer = ({
  expanded,
  children,
}: ExpandableContainerProps) => {
  const [contentRef, { height }] = useMeasure();

  const props = useSpring({
    height: expanded ? height : 0,
  });

  return (
    <Container style={props}>
      <div ref={contentRef as any}>{children}</div>
    </Container>
  );
};
