import { useUpdateOrgTerminology } from "@coaching-culture/api-client";
import {
  GoalFieldDto,
  UpdateOrgTerminologySpec,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Flex,
  FormInput,
  FormSelect,
  FormTextArea,
  FormToggle,
  IconButton,
  IconToast,
  LinkButton,
  Loader,
  Panel,
  SectionHeading,
  useToast,
} from "@coaching-culture/ui";
import { useGoalTerminology, useOrg } from "auth/OrgProvider";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { ExplainImage } from "components/ExplainImage";
import { PageHeader } from "components/PageHeader";
import {
  useCreateGoalField,
  useDeleteGoalField,
  useGoalFields,
  useReorderGoalFields,
  useUpdateGoalField,
} from "queries/performance";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FaArrowDown,
  FaArrowUp,
  FaCheckCircle,
  FaChevronDown,
  FaChevronRight,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { useTheme } from "styled-components";
import { arrMove } from "utils";
import convoSrc from "../../../img/conversations.svg";

type GoalFieldEditorProps = {
  field: GoalFieldDto;
  onCancel: () => void;
  saving?: boolean;
  onSave: (field: GoalFieldDto) => void;
  existing: boolean;
};

const GoalFieldEditor = ({
  field,
  onSave,
  onCancel,
  saving,
  existing,
}: GoalFieldEditorProps) => {
  const theme = useTheme();
  const terms = useGoalTerminology();

  const { handleSubmit, errors, reset, register, control } =
    useForm<GoalFieldDto>();

  useEffect(() => {
    reset(field);
  }, [field, reset]);

  const onSubmit = (values: GoalFieldDto) => {
    if (saving) return;
    onSave(values);
  };

  return (
    <Flex border={1} p={3} borderRadius={2} flexDirection="column" mb={2}>
      {saving && <Loader overlay />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="center" mb={2}>
          <FaChevronDown fontSize="1.5em" color={theme.colors.primary} />
          <FormInput
            width={1}
            mb={0}
            ml={2}
            name="name"
            placeholder="Custom field name..."
            ref={register({ required: true })}
            error={errors.name}
          />
        </Flex>
        <FormTextArea
          height={200}
          name="prompt"
          label="Prompt"
          ref={register}
        />
        <FormSelect
          name="usage"
          disabled={existing}
          label={existing ? "Usage - Locked for existing fields" : "Usage"}
          ref={register}
          options={[
            { label: `${terms.goal.asPluralTitle()} Only`, value: "goal" },
            {
              label: `${terms.objective.asPluralTitle()} Only`,
              value: "objective",
            },
            {
              label: "Both",
              value: "both",
            },
          ]}
        />
        <Controller
          control={control}
          name="required"
          defaultValue={false}
          render={({ value, onChange }) => (
            <FormToggle
              mb={4}
              value={value}
              onChange={onChange}
              label="Required"
              helpText="Whether or users must complete this field"
            />
          )}
        />
        <Flex>
          <Button onClick={onCancel} mr={2} type="button">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

type GoalFieldDisplayProps = {
  field: GoalFieldDto;
  onSelect: () => void;
  onDelete: () => void;
  onMove: (delta: number) => void;
  isTop: boolean;
  isBottom: boolean;
};

const GoalFieldDisplay = ({
  field,
  onDelete,
  onMove,
  isTop,
  isBottom,
  onSelect,
}: GoalFieldDisplayProps) => {
  return (
    <Flex border={1} p={3} borderRadius={2} mb={2} alignItems="center">
      <FaChevronRight fontSize="1.5em" />

      <LinkButton
        style={{ flex: 1 }}
        ml={2}
        mr={2}
        onClick={onSelect}
        fontWeight={500}
      >
        {field.name}
      </LinkButton>
      <IconButton
        icon={FaArrowUp}
        color="body"
        onClick={() => onMove(-1)}
        disabled={isTop}
      />
      <IconButton
        icon={FaArrowDown}
        color="body"
        onClick={() => onMove(1)}
        disabled={isBottom}
        ml={1}
      />
      <IconButton icon={FaTrash} color="danger" onClick={onDelete} ml={1} />
    </Flex>
  );
};

const blankGoalField = (): GoalFieldDto => ({
  usage: "goal",
  prompt: "",
  required: false,
  name: "",
});

export const GoalFields = () => {
  const { data: goalFields, isFetched } = useGoalFields();
  const createGoalField = useCreateGoalField();
  const deleteGoalField = useDeleteGoalField();
  const updateGoalField = useUpdateGoalField();
  const reorderGoalFields = useReorderGoalFields();
  const [editing, setEditing] = useState<GoalFieldDto | number | null>(null);
  const [saving, setSaving] = useState(false);
  const pushToast = useToast();
  const [order, setOrder] = useState<string[]>([]);
  const terms = useGoalTerminology();

  const loading = !isFetched;

  useEffect(() => {
    setOrder(goalFields?.map((x) => x.id) ?? []);
  }, [goalFields]);

  const removeItem = (id: string) => {
    if (window.confirm("Are you sure you want to remove that field?")) {
      deleteGoalField.mutateAsync(id);
    }
  };

  const move = (i: number, delta: number) => {
    const newList = arrMove(order, i, i + delta);
    setOrder(newList);
    reorderGoalFields.mutateAsync(newList);
  };

  const newItem = () => {
    setEditing(blankGoalField());
  };

  const save = async (field: GoalFieldDto) => {
    if (editing == null) {
      return;
    }

    setSaving(true);

    try {
      if (typeof editing === "object") {
        await createGoalField.mutateAsync(field);
      } else {
        await updateGoalField.mutateAsync({
          ...field,
          id: order[editing],
        });
      }

      pushToast({
        content: (
          <IconToast icon={FaCheckCircle} iconColor={"primary"} text="Saved" />
        ),
      });
      setEditing(null);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        window.alert(err.response?.data?.message.message);
      } else {
        window.alert("Unknown error");
      }
    } finally {
      setSaving(false);
    }
  };

  const onCancel = () => {
    setEditing(null);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <CenterColumn>
      <PageHeader
        text={`${terms.goal.asTitle()} Configuration`}
        subtitle={`Customise how ${terms.goal.asPluralBody()} are used in your organisation`}
      />
      <Panel p={[2, 3]} mb={3}>
        <ExplainImage
          src={convoSrc}
          title={`Custom ${terms.goal.asTitle()} Fields`}
          body={[
            `Customise your ${terms.goal.asPluralBody()} with custom fields`,
            `These allow you to tailor your goal setting procedure to match your organisation's system`,
          ].join("\n")}
        />
        <Flex width="100%" flexDirection="column" pt={3}>
          {order
            .map((x) => goalFields.find((gf) => gf.id === x))
            .filter((x) => x != null)
            .map((x, i, self) =>
              editing === i ? (
                <GoalFieldEditor
                  field={x}
                  onSave={save}
                  key={i}
                  onCancel={onCancel}
                  saving={saving}
                  existing
                />
              ) : (
                <GoalFieldDisplay
                  onSelect={() => setEditing(i)}
                  field={x}
                  key={i}
                  onDelete={() => removeItem(x.id)}
                  onMove={(delta) => move(i, delta)}
                  isTop={i === 0}
                  isBottom={i === self.length - 1}
                />
              ),
            )}
          {editing != null && typeof editing === "object" && (
            <GoalFieldEditor
              field={editing}
              onSave={save}
              onCancel={onCancel}
              saving={saving}
              existing={false}
            />
          )}
          {editing == null && (
            <AddButton onClick={newItem}>Add New Field</AddButton>
          )}
        </Flex>
      </Panel>
      <Terminology />
    </CenterColumn>
  );
};

const Terminology = () => {
  const [org] = useOrg();
  const { register, reset, errors, handleSubmit } =
    useForm<UpdateOrgTerminologySpec>();
  const updateOrg = useUpdateOrgTerminology();
  const pushToast = useToast();

  useEffect(() => {
    reset(org);
  }, [org, reset]);

  const save = async (values: UpdateOrgTerminologySpec) => {
    await updateOrg.mutateAsync({ orgId: org.id, ...values });
    pushToast({
      content: <IconToast icon={FaSave} iconColor="primary" text="Saved" />,
    });
  };

  return (
    <>
      <SectionHeading>Terminology</SectionHeading>
      <Panel p={[2, 3]}>
        <form onSubmit={handleSubmit(save)}>
          <FormInput
            label="A Goal is called a:"
            name="goalName"
            ref={register({ required: true, maxLength: 16 })}
            error={errors.goalName}
          />
          <FormInput
            label="A Sub-goal is called a:"
            mb={3}
            name="objectiveName"
            ref={register({ required: true, maxLength: 16 })}
            error={errors.objectiveName}
          />
          <Box>
            <Button color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </Panel>
    </>
  );
};
