import {
  CollectiveGoalDto,
  CollectiveGoalInsightsDto,
  CreateGoalCommentCommand,
  CreateGoalFieldDto,
  GoalDto,
  GoalFieldDto,
  TeamDto,
  TeamGoalDto,
  UserPerformanceInsightDto,
} from "@coaching-culture/types";
import { useUser } from "auth";
import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

// Goals

export async function getMyGoals(): Promise<GoalDto[]> {
  return await axios.get("/api/v2/goals").then(({ data }) => data);
}

export async function getGoalsForUser(userId: string): Promise<GoalDto[]> {
  return await axios
    .get(`/api/v2/goals?userId=${userId}`)
    .then(({ data }) => data);
}

export function useGoalsForUser(id: string) {
  return useQuery(["goals", id], () => getGoalsForUser(id));
}

export function useMyGoals() {
  const [user] = useUser();
  return useGoalsForUser(user.id);
}

export async function createGoal(spec: GoalDto): Promise<string> {
  return await axios.post("/api/v2/goals", spec).then(({ data }) => data);
}

export function useCreateGoal(userId?: string) {
  const [user] = useUser();
  const queryClient = useQueryClient();

  const mutation = useMutation(createGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "goals",
        userId != null ? userId : user.id,
      ]);
      queryClient.invalidateQueries(["orgGoalInsights"]);
    },
  });

  return mutation;
}

export async function updateGoal(spec: GoalDto) {
  if (!spec.id) {
    throw new Error("No id present in the Goal");
  }

  return await axios.put(`/api/v2/goals/${spec.id}`, spec);
}

export function useUpdateGoal(userId?: string) {
  const [user] = useUser();
  const queryClient = useQueryClient();

  const mutation = useMutation(updateGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "goals",
        userId != null ? userId : user.id,
      ]);
      queryClient.invalidateQueries(["orgGoalInsights"]);
    },
  });

  return mutation;
}

export async function deleteGoal(id: string) {
  return await axios.delete(`/api/v2/goals/${id}`);
}

export function useDeleteGoal(userId?: string) {
  const [user] = useUser();
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "goals",
        userId != null ? userId : user.id,
      ]);
      queryClient.invalidateQueries(["orgGoalInsights"]);
    },
  });

  return mutation;
}

export async function completeGoal({
  id,
  outcome,
}: {
  id: string;
  outcome: string;
}) {
  return await axios.put(`/api/v2/goals/${id}/complete`, { outcome: outcome });
}

export function useReopenGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(reopenGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("goals");
      queryClient.invalidateQueries(["orgGoalInsights"]);
    },
  });

  return mutation;
}

export async function reopenGoal(id: string) {
  return await axios.put(`/api/v2/goals/${id}/reopen`);
}

export function useCompleteGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(completeGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("goals");
      queryClient.invalidateQueries(["orgGoalInsights"]);
    },
  });

  return mutation;
}

export async function completeObjective({
  goalId,
  objectiveId,
}: {
  goalId: string;
  objectiveId: string;
}) {
  return await axios.put(
    `/api/v2/goals/${goalId}/objectives/${objectiveId}/complete`
  );
}

export function useCompleteObjective() {
  const queryClient = useQueryClient();

  const mutation = useMutation(completeObjective, {
    onSuccess: () => {
      queryClient.invalidateQueries("goals");
    },
  });

  return mutation;
}

// Goal fields

export async function getGoalFields(): Promise<GoalFieldDto[]> {
  return await axios.get("/api/v2/goal-fields").then(({ data }) => data);
}

export function useGoalFields() {
  return useQuery("goalFields", getGoalFields);
}

export async function createGoalField(
  spec: CreateGoalFieldDto
): Promise<string> {
  return await axios
    .post("/api/v2/goal-fields", spec)
    .then(({ data }) => data.id);
}

export function useCreateGoalField() {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, AxiosError, CreateGoalFieldDto>(
    createGoalField,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("goalFields");
      },
    }
  );

  return mutation;
}

export const reorderGoalFields = (ids: string[]) =>
  axios.put("/api/v2/goal-fields/order", { ids });

export function useReorderGoalFields() {
  const queryClient = useQueryClient();

  const mutation = useMutation(reorderGoalFields, {
    onSuccess: () => {
      queryClient.invalidateQueries("goalFields");
    },
  });

  return mutation;
}

export function deleteGoalField(id: string) {
  return axios.delete(`/api/v2/goal-fields/${id}`);
}

export function useDeleteGoalField() {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteGoalField, {
    onSuccess: () => {
      queryClient.invalidateQueries("goalFields");
    },
  });

  return mutation;
}

export function updateGoalField(body: GoalFieldDto) {
  return axios.put(`/api/v2/goal-fields/${body.id}`, body);
}

export function useUpdateGoalField() {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateGoalField, {
    onSuccess: () => {
      queryClient.invalidateQueries("goalFields");
    },
  });

  return mutation;
}

// Goal Comments

async function deleteGoalComment(goalCommentId: string) {
  return axios.delete(`/api/v2/goal-comments/${goalCommentId}`);
}

export function useDeleteGoalComment() {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteGoalComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("goals");
    },
  });

  return mutation;
}

async function createGoalComment(spec: CreateGoalCommentCommand) {
  return axios.post(`/api/v2/goal-comments`, spec);
}

export function useCreateGoalComment() {
  const queryClient = useQueryClient();

  const mutation = useMutation(createGoalComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("goals");
    },
  });

  return mutation;
}

// Org Goals

export async function getOrgGoals() {
  const { data } = await axios.get<CollectiveGoalDto[]>("/api/v2/org-goals");
  return data;
}

export async function createOrgGoal(spec: CollectiveGoalDto) {
  return await axios
    .post<string>("/api/v2/org-goals", spec)
    .then(({ data }) => data);
}

export async function updateOrgGoal(spec: CollectiveGoalDto) {
  return await axios.put(`/api/v2/org-goals/${spec.id}`, spec);
}

export async function getOrgGoalInsights(id: string) {
  return await axios
    .get<CollectiveGoalInsightsDto>(`/api/v2/org-goals/${id}/insights`)
    .then(({ data }) => data);
}

export async function deleteOrgGoal(id: string) {
  return await axios.delete(`/api/v2/org-goals/${id}`);
}

export function useOrgGoals() {
  return useQuery("orgGoals", getOrgGoals);
}

export function useOrgGoalInsights(id: string) {
  return useQuery(["orgGoalInsights", id], () => getOrgGoalInsights(id));
}

export function useUpdateOrgGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateOrgGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("orgGoals");
    },
  });

  return mutation;
}

export function useCreateOrgGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(createOrgGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("orgGoals");
    },
  });

  return mutation;
}

export function useDeleteOrgGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteOrgGoal, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries("orgGoals");
      queryClient.invalidateQueries(["orgGoalInsights", id]);
    },
  });

  return mutation;
}

export function useApplicableCollectiveGoals() {
  const [user] = useUser();
  const { data: org, isFetched } = useOrgGoals();
  const { data: team, isFetched: teamFetched } = useTeam();
  const { data: mine } = useOwnedTeamGoals();

  const orgGoals = (org ?? []).filter((x) => {
    if (x.conditions.length === 0) {
      return true;
    }

    const preds = x.conditions.map((c) =>
      user.userFields.some(
        (uf) => uf.id === c.userFieldId && uf.value === c.expectedValue
      )
    );

    if (x.aggregationMode === "all") {
      return preds.every((x) => x);
    } else {
      return preds.some((x) => x);
    }
  });

  const teamGoals =
    team == null
      ? []
      : team.lineManagers
          .filter(
            (x) => x.relationship.toAccepted && x.relationship.fromAccepted
          )
          .flatMap((x) => x.teamGoals)
          .concat(mine ?? []);

  return {
    orgGoals,
    teamGoals,
    isFetched: isFetched && teamFetched,
  };
}

export async function getOwnedTeamGoals() {
  const { data } = await axios.get<TeamGoalDto[]>("/api/v2/team-goals");
  return data;
}

export async function deleteTeamGoal(id: string) {
  return axios.delete(`/api/v2/team-goals/${id}`);
}

export async function createTeamGoal(spec: TeamGoalDto) {
  return await axios
    .post<string>("/api/v2/team-goals", spec)
    .then(({ data }) => data);
}

export async function updateTeamGoal(spec: TeamGoalDto) {
  if (!spec.id) {
    throw new Error("Id must be present to update");
  }

  return await axios
    .put<string>(`/api/v2/team-goals/${spec.id}`, spec)
    .then(({ data }) => data);
}

export function useOwnedTeamGoals() {
  return useQuery("ownedTeamGoals", getOwnedTeamGoals);
}

export function useCreateTeamGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(createTeamGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("ownedTeamGoals");
    },
  });

  return mutation;
}

export function useDeleteTeamGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteTeamGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("ownedTeamGoals");
    },
  });

  return mutation;
}

export function useUpdateTeamGoal() {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateTeamGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries("ownedTeamGoals");
    },
  });

  return mutation;
}

export async function getTeam() {
  return await axios.get<TeamDto>("/api/v2/teams/me").then(({ data }) => data);
}

export function useTeam() {
  return useQuery("team", getTeam);
}

export async function getUserPerformancInsights(filterString: string) {
  return await axios
    .get<UserPerformanceInsightDto[]>(
      `/api/v2/admin/user-insights?filter=${filterString}`
    )
    .then(({ data }) => data);
}

export function useUserPerformanceInsights(filterString: string) {
  return useQuery("userPerformanceInsights", () =>
    getUserPerformancInsights(filterString)
  );
}
