import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import styled from "styled-components";
import { Flex, Text, Loader, Panel, Img } from "@coaching-culture/ui";
import { FormCompletionItem, SurveyPlayer } from "components/SurveyPlayer";
import { FeedbackSurvey } from "@coaching-culture/types";
import { useSpring, animated } from "react-spring";
import { FeedbackIntro, FeedbackIntroResult } from "./FeedbackIntro";
import { useUser } from "auth";
import { Link } from "react-router-dom";
import awesomeSrc from "../../img/awesome.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fafafa;
`;

const AniPanel = animated(Panel);

function Done({ message }: { message: string }) {
  const [user] = useUser();

  const spring = useSpring({
    from: {
      y: 50,
    },
    y: 0,
  });

  return (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <AniPanel p={3} width={600} style={spring} m={2}>
        <Img src={awesomeSrc} m={3} />
        <Text
          color="black"
          as="h1"
          fontWeight={600}
          fontSize={5}
          textAlign="center"
          mt={2}
        >
          {message}
        </Text>
        <Text
          fontSize={4}
          textAlign="center"
          mb={5}
          fontWeight={500}
          color="grey1"
        >
          {user == null ? (
            "You can now close this window."
          ) : (
            <Link to="/solutions/feedback">Return to Feedback</Link>
          )}
        </Text>
      </AniPanel>
    </Flex>
  );
}

export function FeedbackSurveyRoute() {
  const [survey, setSurvey] = useState<FeedbackSurvey | null>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [recip, setRecip] = useState<FeedbackIntroResult | null>(null);
  const [done, setDone] = useState<boolean>(false);
  const [alreadyDone, setAlreadyDone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const surveyCode = params.get("survey");

  useEffect(() => {
    axios.get(`/api/feedback/survey/${surveyCode}`).then(({ data }) => {
      setSurvey(data);
      setLoading(false);
    });
  }, [surveyCode]);

  const onCompletion = (answers: FormCompletionItem[]) => {
    axios
      .post(`api/feedback/survey/${survey.id}`, {
        email: recip.email,
        name: recip.name,
        relationship: recip.relationshipId,
        answers,
      })
      .then(() => {
        setDone(true);
      })
      .catch((e) => {
        window.alert("There was an error submitting your response.");
      });
  };

  const onRecip = (res: FeedbackIntroResult) => {
    setLoading(true);
    axios
      .get(`api/feedback/survey/${surveyCode}/check-email?email=${res.email}`)
      .then(({ data }) => {
        if (data.completed) {
          setAlreadyDone(true);
        } else {
          setRecip(res);
        }
        setLoading(false);
      })
      .catch(() => {
        window.alert("There was a problem with the survey");
      });
  };

  const questionCount =
    survey?.form.items.filter(
      (x) =>
        x.type === "rating" ||
        x.type === "free_text" ||
        x.type === "multiple_choice",
    ).length ?? 0;

  const estimatedDuration =
    survey?.form.items
      .map((x) => {
        if (x.type === "free_text") {
          return 2;
        } else if (x.type === "multiple_choice") {
          return 0.5;
        } else if (x.type === "rating") {
          if (x.requiresEvidence) {
            return 2;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      })
      .reduce((acc, val) => acc + val, 0) ?? 0;

  return (
    <Container>
      {alreadyDone ? (
        <Done message="You've already completed this survey" />
      ) : done ? (
        <Done message="Thank you for completing the survey" />
      ) : loading ? (
        <Loader />
      ) : recip == null ? (
        <FeedbackIntro
          sender={survey.sender.name}
          onComplete={onRecip}
          senderId={survey.sender.id}
          relationships={survey.relationships}
          questionCount={questionCount}
          estimatedDuration={estimatedDuration}
        />
      ) : (
        <SurveyPlayer
          email={recip.email}
          form={survey.form}
          onCompletion={onCompletion}
        />
      )}
    </Container>
  );
}
