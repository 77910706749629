import {
  Button,
  Flex,
  IconButton,
  Loader,
  Panel,
  Table,
  Text,
} from "@coaching-culture/ui";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PageHeader } from "components/PageHeader";

const SelfReg = () => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    axios.get("/api/self-registration-forms").then(({ data }) => {
      setItems(data);
    });
  }, []);

  const loading = items == null;

  const deleteItem = (id) => {
    if (window.confirm("Are you sure you want to delete this form?")) {
      axios.delete(`/api/self-registration-forms/${id}`).then(() => {
        setItems((old) => old.filter((x) => x.url !== id));
      });
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Self Registration"
        subtitle="Manage your self registration forms"
      />
      <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
        <Text fontWeight={600}>Self Registration Forms</Text>

        <Button
          to="/success/people/self-registration/create"
          icon={FaPlusCircle}
          color="primary"
        >
          Create Form
        </Button>
      </Flex>
      <Panel>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.length === 0 ? (
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      No Forms Created
                    </td>
                  </tr>
                ) : (
                  items.map((x) => (
                    <tr>
                      <td>
                        <Text fontWeight={600}>
                          <Link
                            to={`/success/people/self-registration/${x.url}`}
                          >
                            {x.name}
                          </Link>
                        </Text>
                      </td>
                      <td>{format(new Date(x.createdOn), "do MMM yyyy")}</td>
                      <td style={{ textAlign: "right" }}>
                        <IconButton
                          icon={FaTimes}
                          color="danger"
                          onClick={() => deleteItem(x.url)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </>
        )}
      </Panel>
    </CenterColumn>
  );
};

export default SelfReg;
