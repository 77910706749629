export function discriminate<
  K extends PropertyKey,
  V extends string | number | boolean
>(discriminantKey: K, discriminantValue: V) {
  return <T extends Record<K, any>>(
    obj: T & Record<K, V extends T[K] ? T[K] : V>
  ): obj is Extract<T, Record<K, V>> =>
    obj[discriminantKey] === discriminantValue;
}

export function discriminateNeg<
  K extends PropertyKey,
  V extends string | number | boolean
>(discriminantKey: K, discriminantValue: V) {
  return <T extends Record<K, any>>(
    obj: T & Record<K, V extends T[K] ? T[K] : V>
  ): obj is Exclude<T, Extract<T, Record<K, V>>> =>
    obj[discriminantKey] !== discriminantValue;
}

export function hasDuplicates(array: any[]): boolean {
  return new Set(array).size !== array.length;
}

export function sanitizeEmail(email: string): string {
  return email.toLowerCase().trim();
}

export const helpItemIdents = {
  "comms-main": "Comms Page - Main",
  "content-main": "Content Access - Main",
  "bulk-main": "Bulk Upload - Main",
  "downloads-main": "Downloads Page - Main",
  "success-feedback-campaigns-main": "Success Feedback Campaigns - Main",
  "success-feedback-forms-main": "Success Feedback Forms - Main",
  "success-surveys-campaigns-main": "Success Surveys Campaigns - Main",
  "success-surveys-forms-main": "Success Surveys Forms - Main",
  "success-insights-main": "Success Insights - Main",
};

export type HelpItemIdent = keyof typeof helpItemIdents;
