import { RatingType } from "@coaching-culture/types";
import React from "react";
import { FaCircle, FaGrin, FaHashtag, FaStar } from "react-icons/fa";
import styled from "styled-components";
import Box from "../Box";
import Circle from "../Circle";
import ControlLabel from "../ControlLabel";
import Flex from "../Flex";
import Text from "../Text";

const types = [
  {
    type: "stars",
    icon: FaStar,
    title: "Stars",
    description: "A star rating out of 5.",
  },
  {
    type: "smiles",
    icon: FaGrin,
    title: "Faces",
    description: "A 'smile rating' for satisfaction.",
  },
  {
    type: "dots",
    icon: FaCircle,
    title: "Dots",
    description: "A measure of agreement.",
  },
  {
    type: "number",
    icon: FaHashtag,
    title: "Numbers",
    description: "A 1-10 rating",
  },
] as const;

const TypeButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  background: white;
  cursor: pointer;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: ${(props) => props.theme.colors.grey6};
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export function RatingTypeEditor({
  value,
  onChange,
}: {
  value: RatingType;
  onChange: (val: RatingType) => void;
}) {
  return (
    <Box mb={2}>
      <ControlLabel>Rating Type</ControlLabel>
      <Flex flexDirection="column">
        {types.map((x) => (
          <TypeButton onClick={() => onChange(x.type)}>
            <Circle
              icon={x.icon}
              mr={2}
              size="large"
              color={value === x.type ? "primary" : undefined}
            />
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Text
                fontWeight={600}
                color={value === x.type ? "primary" : undefined}
              >
                {x.title}
              </Text>
              <Text color="grey2">{x.description}</Text>
            </Flex>
          </TypeButton>
        ))}
      </Flex>
    </Box>
  );
}
