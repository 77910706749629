import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type ContainerProps = SpaceProps;

const NavContainer = styled.div<ContainerProps>`
  display: flex;
  max-width: 100%;
  width: 600px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
  justify-content: center;
  margin: auto;
  margin-bottom: 24px;
  ${space};
`;

type NavLinksProps = {
  activeColor?: string;
};

const StyledLink = styled(NavLink)<NavLinksProps>`
  background: none;
  border: 0;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey};
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
  transform: translateY(1px);
  padding: 12px 24px;
  transition: all 0.3s ease;
  ${space}

  &:hover {
    border-bottom: 4px solid
      ${(props) => props.theme.colors[props.activeColor] || props.activeColor};
    text-decoration: none;
    color: ${(props) => props.theme.colors.body};
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &.active {
    color: ${(props) => props.theme.colors.body};
    border-bottom-color: ${(props) =>
      props.theme.colors[props.activeColor] || props.activeColor};
  }
`;

StyledLink.defaultProps = {
  activeClassName: "active",
  activeColor: "primary",
};

type NavBarProps = {
  links: {
    label: string;
    url: string;
    exact?: boolean;
  }[];
  activeColor?: string;
};

const NavBar = ({ links, activeColor }: NavBarProps) => {
  return (
    <NavContainer>
      {links.map((x, i) => (
        <StyledLink
          to={x.url}
          exact={x.exact}
          activeColor={activeColor}
          key={i}
        >
          {x.label}
        </StyledLink>
      ))}
    </NavContainer>
  );
};

export default NavBar;
