import {
  Button,
  Circle,
  Flex,
  Modal,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import { FaInfoCircle } from "react-icons/fa";

export const HelpModal = ({
  children,
  onCancel,
}: {
  children: React.ReactNode;
  onCancel: () => void;
}) => {
  return (
    <Modal width={300} showCloseButton onClose={onCancel}>
      <PanelHeader>
        <Circle mr={2} icon={FaInfoCircle} color="warning"></Circle>
        <Text fontWeight={600}>Information</Text>
      </PanelHeader>
      {children}
      <Flex justifyContent={"center"} mb={2}>
        <Button color="warning" onClick={onCancel}>
          Dismiss
        </Button>
      </Flex>
    </Modal>
  );
};
