import {
  MultipleChoiceAnswer,
  MultipleChoiceAnswerSpec,
} from "@coaching-culture/types";
import { Flex, Text, SelectionButton } from "@coaching-culture/ui";
import { animated, useTrail } from "react-spring";

export type MultipleChoiceProps = {
  content: string;
  options: (MultipleChoiceAnswer | MultipleChoiceAnswerSpec)[];
  value: string;
  onChange: (val: string) => void;
  index: number;
  count: number;
  reverse: boolean;
  sectionName: string | null;
};

export const MultipleChoice = ({
  content,
  options,
  value,
  onChange,
  index,
  count,
  reverse,
  sectionName,
}: MultipleChoiceProps) => {
  let trail = useTrail(options.length + 1, {
    from: {
      y: 100 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex flexDirection="column" mb={3}>
      <animated.div style={trail[0]}>
        <Text fontWeight={600} color="grey2" fontSize={2}>
          {sectionName ? `${sectionName} - ` : ""} {index + 1} / {count}
        </Text>
        <Text mb={3} fontSize={4} fontWeight={600}>
          {content}
        </Text>
      </animated.div>
      <Flex flexDirection="column" alignItems="stretch">
        {trail.slice(1).map((styles, i) => (
          <animated.div style={styles}>
            <SelectionButton
              key={options[i].id}
              onClick={() => onChange(options[i].id)}
              active={value === options[i].id}
            >
              {options[i].content}
            </SelectionButton>
          </animated.div>
        ))}
      </Flex>
    </Flex>
  );
};
