import styled from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";

const NavContainer = styled.div<SpaceProps>`
  display: flex;
  max-width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
  justify-content: center;
  ${space};
`;

type LinkProps = {
  active: boolean;
  color: string | null;
} & SpaceProps &
  TypographyProps;

const StyledLink = styled.button<LinkProps>`
  background: none;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  color: ${(props) =>
    props.active ? props.theme.colors.body : props.theme.colors.grey};
  border-bottom: 4px solid
    ${(props) =>
      props.active
        ? props.theme.colors[props.color ?? "primary"] ?? props.color
        : "transparent"};
  text-transform: uppercase;
  transform: translateY(1px);
  flex: 0 1 auto;
  user-select: none;
  transition: all 0.3s ease;
  ${space}
  ${typography};

  &:hover {
    border-bottom: 4px solid
      ${(props) => props.theme.colors[props.color ?? "primary"] ?? props.color};
    text-decoration: none;
    color: ${(props) => props.theme.colors.body};
  }

  &:active,
  &:focus {
    outline: 0;
  }
`;

const SectionLink = ({ active, children, onClick, color }) => {
  return (
    <StyledLink
      color={color}
      pl={[3, 5]}
      pr={[3, 5]}
      pb={2}
      pt={0}
      active={active}
      onClick={onClick}
      fontSize={[3, 4]}
    >
      {children}
    </StyledLink>
  );
};

type SectionNavProps = {
  links: string[];
  active: number;
  onSelect: (idx: number) => void;
  colors?: string[];
} & SpaceProps;

const SectionNav = ({
  links,
  active,
  onSelect,
  colors = [],
  ...rest
}: SectionNavProps) => {
  return (
    <NavContainer {...rest}>
      {links.map((x, i) => (
        <SectionLink
          active={active === i}
          onClick={() => onSelect(i)}
          key={i}
          color={colors[i]}
        >
          {x}
        </SectionLink>
      ))}
    </NavContainer>
  );
};

export default SectionNav;
