import { GoalDto } from "@coaching-culture/types";
import { Panel, Text } from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useCreateGoal } from "queries/performance";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { GoalEditor } from "./GoalEditor";

export function CreateGoal() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const createGoal = useCreateGoal();
  const terms = useGoalTerminology();

  const saveGoal = async (values: GoalDto) => {
    setLoading(true);

    try {
      await createGoal.mutateAsync(values);
    } catch (err) {
      setLoading(false);
    }

    history.push("/solutions/performance/goals");
  };

  return (
    <CenterColumn>
      <PageHeader
        text={`Create a ${terms.goal.asTitle()}`}
        subtitle={`${terms.goal.asTitle()} are important for personal growth`}
        backUrl="/solutions/performance"
      />
      <Panel p={3}>
        <Text fontSize={4} fontWeight={500} mb={3}>
          {terms.goal.asTitle()} Details
        </Text>
        <GoalEditor
          goal={null}
          onSave={saveGoal}
          loading={loading}
          onCancel={() => history.push("/solutions/performance/goals")}
        />
      </Panel>
    </CenterColumn>
  );
}
