import { Flex, Text } from "@coaching-culture/ui";
import { lighten } from "polished";
import React, { useState } from "react";
import { IconType } from "react-icons";
import { FaBan, FaStar } from "react-icons/fa";
import { animated, config, useSpring, useTrail } from "react-spring";
import styled, { useTheme } from "styled-components";
import { fontSize, FontSizeProps, space, SpaceProps } from "styled-system";

const starColor = "#F7CA00";

const StarButtonContainer = styled.div<SpaceProps>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 42px;
  ${space};
`;

const StarButton = styled(animated.button)<
  FontSizeProps & SpaceProps & { color: string }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  cursor: pointer;
  ${fontSize};
  ${space};
`;

type RatingTypeProps = {
  reverse: boolean;
  value: number;
  onChange: (val: number) => void;
  requiresEvidence: boolean;
  noEvidenceLabel: string;
  allowNa: boolean;
};

type RatingButtonProps = {
  active: boolean;
  highlight?: boolean;
  icon: IconType;
  color: string;
  onClick: () => void;
};

const RatingButton = ({
  active,
  highlight,
  onClick,
  icon: Icon,
  color,
}: RatingButtonProps) => {
  const props = useSpring({
    rotateY: active ? 180 : 0,
    color: highlight ? lighten(0.2, color) : active ? color : "#AAAAAA",
  });

  return (
    <StarButton
      onClick={onClick}
      style={props}
      fontSize={["24px", "50px"]}
      color={color}
      ml={[1, 2]}
      mr={[1, 2]}
    >
      <Icon />
    </StarButton>
  );
};

export const StarRating = ({
  allowNa,
  requiresEvidence,
  reverse,
  value,
  onChange,
  noEvidenceLabel,
}: RatingTypeProps) => {
  const theme = useTheme();
  const [hover, setHover] = useState<number>(-1);

  let trail = useTrail(allowNa || requiresEvidence ? 6 : 5, {
    config: config.stiff,
    from: {
      y: 20 * (reverse ? -1 : 1),
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
  });

  if (reverse) {
    trail.reverse();
  }

  return (
    <Flex justifyContent="center" alignItems="flex-start">
      <StarButtonContainer pl={[2, 3]} pr={[2, 3]} pt={2} pb={2}>
        {trail.slice(0, 5).map((styles, i) => (
          <animated.div
            style={styles}
            onMouseEnter={() => setHover(i)}
            onMouseLeave={() => setHover(-1)}
          >
            <RatingButton
              active={i + 1 <= value}
              highlight={i <= hover}
              onClick={() => onChange(i + 1)}
              icon={FaStar}
              color={starColor}
            />
          </animated.div>
        ))}
      </StarButtonContainer>
      {(requiresEvidence || allowNa) && (
        <Flex flexDirection="column" alignItems="center">
          <StarButtonContainer
            ml={[1, 2]}
            mb={2}
            pl={[2, 3]}
            pr={[2, 3]}
            pt={2}
            pb={2}
          >
            <animated.div style={trail[5]}>
              <RatingButton
                active={value === -1}
                onClick={() => onChange(-1)}
                icon={FaBan}
                color={theme.colors.danger}
              />
            </animated.div>
          </StarButtonContainer>
          <Text
            textAlign="center"
            color="grey2"
            fontSize={[2, 3]}
            maxWidth={128}
          >
            {allowNa ? "N/A" : noEvidenceLabel}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
