import React from "react";
import {
  CheckBox,
  ControlLabel,
  FormInput,
  FormSelect,
  MultiSelect,
} from "@coaching-culture/ui";
import { SpaceProps } from "styled-system";
import { UserField, UserFieldOptionDto } from "@coaching-culture/types";
import { useUser } from "auth";
import { useOrgUsers } from "queries/users";

type UserFieldInputProps = {
  field: UserField;
  value: string | boolean | string[];
  onChange: (newValue: string | boolean | string[]) => void;
  emptyMessage?: string;
  filteredUsers?: string[];
  error: any;
  isFilter: boolean;
  fieldFilter?: { id: string; value: string[] }[];
  selectedGroups?: string[];
} & SpaceProps;

const UserFieldInput = ({
  field,
  value,
  onChange,
  error,
  filteredUsers,
  emptyMessage = "<Choose>",
  isFilter,
  fieldFilter,
  selectedGroups,
  ...rest
}: UserFieldInputProps) => {
  const [user] = useUser();
  const users = useOrgUsers();
  const canEdit =
    user == null ||
    field.editable === "user" ||
    user.permissions.includes("user-admin");

  if (selectedGroups && selectedGroups.length > 0 && users !== null) {
    users.data = users.data?.filter((user) =>
      user.groups.some((group) => selectedGroups.includes(group.id))
    );
  }

  const getCount = (option: UserFieldOptionDto): number => {
    let userArray = users.data?.filter((user) =>
      user.userFields.find((uf) => uf.id === field.id && uf.value === option.id)
    );
    if (filteredUsers) {
      userArray = userArray?.filter((user) => filteredUsers?.includes(user.id));
    }
    if (fieldFilter != null) {
      userArray = userArray?.filter((user) =>
        user.userFields.some((uf) => {
          return fieldFilter
            .map((res) => res.value)
            .every((vals) => vals.includes(uf.value as string));
        })
      );
    }
    return userArray?.length;
  };

  if (field.type === 0) {
    return (
      <FormInput
        {...rest}
        label={field.name}
        error={error}
        value={(value as string) || ""}
        disabled={!canEdit}
        onChange={(ev) => onChange(ev.target.value)}
      />
    );
  } else if (field.type === 1 && !isFilter) {
    return (
      <FormSelect
        {...rest}
        label={field.name}
        disabled={!canEdit}
        value={value?.toString() || ""}
        onChange={(ev) => onChange(ev.target.value)}
        error={error}
        options={[{ value: "", label: emptyMessage }].concat(
          field.options.map((x: any) => ({
            value: x.id,
            label: x.name,
          }))
        )}
      />
    );
  } else if (field.type === 1 && isFilter) {
    return (
      <>
        <ControlLabel>{field.name}</ControlLabel>
        <MultiSelect
          {...rest}
          options={field.options.map((x) => ({
            value: x.id.toString(),
            label: x.name + " (" + getCount(x) + ")",
          }))}
          value={value as string[]}
          onChange={(v) => onChange(v)}
        />
      </>
    );
  } else if (field.type === 2) {
    return (
      <CheckBox
        {...rest}
        label={field.name}
        disabled={!canEdit}
        value={(value || 0).toString() === "1"}
        onChange={(v) => onChange(v ? "1" : "0")}
      />
    );
  }
};

export default UserFieldInput;
