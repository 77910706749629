import React, { useRef } from "react";
import styled from "styled-components";
import { Button, Text } from "@coaching-culture/ui";
import axios, { AxiosRequestConfig } from "axios";
import { space, SpaceProps } from "styled-system";

type ProfilePictureInputProps = {
  value: string | null;
  onChange: (val: string | null) => void;
  name: string;
} & SpaceProps;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s ease;
`;

const Container = styled.div<{ src: string | null } & SpaceProps>`
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.grey4};
  background-image: ${(props) => (props.src ? `url(${props.src})` : "")};
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 220px;
  ${space};

  &:hover ${ButtonContainer} {
    opacity: 1;
  }
`;

const getInitials = (name: string) =>
  (name || "")
    .split(" ")
    .map((x) => x.charAt(0))
    .join("")
    .substring(0, 2)
    .toUpperCase();

export const ProfilePictureInput = ({
  value,
  onChange,
  name,
  ...rest
}: ProfilePictureInputProps) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const uploadImage = async () => {
    const file = (fileRef?.current?.files || [])[0];

    if (file == null) {
      return;
    }

    if (!file.type.startsWith("image")) {
      alert("Must be an image file");
      return;
    }

    const filename = file.name;

    const res = await axios.post<{ url: string; key: string }>(
      "/api/uploads/signed",
      { filename }
    );

    const config: AxiosRequestConfig = {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
    };

    axios
      .put(res.data.url, new File([file], res.data.key), config)
      .then(() => {
        onChange(res.data.key);
      })
      .catch((err) => {
        alert(err.message);
        console.log(err.toJSON());
      });
  };

  const startUpload = () => {
    fileRef.current.click();
  };

  const remoteFilename = value
    ? process.env.REACT_APP_STATIC_ROOT + "/" + value
    : null;

  return (
    <Container src={remoteFilename} {...rest}>
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={uploadImage}
      />
      {!remoteFilename && (
        <Text fontSize="100px" fontWeight={600}>
          {getInitials(name)}
        </Text>
      )}
      <ButtonContainer>
        <Button onClick={startUpload} color="primary" type="button">
          Change
        </Button>
      </ButtonContainer>
    </Container>
  );
};
