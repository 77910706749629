import { CommentDto } from "@coaching-culture/types";
import { Box, Flex, Text } from "@coaching-culture/ui";
import ReactHtmlParser from "react-html-parser";
import { FaLock } from "react-icons/fa";
import styled from "styled-components";
import { formatDate } from "utils/dates";
import { UserFlag } from "./UserFlag";

const CommentContainer = styled.div`
  & img {
    max-width: 100%;
    height: auto;
  }
`;

export type CommentProps = {
  comment: CommentDto;
  deleteComment?: ((id: string) => void) | null;
};

export const Comment = ({ comment }: CommentProps) => {
  return (
    <Flex mr={0} mt={2} pl={1} flexDirection="column" borderBottom={1}>
      <Flex justifyContent={"space-between"} mb={1}>
        <UserFlag user={comment.createdBy} />
      </Flex>
      <Box pl={"42px"}>
        <CommentContainer>{ReactHtmlParser(comment.body)}</CommentContainer>
        <Flex mb={2} mt={1}>
          <Text fontSize={2} color={"grey"} mr={2} fontWeight={500}>
            {formatDate(comment.createdOn, "dd/MM/yy : HH:mm")}
          </Text>
          {comment.isPrivate && (
            <Text
              fontSize={2}
              fontWeight={600}
              color="warning"
              textAlign={"right"}
            >
              <FaLock style={{ marginRight: 6 }} />
              Private
            </Text>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
