import {
  ProductVisibility,
  Relationship,
  UserDisplay,
} from "@coaching-culture/types";
import {
  Button,
  Flex,
  Loader,
  Panel,
  SectionHeading,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { TabPane, Tabs } from "components/Tabs";
import UserProfileImage from "components/UserProfileImage";
import { useProductVisibility } from "hooks/useProductVisibility";
import { TeamView } from "pages/TeamView";
import { lighten } from "polished";
import { useTeam } from "queries/performance";
import {
  useAcceptRelationship,
  useDeleteRelationship,
  useMyRelationships,
} from "queries/team";
import { Route, Switch } from "react-router";
import { useTheme } from "styled-components";
import { useUser } from "../../auth";
import { MeasuresPane } from "./MeasuresPane";
import { OldTeamView } from "./OldTeamView";
import { TeamGoals } from "./TeamGoals";
import TeamInsights from "./TeamInsights";
import { ManagerItem } from "./TeamMember";

const RelationshipInvite = ({
  user,
  type,
  onAccept,
  onReject,
}: {
  user: UserDisplay;
  type: string;
  onAccept: () => void;
  onReject: () => void;
}) => {
  const theme = useTheme();

  return (
    <Flex
      p={2}
      mb={3}
      justifyContent="space-between"
      borderRadius={6}
      backgroundColor={lighten(0.1, theme.colors.primary)}
      alignItems="center"
      border="1px solid"
      borderColor="primary"
    >
      <Flex alignItems="center">
        <UserProfileImage
          name={user.name}
          profileImage={user.profileImage}
          size="large"
          mr={2}
        />
        <Text color="white" fontWeight={500}>
          <strong>{user.name}</strong> invites you to become their {type}.
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Button color="danger" onClick={onReject}>
          Reject
        </Button>
        <Button color="primary" ml={1} onClick={onAccept}>
          Accept
        </Button>
      </Flex>
    </Flex>
  );
};

const relationshipTypeNames = {
  manager: ["Line Manager", "Direct Report"],
};

const Team = () => {
  const [user] = useUser();
  const hasTeamView =
    useProductVisibility("teamview") === ProductVisibility.Enabled;
  const hasPerf =
    useProductVisibility("performance") === ProductVisibility.Enabled;
  const hasMeasures =
    useProductVisibility("measures") === ProductVisibility.Enabled;
  const { refetch: refetchTeam, isFetched } = useTeam();
  const { data: relationships, isFetched: relsFeteched } = useMyRelationships();

  const acceptRelationship = useAcceptRelationship();
  const deleteRelationshipMut = useDeleteRelationship();

  const isManager = user.permissions.includes("manager");

  const deleteRelationship = async (id: string) => {
    if (window.confirm("Are you sure?")) {
      await deleteRelationshipMut.mutateAsync(id);
      refetchTeam();
    }
  };

  if (!isFetched || !relsFeteched) {
    return <Loader />;
  }

  const managers = relationships.filter(
    (x) => x.type === "manager" && x.from.id !== user.id,
  );

  const invites = relationships.filter(
    (x) =>
      (x.from.id === user.id && x.fromAccepted == null) ||
      (x.to.id === user.id && x.toAccepted == null),
  );

  const notMe = (r: Relationship) => (r.to.id === user.id ? r.from : r.to);

  const relType = (r: Relationship) =>
    relationshipTypeNames[r.type][r.to.id === user.id ? 1 : 0];

  return (
    <>
      {invites.map((x) => (
        <RelationshipInvite
          key={x.id}
          user={notMe(x)}
          type={relType(x)}
          onAccept={() => acceptRelationship.mutate(x.id)}
          onReject={() => deleteRelationship(x.id)}
        />
      ))}
      {isManager && (
        <Tabs>
          <TabPane defaultValue="Team">
            <>{hasTeamView ? <TeamView /> : <OldTeamView />}</>
          </TabPane>
          {hasMeasures && (
            <TabPane defaultValue={"Measures"}>
              <MeasuresPane />
            </TabPane>
          )}
          {hasPerf && (
            <TabPane defaultValue={"Team Goals"}>
              <TeamGoals />
            </TabPane>
          )}
        </Tabs>
      )}
      {!isManager && (
        <>
          <SectionHeading>Your Managers</SectionHeading>
          <Panel pt={2}>
            {managers.length === 0 ? (
              <Text
                color="grey2"
                fontWeight={500}
                pl={2}
                pr={2}
                pb={2}
                textAlign="center"
              >
                No Line Manager Assigned
              </Text>
            ) : (
              managers.map((x) => (
                <ManagerItem
                  key={x.id}
                  manager={x.from}
                  confirmed={x.fromAccepted != null && x.toAccepted != null}
                />
              ))
            )}
          </Panel>
        </>
      )}
    </>
  );
};

const MyTeam = () => {
  return (
    <CenterColumn>
      <PageHeader
        text="My Team"
        subtitle="People with whom you have a relationship"
      />
      <Switch>
        <Route path="/solutions/my-team" exact component={Team} />
        <Route
          path="/solutions/my-team/insights"
          exact
          component={TeamInsights}
        />
      </Switch>
    </CenterColumn>
  );
};

export default MyTeam;
