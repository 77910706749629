import { SurveySummary } from "@coaching-culture/types";
import {
  Box,
  Button,
  ConfirmationModal,
  ExpandableContainer,
  Flex,
  IconButton,
  Label,
  Loader,
  Panel,
  PanelHeader,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { format, fromUnixTime } from "date-fns";
import { useDeleteSurveyCampaign, useSurveyCampaigns } from "queries/surveys";
import { useCallback, useState } from "react";
import {
  FaArchive,
  FaChevronDown,
  FaChevronUp,
  FaEdit,
  FaPaperPlane,
  FaTrash,
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const searchItem = (item: SurveySummary) => item.name;

const AccordianButton = styled.div`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const Campaigns = () => {
  const deleteCampaignMut = useDeleteSurveyCampaign();
  const { data: items, isFetched } = useSurveyCampaigns();
  const [campaignId, setCampaignId] = useState<string>(null);
  const [showArchived, setShowArchived] = useState(false);
  const [modalText, setModalText] = useState("");
  const history = useHistory();

  const cols = useCallback(
    (archive: boolean): SortableTableColumn<SurveySummary>[] => [
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Flex alignItems={"center"}>
            {x.isDraft && (
              <Label mr={2} color="warning" size={"small"}>
                Draft
              </Label>
            )}
            <Link to={`/success/surveys/campaigns/${x.id}`}>
              <strong>{x.name}</strong>
            </Link>
          </Flex>
        ),
      },
      {
        name: "createdOn",
        label: "Sent On",
        props: {
          textAlign: "right",
        },
        headingProps: {
          textAlign: "right",
        },
        format: (x) => format(fromUnixTime(x.createdOn), "yyyy-MM-dd"),
      },
      {
        name: "responseCount",
        label: "Responses",
        props: {
          textAlign: "right",
        },
        headingProps: {
          textAlign: "right",
        },
      },
      {
        name: "responseRate",
        label: "Response Rate",
        props: {
          textAlign: "right",
        },
        headingProps: {
          textAlign: "right",
        },
        format: (x) =>
          ((x.responseCount / Math.max(1, x.recipientCount)) * 100).toFixed(0) +
          "%",
        sortFormat: (x) =>
          (x.responseCount / Math.max(1, x.recipientCount)) * 100,
      },
      {
        label: "",
        name: "actions",
        headingProps: {
          width: 50,
          textAlign: "center",
        },
        format: (x) => (
          <Flex>
            <IconButton
              mr={2}
              title={"Edit"}
              onClick={() =>
                history.push(`/success/surveys/campaigns/edit/${x.id}`)
              }
              color="body"
              icon={FaEdit}
            />
            <IconButton
              title={archive ? "Archive" : "Delete"}
              color={archive ? "body" : "danger"}
              onClick={() => {
                archive
                  ? setModalText(
                      "Are you sure you want to archive this campaign?"
                    )
                  : setModalText(
                      "Are you sure you want to delete this campaign? All associated data will be permantly removed."
                    );
                setCampaignId(x.id);
              }}
              icon={archive ? FaArchive : FaTrash}
            />
          </Flex>
        ),
      },
    ],
    [history]
  );

  return (
    <CenterColumn>
      <ConfirmationModal
        isOpen={campaignId != null}
        onCancel={() => setCampaignId(null)}
        buttons={[
          {
            text: "Yes",
            color: "danger",
            onClick: () => {
              deleteCampaignMut.mutateAsync(campaignId);
              setCampaignId(null);
            },
          },
        ]}
        modalText={modalText}
      />

      <PageHeader
        text="Survey Campaigns"
        subtitle="Unlocking the power of employee feedback"
        helpIdent="success-surveys-campaigns-main"
      />
      <Box>
        {!isFetched ? (
          <Loader />
        ) : (
          <>
            <Flex
              alignItems={["stretch", "center"]}
              justifyContent="flex-end"
              flexDirection={["column", "row"]}
              mb={2}
            >
              <Button
                to="/success/surveys/campaigns/create"
                color="primary"
                icon={FaPaperPlane}
              >
                Send Survey
              </Button>
            </Flex>
            <Panel mb={3} style={{ overflow: "hidden" }}>
              <SortableTable<SurveySummary>
                columns={cols(true)}
                data={items.filter((x) => !x.archived)}
                rowSearch={searchItem}
                paged
                defaultSort={1}
                defaultDesc
                emptyMessage="No Surveys Created Yet..."
              />
            </Panel>
            <Panel style={{ overflow: "hidden" }}>
              <PanelHeader>
                <Text fontWeight={600}>Archived Campaigns</Text>
                <Flex
                  alignItems="end"
                  justifyContent="center"
                  flexDirection="column"
                  flexGrow={1}
                >
                  {showArchived ? (
                    <AccordianButton onClick={() => setShowArchived(false)}>
                      <Flex alignItems={"center"}>
                        <Text fontWeight={600} mr={2}>
                          Hide
                        </Text>
                        <FaChevronUp />
                      </Flex>
                    </AccordianButton>
                  ) : (
                    <AccordianButton onClick={() => setShowArchived(true)}>
                      <Flex alignItems={"center"}>
                        <Text fontWeight={600} mr={2}>
                          Show
                        </Text>
                        <FaChevronDown />
                      </Flex>
                    </AccordianButton>
                  )}
                </Flex>
              </PanelHeader>
              <ExpandableContainer expanded={showArchived}>
                <SortableTable<SurveySummary>
                  columns={cols(false)}
                  data={items.filter((x) => x.archived)}
                  rowSearch={searchItem}
                  paged
                  defaultSort={1}
                  defaultDesc
                  emptyMessage="No Archived Surveys..."
                />
              </ExpandableContainer>
            </Panel>
          </>
        )}
      </Box>
    </CenterColumn>
  );
};
