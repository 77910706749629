import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { sum } from "lodash";

const Container = styled.svg`
  height: 100%;
  width: 100%;
`;

const MainPath = styled.path<{ blockColor: string }>`
  stroke: ${(props) => props.theme.colors[props.blockColor]};
  cursor: pointer;
`;

const Value = styled.text<{ blockColor: string }>`
  fill: ${(props) => props.theme.colors[props.blockColor]};
  font-size: 20px;
  & tspan:first-child {
    font-weight: 600;
    font-size: 40px;
  }
`;

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInRadians: number,
) {
  angleInRadians -= Math.PI / 2;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  console.log(startAngle, endAngle);

  var largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    "0",
    largeArcFlag,
    "0",
    end.x,
    end.y,
  ]
    .map((x) => (typeof x === "string" ? x : x.toFixed(1)))
    .join(" ");

  return d;
}

export type ContentDonutGraphProps = {
  values: {
    label: string;
    value: number;
    colour: string;
  }[];
};

const maxIndex = (values: ContentDonutGraphProps["values"]) => {
  return values
    .map((x) => x.value)
    .reduce(
      (maxIndex, currentValue, currentIndex, array) =>
        currentValue > array[maxIndex] ? currentIndex : maxIndex,
      0,
    );
};

export const ContentDonutGraph = ({ values }: ContentDonutGraphProps) => {
  const [over, setOver] = useState<number | null>(null);
  const [selected, setSelected] = useState<number | null>(maxIndex(values));
  const theme = useTheme();

  const total = sum(values.map((x) => x.value));

  const arcs: string[] = [];
  let theta = 0;

  const lengths = values.map((x) => x.label);
  const colours = values.map((x) => x.colour);

  let fullArc = -1;
  let i = 0;

  for (const val of values) {
    if (val.value === total) {
      fullArc = i;
    }
    const size = (val.value / total) * Math.PI * 2;
    arcs.push(describeArc(0, 0, 90, theta, theta + size));
    theta += size;
    i++;
  }

  const mouseOver = (idx: number | null) => {
    setOver(idx);
  };

  return (
    <Container viewBox={`0 0 200 200`}>
      <g transform="translate(100,100),scale(0.6)">
        <g onMouseOut={() => mouseOver(null)}>
          <circle
            cx={0}
            cy={0}
            r={90}
            fill="none"
            strokeWidth={30}
            stroke="rgba(128,128,128,0.5)"
          />
          {fullArc > -1 && (
            <circle
              cx={0}
              cy={0}
              r={90}
              fill="none"
              stroke={theme.colors[colours[fullArc]]}
              strokeWidth={
                selected === fullArc || over === fullArc ? "35" : "30"
              }
              onClick={() => setSelected(fullArc)}
              onMouseOver={() => mouseOver(fullArc)}
            />
          )}
          {arcs.map((x, i) => (
            <MainPath
              blockColor={colours[i]}
              d={x}
              fill="transparent"
              strokeWidth={selected === i || over === i ? "35" : "30"}
              onClick={() => setSelected(i)}
              onMouseOver={() => mouseOver(i)}
            />
          ))}
          <circle cx={0} cy={0} r={65} fill="rgba(0,0,0,0.01)" />
        </g>
        {over != null ? (
          <g>
            <Value x="0" y="-4" blockColor={colours[over]} textAnchor="middle">
              <tspan>{values[over].value}</tspan>
            </Value>
            <Value
              x="0"
              y="24"
              fill="#655"
              blockColor={colours[over]}
              textAnchor="middle"
            >
              {lengths[over]}
            </Value>
          </g>
        ) : (
          <g>
            <Value
              x="0"
              y="-4"
              blockColor={colours[selected]}
              textAnchor="middle"
            >
              <tspan>{values[selected].value}</tspan>
            </Value>
            <Value
              x="0"
              y="24"
              fill="#655"
              blockColor={colours[selected]}
              textAnchor="middle"
            >
              {lengths[selected]}
            </Value>
          </g>
        )}
      </g>
    </Container>
  );
};
