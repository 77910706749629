import { ConversationTypeDto } from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

async function getConversationTypes() {
  const { data } = await axios.get<ConversationTypeDto[]>(
    "/api/v2/conversation-types"
  );
  return data;
}

async function createConversationType(spec: Partial<ConversationTypeDto>) {
  return await axios
    .post<string>(`/api/v2/conversation-types`, spec)
    .then(({ data }) => data);
}

const updateConversationType = (
  id: string,
  spec: Partial<ConversationTypeDto>
) => axios.put(`/api/v2/conversation-types/${id}`, spec);

const deleteConversationType = (id: string) =>
  axios.delete(`/api/v2/conversation-types/${id}`);

export function useConversationTypes() {
  return useQuery("conversationTypes", getConversationTypes);
}

export function useCreateConversationType() {
  const queryClient = useQueryClient();

  const mutation = useMutation(createConversationType, {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversationTypes"]);
    },
  });

  return mutation;
}

export function useDeleteConversationType() {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteConversationType, {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversationTypes"]);
    },
  });

  return mutation;
}

export function useUpdateConversationType(id: string) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: Partial<ConversationTypeDto>) => updateConversationType(id, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["conversationTypes"]);
      },
    }
  );

  return mutation;
}

const changeOrder = (ids: string[]) =>
  axios.put(`/api/v2/conversation-types/types/reorder`, {
    ids: ids,
  });

export const useChangeConversationTypeOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((ids: string[]) => changeOrder(ids), {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversationTypes"]);
    },
  });

  return mutation;
};
