import {
  AddressBookItem,
  discriminate,
  UserFieldOption,
  UserFieldType,
  UserGroup,
  UserSummary,
} from "@coaching-culture/types";
import {
  Circle,
  Text,
  Flex,
  Button,
  InputWithSearch,
  Box,
  ReactModal,
} from "@coaching-culture/ui";
import axios from "axios";
import { ItemContainer, ItemsList } from "components/ShareModal/Drawer";
import UserProfileImage from "components/UserProfileImage";
import { useUserFields } from "queries/users";
import React, { useEffect, useState } from "react";
import { FaAddressBook, FaUsers } from "react-icons/fa";
import styled from "styled-components";
import SectionNav from "./SectionNav";

const ClearItems = styled.button`
  display: inline;
  color: ${(props) => props.theme.colors.primary};
  background: none;
  border: 0;
  cursor: pointer;
  margin-left: 6px;

  &:hover {
    text-decoration: underline;
  }
`;

export type AddressBookModalProps = {
  onSelect: (items: AddressBookItem[]) => void;
  onCancel: () => void;
  isOpen: boolean;
};

const UserItem = ({
  onClick,
  name,
  active,
}: {
  onClick: () => void;
  name: string;
  active: boolean;
}) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <UserProfileImage name={name} mr={2} size="small" />
        <Text
          fontWeight={500}
          fontSize={4}
          color={active ? "positive" : "body"}
        >
          {name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

const GroupItem = ({
  onClick,
  name,
  active,
}: {
  onClick: () => void;
  name: string;
  active: boolean;
}) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <Circle icon={FaUsers} mr={2} size="small" />
        <Text
          fontWeight={500}
          fontSize={4}
          color={active ? "positive" : "body"}
        >
          {name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

export function AddressBookModal({
  onSelect,
  onCancel,
  isOpen,
}: AddressBookModalProps) {
  const [selectedUsers, setSelectedUsers] = useState<Record<number, boolean>>(
    {}
  );
  const [selectedGroups, setSelectedGroups] = useState<Record<number, boolean>>(
    {}
  );
  const [selectedUserFieldOptions, setSelectedUserFieldOptions] = useState<
    Record<number, boolean>
  >({});

  const userFields = useUserFields();
  const userFieldOptions = (userFields.data ?? [])
    .filter(discriminate("type", UserFieldType.Select))
    .flatMap((x) =>
      x.options.map((o) => ({
        id: o.id,
        name: `${x.name} = ${o.name}`,
      }))
    );
  const [users, setUsers] = useState<UserSummary[]>([]);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [search, setSearch] = useState<string>("");
  const [showing, setShowing] = useState<number>(0);

  useEffect(() => {
    axios.get("/api/users").then(({ data }) => setUsers(data));
  }, []);

  useEffect(() => {
    axios.get("/api/groups").then(({ data }) => setGroups(data));
  }, []);

  const toggleUser = (user: UserSummary) => {
    setSelectedUsers((old) => ({
      ...old,
      [user.id]: !old[user.id],
    }));
  };

  const toggleGroup = (user: UserGroup) => {
    setSelectedGroups((old) => ({
      ...old,
      [user.id]: !old[user.id],
    }));
  };

  const toggleUserField = (field: UserFieldOption) => {
    setSelectedUserFieldOptions((old) => ({
      ...old,
      [field.id]: !old[field.id],
    }));
  };

  let filteredUsers = users;

  if (search.trim() !== "") {
    filteredUsers = users.filter(
      (x) =>
        x.name.toLowerCase().includes(search.toLowerCase()) ||
        x.email.toLowerCase().includes(search.toLowerCase())
    );
  }

  const confirm = () => {
    const u = users
      .filter((x) => selectedUsers[x.id])
      .map<AddressBookItem>((x) => ({
        id: x.id,
        name: x.firstName,
        email: x.email,
        type: "user",
      }));
    const g = groups
      .filter((x) => selectedGroups[x.id])
      .map<AddressBookItem>((x) => ({
        id: x.id,
        name: x.name,
        type: "group",
      }));
    const uf = userFieldOptions
      .filter((x) => selectedUserFieldOptions[x.id])
      .map<AddressBookItem>((x) => ({
        id: x.id,
        name: x.name,
        type: "userfield",
      }));
    onSelect([...u, ...g, ...uf]);
  };

  const clearItems = () => {
    setSelectedGroups({});
    setSelectedUsers({});
    setSelectedUserFieldOptions({});
  };

  const selectedCount = Object.values({
    ...selectedUsers,
    ...selectedGroups,
    ...selectedUserFieldOptions,
  }).filter((x) => x).length;

  return (
    <ReactModal
      isOpen={isOpen}
      width={600}
      minHeight={500}
      onClose={() => onCancel()}
    >
      <>
        <Flex alignItems="center" flexDirection="column" p={3}>
          <Circle color="primary" icon={FaAddressBook} size="large" mb={2} />
          <Text fontWeight={600} fontSize={6}>
            Directory
          </Text>
          <Text>
            {selectedCount} item(s) selected
            {selectedCount > 0 && (
              <ClearItems onClick={clearItems}>Clear Items</ClearItems>
            )}
          </Text>
        </Flex>
        <SectionNav
          mt={3}
          active={showing}
          links={["Users", "Groups", "Userfields"]}
          onSelect={(idx) => setShowing(idx)}
          mb={2}
        />
        {showing === 0 ? (
          <>
            <Box p={3} pt={1}>
              <InputWithSearch
                width={400}
                placeholder="Search..."
                searching={false}
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
              />
            </Box>
            <ItemsList style={{ padding: 0 }}>
              {filteredUsers.map((x) => (
                <UserItem
                  name={x.name}
                  onClick={() => toggleUser(x)}
                  active={selectedUsers[x.id]}
                />
              ))}
            </ItemsList>
          </>
        ) : showing === 1 ? (
          <>
            <ItemsList style={{ padding: 0 }}>
              {groups.map((x) => (
                <GroupItem
                  name={x.name}
                  onClick={() => toggleGroup(x)}
                  active={selectedGroups[x.id]}
                />
              ))}
            </ItemsList>
          </>
        ) : (
          <>
            <ItemsList style={{ padding: 0 }}>
              {userFieldOptions.map((x) => (
                <GroupItem
                  name={x.name}
                  onClick={() => toggleUserField(x)}
                  active={selectedUserFieldOptions[x.id]}
                />
              ))}
            </ItemsList>
          </>
        )}
        <Flex alignItems="center" justifyContent="center" p={3}>
          <Button onClick={confirm} color="primary">
            Select
          </Button>
        </Flex>
      </>
    </ReactModal>
  );
}
