import {
  Button,
  Flex,
  Loader,
  Panel,
  SectionHeading,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import ShareModal, { ShareConfig } from "components/ShareModal";
import { sortBy } from "lodash";
import { useUserContent } from "queries/mindset";
import { useOrgUser } from "queries/users";
import React, { useState } from "react";
import { useParams } from "react-router";
import { ContentDetailsTable, LessonDetailsTable } from "./ContentDetailsTable";

export const UserProfilePage = () => {
  const [user] = useUser();
  const { id } = useParams<{ id: string }>();
  const userId = id.toString().toLowerCase() === "me" ? user.id : id;
  const [shareConfig, setShareConfig] = useState<ShareConfig | null>(null);
  const { data: userContent, isFetched } = useUserContent(userId);
  const { data: profileUser, isFetched: userFetched } = useOrgUser(userId);

  const isMe = userId === user.id;
  const isManagerOrAdmin =
    user.directReports.some((dr) => dr.id === id) ||
    user.permissions.includes("org-admin");

  if (!isFetched || !userFetched) {
    return <Loader />;
  }

  return (
    <CenterColumn>
      {shareConfig && (
        <ShareModal config={shareConfig} onClose={() => setShareConfig(null)} />
      )}

      <PageHeader
        text={isMe ? "My Content" : `${profileUser.name}'s Content`}
        subtitle={`A summary of ${
          isMe ? "your" : "this user's"
        } content progress`}
      />

      <Flex alignItems={"center"}>
        <SectionHeading>Mindset</SectionHeading>
        {isManagerOrAdmin && (
          <Flex mb={3} ml={2} flexDirection={"row-reverse"}>
            <Button
              color="primary"
              onClick={() =>
                setShareConfig({
                  type: "module",
                  targetId: id,
                  itemId: null,
                  targetType: "user",
                })
              }
            >
              Assign Mindset
            </Button>
          </Flex>
        )}
      </Flex>

      <Panel mb={5}>
        <ContentDetailsTable
          items={sortBy(
            userContent.filter((x) => x.type === "module"),
            "name"
          )}
          contentType="module"
        />
      </Panel>
      <Flex alignItems={"center"}>
        <SectionHeading>Lessons</SectionHeading>
        {isManagerOrAdmin && (
          <Flex mb={3} ml={2} flexDirection={"row-reverse"}>
            <Button
              color="primary"
              onClick={() =>
                setShareConfig({
                  type: "lesson",
                  targetId: id,
                  itemId: null,
                  targetType: "user",
                })
              }
            >
              Assign Lesson
            </Button>
          </Flex>
        )}
      </Flex>
      <LessonDetailsTable
        items={userContent.filter((x) => x.type === "lesson")}
      />
    </CenterColumn>
  );
};
