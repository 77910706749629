import {
  UserField,
  UserFieldDto,
  UserFieldOption,
  UserFieldType,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  FormInput,
  FormInputWrap,
  FormSelect,
  FormTextArea,
  IconButton,
  Input,
  Loader,
  Modal,
  Panel,
  PanelHeader,
  PanelInset,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import {
  useCreateUserField,
  useUpdateUserField,
  useUserField,
} from "queries/users";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { useHistory, useParams } from "react-router";

type BulkModalProps = {
  onClose: () => void;
  onSave: (value: string[]) => void;
};

const BulkModal = ({ onClose, onSave }: BulkModalProps) => {
  const [value, setValue] = useState("");

  const save = () => {
    const opts = value.split("\n").filter((x) => x.trim() !== "");
    onSave(opts);
  };

  return (
    <Modal onClose={onClose} showCloseButton width={400}>
      <PanelHeader>
        <Text fontSize={4} fontWeight={600}>
          Bulk Add
        </Text>
      </PanelHeader>
      <Box p={3}>
        <FormTextArea
          height={150}
          label="Options (one per line)"
          value={value}
          onChange={(ev) => setValue(ev.target.value)}
        />
        <Flex>
          <Button onClick={save} color="primary" mr={2}>
            Add
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Flex>
      </Box>
    </Modal>
  );
};

type EditUserFieldParams = { id: string };

const fieldTypes = [
  {
    value: "0",
    label: "Freetext",
  },
  {
    value: "1",
    label: "Select List",
  },
  {
    value: "2",
    label: "Checkbox",
  },
];

const editModes = [
  {
    value: "user",
    label: "Editable by user",
  },
  {
    value: "admin",
    label: "Editable by admins only",
  },
];

type UserFieldFormProps = {
  userField: Partial<UserField>;
  onSave: (values: Partial<UserField>) => void;
};

const UserFieldForm = ({ userField, onSave }: UserFieldFormProps) => {
  const { register, errors, reset, handleSubmit, control, watch } =
    useForm<UserFieldDto>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
    keyName: "key",
  });
  const [showBulkModal, setShowBulkModal] = useState(false);

  useEffect(() => {
    reset(userField);
  }, [userField, reset]);

  const editing = !!watch("id");

  const onSubmit = (values: UserFieldDto) => {
    const type = values.type;

    const spec: UserFieldDto =
      type === UserFieldType.Select
        ? {
            name: values.name,
            editable: values.editable,
            type: UserFieldType.Select,
            options: values.options || [],
          }
        : {
            name: values.name,
            editable: values.editable,
            type,
          };

    if (spec.type === UserFieldType.Select && spec.options.length === 0) {
      window.alert("At least one option is required on a select list");
      return;
    }

    onSave(spec);
  };

  const removeOption = (opt: Partial<UserFieldOption>, index: number) => {
    if (
      !opt.id ||
      window.confirm(
        "Are you sure want to remove this option?\n\nAny users that have selected this option will have it removed."
      )
    ) {
      remove(index);
    }
  };

  const onBulkAdd = (value: string[]) => {
    append(value.map((x) => ({ name: x })));
    setShowBulkModal(false);
  };

  return (
    <Panel p={3}>
      {showBulkModal && (
        <BulkModal onClose={() => setShowBulkModal(false)} onSave={onBulkAdd} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="id" ref={register} />
        <FormInput
          name="name"
          label="Field Name"
          error={errors.name}
          ref={register({ required: true })}
        />
        <FormSelect
          options={editModes}
          ref={register}
          label="Editability"
          name="editable"
          error={errors.editable}
        />
        {editing && (
          <input
            type="hidden"
            ref={register({ valueAsNumber: true })}
            name="type"
          />
        )}
        <FormSelect
          options={fieldTypes}
          ref={
            !editing
              ? register({
                  valueAsNumber: true,
                })
              : undefined
          }
          label={
            !editing ? "Field Type" : "Field Type (Locked for existing fields)"
          }
          value={editing ? userField.type.toString() : undefined}
          name="type"
          disabled={editing}
          error={errors.type}
        />
        {watch("type")?.toString() === "1" && (
          <Box mb={2}>
            <Text fontWeight={600} mb={1}>
              Options
            </Text>
            <PanelInset p={3} mb={2}>
              {editing && (
                <PanelInset color="danger" p={2} mb={2}>
                  <Text color="white" fontWeight={600}>
                    Beware of editing the names of field options on existing
                    fields. Users will inherit any changes made.
                  </Text>
                </PanelInset>
              )}
              {fields.map((x, i, self) => (
                <Box key={x.id} mb={i === self.length - 1 ? 3 : 1}>
                  <input
                    type="hidden"
                    name={`options[${i}].id`}
                    ref={register}
                    defaultValue={x.id}
                  />
                  <FormInputWrap
                    name={`options[${i}].name`}
                    error={(errors as any)?.options?.[i]?.name}
                    mb={0}
                  >
                    <Flex alignItems="center">
                      <Text
                        mr={3}
                        fontSize={4}
                        fontWeight={600}
                        width={30}
                        textAlign="center"
                      >
                        {i + 1}
                      </Text>
                      <Input
                        style={{ flex: 1 }}
                        mb={0}
                        name={`options[${i}].name`}
                        ref={register({ required: true })}
                        defaultValue={x.name}
                        mr={2}
                      />

                      <IconButton
                        icon={FaTimes}
                        color="danger"
                        onClick={() => removeOption(x as any, i)}
                        type="button"
                      />
                    </Flex>
                  </FormInputWrap>
                </Box>
              ))}
              <Flex>
                <Button
                  type="button"
                  onClick={() => append({ name: "" })}
                  mr={2}
                >
                  Add Option
                </Button>
                <Button type="button" onClick={() => setShowBulkModal(true)}>
                  Bulk Add
                </Button>
              </Flex>
            </PanelInset>
          </Box>
        )}
        <Button type="submit" mt={1} color="primary">
          Save Field
        </Button>
      </form>
    </Panel>
  );
};

export function EditUserField() {
  const { id } = useParams<EditUserFieldParams>();
  const history = useHistory();

  const { data, isFetched } = useUserField(id);
  const update = useUpdateUserField(id);

  const onSave = async (values: UserFieldDto) => {
    await update.mutateAsync(values);
    history.push("/success/people/user-fields");
  };

  return (
    <CenterColumn>
      <PageHeader
        text={"Edit User Field"}
        subtitle="Configure a user field"
        backUrl="/success/people/user-fields"
      />
      {!isFetched ? (
        <Loader />
      ) : (
        <Box style={{ position: "relative" }}>
          {update.isLoading ? <Loader overlay /> : null}
          <UserFieldForm userField={data} onSave={onSave} />
        </Box>
      )}
    </CenterColumn>
  );
}

const defaultUserField: Partial<UserField> = {
  name: "",
  type: 0,
  editable: "user",
};

export function CreateUserField() {
  const history = useHistory();
  const createUserField = useCreateUserField();

  const onSave = async (values: UserFieldDto) => {
    await createUserField.mutateAsync(values);
    history.push("/success/people/user-fields");
  };

  return (
    <CenterColumn>
      <PageHeader
        text={"Create User Field"}
        subtitle="Configure a user field"
        backUrl="/success/people/user-fields"
      />
      <Box style={{ position: "relative" }}>
        {createUserField.isLoading ? <Loader overlay /> : null}
        <UserFieldForm userField={defaultUserField} onSave={onSave} />
      </Box>
    </CenterColumn>
  );
}
