import { Text, Panel, Button, Flex, Img } from "@coaching-culture/ui";
import { DownloadResource } from "@coaching-culture/types";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { typography, TypographyProps } from "styled-system";
import axios from "axios";
import { FaDownload } from "react-icons/fa";

const LinkButton = styled.button<TypographyProps>`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  font-size: inherit;
  ${typography};

  &:hover {
    text-decoration: underline;
  }

  & + &:before {
    content: "/";
    font-weight: 500;
    margin-left: 6px;
    margin-right: 6px;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  border: ${(props) => props.theme.borders[1]};
  border-radius: 6px;
  padding: ${(props) => props.theme.space[2]}px;
  font-size: ${(props) => props.theme.fontSizes[4]};
`;

export type FileExplorerPanelProps = {
  items: DownloadResource[];
};

const find = (
  pred: (item: DownloadResource) => boolean,
  items: DownloadResource[]
): DownloadResource | null => {
  const i = items.find(pred);

  if (i != null) {
    return i;
  }

  for (const x of items) {
    if (x.type === "folder") {
      const i = find(pred, x.children);
      if (i != null) {
        return i;
      }
    }
  }
};

export function FileExplorerPanel({ items }: FileExplorerPanelProps) {
  const [needle, setNeedle] = useState<DownloadResource | null>(null);

  const crumbs = useMemo(() => {
    let x = needle;
    const res = [];

    while (x != null) {
      res.unshift(x);
      const id = x.id;
      x = find(
        (i) => i.type === "folder" && i.children.some((c) => c.id === id),
        items
      );
    }

    return res;
  }, [needle, items]);

  const children = needle?.type === "folder" ? needle.children : items;

  const download = (item: DownloadResource) => {
    axios.get(`/api/downloads/${item.id}/download`).then(({ data }) => {
      if (data?.url != null) {
        window.location = data.url;
      } else {
        window.alert("There was a problem getting your download");
      }
    });
  };

  return (
    <Panel p={2}>
      <Breadcrumbs>
        <LinkButton onClick={() => setNeedle(null)}>Resources</LinkButton>
        {crumbs.map((x, i, self) => (
          <>
            <LinkButton
              onClick={() => setNeedle(x)}
              style={{ fontWeight: i === self.length - 1 ? 600 : 500 }}
            >
              {x.name}
            </LinkButton>
          </>
        ))}
      </Breadcrumbs>
      {children
        .filter((x) => x.type === "folder")
        .map((x) => (
          <Flex p={2} pl={0} pr={0} alignItems="center" borderBottom={1}>
            <Img
              maxWidth={100}
              maxHeight={100}
              src={process.env.REACT_APP_STATIC_ROOT + "/" + x.icon}
              mr={2}
            />
            <Flex flexDirection="column" alignItems="flex-start">
              <LinkButton
                onClick={() => setNeedle(x)}
                fontSize={4}
                fontWeight={600}
              >
                Category: {x.name}
              </LinkButton>
              <Text>{x.description}</Text>
            </Flex>
          </Flex>
        ))}
      {children
        .filter((x) => x.type === "file")
        .map((x) => (
          <Flex
            p={2}
            pl={0}
            pr={0}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Img
                maxWidth={100}
                maxHeight={100}
                src={process.env.REACT_APP_STATIC_ROOT + "/" + x.icon}
                mr={2}
              />
              <Flex flexDirection="column">
                <Text fontSize={4} fontWeight={500} color="black">
                  {x.name}
                </Text>
                <Text>{x.description}</Text>
              </Flex>
            </Flex>
            <Button
              color="primary"
              onClick={() => download(x)}
              icon={FaDownload}
              ml={3}
              style={{ flex: "0 0  auto" }}
            >
              Download
            </Button>
          </Flex>
        ))}
    </Panel>
  );
}
