import Papa from "papaparse";

export const downloadContent = (csvFile: string, filename: string) => {
  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  var link = document.createElement("a");
  var url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const saveExportAsCsv =
  (filename: string) => (data: Record<string, string>[]) => {
    const csv = Papa.unparse(data);

    downloadContent(csv, filename);
  };

export const ordinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Move item in array from one index to another
 *
 * @template T - The type of array
 * @param {T[]} array - The array
 * @param {number} oldIndex - The index to move
 * @param {number} newIndex - The new index for the item
 * @returns {T[]} A copy of the array with the moved item
 */
export function arrMove<T>(
  array: T[],
  oldIndex: number,
  newIndex: number
): T[] {
  if (newIndex >= array.length) {
    newIndex = array.length - 1;
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return [...array];
}
