import { DirectReportDto } from "@coaching-culture/types";
import { Button, Flex, Text } from "@coaching-culture/ui";
import { FaChartBar } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { animated, useSpring } from "react-spring";

import styled from "styled-components";
import { MeasuresUpdater } from "./MeasuresUpdater";

const SectionIcon = styled.div`
  color: white;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 3px;
`;

const Drawer = animated(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  overflow-y: auto;
`);

export type ReviewMeasuresDrawerProps = {
  onClose: () => void;
  user: DirectReportDto;
};

export const ReviewMeasuresDrawer = ({
  onClose,
  user,
}: ReviewMeasuresDrawerProps) => {
  const qc = useQueryClient();

  const styleProps = useSpring({
    y: "0%",
    from: {
      y: "100%",
    },
  });

  const close = () => {
    qc.invalidateQueries("team");
    onClose();
  };

  return (
    <Drawer style={styleProps}>
      <Flex p={"100px"} py={5} flexDirection="column">
        <Flex justifyContent={"center"}>
          <Flex alignItems={"center"} mb={5}>
            <SectionIcon>
              <FaChartBar fontSize={"1.5em"} />
            </SectionIcon>
            <Text fontWeight={600} ml={2}>
              Measures
            </Text>
          </Flex>
        </Flex>
        <Text fontSize={4} fontWeight={600} textAlign="center">
          Update measures for {user.name.split(" ")[0]}.
        </Text>
        <Text mb={5} fontWeight={500} color="grey1" textAlign="center">
          Regular feedback is important
        </Text>
        <MeasuresUpdater
          relationshipId={user.relationship.id}
          onUpdatedAll={close}
        />
        <Flex justifyContent={"center"} style={{ gap: 6 }}>
          <Button onClick={close}>Close</Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};
