import {
  Button,
  Flex,
  FormInput,
  FormSelect,
  Loader,
  Panel,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "../../auth";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PostUserSpec } from "@coaching-culture/types";
import { FaCheckCircle } from "react-icons/fa";

type DeliveryForm = {
  country: string;
  companyPostalName?: string;
  subscriptionType?: number;
  address1?: string;
  address2?: string;
  city?: string;
  county?: string;
  postcode?: string;
};

export const DeliverySettings = () => {
  const [loading, setLoading] = useState(true);
  const [user, , setUser] = useUser();
  const pushToast = useToast();

  const { register, handleSubmit, errors, reset, watch } =
    useForm<DeliveryForm>();

  useEffect(() => {
    reset(user);
    setLoading(false);
  }, [user, reset]);

  const onSubmit = (values: DeliveryForm) => {
    setLoading(true);
    const spec: PostUserSpec = {
      ...user,
      userFields: undefined,
      permissions: undefined,
      directReports: undefined,
      managers: undefined,
      groups: undefined,
      ...values,
    };

    console.log(spec);
    axios
      .put("/api/users/me", spec)
      .then(({ data }) => {
        setUser(data);
        pushToast({
          content: (
            <Flex alignItems="center" p={3} justifyContent="center">
              <FaCheckCircle color="green" size={"1.5em"} />
              <Text ml={2} fontWeight={600} fontSize={3}>
                Preferences Saved!
              </Text>
            </Flex>
          ),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        window.alert(err.response?.data ?? err);
      });
  };

  return (
    <Panel p={[3, 5]} pt={5}>
      {loading && <Loader overlay />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize={4} mb={3} style={{ borderBottom: "1px solid #ececec" }}>
          Delivery Preferences
        </Text>
        <FormSelect
          label="Location"
          name="country"
          ref={register}
          options={[
            { value: "", label: "Non-UK" },
            { value: "GB", label: "UK" },
          ]}
        />
        {watch("country") === "GB" ? (
          <>
            <FormSelect
              label="Subscription Type"
              name="subscriptionType"
              ref={register({ valueAsNumber: true })}
              options={[
                { value: "0", label: "Digital" },
                { value: "1", label: "Physical" },
              ]}
            />
            {watch("subscriptionType") === 1 ? (
              <>
                <FormInput
                  name="companyPostalName"
                  label="Company Name"
                  error={errors.companyPostalName}
                  ref={register()}
                />
                <FormInput
                  name="address1"
                  label="Address 1"
                  error={errors.address1}
                  ref={register()}
                />
                <FormInput
                  name="address2"
                  label="Address 2"
                  error={errors.address2}
                  ref={register()}
                />
                <FormInput
                  name="city"
                  label="City"
                  error={errors.city}
                  ref={register()}
                />
                <FormInput
                  name="county"
                  label="County"
                  error={errors.county}
                  ref={register()}
                />
                <FormInput
                  name="postcode"
                  label="Post Code"
                  error={errors.postcode}
                  ref={register()}
                  mb={4}
                />
              </>
            ) : null}
          </>
        ) : null}
        <Button color="primary" type="submit">
          Save
        </Button>
      </form>
    </Panel>
  );
};
