import {
  discriminate,
  GoalExportDto,
  UserField,
  UserFieldType,
} from "@coaching-culture/types";
import {
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useUserFields } from "queries/users";
import { useCallback, useEffect, useMemo, useState } from "react";
import { saveExportAsCsv } from "utils";
import { formatDate } from "utils/dates";

export const GoalExport = () => {
  const { data: userFields, isFetched } = useUserFields();
  const [data, setData] = useState<GoalExportDto[]>(null);
  const goalTerms = useGoalTerminology();

  const selectFields = (userFields || []).filter(
    discriminate("type", UserFieldType.Select),
  );

  useEffect(() => {
    axios
      .get(`/api/v2/goals/export`)
      .then(({ data }: { data: GoalExportDto[] }) => {
        setData(data);
      });
  }, []);

  const formatUserField = useCallback(
    (
      user: {
        id: string;
        value: string | number;
      }[],
      userFieldDef: UserField,
    ) => {
      const field = user?.find((uf) => uf.id === userFieldDef.id);
      if (field != null) {
        if (userFieldDef.type === UserFieldType.Select) {
          const selectField = selectFields.find(
            (f) => f.id === userFieldDef.id,
          );
          return selectField.options.find((option) => option.id === field.value)
            ?.name;
        } else {
          return field.value as string;
        }
      }
      return "";
    },
    [selectFields],
  );

  const getUserFieldCols = useMemo<SortableTableColumn<GoalExportDto>[]>(() => {
    return (
      userFields?.map((y) => ({
        label: userFields?.find((ufo) => ufo.id === y.id).name,
        name: y.id ?? "",
        sortFormat: (x: GoalExportDto) => {
          return formatUserField(x.userFields, y);
        },
        format: (x: GoalExportDto) => {
          return formatUserField(x.userFields, y);
        },
        exportFormat: (x: GoalExportDto) => {
          return formatUserField(x.userFields, y);
        },
      })) ?? []
    );
  }, [userFields, formatUserField]);

  const cols = useMemo((): SortableTableColumn<GoalExportDto>[] => {
    const cols: SortableTableColumn<GoalExportDto>[] = [
      {
        name: "name",
        label: "Name",
        format: (x) => x.userName,
        sortFormat: (x) => x.userName.toLowerCase(),
        exportFormat: (x) => x.userName,
      },
      {
        name: "email",
        label: "Email",
        format: (x) => x.userEmail,
        sortFormat: (x) => x.userEmail.toLowerCase(),
        exportFormat: (x) => x.userEmail,
      },
      {
        name: "goalName",
        label: `${goalTerms.goal.asTitle()} Name`,
        format: (x) =>
          x.isObjective
            ? data?.find((goal) => goal.id === x.parentId).purpose
            : x.purpose,
        sortFormat: (x) =>
          x.isObjective
            ? data?.find((goal) => goal.id === x.parentId).purpose
            : x.purpose,
        exportFormat: (x) =>
          x.isObjective
            ? data?.find((goal) => goal.id === x.parentId).purpose
            : x.purpose,
      },
      {
        name: "subGoalName",
        label: `${goalTerms.objective.asTitle()} Name`,
        format: (x) => (x.isObjective ? x.purpose : ""),
        sortFormat: (x) => (x.isObjective ? x.purpose : ""),
        exportFormat: (x) => (x.isObjective ? x.purpose : ""),
      },
      {
        name: "type",
        label: "Type",
        format: (x) => x.type,
        sortFormat: (x) => x.type,
        exportFormat: (x) => x.type,
      },
      {
        name: "lastUpdated",
        label: "Last Updated",
        props: { fontSize: "13px", color: "grey1" },
        format: (x) =>
          x.updatedOn == null
            ? "Never"
            : formatDate(x.updatedOn, "dd/MM/yyyy : HH:mm"),
        exportFormat: (x) =>
          x.updatedOn == null
            ? "Never"
            : formatDate(x.updatedOn, "dd/MM/yyyy HH:mm"),
        sortFormat: (x) =>
          x.updatedOn == null ? 0 : new Date(x.updatedOn).getTime(),
      },
      {
        name: "created",
        label: "Start",
        props: { fontSize: "13px", color: "grey1" },
        format: (x) => formatDate(x.createdOn, "dd/MM/yyyy HH:mm"),
        exportFormat: (x) => formatDate(x.createdOn, "dd/MM/yyyy HH:mm"),
        sortFormat: (x) => new Date(x.createdOn).getTime(),
      },
      {
        name: "completedOn",
        label: "Completed On",
        props: { fontSize: "13px", color: "grey1" },
        format: (x) =>
          x.completedOn == null
            ? "Never"
            : formatDate(x.completedOn, "dd/MM/yyyy HH:mm"),
        exportFormat: (x) =>
          x.completedOn == null
            ? "Never"
            : formatDate(x.completedOn, "dd/MM/yyyy HH:mm"),
        sortFormat: (x) =>
          x.completedOn == null ? 0 : new Date(x.completedOn).getTime(),
      },
      {
        name: "status",
        label: "Status",
        format: (x) => x.status,
        sortFormat: (x) => x.status,
        exportFormat: (x) => x.status,
      },
      {
        name: "outcome",
        label: "Outcome",
        format: (x) => x.outcome,
        sortFormat: (x) => x.outcome,
        exportFormat: (x) => x.outcome,
      },
      {
        name: "target",
        label: "Target",
        format: (x) => x.target,
        sortFormat: (x) => x.target,
        exportFormat: (x) => x.target.toString(),
      },
      {
        name: "progress",
        label: "Progress",
        format: (x) => x.progress,
        sortFormat: (x) => x.progress,
        exportFormat: (x) => x.progress.toString(),
      },
      {
        name: "associations",
        label: `${goalTerms.goal.asTitle()} Associations`,
        format: (x) => x.associations.map((x) => x.name).join(" | "),
        exportFormat: (x) => x.associations.map((x) => x.name).join(" | "),
      },
    ];

    return cols.concat(getUserFieldCols);
  }, [data, getUserFieldCols, goalTerms]);

  return (
    <CenterColumn>
      {!isFetched ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            text={`Export ${goalTerms.goal.asPluralTitle()} / ${goalTerms.objective.asPluralTitle()}`}
            subtitle={`Export and view ${goalTerms.goal.asPluralBody()} / ${goalTerms.objective.asPluralBody()} for all users`}
          />
          <Panel p={[2, 3]}>
            <SortableTable<GoalExportDto>
              columns={cols}
              data={data}
              defaultSort={1}
              rowKey="id"
              paged
              onExport={saveExportAsCsv("GoalsExport.csv")}
            />
          </Panel>
        </>
      )}
    </CenterColumn>
  );
};
