import { useUpdateOrgBranding } from "@coaching-culture/api-client";
import {
  Box,
  Button,
  ControlLabel,
  FormColorInput,
  IconToast,
  ImageUploader,
  Panel,
  useToast,
} from "@coaching-culture/ui";
import { useOrg } from "auth/OrgProvider";
import CenterColumn from "components/CenterColumn";
import { ExplainImage } from "components/ExplainImage";
import { PageHeader } from "components/PageHeader";
import { useState } from "react";
import { FaSave } from "react-icons/fa";
import brandingSrc from "../../../img/branding.svg";

export function BrandingConfiguration() {
  const [org] = useOrg();

  const [color, setColor] = useState(org.theme.primaryColor ?? "#48B2D2");
  const [logo, setLogo] = useState(org.theme.logo);
  const update = useUpdateOrgBranding();
  const pushToast = useToast();

  const save = async () => {
    await update.mutateAsync({ orgId: org.id, logo, primaryColor: color });
    pushToast({
      content: <IconToast icon={FaSave} text="Saved" iconColor="primary" />,
    });
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Branding"
        subtitle="Customise your platform experience"
      />
      <Panel p={[2, 3]} mb={3}>
        <ExplainImage
          src={brandingSrc}
          title={"Branding"}
          body={
            "Make your platform your own! Change the style settings to make your platform match your brand. This will make it more familiar to your users and enhance their experience. "
          }
        />
        <Box pt={3}>
          <FormColorInput
            label="Primary Colour"
            subLabel="The main accent colour used throughout the non-admin sections of the platform"
            value={color}
            onChange={(newValue) => setColor(newValue)}
            mb={2}
          />
          <ControlLabel>Logo</ControlLabel>
          <ImageUploader
            value={logo}
            onChange={(val) => setLogo(val)}
            height={200}
            width={400}
            allowReset
            mb={3}
          />
          <Button onClick={save} icon={FaSave} color="primary">
            Save Changes
          </Button>
        </Box>
      </Panel>
    </CenterColumn>
  );
}
