import { ActionDto, ActionItemSpec } from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getUserActions = async (userId?: string) =>
  userId == null
    ? []
    : await axios
        .get<ActionDto[]>(`/api/v2/users/${userId}/actions`)
        .then(({ data }) => data);

const removeAction = (actionId: string) =>
  axios.delete(`/api/v2/actions/${actionId}`);

const addAction = (spec: ActionItemSpec) => axios.post(`/api/v2/actions`, spec);

export const useUserActions = (userId?: string) =>
  useQuery(["actions", userId], () => getUserActions(userId));

export const useRemoveAction = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => removeAction(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
      queryClient.invalidateQueries(["actions"]);
    },
  });

  return mutation;
};

export const useAddAction = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
      queryClient.invalidateQueries(["actions"]);
    },
  });

  return mutation;
};
