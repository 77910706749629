import { Flex, Grid, Loader } from "@coaching-culture/ui";
import AddRelationshipModal from "pages/Solutions/AddRelationshipModal";
import { ConversationCommentsModal } from "pages/Solutions/ConversationCommentsModal";
import { useTeam } from "queries/performance";
import { useState } from "react";
import { AddTeamMemberButton } from "./AddTeamMemberButton";
import { TeamViewCard } from "./TeamViewCard";
import { TeamViewModal } from "./TeamViewModal";

export const TeamView = () => {
  const team = useTeam();
  const [active, setActive] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [viewPrivateNotes, setViewPrivateNotes] = useState("");

  if (team.isLoading) {
    return <Loader />;
  }

  const activeUser = team.data.directReports.find((x) => x.id === active);

  return (
    <>
      {active != null && (
        <TeamViewModal user={activeUser} onClose={() => setActive(null)} />
      )}
      {viewPrivateNotes !== "" && (
        <ConversationCommentsModal
          userId={viewPrivateNotes}
          onCancel={() => setViewPrivateNotes("")}
        />
      )}
      {showModal && (
        <AddRelationshipModal
          onClose={(newRelationship) => {
            if (newRelationship) {
              team.refetch();
            }
            setShowModal(false);
          }}
        />
      )}
      <Flex>
        <Grid
          gridTemplateColumns={"repeat(auto-fit, minmax(320px, 1fr))"}
          width="100%"
          gridGap={3}
        >
          {team.data.directReports.map((x) => (
            <TeamViewCard
              user={x}
              onSelect={() => setActive(x.id)}
              onViewPrivateNotes={() => setViewPrivateNotes(x.id)}
            />
          ))}
          <AddTeamMemberButton onClick={() => setShowModal(true)} />
        </Grid>
      </Flex>
    </>
  );
};
