import axios from "axios";
import styled from "styled-components";
import {
  Box,
  Loader,
  Panel,
  Text,
  Flex,
  Button,
  BarChart,
  StreamCollection,
} from "@coaching-culture/ui";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { format } from "date-fns";
import { FaChevronDown, FaChevronUp, FaPrint } from "react-icons/fa";
import ContentContainer from "./ContentContainer";
import {
  CompletedFormItem,
  ModuleAttempt,
  ModuleAttemptSummary,
  ScoreSet,
} from "@coaching-culture/types";
import ReactToPrint from "react-to-print";

export const ProfileBarChart = ({
  color,
  scores,
}: {
  color: string;
  scores: ScoreSet;
}) => {
  const data: StreamCollection[] = scores.groups.map((x) => ({
    label: x.name,
    streams: [{ label: "", value: x.score }],
  }));

  return (
    <BarChart xAxis="" yAxis="" streamLabels={[""]} color={color} data={data} />
  );
};

const Section = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
  padding: 18px;
`;

const ModuleIcon = styled.img`
  width: 28px;
  margin-right: 18px;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-size: 20px;
  cursor: pointer;
  border: 0;
  background: none;
`;

const attemptNumber = (
  attempt: ModuleAttemptSummary,
  attempts: ModuleAttemptSummary[]
) =>
  attempts
    .slice()
    .reverse()
    .filter((x) => x.module.id === attempt.module.id)
    .findIndex((x) => x.id === attempt.id) + 1;

const attemptCount = (
  attempt: ModuleAttemptSummary,
  attempts: ModuleAttemptSummary[]
) => attempts.filter((x) => x.module.id === attempt.module.id).length;

const printItem = (item: CompletedFormItem) => {
  if (item.type === "heading" || item.type === "content") {
    return null;
  } else if (item.type === "rating") {
    return (
      <Box mb={2}>
        <Text fontWeight={600} fontStyle="italic">
          {item.content}
        </Text>
        {item.rating != null && <Text>{item.rating}</Text>}
      </Box>
    );
  } else {
    return (
      <Box mb={2}>
        <Text fontWeight={600} fontStyle="italic">
          {item.content}
        </Text>
        {item.answer && <Text>{item.answer}</Text>}
      </Box>
    );
  }
};

const MyMindset = () => {
  const [attempts, setAttempts] = useState<ModuleAttemptSummary[] | null>(null);
  const [shownAttempt, setShownAttempt] = useState<string | null>(null);
  const [shownAttemptData, setShownAttemptData] =
    useState<ModuleAttempt | null>(null);
  const outcomesRef = useRef();

  useEffect(() => {
    axios.get("/api/mindset/attempts").then(({ data }) => {
      setAttempts(data);
    });
  }, []);

  useEffect(() => {
    setShownAttemptData(null);
    if (shownAttempt != null) {
      axios.get(`/api/mindset/attempts/${shownAttempt}`).then(({ data }) => {
        setShownAttemptData(data);
      });
    }
  }, [shownAttempt]);

  const showAttempt = (attempt: ModuleAttemptSummary) => {
    if (shownAttempt === attempt.id) {
      setShownAttempt(null);
    } else {
      setShownAttempt(attempt.id);
    }
  };

  const loading = attempts == null;

  return (
    <Panel mt={5}>
      {loading ? (
        <Loader />
      ) : attempts.length === 0 ? (
        <Box p={3}>
          <Text textAlign="center" fontWeight={600}>
            You haven't attempted any Mindset Modules yet.
          </Text>
        </Box>
      ) : (
        attempts.map((attempt) => (
          <div>
            <Section>
              <Flex
                alignItems={["stretch", "center"]}
                flexDirection={["column", "row"]}
              >
                <Flex alignItems="center" mb={[1, 0]} flex="1">
                  <ModuleIcon
                    src={
                      process.env.REACT_APP_STATIC_ROOT +
                      "/" +
                      attempt.module.icon
                    }
                  />
                  <Text fontWeight={600}>
                    {attempt.module.name.toUpperCase()}
                  </Text>
                </Flex>
                <Flex alignItems="stretch" flexDirection="column" flex="1">
                  <Text>
                    {attempt.completed ? (
                      <>
                        <strong>Completed: </strong>
                        {`${format(new Date(attempt.completed), "dd.MM.yyyy")}`}
                      </>
                    ) : (
                      <strong>Not Completed</strong>
                    )}
                  </Text>
                  <Text>
                    <strong>Attempt: </strong>
                    {`${attemptNumber(attempt, attempts)}/${attemptCount(
                      attempt,
                      attempts
                    )}`}
                  </Text>
                </Flex>
                <IconButton onClick={() => showAttempt(attempt)}>
                  {shownAttempt === attempt.id ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </IconButton>
              </Flex>
            </Section>
            {shownAttempt === attempt.id ? (
              <>
                {shownAttemptData == null ? (
                  <Section>
                    <Loader />
                  </Section>
                ) : (
                  <>
                    <Section>
                      <Flex justifyContent="flex-end">
                        <ReactToPrint
                          trigger={() => (
                            <Button color="primary" icon={FaPrint}>
                              Print
                            </Button>
                          )}
                          content={() => outcomesRef.current}
                        />
                      </Flex>
                    </Section>
                    <div ref={outcomesRef}>
                      <Section>
                        <Text fontWeight={600} mb={3}>
                          PROFILE OUTCOME
                        </Text>
                        {shownAttemptData.profiles == null ? (
                          <Text color="grey3">No Profile yet</Text>
                        ) : (
                          shownAttemptData.profiles.map((x) => (
                            <>
                              {x.showAssessmentResults === "form-groups" && (
                                <Box maxWidth={1000} ml="auto" mr="auto">
                                  <ProfileBarChart
                                    color={shownAttemptData.module.mainColor}
                                    scores={shownAttemptData.assessmentScores}
                                  />
                                </Box>
                              )}
                              <ContentContainer content={x.content} />
                            </>
                          ))
                        )}
                      </Section>
                      <Section>
                        <Text fontWeight={600} mb={3}>
                          SELF-REFLECTION
                        </Text>
                        {shownAttemptData.assessment == null ? (
                          <Text color="grey3">
                            Self Assessment Not Yet Completed
                          </Text>
                        ) : (
                          shownAttemptData.assessment.items.map(printItem)
                        )}
                      </Section>
                      <Section>
                        <Text fontWeight={600} mb={3}>
                          PROGRAMME
                        </Text>
                        {shownAttemptData.programmeAnswers == null ? (
                          <Text color="grey3">
                            {shownAttemptData.isLegacyAttempt
                              ? "Some data from previous platform versions is not currently available"
                              : "Programme not yet completed"}
                          </Text>
                        ) : shownAttemptData.programmeAnswers.length === 0 ? (
                          <Text color="grey3">No Questions In Programme</Text>
                        ) : (
                          shownAttemptData.programmeAnswers.map((item) => (
                            <Box mb={2}>
                              <Text fontWeight={600} fontStyle="italic">
                                {item.question}
                              </Text>
                              <Text>{item.answer}</Text>
                            </Box>
                          ))
                        )}
                      </Section>
                      <Section>
                        <Text fontWeight={600} mb={3}>
                          CHALLENGE
                        </Text>
                        {shownAttemptData.challenge == null ? (
                          <Text color="grey3">
                            {shownAttemptData.isLegacyAttempt
                              ? "Some data from previous platform versions is not currently available"
                              : "Challenge not yet completed"}
                          </Text>
                        ) : (
                          shownAttemptData.challenge.items.map(printItem)
                        )}
                      </Section>
                    </div>
                  </>
                )}{" "}
              </>
            ) : null}
          </div>
        ))
      )}
    </Panel>
  );
};

export default MyMindset;
