import jschardet from "jschardet";
import {
  hasDuplicates,
  UserField,
  UserFieldType,
  UserGroup,
} from "@coaching-culture/types";
import {
  AddButton,
  Alert,
  Box,
  Button,
  CheckBox,
  Circle,
  Flex,
  IconButton,
  Loader,
  Panel,
  Select,
  Table,
  Text,
} from "@coaching-culture/ui";
import Axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import Papa from "papaparse";
import { uniq } from "lodash";
import { lighten } from "polished";
import React, { useEffect, useMemo, useState } from "react";
import { FaExclamationTriangle, FaFileUpload, FaTimes } from "react-icons/fa";
import styled from "styled-components";

type BulkImportResult = {
  existing: string[];
  inserted: string[];
  failed: string[];
};

const UploadBox = styled(Flex)`
  border: 3px dashed ${(props) => props.theme.colors.primary};
  border-radius: 6px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => lighten(0.44, props.theme.colors.primary)};
`;

const FileButton = styled.label`
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 24px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
  }

  & input {
    display: none;
  }
`;

type CsvLine = Record<string, string>;

const associationTypes = [
  {
    name: "email",
    label: "Email",
    regex: /^email/i,
  },
  {
    name: "name",
    label: "Name",
    regex: /^name/i,
  },
  {
    name: "firstName",
    label: "First Name",
    regex: /^(first|given)\s?(name)?/i,
  },
  {
    name: "lastName",
    label: "Last Name",
    regex: /^(surname|last\s?name)|last/i,
  },
  {
    name: "role",
    label: "Platform Access",
    regex: /^(role)/i,
  },
  {
    name: "manager",
    label: "Manager",
    regex: /^(manager)/i,
  },
];

function getDefaultAssociations(data: CsvLine) {
  const keys = Object.keys(data);
  return associationTypes
    .map((x) => ({
      name: x.name,
      field: keys.find((k) => x.regex.test(k)),
      userFieldId: null,
    }))
    .filter((x) => x.field != null);
}

type Association = {
  name: string;
  field: string;
  userFieldId?: string | null;
};

const validRoles = ["user", "manager", "org-admin"];

const getGroups = (item: CsvLine, groupFields: string[]) => {
  return groupFields
    .map((x) => item[x])
    .filter((x) => x != null && x !== "")
    .map((x) => x.trim());
};

export default function BulkUpload() {
  const [data, setData] = useState<CsvLine[] | null>(null);
  const [associations, setAssociations] = useState<Association[]>([]);
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [groupFields, setGroupFields] = useState<string[]>([]);
  const [userFields, setUserFields] = useState<UserField[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<BulkImportResult | null>(null);

  useEffect(() => {
    Axios.get("/api/groups").then(({ data }) => setGroups(data));
    Axios.get("/api/user-fields").then(({ data }) => setUserFields(data));
  }, []);

  const handleFileChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files[0];
    if (file == null) {
      return window.alert("Unable to read file");
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const csvResult = e.target.result as string;
      const enc = jschardet.detect(csvResult).encoding;
      Papa.parse<CsvLine>(file, {
        header: true,
        encoding: enc,
        skipEmptyLines: true,
        complete: (data) => {
          if (data.errors.length > 0) {
            ev.target.value = "";
            window.alert(
              "There was an error reading the CSV:" + data.errors[0].message
            );
          } else {
            setData(data.data);
            setAssociations(getDefaultAssociations(data.data[0]));
          }
        },
      });
    };
    reader.readAsBinaryString(file);
  };

  const setAcc = (type: string, value: string) => {
    setAssociations((old) =>
      old
        .filter((x) => x.name !== type)
        .concat(value === "" ? [] : [{ name: type, field: value }])
    );
  };

  const setUserField = (id: string, value: string) => {
    setAssociations((old) =>
      old
        .filter((x) => x.userFieldId !== id)
        .concat(
          value === "" ? [] : [{ name: "", field: value, userFieldId: id }]
        )
    );
  };

  const getName = (
    item: CsvLine
  ): { firstName: string | null; lastName: string | null } => {
    const acc = associations.find((x) => x.name === "name");
    if (acc == null) {
      const fnAcc = associations.find((x) => x.name === "firstName");
      const lnAcc = associations.find((x) => x.name === "lastName");

      if (fnAcc == null && lnAcc == null) {
        return {
          firstName: null,
          lastName: null,
        };
      }

      let firstName = "";
      let lastName = "";

      if (fnAcc != null) {
        firstName = item[fnAcc.field] || "";
      }
      if (lnAcc != null) {
        lastName = item[lnAcc.field] || "";
      }
      return { firstName, lastName };
    } else {
      const s = (item[acc.field] || "").split(" ");
      return {
        firstName: s[0],
        lastName: s[1],
      };
    }
  };

  const getEmail = (item: CsvLine) => {
    const acc = associations.find((x) => x.name === "email");
    if (acc == null) {
      return "<Unknown>";
    } else {
      return item[acc.field];
    }
  };

  const getManager = (item: CsvLine) => {
    const acc = associations.find((x) => x.name === "manager");
    if (acc == null) {
      return null;
    } else {
      return item[acc.field]?.trim() || null;
    }
  };

  const getRole = (item: CsvLine) => {
    const acc = associations.find((x) => x.name === "role");
    if (acc != null) {
      const r = (item[acc.field] ?? "").toLowerCase();
      if (validRoles.includes(r)) {
        return r;
      }
    }
    return "<Not Set>";
  };

  const invalidUserFields = useMemo(() => {
    const isValidValue = (uf: UserField, value: string) => {
      if (value.trim() === "") {
        return true;
      } else if (uf.type === UserFieldType.Bool) {
        return ["yes", "no", "0", "1", "true", "false"].includes(
          value.toLowerCase()
        );
      } else if (uf.type === UserFieldType.Select) {
        return uf.options
          .map((x) => x.name)
          .map((x) => x.toLowerCase())
          .includes(value.toLowerCase());
      }
    };

    const getValidValues = (uf: UserField) => {
      if (uf.type === UserFieldType.Bool) {
        return ["0", "1", "no", "yes", "false", "true"];
      } else if (uf.type === UserFieldType.Select) {
        return uf.options.map((x) => x.name);
      }
    };

    return associations
      .filter((x) => x.userFieldId != null)
      .map((x) => ({
        field: x.field,
        uf: userFields.find((u) => u.id === x.userFieldId),
      }))
      .filter((x) => x.uf.type > 0)
      .map((x) => ({
        ...x,
        invalidValues: uniq(data.map((u) => u[x.field])).filter(
          (v) => !isValidValue(x.uf, v)
        ),
        validValues: getValidValues(x.uf),
      }))
      .filter((x) => x.invalidValues.length > 0);
  }, [data, associations, userFields]);

  const invalidGroups = useMemo(() => {
    if (data == null || groups == null) {
      return [];
    }
    const allGroups = uniq(data.flatMap((x) => getGroups(x, groupFields)));
    const groupNames = groups.map((x) => x.name);

    return allGroups.filter((x) => !groupNames.includes(x));
  }, [groups, data, groupFields]);

  const invalidRoles = useMemo(() => {
    if (data == null) {
      return [];
    }
    const acc = associations.find((x) => x.name === "role");

    if (acc == null) {
      return [];
    }

    const roles = uniq(
      data.map((item) => (item[acc.field] ?? "").toLowerCase())
    ).filter((x) => x !== "");

    return roles.filter((x) => !validRoles.includes(x));
  }, [data, associations]);

  const submit = () => {
    const spec = {
      sendEmail: sendEmail,
      users: data.map((x) => ({
        email: getEmail(x),
        manager: getManager(x),
        role: getRole(x),
        ...getName(x),
        groups: getGroups(x, groupFields)
          .map((x) => groups.find((g) => g.name.trim() === x)?.id)
          .filter((x) => x != null),
        userFields: associations
          .filter((x) => x.userFieldId != null)
          .map((a) => {
            const uf = userFields.find((u) => u.id === a.userFieldId);
            const value =
              uf.type === UserFieldType.Bool
                ? ["yes", "true", "1"].includes(x[a.field].toLowerCase())
                : uf.type === UserFieldType.FreeText
                ? x[a.field]
                : uf.options.find((u) => {
                    console.log(u.name, x[a.field]);
                    return u.name.toLowerCase() === x[a.field].toLowerCase();
                  })?.id;
            return {
              id: uf.id,
              value,
            };
          })
          .filter((x) => x.value != null),
      })),
    };

    const emails = spec.users.map((x) => x.email);

    if (hasDuplicates(emails)) {
      window.alert("CSV has duplicate emails. Emails must be unique");
      return;
    }

    if (emails.some((x) => x.trim().includes(" "))) {
      window.alert("Email addresses cannot contain spaces");
      return;
    }

    if (emails.some((x) => !x.includes("@"))) {
      window.alert("Email addresses must contain '@'");
      return;
    }

    const managers = spec.users.map((x) => x.manager).filter((x) => x != null);

    if (managers.some((x) => !x.includes("@"))) {
      window.alert("Manager field must be empty or an email address");
      return;
    }

    if (spec.users.some((x) => x.email === x.manager)) {
      window.alert("A user cannot be their own manager.");
      return;
    }

    setLoading(true);
    Axios.post("/api/users/bulk", spec).then(({ data }) => {
      setResult(data);
      setLoading(false);
    });
  };

  const associationOptions = [
    {
      value: "",
      label: "<Unset>",
    },
    ...Object.keys((data || [])[0] || {}).map((x) => ({
      value: x,
      label: x,
    })),
  ];

  const setGroupField = (i: number, val: string) => {
    setGroupFields((old) => {
      old[i] = val;
      return [...old];
    });
  };

  const getUserFieldValue = (u: any, userFieldId: string) => {
    const ass = associations.find((x) => x.userFieldId === userFieldId);
    return u[ass.field];
  };

  const addGroupField = () => {
    setGroupFields((old) => [...old, ""]);
  };

  const removeGroup = (idx: number) => {
    setGroupFields((old) => old.filter((_, i) => i !== idx));
  };

  const emailSet = associations.find((x) => x.name === "email") != null;
  const nameSet = associations.find((x) => x.name === "name") != null;
  const fnSet = associations.find((x) => x.name === "firstName") != null;
  const lnSet = associations.find((x) => x.name === "lastName") != null;

  const canSubmit =
    emailSet && ((nameSet && !fnSet && !lnSet) || (!nameSet && fnSet && lnSet));

  return (
    <CenterColumn>
      <PageHeader
        text="Bulk User Upload"
        subtitle="Upload a CSV of a large number of users quickly"
        backUrl="/success/people/users"
        helpIdent="bulk-main"
      />
      <Panel p={3}>
        {loading && <Loader overlay />}
        {result != null ? (
          <>
            <Flex alignItems="center" justifyContent="space-between" mb={3}>
              <Text fontWeight={600} fontSize={4}>
                Import Results
              </Text>
              <Button to="/success/people/users">Return to Users</Button>
            </Flex>
            {result.failed.length > 0 && (
              <>
                <Text fontSize={4} color="danger">
                  Failed ({result.existing.length})
                </Text>
                <ul>
                  {result.failed.map((x) => (
                    <li>
                      {x} - User Exists in another Organisation. Contact
                      Support.
                    </li>
                  ))}
                </ul>
              </>
            )}
            {result.existing.length > 0 && (
              <>
                <Text fontSize={4}>Updated ({result.existing.length})</Text>
                <ul>
                  {result.existing.map((x) => (
                    <li>{x} - User Updated</li>
                  ))}
                </ul>
              </>
            )}
            <Text fontSize={4}>Users Imported ({result.inserted.length})</Text>
            <ul>
              {result.inserted.map((x) => (
                <li>{x} - Imported</li>
              ))}
            </ul>
          </>
        ) : data == null ? (
          <UploadBox
            justifyContent="center"
            alignItems="center"
            p={5}
            flexDirection="column"
          >
            <Circle icon={FaFileUpload} size="xlarge" color="primary" mb={3} />
            <Text fontWeight={600} fontSize={4} textAlign="center">
              Upload CSV
            </Text>
            <Text mb={4} width={300} textAlign="center">
              Don't worry about the field names, you'll be able to match your
              fields to ours.
            </Text>
            <Flex alignItems="center">
              <Button mr={2} href="/ExampleImport.csv">
                Example CSV
              </Button>
              <FileButton as="label">
                Select CSV File
                <input type="file" onChange={handleFileChange} />
              </FileButton>
            </Flex>
          </UploadBox>
        ) : (
          <div>
            <Box mb={3} width={600}>
              <Text mb={3} fontWeight={600} fontSize={4}>
                Field Associations
              </Text>
              {associationTypes.map((x) => {
                const set = associations.find((a) => a.name === x.name);
                return (
                  <Flex alignItems="center" mb={1}>
                    <Text mr={2} fontWeight={600} width={150}>
                      {x.label}:
                    </Text>
                    <Select
                      style={{ flex: 1 }}
                      value={set?.field || ""}
                      onChange={(ev) => setAcc(x.name, ev.target.value)}
                      options={associationOptions}
                    ></Select>
                  </Flex>
                );
              })}
              {userFields.map((x) => {
                const set = associations.find((a) => a.userFieldId === x.id);
                return (
                  <Flex alignItems="center" mb={1}>
                    <Text mr={2} fontWeight={600} width={150}>
                      {x.name}:
                    </Text>
                    <Select
                      style={{ flex: 1 }}
                      value={set?.field || ""}
                      onChange={(ev) => setUserField(x.id, ev.target.value)}
                      options={associationOptions}
                    ></Select>
                  </Flex>
                );
              })}
              <Flex mb={3}>
                <Flex height={38} alignItems="center" width={150} mr={2}>
                  <Text fontWeight={600}>Groups:</Text>
                </Flex>
                <Flex flexDirection="column" flex="1">
                  {groupFields.map((x, i) => (
                    <Flex flex={1} alignItems="center" mb={1}>
                      <Select
                        value={x}
                        onChange={(ev) => setGroupField(i, ev.target.value)}
                        options={associationOptions}
                      />
                      <IconButton
                        ml={1}
                        mr={1}
                        icon={FaTimes}
                        color="danger"
                        onClick={() => removeGroup(i)}
                      />
                    </Flex>
                  ))}
                  <AddButton width="100%" p={1} onClick={addGroupField}>
                    Add Group Field
                  </AddButton>
                </Flex>
              </Flex>
            </Box>
            {nameSet && (fnSet || lnSet) && (
              <Alert color="danger" icon={FaExclamationTriangle} mb={3}>
                Set either name <strong>or</strong> first/last name, not both.
              </Alert>
            )}
            {invalidUserFields.map((x) => (
              <Alert color="danger" icon={FaExclamationTriangle} mb={3}>
                <Text>
                  The following values are invalid for the user field{" "}
                  <strong>{x.uf.name}</strong> and will be ignored:{" "}
                  <strong>{x.invalidValues.join(", ")}</strong>
                </Text>
                <Text>
                  The following values are valid:{" "}
                  <strong>{x.validValues.join(", ")}</strong>
                </Text>
              </Alert>
            ))}
            {invalidGroups.length > 0 && (
              <Alert color="danger" icon={FaExclamationTriangle} mb={3}>
                The following groups in your CSV are invalid. They will not be
                imported: <strong>{invalidGroups.join(", ")}</strong>
              </Alert>
            )}
            {invalidRoles.length > 0 && (
              <Alert color="danger" icon={FaExclamationTriangle} mb={3}>
                <Text>
                  The following platform accesses in your CSV are invalid. The
                  users will be imported without those access rights:{" "}
                  <strong>{invalidRoles.join(", ")}</strong>
                </Text>
                <Text>
                  The following values are valid:{" "}
                  <strong>{validRoles.join(", ")}</strong>. Blank values will
                  default to <strong>user</strong>.
                </Text>
              </Alert>
            )}
            {!canSubmit && (
              <Alert color="danger" icon={FaExclamationTriangle} mb={3}>
                Assign email and name fields before submitting
              </Alert>
            )}

            <Text mb={2} fontWeight={600} fontSize={4}>
              Sample Data
            </Text>
            <Table mb={3}>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Manager</th>
                  <th>Platform Access</th>
                  <th>Groups</th>
                  {associations
                    .filter((x) => x.userFieldId != null)
                    .map((x) => userFields.find((u) => u.id === x.userFieldId))
                    .map((x) => (
                      <th>{x.name}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data.slice(0, 10).map((x) => (
                  <tr>
                    <td>{getEmail(x)}</td>
                    <td>{getName(x).firstName ?? "<Unknown>"}</td>
                    <td>{getName(x).lastName ?? "<Unknown>"}</td>
                    <td>{getManager(x) ?? "<Not Set>"}</td>
                    <td>{getRole(x)}</td>
                    <td>{getGroups(x, groupFields).join(", ") || "None"}</td>
                    {associations
                      .filter((x) => x.userFieldId != null)
                      .map((x) =>
                        userFields.find((u) => u.id === x.userFieldId)
                      )
                      .map((u) => (
                        <td>{getUserFieldValue(x, u.id)}</td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Text mb={1} fontWeight={600} fontSize={4}>
              Send Emails?
            </Text>
            <Text mb={2}>
              Would you like to send a welcome email to any new user included on
              your upload? This email will contain their password. If you select
              no, new users won’t be able to log in until you generate a
              password for them.
            </Text>
            <div>
              <CheckBox
                value={sendEmail}
                name="sendemail"
                onChange={(val) => setSendEmail(val)}
                label="Send Emails?"
                mb={3}
              />
            </div>
            <Button onClick={submit} disabled={!canSubmit} color="primary">
              Import Users
            </Button>
          </div>
        )}
      </Panel>
    </CenterColumn>
  );
}
