import { UserFilter } from "@coaching-culture/types";
import { Box, Button, Loader, Text } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { FilterModal } from "components/FilterPanel";
import { PageHeader } from "components/PageHeader";
import { sortBy, uniqBy } from "lodash";
import { useAvailableContent } from "queries/mindset";
import { useMemo, useState } from "react";
import { ContentReport } from "./ContentReport";
import { ContentSummaryCharts } from "./ContentSummaryCharts";
import { MatrixReport } from "./MatrixReport";
import { SummaryReport } from "./SummaryReport";

const LessonsReport = () => {
  const content = useAvailableContent();
  const loading = content.isLoading;
  const [showFilters, setShowFilters] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<string | null>(
    "summary",
  );
  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });

  const lessons = useMemo(
    () => (content.data ?? []).filter((x) => x.type === "lesson"),
    [content.data],
  );

  const noData = content.isFetched && lessons.length === 0;

  const isMatrix =
    selectedLesson != null &&
    (selectedLesson.startsWith("cat") || selectedLesson === "all");

  const selectedCat = selectedLesson?.startsWith("cat-")
    ? selectedLesson.replace("cat-", "")
    : "";

  const catLessons = useMemo(
    () =>
      sortBy(
        lessons.filter(
          (x) => selectedLesson === "all" || x.category.id === selectedCat,
        ),
        (x) => [x.category.id, x.orderIndex],
      ),
    [selectedCat, selectedLesson, lessons],
  );

  const cats = uniqBy(
    lessons.map((x) => x.category),
    "id",
  );

  const lessonOptions = [
    { value: "summary", label: "Summary By Lesson" },
    { value: "all", label: "All Lessons" },
    ...sortBy(cats, "orderIndex").flatMap((x) => [
      { label: `${x.name} (Summary)`, value: `cat-${x.id}` },
      ...sortBy(
        lessons.filter((l) => l.category.id === x.id),
        "orderIndex",
      ).map((l) => ({
        label: `${x.name} / ${l.name}`,
        value: `les-${l.id}`,
      })),
    ]),
  ];

  if (noData) {
    return (
      <CenterColumn>
        <PageHeader
          text="Insights"
          subtitle="An overview of the data collected by the platform"
        />
        <Box>
          <Text textAlign="center" fontSize={4}>
            No Topics Available
          </Text>
        </Box>
      </CenterColumn>
    );
  }

  const commonProps = {
    options: lessonOptions,
    selected: selectedLesson,
    filter,
    type: "lesson",
    onChange: setSelectedLesson,
    onFilterChange: setFilter,
  } as const;

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        filter={filter}
        onClose={(result) => {
          if (result != null) {
            setFilter(result);
          }
          setShowFilters(false);
        }}
      />
      <CenterColumn>
        <PageHeader
          text="Insights"
          subtitle="An overview of the data collected by the platform"
        >
          <Button
            ml={[0, 2]}
            color="primary"
            onClick={() => setShowFilters(true)}
          >
            Filters ({filter.groups.length + filter.userFields.length})
          </Button>
        </PageHeader>
        <Box mb={3}>
          <Text mb={2} fontSize={5}>
            Summary
          </Text>
          <ContentSummaryCharts type="lesson" filter={filter} />
        </Box>
        <Box>
          <Text mb={2} fontSize={5}>
            Lessons
          </Text>
          {selectedLesson == null ? (
            <Loader />
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : selectedLesson === "summary" ? (
                <SummaryReport {...commonProps} />
              ) : isMatrix ? (
                <MatrixReport {...commonProps} content={catLessons} />
              ) : (
                <ContentReport
                  {...commonProps}
                  contentId={selectedLesson.replace("les-", "")}
                />
              )}
            </>
          )}
        </Box>
      </CenterColumn>
    </>
  );
};

export default LessonsReport;
