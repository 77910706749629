import { UserDisplay } from "@coaching-culture/types";
import {
  Box,
  Flex,
  LinkButton,
  Text,
  UserProfileImage,
} from "@coaching-culture/ui";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SpaceProps } from "styled-system";

const StyledLink = styled(Link)`
  font-weight: 500;
`;

export type UserFlagProps = {
  user: UserDisplay | null;
  to?: string;
  onClick?: () => void;
} & SpaceProps;

export function UserFlag({ user, to, onClick, ...rest }: UserFlagProps) {
  return (
    <Flex alignItems="center" {...rest}>
      <UserProfileImage
        mr={2}
        profileImage={user?.profileImage}
        name={user?.name ?? "?"}
      />
      <Box>
        {to != null ? (
          <StyledLink to={to} onClick={onClick}>
            {user?.name ?? "Unknown User"}
          </StyledLink>
        ) : onClick != null ? (
          <LinkButton onClick={onClick}>
            {user?.name ?? "Unknown User"}
          </LinkButton>
        ) : (
          <Text fontWeight={500}>{user?.name ?? "Unknown User"}</Text>
        )}
        <Text fontSize="80%" color="grey2">
          {user?.email ?? ""}
        </Text>
      </Box>
    </Flex>
  );
}
