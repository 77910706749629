import {
  Box,
  Button,
  Flex,
  Loader,
  Modal,
  PanelFooter,
  PanelHeader,
  Text,
  UserFlag,
} from "@coaching-culture/ui";
import { useTeam } from "queries/performance";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaGripVertical } from "react-icons/fa";
import { arrMove } from "utils";
import { getDndItemStyle } from "utils/dragAndDropUtil";

export type ReorderTeamModalProps = {
  onClose: () => void;
  onReorder: (relationshipIds: string[]) => void;
};

export const ReorderTeamModal = ({
  onClose,
  onReorder,
}: ReorderTeamModalProps) => {
  const myTeam = useTeam();

  const [ids, setIds] = useState<string[] | null>(null);

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const ids = arrMove(
      myTeam.data.directReports,
      result.source.index,
      result.destination.index,
    ).map((x) => x.relationship.id);
    setIds(ids);
  };

  const onComplete = () => {
    if (ids == null) {
      onClose();
    } else {
      onReorder(ids);
    }
  };

  const teamData = myTeam.data?.directReports ?? [];

  return (
    <Modal onClose={onClose} showCloseButton width={500} maxHeight={600}>
      <PanelHeader>
        <Text fontSize={4} fontWeight={600}>
          Reorder Team
        </Text>
      </PanelHeader>
      <Box p={3} pb={2} overflowY="auto">
        {myTeam.isLoading ? (
          <Loader />
        ) : (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {teamData.map((x, i) => (
                      <Draggable
                        key={x.relationship.id}
                        draggableId={x.relationship.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <Flex
                            p="6px 12px"
                            border={1}
                            borderRadius="6px"
                            alignItems="center"
                            justifyContent={"space-between"}
                            mb={1}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDndItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <Flex alignItems="center">
                              <Flex
                                {...provided.dragHandleProps}
                                alignItems="center"
                              >
                                <FaGripVertical color="#999" />
                              </Flex>
                              <UserFlag user={x} ml={2} />
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </Box>
      <PanelFooter p={3}>
        <Button color="primary" mr={2} onClick={onComplete}>
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </PanelFooter>
    </Modal>
  );
};
