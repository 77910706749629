import {
  useCancelConversation,
  useCancelConversationOccurrence,
  useConversations,
  useRescheduleConversation,
} from "@coaching-culture/api-client";
import {
  ConversationDto,
  ConversationOccurrenceDto,
  RecurrenceType,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Circle,
  Flex,
  FormInput,
  Loader,
  MiniToggle,
  Panel,
  PanelHeader,
  ReactModal,
  Rule,
  Select,
  Text,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { CustomCalendarComponent } from "components/CalendarAltStyle";
import DotMenuText from "components/DotMenuText";
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  fromUnixTime,
  getDate,
  getDay,
  getUnixTime,
  getWeek,
  isThisWeek,
  isToday,
  isYesterday,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { groupBy, negate, sortBy } from "lodash";
import { useTeam } from "queries/performance";
import { useMyRelationships } from "queries/team";
import { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  FaArrowCircleRight,
  FaCalendar,
  FaCommentDots,
  FaMapMarker,
  FaPlusCircle,
  FaSmile,
  FaTrash,
} from "react-icons/fa";
import { MdRepeat } from "react-icons/md";
import styled from "styled-components";
import { ordinal } from "utils";
import { formatDate, getDayName } from "utils/dates";
import { CancelConversationModal } from "./CancelConversationModal";

const DateContainer = styled.div<{ completed: boolean; cancelled: boolean }>`
  padding-left: 24px;
  margin-left: 3px;
  padding-bottom: 12px;
  position: relative;

  &:before {
    content: "";
    display: block;
    background-color: ${(props) =>
      props.completed
        ? props.theme.colors.positive
        : props.cancelled
          ? props.theme.colors.danger
          : props.theme.colors.primary};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
    left: -9px;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    background-color: ${(props) => props.theme.colors.grey3};
    height: calc(100% - 2px);
    position: absolute;
    left: 0;
    top: 6px;
  }
`;

const isUpcoming = (convo: { scheduledDate: number }) =>
  startOfToday() < fromUnixTime(convo.scheduledDate);

const humanizeDate = (value: Date) => {
  if (isYesterday(value)) {
    return "Yesterday";
  } else if (isToday(value)) {
    return "Today";
  } else if (isThisWeek(value)) {
    return "This Week";
  } else if (getWeek(new Date()) - getWeek(value) === 1) {
    return "Last Week";
  } else if (getWeek(new Date()) - getWeek(value) === -1) {
    return "Next Week";
  } else {
    return format(value, "MMMM yyyy");
  }
};

type ConvoAndInstance = ConversationDto & ConversationOccurrenceDto;

const groupConvos = (data: ConvoAndInstance[]) => {
  const entries = Object.entries(
    groupBy(data, (x) => humanizeDate(fromUnixTime(x.scheduledDate))),
  );

  return sortBy(entries, (x) => x[1][0].scheduledDate).map((x) => ({
    label: x[0],
    items: x[1],
  }));
};

function Convo({ conversation }: { conversation: ConvoAndInstance }) {
  const [user] = useUser();
  const x = conversation;
  const [cancelling, setCancelling] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const rescheduleConvo = useRescheduleConversation(
    conversation.id,
    conversation.index,
  );
  const cancelConversation = useCancelConversation(conversation.id);
  const cancelConversationOccurence = useCancelConversationOccurrence(
    conversation.id,
    conversation.index,
  );
  const [date, setDate] = useState<Date>(new Date());
  const [location, setLocation] = useState(conversation.location);
  const [duration, setDuration] = useState(conversation.duration);

  const otherUser =
    conversation.manager.id === user.id
      ? conversation.directReport
      : conversation.manager;

  const withManager = user.id === conversation.directReport.id;

  const isComplete = conversation.completedOn != null;
  const isCancelled =
    conversation.occurences[conversation.index] != null
      ? conversation.occurences[conversation.index].cancelledOn != null
      : conversation.cancelledOn != null;
  const isManager = conversation.manager.id === user.id;
  const isOwner = user.id === conversation.createdBy;

  const reschedule = async () => {
    if (getUnixTime(date) === conversation.scheduledDate) {
      setRescheduling(false);
      return;
    }
    await rescheduleConvo.mutateAsync({
      scheduledDate: getUnixTime(date),
      location: location,
      duration: duration,
    });
    setRescheduling(false);
  };

  const confirmCancellation = async (all: boolean) => {
    setCancelling(false);
    if (all) {
      await cancelConversation.mutateAsync();
    } else {
      await cancelConversationOccurence.mutateAsync();
    }
  };

  const onItemClick = (cmd: string) => {
    switch (cmd) {
      case "cancel":
        setCancelling(true);
        break;
      case "reschedule":
        setDate(fromUnixTime(conversation.scheduledDate));
        setRescheduling(true);
        break;
    }
  };

  const getDayOcc = (date: Date) => {
    const num = Math.ceil(getDate(date) / 7);

    return num === 5 ? "last" : ordinal(Math.ceil(getDate(date) / 7));
  };

  const formatRecurrence = (
    recurrence: RecurrenceType,
    scheduledDate: number,
  ): string => {
    if (recurrence === "weekly") {
      return `Repeat every ${getDayName(getDay(fromUnixTime(scheduledDate)))}`;
    } else if (recurrence.includes("weekly")) {
      const number = recurrence.charAt(0);
      return `Repeat every ${getDayName(
        getDay(fromUnixTime(scheduledDate)),
      )} every ${number} weeks`;
    } else if (recurrence === "monthly") {
      return `Repeat every ${getDayOcc(
        fromUnixTime(scheduledDate),
      )} ${getDayName(getDay(fromUnixTime(scheduledDate)))} every month`;
    } else if (recurrence.includes("monthly")) {
      const number = recurrence.charAt(0);
      return `Repeat every ${getDayOcc(
        fromUnixTime(scheduledDate),
      )} ${getDayName(
        getDay(fromUnixTime(scheduledDate)),
      )} every ${number} months`;
    } else {
      return `Repeat every year`;
    }
  };

  return (
    <DateContainer
      cancelled={conversation.cancelledOn !== null}
      completed={conversation.completedOn !== null}
    >
      <CancelConversationModal
        isOpen={cancelling}
        onCancel={() => setCancelling(false)}
        onConfirm={confirmCancellation}
      />

      <ReactModal onClose={() => setRescheduling(false)} isOpen={rescheduling}>
        <Box p={4}>
          <Text mb={3} fontSize={18} fontWeight={600}>
            Reschedule Conversation
          </Text>
          <div style={{ width: "100%", marginBottom: 12 }}>
            <ReactDatePicker
              selected={date}
              onChange={setDate}
              inline
              showTimeSelect
              timeIntervals={15}
            />
          </div>
          <FormInput
            label="Duration"
            value={duration}
            onChange={(ev) => setDuration(ev.target.valueAsNumber)}
            type="number"
          />
          <FormInput
            label="Location"
            value={location}
            onChange={(ev) => setLocation(ev.target.value)}
          />
          <Flex flexDirection={"row"} justifyContent={"center"} mt={3}>
            <Button color="positive" mr={2} onClick={reschedule}>
              Save
            </Button>
            <Button onClick={() => setRescheduling(false)}>Cancel</Button>
          </Flex>
        </Box>
      </ReactModal>

      <Flex mb={1} justifyContent="space-between" alignItems="center">
        <div>
          <Flex
            alignItems="flex-start"
            mb={1}
            flexDirection="column"
            mt={"3px"}
          >
            <Text fontWeight={600}>
              {format(fromUnixTime(x.scheduledDate), "do MMMM yyyy @ p")} (
              {x.duration} mins)
            </Text>
            {x.recurrence !== "none" && (
              <Flex alignItems={"center"}>
                <MdRepeat color="grey" />
                <Text
                  as="span"
                  color="grey2"
                  fontSize={2}
                  fontWeight={600}
                  ml={"3px"}
                >
                  {formatRecurrence(x.recurrence, x.scheduledDate)}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex alignItems="center" mb={1}>
            <Text fontWeight={500}>
              {x.type}{" "}
              {isComplete ? "(Complete)" : isCancelled ? "(Cancelled)" : ""}
            </Text>
          </Flex>
          <Flex alignItems="center" mb={"3px"}>
            <Text fontStyle={"italic"} fontWeight={500} fontSize={2}>
              With {otherUser.name}{" "}
              {withManager ? "(Manager)" : "(Direct Report)"}
            </Text>
          </Flex>
          {x.location !== "" && (
            <Flex alignItems={"center"}>
              <FaMapMarker color="#aaa" />
              <Text fontStyle={"italic"} fontWeight={500} fontSize={2} ml={1}>
                {x.location}
              </Text>
            </Flex>
          )}
        </div>
        <Flex alignItems={"end"} flexDirection={"column"}>
          {!isCancelled && (
            <Button
              icon={FaArrowCircleRight}
              color="primary"
              to={`/solutions/performance/conversations/${x.id}/${x.index}/play`}
              mb={1}
              small
            >
              Go To Conversation
            </Button>
          )}
          {!isComplete && !isCancelled && (isOwner || isManager) && (
            <DotMenuText
              color="primary"
              text={"Options"}
              items={[
                {
                  label: "Cancel",
                  disabled: isCancelled || isComplete,
                  cmd: "cancel",
                  color: "danger",
                  icon: FaTrash,
                },
                {
                  label: "Reschedule",
                  disabled: isComplete,
                  cmd: "reschedule",
                  icon: FaCalendar,
                },
              ]}
              onItemClick={(val, cmd) => onItemClick(cmd)}
            />
          )}
        </Flex>
      </Flex>
    </DateContainer>
  );
}

export function Conversations() {
  const { data: team, isFetched: teamFetched } = useTeam();
  const { data: convos, isFetched } = useConversations();
  const { data: relationships } = useMyRelationships();

  const [excludeCancelled, setExcludeCancelled] = useState(true);

  const [selectedTeam, setSelectedTeam] = useState(
    sessionStorage.getItem("selectedTeam") !== null
      ? sessionStorage.getItem("selectedTeam")
      : "",
  );
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(new Date()));

  const [lastPreset, setLastPreset] = useState("thismonth");

  const dateOptions = useMemo(() => {
    return [
      { value: "today", label: "Today" },
      { value: "thisweek", label: "This Week" },
      { value: "thismonth", label: "This Month" },
      { value: "3months", label: "Next 3 Months" },
      { value: "last30days", label: "Last 30 days" },
      { value: "custom", label: "Custom", disabled: true },
    ];
  }, []);

  const setPreset = (value: string) => {
    if (value === "today") {
      setStartDate(startOfDay(new Date()));
      setEndDate(endOfDay(new Date()));
    } else if (value === "thisweek") {
      setStartDate(startOfWeek(new Date()));
      setEndDate(endOfWeek(new Date()));
    } else if (value === "thismonth") {
      setStartDate(startOfMonth(new Date()));
      setEndDate(endOfMonth(new Date()));
    } else if (value === "3months") {
      setStartDate(startOfMonth(new Date()));
      setEndDate(endOfMonth(addMonths(new Date(), 2)));
    } else if (value === "last30days") {
      setStartDate(addDays(new Date(), -30));
      setEndDate(new Date());
    }
    setLastPreset(value);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    sessionStorage.setItem("startDate", start);
    setEndDate(end);
    sessionStorage.setItem("endDate", end);
    setLastPreset("custom");
  };

  const allInstances = (convos ?? []).flatMap((x) =>
    x.occurences.map((o) => ({
      ...x,
      ...o,
      cancelledOn: x.cancelledOn ?? o.cancelledOn,
    })),
  );
  const filteredConvos = useMemo(() => {
    let data = allInstances.filter(
      (x) =>
        fromUnixTime(x.scheduledDate) >= startOfDay(startDate) &&
        fromUnixTime(x.scheduledDate) <= endOfDay(endDate),
    );
    if (selectedTeam.length > 0) {
      data = data.filter(
        (x) =>
          x.directReport.id === selectedTeam || x.manager.id === selectedTeam,
      );
    }

    if (excludeCancelled) {
      data = data.filter((x) => x.cancelledOn == null);
    }

    return {
      upcoming: data.filter(isUpcoming),
      past: data.filter(negate(isUpcoming)),
    };
  }, [startDate, endDate, allInstances, selectedTeam, excludeCancelled]);

  const loading = !isFetched;

  return (
    <>
      <Flex mb={2} flexDirection={"row"}>
        <Flex flexGrow={8}>
          <Flex flexDirection={"column"}>
            <Flex>
              <Text
                fontSize={[5, 6]}
                fontWeight={600}
                lineHeight={1}
                mr={1}
                color="black"
                as="h1"
              >
                Conversations
              </Text>
            </Flex>
            <Text fontSize={3} color="grey1" fontWeight={500} mt={1}>
              Conversations which you've had in your past and ones scheduled for
              the future
            </Text>
          </Flex>
        </Flex>
        {relationships?.some((relation) => relation.type === "manager") && (
          <Flex
            alignItems="end"
            justifyContent="center"
            flexDirection="column"
            flexGrow={1}
          >
            <Button
              to="/solutions/performance/conversations/create"
              color="primary"
              icon={FaPlusCircle}
            >
              Schedule Conversation
            </Button>
          </Flex>
        )}
      </Flex>
      <Rule />
      <Flex
        flex={1}
        flexWrap={["wrap-reverse", "nowrap"]}
        alignItems={"flex-start"}
        flexDirection={"row"}
      >
        <Flex width={["100%", "80%"]}>
          <Panel width={"100%"}>
            <PanelHeader p={3} height={60}>
              <Flex alignItems={"center"} flexDirection={"row"}>
                <Circle mr={2} color="primary">
                  <FaCommentDots size={20} />
                </Circle>
                <Flex flexDirection={"column"}>
                  <Text
                    fontSize={4}
                    fontWeight={600}
                    color="black"
                    as="h2"
                    lineHeight={1.25}
                  >
                    Your Conversations
                  </Text>

                  <Text fontSize={2} fontWeight={600} color="grey1">
                    Showing conversations from{" "}
                    {startDate != null
                      ? formatDate(startDate, "dd/MM/yyyy")
                      : " - "}{" "}
                    to{" "}
                    {endDate != null
                      ? formatDate(endDate, "dd/MM/yyyy")
                      : " - "}
                  </Text>
                </Flex>
              </Flex>
            </PanelHeader>
            {loading ? (
              <Loader />
            ) : filteredConvos.past.concat(filteredConvos.upcoming).length ===
              0 ? (
              <Flex p={6} alignItems="center" flexDirection={"column"}>
                <Text fontSize={"100px"} color="grey4" lineHeight={1}>
                  <FaSmile />
                </Text>
                <Text fontSize={4} fontWeight={600} color="grey2">
                  Nothing Scheduled
                </Text>
              </Flex>
            ) : (
              <Box p={4}>
                {filteredConvos.upcoming.length > 0 && (
                  <>
                    <Text fontWeight={600} fontSize={3} mb={3} color="grey1">
                      Upcoming Conversations
                    </Text>

                    {groupConvos(filteredConvos.upcoming).map((x) => (
                      <Box mb={3}>
                        <Text
                          fontSize={4}
                          fontWeight={500}
                          mt={2}
                          mb={2}
                          color={x.label === "Today" ? "primary" : undefined}
                        >
                          {x.label}
                        </Text>
                        {sortBy(x.items, (x) => x.scheduledDate).map(
                          (x, i, self) => (
                            <Convo conversation={x} />
                          ),
                        )}
                      </Box>
                    ))}
                    {filteredConvos.past.length > 0 && <Rule />}
                  </>
                )}
                {filteredConvos.past.length > 0 && (
                  <>
                    <Text fontWeight={600} fontSize={3} mb={3} color="grey1">
                      Past Conversations
                    </Text>
                    {groupConvos(filteredConvos.past)
                      .reverse()
                      .map((x) => (
                        <Box mb={3}>
                          <Text fontSize={4} fontWeight={500} mb={2}>
                            {x.label}
                          </Text>
                          {sortBy(
                            x.items.reverse(),
                            (x) => x.scheduledDate,
                          ).map((x, i, self) => (
                            <Convo conversation={x} />
                          ))}
                        </Box>
                      ))}
                  </>
                )}
              </Box>
            )}
          </Panel>
        </Flex>
        <Flex
          ml={[0, 3]}
          mb={[3, 0]}
          style={{ minWidth: "250px" }}
          width={["100%", "20%"]}
        >
          <Panel width={"100%"}>
            <Box p={4}>
              <Text
                fontSize={4}
                fontWeight={600}
                lineHeight={1}
                color="black"
                as="h2"
                mb={4}
              >
                Conversations View
              </Text>
              {teamFetched && (
                <>
                  <Text mb={1} fontWeight={600}>
                    User:
                  </Text>

                  <Select
                    mb={3}
                    options={[
                      ...[{ label: "All", value: "" }],
                      ...team.directReports.map((user) => ({
                        label: user.name,
                        value: user.id,
                      })),
                      ...team.lineManagers.map((user) => ({
                        label: user.name,
                        value: user.id,
                      })),
                    ]}
                    value={selectedTeam}
                    onChange={(val) => {
                      setSelectedTeam(val.target.value);
                      sessionStorage.setItem("selectedTeam", val.target.value);
                    }}
                  />
                </>
              )}

              <MiniToggle
                width="100%"
                label="Exclude Cancelled"
                value={excludeCancelled}
                onChange={setExcludeCancelled}
                mb={2}
              />
              <Flex flexDirection={"column"}>
                <Flex justifyContent={"center"}>
                  <CustomCalendarComponent>
                    <ReactDatePicker
                      calendarStartDay={1}
                      selected={startDate}
                      onChange={onChange}
                      inline
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                    />
                  </CustomCalendarComponent>
                </Flex>
                <Select
                  options={dateOptions}
                  onChange={(ev) => setPreset(ev.target.value)}
                  value={lastPreset}
                />
              </Flex>
            </Box>
          </Panel>
        </Flex>
      </Flex>
    </>
  );
}
