import React, { useState } from "react";
import axios from "axios";
import { FaChevronDown } from "react-icons/fa";
import { Flex, Text, Loader, InputWithSearch } from "@coaching-culture/ui";
import {
  Drawer,
  DrawerHeader,
  CloseDrawerButton,
  ItemsList,
  ItemContainer,
} from "./Drawer";
import UserProfileImage from "components/UserProfileImage";
import { useOrg } from "auth/OrgProvider";
import { sortBy } from "lodash";

const UserItem = ({ onClick, user }) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <UserProfileImage name={user.name} mr={2} size="small" />
        <Text fontWeight={500} fontSize={4}>
          {user.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

const OrgDrawer = ({ onSelect, me }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [org] = useOrg();
  const [loading, setLoading] = useState(true);

  const load = () => {
    axios.get("/api/users").then(({ data }) => {
      setUsers(data);
      setLoading(false);
    });
  };

  let filteredUsers = users;

  if (search.trim() !== "") {
    filteredUsers = users.filter((x) =>
      x.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  filteredUsers = sortBy(filteredUsers, (x) => x.name.toLowerCase());

  return (
    <Drawer onReady={load}>
      <DrawerHeader>
        <CloseDrawerButton onClick={() => onSelect(null)}>
          <FaChevronDown />
        </CloseDrawerButton>
        <Text textAlign="center" fontSize={5} fontWeight={600}>
          {org.name}
        </Text>
      </DrawerHeader>
      <Flex p={3} pb={0} mb={3}>
        <InputWithSearch
          width={400}
          placeholder="Search..."
          searching={false}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
      </Flex>
      <ItemsList>
        {loading ? (
          <Loader overlay color="blue" />
        ) : filteredUsers.length === 0 ? (
          <Text textAlign="center" fontWeight={600}>
            None found
          </Text>
        ) : (
          filteredUsers.map((x) => (
            <UserItem onClick={() => onSelect(x)} user={x} key={x.id} />
          ))
        )}
      </ItemsList>
    </Drawer>
  );
};

export default React.memo(OrgDrawer);
