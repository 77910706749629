import { EmailComm } from "@coaching-culture/types";
import {
  Alert,
  Box,
  Button,
  Flex,
  FormInput,
  FormToggle,
  IconToast,
  Loader,
  Panel,
  RichTextEditor,
  useToast,
} from "@coaching-culture/ui";
import { useOrg } from "auth/OrgProvider";
import axios from "axios";
import { AddressBookInput } from "components/AddressBookInput";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import React, { useState } from "react";
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaPaperPlane,
} from "react-icons/fa";
import { useHistory } from "react-router";

const emailMergeFields = [
  {
    description: "User's First Name",
    value: "*|FNAME|*",
  },
  {
    description: "User's Last Name",
    value: "*|LNAME|*",
  },
  {
    description: "User's Email",
    value: "*|EMAIL|*",
  },
  {
    description: "User's Temporary Password",
    value: "*|TEMPPASS|*",
  },
  {
    description: "Platform URL",
    value: "https://platform.coachingculture.com",
  },
];

export const SendEmail = () => {
  const [loading, setLoading] = useState(false);
  const [org] = useOrg();
  const pushToash = useToast();
  const history = useHistory();
  const [email, setEmail] = useState<EmailComm>({
    to: [],
    subject: "",
    body: "",
  });

  const setValue = (key: keyof EmailComm) => (value: any) =>
    setEmail((old) => ({
      ...old,
      [key]: value,
    }));

  const send = async () => {
    if (email.to.length === 0) {
      window.alert("You must select at least one recipient");
      return;
    }

    if (window.confirm("Are you sure you want to send this email?")) {
      setLoading(true);
      try {
        await axios.post("/api/comm-emails", email);
        pushToash({
          content: (
            <IconToast
              icon={FaCheckCircle}
              text="Email Sent"
              iconColor="primary"
            />
          ),
        });
        history.push("/success/people");
      } catch {
        window.alert("There was a problem");
      }
      setLoading(false);
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Send Email"
        subtitle="Send a message out to your organisation"
        helpIdent="comms-main"
      />

      <Box style={{ position: "relative" }}>
        {loading && <Loader overlay />}
        {org.emailsDisabled && (
          <Alert color="danger" mb={3} icon={FaExclamationTriangle}>
            Emails are currently disabled for your organisation. Contact
            support.
          </Alert>
        )}
        <Panel p={3}>
          <FormToggle
            value={email.to === "org"}
            onChange={(val) => setValue("to")(val ? "org" : [])}
            label="Send to whole organisation?"
            helpText="If set, this email will be sent to all active users."
            mb={2}
          />
          {email.to === "org" ? null : (
            <AddressBookInput value={email.to} onChange={setValue("to")} />
          )}
          <FormInput
            name="subject"
            label="Subject"
            value={email.subject}
            onChange={(ev) => setValue("subject")(ev.target.value)}
          />
          <Box mb={3}>
            <RichTextEditor
              value={email.body}
              onChange={setValue("body")}
              mergeFields={emailMergeFields}
            />
          </Box>
          {email.body.includes("*|TEMPPASS|*") && (
            <Alert color="primary" icon={FaInfoCircle} mb={3}>
              Note: If the user has not created a password one will be generated
              for them, otherwise they will be prompted to use their current
              password.
            </Alert>
          )}
          <Flex justifyContent="flex-end">
            <Button
              onClick={send}
              icon={FaPaperPlane}
              color="primary"
              width="auto"
              minWidth={160}
              disabled={org.emailsDisabled}
            >
              Send
            </Button>
          </Flex>
        </Panel>
      </Box>
    </CenterColumn>
  );
};
