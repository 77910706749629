import {
  Button,
  Flex,
  IconButton,
  Label,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import {
  useDeleteUserField,
  useReorderUserFields,
  useUserFields,
} from "queries/users";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaGripVertical, FaPlusCircle, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { arrMove } from "utils";
import { getDndItemStyle } from "utils/dragAndDropUtil";

const fieldTypes = ["Free Text", "Select List", "Checkbox"];

const fieldColors = ["green", "blue", "orange"];

const UserFields = () => {
  const { data: fields, isFetched } = useUserFields();
  const deleteUserField = useDeleteUserField();
  const reorderUserFields = useReorderUserFields();

  const deleteField = (id: string) => {
    if (
      window.confirm(
        "Are you sure you want to delete this field?\n\nIt will remove the values from any user profiles that currently use the field."
      )
    ) {
      deleteUserField.mutate(id);
    }
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const ids = arrMove(
      fields,
      result.source.index,
      result.destination.index
    ).map((x) => x.id);
    await reorderUserFields.mutateAsync(ids);
  };

  return (
    <CenterColumn p={[2, 5]} pt={[5, 5]}>
      <PageHeader
        text="User Fields"
        subtitle="Configure custom fields against your users"
      />

      <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
        <Text fontWeight={600}>User Field Definitions</Text>

        <Button
          to="/success/people/user-fields/create"
          icon={FaPlusCircle}
          color="primary"
        >
          Add New Field
        </Button>
      </Flex>
      <Panel>
        {!isFetched ? (
          <Loader />
        ) : fields.length > 0 ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((x, i) => (
                      <Draggable key={x.id} draggableId={x.id} index={i}>
                        {(provided, snapshot) => (
                          <Flex
                            p="6px 12px"
                            borderBottom={1}
                            alignItems="center"
                            justifyContent={"space-between"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDndItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Flex alignItems="center">
                              <Flex
                                {...provided.dragHandleProps}
                                alignItems="center"
                              >
                                <FaGripVertical color="#999" />
                              </Flex>
                              <Text fontWeight={600} ml={2}>
                                <Link
                                  to={`/success/people/user-fields/${x.id}`}
                                >
                                  {x.name}
                                </Link>
                              </Text>
                              <Label
                                ml={2}
                                size="small"
                                color={fieldColors[x.type]}
                              >
                                {fieldTypes[x.type]}
                              </Label>
                            </Flex>
                            <Flex
                              alignItems="center"
                              justifyContent="flex-end"
                              style={{ gap: 3 }}
                            >
                              <IconButton
                                icon={FaTrash}
                                color="danger"
                                onClick={() => deleteField(x.id)}
                              />
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <Text fontSize={4} textAlign={"center"} p={3} fontWeight={600}>
            No User Fields
          </Text>
        )}
      </Panel>
    </CenterColumn>
  );
};

export default UserFields;
