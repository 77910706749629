import { useEffect } from "react";
import { useUser } from "../../auth";

const Logout = ({ history }) => {
  const [user, setUser] = useUser();

  useEffect(() => {
    if (user == null) {
      history.push("/login");
    } else {
      setUser(null);
      history.push("/login");
    }
    //eslint-disable-next-line
  }, []);

  return null;
};

export default Logout;
