import {
  GoalOutcomeOptions,
  GoalReviewStatusOptions,
} from "@coaching-culture/types";
import { FaFrown, FaGrinWink, FaSmile, FaThumbsUp } from "react-icons/fa";
import styled from "styled-components";

const levels = [
  {
    value: "blocked",
    text: "Blocked",
    color: "danger",
    icon: FaFrown,
  },
  {
    value: "onhold",
    text: "On Hold",
    color: "grey",
    icon: FaFrown,
  },
  {
    value: "achieved",
    text: "Achieved",
    color: "positive",
    icon: FaSmile,
  },
  {
    value: "inprogress",
    text: "In Progress",
    color: "warning",
    icon: FaGrinWink,
  },
  {
    value: "notstarted",
    text: "Not Started",
    color: "grey",
    icon: FaFrown,
  },
];

const labelSize = 30;

const Container = styled.div`
  width: 132px;
  height: ${labelSize}px;
  border-radius: 3px;
  position: relative;
  z-index: 2;
`;

const Label = styled.div<{ color: string; disabled: boolean }>`
  height: ${labelSize}px;
  position: absolute;
  width: 120px;
  top: 0;
  left: 6px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding-left: 12px;
  background-color: ${(props) => props.theme.colors[props.color]};
  user-select: none;

  & svg {
    font-size: 1.1em;
    margin-right: 6px;
  }
`;

const LabelContainer = styled.div``;

export type GoalProgressSelectProps = {
  value: GoalReviewStatusOptions | GoalOutcomeOptions;
  disabled?: boolean;
};

export function GoalProgressDisplay({
  value,
  disabled,
}: GoalProgressSelectProps) {
  const getIcon = (): JSX.Element => {
    switch (value) {
      case "exceeded":
        return <FaGrinWink />;
      case "achieved":
        return <FaThumbsUp />;
      case "notstarted":
        return <FaFrown />;
      case "inprogress":
        return <FaSmile />;
      case "onhold":
        return <FaGrinWink />;
      case "blocked":
        return <FaFrown />;
    }
  };

  const currentLevel = levels.find((level) => level.value === value);

  return (
    <Container
      style={{
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <LabelContainer>
        <Label color={currentLevel.color} disabled={disabled}>
          {getIcon()} {currentLevel.text}
        </Label>
      </LabelContainer>
    </Container>
  );
}
