import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";
import { LayoutProps, SpaceProps } from "styled-system";
import Flex from "./Flex";
import { IconButton } from "./IconButton";
import Panel from "./Panel";
import Text from "./Text";
import { Toggle } from "./Toggle";

const Container = styled(Panel)`
  box-shadow: 0;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  white-space: nowrap;
  flex-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  height: 42px;
`;

type MiniToggleProps = {
  value: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
  label: string;
  help?: () => void;
} & SpaceProps &
  LayoutProps;

export const MiniToggle = ({
  value,
  onChange,
  label,
  disabled = false,
  help,
  ...rest
}: MiniToggleProps) => (
  <Container padding="8px 12px" boxShadow={0} {...rest}>
    <Flex mr={2} flex={1}>
      <Text color="grey1" fontWeight={600} fontSize={2} mr={"3px"}>
        {label}
      </Text>
      {help && (
        <IconButton
          icon={FaQuestionCircle}
          onClick={help}
          title="What's this?"
          style={{
            fontSize: "0.8em",
            position: "relative",
            top: -5,
          }}
        />
      )}
    </Flex>
    <Toggle
      value={value}
      onChange={onChange}
      size="small"
      disabled={disabled}
    />
  </Container>
);
