import { Button, Circle, Flex, Img, Text } from "@coaching-culture/ui";
import React from "react";
import { IconType } from "react-icons";
import styled from "styled-components";

const WidgetHeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  max-height: 83px;
`;

export type ButtonProps = {
  icon: IconType;
  text: string;
  to?: string;
  onClick?: () => void;
};

export default function WidgetHeader({
  title,
  icon: Icon,
  iconImage,
  iconImageHeight,
  button,
}: {
  title: string;
  icon?: IconType;
  iconImage?: string | IconType;
  iconImageHeight?: string;
  button?: ButtonProps;
}) {
  return (
    <WidgetHeaderContainer p={4} alignItems="center" flexDirection={"row"}>
      {Icon && (
        <Circle mr={2} color="primary">
          <Icon size={20} />
        </Circle>
      )}
      {typeof iconImage === "string" ? (
        <Img
          mr={2}
          height={iconImageHeight ? iconImageHeight : "20px"}
          src={iconImage}
        />
      ) : typeof iconImage === "function" ? (
        <Circle color="primary" icon={iconImage} mr={2} />
      ) : null}
      <Text
        fontSize={4}
        fontWeight={600}
        lineHeight={1}
        mr={1}
        color="black"
        as="h1"
      >
        {title}
      </Text>
      {button != null && button.text != null && (
        <Flex
          alignItems="end"
          justifyContent="center"
          flexDirection="column"
          flexGrow={1}
        >
          <Button
            to={button.to}
            color="primary"
            icon={button.icon}
            onClick={button.onClick !== null ? button.onClick : null}
          >
            {button.text}
          </Button>
        </Flex>
      )}
    </WidgetHeaderContainer>
  );
}
