import { OrgSecuritySettings } from "@coaching-culture/types";
import {
  Alert,
  Button,
  ControlLabel,
  FormInput,
  FormSelect,
  FormTextArea,
  FormToggle,
  IconToast,
  Loader,
  Panel,
  ShareLink,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useOrg } from "auth/OrgProvider";
import axios from "axios";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FaExclamationTriangle,
  FaQuestionCircle,
  FaSave,
} from "react-icons/fa";

const samlOptions = [
  {
    label: "Off",
    value: "off",
  },
  {
    value: "allow",
    label: "Allow",
  },
  {
    value: "require",
    label: "Require",
  },
];

export function OrgSecurity() {
  const [org] = useOrg();
  const [loading, setLoading] = useState<boolean>(true);
  const pushToast = useToast();
  const { register, handleSubmit, watch, reset, control } =
    useForm<OrgSecuritySettings>();

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/orgs/${org.id}/security`).then(({ data }) => {
      reset(data);
      setLoading(false);
    });
  }, [org.id, reset]);

  const onSubmit = (values: OrgSecuritySettings) => {
    axios.put(`/api/orgs/${org.id}/security`, values).then(({ data }) => {
      reset(data);
      pushToast({
        content: <IconToast icon={FaSave} text="Saved!" />,
      });
    });
  };

  const useSaml = watch("useSaml");
  const samlEnabled = useSaml !== "off";

  if (loading) {
    return <Loader />;
  }

  return (
    <CenterColumn>
      <PageHeader
        text="Single Sign On"
        subtitle="Configure SAML 2.0 authentication for your organisation"
      />
      <Panel p={3} mb={3}>
        <Text fontSize={5} mb={3}>
          Identity Provider Configuration
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSelect
            label="Use SAML Single Sign On"
            name="useSaml"
            ref={register}
            options={samlOptions}
          />
          {useSaml === "require" && (
            <Alert color="danger" mb={2} icon={FaExclamationTriangle}>
              <p>
                If SAML is required then it will be impossible to login with a
                platform password and so you may be locked out of the platform
                if your configuration is incorrect.
              </p>
              <p>
                You can test you configuration by setting this value to "Allow"
                instead.
              </p>
            </Alert>
          )}
          <FormInput
            name="signOnUrl"
            label="Sign on URL"
            ref={register({
              required: samlEnabled,
            })}
            disabled={!samlEnabled}
          />
          <FormInput
            name="issuer"
            label="EntityID / Issuer"
            disabled={!samlEnabled}
            ref={register({
              required: samlEnabled,
            })}
          />
          <FormTextArea
            height="150px"
            name="publicCert"
            label="Public Certificate"
            ref={register({
              required: samlEnabled,
            })}
            placeholder="Paste your certificate here"
            disabled={!samlEnabled}
            mb={2}
          />
          <Controller
            control={control}
            name="createUser"
            render={({ value, onChange }) => (
              <FormToggle
                mb={3}
                value={value}
                onChange={onChange}
                label="Create Platform User On Authentication"
                helpText="Create a new Coaching Culture user account when a new user authenticates"
              />
            )}
          />

          <Button color="primary" type="submit">
            Save
          </Button>
        </form>
      </Panel>
      <Panel p={3}>
        <Text fontSize={5} mb={3}>
          Service Provider Information
        </Text>
        <ControlLabel>Service Provider Start/Login Url</ControlLabel>
        <ShareLink
          mb={2}
          url={`https://platform.coachingculture.com/api/sso/login/${org.id}`}
        />
        <Alert mb={2} icon={FaQuestionCircle} color="grey1">
          You can create a url for a particular page on the application by
          appending "?redirect=" followed by the appropriate path. For example
          you could append "?redirect=/solutions/mindset" to redirect users
          straight to Mindset if they have appropriate access rights.
        </Alert>
        <ControlLabel>Service Provider ACS Url</ControlLabel>
        <ShareLink
          mb={2}
          url={"https://platform.coachingculture.com/api/sso/assert"}
        />
        <ControlLabel>Service Provider Entity ID</ControlLabel>
        <ShareLink
          url="https://platform.coachingculture.com/api/sso/metadata.xml"
          mb={2}
        />
        <Alert color="warning" icon={FaExclamationTriangle}>
          Your IDP must be configured to provide the user's email address either
          as the NameID field or as an attribute called "email"
        </Alert>
      </Panel>
    </CenterColumn>
  );
}
