import { HelpItem } from "@coaching-culture/types";
import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import { FaQuestion } from "react-icons/fa";
import { Box, Button, Circle, Flex, Modal, RawContent, Text } from "..";

export type HelpItemDisplayModalProps = {
  item: HelpItem;
  onClose: () => void;
};

export function HelpItemDisplayModal({
  item,
  onClose,
}: HelpItemDisplayModalProps) {
  return (
    <Modal onClose={onClose} showCloseButton p={3} width={800}>
      <Flex alignItems="center" mb={3} pb={3} borderBottom={1}>
        <Circle color="primary" icon={FaQuestion} size="xlarge" mr={2} />
        <div>
          <Text color="black" fontSize={5} fontWeight={600} lineHeight={1.2}>
            {item.title}
          </Text>
          <Text fontSize={3} color="grey2" fontWeight={500}>
            What's this?
          </Text>
        </div>
      </Flex>
      {item.videoUrl && (
        <Box mb={3}>
          <Vimeo video={item.videoUrl} responsive />
        </Box>
      )}
      <Flex borderBottom={1} mb={3} pb={3}>
        {item.content != null && (
          <RawContent dangerouslySetInnerHTML={{ __html: item.content }} />
        )}
      </Flex>
      <Flex justifyContent="flex-end">
        <Button color="primary" onClick={onClose}>
          Ok, got it!
        </Button>
      </Flex>
    </Modal>
  );
}
