import { Circle, CircleProps, Text } from "@coaching-culture/ui";

const getInitials = (name: string) =>
  name
    .split(" ")
    .map((x) => x[0])
    .join("")
    .toUpperCase();

type InitialDisplayProps = {
  text: string;
} & CircleProps;

const fontSizes = {
  small: 2,
  medium: 3,
  large: 5,
  xlarge: 7,
  xxlarge: 8,
} as const;

const InitialDisplay = ({ text, size, ...rest }: InitialDisplayProps) => {
  return (
    <Circle {...rest} size={size}>
      <Text fontSize={fontSizes[size] || 3} fontWeight={600}>
        {getInitials(text)}
      </Text>
    </Circle>
  );
};

export default InitialDisplay;
