import {
  MultipleChoiceQuestionFormItemResponses,
  UserDisplay,
} from "@coaching-culture/types";
import { Box, Flex, PanelHeader, PanelInset, Text } from "@coaching-culture/ui";
import { useUser } from "auth";
import React from "react";
import { FaCheckCircle, FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";
import { FreqChart } from "./FreqChart";

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  margin-bottom: 6px;
  font-size: 20px;
  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.grey2};
  background-color: ${(props) =>
    props.active ? props.theme.colors.primary : "white"};
  color: ${(props) => (props.active ? "white" : props.theme.colors.grey2)};
  cursor: pointer;
  width: 100%;
`;

export type MultipleChoiceInsightsProps = {
  item: MultipleChoiceQuestionFormItemResponses;
  user: UserDisplay;
};

export function MultipleChoiceInsights({
  item,
  user,
}: MultipleChoiceInsightsProps) {
  const [currentUser] = useUser();

  const userText = currentUser?.id === user?.id ? "Your" : "User's";
  const getAnswer = (id: string | null) =>
    item.answers.find((x) => x.id === id)?.content ?? "None";

  const responses = item.responses;

  const yourAnswer = item.responses.find((x) => x.email === user?.email);

  const withoutMe = responses.filter((x) => x.email !== user?.email);

  const responseCount = withoutMe.length;

  const data = item.answers.map((x) => ({
    y: x.content,
    x: withoutMe.filter((w) => w.answer === x.id).length,
  }));

  return (
    <PanelInset mb={3}>
      <PanelHeader>
        <FaQuestionCircle />
        <Text fontWeight={600} ml={2}>
          {item.content}
        </Text>
      </PanelHeader>
      <PanelHeader>
        <Flex justifyContent="space-evenly" width="100%">
          <Flex alignItems="center">
            <Text mr={1}>Responses:</Text>
            <Text as="span" fontSize={5} color="positive" fontWeight={600}>
              {responseCount}
            </Text>
          </Flex>
        </Flex>
      </PanelHeader>
      <Flex flexDirection="column" p={3} justifyContent="stretch">
        {user != null && (
          <Box mb={2}>
            <Text fontWeight={600} color="grey2" mb={1}>
              {userText} Answer:
            </Text>
            <Container active={yourAnswer != null}>
              {yourAnswer != null ? <FaCheckCircle /> : <FaQuestionCircle />}
              <Text ml={2} fontSize={[3, 4]}>
                {getAnswer(yourAnswer?.answer)}
              </Text>
            </Container>
          </Box>
        )}
        <Flex width="100%" flexDirection="column">
          <Text fontWeight={600} color="grey2" mb={1}>
            Answers:
          </Text>
          <FreqChart data={data} />
        </Flex>
      </Flex>
    </PanelInset>
  );
}
