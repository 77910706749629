import { ModuleAttempt, ModuleSummary } from "@coaching-culture/types";
import { Button, Flex, Text } from "@coaching-culture/ui";
import styled from "styled-components";
import { SplashContainer } from "./MindsetWelcomeSplash";
import { ReportBackText } from "./ReportBackText";

type ChallengeSplashProps = {
  module: ModuleSummary;
  attempt: ModuleAttempt;
};

const MainText = styled(Text)`
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
`;

export const ChallengeSplash = ({ module, attempt }: ChallengeSplashProps) => (
  <SplashContainer color={module.mainColor}>
    <Flex flexDirection="column" alignItems="center" mb={3}>
      <Flex flexDirection="column" alignItems="center" mb={5}>
        <MainText fontSize={5} fontWeight={600} mb={3} color="white">
          Report Back
        </MainText>
        <ReportBackText
          challengeStarted={new Date(attempt.challengeStarted)}
          challengeDays={attempt.challengeDays}
        />
      </Flex>
      <Button
        to={`/solutions/mindset/modules/${module.slug}/challenge-response`}
        large
        color={module.mainColor}
      >
        Start Challenge Feedback
      </Button>
    </Flex>
  </SplashContainer>
);
