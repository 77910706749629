import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";
import { lighten } from "polished";

type InputProps = SpaceProps &
  LayoutProps & {
    invalid?: boolean | null;
  };

const Input = styled.input<InputProps>`
  display: block;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  max-width: 100%;
  box-shadow: rgba(204, 219, 232, 0.6) 1px 1px 4px 0px inset,
    rgba(255, 255, 255, 0.5) -1px -1px 4px 1px inset;
  background-color: ${(props) =>
    props.invalid ? lighten(0.2, props.theme.colors.danger) : "white"};
  ${space};
  ${layout};

  &:disabled,
  &:read-only {
    background-color: ${(props) => props.theme.colors.grey4};
  }

  &:focus,
  &:active {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: inset 0px 0px 5px
      ${(props) => lighten(0.3, props.theme.colors.primary)};
  }

  &[type="color"] {
    width: 100px;
    height: 36px;
  }

  &:invalid {
    background-color: ${(props) => lighten(0.5, props.theme.colors.danger)};
  }
`;

Input.defaultProps = {
  mb: 0,
  autoComplete: "no",
  width: "100%",
};

export default Input;
