import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  IconToast,
  ProgressBar,
  Text,
  useToast,
} from "@coaching-culture/ui";
import {
  FormItem,
  FormItemSpec,
  FormModel,
  FormModelSpec,
} from "@coaching-culture/types";
import styled from "styled-components";
import {
  FaChevronDown,
  FaChevronUp,
  FaQuestion,
  FaSave,
  FaTimes,
} from "react-icons/fa";
import { config, useTransition, animated } from "react-spring";
import CenterColumn from "../CenterColumn";
import { FormItemRenderer } from "./FormItemRenderer";
import { Prompt } from "react-router";
import { HelpModal } from "components/HelpModal";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const QuizContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 700px;
  max-width: 100%;
  margin-left: 12px;
  margin-right: 12px;
  flex: 1;
  position: relative;
`;

const ItemContainer = styled(animated.div)`
  position: absolute;
  width: 100%;
  padding: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${(props) => props.theme.colors.grey2};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 6px 12px;
  cursor: pointer;
  border: 0;
  background: none;

  &:hover {
    color: ${(props) => props.theme.colors.body};
  }

  & svg {
    margin-right: 6px;
  }
`;

export type FormCompletionItem = {
  id: string;
  answer: string | number;
};

type SurveyPlayerProps = {
  form: FormModel | FormModelSpec;
  onCompletion: (values: FormCompletionItem[]) => void;
  onClose?: () => void;
  email?: string;
};

export type Answer = {
  answer: number | string;
  evidence?: string;
};

const LeavePageBlocker = ({ when }: { when: boolean }) => {
  const message =
    "Are you sure you want to leave the survey without completing?";

  useEffect(() => {
    if (!when) return () => {};

    const beforeUnloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", beforeUnloadCallback);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadCallback);
    };
  }, [when, message]);

  return <Prompt when={when} message={message} />;
};

type StoredAnswers = {
  answers: Record<number, Answer>;
  activeIndex: number;
  email: string;
};

export function SurveyPlayer({
  form,
  onCompletion,
  onClose,
  email,
}: SurveyPlayerProps) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [reverse, setReverse] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Record<number, Answer>>({});
  const [storedAnswers, setStoredAnswers] = useState<StoredAnswers>(null);

  const completed = useRef(false);

  const toast = useToast();

  useEffect(() => {
    completed.current = false;
    const storedAnswers = JSON.parse(
      localStorage.getItem(form.id)
    ) as StoredAnswers;

    if (storedAnswers && storedAnswers.email === email) {
      setStoredAnswers(storedAnswers);
      setAnswers(storedAnswers.answers);
      const i = storedAnswers.activeIndex;
      if (form.items[i]) {
        setActiveIndex(i);
      } else {
        setActiveIndex(0);
      }
    } else {
      setAnswers({});
      setActiveIndex(0);
    }
  }, [form, email]);

  useEffect(() => {
    if (form?.items[activeIndex].type === "heading") {
      setActiveIndex((old) => {
        if (old === 0) {
          return old + 1;
        }
        return old + (reverse ? -1 : 1);
      });
    }
  }, [activeIndex, form?.items, reverse]);

  console.log(form);
  console.log(activeIndex);

  const activeItem = form.items[activeIndex];

  const complete = (data: FormCompletionItem[]) => {
    if (completed.current) {
      return;
    }
    completed.current = true;
    localStorage.removeItem(form.id);
    onCompletion(data);
  };

  const advance = (item: FormItem | FormItemSpec) => {
    setReverse(false);
    if (activeIndex === form.items.length - 1) {
      const data = Object.entries(answers).map(([id, ans]) => ({
        id: id,
        answer: ans.answer === -1 ? null : ans.answer,
        evidence: ans.evidence,
      }));
      complete(data);
    } else if (activeItem.id === item.id) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const back = () => {
    setReverse(true);
    setActiveIndex((old) => Math.max(0, old - 1));
  };

  const receiveAnswer = (ans: Answer, item: FormItem) => {
    setAnswers((old) => {
      old[item.id] = ans;
      return { ...old };
    });
  };

  const dir = reverse ? -1 : 1;

  const t = useTransition(activeItem, {
    config: config.slow,
    reverse: reverse,
    delay: 200,
    from: { opacity: 0, y: 400 * dir },
    enter: { opacity: 1, y: 0 },
    leave: {
      opacity: 0,
      y: -400 * dir,
    },
  });

  const saveAnswers = () => {
    const answersTemp = {
      answers: answers,
      activeIndex: activeIndex,
      email: email,
    };

    localStorage.setItem(form.id, JSON.stringify(answersTemp));
    setStoredAnswers(answersTemp);
    toast({
      content: (
        <IconToast icon={FaSave} iconColor="positive" text="Answers saved" />
      ),
    });
  };

  const ownsAnswers = storedAnswers?.email === email;

  const answerIsSufficient = (item: FormItem | FormItemSpec) => {
    if (item.type === "heading") {
      return true;
    }

    const ans = answers[item.id];

    if (ans == null) {
      return false;
    }

    if (item.type !== "rating") {
      return true;
    } else if (!item.requiresEvidence) {
      return true;
    } else if (ans.answer === -1) {
      return true;
    } else {
      return (ans.evidence ?? "").trim() !== "";
    }
  };

  const canAdvance =
    activeItem.type === "heading" || answerIsSufficient(activeItem);

  const currentSection = form?.items
    .slice(0, activeIndex + 1)
    .reverse()
    .find((x) => x.type === "heading");

  let sectionIndex = activeIndex;
  let sectionLength = form.items.length;
  let totalIndex = activeIndex;

  if (currentSection != null) {
    const currentSectionIndex = form?.items.indexOf(currentSection as any);

    totalIndex =
      activeIndex -
      form.items.slice(0, activeIndex).filter((x) => x.type === "heading")
        .length;

    sectionIndex = activeIndex - currentSectionIndex - 1;

    sectionLength = form.items
      .slice(currentSectionIndex + 1)
      .findIndex((x) => x.type === "heading");

    if (sectionLength < 0) {
      sectionLength = form.items.slice(currentSectionIndex).length - 1;
    }
  }

  return (
    <Container>
      {onClose && (
        <CloseButton onClick={onClose}>
          <FaTimes />
          Close
        </CloseButton>
      )}
      {showHelp && (
        <HelpModal onCancel={() => setShowHelp(false)}>
          <Flex flexDirection={"column"} p={3}>
            <Text mb={2}>
              Saving your answers will store them in the browser so you can
              return at any time using the same device.
            </Text>
            <Text>
              If this is shared computer please be aware that answers could
              potentially be accessed by another party.
            </Text>
          </Flex>
        </HelpModal>
      )}
      <LeavePageBlocker
        when={
          (storedAnswers === null ||
            JSON.stringify(storedAnswers?.answers) !==
              JSON.stringify(answers) ||
            !ownsAnswers) &&
          !completed
        }
      />
      <QuizContainer>
        {t(
          (style, item) =>
            item && (
              <ItemContainer
                style={{
                  ...style,
                  position: activeItem === item ? "relative" : "absolute",
                }}
              >
                <FormItemRenderer
                  index={sectionIndex}
                  sectionName={currentSection?.content}
                  count={sectionLength}
                  item={item}
                  onChange={receiveAnswer}
                  value={answers[item.id]}
                  reverse={reverse}
                  onConfirm={advance}
                />
              </ItemContainer>
            )
        )}
      </QuizContainer>
      <CenterColumn p={0}>
        <Flex
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          p={3}
        >
          <Text fontSize={[3, 5]} mb={[2, 0]}>
            {form.name}
          </Text>
          <Flex
            alignItems={["stretch", "center"]}
            flexDirection={["column", "row"]}
          >
            <Flex flexDirection="column" mb={[2, 0]}>
              <Text fontWeight={600} fontSize={2} mb={1}>
                {totalIndex + 1} of{" "}
                {form.items.filter((x) => x.type !== "heading").length}
              </Text>
              <ProgressBar
                value={(activeIndex + 1) / form.items.length}
                width={["100%", "200px"]}
              />
            </Flex>
            <Flex alignItems="center">
              <Button
                ml={[0, 2]}
                onClick={back}
                color="primary"
                icon={FaChevronUp}
                disabled={activeIndex === 0}
                style={{ flex: 1 }}
              >
                Back
              </Button>
              <Button
                ml={1}
                onClick={() => advance(activeItem)}
                color="primary"
                icon={FaChevronDown}
                disabled={!canAdvance}
                style={{ flex: 1 }}
              >
                {activeIndex === form.items.length - 1 ? "Submit" : "Next"}
              </Button>
              <Button
                ml={1}
                onClick={saveAnswers}
                color="positive"
                icon={FaSave}
              >
                Save for later
              </Button>
              <IconButton
                icon={FaQuestion}
                color={"body"}
                onClick={() => setShowHelp(true)}
              />
            </Flex>
          </Flex>
        </Flex>
      </CenterColumn>
    </Container>
  );
}
