import { CollectiveGoalDto } from "@coaching-culture/types";
import {
  AddButton,
  Circle,
  Flex,
  IconButton,
  LinkButton,
  Loader,
  Panel,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import CenterColumn from "components/CenterColumn";
import { ExplainImage } from "components/ExplainImage";
import { PageHeader } from "components/PageHeader";
import { useDeleteOrgGoal, useOrgGoals } from "queries/performance";
import { FaMedal, FaPen, FaTrash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import orgGoalsSrc from "../../../img/orggoals.svg";

type OrgGoalDisplayProps = {
  field: CollectiveGoalDto;
  onSelect: () => void;
  onDelete: () => void;
};

const OrgGoalDisplay = ({ field, onDelete, onSelect }: OrgGoalDisplayProps) => {
  return (
    <Flex border={1} p={3} borderRadius={2} mb={2} alignItems="center">
      <Circle icon={FaMedal} color="primary" />

      <LinkButton
        style={{ flex: 1 }}
        ml={2}
        mr={2}
        onClick={onSelect}
        fontWeight={500}
      >
        {field.name}
      </LinkButton>
      <IconButton icon={FaPen} color="body" onClick={onSelect} ml={1} />
      <IconButton icon={FaTrash} color="danger" onClick={onDelete} ml={1} />
    </Flex>
  );
};

export function OrgGoals() {
  const { data: orgGoals, isFetched } = useOrgGoals();
  const deleteOrgGoal = useDeleteOrgGoal();
  const history = useHistory();
  const terms = useGoalTerminology();

  const newItem = () => {
    history.push("/success/performance/org-goals/create");
  };

  const removeItem = async (id: string) => {
    if (window.confirm("Are you sure?")) {
      await deleteOrgGoal.mutateAsync(id);
    }
  };

  return (
    <CenterColumn>
      <PageHeader
        text={`Organisation ${terms.goal.asTitle()}`}
        subtitle={`Set ${terms.goal.asPluralBody()} at the organisation level.`}
      />
      <Panel p={[2, 3]}>
        <ExplainImage
          src={orgGoalsSrc}
          title={`Organisation ${terms.goal.asPluralTitle()}`}
          body={[
            `Set ${terms.goal.asPluralBody()} for your organisation as a whole.`,
            `You can specify some conditions for who the ${terms.goal.asPluralBody()} apply to.`,
          ].join("\n")}
        />
        {!isFetched ? (
          <Loader />
        ) : (
          <Flex width="100%" flexDirection="column" pt={3}>
            {orgGoals.map((x, i) => (
              <OrgGoalDisplay
                onSelect={() =>
                  history.push(`/success/performance/org-goals/${x.id}`)
                }
                field={x}
                key={i}
                onDelete={() => removeItem(x.id)}
              />
            ))}
            <AddButton
              onClick={newItem}
            >{`Add New Organisation ${terms.goal.asTitle()}`}</AddButton>
          </Flex>
        )}
      </Panel>
    </CenterColumn>
  );
}
