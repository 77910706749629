import styled from "styled-components";

export const RawContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }

  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  h1 {
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSizes[6]};
  }

  h2 {
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSizes[5]};
    margin-top: 24px;
  }

  h3 {
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSizes[4]};
  }

  li {
    margin-bottom: 6px;
  }

  ol,
  ul {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 12px;
    line-height: 1.5;
    font-weight: 500;
  }

  & ::marker {
    font-weight: 500;
    color: ${(props) => props.theme.colors.body};
  }
`;
