import {
  CommentDto,
  ConversationDto,
  ConversationOccurrenceDto,
} from "@coaching-culture/types";
import {
  Box,
  Button,
  Flex,
  Loader,
  Modal,
  RichTextEditor,
  Text,
} from "@coaching-culture/ui";
import {
  useConversations,
  useCreateConversationComment,
} from "queries/conversations";
import styled from "styled-components";
import { Comment } from "components/Comment";
import { format, fromUnixTime } from "date-fns";
import {
  FaChevronDown,
  FaChevronUp,
  FaInfoCircle,
  FaPaperPlane,
} from "react-icons/fa";
import { useState } from "react";
import { ScrollableContainer } from "components/ScrollableContainer";
import { orderBy } from "lodash";

export type ConversationCommentsModalProps = {
  userId: string;
  onCancel: () => void;
};

const DateContainer = styled.div<{ completed: boolean; cancelled: boolean }>`
  padding-left: 18px;
  margin-left: 18px;
  padding-bottom: 12px;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};

  &:before {
    content: "";
    display: block;
    background-color: ${(props) =>
      props.completed
        ? props.theme.colors.positive
        : props.cancelled
        ? props.theme.colors.danger
        : props.theme.colors.primary};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
    left: -9px;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    background-color: ${(props) => props.theme.colors.grey3};
    height: calc(100% - 10px);
    position: absolute;
    left: 0;
    top: 6px;
  }
`;

const AccordianButton = styled.div`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

type ConvoAndInstance = ConversationDto & ConversationOccurrenceDto;
type SelectedConvo = { id: string; index: number };

function Convo({ conversation }: { conversation: ConvoAndInstance }) {
  const comments = conversation.comments.filter((x) => x.isPrivate);

  return (
    <Flex mb={2} flexDirection={"row"}>
      <Flex width={"100%"} flexDirection={"column"}>
        {comments.length > 0 ? (
          comments.map((item, i) => {
            return <Comment comment={item as CommentDto} />;
          })
        ) : (
          <Flex alignItems="center" justifyContent="center" flex="1">
            <Text color="grey3" fontSize="2em" mr={2} mb={0} lineHeight={0}>
              <FaInfoCircle />
            </Text>
            <Text fontSize={3} fontWeight={600} color="grey2">
              No Comments
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export function ConversationCommentsModal({
  userId,
  onCancel,
}: ConversationCommentsModalProps) {
  const { data: conversations, isFetched } = useConversations();
  const [selectedConversation, setSelectedConversation] =
    useState<SelectedConvo>({ id: "", index: null });
  const [commentText, setCommentText] = useState<string>("");
  const createComment = useCreateConversationComment();

  if (!isFetched) {
    return <Loader />;
  }

  const isSelected = (conversation: ConvoAndInstance): boolean => {
    return (
      selectedConversation.id === conversation.id &&
      selectedConversation.index === conversation.index
    );
  };

  const mappedConvos = orderBy(
    (conversations ?? []).flatMap((x) =>
      x.occurences
        .map((o) => ({
          ...x,
          ...o,
          cancelledOn: x.cancelledOn ?? o.cancelledOn,
        }))
        .filter((x) => x.directReport.id === userId)
    ),
    (x) => x.scheduledDate,
    "desc"
  ).filter((x) => x.comments.some((y) => y.isPrivate));

  const createCommentClick = (isPrivate: boolean) => {
    const comment = {
      body: commentText,
      isPrivate: isPrivate,
      convoId: selectedConversation.id,
      index: selectedConversation.index,
    };
    createComment.mutateAsync(comment).then((res) => {
      setCommentText("");
    });
  };

  return (
    <Modal width={900} showCloseButton onClose={onCancel} p={5}>
      <Text fontSize={5} fontWeight={600} mb={4}>
        Private Notes
      </Text>
      {mappedConvos.length > 0 ? (
        <ScrollableContainer mb={2} maxHeight={500}>
          {mappedConvos.map((x) => (
            <Box mr={2} mb={2}>
              <DateContainer
                cancelled={x.cancelledOn !== null}
                completed={x.completedOn !== null}
              >
                <Flex mb={2} flexDirection={"row"}>
                  <Flex flexGrow={8}>
                    <Flex flexDirection={"column"}>
                      <Flex alignItems="center" mb={1}>
                        <Text fontWeight={600}>
                          {format(
                            fromUnixTime(x.scheduledDate),
                            "do MMMM yyyy @ p"
                          )}
                          {x.recurrence !== "none" && (
                            <Text as="span" color="grey2" fontSize={2} ml={1}>
                              (Recurring)
                            </Text>
                          )}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" mb={1}>
                        <Text fontWeight={500}>
                          {x.type}{" "}
                          {x.completedOn !== null
                            ? "(Complete)"
                            : x.cancelledOn !== null
                            ? "(Cancelled)"
                            : ""}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <Text fontStyle={"italic"} fontWeight={500}>
                          With {x.directReport.name} (Direct Report)
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    alignItems="end"
                    justifyContent="center"
                    flexDirection="column"
                    flexGrow={1}
                  >
                    {!isSelected(x) ? (
                      <AccordianButton
                        onClick={() =>
                          setSelectedConversation({ id: x.id, index: x.index })
                        }
                      >
                        <Flex alignItems={"center"}>
                          <Text fontWeight={600} mr={2}>
                            Expand
                          </Text>
                          <FaChevronDown />
                        </Flex>
                      </AccordianButton>
                    ) : (
                      <AccordianButton
                        onClick={() =>
                          setSelectedConversation({ id: "", index: null })
                        }
                      >
                        <Flex alignItems={"center"}>
                          <Text fontWeight={600} mr={2}>
                            Collapse
                          </Text>
                          <FaChevronUp />
                        </Flex>
                      </AccordianButton>
                    )}
                  </Flex>
                </Flex>

                {isSelected(x) && <Convo conversation={x}></Convo>}
              </DateContainer>
            </Box>
          ))}
        </ScrollableContainer>
      ) : (
        <Flex alignItems="center" justifyContent="center" flex="1">
          <Text color="grey3" fontSize="3em" mr={2} mb={0} lineHeight={0}>
            <FaInfoCircle />
          </Text>
          <Text fontSize={4} fontWeight={600} color="grey2">
            No Conversations
          </Text>
        </Flex>
      )}

      {selectedConversation.id !== "" && (
        <>
          <RichTextEditor
            value={commentText}
            onChange={setCommentText}
            height={300}
          />
          <Flex mb={2} justifyContent={"end"}>
            <Button
              onClick={() => createCommentClick(true)}
              mt={2}
              color="primary"
              icon={FaPaperPlane}
            >
              Add Private Comment
            </Button>
          </Flex>
        </>
      )}
    </Modal>
  );
}
