import React from "react";
import styled from "styled-components";
import { MainNav } from "./MainNav";

import TopBar from "./TopBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fafafa;
`;

const Main = styled.main`
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-left: 300px;
  }
`;

const MainContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <TopBar />
      <Main>
        <MainNav />
        {children}
      </Main>
    </Container>
  );
};

export default MainContainer;
