import {
  ProductIdent,
  ProductVisibility,
  ProductVisibilityStr,
} from "@coaching-culture/types";
import { useUser } from "auth";
import { useOrg } from "auth/OrgProvider";
import { useMemo } from "react";

const visOptions: readonly ProductVisibilityStr[] = [
  "hidden",
  "locked",
  "comingsoon",
  "enabled",
];

export function useProductVisibility(
  ident: ProductIdent | string,
): ProductVisibility {
  const [user] = useUser();
  const [org] = useOrg();

  const val = useMemo(() => {
    const p = org.products.find((p) => p.ident === ident);
    if (p == null || user == null) {
      return "hidden";
    }
    const vis = user.permissions.includes("org-admin")
      ? p.adminVisibility
      : p.userVisibility;
    return vis;
  }, [user, org, ident]);

  return visOptions.indexOf(val);
}

export function useAllProductVisibility(): Record<
  ProductIdent,
  ProductVisibility
> {
  const [user] = useUser();
  const [org] = useOrg();

  const val = useMemo(() => {
    return org == null || user == null
      ? {}
      : Object.fromEntries(
          org.products.map((p) => {
            const vis = user.permissions.includes("org-admin")
              ? p.adminVisibility
              : p.userVisibility;
            return [p.ident, visOptions.indexOf(vis)];
          }),
        );
  }, [user, org]);

  return val as Record<ProductIdent, ProductVisibility>;
}
