import { CSSProperties } from "react";

export const getDndItemStyle = (
  isDragging: boolean,
  draggableStyle: CSSProperties
): CSSProperties => ({
  userSelect: "none",
  backgroundColor: "white",
  boxShadow: isDragging ? "0px 3px 6px rgba(0,0,0,0.2)" : "none",
  border: isDragging ? 0 : undefined,
  ...draggableStyle,
});
