import { Flex, Text } from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import CenterColumn from "components/CenterColumn";
import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GoalReview } from "./GoalReview";

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-top: 12px;

  & svg {
    margin-right: 6px;
  }
`;

export const GoalReviewContainer = () => {
  const { id, userId } = useParams<{
    id: string;
    userId: string;
  }>();
  const terms = useGoalTerminology();
  const history = useHistory();

  return (
    <CenterColumn>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={"row"}
      >
        <Flex
          mb={3}
          borderBottom={1}
          flexDirection="column"
          pb={3}
          width={"100%"}
        >
          <Text
            fontSize={[5, 6]}
            fontWeight={600}
            lineHeight={1}
            color="black"
            as="h1"
          >
            {`${terms.goal.asTitle()} Management`}
          </Text>

          <Text fontSize={3} color="grey1" fontWeight={500} mt={1}>
            {`Edit ${terms.goal.asBody()} details and review your progress`}
          </Text>

          <BackLink onClick={() => history.goBack()} to={null}>
            <FaChevronLeft />
            Back
          </BackLink>
        </Flex>
      </Flex>

      <GoalReview userId={userId} goalId={id} />
    </CenterColumn>
  );
};
