import React from "react";
import { lighten } from "polished";
import { IconType } from "react-icons/lib";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import { PanelInset } from "./Panel";
import Text from "./Text";
import { Box } from "..";

export type AlertProps = {
  children: React.ReactNode;
  color?: string;
  icon?: IconType;
} & SpaceProps;

const lightenValues: any = {
  warning: 0.38,
};

const AlertElem = styled(PanelInset)`
  border-color: ${(props) => props.theme.colors[props.color ?? "body"]};
  color: ${(props) => props.theme.colors[props.color ?? "body"]};
  display: flex;
  align-items: stretch;
  font-weight: 500;
  padding: 0;
  background-color: ${(props) =>
    lighten(
      lightenValues[props.color ?? ""] ?? 0.5,
      props.theme.colors[props.color ?? "body"]
    )};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 70px;
  padding-right: 0;
  flex: 0 0 70px;

  & svg {
    flex: 0 0 auto;
    font-size: 2.5em;
  }
`;

export function Alert({ children, icon: Icon, ...rest }: AlertProps) {
  return (
    <AlertElem {...rest}>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <Box p={2}>{children}</Box>
    </AlertElem>
  );
}
