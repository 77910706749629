import {
  AddButton,
  Box,
  Circle,
  Flex,
  IconButton,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { ExplainImage } from "components/ExplainImage";
import { PageHeader } from "components/PageHeader";
import {
  useChangeConversationTypeOrder,
  useConversationTypes,
  useDeleteConversationType,
} from "queries/conversationTypes";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaCommentDots, FaGripVertical, FaPen, FaTrash } from "react-icons/fa";
import { useHistory } from "react-router";
import { arrMove } from "utils";
import { getDndItemStyle } from "utils/dragAndDropUtil";
import convoSrc from "../../../img/conversations.svg";

export function ConversationTypes() {
  const { data, isFetched } = useConversationTypes();
  const deleteConversationType = useDeleteConversationType();
  const changeOrder = useChangeConversationTypeOrder();
  const history = useHistory();

  const newItem = () =>
    history.push("/success/settings/conversation-types/create");

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    changeOrder.mutateAsync(
      arrMove(data, result.source.index, result.destination.index).map(
        (x) => x.id
      )
    );
  };

  return (
    <CenterColumn>
      <PageHeader
        text="Conversation Types"
        subtitle="Define different types of conversation for your organisation"
      />
      <Panel p={[2, 3]}>
        <ExplainImage
          src={convoSrc}
          title={"Conversation Types"}
          body={
            "Define a set of discussion points for your organisation.\nThese will be the default discussion points for all performance conversations of that type.\nManagers will be able to tweak these for each conversation."
          }
        />
        {!isFetched ? (
          <Loader />
        ) : (
          <Box mt={2}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {data.map((x, i) => (
                      <Draggable
                        key={x.id}
                        draggableId={x.id != null ? x.id : i.toString()}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            key={x.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDndItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Flex
                              border={1}
                              borderRadius={6}
                              mb={2}
                              alignItems={"center"}
                              p={2}
                            >
                              <Flex
                                alignItems={"center"}
                                {...provided.dragHandleProps}
                                mr={1}
                              >
                                <FaGripVertical color="#999" />
                              </Flex>
                              <Circle
                                icon={FaCommentDots}
                                color="primary"
                                mr={2}
                              />
                              <Text style={{ flex: 1 }} fontWeight={500}>
                                {x.name}
                              </Text>
                              <IconButton
                                color="body"
                                icon={FaPen}
                                onClick={() =>
                                  history.push(
                                    `/success/settings/conversation-types/${x.id}`
                                  )
                                }
                              />
                              <IconButton
                                ml={1}
                                color="danger"
                                icon={FaTrash}
                                onClick={() =>
                                  deleteConversationType.mutateAsync(x.id)
                                }
                              />
                            </Flex>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Flex width="100%" flexDirection="column">
              <AddButton onClick={newItem}>Add New Conversation Type</AddButton>
            </Flex>
          </Box>
        )}
      </Panel>
    </CenterColumn>
  );
}
