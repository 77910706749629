import { ContentType, UserFilter } from "@coaching-culture/types";
import {
  Flex,
  GaugeChart,
  Grid,
  Loader,
  Panel,
  Text,
} from "@coaching-culture/ui";
import { useSummaryReport } from "./SummaryReport";

type ContentSummaryChartsProps = {
  type: ContentType;
  filter: UserFilter;
};

export const ContentSummaryCharts = ({
  type,
  filter,
}: ContentSummaryChartsProps) => {
  const data = useSummaryReport({ filter, type });

  if (!data.isSuccess) {
    return (
      <Flex>
        <Loader />
      </Flex>
    );
  }

  const totalUsers = data.data
    .map((x) => x.usersAvailable)
    .reduce((acc, val) => acc + val, 0);
  const completions = data.data
    .map((x) => x.usersCompleted)
    .reduce((acc, val) => acc + val, 0);
  const started = data.data
    .map((x) => x.usersStarted)
    .reduce((acc, val) => acc + val, 0);

  return (
    <Grid gridGap={3} gridTemplateColumns={["1fr", "1fr 1fr 1fr"]}>
      <Panel p={3}>
        <Text color={"primary"} fontWeight={600} textAlign={"center"}>
          Completions
        </Text>
        <GaugeChart
          value={completions}
          total={totalUsers}
          invertColours={false}
        />
      </Panel>
      <Panel p={3}>
        <Text color={"primary"} fontWeight={600} textAlign={"center"}>
          Started or Completed
        </Text>
        <GaugeChart
          value={started + completions}
          total={totalUsers}
          invertColours={false}
        />
      </Panel>
      <Panel p={3}>
        <Text color={"primary"} fontWeight={600} textAlign={"center"}>
          Not Started
        </Text>
        <GaugeChart
          value={totalUsers - (completions + started)}
          total={totalUsers}
          invertColours={true}
        />
      </Panel>
    </Grid>
  );
};
