import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  GaugeChart,
  Grid,
  Loader,
  Panel,
  Select,
  SortableTable,
  SortableTableColumn,
  Text,
} from "@coaching-culture/ui";
import { Link } from "react-router-dom";
import {
  ManagerPerformanceInsightDto,
  UserFilter,
} from "@coaching-culture/types";

import { saveExportAsCsv } from "utils";
import UserProfileImage from "components/UserProfileImage";
import { isOverdue } from "utils/content";
import { addDays, fromUnixTime } from "date-fns";
import { FilterModal } from "components/FilterPanel";
import qsStringify from "qs-stringify";
import axios from "axios";
import { useGoalTerminology } from "auth/OrgProvider";

export const PerformManagerInsights = () => {
  const goalTerms = useGoalTerminology();
  const [data, setData] = useState<ManagerPerformanceInsightDto[] | null>(null);
  const [filter, setFilter] = useState<UserFilter>({
    groups: [],
    userFields: [],
  });

  const [showFilters, setShowFilters] = useState(false);
  const [timeframe, setTimeframe] = useState(30);

  const colour = (ratio: number, invertColours: boolean): string => {
    if (invertColours) {
      return ratio < 0.01
        ? "positive"
        : ratio >= 0.01 && ratio <= 0.5
          ? "warning"
          : "danger";
    }
    return ratio < 0.5
      ? "danger"
      : ratio >= 0.5 && ratio <= 0.99
        ? "warning"
        : "positive";
  };

  const filterString = qsStringify({ filter } as any);
  useEffect(() => {
    axios
      .get(`/api/v2/insights/perform/managers?${filterString}`)
      .then(({ data }) => setData(data));
  }, [filterString]);

  const cols = useMemo(
    (): SortableTableColumn<ManagerPerformanceInsightDto>[] => [
      {
        name: "name",
        label: "Name",
        format: (x) => (
          <Flex alignItems={"center"} flexDirection={"row"}>
            <Flex mr={2} flexDirection={"column"}>
              <UserProfileImage name={x.name} image={x.profileImage} />
            </Flex>
            <Flex flexDirection={"column"}>
              <Link to={`/success/people/users/${x.id}`}>
                <strong>{x.name}</strong>
              </Link>
              <p>{x.email}</p>
            </Flex>
          </Flex>
        ),
        sortFormat: (x) => x.name.toLowerCase(),
        exportFormat: (x) => x.name,
      },
      {
        name: "activeGoals",
        label: `Direct Reports with active ${goalTerms.goal.asPluralTitle()}`,
        headingProps: { whiteSpace: "wrap", width: "20%" },
        format: (x) => {
          const active = x.directReports.filter((user) =>
            user.goals.some((goal) => goal.completedOn === null),
          ).length;
          return (
            <Text color={colour(active / x.directReports.length, false)}>
              <strong>
                {active} / {x.directReports.length}
              </strong>
            </Text>
          );
        },
        sortFormat: (x) =>
          x.directReports.filter((user) =>
            user.goals.some((goal) => goal.completedOn === null),
          ).length,
        exportFormat: (x) =>
          x.directReports
            .filter((user) =>
              user.goals.some((goal) => goal.completedOn === null),
            )
            .length.toString() +
          " / " +
          x.directReports.length,
      },
      {
        name: "overdueGoals",
        label: `Direct Reports with overdue ${goalTerms.goal.asPluralTitle()}`,
        headingProps: { whiteSpace: "wrap", width: "20%" },
        format: (x) => {
          const overdue = x.directReports.filter((user) =>
            user.goals.some(isOverdue),
          );
          return (
            <Text color={colour(overdue.length / x.directReports.length, true)}>
              <strong>
                {overdue.length} / {x.directReports.length}
              </strong>
            </Text>
          );
        },
        sortFormat: (x) =>
          x.directReports.filter((user) =>
            user.goals.some((goal) => goal.completedOn === null && isOverdue),
          ).length,
        exportFormat: (x) =>
          x.directReports
            .filter((user) =>
              user.goals.some((goal) => goal.completedOn === null && isOverdue),
            )
            .length.toString() +
          " / " +
          x.directReports.length,
      },

      {
        name: "completedConversation",
        label: `Completed Conversation in last ${timeframe} days`,
        headingProps: { whiteSpace: "wrap", width: "20%" },
        format: (x) => {
          const completedConversations = x.directReports.filter((user) =>
            user.conversations.some(
              (convo) =>
                convo.completedOn !== null &&
                fromUnixTime(convo.completedOn) >
                  addDays(new Date(), -timeframe),
            ),
          );

          return (
            <Text
              color={colour(
                completedConversations.length / x.directReports.length,
                false,
              )}
            >
              <strong>
                {completedConversations.length} / {x.directReports.length}
              </strong>
            </Text>
          );
        },
        exportFormat: (x) => {
          const completedConversations = x.directReports.filter((user) =>
            user.conversations.some(
              (convo) =>
                convo.completedOn !== null &&
                fromUnixTime(convo.completedOn) >
                  addDays(new Date(), -timeframe),
            ),
          );

          return completedConversations.length + " / " + x.directReports.length;
        },
        sortFormat: (x) => {
          const completedConversations = x.directReports.filter((user) =>
            user.conversations.some(
              (convo) =>
                convo.completedOn !== null &&
                fromUnixTime(convo.completedOn) >
                  addDays(new Date(), -timeframe),
            ),
          );

          return completedConversations.length;
        },
      },
      {
        name: "sentFeedback",
        label: `Direct reports who sent feedback in last ${timeframe} days`,
        headingProps: { whiteSpace: "wrap", width: "20%" },
        format: (x) => {
          const feedbackSent = x.directReports.filter((user) =>
            user.sentFeedback.some(
              (feedback) =>
                fromUnixTime(feedback.sentOn) > addDays(new Date(), -timeframe),
            ),
          );

          return (
            <Text
              color={colour(
                feedbackSent.length / x.directReports.length,
                false,
              )}
            >
              <strong>
                {feedbackSent.length} / {x.directReports.length}
              </strong>
            </Text>
          );
        },
        exportFormat: (x) => {
          const feedbackSent = x.directReports.filter((user) =>
            user.sentFeedback.some(
              (feedback) =>
                fromUnixTime(feedback.sentOn) > addDays(new Date(), -timeframe),
            ),
          );

          return feedbackSent.length + " / " + x.directReports.length;
        },
        sortFormat: (x) => {
          const feedbackSent = x.directReports.filter((user) =>
            user.sentFeedback.some(
              (feedback) =>
                fromUnixTime(feedback.sentOn) > addDays(new Date(), -timeframe),
            ),
          );

          return feedbackSent.length;
        },
      },
      {
        name: "receivedFeedback",
        label: `Direct reports who received feedback in last ${timeframe} days`,
        headingProps: { whiteSpace: "wrap", width: "20%" },
        format: (x) => {
          const feedbackReceived = x.directReports.filter((user) =>
            user.receivedFeedback.some(
              (feedback) =>
                fromUnixTime(feedback.sentOn) > addDays(new Date(), -timeframe),
            ),
          );

          return (
            <Text
              color={colour(
                feedbackReceived.length / x.directReports.length,
                false,
              )}
            >
              <strong>
                {feedbackReceived.length} / {x.directReports.length}
              </strong>
            </Text>
          );
        },
        exportFormat: (x) => {
          const feedbackReceived = x.directReports.filter((user) =>
            user.receivedFeedback.some(
              (feedback) =>
                fromUnixTime(feedback.sentOn) > addDays(new Date(), -timeframe),
            ),
          );

          return feedbackReceived.length + " / " + x.directReports.length;
        },
        sortFormat: (x) => {
          const feedbackReceived = x.directReports.filter((user) =>
            user.receivedFeedback.some(
              (feedback) =>
                fromUnixTime(feedback.sentOn) > addDays(new Date(), -timeframe),
            ),
          );

          return feedbackReceived.length;
        },
      },
    ],
    [timeframe, goalTerms],
  );

  if (data === null) {
    return <Loader />;
  }

  const upcomingConvos = data.filter((manager) =>
    manager.conversations.some(
      (convo) =>
        fromUnixTime(convo.scheduledDate) > new Date() &&
        convo.cancelledOn === null,
    ),
  ).length;

  const recentConvosAllDirectReports = data.filter((x) =>
    x.directReports.every(
      (user) =>
        user.conversations.filter(
          (convo) =>
            fromUnixTime(convo.scheduledDate) >
              addDays(new Date(), -timeframe) && convo.cancelledOn === null,
        ).length > 0,
    ),
  );

  const activeGoals = data.filter((manager) =>
    manager.directReports.every((user) =>
      user.goals.some((goal) => goal.completedOn === null),
    ),
  );

  const onCancel = (result) => {
    if (result) {
      setFilter(result);
    }

    setShowFilters(false);
  };

  return (
    <>
      <FilterModal
        isOpen={showFilters}
        filter={filter}
        filteredUsers={data.map((x) => x.id)}
        onClose={(result) => {
          onCancel(result);
        }}
      />
      <Flex mb={3} justifyContent={"flex-end"}>
        <Button color="primary" onClick={() => setShowFilters(true)}>
          Filters ({filter.groups.length + filter.userFields.length})
        </Button>

        <Select
          ml={2}
          width={200}
          value={timeframe}
          onChange={(val) => setTimeframe(parseInt(val.target.value))}
          options={[
            { label: "Last 14 days", value: "14" },
            { label: "Last 30 days", value: "30" },
            { label: "Last 90 days", value: "90" },
            { label: "Last 180 days", value: "180" },
            { label: "Last year", value: "365" },
          ]}
        />
      </Flex>

      <Grid
        width={"100%"}
        gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}
        gridGap={3}
        mb={3}
      >
        <Flex flexDirection={"column"}>
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Managers with:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Active {goalTerms.goal.asTitle()} for all Direct Reports
              </Text>
            </div>
            <GaugeChart
              value={activeGoals.length}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex flexDirection={"column"}>
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Managers with:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Conversations with all Direct Reports in the last {timeframe}{" "}
                days
              </Text>
            </div>
            <GaugeChart
              value={recentConvosAllDirectReports.length}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
        <Flex flexDirection={"column"}>
          <Panel p={3}>
            <div style={{ minHeight: "64px" }}>
              <Text fontWeight={600} textAlign={"center"}>
                Managers with:
              </Text>
              <Text color={"primary"} fontWeight={600} textAlign={"center"}>
                Upcoming Conversations
              </Text>
            </div>
            <GaugeChart
              value={upcomingConvos}
              total={data.length}
              invertColours={false}
            />
          </Panel>
        </Flex>
      </Grid>
      <Panel style={{ overflow: "hidden" }}>
        <SortableTable<ManagerPerformanceInsightDto>
          columns={cols}
          data={data}
          defaultSort={1}
          rowKey="id"
          paged
          onExport={saveExportAsCsv("CCUserExport.csv")}
        />
      </Panel>
    </>
  );
};
