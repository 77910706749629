import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";

import Modal from "react-modal";
import { useTheme } from "styled-components";

import { IconButton } from "./IconButton";

export const ReactModal = ({
  isOpen,
  children,
  width,
  minHeight,
  onClose,
  position,
  showCloseButton,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  width?: number;
  minHeight?: number;
  onClose: () => void;
  position?: { top: number; left: number };
  showCloseButton?: boolean;
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <Modal
      onRequestClose={onClose}
      width={width}
      style={{
        overlay: {
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.3)",
          zIndex: 1000,
        },
        content: {
          top: position != null ? position.top : "50%",
          left: position != null ? position.left : "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: position == null ? "translate(-50%, -50%)" : "",
          width: width + "px",
          minHeight: minHeight + "px",
          borderRadius: "0",
          borderTop: `4px solid ${(theme as any).colors.primary}`,
          boxShadow: (theme as any).shadows[2],
        },
      }}
      isOpen={isOpen}
    >
      {showCloseButton && (
        <IconButton
          style={{ position: "absolute", top: 20, right: 20 }}
          color="body"
          icon={FaTimes}
          onClick={onClose}
          title={"Close"}
        />
      )}
      {children}
    </Modal>
  );
};
