import { Flex, Text } from "@coaching-culture/ui";
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

const MenuItemElem = styled.button`
  display: flex;
  text-align: left;
  margin-bottom: 6px;
  border: 0;
  padding: 12px;
  background: none;
  align-items: center;
  transition: all 0.3s ease;
  cursor: not-allowed;

  &:hover:enabled {
    background-color: ${(props) => props.theme.colors.grey6};
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

type MenuItemProps = {
  onClick: null | (() => void);
  title: string;
  subtitle: string;
  image: React.ReactNode;
  disabled?: boolean;
};

const MenuItem = ({
  onClick = null,
  title,
  subtitle,
  image,
  disabled,
}: MenuItemProps) => {
  return (
    <MenuItemElem onClick={onClick} disabled={disabled}>
      {image}
      <Flex flexDirection="column" flex="1" mt="3px" mr={3} ml={1}>
        <Text fontSize={5}>{title}</Text>
        <Text fontWeight={500} color="grey1">
          {subtitle}
        </Text>
      </Flex>
      <FaChevronRight size="1.5em" />
    </MenuItemElem>
  );
};

export default MenuItem;
