import {
  ContentAccessDto,
  OrgDto,
  OrgSummary,
  UpdateContentAccessSpec,
  UpdateOrgBrandingSpec,
  UpdateOrgTerminologySpec,
} from "@coaching-culture/types";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getOrgs = () =>
  axios.get<OrgSummary[]>("/api/orgs").then(({ data }) => data);

const getContentAccessSettings = () =>
  axios
    .get<ContentAccessDto[]>(`/api/v2/orgs/me/content-access`)
    .then(({ data }) => data);

const getOrgAddons = () =>
  axios.get<{ msTenant: string }>("/api/addons").then(({ data }) => data);

const getOrgById = (id: string) =>
  axios.get<OrgDto>(`/api/v2/orgs/${id}`).then(({ data }) => data);

const updateContentAccess = (orgId: string, data: UpdateContentAccessSpec) =>
  axios.put(`/api/v2/orgs/${orgId}/content-access`, data);

export const useContentAccessSettings = () =>
  useQuery(["contentaccess"], getContentAccessSettings);

export const updateOrgTerminology = (
  orgId: string,
  spec: UpdateOrgTerminologySpec,
) => axios.put(`/api/v2/orgs/${orgId}/terminology`, spec);

export const updateOrgBranding = (orgId: string, spec: UpdateOrgBrandingSpec) =>
  axios.put(`/api/v2/orgs/${orgId}/branding`, spec);

export const useOrgAddons = () => useQuery(["org", "addons"], getOrgAddons);

export const useOrgs = () => useQuery(["orgs"], getOrgs);

export const useOrgById = (id: string | null) =>
  useQuery(["orgs", id], () => (id == null ? null : getOrgById(id)));

export const useUpdateContentAccess = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ orgId, ...rest }: { orgId: string } & UpdateContentAccessSpec) =>
      updateContentAccess(orgId, { ...rest }),
    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["orgs", spec.orgId]);
      },
    },
  );

  return mutation;
};

export const useUpdateOrgTerminology = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ orgId, ...rest }: { orgId: string } & UpdateOrgTerminologySpec) =>
      updateOrgTerminology(orgId, { ...rest }),
    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["orgs", spec.orgId]);
      },
    },
  );

  return mutation;
};

export const useUpdateOrgBranding = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ orgId, ...rest }: { orgId: string } & UpdateOrgBrandingSpec) =>
      updateOrgBranding(orgId, { ...rest }),
    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["orgs", spec.orgId]);
      },
    },
  );

  return mutation;
};
