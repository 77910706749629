import { ActionDto, ConversationActionDto } from "@coaching-culture/types";
import {
  Circle,
  Flex,
  IconButton,
  IconToast,
  Label,
  Loader,
  Panel,
  Table,
  Text,
  useToast,
} from "@coaching-culture/ui";
import { useUser } from "auth";
import { ScrollableContainer } from "components/ScrollableContainer";
import { Toggle } from "components/Toggle";
import { format, fromUnixTime } from "date-fns";
import { sortBy } from "lodash";
import { useRemoveAction, useUserActions } from "queries/actions";
import { useChangeActionItem } from "queries/conversations";
import { useState } from "react";
import {
  FaBolt,
  FaCheckSquare,
  FaEye,
  FaThumbsUp,
  FaTrash,
} from "react-icons/fa";
import { useHistory } from "react-router";
import styled from "styled-components";
import { grid, GridProps } from "styled-system";
import { clientUrlForContent } from "utils/content";
import { NoDataWidget } from "./NoDataWidget";

const Widget = styled(Panel)<GridProps>`
  ${grid};
`;

const labelProps = {
  requestfeedback: {
    color: "primary",
    children: "Get Feedback",
  },
  conversation: {
    color: "orange",
    children: "Conversation",
  },
  providefeedback: {
    color: "green",
    children: "Give Feedback",
  },
  survey: {
    color: "pink",
    children: "Survey",
  },
  assessment: {
    color: "danger",
    children: "Assessment",
  },
  measures: {
    color: "pink",
    children: "Update Measures",
  },
  contfeedback: {
    color: "purple",
    children: "Give User Feedback",
  },
};

function isOutstanding(item: ActionDto) {
  return item.completedOn == null;
}

const justMine = (who: string) => (item: ActionDto) => {
  if (item.type === "conversation") {
    return item.assignedTo.id === who;
  }
  return true;
};
export const ActionsWidget = () => {
  const [user] = useUser();
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();
  const actions = useUserActions(user.id);
  const modifyAction = useChangeActionItem();
  const removeAction = useRemoveAction();
  const toast = useToast();

  const toShow = showAll
    ? actions.data
    : (actions.data ?? []).filter(isOutstanding);

  const getActionText = (item: ActionDto): string => {
    if (item.type === "conversation") {
      return item.text;
    } else if (item.type === "survey") {
      return `Complete survey: ${item.survey.name}`;
    } else if (item.type === "providefeedback") {
      return `Please give ${item.campaign.user.name} some feedback`;
    } else if (item.type === "requestfeedback") {
      return "Time to get some feedback...";
    } else if (item.type === "assessment") {
      return `Complete Assessment: ${item.content.name}`;
    } else if (item.type === "measures") {
      return "Update your team's Measures";
    } else if (item.type === "contfeedback") {
      return `Give ${item.requester.name} some feedback`;
    }
  };

  const getDueDate = (item: ActionDto): string => {
    let date: number | null = "dueDate" in item ? item.dueDate : null;

    if (date != null) {
      return format(fromUnixTime(date), "dd/MM/yyyy");
    } else {
      return "No Due Date";
    }
  };

  const toggleAction = async (item: ConversationActionDto) => {
    await modifyAction.mutateAsync({
      value: item.completedOn == null,
      conversationId: item.conversation.id,
      actionItemId: item.id,
    });
    toast({
      content: (
        <IconToast
          icon={FaThumbsUp}
          iconColor="primary"
          text="Action Updated"
        />
      ),
    });
  };

  const delAction = async (item: ConversationActionDto) => {
    if (window.confirm("Are you sure you want to delete this action?")) {
      await removeAction.mutateAsync(item.id);

      toast({
        content: (
          <IconToast icon={FaTrash} iconColor="primary" text="Action Removed" />
        ),
      });
    }
  };

  const getInteractions = (item: ActionDto) => {
    if (item.type === "conversation") {
      return (
        <>
          <IconButton
            icon={FaCheckSquare}
            color={item.completedOn == null ? "body" : "positive"}
            onClick={() => toggleAction(item)}
          />
          <IconButton
            icon={FaTrash}
            color="danger"
            onClick={() => delAction(item)}
          />
          <IconButton
            icon={FaEye}
            color="primary"
            onClick={() =>
              history.push(
                `/solutions/performance/conversations/${item.conversation.id}/${item.conversation.index}`
              )
            }
          />
        </>
      );
    } else if (item.type === "measures") {
      return (
        <IconButton
          icon={FaEye}
          color="primary"
          onClick={() => {
            history.push("/solutions/my-team");
          }}
        />
      );
    } else if (item.type === "assessment") {
      return (
        <IconButton
          icon={FaEye}
          color="primary"
          onClick={() => {
            history.push(clientUrlForContent(item.content));
          }}
        />
      );
    } else if (item.type === "survey") {
      return (
        <IconButton
          icon={FaEye}
          color="primary"
          onClick={() => {
            history.push(`/solutions/surveys/${item.survey.id}`);
          }}
        />
      );
    } else if (item.type === "providefeedback") {
      return (
        <IconButton
          icon={FaEye}
          color="primary"
          onClick={() => history.push(`/feedback?survey=${item.code}`)}
        />
      );
    } else if (item.type === "requestfeedback") {
      return (
        <IconButton
          icon={FaEye}
          color="primary"
          onClick={() =>
            history.push(`/solutions/feedback/campaigns/${item.campaign.id}`)
          }
        />
      );
    } else if (item.type === "contfeedback" && item.completedOn == null) {
      return (
        <IconButton
          icon={FaEye}
          color="primary"
          onClick={() =>
            history.push(
              `/solutions/continuous-feedback/send/${item.requester.id}/${item.requestId}`
            )
          }
        />
      );
    }
  };

  return (
    <Widget
      gridColumn={["span 1", "span 1", "span 1", "span 2"]}
      width={"100%"}
    >
      <Flex borderBottom={1} height={83} justifyContent={"space-between"} p={4}>
        <Flex alignItems="center">
          <Circle icon={FaBolt} size={"medium"} color="primary" mr={2} />
          <Text
            fontSize={4}
            fontWeight={600}
            lineHeight={1}
            mr={1}
            color="black"
            as="h1"
          >
            Actions
          </Text>
        </Flex>
        <Toggle
          ariaLabel="Display all actions"
          leftLabel="Outstanding"
          rightLabel="All"
          value={showAll}
          onChange={setShowAll}
        />
      </Flex>
      {toShow == null ? (
        <Loader />
      ) : toShow.length === 0 ? (
        <NoDataWidget text="No Current Actions" />
      ) : (
        <ScrollableContainer maxHeight={310}>
          <Table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Action</th>
                <th>Due Date</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sortBy(toShow.filter(justMine(user.id)), "date").map((x) => (
                <tr>
                  <td>
                    <Label {...labelProps[x.type]} />
                  </td>
                  <td>{getActionText(x)}</td>
                  <td>{getDueDate(x)}</td>
                  <td>
                    <Flex
                      alignItems="center"
                      justifyContent={"flex-end"}
                      style={{ fontSize: "1.2em", gap: 12 }}
                    >
                      {getInteractions(x)}
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollableContainer>
      )}
    </Widget>
  );
};
