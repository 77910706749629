import { FullGroup, GroupMember, UserGroup } from "@coaching-culture/types";
import { Circle, Text, Flex, Modal, Button } from "@coaching-culture/ui";
import axios from "axios";
import { ItemContainer, ItemsList } from "components/ShareModal/Drawer";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";

export type GroupSelectModalProps = {
  onSelect: (users: GroupMember[]) => void;
};

const Item = ({
  onClick,
  group,
  active
}: {
  onClick: () => void;
  group: UserGroup;
  active: boolean;
}) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <Circle
          icon={FaUsers}
          color={active ? "primary" : "body"}
          mr={2}
          size="small"
        />
        <Text
          fontWeight={500}
          fontSize={4}
          color={active ? "positive" : "body"}
        >
          {group.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

export function GroupSelectModal({ onSelect }: GroupSelectModalProps) {
  const [selected, setSelected] = useState<Record<number, boolean>>({});
  const [items, setItems] = useState<UserGroup[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    axios.get("/api/groups").then(({ data }) => setItems(data));
  }, []);

  const toggle = (item: UserGroup) => {
    setSelected((old) => ({
      ...old,
      [item.id]: !old[item.id]
    }));
  };

  const confirm = async () => {
    setFetching(true);
    const users = await Promise.all(
      items
        .filter((x) => selected[x.id])
        .map(({ id }) =>
          axios
            .get<FullGroup>(`/api/groups/${id}`)
            .then(({ data }) => data.members)
        )
    ).then((x) => x.flatMap((x) => x));
    setFetching(false);

    onSelect(uniqBy(users, "id"));
  };

  return (
    <Modal
      width={600}
      minHeight={500}
      p={0}
      onClose={() => onSelect([])}
      showCloseButton
      style={{ overflow: "hidden" }}
    >
      <Flex alignItems="center" flexDirection="column" p={3}>
        <Circle color="primary" icon={FaUsers} size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Select Groups
        </Text>
        <Text>
          {Object.values(selected).filter((x) => x).length} groups(s) selected.
        </Text>
      </Flex>
      <ItemsList style={{ padding: 0 }}>
        {items.map((x) => (
          <Item group={x} onClick={() => toggle(x)} active={selected[x.id]} />
        ))}
      </ItemsList>
      <Flex alignItems="center" justifyContent="center" p={3}>
        <Button onClick={confirm} color="primary" disabled={fetching}>
          Select
        </Button>
      </Flex>
    </Modal>
  );
}
