import { Flex } from "@coaching-culture/ui";
import { useUser } from "auth";
import useScript from "hooks/useScript";
import { FaQuestion } from "react-icons/fa";
import styled from "styled-components";

const LoopedInButton = styled.div`
  border: 0;
  background: ${(props) => props.theme.colors.grey3};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.colors.body};
  }

  &:active,
  &:focus {
    outline: 0;
    background-color: ${(props) => props.theme.colors.body};
  }
`;

export const LoopedInWidget = (...rest) => {
  const [user] = useUser();
  useScript("/loopedin.js", false);
  useScript("https://cdn.loopedin.io/js/sidebar.min.js?v=0.1", true);
  return (
    user != null &&
    user.permissions.some((per) => per === "org-admin") && (
      <Flex ml={[2, 3]}>
        <LoopedInButton
          aria-label="Product help, guides and release information"
          id="loopedIn"
        >
          <FaQuestion size={"0.8em"} />
        </LoopedInButton>
      </Flex>
    )
  );
};
