import {
  CollectiveGoalDto,
  discriminate,
  hasDuplicates,
  UserFieldType,
} from "@coaching-culture/types";
import {
  AddButton,
  Box,
  Button,
  Flex,
  FormInput,
  FormTextArea,
  FormToggle,
  IconButton,
  Loader,
  Select,
  Text,
} from "@coaching-culture/ui";
import { useGoalTerminology } from "auth/OrgProvider";
import { useOrgUsers, useUserFields } from "queries/users";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa";

const aggregationOptions = ["any", "all"].map((x) => ({ value: x, label: x }));

type Condition = {
  id: string;
  value: string;
};

type OrgGoalFormProps = {
  value?: CollectiveGoalDto;
  onSave: (value: CollectiveGoalDto) => void;
};

export function OrgGoalForm({ value, onSave }: OrgGoalFormProps) {
  const [wholeOrg, setWholeOrg] = useState<boolean>(true);
  const { data: userFields, isFetched } = useUserFields();
  const { data: users } = useOrgUsers();
  const [conditions, setConditions] = useState<Condition[]>([]);
  const { register, handleSubmit, reset } = useForm<CollectiveGoalDto>();
  const [aggregationMode, setAggregationMode] = useState<"any" | "all">("any");
  const terms = useGoalTerminology();

  useEffect(() => {
    if (value != null) {
      reset(value);
      setWholeOrg(value.conditions.length === 0);
      setConditions(
        value.conditions.map((x) => ({
          id: x.userFieldId,
          value: x.expectedValue,
        }))
      );
    }
  }, [value, reset]);

  const userCount = useMemo(() => {
    if (users == null) {
      return 0;
    }

    if (conditions.length === 0) {
      return users.length;
    }

    const matches = users.filter((x) => {
      const preds = conditions.map((c) =>
        x.userFields.some((x) => x.id === c.id && x.value === c.value)
      );

      if (aggregationMode === "all") {
        return preds.every((x) => x);
      } else {
        return preds.some((x) => x);
      }
    });

    return matches.length;
  }, [conditions, users, aggregationMode]);

  if (!isFetched) {
    return <Loader />;
  }

  const uf = (userFields || []).filter(
    discriminate("type", UserFieldType.Select)
  );

  const userFieldOptions = uf.map((x) => ({
    value: x.id,
    label: x.name,
  }));

  const userFieldValueOptions = uf.reduce((acc, x) => {
    acc[x.id] = x.options.map((x) => ({
      value: x.id,
      label: x.name,
    }));
    return acc;
  }, {});

  const addCondition = () => {
    if (uf.length === 0) {
      return window.alert(
        "You don't have any user fields of a supported type. To be used in a condition a user field must be of type Select"
      );
    }

    setConditions([
      ...conditions,
      {
        id: uf[0].id,
        value: userFieldValueOptions[uf[0].id][0].value,
      },
    ]);
  };

  const setConditionField =
    (index: number) => (ev: React.ChangeEvent<HTMLSelectElement>) => {
      const c = conditions[index];
      c.id = ev.target.value;
      c.value = userFieldValueOptions[c.id][0].value;
      setConditions([...conditions]);
    };

  const setConditionValue =
    (index: number) => (ev: React.ChangeEvent<HTMLSelectElement>) => {
      const c = conditions[index];
      c.value = ev.target.value;
      setConditions([...conditions]);
    };

  const removeCondition = (idx: number) => {
    setConditions(conditions.filter((_, i) => i !== idx));
  };

  const conflictedConditions =
    !wholeOrg &&
    aggregationMode === "all" &&
    hasDuplicates(conditions.map((x) => x.id));

  const onSubmit = (values: CollectiveGoalDto) => {
    const spec: CollectiveGoalDto = {
      ...values,
      id: value?.id,
      aggregationMode: aggregationMode,
      conditions: wholeOrg
        ? []
        : conditions.map((x) => ({
            userFieldId: x.id,
            expectedValue: x.value,
          })),
    };

    onSave(spec);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={3}>
        <Text fontWeight={500} fontSize={4} mb={1}>
          {terms.goal.asTitle()} Details
        </Text>
        <FormInput
          ref={register({ required: true })}
          name="name"
          label="Name"
          required
        />
        <FormTextArea ref={register} name="description" label="Description" />
        <FormToggle
          mb={2}
          value={wholeOrg}
          onChange={setWholeOrg}
          label="Applies to whole organisation"
          helpText={`Should everyone in your organisation have this ${terms.goal.asBody()} in mind?`}
        />
        {!wholeOrg && (
          <>
            <Flex alignItems="flex-end" justifyContent="space-between" mb={1}>
              <Text fontWeight={500} fontSize={4}>
                {terms.goal.asTitle()} Conditions
              </Text>
              <Text color="primary" fontWeight={500}>
                Matches {userCount} users
              </Text>
            </Flex>
            <Flex
              p={2}
              pl={3}
              mb={2}
              border={1}
              borderRadius={3}
              alignItems="center"
            >
              <Text fontWeight={500}>
                Apply this {terms.goal.asBody()} if a user meets{" "}
              </Text>
              <Select
                ml={1}
                mr={1}
                style={{ display: "inline-grid", width: "80px", flex: 1 }}
                defaultValue="any"
                options={aggregationOptions}
                onChange={(ev) => setAggregationMode(ev.target.value as any)}
                name="aggregationMode"
              />{" "}
              <Text fontWeight={500}>of these conditions.</Text>
            </Flex>
            {conditions.map((x, i) => (
              <Flex
                border={1}
                mb={2}
                alignItems="center"
                p={2}
                borderRadius={3}
              >
                <Select
                  options={userFieldOptions}
                  value={x.id}
                  onChange={setConditionField(i)}
                />
                <Text
                  ml={2}
                  mr={2}
                  style={{ whiteSpace: "nowrap" }}
                  fontWeight={500}
                >
                  is equal to{" "}
                </Text>
                <Select
                  options={userFieldValueOptions[x.id]}
                  value={x.value}
                  onChange={setConditionValue(i)}
                />
                <IconButton
                  icon={FaTrash}
                  color="danger"
                  onClick={() => removeCondition(i)}
                  ml={2}
                />
              </Flex>
            ))}
            <AddButton onClick={addCondition}>Add Condition</AddButton>
          </>
        )}
      </Box>

      <Flex alignItems="center">
        <Button type="submit" disabled={conflictedConditions} color="primary">
          Save
        </Button>
        {conflictedConditions && (
          <Text color="danger" ml={2}>
            Conflicting conditions detected
          </Text>
        )}
      </Flex>
    </form>
  );
}
