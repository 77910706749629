import { Loader, Text } from "@coaching-culture/ui";
import CenterColumn from "components/CenterColumn";
import { PageHeader } from "components/PageHeader";
import { minBy, sortBy, uniqBy } from "lodash";
import ContentList from "pages/Mindset/ModuleList";
import { useAvailableContent } from "queries/mindset";
import { Redirect, useParams } from "react-router-dom";

const LessonsList = () => {
  const { category } = useParams<{ category?: string }>();
  const { data: content, isFetched } = useAvailableContent();

  if (!isFetched) {
    return <Loader />;
  }

  const lessons = content.filter((x) => x.type === "lesson");

  if (lessons.length === 0) {
    return (
      <>
        <CenterColumn>
          <PageHeader
            text={"Lessons"}
            subtitle="Giving everybody the capability to coach"
          />
          <Text>No Lessons Available</Text>
        </CenterColumn>
      </>
    );
  }

  const cats = uniqBy(
    lessons.map((x) => x.category),
    "id"
  );
  const cat = cats.find((x) => x.id === category);

  if (category == null || cat == null) {
    return (
      <Redirect
        to={`/solutions/lessons/${minBy(cats, (x) => x.orderIndex).id}`}
      />
    );
  }

  const sortedLessons = sortBy(
    content.filter((x) => x.category.id === category),
    "orderIndex"
  );

  if (sortedLessons.length === 0) {
    return (
      <Text m={3} fontSize={4} fontWeight={600}>
        No Content Available
      </Text>
    );
  }

  return (
    <>
      <CenterColumn>
        <PageHeader
          text={cat.name}
          subtitle="Giving everybody the capability to coach"
        />
        <ContentList items={sortedLessons} />
      </CenterColumn>
    </>
  );
};

export default LessonsList;
