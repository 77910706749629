import {
  ContentFormItem,
  FormItem,
  FormItemSpec,
  FreeTextQuestionFormItem,
  HeadingFormItem,
  MultipleChoiceQuestionFormItem,
  MultipleChoiceQuestionFormItemSpec,
  RatingFormItem,
  Spec,
} from "@coaching-culture/types";
import React from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import Box from "../Box";
import Button from "../Button";
import ControlLabel from "../ControlLabel";
import Flex from "../Flex";
import FormInput from "../FormInput";
import FormInputWrap from "../FormInputWrap";
import { FormToggle } from "../FormToggle";
import { IconButton } from "../IconButton";
import Input from "../Input";
import { MultiToggle } from "../MultiToggle";
import { RichTextEditor } from "../RichTextEditor";
import Text from "../Text";
import { RatingTypeEditor } from "./RatingTypeEditor";

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const Overlay = styled(animated.div)`
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Panel = styled(animated.div)`
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 100%;
  width: 500px;
`;

const AddAnswerButton = styled.button`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  height: 32px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }

  & svg {
    margin-right: 12px;
  }
`;

export type FormItemEditorProps = {
  value: FormItem | FormItemSpec;
  onChange: (item: FormItemSpec) => void;
  onClose: () => void;
  adminMode: boolean;
  allowEvidence: boolean;
};

type EditorProps<T> = {
  value: T;
  onChange: (val: T) => void;
  adminMode?: boolean;
  allowEvidence?: boolean;
};

function MultipleChoiceEditor({
  value,
  onChange,
  adminMode,
}: EditorProps<MultipleChoiceQuestionFormItemSpec>) {
  const changeContent =
    (index: number) => (ev: React.ChangeEvent<HTMLInputElement>) =>
      onChange({
        ...value,
        answers: value.answers.map((x, i) => ({
          ...x,
          content: i === index ? ev.target.value : x.content,
        })),
      });

  const changeValue =
    (index: number) => (ev: React.ChangeEvent<HTMLInputElement>) =>
      onChange({
        ...value,
        answers: value.answers.map((x, i) => ({
          ...x,
          value: i === index ? parseInt(ev.target.value, 10) : x.value,
        })),
      });

  const removeAnswer = (index: number) => {
    onChange({
      ...value,
      answers: value.answers.filter((_, i) => i !== index),
    });
  };

  const addAnswer = () => {
    onChange({
      ...value,
      answers: [
        ...value.answers,
        {
          content: "",
          value: 0,
        },
      ],
    });
  };

  return (
    <>
      <FormInput
        value={value.content}
        label="Question"
        onChange={(ev) => onChange({ ...value, content: ev.target.value })}
      />
      {adminMode && (
        <FormInput
          value={value.group ?? ""}
          label="Group"
          onChange={(ev) =>
            onChange({ ...value, group: ev.target.value || null })
          }
        />
      )}
      <ControlLabel>Answers</ControlLabel>
      {value.answers.map((x, i) => (
        <Flex alignItems="center" mb={1}>
          <Input
            value={x.content}
            style={{ flex: 1 }}
            onChange={changeContent(i)}
          />
          {adminMode && (
            <Input
              value={x.value?.toString()}
              required
              style={{ flex: 0, width: 60 }}
              type="number"
              ml={1}
              onChange={changeValue(i)}
            />
          )}
          <IconButton
            ml={1}
            icon={FaTimes}
            color="danger"
            onClick={() => removeAnswer(i)}
          />
        </Flex>
      ))}
      <AddAnswerButton onClick={addAnswer}>
        <FaPlus />
        Add Answer
      </AddAnswerButton>
    </>
  );
}

function FreeTextEditor({
  value,
  onChange,
}: EditorProps<Spec<FreeTextQuestionFormItem>>) {
  return (
    <>
      <FormInput
        value={value.content}
        label="Question"
        onChange={(ev) => onChange({ ...value, content: ev.target.value })}
      />
      <FormInputWrap label="Field Type">
        <MultiToggle
          items={["Multi-line", "Single-line"]}
          active={value.fieldType === "multiline" ? 0 : 1}
          onSelect={(i) =>
            onChange({
              ...value,
              fieldType: i === 0 ? "multiline" : "singleline",
            })
          }
        />
      </FormInputWrap>
    </>
  );
}

function HeadingEditor({
  value,
  onChange,
}: EditorProps<Spec<HeadingFormItem>>) {
  return (
    <>
      <FormInput
        value={value.content}
        label="Section Name"
        onChange={(ev) => onChange({ ...value, content: ev.target.value })}
      />
    </>
  );
}

function RatingEditor({
  value,
  onChange,
  allowEvidence,
}: EditorProps<Spec<RatingFormItem>>) {
  const setValue = (key: string) => (val: any) =>
    onChange({ ...value, [key]: val });

  return (
    <>
      <FormInput
        value={value.content}
        label="Question"
        onChange={(ev) => onChange({ ...value, content: ev.target.value })}
      />
      <RatingTypeEditor
        value={value.ratingType}
        onChange={setValue("ratingType")}
      />
      {value.ratingType === "number" && (
        <>
          <FormInput
            value={value.highRangeLabel ?? ""}
            label="High End Label"
            onChange={(ev) =>
              onChange({
                ...value,
                highRangeLabel: ev.target.value.substring(0, 32),
              })
            }
          />
          <FormInput
            value={value.lowRangeLabel ?? ""}
            label="Low End Label"
            maxLength={32}
            onChange={(ev) =>
              onChange({
                ...value,
                lowRangeLabel: ev.target.value.substring(0, 32),
              })
            }
          />
        </>
      )}
      {value.ratingType === "dots" && (
        <FormToggle
          label="Disagree is positive?"
          value={value.reverseScale}
          helpText="If a user disagrees with the question then this would be considered a positive outcome."
          onChange={setValue("reverseScale")}
          mb={2}
        />
      )}
      {allowEvidence ? (
        <>
          <FormToggle
            label="Requires Evidence"
            value={value.requiresEvidence}
            onChange={setValue("requiresEvidence")}
            mb={2}
          />
          {value.requiresEvidence && (
            <FormInput
              value={value.noEvidenceLabel ?? ""}
              label="No Evidence Label (32 chars max)"
              maxLength={32}
              onChange={(ev) =>
                onChange({
                  ...value,
                  noEvidenceLabel: ev.target.value.substring(0, 32),
                })
              }
            />
          )}{" "}
        </>
      ) : (
        <FormToggle
          label="Allow N/A"
          value={value.allowNa}
          helpText="Allow the user to answer with Not Applicable."
          onChange={setValue("allowNa")}
          mb={2}
        />
      )}
    </>
  );
}
function ContentEditor({
  value,
  onChange,
}: EditorProps<Spec<ContentFormItem>>) {
  return (
    <>
      <FormInput
        value={value.title}
        onChange={(val) => onChange({ ...value, title: val.target.value })}
        label="Title"
      />
      <ControlLabel>Content</ControlLabel>
      <RichTextEditor
        value={value.content}
        onChange={(val) => onChange({ ...value, content: val })}
      />
    </>
  );
}

export function FormItemEditor({
  value,
  onChange,
  onClose,
  adminMode,
  allowEvidence,
}: FormItemEditorProps) {
  const spring = useSpring({
    from: {
      opacity: 0,
      x: "100%",
    },
    opacity: 1,
    x: "0%",
  });

  return (
    <Container>
      <Overlay onClick={onClose} style={{ opacity: spring.opacity }} />
      <Panel style={spring}>
        <Flex justifyContent="space-between" alignItems="center" p={3}>
          <Text fontSize={4} fontWeight={600}>
            Edit Form Item
          </Text>
          <IconButton icon={FaTimes} color="body" onClick={onClose} />
        </Flex>
        <Box p={3}>
          <Box mb={3}>
            {value.type === "content" ? (
              <ContentEditor value={value} onChange={onChange} />
            ) : value.type === "free_text" ? (
              <FreeTextEditor value={value} onChange={onChange} />
            ) : value.type === "heading" ? (
              <HeadingEditor value={value} onChange={onChange} />
            ) : value.type === "multiple_choice" ? (
              <MultipleChoiceEditor
                value={value}
                onChange={onChange}
                adminMode={adminMode}
              />
            ) : value.type === "rating" ? (
              <RatingEditor
                value={value}
                onChange={onChange}
                allowEvidence={allowEvidence}
              />
            ) : null}
          </Box>
          <Flex justifyContent="flex-end">
            <Button onClick={onClose}>Close</Button>
          </Flex>
        </Box>
      </Panel>
    </Container>
  );
}
