import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const ContentElement = styled.div`
  & h2 {
    margin-bottom: 12px;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
  }

  & p {
    margin-bottom: 6px;
  }

  & p:empty {
    display: none;
  }
`;

const ContentContainer = ({ content }) => {
  return (
    <ContentElement>
      <ReactMarkdown children={content} />
    </ContentElement>
  );
};

export default ContentContainer;
