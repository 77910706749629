import {
  InsightsData,
  ModuleInsight,
  ProductVisibility,
} from "@coaching-culture/types";
import {
  BarChart,
  Box,
  Button,
  DonutGraph,
  Flex,
  IconButton,
  Label,
  Modal,
  Panel,
  PanelHeader,
  Text,
} from "@coaching-culture/ui";
import {
  grid,
  GridProps,
  LayoutProps,
  space,
  layout,
  SpaceProps,
} from "styled-system";
import styled from "styled-components";
import {
  FaQuestion,
  FaRegWindowClose,
  FaRegWindowMaximize,
  FaSadTear,
} from "react-icons/fa";
import StarRating from "components/StarRating";
import { useCallback, useMemo, useRef, useState } from "react";
import { ActivityMatrix } from "components/ActivityMatrix";
import { useProductVisibility } from "hooks/useProductVisibility";
import ReactHtmlParser from "react-html-parser";
import { useOrg } from "auth/OrgProvider";

const WidgetGrid = styled.div<GridProps & SpaceProps & LayoutProps>`
  display: grid;
  justify-content: center;
  ${grid};
  ${layout};
  ${space};
`;

const ModuleIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const NoDataContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grey2};
`;

const NoData = () => (
  <NoDataContainer>
    <FaSadTear fontSize={"6em"} />
    <Text textAlign="center" mt={2} fontSize={4} fontWeight={600}>
      No Data
    </Text>
  </NoDataContainer>
);

const PopOver = styled(Panel)`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 3px;
  padding: 6px 12px;
  z-index: 1000;
`;

const ModuleBarChart = ({
  module,
  color,
}: {
  module: ModuleInsight;
  color: string;
}) => {
  const popoverRef = useRef<HTMLDivElement>(null!);
  const [mouseOver, setMouseOver] = useState<{
    label: string;
    value: number;
  } | null>(null);

  const data = useMemo(
    () =>
      module.data.map((x) => ({
        label: x.name,
        streams: Array.isArray(x.count)
          ? (x.count.map((y) => ({ label: y.label, value: y.value })) as {
              label: string;
              value: number;
            }[])
          : [{ label: "", value: x.count }],
      })),
    [module]
  );

  const onMouseOver = useCallback((dataPoint) => {
    popoverRef.current.style.opacity = dataPoint == null ? "0" : "1";
    if (dataPoint != null) {
      setMouseOver({
        label: dataPoint.x,
        value: dataPoint.y,
      });
    }
  }, []);

  const onMouseMove = useCallback((e) => {
    var rect = e.currentTarget.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY - rect.top; //y position within the element.
    popoverRef.current.style.left = x + "px";
    popoverRef.current.style.top = `${y - 40}px`;
  }, []);

  return (
    <Box
      onMouseMove={onMouseMove}
      style={{
        position: "relative",
      }}
    >
      <BarChart
        xAxis={module.xAxis}
        yAxis={module.yAxis}
        streamLabels={[""]}
        onValueMouseOver={onMouseOver}
        color={color}
        data={data}
      />
      <PopOver ref={popoverRef} p={1} color="none" boxShadow={2}>
        <Text color="grey1" fontWeight={600} style={{ whiteSpace: "nowrap" }}>
          {mouseOver?.label}: {mouseOver?.value}
        </Text>
      </PopOver>
    </Box>
  );
};

const PieChart = ({ module }: { module: ModuleInsight }) => {
  return (
    <DonutGraph
      values={module.data.map((x) => ({
        label: x.name,
        value: x.count,
      }))}
    />
  );
};

type SingleValueWidgetProps = {
  title: string;
  value: number;
};

const hasNoData = (mod: ModuleInsight) => {
  if (mod.data.every((x) => x.count === 0)) {
    return -1;
  }
  return 1;
};

const SingleValueWidget = ({ title, value }: SingleValueWidgetProps) => (
  <Panel p={2}>
    <Text fontSize={2} color="grey2" fontWeight={600} mb={1}>
      {title.toUpperCase()}
    </Text>
    <Text fontSize={4} fontWeight={600}>
      {value}
    </Text>
  </Panel>
);

const Insights = ({ data }: { data: InsightsData }) => {
  const hasMindset =
    useProductVisibility("mindset") === ProductVisibility.Enabled;
  const insightsRef = useRef<HTMLDivElement>(null!);
  const [showHelp, setShowHelp] = useState({ name: "", text: "" });
  const [selectedModule, setSelectedModule] = useState("");
  const [org] = useOrg();

  return (
    <>
      {showHelp.text !== "" && (
        <Modal
          width={"500px"}
          onClose={() => setShowHelp({ name: "", text: "" })}
          showCloseButton
        >
          <Box p={3}>
            <PanelHeader mb={2}>
              <Text fontSize={18} fontWeight={600}>
                {showHelp.name}
              </Text>
            </PanelHeader>
            {ReactHtmlParser(showHelp.text)}
            <Flex mt={2} justifyContent={"flex-end"}>
              <Button
                type="button"
                onClick={() => setShowHelp({ name: "", text: "" })}
                mr={2}
              >
                Cancel
              </Button>
            </Flex>
          </Box>
        </Modal>
      )}
      <Flex justifyContent="space-between" alignItems="flex-end" mb={3}>
        <Text fontSize={5}>Engagement</Text>
      </Flex>
      <div ref={insightsRef}>
        <WidgetGrid
          gridGap={[3, 3]}
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
          mb={3}
        >
          <SingleValueWidget title="Total Users" value={data.totalUsers} />
          <SingleValueWidget
            title="Total Module Attempts"
            value={data.moduleAttempts}
          />
          <SingleValueWidget
            title="Active Users (7d)"
            value={data.activeUsersWeek}
          />
          <SingleValueWidget
            title="Active Users (30d)"
            value={data.activeUsersMonth}
          />
        </WidgetGrid>
        <Panel
          mb={3}
          p={2}
          style={{ pageBreakInside: "avoid", pageBreakAfter: "always" }}
        >
          <Text fontSize={2} fontWeight={600} color="grey2" mb={2}>
            ACTIVITY BY HOUR (LAST 30 DAYS)
          </Text>
          <ActivityMatrix data={data.activity} />
        </Panel>
        {hasMindset && (
          <>
            <Text fontSize={5} mb={3}>
              Modules
            </Text>
            {data.modules.length === 0 ? (
              <Text textAlign="center" fontSize={4}>
                No Modules Available
              </Text>
            ) : (
              <WidgetGrid
                gridGap={[3, 3]}
                gridTemplateColumns={"repeat(auto-fit, minmax(500px, 1fr))"}
                display={selectedModule !== "" ? "flex" : ["block", "grid"]}
              >
                {data.modules
                  .sort((a, b) => {
                    return (
                      hasNoData(b) - hasNoData(a) ||
                      a.name.localeCompare(b.name)
                    );
                  })
                  .map((x) => (
                    <Panel
                      width={selectedModule === x.id ? "75%" : ""}
                      display={
                        selectedModule !== "" && selectedModule !== x.id
                          ? "none"
                          : ""
                      }
                      color={x.mainColor}
                      p={2}
                      key={x.id}
                      style={{
                        position: "relative",
                        pageBreakAfter: "always",
                      }}
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                      >
                        <Flex
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <ModuleIcon
                            src={
                              process.env.REACT_APP_STATIC_ROOT + "/" + x.icon
                            }
                          />
                          <Text ml={2} fontSize={4} fontWeight={500}>
                            {x.name}
                          </Text>
                        </Flex>
                        <Flex
                          justifyContent={"flex-end"}
                          alignItems={"center-end"}
                        >
                          {x.reportHelp !== "" && (
                            <IconButton
                              mr={2}
                              color="body"
                              aria-label="Help"
                              icon={FaQuestion}
                              onClick={() =>
                                setShowHelp({
                                  name: x.name,
                                  text: x.reportHelp,
                                })
                              }
                            />
                          )}
                          {selectedModule === x.id ? (
                            <IconButton
                              mr={2}
                              color="body"
                              aria-label="Close fullscreen"
                              icon={FaRegWindowClose}
                              onClick={() => setSelectedModule("")}
                            />
                          ) : (
                            <IconButton
                              mr={2}
                              color="body"
                              aria-label="Fullscreen"
                              icon={FaRegWindowMaximize}
                              onClick={() => setSelectedModule(x.id)}
                            />
                          )}
                        </Flex>
                      </Flex>
                      <Flex mb={2}>
                        <Label color={x.mainColor}>Started: {x.started}</Label>
                        <Label color={x.mainColor}>
                          Assessment Completed: {x.formCompleted}
                        </Label>
                        <Label color={x.mainColor}>
                          Programme Completed: {x.challengeStarted}
                        </Label>
                        <Label color={x.mainColor}>
                          Challenge Completed: {x.challengeCompleted}
                        </Label>
                        <Label color={x.mainColor}>
                          Completed: {x.completed}
                        </Label>
                      </Flex>
                      <Box style={{ position: "relative" }}>
                        {x.graphType === "bar" ? (
                          <ModuleBarChart module={x} color={x.mainColor} />
                        ) : x.graphType === "pie" ? (
                          <PieChart module={x} />
                        ) : null}
                        {x.data.every((x) => x.count === 0) && <NoData />}
                      </Box>
                      {org.collectRatings && (
                        <Flex
                          flexDirection="column"
                          border={1}
                          p={2}
                          mt={1}
                          alignItems="center"
                        >
                          <Text fontWeight={600}>User Rating</Text>
                          <StarRating value={x.averageRating} size={"3em"} />
                        </Flex>
                      )}
                    </Panel>
                  ))}
              </WidgetGrid>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Insights;
