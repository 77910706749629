import React, { useState, useEffect } from "react";
import { useUser } from "../../auth";
import styled from "styled-components";
import axios from "axios";
import {
  Circle,
  Modal,
  Button,
  Text,
  Flex,
  Loader,
} from "@coaching-culture/ui";
import UserProfileImage from "../../components/UserProfileImage";
import {
  FaUser,
  FaChevronRight,
  FaSearch,
  FaChevronDown,
} from "react-icons/fa";
import { Drawer, DrawerHeader } from "components/ShareModal/Drawer";

const EmptyCircle = () => <Circle color="grey3" mr={2} />;

const MenuItemElem = styled.button`
  display: flex;
  text-align: left;
  margin-bottom: 6px;
  border: 0;
  padding: 12px;
  background: none;
  align-items: center;
  transition: all 0.3s ease;
  cursor: not-allowed;

  &:hover:enabled {
    background-color: ${(props) => props.theme.colors.grey6};
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const MenuItem = ({ onClick, title, subtitle, image, disabled }) => {
  return (
    <MenuItemElem onClick={onClick} disabled={disabled}>
      {image}
      <Flex flexDirection="column" flex="1" mt="3px">
        <Text fontSize={5}>{title}</Text>
        <Text>{subtitle}</Text>
      </Flex>
      <FaChevronRight size="1.5em" />
    </MenuItemElem>
  );
};

const SearchInputContainer = styled.div`
  background-color: ${(props) => props.theme.colors.grey6};
  width: 400px;
  max-width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 18px;
  border: 1px solid transparent;
  margin-bottom: 24px;

  & > svg {
    margin-right: 12px;
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

const SearchInput = styled.input`
  flex: 1;
  background: transparent;
  border: 0;

  &:focus,
  &:active {
    border: 0;
    outline: 0;
  }
`;

const SearchBox = ({ value, onChange }) => {
  return (
    <SearchInputContainer>
      <FaSearch />
      <SearchInput
        autoFocus
        value={value}
        onChange={onChange}
        placeholder="Search for people..."
      />
    </SearchInputContainer>
  );
};

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const ItemsList = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: scroll;
`;

const ItemContainer = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  background: none;
  transition: all 0.3s ease;
  border: 0;

  &:focus,
  &:active {
    outline: 0;
    background-color: ${(props) => props.theme.colors.grey4};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }
`;

const CloseDrawerButton = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  color: ${(props) => props.theme.colors.grey3};
  background: none;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.grey1};
  }
`;

const UserItem = ({ onClick, user }) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width="400px" maxWidth="100%" alignItems="center">
        <UserProfileImage
          profileImage={user.profileImage}
          name={user.name}
          mr={2}
          size="small"
        />
        <Text fontWeight={500} fontSize={4}>
          {user.name}
        </Text>
      </Flex>
    </ItemContainer>
  );
};

export const UserDirectory = ({ onSelect, exclude }) => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState(false);

  const dbSearch = useDebounce(search, 500);

  useEffect(() => {
    if (dbSearch !== "") {
      const id = setTimeout(() => {
        setSearching(true);
      }, 200);
      axios.get(`/api/users?search=${dbSearch}`).then(({ data }) => {
        setUsers(data.filter((x) => x.id !== exclude));
        setSearching(false);
        clearTimeout(id);
      });
    }
  }, [dbSearch, exclude]);

  return (
    <Drawer>
      <DrawerHeader>
        <CloseDrawerButton onClick={() => onSelect(null)}>
          <FaChevronDown />
        </CloseDrawerButton>
        <Text textAlign="center" fontSize={5} fontWeight={600}>
          Directory
        </Text>
      </DrawerHeader>
      <SearchBox value={search} onChange={(ev) => setSearch(ev.target.value)} />
      <ItemsList>
        {searching && <Loader overlay color="primary" />}
        {!searching && users.length === 0 && dbSearch !== "" && (
          <Text textAlign="center" fontWeight={600}>
            No users found
          </Text>
        )}
        {users.map((x) => (
          <UserItem onClick={() => onSelect(x)} user={x} />
        ))}
      </ItemsList>
    </Drawer>
  );
};

const RelationshipItem = ({ onClick, name, description }) => {
  return (
    <ItemContainer onClick={onClick}>
      <Flex width={400} maxWidth="100%" flexDirection="column">
        <Text fontSize={4} fontWeight={500}>
          {name}
        </Text>
        <Text>{description}</Text>
      </Flex>
    </ItemContainer>
  );
};

const relationships = [
  {
    name: "Line Manager",
    type: "manager",
    description: "This person manages me directly",
    me: "to",
  },
  {
    name: "Direct Report",
    type: "manager",
    description: "I Manage this person directly",
    me: "from",
  },
];

const SelectRelationship = ({ onSelect, user }) => {
  return (
    <Drawer>
      <DrawerHeader>
        <CloseDrawerButton onClick={() => onSelect(null)}>
          <FaChevronDown />
        </CloseDrawerButton>
        <Text textAlign="center" fontSize={5} fontWeight={600}>
          {user.name} is my...
        </Text>
      </DrawerHeader>
      <ItemsList>
        {relationships.map((x) => (
          <RelationshipItem
            onClick={() => onSelect(x)}
            name={x.name}
            description={x.description}
          />
        ))}
      </ItemsList>
    </Drawer>
  );
};

const AddRelationshipModal = ({ onClose }) => {
  const [me] = useUser();
  const [user, setUser] = useState(null);
  const [relationType, setRelationType] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showUserDirectory, setShowUserDirectory] = useState(false);
  const [showRelDrawer, setShowRelDrawer] = useState(false);

  const ready = user != null && relationType != null;

  const doRelationship = () => {
    let spec = {
      type: relationType.type,
      from: relationType.me === "from" ? me.id : user.id,
      to: relationType.me === "to" ? me.id : user.id,
    };

    axios
      .post("/api/relationships", spec)
      .then(({ data }) => {
        onClose(data);
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          window.alert(err.response.data);
        } else {
          window.alert(err);
        }
        setLoading(false);
      });

    console.log(spec);
  };

  const onUserSelect = (user) => {
    if (user) {
      setUser(user);
    }
    setShowUserDirectory(false);
  };

  const onRelationshipSelect = (rel) => {
    if (rel) {
      setRelationType(rel);
    }
    setShowRelDrawer(false);
  };

  return (
    <Modal
      color="primary"
      width={600}
      p={5}
      onClose={() => onClose(null)}
      showCloseButton={!loading}
      style={{ overflow: "hidden" }}
    >
      {loading && <Loader overlay color="primary" />}
      {showUserDirectory && (
        <UserDirectory onSelect={onUserSelect} exclude={me.id} />
      )}
      {showRelDrawer && (
        <SelectRelationship onSelect={onRelationshipSelect} user={user} />
      )}
      <Flex alignItems="center" flexDirection="column" mb={5}>
        <Circle icon={FaUser} color="primary" size="large" mb={2} />
        <Text fontWeight={600} fontSize={6}>
          Create Relationship
        </Text>
      </Flex>
      <Flex mb={5} flexDirection="column">
        <MenuItem
          title={user ? user.name : "Who with?"}
          image={
            user ? (
              <UserProfileImage
                profileImage={user.profileImage}
                name={user.name}
                mr={2}
              />
            ) : (
              <EmptyCircle />
            )
          }
          subtitle={user ? user.email : "Select from the directory"}
          onClick={() => setShowUserDirectory(true)}
          disabled={false}
        />
        <MenuItem
          image={<EmptyCircle />}
          disabled={user == null}
          title={relationType ? relationType.name : "What's the relationship?"}
          subtitle={
            relationType
              ? relationType.description
              : "Select what the relationship is"
          }
          onClick={() => setShowRelDrawer(true)}
        />
      </Flex>
      <Flex justifyContent="center">
        <Button onClick={doRelationship} disabled={!ready}>
          Send Relationship Invite
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddRelationshipModal;
